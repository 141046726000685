import { Component, Input, OnInit } from "@angular/core";
import { ContaCorrenteSimularAcrescimoService } from "src/app/component/criacao-conta-corrente/services/conta-corrente-simular-acrescimo.service";
import { ValorSimularHistorico } from "src/app/models/valor-simular-historico";
import { UtilsService } from "src/app/shared/utils.service";
import { AlertsUtil } from "src/app/utils/alerts/alerts.util";
import { MaskCpfOuCnpj } from "src/app/utils/masklCpfCnpjList/maskCpfCnpj.util";
import { RemoveParamsUtil } from "src/app/utils/paramsRemove/paramsRemove.util";

@Component({
    selector: "ccp-modal-simular-historico",
    templateUrl: "./modal-simular-historico.component.html",
    styleUrls: ["./modal-simular-historico.component.scss"],
})
export class ModalSimularHistoricoComponent implements OnInit {
    objSimular = new ValorSimularHistorico();
    titulo: string = "Simular Contas";
    dadosContribuinte: any;
    rowIndexConta: any;
    visible: boolean = false;
    simularBoolean: boolean;
    dataAtual = new Date();
    contasSimuladaList: any[] = [];
    /**popular chamada BE */
    @Input() listContasSimular: any[] = [];
    blockedDocument: boolean;
    @Input() abaSelecionada: any;

    constructor(
        private alertUtil: AlertsUtil,
        private utilService: UtilsService,
        private maskCpfOuCnpj: MaskCpfOuCnpj,
        private removeParamsUtil: RemoveParamsUtil,
        private simularAcrescimoService: ContaCorrenteSimularAcrescimoService
    ) {}

    ngOnInit(): void {}

    insereMascara(cpfOuCnpj) {
        return this.maskCpfOuCnpj.inserirMascara(cpfOuCnpj);
    }

    /**Soma para verificar se o valor está negativo e exibir o label vermelho caso negativo */
    maior(ev) {
        if (ev < 0) {
            return true;
        }
        return false;
    }

    /**Mascara mes/ano */
    converteMesAno(data) {
        let ano = data?.toString().substring(0, 4);
        let mes = data?.toString().substring(4, 6);
        return `${mes}/${ano}`;
    }

    showDialog(dadosContribuinte: any) {
        this.blockedDocument = true;
        setTimeout(() => {
        this.blockedDocument = false;
            this.calculaValorLancadoAndAtualizado();
        }, 500);
        this.dadosContribuinte = dadosContribuinte;
        this.visible = true;
        this.simularBoolean = false;
    }

    hiddenDialog() {
        this.visible = false;
    }

    expandeEnvento(evento) {
        /**Index para o componente tabela juros */
        this.rowIndexConta = evento;
    }

    /**Calculo valores lançado e atulizado das contas selecionadas */
    calculaValorLancadoAndAtualizado() {
        this.contasSimuladaList = [];
        this.objSimular = new ValorSimularHistorico();

        this.calculaValorLancado();
        this.calculaAtualizado();
        if(this.simularBoolean){
        this.calculaValorSimulado();
        }
        this.contasSimuladaList.push(this.objSimular);
    }

    /**Calculo valor lançado */
    calculaValorLancado() {
        let valorLancadoPrincipal = 0;
        let valorLancadoMulta = 0;
        let valorLancadoJuros = 0;
        let valorLancadoMultaInfra = 0;
        let valorTotalLancado = 0;

        this.listContasSimular.forEach((conta) => {
            valorLancadoPrincipal =
                parseFloat(conta?.valorLancadoPrincipal) +
                valorLancadoPrincipal;
            this.objSimular.valorLancadoPrincipal = valorLancadoPrincipal;

            valorLancadoMulta =
                parseFloat(conta?.valorLancadoMulta) + valorLancadoMulta;
            this.objSimular.valorLancadoMulta = valorLancadoMulta;

            valorLancadoJuros =
                parseFloat(conta?.valorLancadoJuros) + valorLancadoJuros;
            this.objSimular.valorLancadoJuros = valorLancadoJuros;

            valorLancadoMultaInfra =
                parseFloat(conta?.valorLancadoMultaInfra) +
                valorLancadoMultaInfra;
            this.objSimular.valorLancadoMultaInfra = valorLancadoMultaInfra;

            valorTotalLancado =
                parseFloat(conta?.valorTotalLancado) + valorTotalLancado;
            this.objSimular.valorTotalLancado = valorTotalLancado;
        });
    }

    /**Calculo valor atualizado */
    calculaAtualizado() {
        let valorAtualizadoPrincipal = 0;
        let valorAtualizadoMulta = 0;
        let valorAtualizadoJuros = 0;
        let valorAtualizadoMultaInfra = 0;
        let saldoTotalAtualizado = 0;

        this.listContasSimular.forEach((conta) => {
            valorAtualizadoPrincipal =
                parseFloat(conta?.valorAtualizadoPrincipal) +
                valorAtualizadoPrincipal;
            this.objSimular.valorAtualizadoPrincipal = valorAtualizadoPrincipal;

            valorAtualizadoMulta =
                parseFloat(conta?.valorAtualizadoMulta) + valorAtualizadoMulta;
            this.objSimular.valorAtualizadoMulta = valorAtualizadoMulta;

            valorAtualizadoJuros =
                parseFloat(conta?.valorAtualizadoJuros) + valorAtualizadoJuros;
            this.objSimular.valorAtualizadoJuros = valorAtualizadoJuros;

            valorAtualizadoMultaInfra =
                parseFloat(conta?.valorAtualizadoMultaInfra) +
                valorAtualizadoMultaInfra;
            this.objSimular.valorAtualizadoMultaInfra =
                valorAtualizadoMultaInfra;

            saldoTotalAtualizado =
                parseFloat(conta?.saldoTotalAtualizado) + saldoTotalAtualizado;
            this.objSimular.saldoTotalAtualizado = saldoTotalAtualizado;
        });
    }

    /**Calculo valor atualizado */
    calculaValorSimulado() {
        
        let valorSimuladoPrincipal = 0;
        let valorSimuladoMulta = 0;
        let valorSimuladoJuros = 0;
        let valorSimuladoMultaInfra = 0;
        let saldoTotalSimulado = 0;

        this.listContasSimular.forEach((conta) => {
            valorSimuladoPrincipal =
                parseFloat(conta.financeiros[0]?.valorLancado) +
                valorSimuladoPrincipal;
            this.objSimular.valorSimuladoPrincipal = valorSimuladoPrincipal;

            valorSimuladoMulta =
                parseFloat(conta.financeiros[1]?.valorLancado) +
                valorSimuladoMulta;
            this.objSimular.valorSimuladoMulta = valorSimuladoMulta;

            valorSimuladoJuros =
                parseFloat(conta.financeiros[2]?.valorLancado) +
                valorSimuladoJuros;
            this.objSimular.valorSimuladoJuros = valorSimuladoJuros;


            valorSimuladoMultaInfra =
                parseFloat(conta.financeiros[3]?.valorLancado) +
                valorSimuladoMultaInfra;
            this.objSimular.valorSimuladoMultaInfra = valorSimuladoMultaInfra;

        });

        this.listContasSimular.forEach((conta) => {
            saldoTotalSimulado =
                parseFloat(conta?.valorTotalSimulado) + saldoTotalSimulado;
            this.objSimular.saldoTotalSimulado = saldoTotalSimulado;
        });

    }

    /**Simular Multa e Juros */
    simular() {
        this.blockedDocument = true;
        setTimeout(() => {
            this.blockedDocument = false;
        }, 1000);
        this.simularBoolean = true;
        let listIdsContas = [];
        this.listContasSimular.forEach((element) => {
            listIdsContas.push(element.contaCorrenteId);
        });
        const params = {
            idsContas: listIdsContas,
            dataEvento: this.utilService.convertDateToSave(new Date()),
        };
        this.requestSimular(params);
    }

    /**Pos Simular Acrescimos */
    requestSimular(params: any) {
        this.simularAcrescimoService.postSimularAcrescimos(params).subscribe(
            (resp: any)=>{
                this.simulaContasResponse(resp);
            },
            (error) => {
                this.hiddenDialog();
                this.alertUtil.handleError(error);
            });
    }
    
    /**Percorre lista das contas selecionadas e vincula o valor SIMULADO a cada conta  */
    simulaContasResponse(simularResponse){
        let listaContaSimularAux = [];
         /**Adiciona financeiros e valor total a cada item */
         this.listContasSimular.forEach((element, index) => {
            if (
                simularResponse.contasSimuladas.find(
                    (aux) =>
                        aux.contaCorrente.configConta.configContaId ==
                        element.configContaId
                )
            ) {
                listaContaSimularAux.push({
                    ...element,
                    ...{
                        financeiros:
                            simularResponse.contasSimuladas[index]?.financeiros,
                        valorTotalSimulado:
                            simularResponse.contasSimuladas[index]?.totalValorSimulado,
                    },
                });
            }
        });
        this.listContasSimular = listaContaSimularAux;
        /**Simular contas */
        this.calculaValorLancadoAndAtualizado();
    }

    simulacaoExtratoContaCorrentePdf(conta: any){
        this.blockedDocument = true;
        
        const objParams = {
            contaCorrenteId: conta?.contaCorrenteId,
            cpfCnpj: this.dadosContribuinte?.cpfcnpj,
            viewContaCorrenteEnum: this.abaSelecionada
        }

        this.removeParamsUtil.removeParametroNaoUtilizado(objParams);
            
        this.simularAcrescimoService
        .simulacaoExtratoContaCorrentePdf(objParams)
        .subscribe((response) => {
          this.blockedDocument = false;
           const blob = new Blob([response], { type: 'application/pdf' }); 
           const data = window.URL.createObjectURL(blob); 
           const link = document.createElement('a'); 
           link.href = data; window?.open(data, '_blank'); 
          },
          (error) => {
            this.blockedDocument = false;
            this.alertUtil.handleError(error);
          }
        )
      }

}
