import { TiposParamsConfigEventos } from './../models/tipos-params-config-evento';
import { EventoGerenciarConfigService } from './../services/evento-gerenciar-config.service';
import { Subscription } from 'rxjs';
import { Directive, ElementRef, Input, OnDestroy, OnInit } from "@angular/core";

@Directive({
    selector: "[paramEventoVisivel]",
})
export class ParamEventoVisivelDirective implements OnDestroy, OnInit {
    @Input("parametro") parametro: TiposParamsConfigEventos;
    private subs = new Subscription();

    constructor(private elementRef: ElementRef,   private eventoGerenciarConfigService: EventoGerenciarConfigService) {

    }

    ngOnInit(): void {
        this.elementRef.nativeElement.style.display = "block";
        this.eventoGerenciarConfigService.onPermissoesCarregadas().subscribe(
            resp => {
                const config = resp.find(p => p.parametro == this.parametro);
                if(config &&  !config.visivel){
                    this.elementRef.nativeElement.style.display =  "none";
                } else {
                    this.elementRef.nativeElement.style.display = "block";
                }
            }
        );
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

}
