import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ContaCorrenteSimularAcrescimoService {
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(
        private http: HttpClient,
    ) {
    }

    postSimularAcrescimos(objSimular: any) {
        return this.http.post<any>(
            `${environment.baseUrl}/evento-conta/simular-acrescimos`, objSimular);
    }

    simulacaoExtratoContaCorrentePdf(objParams): Observable<Blob>{
        return this.http.get(`${environment.baseUrl}/conta-corrente/simular-extrato-conta/pdf`, {
           responseType: 'blob',
           params: {
            ...objParams
           }
        });
    }

}
