<p-dialog
    header="Gerar Extrato"
    [(visible)]="visible"
    [style]="{ width: '40vw', height: '25vw' }"
    [baseZIndex]="100"
    [draggable]="false"
    [resizable]="false"
>
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-12">
            <p>
                <strong
                    >{{ insereMascara(dadosContribuinte?.baseCnpj) }}
                </strong>
                - {{ dadosContribuinte?.nome }} /
                <strong>{{
                    contaCorrenteContribuinte?.contaCorrenteId
                }}</strong>
                - {{ contaCorrenteContribuinte?.configContaNomeCompleto }}
            </p>
        </div>
        <div class="p-field p-col-12 p-md-4">
            <label>Extrato *</label>
            <p-dropdown
                optionLabel="nome"
                [autoDisplayFirst]="true"
                [options]="valoresExtratoList"
                [(ngModel)]="extratoValue"
                (onChange)="onChangeExtratoValue($event)"
            ></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-8">
            <label title="dataFimVigencia">Posição na *</label>
            <p-calendar
                [disabled]="extratoValue?.nome == 'Atual'"
                dateFormat="dd/mm/yy"
                appendTo="body"
                [showIcon]="true"
                [(ngModel)]="posicaoData"
                [maxDate]="maxDate"
            ></p-calendar>
        </div>
    </div>

    <ng-template pTemplate="footer">
        <p-button
            [disabled]="!posicaoData"
            (click)="gerarExtrato()"
            icon="fa-solid fa-file-pdf"
            label="Gerar Extrato"
        ></p-button>
        <p-button
            (click)="hiddenDialog()"
            icon="pi pi-times"
            label="Fechar"
        ></p-button>
    </ng-template>
</p-dialog>
