import {ConfigConta} from './config-conta';
import {EventoCompensacao} from './evento-compensacao';

export class EventoCompensacaoConta {
    eventoCompensacaoContaId: number;
    origemDestino: string;
    configConta: ConfigConta;
    eventoCompensacao: EventoCompensacao;

    constructor(init?: Partial<EventoCompensacaoConta>) {
        if (init) {
            Object.assign(this, init);
        } else {
            this.eventoCompensacaoContaId = null;
            this.origemDestino = null;
            this.configConta = null;
            this.eventoCompensacao = null;
        }
    }
}
