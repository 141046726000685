import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BeneficioFiscalService } from '../../service/beneficio-fiscal.service';
import swal from "sweetalert2";
import { destinatarioSaveRequest, regraId, TipoCadastroId } from 'src/app/request/destinatario-save-request';
import { EventoBeneficioSaveAllRequest } from 'src/app/models/evento-beneficio-save-all-request';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-cadastrar-destinatario-beneficio-fiscal',
  templateUrl: './cadastrar-destinatario-beneficio-fiscal.component.html',
  styleUrls: ['./cadastrar-destinatario-beneficio-fiscal.component.scss']
})
export class CadastrarDestinatarioBeneficioFiscalComponent implements OnInit {
  eventoBeneficioId: number;
  objParams: any;
  regraList: any[] = [];
  tipoCadastroList: any[] = [];
  destinatarioSaveRequest: destinatarioSaveRequest;
  eventoBeneficioSaveAllRequest: EventoBeneficioSaveAllRequest;
  objDestinatario: any;
  submitted: boolean;
  descricao: any
  destinatarioSave: any;
  // destinatarioList: any[] = [];

  @Output() outAbaAnterior = new EventEmitter();
  @Output() saveAllRequest = new EventEmitter();
  @Input() cadastrarDestinatario: any;
  @Input() configEvento: any;
  implantado: boolean = false;

  constructor(
    private beneficioFiscalService: BeneficioFiscalService,
    private activatedRouter: ActivatedRoute
    ) {
    this.activatedRouter.params.subscribe((eventoId) => {
      this.eventoBeneficioId = eventoId.id;
  });
  }

  ngOnInit(): void {
    this.destinatarioSaveRequest = new destinatarioSaveRequest();
    this.eventoBeneficioSaveAllRequest = new EventoBeneficioSaveAllRequest();
    this.destinatarioSaveRequest.regra = new regraId();
    this.destinatarioSaveRequest.tipoCadastro = new TipoCadastroId();
    this.getRegra();
    this.populaCampos();
  }

  populaCampos(){
    if(this.eventoBeneficioId){
      if (this.cadastrarDestinatario && this.destinatarioSaveRequest) {
          this.getDestinatarioEventoBeneficio();
      }
    } else
    if(this.cadastrarDestinatario){
      this.destinatarioSaveRequest = this.cadastrarDestinatario;
    }
  }

  getDestinatarioEventoBeneficio(){
    this.beneficioFiscalService
        .getDestinatario(this.eventoBeneficioId)
        .subscribe(
            (response: any) => {
              this.destinatarioSaveRequest.tipoCadastro.tipoCadastroId = null;
              this.destinatarioSaveRequest.descricao = response.descricao;
              this.destinatarioSaveRequest.eventoDestinatarioId = response.eventoDestinatarioId;
              this.destinatarioSaveRequest.tipoCadastro.tipoCadastroId = response.regra.tipoCategoria.tipoCadastro.tipoCadastroId;
              this.destinatarioSaveRequest.regra = response.regra;
              this.implantado = response.eventoBeneficio.implantado == "SIM";
            },
            (erro) => {
                this.handleError(erro);
            }
        );

  }

  getRegra() {
    this.objParams = {
      situacao: 'ATIVA',
      tipoCategoriaTipoUso: 'DESTINATARIO_DO_BENEFICIO_FISCAL',
      tipoCadastroId: this.configEvento?.tipoCadastro?.tipoCadastroId
     };
    this.beneficioFiscalService.getRegra(this.objParams).subscribe(
        (resp: any) => {
            this.regraList = resp;
            if (!this.implantado && this.regraList.length <= 0) {
                swal.fire({
                    title: "Atenção",
                    text: `Não há regras ativas para categoria [3 - Destinatário do Benefício Fiscal] cadastradas para todos os tipos de cadastro ou para o tipo cadastro ${this.configEvento.tipoCadastro.descricao}`,
                    icon: "warning",
                });
            }
        },
        (erro) => {
          this.handleError(erro);
        }
    );
  }

  voltarAba() {
    this.outAbaAnterior.emit(2);
  }

  getDestinatario(){
    this.destinatarioSave = this.destinatarioSaveRequest;
    return this.destinatarioSave;
  }

  setDestinatario(){
    /**Salvando conta nova */
    if(!this.eventoBeneficioId) {
      this.eventoBeneficioId = 0;
    }
    const objDestinatarioSave = {
      descricao: this.destinatarioSaveRequest.descricao,
      eventoBeneficio: {eventoBeneficioId: this.eventoBeneficioId},
      eventoDestinatarioId:  this.destinatarioSaveRequest.eventoDestinatarioId,
      regra: {regraId: this.destinatarioSaveRequest.regra.regraId},
    }
    return objDestinatarioSave;
  }

  validaCampos(){
    this.submitted = true;
    if(this.destinatarioSaveRequest.descricao && this.destinatarioSaveRequest.regra.regraId){
      return true;
    }
    return false;
  }

  salvar(){
    if(this.validaCampos()){
      this.saveAllRequest.emit(this.setDestinatario());
    }
  }

  handleError(error: any): void {
    if (error.status === 500) {
        swal.fire(
            error.statusText,
            "Ocorreu um erro interno",
            "error"
        ).then((r) => {});
    } else {
        swal.fire({
            title: "Atenção",
            text: `${error.error.message}`,
            icon: "warning",
        });
    }
  }

}
