<p-messages [(value)]="msgs1" [enableService]="false"></p-messages>
<div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12">
        <p-fieldset legend="Identificação do Modelo">
            <div style="text-align: center">
                <h5 *ngIf="regraCriacaoConta.regraCriacaoContaId"
                style="text-transform: capitalize; color: blue; padding: 0; border: 0; padding-bottom: 0;">
                    {{regraCriacaoConta.regraCriacaoContaId}} - {{regraCriacaoConta.descricaoCompleta}}
                </h5>
            </div>
        </p-fieldset>
    </div>
</div>
<div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-md-4">
        <label title="regraCriacaoContaId">Código</label>
        <div class="p-inputgroup">
            <input onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || (event.charCode === 44)" type="text" pInputText [(ngModel)]="searchRequest.configContaId">
        </div>
    </div>
    <div class="p-field p-col-12 p-md-8">
        <label title="regraCriacaoContaId">Nome da Conta</label>
        <input type="text" pInputText [(ngModel)]="searchRequest.nomeCompleto">
    </div>
    <div class="p-field p-col-12 p-md-4">
        <label title="situacao">Situação da Associação</label>
        <p-dropdown
                optionLabel="valor"
                optionValue="chave"
                showClear="true"
                [autoDisplayFirst]="false"
                [options]="situacoesInput"
                (onChange)="onChangeSituacao($event)"
                [(ngModel)]="searchRequest.situacao"
        ></p-dropdown>
    </div>
    <div class="p-field p-col-12 p-md-4">
        <label title="dataInicioVigencia">Início da Vigência da Associação</label>
        <p-calendar
                dateFormat="dd/mm/yy"
                [showIcon]="true"
                [(ngModel)]="searchRequest.dataInicioVigencia"
        ></p-calendar>
    </div>
    <div class="p-field p-col-12 p-md-4">
        <label title="dataFimVigencia">Fim da Vigência da Associação</label>
        <p-calendar
                dateFormat="dd/mm/yy"
                [showIcon]="true"
                [(ngModel)]="searchRequest.dataFimVigencia"
                (onSelect)="onSelectDataFimVigencia($event)"
                (change)="onChangeDataFimVigencia($event)"
        ></p-calendar>
    </div>
</div>
<div class="p-field p-col-12 p-md-12" style="margin-top: 10px; text-align: right;">
    <button
        pButton
        type="button"
        label="Pesquisar"
        icon="pi pi-search"
        (click)="getConfigContaRegraSituacoes()"
    ></button>
    <button
            style="margin-left: 2px;"
            pButton
            type="button"
            label="Limpar"
            icon="pi pi-trash"
            class="p-mr-1 p-button-danger"
            (click)="limpar()"
    ></button> 
</div>
<p-table [value]="configContaRegraCriacoes">
    <ng-template pTemplate="header">
        <tr>
            <th title="Código da Conta Corrente">Código</th>
            <th>Nome da Conta Corrente</th>
            <th>Situação da Associação</th>
            <th>Início da Vigência da Associação</th>
            <th>Fim da Vigência da Associação</th>

        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-regra>
        <tr>
            <td>{{regra.configConta?.configContaId}}</td>
            <td>{{regra.configConta?.nomeCompleto}}</td>
            <td>{{regra.situacao}}</td>
            <td>{{utilsService.convertToDateBr(regra.dataInicioVigencia)}}</td>
            <td>{{utilsService.convertToDateBr(regra.dataFimVigencia)}}</td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="12" style="text-align: center">
                Nenhum registro localizado
            </td>
        </tr>
    </ng-template>
</p-table>
<p-paginator [rows]="10" [totalRecords]="pagination.totalElements" (onPageChange)="onPageChange($event)"></p-paginator>


<app-config-conta-search-modal></app-config-conta-search-modal>
