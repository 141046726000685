export class ConfigModelo {
    configContaId: number;
    configContaRegraCriacaoId: number;
    dataFimVigencia?: string;
    dataInicioVigencia?: string;
    regraCriacaoContaId: string;
    situacao: string;

    constructor(init?: Partial<ConfigModelo>) {
        if (init) {
            Object.assign(this, init);
        } else {
            this.configContaId = null;
            this.configContaRegraCriacaoId = null;
            this.dataFimVigencia = null;
            this.dataInicioVigencia = null;
            this.regraCriacaoContaId = null;
            this.situacao = null;
        }
    }
}
