import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EnumsService } from 'src/app/core/enums.service';
import { EventosEstadoService } from 'src/app/component/evento-estado/evento-estado.service';
import { Pagination } from 'src/app/shared/pagination/pagination';
import { AlertsUtil } from 'src/app/utils/alerts/alerts.util';
import swal from "sweetalert2";
@Component({
    selector: "app-evento-estado-list",
    templateUrl: "./evento-estado-list.component.html",
    styleUrls: ["./evento-estado-list.component.scss"],
})
export class EventoEstadoListComponent implements OnInit {
    eventosEstadoList = [];
    blockedDocument = false;
    pagination: Pagination<any>;
    situacaoEnum: any[] = [];
    eventoEstadoId: any = "";
    situacao: any = "";
    descricao: any = "";
    objEstadoParams: { eventoEstadoId?: any; situacao?: any; tipoEvento?: any; descricao?: any };
    tipoEventoEnum: any[];
    tipoEvento: any = "";
    implantadoEnum: any[];

    constructor(
        private eventosEstadoService: EventosEstadoService,
        private enumsService: EnumsService,
        private alertsUtil: AlertsUtil,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.pagination = new Pagination<any>();
        this.init();
    }

    async init() {
        await this.getEnum();
        this.pesquisar();  
    }

    async getEnum() {
        this.situacaoEnum = await this.enumsService.getSituacao().toPromise();
        this.tipoEventoEnum = await this.enumsService.getEnumNome("TipoEvento").toPromise();
        this.implantadoEnum = await this.enumsService.getEnumNome("SimNaoEnum").toPromise();
    }

    getValorEnum(chave, enumName) {
       let valor = enumName.find(e => e.chave === chave) 
       return valor.valor;
    }

    onPageChange(e) {
        this.pagination.pageable.pageNumber = e.page;
        this.pesquisar();
    }

    pesquisar(objEstadoParams?) {
        if (!objEstadoParams) {
            objEstadoParams = this.objEstadoParams;
        }
        this.blockedDocument = true;
        this.eventosEstadoService.getEventoEstado(objEstadoParams, this.pagination).subscribe(
            (resp: any) => {
                this.blockedDocument = false;
                this.pagination = resp;
                this.eventosEstadoList = resp.content;
            },
            (error) => {
                this.alertsUtil.handleError(error);
            }
        );
    }

    pesquisaFiltro() {
        this.blockedDocument = false;
        if (this.situacao === null) {
            this.situacao = "";
        }
        if (this.eventoEstadoId === null) {
            this.eventoEstadoId = "";
        }
        this.objEstadoParams = {
            eventoEstadoId: this.eventoEstadoId,
            situacao: this.situacao,
            descricao: this.descricao,
            tipoEvento: this.tipoEvento
        };
        this.pesquisar(this.objEstadoParams);
    }

    putAtivaInativa(eventoEstadoId, desc, ativaInativa) {
        swal.fire({
            title: "Aviso",
            text: `Deseja ${ativaInativa} o evento ${eventoEstadoId} - ${desc}.`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "SIM",
            cancelButtonText: "NÃO"
        }).then((result) => {
            if (result.isConfirmed) {
                this.eventosEstadoService
                    .putAtivaInativa(eventoEstadoId)
                    .subscribe(
                        (resp) => {
                          this.alertsUtil.saveSuccess('Atualizado');
                          this.objEstadoParams = {};
                          this.pesquisar();
                        },
                        (error) => {
                            this.alertsUtil.handleError(error);
                        }
                    );
            }
        });
    }

    irConfigEvento(eventoEstadoId?){
        if(eventoEstadoId){
            this.router.navigateByUrl(`/config-evento-estado/${eventoEstadoId}`)
        } else {
            this.router.navigateByUrl('/config-evento-estado')
        }
    }

    excluir(i, item) {
        let id = item?.eventoEstadoId;
        if(id){
            swal.fire({
                title: "Aviso",
                text: `Deseja excluir o evento estado ${id} - ${item.descricao}.`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "SIM",
                cancelButtonText: "NÃO"
            }).then((result) => {
                if (result.isConfirmed) {
                    this.eventosEstadoService.deleteEventoEstado(id).subscribe(
                        resp => {
                            swal.fire("Sucesso", "Removido com sucesso", "success").then(
                                () => {
                                    this.eventosEstadoList.splice(i, 1);
                                }
                            );
                        }
                    ), erro =>{
                        this.alertsUtil.handleError(erro)
                    }
                }
            }); 
        } else {
            this.eventosEstadoList.splice(i, 1);
        }
    }

    limpar() {
        this.eventoEstadoId = '';
        this.situacao  = '';
        this.tipoEvento = '';
        this.descricao = '';
        this.objEstadoParams = {};
        this.pesquisar();

    }
}
