import { Atributo } from './atributo';
import { RegraSelecao } from './regra-selecao';


export class RegraParametro {
    regraParamId: string;
    grupo: number;
    operadorRelacional: string;
    operadorLogico: string;
    valor: string;
    regra: RegraSelecao
    atributo: Atributo;
    atributoId: number;

    constructor(init?: Partial<RegraParametro>) {
        if (init) {
            Object.assign(this, init);
        } else {
            this.regra = null;
            this.regraParamId = null;
            this.grupo = null;
            this.operadorRelacional = null;
            this.operadorLogico = null;
            this.valor = null;
        }
    }
}

export enum OperadorRelacionalEnum {
    E = 'E',
    OU = 'OU',
}


export enum OperadorLogicoEnum {
    IGUAL = '=',
    MAIOR = '>',
    MAIOR_IGUAL = '>=',
    MENOR = '<',
    MENOR_IGUAL = '<=',
    DIFERENTE = '<>',
}



