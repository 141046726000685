export class ConfigRegraSelecaoSearchRequest {
    regraId: string;
    descricao: string;
    situacao: string;
    tipoCategoriaId: string;
    tipoCadastroId: string;


    constructor(init?: Partial<ConfigRegraSelecaoSearchRequest>) {
        if (init) {
            Object.assign(this, init);
        } else {
            this.regraId = '';
            this.descricao = '';
            this.situacao = '';
            this.tipoCategoriaId = '';
            this.tipoCadastroId = '';
        }
    }
}
