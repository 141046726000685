<ng-template pTemplate="header">
    <span>Base Legal</span>
</ng-template>

<div class="p-d-flex p-jc-center" *ngIf="!disabled">
    <p-button
        (click)="showBasicDialog()"
        icon="pi pi-search"
        label="Buscar Base Legal"
        [attr.disabled]="beneficioImplantado"
    >
    </p-button>
</div>
<div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-md-12">
        <label>Descrição da Base Legal</label>
        <input
            [value]="baseLegal?.tblDescBaseLegal"
            type="text"
            pInputText
            disabled
        />
    </div>

    <div class="p-field p-col-12 p-md-12">
        <label>Capitulação Legal</label>
        <textarea
            rows="7"
            cols="30"
            pInputTextarea
            [value]="baseLegal?.tblCapitulacionLegal"
            disabled
        ></textarea>
    </div>
    <div class="p-field p-col-6">
        <label>Início da Vigência</label>
        <p-calendar
            dateFormat="dd/mm/yy"
            [disabled]="true"
            [showIcon]="true"
            #dataInicialBaseLegal
        ></p-calendar>
    </div>
    <div class="p-field p-col-6">
        <label>Fim da Vigência</label>
        <p-calendar
            dateFormat="dd/mm/yy"
            [disabled]="true"
            [showIcon]="true"
            #dataFinalBaseLegal
        ></p-calendar>
    </div>
</div>
<app-base-legal-search-modal
    (selectBaseLegal)="changeBaseLegal($event)"
></app-base-legal-search-modal>
