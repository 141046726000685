import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MenuItem } from "primeng/api";
import { ContaCorrenteService } from "src/app/core/conta-corrente.service";
import { Pagination } from "src/app/shared/pagination/pagination";
import { ConfigContaPerfilVisualizacaoTabViewService } from "./config-perfil-visualizacao-tab-view.service";
import { EnumsService } from "src/app/core/enums.service";
import { SituacaoEnum } from "src/app/utils/enums/situacao-enum";
import { AlertsUtil } from "src/app/utils/alerts/alerts.util";
import { PerfisAcesso } from "src/app/models/perfis-acesso";
import { UtilsService } from "src/app/shared/utils.service";
import { ConfigContaId, PerfilVisualizacaoConta } from "src/app/models/perfis-visualizacao";

@Component({
    selector: "app-config-perfil-visualizacao",
    templateUrl: "./config-perfil-visualizacao.component.html",
    styleUrls: ["./config-perfil-visualizacao.component.scss"],
})
export class ConfigPerfilVisualizacaoComponent implements OnInit {
    @Input() configConta;
    pagination: Pagination<any>;

    items: MenuItem[];
    perfis: any[];
    perfil: any;
    dataInicio: any;
    dataFim: any;
    perfilList: PerfisAcesso[];
    situacoes: SituacaoEnum[];
    submitted: boolean;
    configContaId: ConfigContaId;

    btnSaveLabel = "Adicionar Perfil";
    btnSaveIcone = "pi pi-plus-circle";
    codigoPerfilModel: any;

    constructor(
        private router: Router,
        private util: UtilsService,
        private alertUtil: AlertsUtil,
        private enumService: EnumsService,
        private contaCorrenteService: ContaCorrenteService,
        public perfilService: ConfigContaPerfilVisualizacaoTabViewService
    ) {}

    ngOnInit(): void {
        /**Itens timiline */
        this.items = this.contaCorrenteService.getEtapaConta();
        this.pagination = new Pagination<any>();

        /**Carrega obeto conta do sessionStorage */
        const objContaSession = sessionStorage.getItem("objConta");
        if (objContaSession) {
            const objConta = JSON.parse(objContaSession);
            this.configConta = objConta;
        }

        this.getDominiosAndEnum();
        this.getConfigPerfilSearch();
    }

    async getDominiosAndEnum() {

        this.perfilService.perfilVisualizacaoConta.situacao = "ATIVA";

        this.situacoes = await this.enumService.getSituacao().toPromise();
        this.perfilList = await this.perfilService
        .getPerfilKeyCloak()
        .toPromise();
    }

    getConcatenatedLabelPerfil(item: any): string {
        return `${item.codigoPerfil} - ${item.descricao != null ? item.descricao : ""}`;
    }

    getConfigPerfilSearch() {
        const objParams = {
            configContaId: this.configConta.configContaId,
        };
        this.perfilService
            .getPerfilSearch(objParams, this.pagination)
            .subscribe(
                (resp) => {
                    this.pagination = resp;
                },
                (e) => {
                    this.alertUtil.handleError(e);
                }
            );
    }

    onChangePerfil(e) {
        this.pagination.pageable.pageNumber = e.page;
        this.getConfigPerfilSearch();
    }

    adicionaPerfis(perfil?: any) {
        
            this.perfilService.perfilVisualizacaoConta.configConta = {
                configContaId: this.configConta.configContaId,
            };
            if (this.validaEditarESalvar()) {
                if(this.validacaoJaExisteNalista()){
                const data = JSON.parse(
                    JSON.stringify(this.perfilService?.perfilVisualizacaoConta)
                );
                data.codigoPerfil = this.codigoPerfilModel.codigoPerfil;
                if (data.dataInicioVigencia) {
                    data.dataInicioVigencia = this.util.convertToSaveUTC(
                        data.dataInicioVigencia
                    );
                }
                if (data.dataFimVigencia) {
                    data.dataFimVigencia = this.util.convertToSaveUTC(
                        data.dataFimVigencia
                    );
                }
    
                this.perfilService.postPerfil(data).subscribe(
                    (resp) => {
                        this.alertUtil.saveSuccess(`Salvo com sucesso`);
                        this.reseteValues();
                        this.getConfigPerfilSearch();
                    },
                    (e) => {
                        this.alertUtil.handleError(e);
                    }
                    );
                }
        }  
    }
        
    cancelar() {
        this.reseteValues();
    }

    validacaoJaExisteNalista() {
            if (
                this.pagination.content.find(
                    (aux) => aux.codigoPerfil === this.codigoPerfilModel?.codigoPerfil
                )
            ) {
                this.alertUtil.warning(
                    `O perfil com código ${this.codigoPerfilModel?.codigoPerfil}, já existe na lista!`
                );
                return false;
        }
        return true;
    }
        
    reseteValues() {
        this.submitted = false;
        this.btnSaveLabel = "Adicionar Perfil";
        this.btnSaveIcone = "pi pi-plus-circle";
        this.perfilService.perfilVisualizacaoConta =
            new PerfilVisualizacaoConta();
    }

    validaEditarESalvar() {
        this.submitted = true;
        if (this.codigoPerfilModel != null &&
            this.perfilService?.perfilVisualizacaoConta.dataInicioVigencia != null) {
            return true;
        }
        return false;
    }

    ativaInativar(perfil) {
        let msgSituacao = perfil.situacao === "ATIVA" ? "inativar" : "ativar";

        this.alertUtil
            .confirm(
                `
            Deseja ${msgSituacao} o perfil de visualização ${perfil.codigoPerfil}?`
            )
            .then((result) => {
                if (result.isConfirmed) {
                    let msg =
                        perfil.situacao === "ATIVA" ? "Inativado" : "Ativado";
                    this.perfilService
                        .ativaInativaPerfil(perfil.perfilVisualizacaoContaId)
                        .subscribe((resp) => {
                            this.alertUtil
                                .saveSuccess(`${msg} com sucesso`)
                                .then(() => {
                                    this.getConfigPerfilSearch();
                                });
                        });
                }
            });
    }

    delete(perfil) {
        this.alertUtil
            .confirm(
                `
            Deseja excluir o perfil de visualização ${perfil.codigoPerfil}?`
            )
            .then((result) => {
                if (result.isConfirmed) {
                    this.perfilService
                        .deletePerfil(perfil.perfilVisualizacaoContaId)
                        .subscribe((resp) => {
                            this.alertUtil
                                .saveSuccess(`Perfil excluído com sucesso`)
                                .then(() => {
                                    this.getConfigPerfilSearch();
                                });
                        });
                }
            });
    }

    editar(perfil) {
        this.btnSaveIcone = "pi pi-pencil";
        this.btnSaveLabel = "Atualizar Perfil";

        this.perfilService.perfilVisualizacaoConta = JSON.parse(
            JSON.stringify(perfil)
        );
        if (this.perfilService.perfilVisualizacaoConta.dataInicioVigencia) {
            this.perfilService.perfilVisualizacaoConta.dataInicioVigencia =
                this.util.convertToDateBr(
                    this.perfilService.perfilVisualizacaoConta
                        .dataInicioVigencia
                );
        }
        if (this.perfilService.perfilVisualizacaoConta.dataFimVigencia) {
            this.perfilService.perfilVisualizacaoConta.dataFimVigencia =
                this.util.convertToDateBr(
                    this.perfilService.perfilVisualizacaoConta.dataFimVigencia
                );
        }
    }

    prevPage() {
        this.router.navigate(["/unid-organiza"]);
    }
}
