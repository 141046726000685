import {Component, Input, OnInit} from '@angular/core';
import {ConfigContaUnidOrganiza} from '../../../models/config-conta-unid-organiza';
import {UnidadeOrganizacional} from '../../../models/unidade-organizacional';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ConfigContaUnidOrganizaTabViewService} from './config-conta-unid-organiza-tab-view.service';
import {
    UnidadeOrganizacionalSearchModalService
} from '../../unidade-organizacional/unidade-organizacional-search-modal/unidade-organizacional-search-modal.service';
import {UtilsService} from '../../../shared/utils.service';
import * as moment from 'moment';
import {ConfigConta} from '../../../models/config-conta';
import {ConfigContaBaseLegal} from '../../../models/config-conta-base-legal';
import {environment} from '../../../../environments/environment';
import swal from 'sweetalert2';
import {MenuItem, Message} from "primeng/api";
import { Pagination } from 'src/app/shared/pagination/pagination';
import { UnidadeOrganizacionalSearchRequest } from 'src/app/request/unidade-organizacional-search-request';
import { Router } from '@angular/router';
import { ContaCorrenteService } from 'src/app/core/conta-corrente.service';
import { AlertsUtil } from 'src/app/utils/alerts/alerts.util';

@Component({
    selector: "app-config-conta-unid-organiza-tab-view",
    templateUrl: "./config-conta-unid-organiza-tab-view.component.html",
    styleUrls: ["./config-conta-unid-organiza-tab-view.component.scss"],
    providers: [],
})
export class ConfigContaUnidOrganizaTabViewComponent implements OnInit {
    blockedDocument = false;
    situacoes: any[];
    @Input() configConta;
    configContaUnidOrganizas: ConfigContaUnidOrganiza[] = [];
    httpOptions = {
        headers: new HttpHeaders({
            "Content-Type": "application/json",
        }),
    };
    msgs1: Message[];
    pagination: Pagination<any>;
    searchRequest: UnidadeOrganizacionalSearchRequest;
    items: MenuItem[];

    editar: boolean = false;
    submitted: boolean;
    configUnidOrganiza: ConfigContaUnidOrganiza;
    isLoading = false;

    constructor(
        private http: HttpClient,
        public service: ConfigContaUnidOrganizaTabViewService,
        private searchModalService: UnidadeOrganizacionalSearchModalService,
        private utils: UtilsService,
        private router: Router,
        private contaCorrenteService: ContaCorrenteService,
        private alertsUtil: AlertsUtil
    ) {}

    ngOnInit(): void {
        this.service.getSituacoes().subscribe((r) => {
            this.situacoes = r;
        });
        this.searchRequest = new UnidadeOrganizacionalSearchRequest();
        this.pagination = new Pagination<any>();
        this.configUnidOrganiza = this.contaCorrenteService.getConfigUnidOraganizalInformtation();
        this.items = this.contaCorrenteService.getEtapaConta();
        this.configConta = this.contaCorrenteService.getContaInformtation();
        this.contaCorrenteService.configContaUnidOrganiza = this.service.configContaUnidOrganiza
        if (this.configConta.configContaId !== null) {
            this.init();
        }
        const objContaSession = sessionStorage.getItem('objConta');
        if (objContaSession) {
            const objConta = JSON.parse(objContaSession);
            this.configConta = objConta;
        }
    }

    validaEditarESalvar() {
        this.submitted = true;
        if (
            this.service.configContaUnidOrganiza.unidadeOrganizacional
                .unidadeOrganizacionalId &&
            this.service.configContaUnidOrganiza.situacao &&
            this.service.configContaUnidOrganiza.dataInicioVigencia
        ) {
            return true;
        }
        return false;
    }

    salvarContaAll() {
        this.contaCorrenteService.configContaUnidOrganiza.configConta = new ConfigConta();
        this.contaCorrenteService.configContaUnidOrganiza = this.service.configContaUnidOrganiza
        if (this.validaEditarESalvar()) {
            this.isLoading = true;
            let lengtDate = this.service.configContaUnidOrganiza.dataInicioVigencia?.length;
            if(lengtDate != 10) {
                this.preparaDatas();
            }
            this.contaCorrenteService.complete();
            this.submitted = true;
            setTimeout(() => {
                this.isLoading = false;
            }, 3000)
        }
    }

    preparaDatas() {

        this.contaCorrenteService.configContaSaveAllRequest.configContaSaveRequest = this.contaCorrenteService.configConta;
        // this.contaCorrenteService.configContaSaveAllRequest.configContaSaveRequest.dataInicioVigencia =
        // this.convertDataToSave(this.contaCorrenteService.configContaSaveAllRequest.configContaSaveRequest.dataInicioVigencia);
        // this.contaCorrenteService.configContaSaveAllRequest.configContaSaveRequest.dataFimVigencia =
        // this.convertDataToSave(this.contaCorrenteService.configContaSaveAllRequest.configContaSaveRequest.dataFimVigencia);

        this.contaCorrenteService.configConta = this.converteDataConfigContaCorrente();
        this.contaCorrenteService.configComposicaoConta = this.converteDataConfigComosicao();
        this.contaCorrenteService.configModelo = this.converteDataConfigModelo();
        this.contaCorrenteService.configReceita = this.converteDataConfigReceita();
        this.contaCorrenteService.configContaBaseLegal = this.converteDataConfigBaseLegal();
        this.contaCorrenteService.configContaUnidOrganiza = this.converteDataConfigUnidOrganiza();
    }

    convertDataToSave(date: any) {
        if(date != null && date != "") {
            const data = JSON.parse(
                JSON.stringify(date)
            );
            return this.utils.convertDateToSave(data);
        }
        return null;
    }
    converteDataConfigContaCorrente() {
        const data = JSON.parse(
            JSON.stringify(this.contaCorrenteService.configConta)
        );
        data.dataInicioVigencia = this.utils.convertDateToSave(
            this.contaCorrenteService.configConta.dataInicioVigencia
        );
        data.dataFimVigencia = this.utils.convertDateToSave(
            this.contaCorrenteService.configConta.dataFimVigencia
        );
        return data;
    }
    converteDataConfigComosicao() {
        const data = JSON.parse(
            JSON.stringify(this.contaCorrenteService.configComposicaoConta)
        );
        data.dataInicioVigencia = this.utils.convertDateToSave(
            this.contaCorrenteService.configComposicaoConta.dataInicioVigencia
        );
        data.dataFimVigencia = this.utils.convertDateToSave(
            this.contaCorrenteService.configComposicaoConta.dataFimVigencia
        );
        return data;
    }
    converteDataConfigModelo() {
        const data = JSON.parse(
            JSON.stringify(this.contaCorrenteService.configModelo)
        );
        data.dataInicioVigencia = this.utils.convertDateToSave(
            this.contaCorrenteService.configModelo.dataInicioVigencia
        );
        return data;
    }
    converteDataConfigReceita() {
        const data = JSON.parse(
            JSON.stringify(this.contaCorrenteService.configReceita)
        );
        data.dataInicioVigencia = this.utils.convertDateToSave(
            this.contaCorrenteService.configReceita.dataInicioVigencia
        );
        data.dataFimVigencia = this.utils.convertDateToSave(
            this.contaCorrenteService.configReceita.dataFimVigencia
        );
        return data;
    }
    converteDataConfigBaseLegal() {
        const data = JSON.parse(
            JSON.stringify(this.contaCorrenteService.configContaBaseLegal)
        );
        data.dataInicioVigencia = this.utils.convertDateToSave(
            this.contaCorrenteService.configContaBaseLegal.dataInicioVigencia
        );
        data.dataFimVigencia = this.utils.convertDateToSave(
            this.contaCorrenteService.configContaBaseLegal.dataFimVigencia
        );
        return data;
    }
    converteDataConfigUnidOrganiza() {
        const data = JSON.parse(
            JSON.stringify(this.contaCorrenteService.configContaUnidOrganiza)
        );
        data.dataInicioVigencia = this.utils.convertDateToSave(
            this.contaCorrenteService.configContaUnidOrganiza.dataInicioVigencia
        );
        data.dataFimVigencia = this.utils.convertDateToSave(
            this.contaCorrenteService.configContaUnidOrganiza.dataFimVigencia
        );
        return data;
    }

    prevPage() {
        this.router.navigate(["/config-base-legal"]);
    }

    nextPage() {
        this.router.navigate(["/perfil-visualizacao"]);
    }

    init() {
        this.getConfigContaUnidOrganizaSearch();
    }

    onPageChange(e) {
        this.pagination.pageable.pageNumber = e.page;
        this.getConfigContaUnidOrganizaSearch();
    }

    searchModal() {
        this.searchModalService.displayMaximizable = true;
    }

    save() {
        if (this.validaEditarESalvar()) {
            const data = JSON.parse(
                JSON.stringify(this.service.configContaUnidOrganiza)
            );
            data.configConta = new ConfigConta();
            data.configConta.configContaId = this.configConta.configContaId;
            data.dataInicioVigencia = this.utils.convertDateToSave(
                this.service.configContaUnidOrganiza.dataInicioVigencia
            );
            if(this.service.configContaUnidOrganiza.dataFimVigencia != "") {
                data.dataFimVigencia = this.utils.convertDateToSave(
                    this.service.configContaUnidOrganiza.dataFimVigencia
                );
            }
            this.blockedDocument = true;
            this.http
                .post<ConfigContaBaseLegal>(
                    `${environment.baseUrl}/config-conta-unid-organiza`,
                    JSON.stringify(data),
                    this.httpOptions
                )
                .subscribe(
                    (response) => {
                        this.blockedDocument = false;
                        this.editar = false;
                        this.service.configContaUnidOrganiza.configContaUnidOrganizaId =
                        null;
                        this.service.configContaUnidOrganiza.dataInicioVigencia =
                        null;
                        this.service.configContaUnidOrganiza.dataFimVigencia =
                        null;
                        this.service.configContaUnidOrganiza.unidadeOrganizacional =
                            new UnidadeOrganizacional();
                        swal.fire(
                            "Sucesso",
                            "Salvo com sucesso",
                            "success"
                        ).then((r) => {
                            this.getConfigContaUnidOrganiza();
                        });
                        this.submitted = false;
                    },
                    (error) => {
                        this.submitted = false;
                        this.blockedDocument = false;
                        this.alertsUtil.handleError(error);
                    }
                );
        }
    }

    onSelectDataInicioVigencia(event) {
        let dataVigenciaConta = moment(this.configConta.dataInicioVigencia, 'DD/MM/YYYY').format("YYYY-MM-DD");
        
        let dataInicio = moment(event).format("YYYY-MM-DD");
        if(dataInicio < dataVigenciaConta){
            swal.fire({
                title: 'A data não pode ser menor que a data da vigência da conta',
                icon: 'warning',
                showCancelButton: true,
                // confirmButtonText: 'SIM',
                // cancelButtonText: 'NÃO'
            }).then((result) => {
                this.service.configContaUnidOrganiza.dataInicioVigencia = null;
            });
        }
           if (event) {
               this.service.configContaUnidOrganiza.dataInicioVigencia =
                   moment(event).format("DD/MM/YYYY");
           } else {
               this.service.configContaUnidOrganiza.dataInicioVigencia = "";
           }
    }


    onSelectDataFimVigencia(event) {
        if (event) {
            this.service.configContaUnidOrganiza.dataFimVigencia =
                moment(event).format("DD/MM/YYYY");
        } else {
            this.service.configContaUnidOrganiza.dataFimVigencia = "";
        }
    }

    getConfigContaUnidOrganiza() {
        this.blockedDocument = true;
        this.http
            .get<Array<any>>(
                `${environment.baseUrl}/config-conta-unid-organiza/get-by-config-conta/${this.configConta.configContaId}`
            )
            .subscribe((r) => {
                this.blockedDocument = false;
                this.configContaUnidOrganizas = r;
            });
    }

    edit(configContaUnidOrganiza: ConfigContaUnidOrganiza) {
        this.editar = true;
        this.service.configContaUnidOrganiza = JSON.parse(
            JSON.stringify(configContaUnidOrganiza)
        );
        this.service.configContaUnidOrganiza.dataInicioVigencia =
            this.utils.convertDateToEdit(
                this.service.configContaUnidOrganiza.dataInicioVigencia
            );
        this.service.configContaUnidOrganiza.dataFimVigencia =
            this.utils.convertDateToEdit(
                this.service.configContaUnidOrganiza.dataFimVigencia
            );
    }

    ativaInativar(configContaUnidOrganiza: ConfigContaUnidOrganiza) {
            let msgSituacao = configContaUnidOrganiza.situacao === "ATIVA" ? "inativar" : "ativar"
            swal.fire({
                title: 'Confirmação',
                text: `Deseja ${msgSituacao} a unidade da conta corrente?`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não'
            }).then((result) => {
                if (result.isConfirmed) {
                    let msg = configContaUnidOrganiza.situacao === "ATIVA" ? "Inativado" : "Ativado"
                    this.service.ativaInativaUnidOrganiza(configContaUnidOrganiza.configContaUnidOrganizaId)
                        .subscribe(r => {
                            swal.fire('Sucesso', `${msg} com sucesso`, 'success').then( r => {
                                this.getConfigContaUnidOrganizaSearch();
                            });
                        });
                }
            });
    }

    cancel() {
        this.submitted = false;
        this.editar = false;
        this.service.configContaUnidOrganiza.unidadeOrganizacional =
            new UnidadeOrganizacional();
        this.service.configContaUnidOrganiza.configContaUnidOrganizaId = null;
        this.service.configContaUnidOrganiza.dataInicioVigencia = null;
        this.service.configContaUnidOrganiza.dataFimVigencia = null;
    }

    getConfigContaUnidOrganizaSearch() {
        const data = JSON.parse(JSON.stringify(this.searchRequest));
        data.configContaId =
            this.configConta.configContaId === null
                ? ""
                : this.configConta.configContaId;
        this.service
            .getConfigContaUnidOrganizaSearch(data, this.pagination)
            .subscribe((response) => {
                this.configContaUnidOrganizas = response.content;
                this.pagination = response;
            });
    }

    delete(item){
        swal.fire({
            title: "Confirmação",
            text: `Deseja excluir a unidade organizacional ${item.unidadeOrganizacional.descricao}.`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "SIM",
            cancelButtonText: "NÃO"
        }).then((result) => {
            if (result.isConfirmed) {
                this.service
                    .deleteConfigUnidadeOrganiza(item.configContaUnidOrganizaId)
                    .subscribe((resp) => {
                        swal.fire("Sucesso", "Excluído com sucesso", "success").then(
                            () => {
                                this.getConfigContaUnidOrganizaSearch();
                            }
                        );
                    });
            }
        });
    }

    handleError(error: any): void {
        if (error.status === 500) {
            swal.fire(
                error.statusText,
                "Ocorreu um erro interno",
                "error"
            ).then((r) => {});
        } else if (error.status === 412) {
            swal.fire({
                title: "Atenção",
                text: `${error.error.message}`,
                icon: "warning",
            });
        } else if (error.status === 400) {
            const errors = error.error.errors;
            this.msgs1 = [];
            errors.forEach((e: { message: any }) => {
                this.msgs1.push({
                    severity: "error",
                    summary: "Erro",
                    detail: e.message,
                });
            });
        } else {
            swal.fire({
                title: "ERROR",
                text: `${error.error.message}`,
                icon: "error",
            });
        }
    }
}
