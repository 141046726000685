<p-table [value]="veiculosListSearch || []" dataKey="nome">
    
    <ng-template pTemplate="header">
        <tr>
            <th >  </th>
            <th pSortableColumn="renavam">Renavam <p-sortIcon field="name"></p-sortIcon></th>
            <th pSortableColumn="chassi">Chassi<p-sortIcon field="price"></p-sortIcon></th>
            <th pSortableColumn="placa">Placa <p-sortIcon field="category"></p-sortIcon></th>
            <th pSortableColumn="marcaModelo">Marca Modelo <p-sortIcon field="rating"></p-sortIcon></th>
            <th pSortableColumn="anoModelo">Ano modelo <p-sortIcon field="rating"></p-sortIcon></th>
            <th > Ação </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-veiculo let-expanded="expanded" >
        <tr>
            <td>
                <button type="button" pButton pRipple [pRowToggler]="veiculo" (click)="searchDetails(veiculo);"  class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
            </td>
            
            <td>{{veiculo.renavam}}</td>
            <td>{{veiculo.chassi}}</td>
            <td>{{veiculo.placa }}</td>
            <td>{{veiculo.marcaModelo}}</td>
            <td>{{veiculo.anoModelo}}</td>
   
           <td>
            <p-button icon="pi pi-search" (click)="op.toggle($event);"></p-button>
            <p-button
            icon="pi pi-external-link" 
            (click)="goToExtratoContaCorrente(veiculo)"
            pTooltip="Extrato conta"
            ></p-button>
            </td> 

            <p-overlayPanel #op   [style]="{width: '450px;'}">
                <ng-template pTemplate>
                    <p-table   selectionMode="single" [value]="[veiculo]"  responsiveLayout="scroll">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Nome</th>
                                <th>Bairro</th>
                                <th>Endereço</th>
                                <th>Ano Modelo</th>
                                <th>Ano Fabricação</th>
                                <th>Logradouro</th>                                              
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-veiculo  >
                            <tr [pSelectableRow]="rowData">
                                <td>{{veiculo.nome}}</td>
                                <td>{{veiculo.bairro}}</td>
                                <td>{{veiculo.endereco}}</td>
                                <td>{{veiculo.anoModelo}}</td>
                                <td>{{veiculo.anoFabricacao}}</td>
                                <td>{{veiculo.logradouro}}</td>
                               
                            </tr>
                        </ng-template>
                    </p-table>
                </ng-template>
            </p-overlayPanel>
          
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-detailsContribuinte>
        <tr>
            <td colspan="7">
                <div class="p-p-3">
                    <p-table [value]="[detailsVeiculo] || []" dataKey="cpfcnpj">
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="corVeiculo">Cor veículo <p-sortIcon field="price"></p-sortIcon></th>
                                <th pSortableColumn="dataLancamento">Data de lançamento <p-sortIcon field="customer"></p-sortIcon></th>
                                <th pSortableColumn="uf">UF <p-sortIcon field="date"></p-sortIcon></th>
                                <th pSortableColumn="cilindrada">Cilindrada<p-sortIcon field="amount"></p-sortIcon></th>
                                <th style="width: 4rem"></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body"  let-detailsVeiculo  > 
                            <tr>
                                <td>{{detailsVeiculo?.cor}}</td>
                                <td>{{detailsVeiculo?.dataLancamento}}</td>
                                <td>{{detailsVeiculo?.uf}}</td>
                                <td>{{detailsVeiculo?.cilindrada }}</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="6">There are no order for this product yet.</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>

<p-paginator [rows]="10" [totalRecords]="pagination.totalElements" (onPageChange)="onPageChange($event)">
</p-paginator>