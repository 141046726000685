export class ConfigReceita {
    configConta: number
    configContaId: number
    configContaReceitaId: number
    dataFimVigencia: string
    dataInicioVigencia: string
    receita?: any
    subreceita?: any
    situacao: string

    constructor(init?: Partial<ConfigReceita>) {
        if (init) {
            Object.assign(this, init);
        } else {
            this.configConta = null
            this.configContaId = null
            this.configContaReceitaId = null
            this.dataFimVigencia = null
            this.dataInicioVigencia = null
            this.receita = null
            this.subreceita = null
            this.situacao = null
        }
    }
}
