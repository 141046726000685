export class RegraCriacaoSearchRequest {
    regraCriacaoContaId: string;
    descricaoResumida: string;
    descricaoCompleta: string;
    situacao: string;
    dataCriacao: string;
    tributoId: string;
    tributoIdIsNull: boolean;

    constructor() {
        this.regraCriacaoContaId = '';
        this.descricaoResumida = '';
        this.descricaoCompleta = '';
        this.situacao = '';
        this.dataCriacao = '';
        this.tributoId = '';
        this.tributoIdIsNull = false;
    }
}
