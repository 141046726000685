 <!-- //fará parte do componente -->
 <p-panelMenu [model]="items"></p-panelMenu>
 <p-card 
    *ngIf="showExtratoVeiculos"
    [style]="{'margin-top': '10px'}">

    <p-table [value]="contaExtratoVeiculo" dataKey="nomeResumido">
        <ng-template pTemplate="body" let-conta let-expanded="expanded">
          <tr>
            <td>
              <button
                type="button"
                pButton
                pRipple
                [pRowToggler]="conta"
                class="p-button-text p-button-rounded p-button-plain"
                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
              ></button>
            </td>
            <td colspan="5">Conta: {{conta.nomeResumido}}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-conta>
          <tr>
            <td colspan="7">
              <div class="p-p-3">
                <p-table [value]="conta.contas" dataKey="parcela">
                  <ng-template pTemplate="header">
                    <tr>
                      <th>
                        Nº Docto
                      </th>
                      <th>
                        Ano
                      </th>
                      <th pSortableColumn="parcela">
                        Parcela <p-sortIcon field="parcela"></p-sortIcon>
                      </th>
                      <th>
                        Vencimento 
                      </th>
                      <th>
                        Principal
                      </th>
                      <th>
                        Juros
                      </th>
                      <th>
                        Multa
                      </th>
                      <!-- <th>
                        Desconto
                      </th> -->
                      <th >
                        Total 
                      </th>
                      <th >
                        Estado 
                      </th>
                      <th>Ação</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-order>
                    <tr>
                        <td>{{order.numeroDocumento}}</td>
                        <td>{{substringAno(order.periodoReferencia)}}</td>
                        <td>{{order.parcela}}</td>
                        <td>{{order.dataVencimento | date: 'dd/MM/yyyy'}}</td>
                        <td>{{verificaNaturezaItemCalculoPrincipal(order.contaCorrenteFinanceiros)}}</td>
                        <td>{{verificaNaturezaItemCalculoJuros(order.contaCorrenteFinanceiros)}}</td>
                        <td>{{verificaNaturezaItemCalculoMulta(order.contaCorrenteFinanceiros)}}</td>
                        <!-- <td>??</td> -->
                        <td>{{order.saldoTotalAtualizado}}</td>
                        <td>{{order.estadoConta.descricao}}</td>
                        <td>
                            <button
                            pButton
                            pRipple
                            title="Editar"
                            type="button"
                            icon="pi pi-pencil"
                            class="p-button-rounded p-button botao-editar"
                            [disabled]="true"
                            (click)="detalheTransacaoAndEventos()"
                            >
                            </button>
                        </td>
                    </tr>
                 
                  </ng-template>
                  <ng-template pTemplate="footer" let-order>
                    <tr>
                        <td colspan="4" class="text-right">Total (R$)</td>
                        <td>{{conta.principal | currency: 'BRL'}}</td>
                        <td>{{conta.juros | currency: 'BRL'}}</td>
                        <td>{{conta.multa | currency: 'BRL'}}</td>
                        <td colspan="3">{{conta.total | currency: 'BRL'}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="12" style="text-align: center">
                            Nenhum registro localizado
                        </td>
                    </tr>
                </ng-template>
                </p-table>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
     <p-paginator 
        [rows]="10" 
        [totalRecords]="pagination.totalElements" 
        (onPageChange)="onPageChangeVeiculos($event)"
    ></p-paginator>
 </p-card>
 <!-- <p-panel *ngIf="showTransacoesAndEventos" header="Tributo: IPVA - Conta: IPVA - Nº Docto: 12345 - Ano: 2022 - Parcela: 0" [toggleable]="true">
    <p-tabView>
        <p-tabPanel header="Transações">
            <p-card>
                <p-table [value]="conta">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Data</th>
                            <th>Tipo Transação</th>
                            <th>Tipo Fato</th>
                            <th>Natureza Evento</th>
                            <th>Descrição</th>
                            <th>Origem</th>
                            <th>Tipo Origem</th>
                            <th>Tributo</th>
                            <th>Valor</th>
                            <th>Saldo</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-item>
                        <tr>
                            <td>{{item.numero}}</td>
                            <td>item</td>
                            <td>item</td>
                            <td>item</td>
                            <td>item</td>
                            <td>item</td>
                            <td>item</td>
                            <td>item</td>
                            <td>item</td>
                            <td>item</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="12" style="text-align: center">Nenhum registro localizado</td>
                        </tr>
                    </ng-template>
                </p-table>
                <p-paginator 
                    [rows]="10" 
                    [totalRecords]="pagination.totalElements" 
                    (onPageChange)="onPageChangeTransacao($event)"
                ></p-paginator>
            </p-card>
        </p-tabPanel>
        <p-tabPanel header="Eventos">
            <p-card>
                <p-table [value]="conta">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Data</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-item>
                        <tr>
                            <td>01/01/2022</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="12" style="text-align: center">Nenhum registro localizado</td>
                        </tr>
                    </ng-template>
                </p-table>
                <p-paginator 
                    [rows]="10"
                    [totalRecords]="pagination.totalElements"
                    (onPageChange)="onPageChangeEventos($event)"
                ></p-paginator>
            </p-card>
        </p-tabPanel>
    </p-tabView>
</p-panel> -->
