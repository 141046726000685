<div class="p-grid">
    <div class="p-col-12">
        <p-card>
            <p-steps [model]="items" [activeIndex]="0"></p-steps>
        </p-card>
    </div>
    <br />
    <div class="p-col-12">
        <p-card>
            <div class="grid grid-nogutter justify-content-end margin-bt">
                <button
                    pButton
                    label="Voltar"
                    icon="pi pi-angle-left"
                    class="p-button-outlined p-mr-2"
                    (click)="voltar()"
                ></button>
                <button
                    pButton
                    class="float-btn-next"
                    label="Próxima"
                    icon="pi pi-angle-right"
                    iconPos="right"
                    [disabled]="valor == null"
                    (click)="confirmar()"
                ></button>
            </div>

            <ng-template pTemplate="title"> Selecionar Categoria </ng-template>

            <div class="p-formgrid p-grid" style="margin-top: 20px">
                <div class="p-field p-col-12">
                    <label>Categoria *</label><br />
                    <p-dropdown
                        [options]="eventos"
                        [(ngModel)]="valor"
                        optionLabel="label"
                        optionValue="value"
                    >
                    </p-dropdown>
                </div>
            </div>
        </p-card>
    </div>
</div>
