<form [formGroup]="formGroupReducaoPunitiva" (change)="onChange()">
    <div class="p-fluid p-formgrid p-grid p-mt-4">
        <div class="p-field p-col-4">
            <label>Momento da Incidência*</label>
            <p-dropdown
                formControlName="tipoDataRefereMomenInicid"
                [options]="tiposDatasReferenciaAcontarLista"
                [optionLabel]="'descricao'"
                [dataKey]="'descricao'"
                (onChange)="onChangeMomentoIncidencia($event)"
            >
            </p-dropdown>
        </div>
        <div class="p-field p-col-4">
            <label>Há Perda do Benefício? *</label>
            <p-dropdown
                optionLabel="valor"
                formControlName="haPerdaBeneficio"
                [autoDisplayFirst]="false"
                [options]="perdaBeneficioEnum"
                (onChange)="onChangePerdaBeneficio($event.value)"
            >
            </p-dropdown>
        </div>
    </div>

    <div class="p-field p-col-12 p-md-12">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-12" style="padding: 0;">
                <p-fieldset legend="A redução incide até"
                            *ngIf="indicaQualAbaExibir === 2 || indicaQualAbaExibir === 3 || indicaQualAbaExibir === 4">
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-4 p-md-4" style="padding-left: 0;"
                             *ngIf="indicaQualAbaExibir === 2 || indicaQualAbaExibir === 3">
                            <label>Qtd Dias *</label>
                            <p-inputNumber
                                formControlName="qtdDias"
                                maxlength="3"
                                onkeypress="return event.charCode >= 48"
                            ></p-inputNumber>
                            <p
                                class="erro-validacao"
                                *ngIf="reducaoPunitivaFormControl.qtdDias.errors?.required"
                            >
                                Campo obrigatório
                            </p>
                        </div>

                        <div class="p-field p-col-4 p-md-4  p-pl-6"
                             *ngIf="indicaQualAbaExibir === 2 || indicaQualAbaExibir === 3 || indicaQualAbaExibir === 4">
                            <label>Contagem em *</label>
                            <p-dropdown
                                formControlName="tipoContagem"
                                [options]="tiposContagemLista"
                                [optionLabel]="'descricao'"
                                [dataKey]="'descricao'"
                                (onChange)="onChangeContagemEm($event)"

                            >
                            </p-dropdown>
                        </div>
                        <div class="p-field p-col-4 p-md-4  p-pl-6"
                             *ngIf="indicaQualAbaExibir === 2 || indicaQualAbaExibir === 3 || indicaQualAbaExibir === 4">
                            <label>A contar da *</label>
                            <p-dropdown
                                formControlName="tipoDataReferenciaAcontar"
                                [options]="tiposDatasReferenciaAcontarLista"
                                [optionLabel]="'descricao'"
                                [dataKey]="'descricao'"
                                (onChange)="onChangeAcontarDa($event)"
                            >
                            </p-dropdown>
                        </div>
                    </div>
                </p-fieldset>
            </div>

            <div class="p-field p-col-12" style="display: flex; flex-direction: row;">
                <div class="p-field p-col-6" style="padding-left: 0;"
                     *ngIf="indicaQualAbaExibir === 1 || indicaQualAbaExibir === 2 || indicaQualAbaExibir === 3 || indicaQualAbaExibir === 4">
                    <p-fieldset legend="Tipo do valor da Redução *">
                        <div style="display: flex; justify-content: space-between;">
                            <p-radioButton (onClick)="onClickTipoValorReducao(1)"
                                           name="tipoValorReducao" inputId="FIXO" label="Fixo" value="FIXO"
                                           formControlName="tipoValorReducao"></p-radioButton>
                            <p-radioButton (onClick)="onClickTipoValorReducao(2)" name="tipoValorReducao"
                                           label="Percentual" inputId="PERCENTUAL" value="PERCENTUAL"
                                           formControlName="tipoValorReducao"></p-radioButton>
                        </div>

                    </p-fieldset>
                </div>

                <div class="p-col-4 p-field" style="justify-content: end; display: flex; flex-direction: column;"
                     *ngIf="indicaQualAbaExibir === 1 || indicaQualAbaExibir === 2 || indicaQualAbaExibir === 3">
                    <label>{{rotuloReducao}} *</label>
                    <p-inputNumber
                    *ngIf="mode"
                    formControlName="valorReducao"
                    maxlength="15"
                    [mode]="mode"
                    [currency]="currency"
                    [locale]="locale"
                    [maximizable]="true"
                    [baseZIndex]="90"
                    [draggable]="false"
                    [resizable]="false"
                    (onBlur)="onChangeValorReducao()"
                  ></p-inputNumber>
                  <p-inputNumber
                    suffix="%"
                    maxlength="4"
                    [max]="100"
                    *ngIf="!mode"
                    step="0,01"
                    value="0,01"
                    min="0,01"
                    max="100,00"
                    formControlName="valorReducao"
                    (onBlur)="onChangeValorReducao()"
                  ></p-inputNumber>
                    <p
                        class="erro-validacao"
                        *ngIf="erroLimiteReducao"
                    >
                        {{msgErroReducao}}
                    </p>
                    <p
                        class="erro-validacao"
                        *ngIf="(reducaoPunitivaFormControl.valorReducao.touched ||
                            submitted) &&
                            reducaoPunitivaFormControl.valorReducao.errors?.required"
                        >
                        Campo obrigatório
                    </p>
                </div>
                <div class="p-col-6 p-field" *ngIf="indicaQualAbaExibir === 4">
                    <div class="p-mb-3" style="text-align: right;">
                        <button style="width: 210px;" pButton pRipple title="Adicionar" label="Adicionar nova Redução"
                                type="button" icon="pi pi-plus-circle" class="p-button p-button p-mr-2 p-text-left"
                                (click)="adicionarReducaoBotao()"></button>
                    </div>

                    <p-table [value]="regraReducaoProgressivaLista().controls">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Até Quantidade Dia</th>
                                <th>{{rotuloReducao}}</th>
                                <th>Ações</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-bl let-rowData let-rowIndex="rowIndex">
                            <ng-container formArrayName="regraReducaoProgressivaVariavelLista">
                                <tr [formGroupName]="rowIndex">
                                    <td>
                                        <p-inputNumber
                                        min="0"
                                        max="999"
                                        maxlength="3"
                                        formControlName="qtdDiasFinal"
                                        value="qtdDiasFinal"
                                        onkeypress="return event.charCode >= 48">
                                        </p-inputNumber>
                                    </td>
                                    <td>
                                        <p-inputNumber
                                        *ngIf="mode"
                                        formControlName="valorReducao"
                                        maxlength="15"
                                        [mode]="mode"
                                        [currency]="currency"
                                        [locale]="locale"
                                        [maximizable]="true"
                                        [baseZIndex]="90"
                                        [draggable]="false"
                                        [resizable]="false"
                                        (onBlur)="validaOrdemDaQuantidadeDiasNosCamposTabela()"
                                      ></p-inputNumber>
                                      <p-inputNumber
                                        suffix="%"
                                        maxlength="4"
                                        [max]="100"
                                        *ngIf="!mode"
                                        step="0,01"
                                        value="0,01"
                                        min="0,01"
                                        max="100,00"
                                        formControlName="valorReducao"
                                        (onBlur)="validaOrdemDaQuantidadeDiasNosCamposTabela()"
                                      ></p-inputNumber>
                                    <td>
                                        <button pButton pRipple title="Excluir" type="button" icon="pi pi-trash"
                                                class="p-button-rounded p-button-danger"
                                                (click)="removeReducao(rowIndex)"></button>
                                    </td>
                                </tr>
                            </ng-container>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td class="p-text-center" colspan="6">
                                    Nenhum registro localizado
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>

                    <p
                        class="erro-validacao"
                        *ngIf="erroQtdDiaTabela"
                    >
                        A quantidade de dias deve estar em ordem crescente
                    </p>
                    <p
                        class="erro-validacao"
                        *ngIf="erroValorReducaoTabela"
                    >
                        Os valores das reduções devem estar em ordem decrescente
                    </p>
                    <p
                        class="erro-validacao"
                        *ngIf="erroLimiteReducao"
                    >
                        {{msgErroReducao}}
                    </p>
                    <p
                        class="erro-validacao"
                        *ngIf="regraReducaoProgressivaLista().controls?.length === 0 || erroCamposObrigatoriosTabela"
                    >
                        Preenchimento obrigatório
                    </p>

                </div>
            </div>

            <div class="p-col-4 p-md-3 p-field" *ngIf="indicaQualAbaExibir === 3"
                 style="justify-content: end; display: flex; flex-direction: column;">
                <label>{{rotuloDecrescimo}} *</label>
                <!-- <input
                    min="0"
                    type="number"
                    pInputText=""
                    formControlName="valorDecrescimo"
                /> -->
                <p-inputNumber
                    *ngIf="mode"
                    formControlName="valorDecrescimo"
                    maxlength="15"
                    [mode]="mode"
                    [currency]="currency"
                    [locale]="locale"
                    [maximizable]="true"
                    [baseZIndex]="90"
                    [draggable]="false"
                    [resizable]="false"
                  ></p-inputNumber>
                <p-inputNumber
                    suffix="%"
                    maxlength="4"
                    [max]="100"
                    *ngIf="!mode"
                    step="0,01"
                    value="0,01"
                    min="0,01"
                    max="100,00"
                    formControlName="valorDecrescimo"
                  ></p-inputNumber>
                <p
                    class="erro-validacao"
                    *ngIf="erroLimiteDecrescimo"
                >
                    {{msgErroDecrescimo}}
                </p>
                <p
                    class="erro-validacao"
                    *ngIf="reducaoPunitivaFormControl.valorDecrescimo.errors?.required"
                >
                    Campo obrigatório
                </p>
            </div>
            <div class="p-col-4 p-md-3 p-field" *ngIf="indicaQualAbaExibir === 3">
                <label>Quantidade Dias Subsequentes *</label>
                <p-inputNumber
                    min="0"
                    max="999"
                    maxlength="3"
                    formControlName="qtdDiasSubSequente"
                    onkeypress="return event.charCode >= 48"
                >
                </p-inputNumber>
                <p
                    class="erro-validacao"
                    *ngIf="reducaoPunitivaFormControl.qtdDiasSubSequente.errors?.required"
                >
                    Campo obrigatório
                </p>
            </div>
        </div>
    </div>
</form>
