<div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col">
        <p-table
            selectionMode="single"
            [value]="contaList"
            responsiveLayout="scroll"
            styleClass="p-datatable-gridlines"
        >
            <ng-template pTemplate="caption">
                <div class="table-header p-text-center">{{tituloValorLancado}}</div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>Descricao</th>
                    <th>Total Lançado</th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-c>
                <tr>
                    <td>Principal</td>
                    <td [ngClass]="{'saldo-negativo': maior(c?.valorLancadoPrincipal) }">{{ c?.valorLancadoPrincipal | currency: 'BRL' }}</td>
                </tr>
                <tr>
                    <td>Multa de Mora</td>
                    <td [ngClass]="{'saldo-negativo': maior(c?.valorLancadoMulta) }">{{ c?.valorLancadoMulta | currency: 'BRL' }}</td>
                </tr>
                <tr>
                    <td>Juros de Mora</td>
                    <td [ngClass]="{'saldo-negativo': maior(c?.valorLancadoJuros) }">{{ c?.valorLancadoJuros | currency: 'BRL' }}</td>
                </tr>
                <tr>
                    <td>Multa por Infração</td>
                    <td [ngClass]="{'saldo-negativo': maior(c?.valorLancadoMultaInfra) }">{{ c?.valorLancadoMultaInfra | currency: 'BRL' }}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="footer">
                <tr>
                    <td>Valor Total</td>
                    <td [ngClass]="{'saldo-negativo': maior(contaList[0]?.valorTotalLancado) }">{{ contaList[0]?.valorTotalLancado | currency: 'BRL' }}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td class="p-text-center" colspan="6">
                        Nenhum detalhe encontrado
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="p-field p-col">
        <p-table
            selectionMode="single"
            [value]="contaList"
            responsiveLayout="scroll"
            styleClass="p-datatable-gridlines"
        >
            <ng-template pTemplate="caption">
                <div class="table-header p-text-center">{{ tituloValorAtualizado }}</div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>Descricao</th>
                    <th>Saldo Atualizado</th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-c>
                <tr>
                    <td>Principal</td>
                    <td [ngClass]="{'saldo-negativo': maior(c?.valorAtualizadoPrincipal) }">{{ c?.valorAtualizadoPrincipal | currency: 'BRL' }}</td>
                </tr>
                <tr>
                    <td>Multa de Mora</td>
                    <td [ngClass]="{'saldo-negativo': maior(c?.valorAtualizadoMulta) }">{{ c?.valorAtualizadoMulta | currency: 'BRL'}}</td>
                </tr>
                <tr>
                    <td>Juros de Mora</td>
                    <td [ngClass]="{'saldo-negativo': maior(c?.valorAtualizadoJuros) }">{{ c?.valorAtualizadoJuros | currency: 'BRL' }}</td>
                </tr>
                <tr>
                    <td>Multa por Infração</td>
                    <td [ngClass]="{'saldo-negativo': maior(c?.valorAtualizadoMultaInfra) }">{{ c?.valorAtualizadoMultaInfra | currency: 'BRL' }}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="footer">
                <tr>
                    <td>Valor Total</td>
                    <td [ngClass]="{'saldo-negativo': maior(contaList[0]?.saldoTotalAtualizado) }">
                        {{ contaList[0]?.saldoTotalAtualizado | currency: 'BRL' }}
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td class="p-text-center" colspan="6">
                        Nenhum detalhe encontrado
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
     <div *ngIf="isSimular" class="p-field p-col">
        <p-table
            selectionMode="single"
            [value]="contasSimuladas"
            responsiveLayout="scroll"
            styleClass="p-datatable-gridlines"
        >
            <ng-template pTemplate="caption">
                <div style="background-color: #a4bbd3;" class="table-header p-text-center conta-simulada-font">{{ tituloValorSimulado }}</div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>Descricao</th>
                    <th>Saldo Atualizado</th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-simular>
                <tr class="conta-simulada-font">
                    <td>{{simular.naturezaItemCalculo?.descricao}}</td>
                    <td [ngClass]="{'saldo-negativo': maior(simular.valorLancado) }">{{ simular.valorLancado | currency: 'BRL' }}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="footer">
                <tr class="conta-simulada-font">
                    <td>Valor Total</td>
                     <td [ngClass]="{'saldo-negativo': maior(valorTotalSimulado) }">{{valorTotalSimulado | currency: 'BRL' }}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td class="p-text-center" colspan="6">
                        Nenhum detalhe encontrado
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
