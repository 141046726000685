import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Pagination } from "src/app/shared/pagination/pagination";
import { Pageable } from "src/app/shared/pagination/pageable";

@Injectable({
    providedIn: "root",
})
export class ConsultaContribuinteService {
    httpOptions = {
        headers: new HttpHeaders({
            "Content-Type": "application/json",
        }),
    };

    constructor(private http: HttpClient) {}

    getContribuinte(cpfCnpj): Observable<Pageable<any>> {
        return this.http.get<Pageable<any>>(
            `${environment.baseUrl}/conta-corrente/contas-contribuinte/${cpfCnpj}`
        );
    }

    getContribuinteView(objParams, pagination: Pagination<any>): Observable<Pageable<any>> {
        return this.http.get<Pageable<any>>(
            `${environment.baseUrl}/conta-corrente/view/search`, {
                params: {
                    ...objParams,
                    page: pagination.pageable.pageNumber,
                    size: pagination.size
                  }
            }
        );
    } 
    
    getContribuinteFinanceiro(contaCorrenteId): Observable<Pageable<any>> {
        return this.http.get<Pageable<any>>(
            `${environment.baseUrl}/conta-corrente/${contaCorrenteId}/financeiro`
        );
    }
}
