<p-dialog
    [header]="textoHeader"
    (onHide)="onHide()"
    (onShow)="onShow()"
    [(visible)]="eventoPagamentoParametroConfiguracaoModalService.displayMaximizable"
    [modal]="true"
    [style]="{ width: '60vw' }"
    [maximizable]="true"
    [baseZIndex]="10000"
    [draggable]="false"
    [resizable]="false"
>
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-3">
            <label title="situacao">Situação*</label>
            <p-dropdown
                optionLabel="valor"
                optionValue="chave"
                showClear="true"
                [options]="situacoes"
                [(ngModel)]="situacao"
                [disabled]="situacao"
                [autoDisplayFirst]="false"
            ></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-3">
            <label title="dataInicioVigencia">Início da Vigência*</label>
<!--                [disabled]="validaData"-->
            <p-calendar
                dateFormat="dd/mm/yy"
                [showIcon]="true"
                [(ngModel)]="dateStart"
                (ngModelChange)="onSelectDataInicioVigencia($event)"
            ></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-3">
            <label title="dataFimVigencia">Fim da Vigência</label>
            <p-calendar
                dateFormat="dd/mm/yy"
                [showIcon]="true"
                [(ngModel)]="dateEnd"
                (ngModelChange)="onSelectDataFimVigencia($event)"
            ></p-calendar>
        </div>
    </div>
    <p-fieldset legend="Prioridade das Contas Associadas">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-6">
                <label title="Contas Associadas à Receita">Contas Associadas à Receita</label>
                <p-dropdown
                    optionLabel="nomeCompleto"
                    showClear="true"
                    [options]="contasReceita"
                    [(ngModel)]="contaReceita.configContaReceita"
                    [autoDisplayFirst]="false"
                ></p-dropdown>
            </div>
            <div class="p-col-12 p-md-6" style="margin-top: 24px; margin-bottom: 30px">
                <button
                    (click)="novaConta()"
                    pButton
                    type="button"
                    icon="pi pi-plus-circle"
                    label="Adicionar Conta"
                    class="p-mr-1 p-text-left"
                ></button>
            </div>
        </div>
        <p-table [value]="pagamentoReceita.eventoPagtoReceitaContas || []" [reorderableColumns]="true">
            <ng-template pTemplate="header">
                <tr>
                    <th>Prioridade</th>
                    <th>Código Conta</th>
                    <th>Nome Conta</th>
                    <th>Ação</th>
                </tr>
            </ng-template>
            <ng-template
                pTemplate="body"
                let-pagamento
                let-columns="columns"
                let-index="rowIndex"
            >
                <tr [pReorderableRow]="index">
                    <td pReorderableRowHandle>
                        {{ setOrdem(index) }}
                    </td>
                    <td>
                        {{ pagamento.configContaReceita.configContaId }}
                    </td>
                    <td>{{ pagamento.configContaReceita.nomeCompleto }}</td>
                    <td>
                        <button
                            pButton
                            pRipple
                            title="Excluir"
                            type="button"
                            icon="pi pi-trash"
                            class="p-button-rounded p-button-danger"
                            style="margin-left: 3px"
                            (click)="removeContaAssociada(pagamento)"
                        ></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </p-fieldset>

    <p-fieldset legend="Critério de Identificação da Conta">
        <div class="p-fluid p-formgrid p-grid">
            <div
                class="p-col-12 p-md-4"
                style="margin-top: 24px; margin-bottom: 30px"
            >
                <button
                    (click)="novaRegraIdentificacao()"
                    pButton
                    type="button"
                    icon="pi pi-plus-circle"
                    label="Adicionar Regra*"
                    class="p-mr-1 p-text-left"
                ></button>
            </div>
        </div>
        <p-table [value]="pagamentoReceita.eventoPagtoReceitaRegras || []">
            <ng-template pTemplate="header">
                <tr>
                    <th>Regra de Identificação da conta</th>
                    <th>Início Vigência</th>
                    <th>Fim Vigência</th>
                    <th>Ações</th>
                </tr>
            </ng-template>
            <ng-template
                pTemplate="body"
                let-listaIdentificacao
                let-columns="columns"
                let-index="rowIndex"
            >
                <tr>
                    <td>
                        {{ listaIdentificacao.regra.descricao }}
                    </td>
                    <td>
                        {{listaIdentificacao.dataInicioVigencia | date: "dd/MM/yyyy"}}
                    </td>
                    <td>
                        {{listaIdentificacao.dataFimVigencia | date: "dd/MM/yyyy"}}
                    </td>
                    <td>
                        <button
                            *ngIf="index == 0"
                            pButton
                            pRipple
                            title="Excluir"
                            type="button"
                            icon="pi pi-trash"
                            class="p-button-rounded p-button-danger"
                            style="margin-left: 3px"
                            (click)="excluiIdentificacaoConta(listaIdentificacao)"
                        ></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <div class="p-col-12 p-md-12" style="margin-top: 50px">
            <div class="p-field-checkbox">
                <p-checkbox
                    [disabled]="pagamentoReceita.eventoPagtoContaExcecoes.length > 0"
                    binary="false"
                    trueValue="SIM"
                    falseValue="NAO"
                    [(ngModel)]="criarContaCorrente"
                    label="Criar Conta Corrente, quando não localizada pelos critérios de identificação?"
                    title="Criar Conta Corrente, quando não localizada pelos critérios de identificação?"
                    (onChange)="setContaCorrente()"
                ></p-checkbox>
            </div>
        </div>
        <div *ngIf="criarContaCorrente == 'SIM'">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-col-12 p-md-4" style="margin-bottom: 30px">
                    <button
                        (click)="novaExcecaoRegra()"
                        pButton
                        type="button"
                        icon="pi pi-plus-circle"
                        label="Adicionar Conta*"
                        class="p-mr-1 p-text-left"
                    ></button>
                </div>
            </div>
            <p-table [value]="pagamentoReceita.eventoPagtoContaExcecoes || []">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Código Conta</th>
                        <th>Nome Conta</th>
                        <th>Início Vigência</th>
                        <th>Fim Vigência</th>
                        <th>Ação</th>
                    </tr>
                </ng-template>
                <ng-template
                    pTemplate="body"
                    let-eventoPagtoContaExcecoes
                    let-columns="columns"
                    let-index="rowIndex"
                >
                    <tr>
                        <td>
                            {{
                                eventoPagtoContaExcecoes.configContaReceita
                                    .configContaId
                            }}
                        </td>
                        <td>
                            {{
                                eventoPagtoContaExcecoes.configContaReceita
                                    .nomeResumido
                            }}
                        </td>
                        <td>
                            {{
                                eventoPagtoContaExcecoes.dataInicioVigencia
                                    | date: "dd/MM/yyyy"
                            }}
                        </td>
                        <td>
                            {{
                                eventoPagtoContaExcecoes.dataFimVigencia
                                    | date: "dd/MM/yyyy"
                            }}
                        </td>
                        <td>
                            <button
                                *ngIf="index == 0"
                                pButton
                                pRipple
                                title="Excluir"
                                type="button"
                                icon="pi pi-trash"
                                class="p-button-rounded p-button-danger"
                                style="margin-left: 3px"
                                (click)="
                                    excluiPagtoExcessoes(
                                        eventoPagtoContaExcecoes
                                    )
                                "
                            ></button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </p-fieldset>
    <div class="p-col-12 p-md-12" style="margin-top: 30px">
        <div class="p-field-checkbox">
            <p-checkbox
                [disabled]="pagamentoReceita.eventoPagtoCompensacoes.length > 0"
                binary="false"
                trueValue="SIM"
                falseValue="NAO"
                [(ngModel)]="criarTransbordo"
                label="Há Transbordo?"
                title="Há Transbordo?"
                (onChange)="setCriarTransbordo()"
            ></p-checkbox>
        </div>
    </div>
    <div *ngIf="criarTransbordo == 'SIM'">
        <div
            class="p-col-12 p-md-6"
            style="margin-top: 24px; margin-bottom: 30px"
        >
            <button
                (click)="novoCompesacaoAutomatico()"
                pButton
                type="button"
                icon="pi pi-plus-circle"
                label="Adicionar Evento Compensação*"
                class="p-mr-1 p-text-left"
            ></button>
        </div>

        <p-table [value]="pagamentoReceita.eventoPagtoCompensacoes || []">
            <ng-template pTemplate="header">
                <tr>
                    <th>Evento de Compensação</th>
                    <th>Início Vigência</th>
                    <th>Fim Vigência</th>
                    <th>Ação</th>
                </tr>
            </ng-template>
            <ng-template
                pTemplate="body"
                let-eventoPagtoCompensacoes
                let-columns="columns"
                let-index="rowIndex"
            >
                <tr>
                    <td>
                        {{
                            eventoPagtoCompensacoes.eventoCompensacao.descricao
                        }}
                    </td>
                    <td>
                        {{
                            eventoPagtoCompensacoes.dataInicioVigencia
                                | date: "dd/MM/yyyy"
                        }}
                    </td>
                    <td>
                        {{
                            eventoPagtoCompensacoes.dataFimVigencia
                                | date: "dd/MM/yyyy"
                        }}
                    </td>
                    <td>
                        <button
                            *ngIf="index == 0"
                            pButton
                            pRipple
                            title="Excluir"
                            type="button"
                            icon="pi pi-trash"
                            class="p-button-rounded p-button-danger"
                            style="margin-left: 3px"
                            (click)="
                                excluiPagtoCompensacoes(eventoPagtoCompensacoes)
                            "
                        ></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <ng-template pTemplate="footer">
        <p-button
            icon="pi pi-times"
            (click)="eventoPagamentoParametroConfiguracaoModalService.displayMaximizable = false"
            label="Fechar"
        ></p-button>
        <p-button
            icon="pi pi-save"
            (click)="confirmar()"
            label="Confirmar"
        ></p-button>
    </ng-template>
</p-dialog>
<app-evento-pagamento-regra-identificacao-modal
    (regraIdentificacaoSelecionada)="regraIdentificacaoSelecionada($event)"
    [(dataInicio)]="pagamentoReceita.dataInicioVigencia"
    [(regraLength)]="eventoPagtoReceitaRegrasLength"
></app-evento-pagamento-regra-identificacao-modal>

<app-evento-pagamento-conta-excecao-modal
    (eventoExcessaoSelecionado)="eventoExcessaoSelecionado($event)"
    [(dataInicio)]="pagamentoReceita.dataInicioVigencia"
    [(eventoPagtoContaExcecoesLength)]="eventoPagtoContaExcecoesLength"
></app-evento-pagamento-conta-excecao-modal>

<app-evento-pagamento-evento-compensacao-modal
    (eventoCompensacaoSelecionado)="eventoCompensacaoSelecionado($event)"
    [(dataInicio)]="pagamentoReceita.dataInicioVigencia"
    [(eventoCompensacaoLength)]="eventoPagtoCompensacoesLength"
>
</app-evento-pagamento-evento-compensacao-modal>

