import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ModalContasAssociadasBuscarComponent } from 'src/app/modal/modal-contas-associadas-buscar/modal-contas-associadas-buscar.component';
import { Pagination } from 'src/app/shared/pagination/pagination';
import { AlertsUtil } from 'src/app/utils/alerts/alerts.util';

@Component({
  selector: 'app-contas-associadas',
  templateUrl: './contas-associadas.component.html',
  styleUrls: ['./contas-associadas.component.scss']
})
export class ContasAssociadasComponent implements OnInit {

  @ViewChild(ModalContasAssociadasBuscarComponent, {static: false})
  modalBuscarContaAssociada: ModalContasAssociadasBuscarComponent

  @Output() outEtapas = new EventEmitter();

  blockedDocument = false;
  pagination: Pagination<any>;
  contaAssociadaList: any[] = [];
  contasSelecionadas: any[] = [];

  constructor(
    private alertUtil: AlertsUtil,
  ) { }

  ngOnInit(): void {
    this.pagination = new Pagination<any>();
    if(JSON.parse(localStorage.getItem("contaAssociadaList"))) {
      this.pagination.content = JSON.parse(localStorage.getItem("contaAssociadaList"));
    }

  }

  irMemoriaCalculo(){
    this.outEtapas.emit(1)
  }

  irConfirmacao(){
    this.outEtapas.emit(3);
    localStorage.setItem('contaAssociadaList', JSON.stringify(this.pagination.content));
  }

  onChange(e) {
    this.pagination.pageable.pageNumber = e.page;
    this.getConfigPerfilSearch();
  }

  getConfigPerfilSearch() {
      throw new Error("Method not implemented.");
  }

  openModa() {
    this.modalBuscarContaAssociada.showDialog();
  }

  selectBaseLegal(conta: any){
    this.incluirConta(conta);
  }

  incluirConta(contaList: any) {
      let mesmoId;
      contaList.forEach(element => { 
         mesmoId = this.pagination.content.find(
          (aux) => aux.contaCorrenteId == element.contaCorrenteId)
      });
  
  
      if(mesmoId){
        this.alertUtil.warning('Selecione apenas as contas que não exitem na lista.');
      } else {
        contaList.forEach(element => {
          this.pagination.content.push(element);
        });
        // contaList.forEach(element => {
        //   this.contaAssociadaList.push({
        //     contaCorrenteId: element.contaCorrenteId
        //   });
        // });
      }
  }

  removerItemPorId(): void {
    this.contasSelecionadas.forEach((element) => {
      this.pagination.content = this.pagination.content.filter(item => item.contaCorrenteId !== element.contaCorrenteId);
    });
  }

}
