import { EventoPagamentoReceitas } from "./evento-pagamento-receitas";
import { EventoPagtoRgrImputacaos } from "./evento-pagamento-regra-imputacoes";
import { EventoPagamentoTransacaos } from "./evento-pagamento-transacoes";
import {EventoPagamentoReceitaDTO} from './evento-pagamento-receita-dto';

export class ConfigEventoPagamento {
    dataInicioVigencia: string;
    dataFimVigencia: string;
    descricao: string;
    eventoPagamentoId: number;
    eventoPagamentoReceitas: EventoPagamentoReceitas[];
    eventoPagamentoReceitaDTOs: EventoPagamentoReceitaDTO[];
    eventoPagamentoTransacaos: EventoPagamentoTransacaos[];
    eventoPagtoRgrImputacaos: EventoPagtoRgrImputacaos[];
    implantado: string;
    situacao: string;

    constructor(init?: Partial<ConfigEventoPagamento>) {
        if (init) {
            Object.assign(this, init);
        } else {
            this.dataInicioVigencia = "";
            this.dataFimVigencia = "";
            this.descricao = "";
            this.eventoPagamentoId = null;
            this.eventoPagamentoReceitas = [];
            this.eventoPagamentoReceitaDTOs = [];
            this.eventoPagamentoTransacaos = [];
            this.eventoPagtoRgrImputacaos = [];
            this.implantado = "";
            this.situacao = "";
        }

    }
}
