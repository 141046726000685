import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../../../environments/environment';
import { ModeloDeEstadoConta } from 'src/app/models/modelo-estado-conta';

@Injectable({
    providedIn: 'root'
})
export class EstadoContaListService {


    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(
        private http: HttpClient,
    ) {
    }

    getEfeitoSobreContaCorrente(): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${environment.baseUrl}/enums/name/EfeitoSobreConta`);
    }

    getSituacao(): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${environment.baseUrl}/enums/name/Situacao`);
    }
    getPerfis(): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${environment.baseUrl}/keycloak/roles`);
    }
    getListaEventosEstadoPerfil(): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${environment.baseUrl}/evento-estado-perfil`);
    }

    getEstadoConta(objParams): Observable<Array<any>> {
    const params = new HttpParams({ fromObject: objParams })
        return this.http.get<Array<any>>(`${environment.baseUrl}/estado-conta/search`,{params});
    }

    getEstadoContaPorId(estadoContaId: any) {
        return this.http.get(`${environment.baseUrl}/estado-conta/${estadoContaId}`);
    }

    getListaEstadoPerfil(estadoContaId): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${environment.baseUrl}/estado-perfil/by-estado-conta/${estadoContaId}`);
    }

    postEstadoConta(modeloSave: ModeloDeEstadoConta): Observable<ModeloDeEstadoConta> {
        return this.http.post<ModeloDeEstadoConta>(`${environment.baseUrl}/estado-conta`, modeloSave);
    }

    patchEstadoConta(data: any) {
        return this.http.patch<ModeloDeEstadoConta>(`${environment.baseUrl}/estado-conta/${data.estadoContaId}`, data);
    }

    putAtivaInativa(estadoPerfilId) {
        return this.http.put<void>(
            `${environment.baseUrl}/estado-perfil/${estadoPerfilId}/ativa-inativa`,
            {}
        );
    }

    deletePerfil(estadoPerfilId): Observable<void> {
        return this.http.delete<void>(
            `${environment.baseUrl}/estado-perfil/${estadoPerfilId}`);
    }
}
