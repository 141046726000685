import {Injectable} from '@angular/core';
import {EventoPagamentoReceitas} from '../../../models/evento-pagamento-receitas';

@Injectable({
    providedIn: 'root'
})
export class EventoPagamentoContaExcecaoModalService {
    displayMaximizable: boolean;
    dateStart: Date;
    eventoPagamentoReceita: EventoPagamentoReceitas
    constructor() {
    }
}
