import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-input-date',
  templateUrl: './input-date.component.html'
})
export class InputDateComponent implements OnInit {

  @Input('for-name') forName!: string;
  @Input('name') name!: string;
  @Input('min-date') minDate: any = null;
  @Input('max-date') maxDate: any = null;
  @Input('show-on-focus') showOnFocus: boolean = false;
  @Input('form') form!: FormGroup;
  @Input('year-range-max') yearRangeMax: string | null = null;
  @Input('year-range-min') yearRangeMin: string | null = null;
  @Input('readonly-ynput') readonlyInput: boolean = true;
  @Input('date-format') dateFormat: string = 'dd/mm/yy';
  @Input() view: string = 'date';
  @Input() touch: boolean = false;

  @Output('blur') blur: EventEmitter<any> = new EventEmitter<any>();

  yearRange: any = null;

  constructor() { }

  get touchUI(): any {
    return window.innerWidth <= 600;
  }

  ngOnInit(): void {
    const ano = this.maxDate ? this.maxDate.getFullYear() : new Date().getFullYear();
    this.yearRangeMax = !this.yearRangeMax ? `${this.maxDate ? this.maxDate.getFullYear() : (ano + 10)}` : this.yearRangeMax;
    this.yearRangeMin = !this.yearRangeMin ? `${this.minDate ? this.minDate.getFullYear() : (ano - 110)}` : this.yearRangeMin;
    this.yearRange = !this.yearRange ? `${this.yearRangeMin}:${this.yearRangeMax}` : this.yearRange;
  }

  ngOnChanges(changes: SimpleChanges) { }

  onBlur(event: any): void {
    this.blur.emit(event);
  }
}
