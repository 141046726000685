export class ConfigComposicaoConta {
    comentario: string;
    configContaId: number;
    configContaItemCalculoId: number;
    dataFimVigencia: any;
    dataInicioVigencia: any;
    itemCalculoNome: string;
    itemCalculoSigla: string;
    naturezaItemCalculoId: number;
    regraCalculo: any;
    regraCalculoJson: string;
    situacao: string;

    constructor(init?: Partial<ConfigComposicaoConta>) {
        if (init) {
            Object.assign(this, init);
        } else {
            this.comentario = null;
            this.configContaId = null;
            this.configContaItemCalculoId = null;
            this.dataFimVigencia = null;
            this.dataInicioVigencia = null;
            this.itemCalculoNome = null;
            this.itemCalculoSigla = null;
            this.naturezaItemCalculoId = null;
            this.regraCalculo = null;
            this.regraCalculoJson = null;
            this.situacao = null;
        }
    }
}
