import { AbstractControl, ValidatorFn } from "@angular/forms";
import * as moment from "moment";

export class DateValidator {
    static dateLessThan(dateField1: string, dateField2: string, validatorField: { [key: string]: boolean }): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            const date1 = c.get(dateField1).value;
            const date2 = c.get(dateField2).value;
            if ((date1 !== null && date2 !== null) && date1 > date2) {
                return validatorField;
            }
            return null;
        };
    }

    static intervalDateOneYear(dateField1: string, validatorField: { [key: string]: boolean }): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            const now = new Date();
            const past = new Date(c.get(dateField1).value)
            const diff = Math.abs(now.getTime() - past.getTime());
            const days = Math.ceil(diff / (1000 * 60 * 60 * 24)); // Divide o total pelo total de milisegundos correspondentes a 1 dia. (1000 milisegundos = 1 segundo).

            if (days >= 365) {
                return validatorField;
            }
            return null;
        };
    }

    static intervalDateOneMonth(dateField1: string, validatorField: { [key: string]: boolean }): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            let dataAtual: any = moment(new Date()).format('YYYYY/MM/DD');
            const now = new Date();
            const past = new Date(c.get(dateField1).value)
            const diff = Math.abs(now.getTime() - past.getTime());
            const days = Math.ceil(diff / (1000 * 60 * 60 * 24)); // Divide o total pelo total de milisegundos correspondentes a 1 dia. (1000 milisegundos = 1 segundo).
            const dataInicio = moment(new Date(c.get(dateField1).value)).format('YYYY/MM/DD');

            if (dataInicio > dataAtual && days > 30) {
                return validatorField;
            }

            return null;
        };
    }


}