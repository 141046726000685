export class BaseLegalSearchRequest {
    descricao: string;
    dataInicioVigencia: string;
    dataFimVigencia: string;
    tipoBaseLegalId: string;

    constructor(init?: Partial<BaseLegalSearchRequest>) {
        if (init) {
            Object.assign(this, init);
        } else {
            this.descricao = '';
            this.dataInicioVigencia = '';
            this.dataFimVigencia = '';
            this.tipoBaseLegalId = '';
        }
    }
}
