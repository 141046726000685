<p-card header="Configuração do Modelo de Conta">
<p-messages [(value)]="msgs1" [enableService]="false"></p-messages>
    <p-tabView (onChange)="handleChange($event)">
        <p-tabPanel header="Modelo" title="RegraCriacaoConta">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-2">
                    <label title="regraCriacaoContaId">Código</label>
                    <input
                            type="text"
                            pInputText
                            disabled
                            [(ngModel)]="regraCriacaoConta.regraCriacaoContaId"
                    >
                </div>
                <div class="p-field p-col-12 p-md-10">
                    <label title="descricaoResumida">Descrição Resumida *</label>
                    <input #descricaoResumida="ngModel" required
                            type="text"
                            pInputText
                            [(ngModel)]="regraCriacaoConta.descricaoResumida"
                            [disabled]="!podeEditar"
                            maxlength="50"
                    >
                    <p class="erro-validacao" *ngIf="descricaoResumida.touched && descricaoResumida.errors?.required">
                        Campo obrigatório
                    </p>
                </div>
                <div class="p-field p-col-12 p-md-12">
                    <label title="descricaoCompleta">Descrição Completa *</label>
                    <input #descricaoCompleta="ngModel" required
                            type="text"
                            pInputText
                            [(ngModel)]="regraCriacaoConta.descricaoCompleta"
                            [disabled]="!podeEditar"
                            maxlength="250"
                    >
                    <p class="erro-validacao" *ngIf="descricaoCompleta.touched && descricaoCompleta.errors?.required">
                        Campo obrigatório
                    </p>
                </div>

                <div class="p-field p-col-12 p-md-3">
                    <label title="Sigla do Tributo">Tributo</label>
                    <p-dropdown
                            optionLabel="sigla"
                            optionValue="tributoId"
                            showClear="true"
                            [autoDisplayFirst]="false"
                            [options]="tributos"
                            [(ngModel)]="regraCriacaoConta.tributoId"
                            [disabled]="!podeEditar && this.regraCriacaoConta.regraCriacaoContaId != null"
                    ></p-dropdown>
                </div>

                <div class="p-field p-col-12 p-md-3">
                    <label title="Modelos inativos não podem ser associados à conta corrente">Situação *</label>
                    <p-dropdown
                            optionLabel="valor"
                            optionValue="chave"
                            [options]="situacaoes"
                            [(ngModel)]="regraCriacaoConta.situacao"
                            (onChange)="onChangeSituacao($event)"
                            [disabled]="!podeEditar && !this.regraCriacaoConta.regraCriacaoContaId || !podeEditarSituacao"
                    ></p-dropdown>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label title="Data da Criação">Data da Criação</label>
                    <p-calendar
                            dateFormat="dd/mm/yy"
                            [showIcon]="true"
                            [(ngModel)]="regraCriacaoConta.dataCriacao"
                            disabled
                    ></p-calendar>
                </div>
                <div class="p-col-12 p-md-12">
                    <div class="p-field-checkbox">
                        <p-checkbox
                                binary="false"
                                trueValue="1"
                                falseValue="0"
                                [(ngModel)]="regraCriacaoConta.permiteDuplicidadeLancamento"
                                label="Permite gerar múltiplas contas para o mesmo período de referência?"
                                title="permiteDuplicidadeLancamento"
                                [disabled]="!podeEditar"
                        ></p-checkbox>
                    </div>
                </div>
                <div class="p-col-12 p-md-12">
                    <div class="p-field-checkbox">
                        <p-checkbox
                                binary="false"
                                trueValue="1"
                                falseValue="0"
                                [(ngModel)]="regraCriacaoConta.permiteUtilizarCreditoAuto"
                                label="Permite utilização de crédito automático entre contas de mesma natureza?"
                                title="permiteUtilizarCreditoAuto"
                                (onChange)="OnChangePermiteUtilizarCreditoAuto($event)"
                                [disabled]="!podeEditar"
                        ></p-checkbox>
                    </div>
                    <div class="p-field p-col-12 p-md-12" *ngIf="regraCriacaoConta.permiteUtilizarCreditoAuto == '1'">
                        <label title="criterioUtilizacaoCredito">Critério de Utilização do Crédito *</label>
                        <p-dropdown
                                optionLabel="valor"
                                optionValue="chave"
                                showClear="true"
                                [autoDisplayFirst]="false"
                                [options]="criteriosUtilizacaoCredito"
                                [(ngModel)]="regraCriacaoConta.criterioUtilizacaoCredito"
                                [disabled]="!podeEditar"
                        ></p-dropdown>
                    </div>
                </div>
                <div class="p-col-12 p-md-12">
                    <div class="p-field-checkbox">
                        <p-checkbox
                                binary="false"
                                trueValue="1"
                                falseValue="0"
                                [(ngModel)]="regraCriacaoConta.permiteEmissaoDare"
                                title="permiteEmissaoDare"
                                label="Permite emissão de DARE?"
                                [disabled]="!podeEditar"
                        ></p-checkbox>
                    </div>
                </div>
                <div class="p-col-12 p-md-12">
                    <div class="p-field-checkbox">
                        <p-checkbox
                                binary="false"
                                trueValue="1"
                                falseValue="0"
                                [(ngModel)]="regraCriacaoConta.permiteDareValorMenorSaldo"
                                title="permiteDareValorMenorSaldo"
                                label="Permite emitir DARE com valor menor que o saldo da conta?"
                                [disabled]="!podeEditar"
                        ></p-checkbox>
                    </div>
                </div>
                <div class="p-col-12 p-md-12">
                    <div class="p-field-checkbox">
                        <p-checkbox
                                binary="false"
                                trueValue="1"
                                falseValue="0"
                                [(ngModel)]="regraCriacaoConta.permiteLancamentoRetroativo"
                                title="permiteLancamentoRetroativo"
                                label="Permite lançamento retroativo de débito?"
                                (onChange)="onChangePermiteLancamentoRetroativo($event)"
                                [disabled]="!podeEditar"
                        ></p-checkbox>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-mb-5" *ngIf="regraCriacaoConta.permiteLancamentoRetroativo == '1'">
                    <p-fieldset legend="Controle de Lançamento Retroativo" [disabled]="!podeEditar">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-3">
                                <label title="referenLancamentoRetroativo">Referência *</label>
                                <p-dropdown
                                        optionLabel="valor"
                                        optionValue="chave"
                                        showClear="true"
                                        [autoDisplayFirst]="false"
                                        [options]="referencias"
                                        [(ngModel)]="regraCriacaoConta.referenLancamentoRetroativo"
                                        (onChange)="onChangeReferenLancamentoRetroativo($event)"
                                        [disabled]="camposLancamentoAndPrescricao"
                                ></p-dropdown>
                            </div>
                            <div class="p-field p-col-12 p-md-3">
                                <label title="qtdRetroativo">{{ qtdRetroativoLabel }}</label>
                                <input
                                        type="text"
                                        pInputText
                                        [(ngModel)]="regraCriacaoConta.qtdRetroativo"
                                        maxlength="2"
                                        pKeyFilter="num"
                                        [disabled]="camposLancamentoAndPrescricao"
                                >
                            </div>
                            <div class="p-field p-col-12 p-md-3">
                                <label title="tipoContagemRetroativo">{{ tipoContagemRetroativoLabel }}</label>
                                <p-dropdown
                                        optionLabel="descricao"
                                        showClear="true"
                                        [disabled]="regraCriacaoConta.referenLancamentoRetroativo != 'DIAS' || camposLancamentoAndPrescricao"
                                        [autoDisplayFirst]="false"
                                        [options]="contagensEm"
                                        [(ngModel)]="regraCriacaoConta.tipoContagemRetroativo"
                                ></p-dropdown>
                            </div>
                            <div class="p-field p-col-12 p-md-3">
                                <label title="tipoDataReferenciaRetroativo">A contar da *</label>
                                <p-dropdown
                                        optionLabel="descricao"
                                        showClear="true"
                                        [autoDisplayFirst]="false"
                                        [options]="aContarDas"
                                        [(ngModel)]="regraCriacaoConta.tipoDataReferenciaRetroativo"
                                        [disabled]="camposLancamentoAndPrescricao"
                                ></p-dropdown>
                            </div>
                        </div>
                    </p-fieldset>
                </div>

                <div class="p-col-12 p-md-12">
                    <div class="p-field-checkbox">
                        <p-checkbox
                                binary="false"
                                trueValue="1"
                                falseValue="0"
                                [(ngModel)]="regraCriacaoConta.permitePrescricao"
                                title="permitePrescricao"
                                label="Permite prescrição do débito?"
                                (onChange)="onChangePermitePrescricao($event)"
                                [disabled]="!podeEditar"
                        ></p-checkbox>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-mb-5" *ngIf="regraCriacaoConta.permitePrescricao == '1'">
                    <p-fieldset legend="Regra de Prescrição do Débito" [disabled]="!podeEditar">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-3">
                                <label title="referenciaPrescricao">Referência *</label>
                                <p-dropdown
                                        optionLabel="valor"
                                        optionValue="chave"
                                        showClear="true"
                                        [autoDisplayFirst]="false"
                                        [options]="referencias"
                                        [(ngModel)]="regraCriacaoConta.referenciaPrescricao"
                                        (onChange)="onChangeReferenciaPrescricao($event)"
                                        [disabled]="camposLancamentoAndPrescricao"
                                ></p-dropdown>
                            </div>
                            <div class="p-field p-col-12 p-md-3">
                                <label title="qtdPrescricao">{{ qtdPrescricaoLabel }} </label>
                                <input
                                type="text"
                                pInputText
                                [(ngModel)]="regraCriacaoConta.qtdPrescricao"
                                maxlength="2"
                                pKeyFilter="num"
                                [disabled]="camposLancamentoAndPrescricao"
                                >
                            </div>
                            <div class="p-field p-col-12 p-md-3">
                                <label title="tipoContagemPrescricao">{{ tipoContagemPrescricaoLabel }}</label>
                                <p-dropdown
                                        optionLabel="descricao"
                                        showClear="true"
                                        [autoDisplayFirst]="false"
                                        [options]="contagensEm"
                                        [(ngModel)]="regraCriacaoConta.tipoContagemPrescricao"
                                        [disabled]="regraCriacaoConta.referenciaPrescricao !='DIAS' || camposLancamentoAndPrescricao"
                                ></p-dropdown>
                            </div>
                            <div class="p-field p-col-12 p-md-3">
                                <label title="tipoDataReferenciaPrescricao">A contar da *</label>
                                <p-dropdown
                                        optionLabel="descricao"
                                        showClear="true"
                                        [autoDisplayFirst]="false"
                                        [options]="aContarDas"
                                        [(ngModel)]="regraCriacaoConta.tipoDataReferenciaPrescricao"
                                        [disabled]="camposLancamentoAndPrescricao"
                                ></p-dropdown>
                            </div>
                        </div>
                    </p-fieldset>
                </div>

                <div class="p-col-12 p-md-12 fieldset-space-down">
                    <p-fieldset legend="Regra de Vencimento" >
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-12">
                                <p-fieldset title="vencimentoEmDiaUtil" legend="Vencimento em dia útil *">
                                    <div class="p-fluid p-formgrid p-grid">
                                        <div *ngFor="let venc of vencimentosEmDiaUtil" class="p-col-12 p-md-4">
                                            <div class="p-field-radiobutton">
                                                <p-radioButton [name]="venc.key" [inputId]="venc.key" [value]="venc.key" [(ngModel)]="regraCriacaoConta.vencimentoEmDiaUtil" [disabled]="!podeEditar"></p-radioButton>
                                                <label style="cursor: pointer" [for]="venc.key">{{venc.name}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </p-fieldset>
                            </div>

                            <div class="p-field p-col-12 p-md-6">
                                <label title="regraVencimento">Tipo de Vencimento *</label>
                                <p-dropdown #regraVencimento="ngModel" required
                                        optionLabel="descricao"
                                        showClear="false"
                                        [autoDisplayFirst]="false"
                                        [options]="regraVencimentos"
                                        [(ngModel)]="regraCriacaoConta.regraVencimento"
                                        (onChange)="onChangeRegraVencimento($event)"
                                        [disabled]="!podeEditar"
                                ></p-dropdown>
                                <p class="erro-validacao" *ngIf="regraVencimento.touched && regraVencimento.errors?.required">
                                    Campo obrigatório
                                </p>
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                                <label title="tipoDataReferenciaVencimento">Data Referência *</label>
                                <p-dropdown #dataRef="ngModel" required
                                        optionLabel="descricao"
                                        showClear="false"
                                        [autoDisplayFirst]="false"
                                        [options]="aContarDas"
                                        [(ngModel)]="regraCriacaoConta.tipoDataReferenciaVencimento"
                                        [disabled]="!podeEditar"
                                ></p-dropdown>
                                <p class="erro-validacao" *ngIf="dataRef.touched && dataRef.errors?.required">
                                    Campo obrigatório
                                </p>
                            </div>

                            <div class="p-field p-col-12 p-md-12">
                                <div class="p-fluid p-formgrid p-grid">
                                    <div class="p-field p-col-12 p-md-6" *ngIf="regraCriacaoConta.regraVencimento?.regraUtilizaDia=='1'">
                                        <label title="diaVencimento">Dia vencimento *</label>
                                        <input
                                                type="text"
                                                min="1"
                                                max="31"
                                                pInputText
                                                pKeyFilter="num"
                                                maxlength="2"
                                                [(ngModel)]="regraCriacaoConta.diaVencimento"
                                                [disabled]="!podeEditar"
                                        >
                                        <p class="erro-validacao" *ngIf="regraCriacaoConta.diaVencimento == '' || regraCriacaoConta.diaVencimento == null">
                                            Campo obrigatório
                                        </p>
                                    </div>
                                    <div class="p-field p-col-12 p-md-6" *ngIf="regraCriacaoConta.regraVencimento?.regraUtilizaMes=='1'">
                                        <label title="mesVencimento">Mês vencimento *</label>
                                        <input
                                                type="number"
                                                min="1"
                                                max="12"
                                                pInputText
                                                [(ngModel)]="regraCriacaoConta.mesVencimento"
                                                [disabled]="!podeEditar"
                                                pKeyFilter="num"
                                                maxlength="2"
                                        >
                                        <p class="erro-validacao" *ngIf="regraCriacaoConta.mesVencimento == '' || regraCriacaoConta.mesVencimento == null">
                                            Campo obrigatório
                                        </p>
                                    </div>
                                    <div class="p-field p-col-12 p-md-6" *ngIf="regraCriacaoConta.regraVencimento?.regraUtilizaAno=='1'">
                                        <label title="anoVencimento">Ano vencimento *</label>
                                        <input
                                                type="text"
                                                pInputText
                                                [(ngModel)]="regraCriacaoConta.anoVencimento"
                                                [disabled]="!podeEditar"
                                        >
                                        <p class="erro-validacao" *ngIf="regraCriacaoConta.anoVencimento == '' || regraCriacaoConta.anoVencimento == null">
                                            Campo obrigatório
                                        </p>
                                    </div>
                                    <div class="p-field p-col-12 p-md-6" *ngIf="regraCriacaoConta.regraVencimento?.regraUtilizaQtddias == '1'">
                                        <label title="qtdDiasVencimento">Quantidade de Dias *</label>
                                        <input
                                                type="text"
                                                pInputText
                                                [(ngModel)]="regraCriacaoConta.qtdDiasVencimento"
                                                [disabled]="!podeEditar"
                                                pKeyFilter="num"
                                                maxlength="2"
                                        >
                                        <p class="erro-validacao" *ngIf="regraCriacaoConta.qtdDiasVencimento == '' || regraCriacaoConta.qtdDiasVencimento == null">
                                            Campo obrigatório
                                        </p>
                                    </div>
                                    <div class="p-field p-col-12 p-md-6" *ngIf="regraCriacaoConta.regraVencimento?.regraUtilizaQtddias == '1'">
                                        <label title="tipoContagemVencimento">Contagem em *</label>
                                        <p-dropdown
                                                optionLabel="descricao"
                                                showClear="true"
                                                [autoDisplayFirst]="false"
                                                [options]="contagensEm"
                                                [(ngModel)]="regraCriacaoConta.tipoContagemVencimento"
                                                [disabled]="!podeEditar"
                                        ></p-dropdown>
                                        <p class="erro-validacao" *ngIf="regraCriacaoConta.tipoContagemVencimento == '' || regraCriacaoConta.tipoContagemVencimento == null">
                                            Campo obrigatório
                                        </p>
                                    </div>
                                    <div class="p-field p-col-12 p-md-6" *ngIf="regraCriacaoConta.regraVencimento?.regraUtilizaQtdmeses == '1'">
                                        <label title="qtdMesesVencimento">Quantidade de Meses *</label>
                                        <input
                                                type="text"
                                                pInputText
                                                [(ngModel)]="regraCriacaoConta.qtdMesesVencimento"
                                                [disabled]="!podeEditar"
                                                pKeyFilter="num"
                                                maxlength="2"
                                        >
                                        <p class="erro-validacao" *ngIf="regraCriacaoConta.qtdMesesVencimento == '' || regraCriacaoConta.qtdMesesVencimento == null">
                                            Campo obrigatório
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </p-fieldset>
                </div>

                <div class="p-col-12 p-md-12 p-8">
                    <div class="p-field-checkbox">
                        <p-checkbox
                                binary="false"
                                trueValue="1"
                                falseValue="0"
                                [(ngModel)]="regraCriacaoConta.permiteCotaUnica"
                                label="Permite criação de cota única?"
                                title="permiteCriacaoCotaUnica"
                                (onChange)="OnChangePermiteCriacaoCotaUnica($event)"
                                [disabled]="!podeEditar"
                        ></p-checkbox>
                    </div>
                </div>

                <div class="p-col-12 p-md-12 p-8">
                    <div class="p-field-checkbox">
                        <p-checkbox
                                binary="false"
                                trueValue="1"
                                falseValue="0"
                                [(ngModel)]="regraCriacaoConta.permiteParcelar"
                                label="Permite informar quantidade de parcelas/cotas?"
                                title="permiteInformarQuantidadeParcelas"
                                (onChange)="OnChangePermiteInformarQuantidadeParcelas($event)"
                                [disabled]="regraCriacaoConta.permiteCotaUnica == '1' || !podeEditar"
                        ></p-checkbox>
                    </div>
                </div>

                <div class="p-col-12 p-md-12 fieldset-space-down" *ngIf="regraCriacaoConta.permiteParcelar == '1'">
                    <p-fieldset legend="Regra de Vencimento das Parcelas/Cotas">

                        <p-fieldset legend="Regra de Vencimento da Primeira Parcela/Cota">
                            <div class="p-fluid p-formgrid p-grid">
                                <div class="p-field p-col-12 p-md-6">
                                    <label title="regraVencimento">Regra *</label>
                                    <p-dropdown #regraVencimentoPrimeira
                                            optionLabel="descricao"
                                            showClear="true"
                                            [autoDisplayFirst]="false"
                                            [options]="regraVencimentos"
                                            [(ngModel)]="regraCriacaoConta.regraVencimentoPrimeiraParcela"
                                            (onChange)="onChangeRegraVencimentoPrimeira($event)"
                                            [disabled]="!podeEditar"
                                    ></p-dropdown>
                                    <p class="erro-validacao" *ngIf="regraCriacaoConta.permiteParcelar == '1' && !regraVencimentoPrimeira?.value?.regraVencimentoId">
                                        Campo obrigatório
                                    </p>
                                </div>
                                <div class="p-field p-col-12 p-md-6">
                                    <label title="tipoDataReferenciaVencimento">Data Referência *</label>
                                    <p-dropdown #tipoDataRefVencimentoPrimeira
                                            optionLabel="descricao"
                                            showClear="true"
                                            [autoDisplayFirst]="false"
                                            [options]="aContarDas"
                                            [(ngModel)]="regraCriacaoConta.tipoDataReferenciaPrimeiraParcela"
                                            [disabled]="!podeEditar"
                                    ></p-dropdown>
                                    <p class="erro-validacao" *ngIf="regraCriacaoConta.permiteParcelar == '1' && !tipoDataRefVencimentoPrimeira?.value?.tipoDataReferenciaId">
                                        Campo obrigatório
                                    </p>
                                </div>

                                <div class="p-field p-col-12 p-md-12">
                                    <div class="p-fluid p-formgrid p-grid">
                                        <div class="p-field p-col-12 p-md-6" *ngIf="regraCriacaoConta.regraVencimentoPrimeiraParcela?.regraUtilizaDia=='1'">
                                            <label title="diaVencimento">Dia vencimento *</label>
                                            <input
                                                    type="text"
                                                    min="1"
                                                    max="31"
                                                    pInputText
                                                    pKeyFilter="num"
                                                    maxlength="2"
                                                    [(ngModel)]="regraCriacaoConta.diaVencPrimeiraParcela"
                                                    [disabled]="!podeEditar"
                                            >
                                            <p class="erro-validacao" *ngIf="regraCriacaoConta.diaVencPrimeiraParcela == '' || regraCriacaoConta.diaVencPrimeiraParcela == null">
                                                Campo obrigatório
                                            </p>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6" *ngIf="regraCriacaoConta.regraVencimentoPrimeiraParcela?.regraUtilizaMes=='1'">
                                            <label title="mesVencimento">Mês vencimento *</label>
                                            <input
                                                    type="number"
                                                    min="1"
                                                    max="12"
                                                    pInputText
                                                    [(ngModel)]="regraCriacaoConta.mesVencPrimeiraParcela"
                                                    [disabled]="!podeEditar"
                                                    pKeyFilter="num"
                                                    maxlength="2"
                                            >
                                            <p class="erro-validacao" *ngIf="regraCriacaoConta.mesVencPrimeiraParcela == '' || regraCriacaoConta.mesVencPrimeiraParcela == null">
                                                Campo obrigatório
                                            </p>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6" *ngIf="regraCriacaoConta.regraVencimentoPrimeiraParcela?.regraUtilizaAno=='1'">
                                            <label title="anoVencimento">Ano vencimento *</label>
                                            <input
                                                    type="text"
                                                    pInputText
                                                    [(ngModel)]="regraCriacaoConta.anoVencPrimeiraParcela"
                                                    [disabled]="!podeEditar"
                                            >
                                            <p class="erro-validacao" *ngIf="regraCriacaoConta.anoVencPrimeiraParcela == '' || regraCriacaoConta.anoVencPrimeiraParcela == null">
                                                Campo obrigatório
                                            </p>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6" *ngIf="regraCriacaoConta.regraVencimentoPrimeiraParcela?.regraUtilizaQtddias == '1'">
                                            <label title="qtdDiasVencimento">Quantidade de Dias *</label>
                                            <input
                                                    type="text"
                                                    pInputText
                                                    [(ngModel)]="regraCriacaoConta.qtdDiasPrimeiraParcela"
                                                    [disabled]="!podeEditar"
                                                    pKeyFilter="num"
                                                    maxlength="2"
                                            >
                                            <p class="erro-validacao" *ngIf="regraCriacaoConta.qtdDiasPrimeiraParcela == '' || regraCriacaoConta.qtdDiasPrimeiraParcela == null">
                                                Campo obrigatório
                                            </p>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6" *ngIf="regraCriacaoConta.regraVencimentoPrimeiraParcela?.regraUtilizaQtddias == '1'">
                                            <label title="tipoContagemVencimento">Contagem em *</label>
                                            <p-dropdown
                                                    optionLabel="descricao"
                                                    showClear="true"
                                                    [autoDisplayFirst]="false"
                                                    [options]="contagensEm"
                                                    [(ngModel)]="regraCriacaoConta.tipoContagemVencPrimeiraParcela"
                                                    [disabled]="!podeEditar"
                                            ></p-dropdown>
                                            <p class="erro-validacao" *ngIf="regraCriacaoConta.tipoContagemVencPrimeiraParcela == '' || regraCriacaoConta.tipoContagemVencPrimeiraParcela == null">
                                                Campo obrigatório
                                            </p>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6" *ngIf="regraCriacaoConta.regraVencimentoPrimeiraParcela?.regraUtilizaQtdmeses == '1'">
                                            <label title="qtdMesesVencimentoPrimeiraParcela">Quantidade de Meses *</label>
                                            <input
                                                    type="text"
                                                    pInputText
                                                    [(ngModel)]="regraCriacaoConta.qtdMesesPrimeiraParcela"
                                                    [disabled]="!podeEditar"
                                                    pKeyFilter="num"
                                                    maxlength="2"
                                            >
                                            <p class="erro-validacao" *ngIf="regraCriacaoConta.qtdMesesPrimeiraParcela == '' || regraCriacaoConta.qtdMesesPrimeiraParcela == null">
                                                Campo obrigatório
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </p-fieldset>

                        <p-fieldset legend="Regra Demais Parcelas/Cotas - Referência Data de Vencimento Calculada da Primeira">
                            <div class="p-fluid p-formgrid p-grid">
                                <div class="p-field p-col-12 p-md-6">
                                    <label title="regraVencimento">Regra *</label>
                                    <p-dropdown #regraVencimentoDemais
                                            optionLabel="descricao"
                                            showClear="true"
                                            [autoDisplayFirst]="false"
                                            [options]="regraVencimentos"
                                            [(ngModel)]="regraCriacaoConta.regraVencimentoDemaisParcela"
                                            (onChange)="onChangeRegraVencimentoDemais($event)"
                                            [disabled]="!podeEditar"
                                    ></p-dropdown>
                                    <p class="erro-validacao" *ngIf="regraCriacaoConta.permiteParcelar=='1' && !regraVencimentoDemais?.value?.regraVencimentoId">
                                        Campo obrigatório
                                    </p>
                                </div>

                                <div class="p-field p-col-12 p-md-12">
                                    <div class="p-fluid p-formgrid p-grid">
                                        <div class="p-field p-col-12 p-md-6" *ngIf="regraCriacaoConta.regraVencimentoDemaisParcela?.regraUtilizaDia=='1'">
                                            <label title="diaVencimento">Dia vencimento *</label>
                                            <input
                                                    type="text"
                                                    min="1"
                                                    max="31"
                                                    pInputText
                                                    pKeyFilter="num"
                                                    maxlength="2"
                                                    [(ngModel)]="regraCriacaoConta.diaVencDemaisParcela"
                                                    [disabled]="!podeEditar"
                                            >
                                            <p class="erro-validacao" *ngIf="regraCriacaoConta.diaVencDemaisParcela == '' || regraCriacaoConta.diaVencDemaisParcela == null">
                                                Campo obrigatório
                                            </p>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6" *ngIf="regraCriacaoConta.regraVencimentoDemaisParcela?.regraUtilizaMes=='1'">
                                            <label title="mesVencimento">Mês vencimento *</label>
                                            <input
                                                    type="number"
                                                    min="1"
                                                    max="12"
                                                    pInputText
                                                    [(ngModel)]="regraCriacaoConta.mesVencDemaisParcela"
                                                    [disabled]="!podeEditar"
                                                    pKeyFilter="num"
                                                    maxlength="2"
                                            >
                                            <p class="erro-validacao" *ngIf="regraCriacaoConta.mesVencDemaisParcela == '' || regraCriacaoConta.mesVencDemaisParcela == null">
                                                Campo obrigatório
                                            </p>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6" *ngIf="regraCriacaoConta.regraVencimentoDemaisParcela?.regraUtilizaAno=='1'">
                                            <label title="anoVencimento">Ano vencimento *</label>
                                            <input
                                                    type="text"
                                                    pInputText
                                                    [(ngModel)]="regraCriacaoConta.anoVencDemaisParcela"
                                                    [disabled]="!podeEditar"
                                            >
                                            <p class="erro-validacao" *ngIf="regraCriacaoConta.anoVencDemaisParcela == '' || regraCriacaoConta.anoVencDemaisParcela == null">
                                                Campo obrigatório
                                            </p>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6" *ngIf="regraCriacaoConta.regraVencimentoDemaisParcela?.regraUtilizaQtddias == '1'">
                                            <label title="qtdDiasVencimento">Quantidade de Dias *</label>
                                            <input
                                                    type="text"
                                                    pInputText
                                                    [(ngModel)]="regraCriacaoConta.qtdDiasDemaisParcela"
                                                    [disabled]="!podeEditar"
                                                    pKeyFilter="num"
                                                    maxlength="2"
                                            >
                                            <p class="erro-validacao" *ngIf="regraCriacaoConta.qtdDiasDemaisParcela == '' || regraCriacaoConta.qtdDiasDemaisParcela == null">
                                                Campo obrigatório
                                            </p>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6" *ngIf="regraCriacaoConta.regraVencimentoDemaisParcela?.regraUtilizaQtddias == '1'">
                                            <label title="tipoContagemVencimento">Contagem em *</label>
                                            <p-dropdown
                                                    optionLabel="descricao"
                                                    showClear="true"
                                                    [autoDisplayFirst]="false"
                                                    [options]="contagensEm"
                                                    [(ngModel)]="regraCriacaoConta.tipoContagemVencDemaisParcela"
                                                    [disabled]="!podeEditar"
                                            ></p-dropdown>
                                            <p class="erro-validacao" *ngIf="regraCriacaoConta.tipoContagemVencDemaisParcela == '' || regraCriacaoConta.tipoContagemVencDemaisParcela == null">
                                                Campo obrigatório
                                            </p>
                                        </div>
                                        <div class="p-field p-col-12 p-md-6" *ngIf="regraCriacaoConta.regraVencimentoDemaisParcela?.regraUtilizaQtdmeses == '1'">
                                            <label title="qtdMesesVencimentoDemaisParcela">Quantidade de Meses *</label>
                                            <input
                                                    type="text"
                                                    pInputText
                                                    [(ngModel)]="regraCriacaoConta.qtdMesesDemaisParcela"
                                                    [disabled]="!podeEditar"
                                                    pKeyFilter="num"
                                                    maxlength="2"
                                            >
                                            <p class="erro-validacao" *ngIf="regraCriacaoConta.qtdMesesDemaisParcela == '' || regraCriacaoConta.qtdMesesDemaisParcela == null">
                                                Campo obrigatório
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </p-fieldset>

                    </p-fieldset>
                </div>

            </div>
            <div style="margin-top: 10px; text-align: right;">
                <button pButton type="button" label="Voltar" class="p-button-outlined p-mr-2" (click)="voltar()"></button>
                <p-button label="Salvar" (onClick)="create()"></p-button>
            </div>
        </p-tabPanel>
        <p-tabPanel header="Contas" [disabled]="regraCriacaoConta.regraCriacaoContaId == null" title="ConfigContaRegraCriacao">
            <app-contas-correntes-tab-view [situacoesInput]="situacaoes" [regraCriacaoConta]="regraCriacaoConta" (temContaAssociadaEvent)="setTemContaAssociadaEvent($event)"></app-contas-correntes-tab-view>
        </p-tabPanel>
    </p-tabView>
</p-card>
