import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ArrecadaCaoDareService {
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };
    private routerInfo: BehaviorSubject<any>;

    constructor(
        private http: HttpClient,
    ) {
        this.routerInfo = new BehaviorSubject<any>(false);
    }

    postArrecadacaoDare(objConta: any) {
        return this.http.post<any>(
            `${environment.baseUrl}/arrecadacao/dare`, objConta);
    }

}
