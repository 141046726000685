<div class="p-grid">
    <div class="p-col-12">
        <div class="card">
            <p-steps [model]="passos" [(activeIndex)]="passoAtivo"></p-steps>
        </div>
    </div>
    <div class="p-col-12">
        <div class="card">
            <div class="p-grid">
                <div class="p-col-12">
                    <button
                        pButton
                        type="button"
                        class="p-button-outlined p-mr-2"
                        label="Voltar"
                        (click)="voltarPasso()"
                    ></button>
                    <button
                        pButton
                        pRipple
                        type="button"
                        class="float-btn-next"
                        label="Próxima"
                        (click)="avancarPasso()"
                        icon="pi pi-angle-right"
                        iconPos="right"
                        *ngIf="passoAtivo !== tipoPasso.BASE_LEGAL"
                        [disabled]="edicaoCategoria && !edicaoConfiguracao"
                    ></button>
                </div>
                <div class="p-col-12" *ngIf="passoAtivo === tipoPasso.EVENTO">
                    <app-eventos
                        [form]="form"
                        [validarCampos]="validarCampos"
                        [dadosCarregados]="dadosCarregados"
                    ></app-eventos>
                </div>
                <div
                    class="p-col-12"
                    *ngIf="passoAtivo === tipoPasso.CONFIGURACAO"
                >
                    <app-configuracao
                        [form]="form"
                        [validarCampos]="validarCampos"
                        [dadosCarregados]="dadosCarregados"
                    ></app-configuracao>
                </div>
                <div class="p-col-12" *ngIf="passoAtivo === tipoPasso.CONTAS">
                    <app-contas
                        [form]="form"
                        [validarCampos]="validarCampos"
                        [dadosCarregados]="dadosCarregados"
                    ></app-contas>
                </div>
                <div
                    class="p-col-12"
                    *ngIf="passoAtivo === tipoPasso.TRANSACOES"
                >
                    <app-transacoes
                        [form]="form"
                        [validarCampos]="validarCampos"
                        [dadosCarregados]="dadosCarregados"
                    ></app-transacoes>
                </div>
                <div
                    class="p-col-12"
                    *ngIf="passoAtivo === tipoPasso.BASE_LEGAL"
                >
                    <app-base-legal
                        [form]="form"
                        [validarCampos]="validarCampos"
                        [dadosCarregados]="dadosCarregados"
                    ></app-base-legal>
                </div>
                <div class="p-col-12">
                    <button
                        pButton
                        type="button"
                        class="p-button-outlined p-mr-2"
                        label="Voltar"
                        (click)="voltarPasso()"
                    ></button>
                    <button
                        pButton
                        pRipple
                        type="button"
                        class="float-btn-next"
                        label="Próxima"
                        (click)="avancarPasso()"
                        icon="pi pi-angle-right"
                        iconPos="right"
                        *ngIf="passoAtivo !== tipoPasso.BASE_LEGAL"
                        [disabled]="edicaoCategoria && !edicaoConfiguracao"
                    ></button>
                    <button
                        pButton
                        pRipple
                        type="button"
                        class="float-btn-next"
                        label="Salvar"
                        (click)="salvar()"
                        *ngIf="passoAtivo === tipoPasso.BASE_LEGAL && (!implantado|| habilitaSalvarNovasContasImplementado)"
                    ></button>
                </div>
            </div>
        </div>
    </div>
</div>
