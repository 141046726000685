import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigAssociarReceitaService {
  receitas = [];
  subreceitas = [];
  constructor() { }
}
