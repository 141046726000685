import { Component, OnInit, Input } from '@angular/core';
import { Conditional, Item, LogicalOperator, RelationalOperator } from '../condition-node';

@Component({
  selector: 'app-conditional',
  templateUrl: './conditional.component.html',
  styleUrls: ['./conditional.component.scss']
})
export class ConditionalComponent implements OnInit {

  @Input() conditions: Conditional[];
  @Input() groupArr: any[];
  operators = RelationalOperator;

  constructor() { }

  ngOnInit (): void {
  }

  closeTimeselector (event, element) {
    element.hide(event);
  }

  itemCalculo (item: Item, selectedItem: any) {
    item.id = selectedItem.itemComposicaoId;
    item.name = selectedItem.itemCampo;
  }
}
