 

    export class Veiculo {
        anoFabricacao: number;
        anoModelo: number;
        bairro: string;
        bairroDono: string;
        capacidadePassageiros: number;
        categoria: number;
        categoriaVeiculo: number;
        cep: number;
        cepDono: number;
        chassi?: string;
        cilindrada: number;
        codCombustivel: number;
        codMarcaModelo: number;
        codMunicipio: number;
        codMunicipioDono: number;
        complemento: string;
        complementoDono: string;
        cor: string;
        cpfCnpjRenavam: number;
        cpfcnpjArrendatario: number;
        dataLancamento: string;
        email: string;
        fax: string;
        fone: string;
        idRenavam: number;
        idRucRenavam: number;
        logradouro: string;
        logradouroDono: string;
        marcaModelo: string;
        nome: string;
        nomeArrendatario: string;
        numero: string;
        numeroDono: string;
        placa: string;
        potencia: number;
        referencia: string;
        referenciaDono: string;
        renavam: number;
        tipoLogradouro: string;
        tipoLogradouroDono: string;
        tipoPessoa: string;
        tipoPessoaDono: number;
        uf: string;
        constructor(init?: Partial<Veiculo>) {
            if (init) {
                Object.assign(this, init);
            } else {
                this.uf = null;
                this.chassi = null;
                this.renavam = null;
                this.placa = null;
                
    
            }
        }
    }

   
 


