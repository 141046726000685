import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnumsService {

  constructor(private http: HttpClient) { }

  getSimNao(): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${environment.baseUrl}/enums/sim-nao`);
  }

  getTipoTransacao(): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${environment.baseUrl}/enums/transacao/tipo`);
  }

  getTipoHonorarios(): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${environment.baseUrl}/enums/tipo-honorarios`);
  }

  getTipoCconsolidado(): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${environment.baseUrl}/enums/tipo-consolidado`);
  }

  getSituacao(): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${environment.baseUrl}/enums/situacao`);
  }

  getEnumNome(nome): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${environment.baseUrl}/enums/name/${nome}`,);
  }

}
