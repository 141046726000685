<div class="p-fluid p-formgrid p-grid">
    <div class="p-col-12" *ngIf="implantado">
        <p-messages severity="info">
            <ng-template pTemplate>
                <div class="ml-2">
                    Não é permitido editar um evento implantado.
                </div>
            </ng-template>
        </p-messages>
    </div>
    <div class="p-field p-col-12 p-md-12">
        <app-base-legal-search
            (baseLegalChange)="baseLegalChange($event)"
            [baseLegalId]="baseLegalForm.value.idBaseLegal"
            [disabled]="implantado"
        ></app-base-legal-search>
    </div>
    <p
        class="erro-validacao"
        *ngIf="
            validarCampos && baseLegalForm.controls.idBaseLegal.errors?.required
        "
    >
        Obrigatório selecionar uma Base Legal.
    </p>
</div>
