import { ParamConfigEvento } from "./../models/param-config-evento";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class ParamConfigEventoService {
    constructor(private http: HttpClient) {}

    buscarPermissoes(eventoId: number): Observable<ParamConfigEvento[]> {
        return this.http.get<ParamConfigEvento[]>(
            `${environment.baseUrl}/param-config-evento/${eventoId}`
        );
    }
}
