<div class="p-grid">
    <div class="p-col-12" *ngIf="implantado">
        <p-messages severity="info">
            <ng-template pTemplate>
                <div class="ml-2">
                    Não é permitido editar um evento implantado.
                </div>
            </ng-template>
        </p-messages>
    </div>
    <div class="p-col-12">
        <div class="p-grid">
            <div class="p-col-7 p-fluid">
                <label>Perfil * </label>
                <p-dropdown
                    [disabled]="todosCheckBox"
                    [options]="perfisOptions"
                    [(ngModel)]="perfilSelecionado"
                    optionLabel="codigoPerfil"
                    placeholder="Selecione uma opção"
                ></p-dropdown>
            </div>
            <div class="p-col-1 p-fluid">
                <label><br /></label>
                <button
                    [disabled]="
                        !perfilSelecionado || todosCheckBox || implantado
                    "
                    type="button"
                    pButton
                    title="Incluir"
                    icon="pi pi-plus"
                    class="p-button-rounded"
                    (click)="addPerfil()"
                ></button>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-12">
                <div class="p-field-checkbox">
                    <p-checkbox
                        binary="true"
                        [ngModel]="todosCheckBox"
                        (click)="aplicarTodos($event)"
                        label="Todos os perfis"
                        title="Todos os perfis"
                        [disabled]="implantado"
                    ></p-checkbox>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="p-grid">
    <div class="p-col-12">
        <h5>Lista de Contas</h5>
        <p-table [value]="perfilVisualizacaoForm.value.perfis">
            <ng-template pTemplate="header">
                <tr>
                    <th>Perfil</th>
                    <th>Cadastro da Associação</th>
                    <th>Fim da Associação</th>
                    <th *ngIf="!implantado">Ações</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-perfil>
                <tr>
                    <td>{{ perfil.codigoPerfil }}</td>
                    <td>
                        {{ perfil.dataInicioVigencia | date : "dd/MM/yyyy" }}
                    </td>
                    <td>{{ perfil.dataFimVigencia | date : "dd/MM/yyyy" }}</td>
                    <td *ngIf="!implantado">
                        <button
                            pButton
                            pRipple
                            title="Remover"
                            type="button"
                            icon="pi pi-trash"
                            class="p-button-rounded"
                            (click)="removerPerfil(perfil)"
                        ></button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td class="p-text-center" colspan="4">
                        {{ "Adicione pelo menos um perfil ou marque todos" }}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <p
            class="erro-validacao"
            *ngIf="
                (perfilVisualizacaoForm.controls.perfis.touched ||
                    validarCampos) &&
                perfilVisualizacaoForm.controls.perfis.errors?.required
            "
        >
            Campo obrigatório
        </p>
    </div>
</div>
