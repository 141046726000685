import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {IConfigContaSearchModalState, setConfigContas} from '../../../store/config.conta.search.modal.state';
import {Store} from '@ngrx/store';
import {ConfigContaSearchRequest} from '../../../request/config-conta-search-request';
import * as moment from 'moment';
import {map} from 'rxjs/operators';
import {Pagination} from '../../../shared/pagination/pagination';
import {Pageable} from '../../../shared/pagination/pageable';
import {ConfigConta} from '../../../models/config-conta';
import {ConfigContaRegraCriacao} from '../../../models/config-conta-regra-criacao';
import * as RegraCriacaoViewActions from '../../regra-criacao/regra-criacao-view/regra-criacao-view.actions';
import { UtilsService } from 'src/app/shared/utils.service';
import { RemoveParamsUtil } from 'src/app/utils/paramsRemove/paramsRemove.util';

@Component({
    selector: 'app-config-conta-search-modal',
    templateUrl: './config-conta-search-modal.component.html',
    styleUrls: ['./config-conta-search-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ConfigContaSearchModalComponent implements OnInit {

    displayMaximizable: boolean;

    @Input() multipleSelection: boolean = false;

    selectedConfigContas: any[];

    configContaSearchRequest: ConfigContaSearchRequest;

    tiposConta: any[];

    tributos: any[];

    naturezaContaCorrentes: any[];

    origemInformacaos: any[];

    selectedAll: boolean = false;

    configContaSearchResponses$ = this.store.select('app').pipe(
        map(e => e.configContas)
    );

    pagination: Pagination<any>;

    blockedDocument = false;

    configContaRegraCriacao: ConfigContaRegraCriacao;

    @Output() outConfigConta = new EventEmitter<string>();
    dataInicioVigencia: any;
    dataFimVigencia: any;


    constructor(
        private http: HttpClient,
        private store: Store<{ app: IConfigContaSearchModalState }>,
        private storeConfigContaSearchModal: Store<{ configContaSearchModal: { displayMaximizable: boolean } }>,
        private storeRegraCriacaoView: Store<{regraCriacaoView: {configContaRegraCriacao: ConfigContaRegraCriacao}}>,
        private storeRegrasCriacoesView: Store<{regrasCriacoesView: {configsContasRegrasCriacoes: ConfigContaRegraCriacao[]}}>,
        private utilService: UtilsService,
        private removeParamsUtil: RemoveParamsUtil,
    ) {
        this.configContaSearchRequest = new ConfigContaSearchRequest();
    }

    ngOnInit(): void {
        this.pagination = new Pagination<any>();
        this.setConfigContaRegraCriacao();
        this.setDisplayMaximizable();
        this.setListaTipoDeConta();
        this.setTributos();
        this.setNaturezaContaCorrente();
        this.setOrigemInformacoes();
    }

    private setConfigContaRegraCriacao() {
        this.storeRegraCriacaoView
            .select('regraCriacaoView')
            .subscribe(data => this.configContaRegraCriacao = JSON.parse(JSON.stringify(data.configContaRegraCriacao)));
    }

    private setDisplayMaximizable() {
        this.storeConfigContaSearchModal
            .select('configContaSearchModal')
            .subscribe(data => this.displayMaximizable = data.displayMaximizable);
    }

    private setListaTipoDeConta() {
        this.http.get<Array<any>>(`${environment.baseUrl}/enums/config-conta/tipo-conta`).subscribe(response => {
            this.tiposConta = response;
            this.configContaSearchRequest.tipoConta = 'TRIBUTARIA';
        });
    }

    private setTributos() {
        this.http
            .get<Array<any>>(`${environment.baseUrl}/tributo`)
            .subscribe(response => {
                this.tributos = response;
            });
    }

    private setNaturezaContaCorrente() {
        this.http
            .get<Array<any>>(`${environment.baseUrl}/natureza-conta-corrente`)
            .subscribe(response => {
                this.naturezaContaCorrentes = response;
            });
    }

    private setOrigemInformacoes() {
        this.http
            .get<Array<any>>(`${environment.baseUrl}/origem-informacao`)
            .subscribe(response => {
                this.origemInformacaos = response;
            });
    }

    onSelectDataInicioVigencia(event) {
        if (event) {
            this.configContaSearchRequest.dataInicioVigencia = moment(event).format('DD/MM/YYYY');
        } else {
            this.configContaSearchRequest.dataInicioVigencia = '';
        }
    }

    onSelectDataFimVigencia(event) {
        if (event) {
            this.configContaSearchRequest.dataFimVigencia = moment(event).format('DD/MM/YYYY');
        } else {
            this.configContaSearchRequest.dataFimVigencia = '';
        }
    }

    convertDateBr(data) {
        return data == null ? '' : moment(data).format('DD/MM/YYYY');
    }

    onPageChange(e) {
        this.pagination.pageable.pageNumber = e.page;
        this.search();
    }

    search() {
        this.blockedDocument = true;
        const data = this.configContaSearchRequest

        data.situacao = 'ATIVA';

        if(data.dataInicioVigencia) {
            data.dataInicioVigencia = this.utilService.convertDateToSave(this.dataInicioVigencia);
        }
        if(data.dataFimVigencia) {
            data.dataFimVigencia = this.utilService.convertDateToSave(this.dataFimVigencia);
        }

        this.removeParamsUtil.removeParametroNaoUtilizado(data);

        this.http
            .get<Pageable<any>>(`${environment.baseUrl}/config-conta/search`, {
                params: {
                    ...data,
                    page: this.pagination.pageable.pageNumber,
                    size: 5
                }
            })
            .subscribe(response => {
                this.pagination = response;
                const ccm: ConfigConta[] = response.content;
                this.store.dispatch(setConfigContas({cc: ccm}));
                this.blockedDocument = false;

                this.selectedConfigContas = [];
                this.selectedAll = false;
            }, error => {
                this.blockedDocument = false;
            });
    }

    clearOrigem() {
        this.configContaSearchRequest.origemInformacaoId = '';
    }

    clearTributo() {
        this.configContaSearchRequest.tributoId = '';
    }

    select(cc) {
        this.configContaRegraCriacao.configConta = JSON.parse(JSON.stringify(cc));
        this.storeRegraCriacaoView.dispatch(new RegraCriacaoViewActions.SetConfigContaRegraCriacao(this.configContaRegraCriacao));
        this.displayMaximizable = false;
        this.outConfigConta.emit(cc);
    }

    confirmarSelecionados() {
        const x = JSON.parse(JSON.stringify(this.selectedConfigContas));
        this.storeRegraCriacaoView.dispatch(new RegraCriacaoViewActions.SetConfigsContasRegrasCriacoes(x));
        this.displayMaximizable = false;
        this.outConfigConta.emit(x);
    }

    onHide() {
        this.onClearFilter()
    }

    onSelectAll(event) {
        if (event.checked) {
            this.store
                .select('app')
                .subscribe((data: IConfigContaSearchModalState) => {
                    this.selectedConfigContas = data.configContas;
                } );
        }
        else {
            this.selectedConfigContas = [];
        }
    }

    onClearFilter() {
        this.configContaSearchRequest = new ConfigContaSearchRequest();
        this.dataInicioVigencia = null;
        this.dataFimVigencia = null;
        this.selectedConfigContas = [];
        this.selectedAll = false;

        const ccm: ConfigConta[] = [];
        this.store.dispatch(setConfigContas({cc: ccm}));
        this.blockedDocument = false;

        this.pagination = new Pagination<any>();
    }

    onShow() {
        this.search()
    }
}
