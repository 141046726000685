
export class eventoBeneficioId {
    eventoBeneficioId: any;

    constructor(init?: Partial<eventoBeneficioId>) {
        if (init) {
            Object.assign(this, init);
        } else {
            this.eventoBeneficioId = 0;
        }
    }
}
export class regraId {
    regraId: any;

    constructor(init?: Partial<regraId>) {
        if (init) {
            Object.assign(this, init);
        } else {
            this.regraId = null;
        }
    }
}
export class TipoCadastroId {
    tipoCadastroId: any;

    constructor(init?: Partial<TipoCadastroId>) {
        if (init) {
            Object.assign(this, init);
        } else {
            this.tipoCadastroId = null;
        }
    }
}

export class destinatarioSaveRequest {
    tipoCadastro?: TipoCadastroId;
    descricao: string;
    eventoBeneficio: eventoBeneficioId;
    eventoDestinatarioId: number;
    regra: regraId;

    constructor(init?: Partial<destinatarioSaveRequest>) {
        if (init) {
            Object.assign(this, init);
        } else {
            this.descricao = '';
            this.eventoBeneficio = null;
            this.eventoDestinatarioId = null;
            this.regra = null;
        }
    }
}
