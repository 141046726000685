// import { SelecionarContribInternaListComponent } from './component/selecionar-contrib-interna/selecionar-contrib-interna-list/selecionar-contrib-interna-list.component';

import { AppMainComponent } from './app.main.component';
import { EventoCompensacaoListComponent } from './component/evento-compensacao/evento-compensacao-list/evento-compensacao-list.component';
import { EventoPagamentoCadastroComponent } from './component/evento-pagamento/evento-pagamento-cadastro/evento-pagamento-cadastro.component';
import { EventoPagamentoListComponent } from './component/evento-pagamento/evento-pagamento-list/evento-pagamento-list.component';

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';


import { AuthGuard } from './auth/auth.guard';
import { ConfigContaListComponent } from './component/config-conta/config-conta-list/config-conta-list.component';
import { ConfigEstadoContaListComponent } from './component/config-estado-conta/modules/estado-conta/config-estado-conta-list/config-estado-conta-list.component';
import { ConfigEventoBeneficioFiscalComponent } from './component/config-evento-beneficio-fiscal/config-evento-beneficio-fiscal.component';
import { ConfigRegraSelecaoCadastroComponent } from './component/config-regra-selecao/config-regra-selecao-cadastro/config-regra-selecao-cadastro.component';
import { ConfigRegraSelecaoListComponent } from './component/config-regra-selecao/config-regra-selecao-list/config-regra-selecao-list.component';
import { AssociarModeloContaCorrenteComponent } from './component/configurar-conta-corrente/associar-modelo-conta-corrente/associar-modelo-conta-corrente.component';
import { ConfigAssociarReceitaTabViewComponent } from './component/configurar-conta-corrente/config-associar-receita-tab-view/config-associar-receita-tab-view.component';
import { ConfigComposicaoContaComponent } from './component/configurar-conta-corrente/config-composicao-conta/config-composicao-conta.component';
import { ConfigContaBaseLegalTabViewComponent } from './component/configurar-conta-corrente/config-conta-base-legal-tab-view/config-conta-base-legal-tab-view.component';
import { ConfigContaCorrenteTabViewComponent } from './component/configurar-conta-corrente/config-conta-corrente-tab-view/config-conta-corrente-tab-view.component';
import { ConfigContaUnidOrganizaTabViewComponent } from './component/configurar-conta-corrente/config-conta-unid-organiza-tab-view/config-conta-unid-organiza-tab-view.component';
import { ConfigPerfilVisualizacaoComponent } from './component/configurar-conta-corrente/config-perfil-visualizacao/config-perfil-visualizacao.component';
import { ConfigurarContaCorrenteComponent } from './component/configurar-conta-corrente/configurar-conta-corrente.component';
import { RegistrarMemoriaCalculoComponent } from './component/criacao-conta-corrente/components/registrar-memoria-calculo/registrar-memoria-calculo.component';
import { ConsultaDebitoContribuinteComponent } from './component/criacao-conta-corrente/consulta-conta-veiculo/components/consulta-debito-contribuinte/consulta-debito-contribuinte.component';
import { ConsultaContaVeiculoComponent } from './component/criacao-conta-corrente/consulta-conta-veiculo/consulta-conta-veiculo.component';
import { CriacaoContaCorrenteComponent } from './component/criacao-conta-corrente/criacao-conta-corrente.component';
import { EstadoContaListComponent } from './component/estado-conta/estado-conta-list/estado-conta-list.component';
import { EventoBeneficioListComponent } from './component/evento-beneficio/evento-beneficio-list/evento-beneficio-list.component';
import {
    EventoCompensacaoConfigComponent
} from './component/evento-compensacao/evento-compensacao-config/evento-compensacao-config.component';
import { ConfigEventoEstadoComponent } from './component/evento-estado/config-evento-estado/config-evento-estado.component';
import { EventoEstadoListComponent } from './component/evento-estado/evento-estado-list/evento-estado-list.component';
import { EventoGerenciarConfigComponent } from './component/evento-gerenciar-config/evento-gerenciar-config.component';
import { ConfigEventoLancamentoComponent } from './component/evento-lancamento/config-evento-lancamento/config-evento-lancamento.component';
import { EventoPesquisaConfigComponent } from './component/evento-pesquisa-config/evento-pesquisa-config.component';
import { ExtratoContaCorrenteComponent } from './component/extrato-conta-corrente/extrato-conta-corrente.component';
import { ModalPdfComponent } from './component/modal-pdf/modal-pdf.component';
import { RegraCriacaoListComponent } from './component/regra-criacao/regra-criacao-list/regra-criacao-list.component';
import { RegraCriacaoViewComponent } from './component/regra-criacao/regra-criacao-view/regra-criacao-view.component';
import { CadastroTransacaoFinanceiraComponent } from './component/transacao-financeira/pages/cadastro-transacao-financeira/cadastro-transacao-financeira.component';
import { GerenciarTransacaoFinanceiraComponent } from './component/transacao-financeira/pages/gerenciar-transacao-financeira/gerenciar-transacao-financeira.component';
import { SelecaoEventoComponent } from './component/transacao-financeira/pages/selecao-evento/selecao-evento.component';
import { DashboardDemoComponent } from './demo/view/dashboarddemo.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppCalendarComponent } from './pages/app.calendar.component';
import { AppCrudComponent } from './pages/app.crud.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppHelpComponent } from './pages/app.help.component';
import { AppInvoiceComponent } from './pages/app.invoice.component';
import { AppLoginComponent } from './pages/app.login.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppTimelineDemoComponent } from './pages/app.timelinedemo.component';
import { DisplayComponent } from './utilities/display.component';
import { ElevationComponent } from './utilities/elevation.component';
import { FlexboxComponent } from './utilities/flexbox.component';
import { GridComponent } from './utilities/grid.component';
import { IconsComponent } from './utilities/icons.component';
import { SpacingComponent } from './utilities/spacing.component';
import { TextComponent } from './utilities/text.component';
import { TypographyComponent } from './utilities/typography.component';
import { WidgetsComponent } from './utilities/widgets.component';
import {
    LancamentoPorImpostoComponent
} from "./component/dashboard/lancamento-por-imposto/lancamento-por-imposto.component";
import {EventoPorPeriodoComponent} from "./component/dashboard/evento-por-periodo/evento-por-periodo.component";
import {ImpostoAtrasadoComponent} from "./component/dashboard/imposto-atrasado/imposto-atrasado.component";
import {ImpostoPagoComponent} from "./component/dashboard/imposto-pago/imposto-pago.component";
import {
    RelatorioPorPeriodoComponent
} from "./component/relatorios/relatorio-por-periodo/relatorio-por-periodo.component";

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', component: AppMainComponent,
                children: [
                    {path: '', component: DashboardDemoComponent, canActivate: [AuthGuard]},

                    // Rotas referente o Dashboard
                    {path: 'dashboard/lancamento-por-imposto', component: LancamentoPorImpostoComponent, canActivate: [AuthGuard]},
                    {path: 'dashboard/imposto-em-atraso', component: ImpostoAtrasadoComponent, canActivate: [AuthGuard]},
                    {path: 'dashboard/imposto-conta-pagas', component: ImpostoPagoComponent, canActivate: [AuthGuard]},
                    {path: 'dashboard/evento-por-periodo', component: EventoPorPeriodoComponent, canActivate: [AuthGuard]},

                    {path: 'relatorios/relatorio-por-periodo', component: RelatorioPorPeriodoComponent, canActivate: [AuthGuard]},

                    {path: 'regra-criacao/regra-criacao-view', component: RegraCriacaoViewComponent, canActivate: [AuthGuard]},
                    {path: 'regra-criacao/regra-criacao-list', component: RegraCriacaoListComponent, canActivate: [AuthGuard]},
                    {
                        path: 'gerenciar-transacao-financeira/selecionar-evento',
                        component: SelecaoEventoComponent, canActivate: [AuthGuard]
                    },
                    {
                        path: 'gerenciar-transacao-financeira/evento/:eventoId',
                        component: GerenciarTransacaoFinanceiraComponent, canActivate: [AuthGuard]
                    },
                    {
                        path: 'gerenciar-transacao-financeira/:eventoId/cadastro/:transacaoId',
                        component: CadastroTransacaoFinanceiraComponent, canActivate: [AuthGuard]
                    },
                    {
                        path: 'gerenciar-transacao-financeira/:eventoId/cadastro',
                        component: CadastroTransacaoFinanceiraComponent, canActivate: [AuthGuard]
                    },



                    {path: 'config-conta/configurar-conta-corrente', component: ConfigurarContaCorrenteComponent, canActivate: [AuthGuard]},
                    {path: 'evento-pagamento/evento-pagamento-list', component: EventoPagamentoListComponent, canActivate: [AuthGuard]},
                    {path: 'evento-pagamento/evento-pagamento-cadastrar', component: EventoPagamentoCadastroComponent, canActivate: [AuthGuard]},
                    {path: 'evento-pagamento/evento-pagamento-cadastrar/:eventoPagamentoId', component: EventoPagamentoCadastroComponent, canActivate: [AuthGuard]},
                    {path: 'config-conta-corrente', component: ConfigContaCorrenteTabViewComponent, canActivate: [AuthGuard]},
                    {path: 'config-composicao', component: ConfigComposicaoContaComponent, canActivate: [AuthGuard]},
                    {path: 'config-modelo', component: AssociarModeloContaCorrenteComponent, canActivate: [AuthGuard]},
                    {path: 'config-receita', component: ConfigAssociarReceitaTabViewComponent, canActivate: [AuthGuard]},
                    {path: 'config-base-legal', component: ConfigContaBaseLegalTabViewComponent, canActivate: [AuthGuard]},
                    {path: 'unid-organiza', component: ConfigContaUnidOrganizaTabViewComponent, canActivate: [AuthGuard]},
                    {path: 'perfil-visualizacao', component: ConfigPerfilVisualizacaoComponent, canActivate: [AuthGuard]},
                    {path: 'evento-beneficio/evento-beneficio-list', component: EventoBeneficioListComponent, canActivate: [AuthGuard]},
                    {path: 'evento-compensacao/evento-compensacao-list', component: EventoCompensacaoListComponent, canActivate: [AuthGuard]},
                    {path: 'evento-compensacao/config/:id', component: EventoCompensacaoConfigComponent, canActivate: [AuthGuard]},
                    {path: 'evento-compensacao/config', component: EventoCompensacaoConfigComponent, canActivate: [AuthGuard]},
                    {path: 'evento-estado/evento-estado-list', component: EventoEstadoListComponent, canActivate: [AuthGuard]},
                    {path: 'evento-lancamento/evento-lancamento-list', component: ConfigEventoLancamentoComponent, canActivate: [AuthGuard]},
                    {path: 'evento-pesquisa-config', component: EventoPesquisaConfigComponent, canActivate: [AuthGuard]},
                    {path: 'evento-gerenciar-config', component: EventoGerenciarConfigComponent, canActivate: [AuthGuard]},
                    {path: 'evento-gerenciar-config/:eventosId', component: EventoGerenciarConfigComponent, canActivate: [AuthGuard]},
                    {path: 'evento-gerenciar-config/:eventosId/:configuracaoEventosId', component: EventoGerenciarConfigComponent, canActivate: [AuthGuard]},
                    {path: 'estado-conta/estado-conta-list', component: EstadoContaListComponent, canActivate: [AuthGuard]},
                    {path: 'config-conta/estado-conta/config-modelo-estado', component: ConfigEstadoContaListComponent, canActivate: [AuthGuard]},
                    {path: 'config-conta/estado-conta/config-modelo-estado/:id', component: ConfigEstadoContaListComponent, canActivate: [AuthGuard]},
                    {path: 'config-regra-selecao/config-regra-selecao-list', component: ConfigRegraSelecaoListComponent, canActivate: [AuthGuard]},
                    {path: 'config-regra-selecao/config-regra-selecao-cadastro', component: ConfigRegraSelecaoCadastroComponent, canActivate: [AuthGuard]},
                    {path: 'config-conta/config-conta-list', component: ConfigContaListComponent, canActivate: [AuthGuard]},
                    {path: 'config-beneficio-fiscal', component: ConfigEventoBeneficioFiscalComponent, canActivate: [AuthGuard]},
                    {path: 'config-beneficio-fiscal/:id', component: ConfigEventoBeneficioFiscalComponent, canActivate: [AuthGuard]},
                    {path: 'config-evento-estado', component: ConfigEventoEstadoComponent, canActivate: [AuthGuard]},
                    {path: 'config-evento-estado/:id', component: ConfigEventoEstadoComponent, canActivate: [AuthGuard]},
                    {path: 'config-evento-lancamento', component: ConfigEventoLancamentoComponent, canActivate: [AuthGuard]},
                    {path: 'config-evento-lancamento/:id', component: ConfigEventoLancamentoComponent, canActivate: [AuthGuard]},
                    {path: 'modal-pdf', component: ModalPdfComponent, canActivate: [AuthGuard]},
                    {path: 'criacao-conta', component: CriacaoContaCorrenteComponent, canActivate: [AuthGuard]},
                    {path: 'registro-memoria-calculo', component: RegistrarMemoriaCalculoComponent, canActivate: [AuthGuard]},
                    {path: 'consultar-conta-veiculo', component: ConsultaContaVeiculoComponent, canActivate: [AuthGuard]},
                    {path: 'consultar-debito-contribuinte/:cpfCnpj', component: ConsultaDebitoContribuinteComponent, canActivate: [AuthGuard]},

                    // {path: 'app-selecionar-contrib-interna', component: SelecionarContribInternaComponent},
                    // {path: 'app-selecionar-contrib-interna-list', component: SelecionarContribInternaListComponent},
                    {path: 'extrato-conta-corrente/', component: ExtratoContaCorrenteComponent, canActivate: [AuthGuard]},

                    { path: 'config-regra-geral', loadChildren: () => import('./component/config-regra-geral/config-regra-geral.module').then(m => m.ConfigRegraGeralModule), canActivate: [AuthGuard]},
                    { path: 'config-operadores', loadChildren: () => import('./component/config-operadores/config-operadores.module').then(m => m.ConfigOperadoresModule), canActivate: [AuthGuard]},
                    { path: 'config-fluxos', loadChildren: () => import('./component/config-fluxos/config-fluxos.module').then(m => m.ConfigFluxosModule), canActivate: [AuthGuard]},
                    // { path: 'config-estado-conta', loadChildren: () =>
                    //     import('./component/config-estado-conta/config-estado-conta.module').then(m => m.ConfigEstadoContaModule)
                    // },
                    {path: 'selecionar-contrib-interna', loadChildren: () =>
                    import('./component/selecionar-contrib-interna/selecionar-contrib-interna.module')
                    .then(m => m.SelecionarContribInternaModule), canActivate: [AuthGuard] },
                    {path: 'utilities/display', component: DisplayComponent, canActivate: [AuthGuard]},
                    {path: 'utilities/elevation', component: ElevationComponent, canActivate: [AuthGuard]},
                    {path: 'utilities/flexbox', component: FlexboxComponent, canActivate: [AuthGuard]},
                    {path: 'utilities/grid', component: GridComponent, canActivate: [AuthGuard]},
                    {path: 'utilities/icons', component: IconsComponent, canActivate: [AuthGuard]},
                    {path: 'utilities/widgets', component: WidgetsComponent, canActivate: [AuthGuard]},
                    {path: 'utilities/spacing', component: SpacingComponent, canActivate: [AuthGuard]},
                    {path: 'utilities/typography', component: TypographyComponent, canActivate: [AuthGuard]},
                    {path: 'utilities/text', component: TextComponent, canActivate: [AuthGuard]},
                    {path: 'pages/crud', component: AppCrudComponent, canActivate: [AuthGuard]},
                    {path: 'pages/calendar', component: AppCalendarComponent, canActivate: [AuthGuard]},
                    {path: 'pages/timeline', component: AppTimelineDemoComponent, canActivate: [AuthGuard]},
                    {path: 'pages/invoice', component: AppInvoiceComponent, canActivate: [AuthGuard]},
                    {path: 'pages/help', component: AppHelpComponent, canActivate: [AuthGuard]},
                ]
            },
            {path: 'error', component: AppErrorComponent},
            {path: 'access', component: AppAccessdeniedComponent},
            {path: 'notfound', component: AppNotfoundComponent},
            {path: 'login', component: AppLoginComponent},
            {path: '**', redirectTo: '/notfound'},
        ], {scrollPositionRestoration: 'enabled'})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
