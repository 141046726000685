import {Action} from '@ngrx/store';
import {ConfigContaRegraCriacao} from '../../../models/config-conta-regra-criacao';

export const SET_CONFIG_CONTA_REGRA_CRIACAO = 'SET_CONFIG_CONTA_REGRA_CRIACAO';
export const SET_CONFIGS_CONTAS_REGRAS_CRIACOES = 'SET_CONFIGS_CONTAS_REGRAS_CRIACOES';

export class SetConfigContaRegraCriacao implements Action {
    readonly type = SET_CONFIG_CONTA_REGRA_CRIACAO;
    constructor(public payload: ConfigContaRegraCriacao) {}
}

export class SetConfigsContasRegrasCriacoes implements Action {
    readonly type = SET_CONFIGS_CONTAS_REGRAS_CRIACOES;
    constructor(public payload: ConfigContaRegraCriacao[]) {}
}
