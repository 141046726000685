import {ConfigConta} from './config-conta';
import {TipoCadastro} from './tipo-cadastro';

export class ConfigContaTipoCadastro {

    tipoCadastro?: any = null;
    tipoCadastroId: number = null;
    descricao: string = null;
    tipoCodigoCadastro: string = null;

    constructor(init?: Partial<TipoCadastro>) {
        if (init) {
            Object.assign(this, init);
        }
        // else {
        //     this.tipoCadastroId = null;
        //     this.descricao = null;
        // }
    }

    // configContaTipoCadastroId: number;
    // configConta: ConfigConta;
    // tipoCadastro: TipoCadastro;
    // constructor(init?: Partial<ConfigContaTipoCadastro>) {
    //     if (init) {
    //         Object.assign(this, init);
    //     } else {
    //         this.configContaTipoCadastroId = null;
    //         this.configConta = null;
    //         this.tipoCadastro = null;
    //     }
    // }
}
