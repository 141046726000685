import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
    selector: "app-base-legal",
    templateUrl: "./base-legal.component.html",
    styleUrls: ["./base-legal.component.scss"],
})
export class BaseLegalComponent implements OnInit {
    @Input() form!: FormGroup;
    @Input() validarCampos: boolean;
    @Input() dadosCarregados: any;

    implantado: boolean | null;

    baseLegalForm: FormGroup = new FormBuilder().group({
        idBaseLegal: [null],
    });

    constructor() {}

    ngOnInit(): void {
        const baseLegalForm = this.form.get("baseLegalForm").value;
        if (baseLegalForm) {
            this.baseLegalForm = baseLegalForm;
        } else {
            this.form.get("baseLegalForm").setValue(this.baseLegalForm);
        }

        if (this.dadosCarregados?.idBaseLegal) {
            this.baseLegalForm
                .get("idBaseLegal")
                .setValue(this.dadosCarregados?.idBaseLegal);
            this.dadosCarregados.idBaseLegal = null;
        }
        this.implantado = this.dadosCarregados?.implantado;
    }

    baseLegalChange(event) {
        this.baseLegalForm.get("idBaseLegal").setValue(event?.baseLegalId);
    }
}
