import { ParamConfigEvento } from './../../models/param-config-evento';
import { TiposParamsConfigEventos } from './../../models/tipos-params-config-evento';
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from "@angular/forms";
import { Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { EnumService } from "src/app/compartilhado/services/enum.service";
import { Pageable } from "src/app/shared/pagination/pageable";
import { EventoGerenciarConfigService } from "../../services/evento-gerenciar-config.service";

export const configuracaoFormType: FormGroup = new FormBuilder().group({
    descricaoResumida: [null, Validators.required],
    descricaoCompleta: [null, Validators.required],
    tipoProcessamento: [null, Validators.required],
    dataInicioVigencia: [null],
    dataFimVigencia: [null],
    mudaEstadoConta: [false, idEstadoContaRequiredValidator],
    idEstadoConta: [null],
    mudaSituacaoCobranca: [false, idSituacaoCobrancaRequiredValidator],
    situacaoCobrancaId: [null],
    situacaoZeroPosEvento: [null],
    situacaoCreditoPosEvento: [null],
    situacaoDebitoPosEvento: [null],
    listaBloqueio: [[]],
    lancaHonorario: [false, lancaHonorarioRequiredValidator],
    honorario: [null],
});

class BloqueioConta {
    idSaldoConta: number;
    idSituacaoCredito: number;
    idEstadoConta: number;
    idSituacaoCobranca: number;
}

class Honorario {
    tipoHonorario: string = TipoHonorario.FIXO;
    valorHonorario: number;
    configContaHonorario: number;
    descricaoHonorario: string;
}

enum TipoHonorario {
    FIXO = "FIXO",
    PERCENTUAL = "PERCENTUAL",
}

interface AutoCompleteCompleteEvent {
    originalEvent: Event;
    query: string;
}

@Component({
    selector: "app-configuracao",
    templateUrl: "./configuracao.component.html",
    styleUrls: ["./configuracao.component.scss"],
})
export class ConfiguracaoComponent implements OnInit, OnDestroy {
    subs = new Subscription();

    tiposParam = TiposParamsConfigEventos;

    @Input() form!: FormGroup;
    @Input() validarCampos: boolean;
    @Input() dadosCarregados: any;

    configuracaoForm: FormGroup = configuracaoFormType;

    evento: any;
    tipoProcessamentoList: any[];
    estadoContaList: any[];
    situacaoCobrancaList: any[];
    situacaoCreditoList: any[];
    saldoContaList: any[];
    tipoHonorariosList: any[] = [
        {
            valor: "Fixo",
            chave: "FIXO",
        },
        {
            valor: "Percentual",
            chave: "PERCENTUAL",
        },
    ];

    bloqueioConta: BloqueioConta = new BloqueioConta();

    implantado: boolean | null;

    contaAutoCompleteOptions = [];

    constructor(
        private eventoGerenciarConfigService: EventoGerenciarConfigService,
        private enumService: EnumService
    ) {}

    ngOnInit() {
        this.evento = this.form
            ?.getRawValue()
            ?.eventosForm?.getRawValue()?.evento;

        this.subs.add(
            this.eventoGerenciarConfigService
                .getAllEstadoConta()
                .subscribe((res) => (this.estadoContaList = res))
        );

        this.subs.add(
            this.eventoGerenciarConfigService
                .getAllSituacaoCobranca()
                .subscribe((res) => (this.situacaoCobrancaList = res))
        );

        this.subs.add(
            this.eventoGerenciarConfigService
                .getAllSituacaoCredito()
                .subscribe((res) => (this.situacaoCreditoList = res))
        );

        this.subs.add(
            this.eventoGerenciarConfigService
                .getAllSaldoConta()
                .subscribe((res) => (this.saldoContaList = res))
        );

        this.subs.add(
            this.enumService
                .getByName("TipoProcessamento")
                .subscribe((res) => (this.tipoProcessamentoList = res))
        );


        const configuracaoForm = this.form.get("configuracaoForm").value;

        if (configuracaoForm) {
            this.configuracaoForm = configuracaoForm;
        } else {
            this.form.get("configuracaoForm").setValue(this.configuracaoForm);
        }

        if (this.dadosCarregados?.configuracao) {
            this.configuracaoForm.patchValue(this.dadosCarregados.configuracao);
            this.dadosCarregados.configuracao = null;
        }

        this.configuracaoForm
            .get("lancaHonorario")
            .valueChanges.subscribe((lancaHonorario) => {
                const formHonorario = this.configuracaoForm.get("honorario");
                if (lancaHonorario && !formHonorario.value) {
                    formHonorario.setValue(new Honorario());
                }
                if (lancaHonorario) {
                    this.updateHonorarioValidator();
                } else {
                    this.configuracaoForm.controls["honorario"].setErrors(null);
                }
            });

        this.subs.add(
            this.eventoGerenciarConfigService.onPermissoesCarregadas().subscribe(resp => {
                this.atualizaCamposParaEventoCorrecaoPagamento(resp);
            })
        )


        if (this.dadosCarregados?.implantado) {
            this.implantado = this.dadosCarregados?.implantado;
            this.configuracaoForm.disable();
        }
    }

    private atualizaCamposParaEventoCorrecaoPagamento(params: ParamConfigEvento[]) {
        const mapPermissoesControllers = new Map<TiposParamsConfigEventos, string[]>();
        mapPermissoesControllers.set(TiposParamsConfigEventos.P2_LANCA_HONORARIO, []);
        mapPermissoesControllers.set(TiposParamsConfigEventos.P2_MUDA_ESTADO_CONTA, ["mudaEstadoConta"]);
        mapPermissoesControllers.set(TiposParamsConfigEventos.P2_MUDA_SITUCAO_COBRANCA, [ "mudaSituacaoCobranca"]);
        mapPermissoesControllers.set(TiposParamsConfigEventos.P2_MUDA_SITUCAO_CREDITO, [ "situacaoZeroPosEvento",
        "situacaoCreditoPosEvento",
        "situacaoDebitoPosEvento",]);

        mapPermissoesControllers.forEach((v, k) => {
            const config = params.find(c => c.parametro == k);

             v.forEach(control => {
                if(config && config.obrigatorio) {
                    this.configuracaoForm.get(control).setValidators(Validators.required);
                } else {
                    this.configuracaoForm.get(control).removeValidators(Validators.required);
                }
             });
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    adicionarExcecao() {
        const listaBloqueio: BloqueioConta[] =
            this.configuracaoForm.get("listaBloqueio").value;

        const existeCombinacao: boolean = !!listaBloqueio.find(
            (item) =>
                item.idEstadoConta === this.bloqueioConta.idEstadoConta &&
                item.idSaldoConta === this.bloqueioConta.idSaldoConta &&
                item.idSituacaoCobranca ===
                    this.bloqueioConta.idSituacaoCobranca &&
                item.idSituacaoCredito === this.bloqueioConta.idSituacaoCredito
        );
        if (existeCombinacao) {
            this.bloqueioConta = new BloqueioConta();
            return;
        }
        listaBloqueio.push(this.bloqueioConta);
        this.configuracaoForm.get("listaBloqueio").setValue(listaBloqueio);
        this.bloqueioConta = new BloqueioConta();
    }

    excluirExcecao(conta: BloqueioConta) {
        const listaBloqueio: BloqueioConta[] =
            this.configuracaoForm.get("listaBloqueio").value;
        const indexExcluir = listaBloqueio.findIndex(
            (item) =>
                item.idEstadoConta === conta.idEstadoConta &&
                item.idSaldoConta === conta.idSaldoConta &&
                item.idSituacaoCobranca === conta.idSituacaoCobranca &&
                item.idSituacaoCredito === conta.idSituacaoCredito
        );
        listaBloqueio.splice(indexExcluir, 1);
        this.configuracaoForm.get("listaBloqueio").setValue(listaBloqueio);
    }

    updateHonorarioValidator() {
        const honorario = this.configuracaoForm.get("honorario").value;

        if (
            !honorario.configContaHonorario ||
            !honorario.tipoHonorario ||
            !honorario.descricaoHonorario ||
            !honorario.valorHonorario
        ) {
            this.configuracaoForm.controls["honorario"].setErrors({
                configContaHonorario: !honorario.configContaHonorario,
                tipoHonorario: !honorario.tipoHonorario,
                descricaoHonorario: !honorario.descricaoHonorario,
                valorHonorario: !honorario.valorHonorario,
            });
        } else {
            this.configuracaoForm.controls["honorario"].setErrors(null);
        }
    }

    descricaoSaldoConta(saldoContaId) {
        return this.saldoContaList?.find((s) => s.saldoContaId === saldoContaId)
            ?.descricao;
    }

    descricaoSituacaoCredito(situacaoCreditoId) {
        return this.situacaoCreditoList?.find(
            (s) => s.situacaoCreditoId === situacaoCreditoId
        )?.descricao;
    }

    descricaoEstadoConta(estadoContaId) {
        return this.estadoContaList?.find(
            (s) => s.estadoContaId === estadoContaId
        )?.descricao;
    }

    descricaoSituacaoCobranca(situacaoCobrancaId) {
        return this.situacaoCobrancaList?.find(
            (s) => s.situacaoCobrancaId === situacaoCobrancaId
        )?.descricao;
    }

    buscarContaPorNome(event: AutoCompleteCompleteEvent) {
        this.eventoGerenciarConfigService
            .getContaByNome(event.query)
            .pipe(debounceTime(500))
            .subscribe((s: Pageable<any>) => {
                this.contaAutoCompleteOptions = s.content;
            });
    }

    get desabilitaBotaoAdicionarBloqueio(): boolean {
        return (
            !this.bloqueioConta.idEstadoConta &&
            !this.bloqueioConta.idSaldoConta &&
            !this.bloqueioConta.idSituacaoCobranca &&
            !this.bloqueioConta.idSituacaoCredito
        );
    }
}

function idEstadoContaRequiredValidator(formControl: AbstractControl) {
    if (!formControl.parent) {
        return null;
    }

    if (formControl.value) {
        formControl.parent
            .get("idEstadoConta")
            .setValidators(Validators.required);
    } else {
        formControl.parent.get("idEstadoConta").clearValidators();
        formControl.parent.get("idEstadoConta").setValue(null);
    }

    return null;
}

function idSituacaoCobrancaRequiredValidator(formControl: AbstractControl) {
    if (!formControl.parent) {
        return null;
    }

    if (formControl.value) {
        formControl.parent
            .get("situacaoCobrancaId")
            .setValidators(Validators.required);
    } else {
        formControl.parent.get("situacaoCobrancaId").clearValidators();
        formControl.parent.get("situacaoCobrancaId").setValue(null);
    }

    return null;
}

function lancaHonorarioRequiredValidator(formControl: AbstractControl) {
    if (!formControl.parent) {
        return null;
    }

    if (formControl.value) {
        formControl.parent.get("honorario").setValidators(Validators.required);
    } else {
        formControl.parent
            .get("honorario")
            .removeValidators(Validators.required);
        formControl.parent.get("honorario").setValue(null);
    }

    return null;
}

export function honorarioRequiredValidator(): ValidatorFn {
    return (formControl: AbstractControl): ValidationErrors | null => {
        if (!formControl.parent) {
            return null;
        }

        if (!formControl.parent.get("lancaHonorario").value) {
            const honorario: Honorario = formControl.value;
            return {
                configContaHonorario: !honorario.configContaHonorario,
                tipoHonorario: !honorario.tipoHonorario,
                descricaoHonorario: !honorario.descricaoHonorario,
                valorHonorario: !honorario.valorHonorario,
            };
        }
        return null;
    };
}
