export class RegraVencimento {
    regraVencimentoId: number
    descricao: string
    regraUtilizaAno?: string
    regraUtilizaDia?: string
    regraUtilizaMes?: string
    regraUtilizaQtddias?: string
    regraUtilizaQtdmeses?: string
    formulaDia?: any
    formulaMes?: any
    formulaAno?: any
    formulaQtdDias?: any
    formulaQtdMeses?: any
    formulaQtdAnos?: any
    constructor(init?: Partial<RegraVencimento>) {
        if (init) {
            Object.assign(this, init);
        } else {
            this.descricao = null;
            this.regraUtilizaAno = null;
            this.regraUtilizaDia = null;
            this.regraUtilizaMes = null;
            this.regraUtilizaQtddias = null;
            this.regraUtilizaQtdmeses = null;
            this.regraVencimentoId = null;
            this.formulaDia = null;
            this.formulaMes = null;
            this.formulaAno = null;
            this.formulaQtdDias = null;
            this.formulaQtdMeses = null;
            this.formulaQtdAnos = null
        }
    }
}
