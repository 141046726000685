import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BeneficioFiscalService } from '../../service/beneficio-fiscal.service';
import swal from "sweetalert2";
import { TransacaoSearchRequest } from 'src/app/request/TransacaoSearchRequest';
import { EventoBeneficioSaveAllRequest } from 'src/app/models/evento-beneficio-save-all-request';
import { ActivatedRoute } from '@angular/router';
import { AlertsUtil } from 'src/app/utils/alerts/alerts.util';
@Component({
    selector: "app-associar-transacao-e-regra-selecao",
    templateUrl: "./associar-transacao-e-regra-selecao.component.html",
    styleUrls: ["./associar-transacao-e-regra-selecao.component.scss"],
})
export class AssociarTransacaoERegraSelecaoComponent implements OnInit {
    eventoBeneficioId: number;
    objParams: any;
    transacaoSelecionada: any;
    regraSelecionada: any;
    transacoesAndRegras: any[] = [];
    transacaoList: any[] = [];
    regraList: any[] = [];
    transacaoSearchRequest: TransacaoSearchRequest;
    eventoBeneficioSaveAllRequest: EventoBeneficioSaveAllRequest;

    @Output() outProximaAba = new EventEmitter();
    @Output() outAbaAnterior = new EventEmitter();
    @Output() outRemovePorId = new EventEmitter();
    @Input() associarTransacaoRegra: any[] = [];
    @Input() configEvento: any;
    implantado: boolean;

    constructor(
        private beneficioFiscalService: BeneficioFiscalService,
        private activatedRouter: ActivatedRoute,
        private beneficioService: BeneficioFiscalService,
        private alertsUtil: AlertsUtil,
        ) {
            this.activatedRouter.params.subscribe((eventoId) => {
                this.eventoBeneficioId = eventoId.id;
            });
        }

    ngOnInit(): void {
        this.transacaoSearchRequest = new TransacaoSearchRequest();
        this.getTransacao();
        this.getRegra();
        this.populaCampos();
        this.eventoBeneficioSaveAllRequest = new EventoBeneficioSaveAllRequest();
    }

    populaCampos(){
        if(this.eventoBeneficioId){
            if (!(this.transacoesAndRegras.length === 0 && this.associarTransacaoRegra.length === 0)) {
                this.getDadosTransacaoERegras();
            }
        } else
        if(this.associarTransacaoRegra){
            this.transacoesAndRegras = this.associarTransacaoRegra;
        }
    }

    getDadosTransacaoERegras(){
        let transacoesAndRegrasAux = [];
        this.beneficioFiscalService
            .getTransacoes(this.eventoBeneficioId)
            .subscribe(
                resp => {
                resp.forEach(transacoes => {
                    const objTransacoesAndRegras = {

                            transacaoId: transacoes.transacao.transacaoId,
                            descricao: transacoes.transacao.nomeResumido,
                            operacao: transacoes.transacao.operacao,
                            regraId: transacoes.regra.regraId,
                            descRegra: transacoes.regra.descricao,
                            implantado: transacoes.eventoBeneficio.implantado,
                            eventoTransacaoRegraId: transacoes.eventoTransacaoRegraId
                        };
                        transacoesAndRegrasAux.push(objTransacoesAndRegras);
                        this.implantado = transacoes.eventoBeneficio.implantado
                    });
                    this.transacoesAndRegras = transacoesAndRegrasAux;
                },
                (erro) => {
                this.handleError(erro);
            }
        )
    }

    getTransacao() {
        const data = JSON.parse(JSON.stringify(this.transacaoSearchRequest));
        data.situacao = 'ATIVA';
        data.eventosId = this.configEvento.eventos.eventosId;
        data.tributoId = this.configEvento.tributo.tributoId;
        this.beneficioFiscalService.getTrasacao(data).subscribe(
            (resp: any) => {
                this.transacaoList = resp;
                if (!this.implantado && this.transacaoList.length <= 0) {
                    swal.fire({
                        title: "Atenção",
                        text: `Não há transações ativas para o tipo evento ${this.configEvento.evento.descricao} e Tributo ${this.configEvento.tributo.sigla}`,
                        icon: "warning",
                    });
                }
            },
            (erro) => {
              this.handleError(erro);
            }
        );
    }

    getRegra() {
        this.objParams = {
            situacao: 'ATIVA',
            tipoCategoriaTipoUso: 'TRANSACOES_REGRAS_DO_BENEFICIO_FISCAL',
            tipoCadastroId: this.configEvento.tipoCadastro.tipoCadastroId
        };
        this.beneficioFiscalService.getRegra(this.objParams).subscribe(
            (resp: any) => {
                this.regraList = resp;
                if (!this.implantado && this.regraList.length <= 0) {
                    swal.fire({
                        title: "Atenção",
                        text: `Não há regras ativas da categoria [1 - Benefício Fiscal] cadastradas para todos os tipos de cadastro ou para o cadastro ${this.configEvento.tipoCadastro.descricao}`,
                        icon: "warning",
                    });
                }
            },
            (erro) => {
              this.handleError(erro);
            }
        );
    }

    addTransacaoAndRegra() {
        /**Salvando conta nova */
        if(!this.eventoBeneficioId) {
            this.eventoBeneficioId = 0;
        }
        if (!this.transacaoSelecionada || !this.regraSelecionada) {
            swal.fire({
                icon: "warning",
                title: "Atenção",
                text: "Escolha uma transação e uma regra para adicionar a lista!",
                showConfirmButton: true,
            });
            return;
        }

        if (
            this.transacoesAndRegras.find(
                (p) => p.transacaoId == this.transacaoSelecionada.transacaoId
            )
        ) {
            swal.fire({
                icon: "warning",
                title: "Atenção",
                text: `A Transação com o código ${this.transacaoSelecionada.transacaoId} já existe na lista!`,
                showConfirmButton: true,
            });
            return;
        }

        this.transacoesAndRegras = [
            ...this.transacoesAndRegras,
            {
                transacaoId: this.transacaoSelecionada.transacaoId,
                descricao: this.transacaoSelecionada.nomeResumido,
                operacao: this.transacaoSelecionada.operacao,
                regraId: this.regraSelecionada.regraId,
                descRegra: this.regraSelecionada.descricao,
            },
        ];
        /**Obj saveRequest */
        this.eventoBeneficioSaveAllRequest.transacaoRegraSaveRequests = [
            ...this.eventoBeneficioSaveAllRequest.transacaoRegraSaveRequests,
            {
                eventoBeneficio: {eventoBeneficioId: this.eventoBeneficioId},
                eventoTransacaoRegraId: null,
                regra: {regraId: this.regraSelecionada.regraId},
                transacao: {transacaoId: this.transacaoSelecionada.transacaoId},
            }
        ]
    }

    setTransacoes(){
        return this.eventoBeneficioSaveAllRequest.transacaoRegraSaveRequests;
    }

    removerConta(index, transacao) {
        if(transacao.eventoTransacaoRegraId){
            swal.fire({
                title: "Aviso",
                text: `Deseja remover a transacao ${transacao.transacaoId} - ${transacao.descricao}.`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "SIM",
                cancelButtonText: "NÃO"
            }).then((result) => {
                if (result.isConfirmed) {
                    this.beneficioService
                        .deleteTransacaoRegras(transacao.eventoTransacaoRegraId)
                        .subscribe(
                            (resp) => {
                              this.alertsUtil.saveSuccess('excluido');
                              this.getDadosTransacaoERegras();
                            },
                            (error) => {
                                this.alertsUtil.handleError(error);
                            }
                        );
                }
            });
        } else {
            this.transacoesAndRegras.splice(index, 1);
            this.removePorIdOutput(transacao.transacaoId);
            if(this.eventoBeneficioSaveAllRequest.transacaoRegraSaveRequests.find(
                (aux) => aux.transacao.transacaoId == transacao.transacaoId
            )){
                this.eventoBeneficioSaveAllRequest.transacaoRegraSaveRequests = this.removerPorId(transacao.transacaoId)
            }
        }
    }

    // removerPorId(id) {
    //     return this.eventoBeneficioSaveAllRequest.transacaoRegraSaveRequests.filter(function(el) {
    //         return el.transacao.transacaoId !== id;
    //     });
    // }

    removePorIdOutput(id){
        this.outRemovePorId.emit(id);
    }

    removerPorId(id){
        return this.eventoBeneficioSaveAllRequest.transacaoRegraSaveRequests.filter((item) => item.transacao.transacaoId !== id);
    }

    handleError(error: any): void {
        if (error.status === 500) {
            swal.fire(
                error.statusText,
                "Ocorreu um erro interno",
                "error"
            ).then((r) => {});
        } else {
            swal.fire({
                title: "Atenção",
                text: `${error.error.message}`,
                icon: "warning",
            });
        }
    }

    getTrasacao(){
        return this.transacoesAndRegras;
    }

    proximaAba() {
        if (this.transacoesAndRegras.length > 0) {
            this.outProximaAba.emit(3);
        }
    }

    voltarAba() {
        this.outAbaAnterior.emit(1);
    }
}
