import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EventoPagamentoService {

  constructor(private http: HttpClient) { }

  deleteRegraImputacao(eventoPagamentoId, eventoPagtoRgrImputacaoId): Observable<void> {
    return this.http.delete<void>(
      `${environment.baseUrl}/evento-pagamento/${eventoPagamentoId}/regra-imputacao/${eventoPagtoRgrImputacaoId}`);
  }

  deleteAssociacaoTransacao(eventoPagamentoId, eventoPagamentoTransacaoId): Observable<void> {
    return this.http.delete<void>(
      `${environment.baseUrl}/evento-pagamento/${eventoPagamentoId}/transacao/${eventoPagamentoTransacaoId}`);
  }

  deleteReceita(eventoPagamentoId, eventoPagamentoReceitaId): Observable<void> {
    return this.http.delete<void>(
      `${environment.baseUrl}/evento-pagamento/${eventoPagamentoId}/receita/${eventoPagamentoReceitaId}`);
  }

}
