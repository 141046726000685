import * as EventoPagamentoRegraImputacaoModalActions from './evento-pagamento-regra-imputacao-modal-actions';

const eventoPagamentoRegraImputacaoModalInitialstate = {
    displayMaximizable: false
};

// tslint:disable-next-line:max-line-length
export function eventoPagamentoRegraImputacaoModalReducer(state = eventoPagamentoRegraImputacaoModalInitialstate, action: EventoPagamentoRegraImputacaoModalActions.SetDisplayMaximizable) {
    switch (action.type) {
        case EventoPagamentoRegraImputacaoModalActions.SET_DISPLAY_MAXIMIZABLE:
            return {
                ...state,
                displayMaximizable: action.value
            };
        default:
            return state;
    }
}
