<p-card>
    <ng-template pTemplate="title">
        Configuração de Evento de Lançamento 
    </ng-template>
    <ng-template pTemplate="content">
        <form *ngIf="eventoLancamentoForm" [formGroup]="eventoLancamentoForm" >
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-2">
                    <label>Código</label>
                    <input
                        type="text"
                        [value]="eventoLancamentoId"
                        disabled
                        pInputText
                    />
                </div>
                <div class="p-field p-col-10">
                    <label>Descrição*</label>
                    <input
                     maxlength="150"
                     formControlName="descricao" 
                     pInputText 
                     [pKeyFilter]="somenteLetrasEspaco"  
                     type="text" />
                     <p 
                     class="erro-validacao"
                     *ngIf="(f.descricao.touched)
                         && f.descricao.errors?.required">
                         Campo obrigatório
                     </p>
                </div>

                <div class="p-field p-col-2">
                    <label>Situação*</label>
                    <p-dropdown
                        formControlName="situacao"
                        optionLabel="valor"
                        optionValue="chave"
                        showClear="true"
                        [autoDisplayFirst]="false"
                        [options]="situacaoComboList"
                        [disabled]="!eventoLancamentoId || eventoLancamentoRequest?.implantado === 'NAO'"
                    >
                    </p-dropdown>
                </div>
                <div class="p-field p-col-2">
                    <label>Implantado</label>
                    <p-dropdown
                        formControlName="implantado"
                        optionLabel="valor"
                        optionValue="chave"
                        showClear="true"
                        [autoDisplayFirst]="false"
                        [options]="implantadoEnum"
                        [disabled]="true"
                    >
                    </p-dropdown>
                </div>
                <div class="p-field p-col-4">
                    <label>Início da Vigência*</label>
                    <p-calendar
                        formControlName="dataInicioVigencia"
                        name="dataInicioVigencia"
                        [showIcon]="true"
                        inputId="icon"
                        dateFormat="dd/mm/yy"
                        name="dataInicioVigencia"
                        (onSelect)="onSelectDataMenorVigencia($event)"
                        [disabled]="eventoLancamentoRequest?.implantado === 'SIM'"
                    >
                    </p-calendar>
                    <p 
                    class="erro-validacao"
                    *ngIf="(f.dataInicioVigencia.touched)
                        && f.dataInicioVigencia.errors?.required">
                        Campo obrigatório
                    </p>
                </div>
                <div class="p-field p-col-4">
                    <label>Fim da Vigência</label>
                    <p-calendar
                        formControlName="dataFimVigencia"
                        name="dataFimVigencia"
                        [showIcon]="true"
                        inputId="icon"
                        dateFormat="dd/mm/yy"
                        name="dataFimVigencia"
                        (onSelect)="onSelectDataMenorVigencia($event)"
                    >
                    </p-calendar>
                </div>
                <!-- <div class="p-field p-col-4">
                    <label>Tipo Evento</label>
                    <p-dropdown
                    formControlName="tipoEvento"
                    optionLabel="tipoEvento.descricaoFuncionalidade"
                    optionValue="tipoEvento.tipoEventoId"
                    showClear="true"
                    [autoDisplayFirst]="false"
                    [options]="eventosList"
                    >
                    </p-dropdown>
                </div> -->
                <div class="p-field p-col-4">
                    <label>Eventos de Estado de Criação de Conta*</label>
                    <p-dropdown
                        formControlName="eventoEstado"
                        optionLabel="descricao"
                        optionValue="eventoEstadoId"
                        showClear="true"
                        [autoDisplayFirst]="false"
                        [options]="eventoEstadoComboList"
                        [disabled]="eventoLancamentoRequest?.implantado === 'SIM'"
                    >
                    </p-dropdown>
                    <p 
                    class="erro-validacao"
                    *ngIf="(f.eventoEstado.touched)
                        && f.eventoEstado.errors?.required">
                        Campo obrigatório
                    </p>
                </div>
                <div class="p-field p-col-4">
                    <label>Transação de Lançamento*</label>
                    <p-dropdown
                        formControlName="transacao"
                        optionLabel="nomeCompleto"
                        optionValue="transacaoId"
                        showClear="true"
                        [autoDisplayFirst]="false"
                        [options]="transacaoComboList"
                        [disabled]="eventoLancamentoRequest?.implantado === 'SIM'"
                    >
                    </p-dropdown>
                    <p 
                    class="erro-validacao"
                    *ngIf="(f.transacao.touched)
                        && f.transacao.errors?.required">
                        Campo obrigatório
                    </p>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-2" style="margin-top: 25px">
                    <button
                        pButton
                        pRipple
                        label="Adicionar Conta"
                        type="button"
                        icon="pi pi-plus-circle"
                        (click)="showModalConta()"
                    ></button>
                </div>
            </div>
        </form>
        <div class="p-fluid p-formgrid p-grid">

            <div class="p-field p-col-12">
             
                <p-table [value]="contasAssociadasList">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Código</th>
                            <th>Nome da Conta</th>
                            <th>Situação</th>
                            <th>Início Vigência Associação</th>
                            <th>Fim Vigência Associação</th>
                            <th>Ação</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-conta let-rowIndex="rowIndex">
                        <tr>
                            <td>{{conta.configContaId}}</td>
                            <td>{{conta.nomeDaConta}}</td>
                            <td>{{conta.situacao}}</td>
                            <td>
                                <p-calendar
                                    [disabled]="conta.eventoLancamentoContaId && eventoLancamentoRequest?.implantado === 'SIM'"
                                    [showIcon]="true" 
                                    inputId="icon" 
                                    dateFormat="dd/mm/yy" 
                                    name="inicioVigenciaAssociacao"
                                    [(ngModel)]="conta.inicioVigenciaAssociacao"
                                    (onSelect)="onSelectDataMenorAssociacao(conta, rowIndex)"
                                    >
                                </p-calendar>
                                <p 
                                class="erro-validacao"
                                *ngIf="conta.inicioVigenciaAssociacao === null">
                                    Campo obrigatório
                                </p>
                            </td>
                            <td>
                                <p-calendar
                                [showIcon]="true" 
                                inputId="icon" 
                                dateFormat="dd/mm/yy" 
                                name="fimVigenciaAssociacao"
                                [(ngModel)]="conta.fimVigenciaAssociacao"
                                (onSelect)="onSelectDataMenorAssociacao(conta, rowIndex)"
                                >
                            </p-calendar>
                            </td>
                            <td>
                                <button
                                *ngIf="conta.eventoLancamentoContaId && eventoLancamentoRequest?.implantado === 'SIM'"
                                pButton
                                pRipple
                                title="{{ conta.situacao === 'ATIVA' ? 'Inativar' : 'Ativar' }}"
                                type="button"
                                icon="{{ conta.situacao === 'ATIVA' ? 'pi pi-times' : 'pi pi-check' }}"
                                icon="pi pi-pencil"
                                class="p-button-rounded p-button-secondary"
                                (click)="ativaInativaConta(conta)"
                             ></button>
                            <button
                                (click)="removerConta(rowIndex, conta)"
                                pButton
                                pRipple 
                                title="Excluir" 
                                type="button"
                                icon="pi pi-trash"
                                class="p-button-rounded p-button-danger"
                                style="margin-left: 3px"
                                *ngIf="eventoLancamentoRequest?.implantado === 'NAO' || conta.eventoLancamentoContaId == null"
                                ></button>
                            </td>
                        </tr>
                    </ng-template>    
                </p-table>
                <hr/>
                <button
                    class="float-btn-next"
                    style="width: 15%; margin-right: 8px; margin-top: 20px;"
                    pRipple 
                    label="Salvar"
                    (click)="save()"
                    pButton
                >
                  <i class="pi pi-check"></i>
                </button>
                <a
                class="float-btn-previous p-button-outlined"
                style="width: 15%; margin-right: 8px; margin-top: 20px;"
                pRipple 
                label="Voltar"
                pButton
                routerLink="/evento-lancamento/evento-lancamento-list"
               >
               <i class="pi pi-chevron-left"></i>
            </a>
            </div>
        </div>
    </ng-template>
</p-card>

<app-config-conta-search-modal
[multipleSelection]="false"
(outConfigConta)="outConfigConta($event)"
></app-config-conta-search-modal>

