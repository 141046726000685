import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { EventoPesquisaConfigService } from "src/app/component/evento-pesquisa-config/evento-pesquisa-config.service";
import { EventosFinanceirosService } from "src/app/core/eventos-financeiros.service";
import { EventoGerenciarConfigService } from "../../services/evento-gerenciar-config.service";

export const eventosFormType: FormGroup = new FormBuilder().group({
    eventosId: [null, Validators.required],
    evento: [null],
});
@Component({
    selector: "app-eventos",
    templateUrl: "./eventos.component.html",
    styleUrls: ["./eventos.component.scss"],
})
export class EventosComponent implements OnInit {
    @Input() form: FormGroup;
    @Input() validarCampos: boolean;
    @Input() dadosCarregados: any;

    eventosList: any[];
    eventoSelecionado: any;

    configuracaoEventosList: any[];
    edicaoCategoria: boolean = false;
    eventosForm = eventosFormType;

    constructor(
        private eventosFinanceirosService: EventosFinanceirosService,
        private eventoPesquisaConfigService: EventoPesquisaConfigService,
        private egc: EventoGerenciarConfigService,
        private activatedRoute: ActivatedRoute
    ) {}

    async ngOnInit() {
        const eventosId = Number(
            this.activatedRoute.snapshot.paramMap.get("eventosId")
        );

        const eventosForm = this.form.get("eventosForm").value;
        if (eventosForm) {
            this.eventosForm = eventosForm;
        } else {
            this.form.get("eventosForm").setValue(this.eventosForm);
        }

        if (!!eventosId) {
            this.eventosForm.get("eventosId").setValue(eventosId);
            this.edicaoCategoria = true;
        }

        this.eventosList = await this.eventosFinanceirosService
            .getAllEventos()
            .toPromise();

        await this.carregarEvento();
    }

    async carregarEvento() {
        const eventosId = this.eventosForm.value.eventosId;
        if (!eventosId) {
            this.eventoSelecionado = null;
            this.configuracaoEventosList = [];
            return;
        }

        this.eventoSelecionado = this.eventosList?.find(
            (e) => e.eventosId === eventosId
        );

        this.eventosForm.get("evento").setValue(this.eventoSelecionado);
        this.egc.carregarPermissoesEvento(eventosId);
        
        const response = await this.eventoPesquisaConfigService
            .getConfigEventosByTipoEventoId(
                {
                    eventosId,
                },
                null
            )
            .toPromise();

        this.configuracaoEventosList = response.content;
    }
}
