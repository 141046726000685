<div
    class="layout-wrapper"
    (click)="onLayoutClick()"
    [ngClass]="{
        'layout-menu-horizontal': isHorizontal(),
        'layout-menu-overlay': isOverlay(),
        'layout-menu-static': isStatic(),
        'layout-menu-slim': isSlim(),
        'layout-menu-static-inactive': staticMenuDesktopInactive,
        'layout-menu-mobile-active': staticMenuMobileActive,
        'layout-menu-overlay-active': overlayMenuActive,
        'topbar-icons-active': topbarIconsActive,
        'p-input-filled': app.inputStyle === 'filled',
        'p-ripple-disabled': !app.ripple
    }"
>
    <app-topbar></app-topbar>

    <app-rightpanel></app-rightpanel>

    <div class="layout-sidebar" (click)="onMenuClick($event)">
        <app-menu></app-menu>
    </div>

    <div class="layout-main">
        <!--        <app-breadcrumb></app-breadcrumb>-->

        <div class="layout-content">
            <router-outlet></router-outlet>
        </div>

        <!--        <app-footer></app-footer>-->
        <div
            class="layout-mask"
            *ngIf="staticMenuMobileActive || topbarIconsActive"
        ></div>

        
    </div>

    <!--    <app-config></app-config>-->
</div>
