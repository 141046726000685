import {
    HashLocationStrategy,
    LocationStrategy,
    registerLocaleData,
} from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { EventoCompensacaoListComponent } from "./component/evento-compensacao/evento-compensacao-list/evento-compensacao-list.component";
import { ParamEventoVisivelDirective } from './component/evento-gerenciar-config/directives/param-evento-visivel.directive';

import { FullCalendarModule } from "@fullcalendar/angular";
import { AccordionModule } from "primeng/accordion";
import { AutoCompleteModule } from "primeng/autocomplete";
import { AvatarModule } from "primeng/avatar";
import { AvatarGroupModule } from "primeng/avatargroup";
import { BadgeModule } from "primeng/badge";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { ButtonModule } from "primeng/button";
import { CalendarModule } from "primeng/calendar";
import { CardModule } from "primeng/card";
import { CarouselModule } from "primeng/carousel";
import { CascadeSelectModule } from "primeng/cascadeselect";
import { ChartModule } from "primeng/chart";
import { CheckboxModule } from "primeng/checkbox";
import { ChipModule } from "primeng/chip";
import { ChipsModule } from "primeng/chips";
import { CodeHighlighterModule } from "primeng/codehighlighter";
import { ColorPickerModule } from "primeng/colorpicker";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ConfirmPopupModule } from "primeng/confirmpopup";
import { ContextMenuModule } from "primeng/contextmenu";
import { DataViewModule } from "primeng/dataview";
import { DialogModule } from "primeng/dialog";
import { DividerModule } from "primeng/divider";
import { DropdownModule } from "primeng/dropdown";
import { FieldsetModule } from "primeng/fieldset";
import { FileUploadModule } from "primeng/fileupload";
import { GalleriaModule } from "primeng/galleria";
import { ImageModule } from "primeng/image";
import { InplaceModule } from "primeng/inplace";
import { InputMaskModule } from "primeng/inputmask";
import { InputNumberModule } from "primeng/inputnumber";
import { InputSwitchModule } from "primeng/inputswitch";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { KeyFilterModule } from "primeng/keyfilter";
import { KnobModule } from "primeng/knob";
import { LightboxModule } from "primeng/lightbox";
import { ListboxModule } from "primeng/listbox";
import { MegaMenuModule } from "primeng/megamenu";
import { MenuModule } from "primeng/menu";
import { MenubarModule } from "primeng/menubar";
import { MessageModule } from "primeng/message";
import { MessagesModule } from "primeng/messages";
import { MultiSelectModule } from "primeng/multiselect";
import { OrderListModule } from "primeng/orderlist";
import { OrganizationChartModule } from "primeng/organizationchart";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { PaginatorModule } from "primeng/paginator";
import { PanelModule } from "primeng/panel";
import { PanelMenuModule } from "primeng/panelmenu";
import { PasswordModule } from "primeng/password";
import { PickListModule } from "primeng/picklist";
import { ProgressBarModule } from "primeng/progressbar";
import { RadioButtonModule } from "primeng/radiobutton";
import { RatingModule } from "primeng/rating";
import { RippleModule } from "primeng/ripple";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { ScrollTopModule } from "primeng/scrolltop";
import { SelectButtonModule } from "primeng/selectbutton";
import { SidebarModule } from "primeng/sidebar";
import { SkeletonModule } from "primeng/skeleton";
import { SlideMenuModule } from "primeng/slidemenu";
import { SliderModule } from "primeng/slider";
import { SplitButtonModule } from "primeng/splitbutton";
import { SplitterModule } from "primeng/splitter";
import { StepsModule } from "primeng/steps";
import { TableModule } from "primeng/table";
import { TabMenuModule } from "primeng/tabmenu";
import { TabViewModule } from "primeng/tabview";
import { TagModule } from "primeng/tag";
import { TerminalModule } from "primeng/terminal";
import { TieredMenuModule } from "primeng/tieredmenu";
import { TimelineModule } from "primeng/timeline";
import { ToastModule } from "primeng/toast";
import { ToggleButtonModule } from "primeng/togglebutton";
import { ToolbarModule } from "primeng/toolbar";
import { TooltipModule } from "primeng/tooltip";
import { TreeModule } from "primeng/tree";
import { TreeTableModule } from "primeng/treetable";
import { VirtualScrollerModule } from "primeng/virtualscroller";

import { AppBreadcrumbComponent } from "./app.breadcrumb.component";
import { AppCodeModule } from "./app.code.component";
import { AppComponent } from "./app.component";
import { AppConfigComponent } from "./app.config.component";
import { AppFooterComponent } from "./app.footer.component";
import { AppMainComponent } from "./app.main.component";
import { AppMenuComponent } from "./app.menu.component";
import { AppMenuitemComponent } from "./app.menuitem.component";
import { AppRightPanelComponent } from "./app.rightpanel.component";
import { AppTopBarComponent } from "./app.topbar.component";
import { DashboardDemoComponent } from "./demo/view/dashboarddemo.component";
import { AppAccessdeniedComponent } from "./pages/app.accessdenied.component";
import { AppCalendarComponent } from "./pages/app.calendar.component";
import { AppCrudComponent } from "./pages/app.crud.component";
import { AppErrorComponent } from "./pages/app.error.component";
import { AppHelpComponent } from "./pages/app.help.component";
import { AppInvoiceComponent } from "./pages/app.invoice.component";
import { AppLoginComponent } from "./pages/app.login.component";
import { AppNotfoundComponent } from "./pages/app.notfound.component";
import { AppTimelineDemoComponent } from "./pages/app.timelinedemo.component";
import { DisplayComponent } from "./utilities/display.component";
import { ElevationComponent } from "./utilities/elevation.component";
import { FlexboxComponent } from "./utilities/flexbox.component";
import { GridComponent } from "./utilities/grid.component";
import { IconsComponent } from "./utilities/icons.component";
import { SpacingComponent } from "./utilities/spacing.component";
import { TextComponent } from "./utilities/text.component";
import { TypographyComponent } from "./utilities/typography.component";
import { WidgetsComponent } from "./utilities/widgets.component";

import { BreadcrumbService } from "./app.breadcrumb.service";
import { MenuService } from "./app.menu.service";
import { CountryService } from "./demo/service/countryservice";
import { CustomerService } from "./demo/service/customerservice";
import { EventService } from "./demo/service/eventservice";
import { IconService } from "./demo/service/iconservice";
import { NodeService } from "./demo/service/nodeservice";
import { PhotoService } from "./demo/service/photoservice";
import { ProductService } from "./demo/service/productservice";

import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { StoreModule } from "@ngrx/store";
import { HIGHLIGHT_OPTIONS, HighlightModule } from "ngx-highlightjs";
import { BlockUIModule } from "primeng/blockui";
import { DragDropModule } from "primeng/dragdrop";
import { DialogService, DynamicDialogModule } from "primeng/dynamicdialog";
import { baseLegalSearchModalReducer } from "./component/base-legal/base-legal-search-modal/base-legal-search-modal.reducer";
import { ConfigContaListComponent } from "./component/config-conta/config-conta-list/config-conta-list.component";
import { ConfigContaSearchModalComponent } from "./component/config-conta/config-conta-search-modal/config-conta-search-modal.component";
import { configContaSearchModalReducer2 } from "./component/config-conta/config-conta-search-modal/config-conta-search-modal.reducer";
import { AssociarModeloContaCorrenteComponent } from "./component/configurar-conta-corrente/associar-modelo-conta-corrente/associar-modelo-conta-corrente.component";
import { ConfigAssociarReceitaTabViewComponent } from "./component/configurar-conta-corrente/config-associar-receita-tab-view/config-associar-receita-tab-view.component";
import { ConfigComposicaoContaComponent } from "./component/configurar-conta-corrente/config-composicao-conta/config-composicao-conta.component";
import { ConfigContaBaseLegalTabViewComponent } from "./component/configurar-conta-corrente/config-conta-base-legal-tab-view/config-conta-base-legal-tab-view.component";
import { ConfigContaCorrenteTabViewComponent } from "./component/configurar-conta-corrente/config-conta-corrente-tab-view/config-conta-corrente-tab-view.component";
import { ConfigContaUnidOrganizaTabViewComponent } from "./component/configurar-conta-corrente/config-conta-unid-organiza-tab-view/config-conta-unid-organiza-tab-view.component";
import { ConfigurarContaCorrenteComponent } from "./component/configurar-conta-corrente/configurar-conta-corrente.component";
import { configContaBaseLegalReducer } from "./component/configurar-conta-corrente/configurar-conta-corrente.reducer";
import { ContasCorrentesTabViewComponent } from "./component/contas-correntes-tab-view/contas-correntes-tab-view.component";
import { eventoPagamentoContaExcecaoModalReducer } from "./component/evento-pagamento/evento-pagamento-conta-excecao-modal/evento-pagamento-conta-excecao-modal.reducer";
import { eventoPagamentoEventoCompensacaoModalReducer } from "./component/evento-pagamento/evento-pagamento-evento-compensacao-modal/evento-pagamento-evento-compensacao-modal.reducer";
import { eventoPagamentoParametroConfiguracaoModalReducer } from "./component/evento-pagamento/evento-pagamento-parametro-configuracao-modal/evento-pagamento-parametro-configuracao-modal.reducer";
import { eventoPagamentoRegraIdentificacaoModalReducer } from "./component/evento-pagamento/evento-pagamento-regra-identificacao-modal/evento-pagamento-regra-identificacao-modal.reducer";
import { eventoPagamentoRegraImputacaoModalReducer } from "./component/evento-pagamento/evento-pagamento-regra-imputacao-modal/evento-pagamento-regra-imputacao-modal.reducer";
import { eventoPagamentoTransacaoFinanceiraModalReducer } from "./component/evento-pagamento/evento-pagamento-transacao-financeira-modal/evento-pagamento-transacao-financeira-modal.reducer";
import { RegraCriacaoListComponent } from "./component/regra-criacao/regra-criacao-list/regra-criacao-list.component";
import { RegraCriacaoViewComponent } from "./component/regra-criacao/regra-criacao-view/regra-criacao-view.component";
import {
    regraCriacaoViewReducer,
    regrasCriacoesViewReducer,
} from "./component/regra-criacao/regra-criacao-view/regra-criacao-view.reducer";
import { AssociarPerfilComponent } from "./component/transacao-financeira/components/associar-perfil/associar-perfil.component";
import { ReducaoMultaPunitivaComponent } from "./component/transacao-financeira/components/reducao-multa-punitiva/reducao-multa-punitiva.component";
import { CadastroTransacaoFinanceiraComponent } from "./component/transacao-financeira/pages/cadastro-transacao-financeira/cadastro-transacao-financeira.component";
import { GerenciarTransacaoFinanceiraComponent } from "./component/transacao-financeira/pages/gerenciar-transacao-financeira/gerenciar-transacao-financeira.component";
import { UnidadeOrganizacionalSearchModalComponent } from "./component/unidade-organizacional/unidade-organizacional-search-modal/unidade-organizacional-search-modal.component";
import { ModalItemComposicaoComponent } from "./modal/modal-item-composicao/modal-item-composicao.component";
import { ConditionComponent } from "./shared/condition/condition.component";
import { ConditionalComponent } from "./shared/condition/conditional/conditional.component";
import { FormulaComponent } from "./shared/condition/formula/formula.component";
import { IdentificacaoContaComponent } from "./shared/identificacao-conta/identificacao-conta.component";
import { configContaSearchModalReducer } from "./store/config.conta.search.modal.state";
import { contasCorrentesTabViewReducer } from "./store/contas.correntes.tab.view.state";

import localePt from "@angular/common/locales/pt";
import { AuthComponent } from "./auth/auth.component";
import { BaseLegalModule } from "./component/base-legal/base-legal.module";
import { AssociarContaCorrenteComponent } from "./component/config-evento-beneficio-fiscal/components/associar-conta-corrente/associar-conta-corrente.component";
import { AssociarTransacaoERegraSelecaoComponent } from "./component/config-evento-beneficio-fiscal/components/associar-transacao-e-regra-selecao/associar-transacao-e-regra-selecao.component";
import { CadastrarDestinatarioBeneficioFiscalComponent } from "./component/config-evento-beneficio-fiscal/components/cadastrar-destinatario-beneficio-fiscal/cadastrar-destinatario-beneficio-fiscal.component";
import { EventoBeneficioFiscalComponent } from "./component/config-evento-beneficio-fiscal/components/evento-beneficio-fiscal/evento-beneficio-fiscal.component";
import { ConfigEventoBeneficioFiscalComponent } from "./component/config-evento-beneficio-fiscal/config-evento-beneficio-fiscal.component";
import { ConfigRegraSelecaoCadastroComponent } from "./component/config-regra-selecao/config-regra-selecao-cadastro/config-regra-selecao-cadastro.component";
import { ConfigRegraSelecaoListComponent } from "./component/config-regra-selecao/config-regra-selecao-list/config-regra-selecao-list.component";
import { EstadoContaListComponent } from "./component/estado-conta/estado-conta-list/estado-conta-list.component";
import { EventoBeneficioListComponent } from "./component/evento-beneficio/evento-beneficio-list/evento-beneficio-list.component";
import { EventoCompensacaoConfigComponent } from "./component/evento-compensacao/evento-compensacao-config/evento-compensacao-config.component";
import { RegraInputacaoSelectModalComponent } from "./component/evento-compensacao/regra-inputacao-select-modal/regra-inputacao-select-modal.component";
import { ConfigEventoEstadoComponent } from "./component/evento-estado/config-evento-estado/config-evento-estado.component";
import { EventoEstadoListComponent } from "./component/evento-estado/evento-estado-list/evento-estado-list.component";
import { ConfigEventoLancamentoComponent } from "./component/evento-lancamento/config-evento-lancamento/config-evento-lancamento.component";
import { EventoLancamentoListComponent } from "./component/evento-lancamento/evento-lancamento-list/evento-lancamento-list.component";
import { EventoPagamentoModule } from "./component/evento-pagamento/evento-pagamento.module";
import { SelecionarContribInternaModule } from "./component/selecionar-contrib-interna/selecionar-contrib-interna.module";
import { ReferenciasComponent } from "./component/transacao-financeira/components/referencias/referencias.component";

registerLocaleData(localePt);

import { KeycloakAngularModule, KeycloakService } from "keycloak-angular";
import { NgxSpinnerModule } from "ngx-spinner";
import { environment } from "src/environments/environment";
import { ConfigEstadoContaListComponent } from "./component/config-estado-conta/modules/estado-conta/config-estado-conta-list/config-estado-conta-list.component";
import { ConfigPerfilVisualizacaoComponent } from "./component/configurar-conta-corrente/config-perfil-visualizacao/config-perfil-visualizacao.component";
import { DetalheEventosTransacoesComponent } from "./component/criacao-conta-corrente/component/detalhe-eventos-transacoes/detalhe-eventos-transacoes.component";
import { ConsultaConfigContaComponent } from "./component/criacao-conta-corrente/components/consulta-config-conta/consulta-config-conta.component";
import { ContasAssociadasComponent } from "./component/criacao-conta-corrente/components/contas-associadas/contas-associadas.component";
import { RegistrarMemoriaCalculoComponent } from "./component/criacao-conta-corrente/components/registrar-memoria-calculo/registrar-memoria-calculo.component";
import { VisualizaConfigContaComponent } from "./component/criacao-conta-corrente/components/visualiza-config-conta/visualiza-config-conta.component";
import { ConsultaDebitoContribuinteComponent } from "./component/criacao-conta-corrente/consulta-conta-veiculo/components/consulta-debito-contribuinte/consulta-debito-contribuinte.component";
import { DetalhamentoContaComponent } from "./component/criacao-conta-corrente/consulta-conta-veiculo/components/detalhamento-conta/detalhamento-conta.component";
import { PesquisaContribuinteComponent } from "./component/criacao-conta-corrente/consulta-conta-veiculo/components/pesquisa-contribuinte/pesquisa-contribuinte.component";
import { SelecaoContaComponent } from "./component/criacao-conta-corrente/consulta-conta-veiculo/components/selecao-conta/selecao-conta.component";
import { ConsultaContaVeiculoComponent } from "./component/criacao-conta-corrente/consulta-conta-veiculo/consulta-conta-veiculo.component";
import { DetalheCalculoJurosComponent } from "./component/criacao-conta-corrente/consulta-conta-veiculo/detalhe-calculo-juros/detalhe-calculo-juros.component";
import { CriacaoContaCorrenteComponent } from "./component/criacao-conta-corrente/criacao-conta-corrente.component";
import { EventoPorPeriodoComponent } from './component/dashboard/evento-por-periodo/evento-por-periodo.component';
import { ImpostoAtrasadoComponent } from './component/dashboard/imposto-atrasado/imposto-atrasado.component';
import { ImpostoPagoComponent } from './component/dashboard/imposto-pago/imposto-pago.component';
import {
    LancamentoPorImpostoComponent
} from "./component/dashboard/lancamento-por-imposto/lancamento-por-imposto.component";
import { EventoGerenciarConfigComponent } from "./component/evento-gerenciar-config/evento-gerenciar-config.component";
import { BaseLegalComponent } from "./component/evento-gerenciar-config/passos/base-legal/base-legal.component";
import { ConfiguracaoComponent } from "./component/evento-gerenciar-config/passos/configuracao/configuracao.component";
import { ContasComponent } from "./component/evento-gerenciar-config/passos/contas/contas.component";
import { EventosComponent } from "./component/evento-gerenciar-config/passos/eventos/eventos.component";
import { TransacoesComponent } from "./component/evento-gerenciar-config/passos/transacoes/transacoes.component";
import { EventoPesquisaConfigComponent } from "./component/evento-pesquisa-config/evento-pesquisa-config.component";
import { VisualizaExtratoVeiculoComponent } from "./component/extrato-conta-corrente/components/visualiza-extrato-veiculo/visualiza-extrato-veiculo.component";
import { ExtratoContaCorrenteComponent } from "./component/extrato-conta-corrente/extrato-conta-corrente.component";
import { ModalPdfComponent } from "./component/modal-pdf/modal-pdf.component";
import { RelatorioPorPeriodoComponent } from './component/relatorios/relatorio-por-periodo/relatorio-por-periodo.component';
import { SelecaoEventoComponent } from "./component/transacao-financeira/pages/selecao-evento/selecao-evento.component";
import { ModalAlertaHonorarioComponent } from "./modal/modal-alerta-honorario/modal-alerta-honorario.component";
import { ModalContasAssociadasBuscarComponent } from "./modal/modal-contas-associadas-buscar/modal-contas-associadas-buscar.component";
import { ModalEventoContaCorrenteComponent } from "./modal/modal-evento-conta-corrente/modal-evento-conta-corrente.component";
import { ModalExtratoContaCorrenteComponent } from "./modal/modal-extrato-conta-corrente/modal-extrato-conta-corrente.component";
import { ModalSimularHistoricoComponent } from "./modal/modal-simular-historico/modal-simular-historico.component";
import { ErrorInterceptors } from "./shared/helper/error-interceptors";
import { LoaderInterceptors } from "./shared/helper/loader.interceptors";

FullCalendarModule.registerPlugins([
    dayGridPlugin,
    timeGridPlugin,
    interactionPlugin,
]);

function initializeKeycloak(keycloak: KeycloakService) {
    return () =>
        keycloak.init({
            config: {
                realm: environment.realm,
                url: environment.ssoUrl,
                clientId: environment.ssoClient,
            },
            initOptions: {
                checkLoginIframe: false,
                onLoad: "check-sso",
                silentCheckSsoRedirectUri:
                    window.location.origin + "/assets/silent-check-sso.html",
                redirectUri: checkUrlRedirectKeycloak(),
            },
            shouldAddToken: (request) => {
                const { method, url } = request;

                const isGetRequest = "GET" === method.toUpperCase();
                const acceptablePaths = [
                    "/assets",
                    "/clients/public",
                    "/publico/vistoria/detalhe-ordem",
                ];
                const isAcceptablePathMatch = acceptablePaths.some((path) =>
                    url.includes(path)
                );

                return !(isGetRequest && isAcceptablePathMatch);
            },
        });
}

function checkUrlRedirectKeycloak(): string {
    const url = localStorage.getItem("keycloack-redirect");
    if (url) {
        localStorage.removeItem("keycloack-redirect");
        return environment.redirectUrl + "#" + url;
    }
    return environment.redirectUrl;
}
export function getHighlightLanguages() {
    return {
        sql: () => import("highlight.js/lib/languages/sql"),
    };
}

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        AvatarGroupModule,
        AvatarModule,
        BadgeModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        CascadeSelectModule,
        ChartModule,
        CheckboxModule,
        ChipModule,
        ChipsModule,
        CodeHighlighterModule,
        ConfirmDialogModule,
        ConfirmPopupModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        SelecionarContribInternaModule,
        DialogModule,
        DividerModule,
        DropdownModule,
        FieldsetModule,
        FileUploadModule,
        FullCalendarModule,
        GalleriaModule,
        ImageModule,
        InplaceModule,
        InputNumberModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        KnobModule,
        KeyFilterModule,
        LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        DialogModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        RippleModule,
        ScrollPanelModule,
        ScrollTopModule,
        SelectButtonModule,
        SidebarModule,
        SkeletonModule,
        SlideMenuModule,
        SliderModule,
        SplitButtonModule,
        SplitterModule,
        StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TagModule,
        TerminalModule,
        TieredMenuModule,
        TimelineModule,
        ToastModule,
        ToggleButtonModule,
        SelecionarContribInternaModule,
        KeycloakAngularModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        VirtualScrollerModule,
        AppCodeModule,
        BlockUIModule,
        DragDropModule,
        DynamicDialogModule,
        OverlayPanelModule,
        HighlightModule,

        StoreModule.forRoot(
            {
                app: configContaSearchModalReducer,
                app2: contasCorrentesTabViewReducer,
                regraCriacaoView: regraCriacaoViewReducer,
                regrasCriacoesView: regrasCriacoesViewReducer,
                configContaSearchModal: configContaSearchModalReducer2,
                baseLegalSearchModal: baseLegalSearchModalReducer,
                configContaBaseLegal: configContaBaseLegalReducer,
                eventoPagamentoTransacaoFinanceiraModal:
                    eventoPagamentoTransacaoFinanceiraModalReducer,
                eventoPagamentoRegraImputacaoModal:
                    eventoPagamentoRegraImputacaoModalReducer,
                eventoPagamentoRegraIdentificacaoModal:
                    eventoPagamentoRegraIdentificacaoModalReducer,
                eventoPagamentoParametroConfiguracaoModal:
                    eventoPagamentoParametroConfiguracaoModalReducer,
                eventoPagamentoEventoCompensacaoModal:
                    eventoPagamentoEventoCompensacaoModalReducer,
                eventoPagamentoContaExcecaoModal:
                    eventoPagamentoContaExcecaoModalReducer,
            },
            {}
        ),
        BaseLegalModule,
        EventoPagamentoModule,
        NgxSpinnerModule,


    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppConfigComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppTopBarComponent,
        AppFooterComponent,
        AppBreadcrumbComponent,
        AppRightPanelComponent,
        DashboardDemoComponent,
        DisplayComponent,
        ElevationComponent,
        FlexboxComponent,
        GridComponent,
        IconsComponent,
        AuthComponent,
        WidgetsComponent,
        SpacingComponent,
        TypographyComponent,
        TextComponent,
        AppCrudComponent,
        AppCalendarComponent,
        AppTimelineDemoComponent,
        AppLoginComponent,
        AppInvoiceComponent,
        AppHelpComponent,
        AppNotfoundComponent,
        AppErrorComponent,
        AppAccessdeniedComponent,
        RegraCriacaoViewComponent,
        RegraCriacaoListComponent,
        ContasCorrentesTabViewComponent,
        ConfigContaSearchModalComponent,
        ConfigContaListComponent,
        ConfigurarContaCorrenteComponent,
        ConfigContaBaseLegalTabViewComponent,
        ConfigContaUnidOrganizaTabViewComponent,
        UnidadeOrganizacionalSearchModalComponent,
        ConfigComposicaoContaComponent,
        AssociarModeloContaCorrenteComponent,
        ModalItemComposicaoComponent,
        ConfigAssociarReceitaTabViewComponent,
        GerenciarTransacaoFinanceiraComponent,
        ConfigContaCorrenteTabViewComponent,
        FormulaComponent,
        ConditionComponent,
        ConditionalComponent,
        IdentificacaoContaComponent,
        CadastroTransacaoFinanceiraComponent,
        ReducaoMultaPunitivaComponent,
        AssociarPerfilComponent,
        ReferenciasComponent,
        ConfigEventoBeneficioFiscalComponent,
        EventoBeneficioFiscalComponent,
        EventoBeneficioListComponent,
        AssociarContaCorrenteComponent,
        CadastrarDestinatarioBeneficioFiscalComponent,
        AssociarTransacaoERegraSelecaoComponent,
        ConfigRegraSelecaoListComponent,
        ConfigRegraSelecaoCadastroComponent,
        EventoCompensacaoListComponent,
        EventoEstadoListComponent,
        ConfigEventoEstadoComponent,
        EstadoContaListComponent,
        ConfigEstadoContaListComponent,
        EventoLancamentoListComponent,
        ConfigEventoLancamentoComponent,
        EventoCompensacaoConfigComponent,
        RegraInputacaoSelectModalComponent,
        ExtratoContaCorrenteComponent,
        VisualizaExtratoVeiculoComponent,
        ModalPdfComponent,
        CriacaoContaCorrenteComponent,
        ConsultaConfigContaComponent,
        RegistrarMemoriaCalculoComponent,
        VisualizaConfigContaComponent,
        ConsultaContaVeiculoComponent,
        PesquisaContribuinteComponent,
        SelecaoContaComponent,
        DetalhamentoContaComponent,
        DetalheEventosTransacoesComponent,
        ModalExtratoContaCorrenteComponent,
        ModalEventoContaCorrenteComponent,
        ConsultaDebitoContribuinteComponent,
        DetalheCalculoJurosComponent,
        ModalSimularHistoricoComponent,
        EventoPesquisaConfigComponent,
        ConfigPerfilVisualizacaoComponent,
        ContasAssociadasComponent,
        ModalContasAssociadasBuscarComponent,
        ModalAlertaHonorarioComponent,
        SelecaoEventoComponent,
        EventoGerenciarConfigComponent,
        EventosComponent,
        ConfiguracaoComponent,
        ContasComponent,
        TransacoesComponent,
        BaseLegalComponent,
        ParamEventoVisivelDirective,
        LancamentoPorImpostoComponent,
        ImpostoAtrasadoComponent,
        ImpostoPagoComponent,
        EventoPorPeriodoComponent,
        RelatorioPorPeriodoComponent
    ],
    providers: [
        {
            provide: LOCALE_ID,
            useValue: "pt",
        },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptors,
            multi: true,
            deps: [KeycloakService],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptors,
            multi: true,
        },
        CountryService,
        CustomerService,
        EventService,
        IconService,
        NodeService,
        PhotoService,
        ProductService,
        BreadcrumbService,
        MenuService,
        DialogService,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakService],
        },
        {
            provide: HIGHLIGHT_OPTIONS,
            useValue: {
                coreLibraryLoader: () => import("highlight.js/lib/core"),
                languages: getHighlightLanguages(),
            },
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
