import { destinatarioSaveRequest } from "../request/destinatario-save-request";
import { OrigemCreditoSaveRequests } from "../request/origem-credito-save-requests";
import { ContaContempladaSaveRequests } from "./conta-contemplada-save-request";
import {  EventoBeneficioSaveRequest } from "./evento-beneficio-save-request";
import { TransacaoRegraSaveRequests } from "./transacao-contemplada-save-request";


export class EventoBeneficioSaveAllRequest {
    eventoBeneficioSaveRequest: EventoBeneficioSaveRequest;
    contaContempladaSaveRequests: ContaContempladaSaveRequests[];
    transacaoRegraSaveRequests: TransacaoRegraSaveRequests[];
    destinatarioSaveRequest: destinatarioSaveRequest;
    origemCreditoSaveRequests: OrigemCreditoSaveRequests[];

    constructor(init?: Partial<EventoBeneficioSaveAllRequest>) {
        if (init) {
            Object.assign(this, init);
        } else {
            this.eventoBeneficioSaveRequest = null;
            this.contaContempladaSaveRequests = [];
            this.transacaoRegraSaveRequests = [];
            this.destinatarioSaveRequest = null;
            this.origemCreditoSaveRequests = [];
        }
    }
}
