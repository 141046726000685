import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EstadoEventoSaveRequest } from 'src/app/models/estado-conta';
import { environment } from 'src/environments/environment';
import { Pageable } from '../../shared/pagination/pageable';
import { Pagination } from '../../shared/pagination/pagination';

@Injectable({
  providedIn: 'root'
})
export class EstadoContaService {

  constructor(private http: HttpClient) { }

  getModeloEstado(objParams, pagination: Pagination<any>): Observable<Pageable<any>> {
    // const params = new HttpParams({ fromObject: objParams })
    return this.http.get<Pageable<any>>(`${environment.baseUrl}/estado-conta/search`, {
      params: {
        ...objParams,
        page: pagination.pageable.pageNumber,
        size: pagination.size
      }
    });
  }

  putAtivaInativa(estadoId: number): Observable<void> {
    return this.http.put<void>(`${environment.baseUrl}/estado-conta/${estadoId}/ativa-inativa`, {});
  }

  getEstadoConta(objParams): Observable<Array<any>> {
    const params =  new HttpParams({ fromObject: objParams});
    return this.http.get<Array<any>>(`${environment.baseUrl}/estado-conta`, { params });
  }

  getAtributos(objParams): Observable<Array<any>> {
    const params =  new HttpParams({ fromObject: objParams});
    return this.http.get<Array<any>>(`${environment.baseUrl}/atributo`, {params});
  }

  postEventoEstado(estadoEventoSaveRequest: EstadoEventoSaveRequest){
    return this.http.post<EstadoEventoSaveRequest>(
      `${environment.baseUrl}/evento-estado`, estadoEventoSaveRequest
    );
  }

  delete(estadoContaId): Observable<void> {
    return this.http.delete<void>(
        `${environment.baseUrl}/estado-conta/${estadoContaId}`);
  }

}
