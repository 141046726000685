import * as EventoPagamentoRegraIdentificacaoModalActions from './evento-pagamento-regra-identificacao-modal-actions';

const EventoPagamentoRegraIdentificacaoModalInitialstate = {
    displayMaximizable: false
};

// tslint:disable-next-line:max-line-length
export function eventoPagamentoRegraIdentificacaoModalReducer(state = EventoPagamentoRegraIdentificacaoModalInitialstate, action: EventoPagamentoRegraIdentificacaoModalActions.SetDisplayMaximizable) {
    switch (action.type) {
        case EventoPagamentoRegraIdentificacaoModalActions.SET_DISPLAY_MAXIMIZABLE:
            return {
                ...state,
                displayMaximizable: action.value
            };
        default:
            return state;
    }
}
