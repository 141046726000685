import {Action} from '@ngrx/store';
import {ConfigContaBaseLegal} from '../../models/config-conta-base-legal';


export const SET_CONFIG_CONTA_BASE_LEGAL = 'SET_CONFIG_CONTA_BASE_LEGAL';

export class SetConfigContaBaseLegal implements Action {
    readonly type = SET_CONFIG_CONTA_BASE_LEGAL;
    constructor(public payload: ConfigContaBaseLegal) {}
}
