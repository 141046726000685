import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {
    ConfigComposicaoContaService
} from 'src/app/component/configurar-conta-corrente/config-composicao-conta/config-composicao-conta.service';
import {format} from 'sql-formatter';
import {
    Command,
    CommandType,
    Formula,
    RegraCalculoDto,
    Item,
    ArithmeticOperator,
    RuleType,
    Conditional,
    ConditionType,
    LogicalOperator,
    RelationalOperator
} from 'src/app/shared/condition/condition-node';
import {ContaCorrenteService} from 'src/app/core/conta-corrente.service';
import {AlertsUtil} from 'src/app/utils/alerts/alerts.util';

@Component({
    selector: 'app-condition',
    templateUrl: './condition.component.html',
    styleUrls: ['./condition.component.scss']
})
export class ConditionComponent implements OnInit {

    @Input() regraCalculo: RegraCalculoDto;
    @Input() groupArr: any[];
    @Output() resposta = new EventEmitter();
    @Output() erroSqlOutput = new EventEmitter();

    lastReturn: any;
    lastConditional = [];
    lastElement: any;
    lastChildren = [];
    lastCondition = [];
    tipoRegra: RuleType = RuleType.FORMULA;

    commands: Command[] = [
        {type: CommandType.FORMULA, name: 'FORMULA'},
        {type: CommandType.IF, name: 'SE'},
        {type: CommandType.ELSE, name: 'SENAO'},
        {type: CommandType.ITEM, name: 'ITEM DE COMPOSIÇÃO'},
        {type: CommandType.CONSTANT, name: 'CONSTANTE'},
        {type: CommandType.ARITHMETIC, name: 'OP. ARITMÉTICO'},
        {type: CommandType.RELATIONAL, name: 'OP. RELACIONAL'},
        {type: CommandType.OR, name: 'OU'},
        {type: CommandType.AND, name: 'E'},
        {type: CommandType.SUB, name: '( )'},

    ];

    draggedCommand: Command;

    formulas: Formula[];
    condicionais: Conditional[];

    sqlVisible = false;
    sqlReturn = '';
    erroValidacao = '';
    bloqueiFormulaPai = true;

    constructor(
        private configComposicaoContaService: ConfigComposicaoContaService,
        private contaCorrenteService: ContaCorrenteService,
        private alertUtil: AlertsUtil
    ) {
    }

    ngOnInit(): void {
        this.condicionais = [];  
    }

    validRule() {
        const regraDto: RegraCalculoDto = {
            tipoRegra: this.tipoRegra,
            formulas: this.formulas,
            condicionais: this.condicionais
        };
        this.configComposicaoContaService.postValidaSQL(regraDto).subscribe((res) => {
            this.sqlReturn = format(res.sql);
            this.erroValidacao = res.erro;
            this.sqlVisible = true;
        });
    }

    dragStart(event, command: Command) {
        this.draggedCommand = command;
    }

    dragEnd(event) {
        this.draggedCommand = null;
    }

    drop(event) {
        /**
         * console.log(
            'draggedCommand=>',this.draggedCommand,
             'tipoRegra=>',this.tipoRegra, 
             'condicionais=>',this.condicionais,
             'formula=>',this.formulas);
         */
        if(this.condicionais.length > 0){
            if(this.draggedCommand.type === 0 && this.condicionais.find( aux=> aux.conditionType == 'SE')){
                this.alertUtil.warning('Não é permitida duas expressões SE');
                return;
            }
            if(this.draggedCommand.type === 1 && this.condicionais.find( aux=> aux.conditionType == 'SENAO')){
                this.alertUtil.warning('Não é permitida duas expressões SENAO');
                return;
            }
        }    

        if (this.tipoRegra === 'CONDICIONAL' && this.draggedCommand.name === 'FORMULA' && this.condicionais.length == 0) {
            this.alertUtil.warning('Expressão condicional deve iniciar com o componente SE');
            return;
        }
        if (this.draggedCommand) {
            this.flowState(this.draggedCommand, event);
            this.draggedCommand = null;
        }
    }

    clear() {
        this.formulas = null;
        this.condicionais = null;
        this.lastReturn = null;
        this.lastConditional = [];
        this.lastElement = null;
        this.lastChildren = [];
        this.lastCondition = [];
        this.tipoRegra = RuleType.FORMULA;
    }

    private flowState(command: Command, event) {
        switch (command.type) {
            case CommandType.FORMULA:
                this.addFormula(event);
                break;
            case CommandType.ITEM:
                this.addItem({id: null, name: ''}, event);
                break;
            case CommandType.ARITHMETIC:
                this.addArithmetic(event);
                break;
            case CommandType.CONSTANT:
                this.addConstant('', event);
                break;
            case CommandType.IF:
                if (this.tipoRegra === RuleType.CONDICIONAL) {
                    const conditionIF = {conditionType: ConditionType.IF, children: []};
                    if (!this.condicionais) {
                        this.condicionais = [];
                    }
                    this.condicionais.push(conditionIF);
                    this.lastConditional.push(conditionIF);
                }
                break;
            case CommandType.ELSE:
                if (this.tipoRegra === RuleType.CONDICIONAL) {
                    const conditionElse = {conditionType: ConditionType.ELSE, children: []};
                    this.condicionais.push(conditionElse);
                    this.lastConditional.push(conditionElse);
                }
                break;
            case CommandType.RELATIONAL:
                if (this.tipoRegra === RuleType.CONDICIONAL) {
                    this.addRelational(event);
                }
                break;
            case CommandType.AND:
                if (this.tipoRegra === RuleType.CONDICIONAL) {
                    this.addLogical(LogicalOperator.AND, event);
                }
                break;
            case CommandType.OR:
                if (this.tipoRegra === RuleType.CONDICIONAL) {
                    this.addLogical(LogicalOperator.OR, event);
                }
                break;
            case CommandType.SUB:
                this.addSub(event);
                break;
        }
    }

    private addFormula(event) {
        this.verificaConditionType(this.condicionais);
        if (event.srcElement.className.startsWith('form-group border p-3')) {
            const index = this.lastConditional.length - 1;
            this.condicionais[index].returns = [];
            this.lastReturn = this.condicionais[index].returns;
        } else {
            this.formulas = [];
        }
    }

    verificaConditionType(condicionais) {
        if(condicionais.find(aux => aux.conditionType === 'SE' || aux.conditionType === 'SENAO')){
            this.bloqueiFormulaPai = false;
      } 
      
    }

    private addItem(item: Item, event) {
        if (this.tipoRegra === 'FORMULA') {
            if (event.srcElement.className.startsWith('children')) {
                this.lastChildren.push({item});
            } else {
                this.formulas.push({item});
            }
        } else {
            if (event.srcElement.className.startsWith('form-group border p-3')) {
                if (this.lastReturn) {
                    this.lastReturn.push({item});
                } else {
                    const index = this.lastConditional.length - 1;
                    this.condicionais[index].children.push({item});
                    this.lastCondition.push(item);
                }
            }
        }

    }

    private addConstant(constant: string, event) {
        const item: Formula = {constant};

        if (this.tipoRegra === 'FORMULA') {
            if (event.srcElement.className.startsWith('children')) {
                this.lastChildren.push(item);
            } else {
                this.formulas.push(item);
            }
        } else {
            if (event.srcElement.className.startsWith('form-group border p-3')) {
                if (this.lastReturn) {
                    this.lastReturn.push(item);
                } else {
                    const index = this.lastConditional.length - 1;
                    const indexCond = this.lastCondition.length - 1;
                    this.condicionais[index].children[indexCond].constant = constant;
                }
            }
        }
    }

    private addArithmetic(event) {
        const formula: Formula = {arithmeticOperator: ArithmeticOperator.SUM};
        if (this.lastReturn) {
            this.lastReturn.push(formula);
        } else {
            if (event.srcElement.className.startsWith('children')) {
                this.lastChildren.push(formula);
            } else {
                this.formulas.push(formula);
            }
        }
    }

    private addLogical(operator: LogicalOperator, event) {
        const index = this.lastConditional.length - 1;
        const indexCond = this.lastCondition.length - 1;
        this.condicionais[index].children[indexCond].logical = operator;
    }

    private addRelational(event) {
        const index = this.lastConditional.length - 1;
        const indexCond = this.lastCondition.length - 1;
        this.condicionais[index].children[indexCond].relational = RelationalOperator.EQ;
    }

    private addSub(event) {
        const index = this.formulas.length - 1;
        this.formulas[index].formulaChildren = [];
        this.lastChildren = this.formulas[index].formulaChildren;
    }

    enviarDados() {
        let formulas: Formula[];
        let condicionais: Conditional[];
        if (this.formulas) {
            formulas = JSON.parse(JSON.stringify(this.formulas));
        }
        if (this.condicionais) {
            condicionais = JSON.parse(JSON.stringify(this.condicionais));
        }

        const regraCaclculoDto: RegraCalculoDto = {
            formulas, condicionais, tipoRegra: this.tipoRegra
        };
        this.resposta.emit(regraCaclculoDto);
        this.erroSqlOutput.emit(this.erroValidacao);
    }

    setRegra(regraCalculo) {
        const regra = JSON.parse(regraCalculo);
        if (regra) {
            this.formulas = regra.formulas;
            this.condicionais = regra.condicionais;
            this.tipoRegra = regra.tipoRegra;
        }
    }

    limpar() {
        this.formulas = undefined;
        this.condicionais = [];
        this.lastConditional = [];
        this.lastCondition = [];
        this.sqlReturn = '';
        this.contaCorrenteService.condition = null;
        this.bloqueiFormulaPai = true;
        this.lastReturn = undefined;
    }

}
