import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Chart, ChartConfiguration} from "chart.js";
import {LancamentoPorImpostoService} from "./lancamento-por-imposto.service";
import {LancamentoPorImpostoSearchRequest} from "../../../request/LancamentoPorImpostoSearchRequest";
import {UtilsService} from "../../../shared/utils.service";

export interface ChartData {
    labels: string[];
    datasets: Dataset[];
}

interface Dataset {
    label: string;
    data: number[];
    backgroundColor?: string | string[];
    borderColor?: string; // Opcional, pois nem todos os datasets possuem borderColor
    borderWidth: number;
    stack: string;
    barPercentage?: number;
    categoryPercentage?: number;
}


@Component({
  selector: 'app-lancamento-por-imposto',
  templateUrl: './lancamento-por-imposto.component.html',
  styleUrls: ['./lancamento-por-imposto.component.scss'],
  providers: [LancamentoPorImpostoService]
})


export class LancamentoPorImpostoComponent implements OnInit {
    lancamentoPorImpostoSearchRequest: LancamentoPorImpostoSearchRequest;
    chartDataResponse: any[];

    @ViewChild('meuCanvas', {static: true}) elemento: ElementRef;

    colorEm_Aberto_grupo1: string = 'rgba(66, 165, 245, 0.7)'; // Cor azul meio transparente
    colorEm_Aberto_grupo2: string = 'rgba(255, 167, 38, 0.7)'; // Cor castanho meio transparente
    colorEm_Recolhido: string = 'rgba(205,0,48,1)'; // Cor vernelho meio transparente
    alternateColorFlag: boolean = true;

    transacaoList: any[]
    impostoList: any[]
    anoList: any[]

    campoemBranco: boolean = false;
    DadosemBranco: boolean = false;

    grafico: any
    maxDateFinal: Date | null = null;
    dataInicialReferenciaValue: Date | null = null;
    dataFinalReferenciaValue: Date | null = null;


    constructor(
        public lancamentoPorImpostoService: LancamentoPorImpostoService,
        private utils: UtilsService,
    ) {}

    ngOnInit() {
        this.lancamentoPorImpostoSearchRequest = new LancamentoPorImpostoSearchRequest();
        this.getTransacoesList();
        this.getImpostoList();
    }

    createChart(dataResponse, optionsResponse) {
        this.grafico = new Chart(this.elemento.nativeElement, {
            type: 'bar',
            data: dataResponse,  // Passando os dados da variável
            options: optionsResponse  // Passando as opções da variável
        });
    }

    public chartOptions: ChartConfiguration['options'] = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                stacked: false,
            },
            y: {
                stacked: false,
                beginAtZero: true,
                ticks: {
                    callback: function(value) {
                        return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(value));
                    }
                }
            }
        },
        plugins: {
            legend: {
                display: true,
                position: 'top',
            }
        }
    };


    clear() {
        if(this.grafico){
            this.grafico.destroy()
        }
        this.dataInicialReferenciaValue = null,
        this.dataFinalReferenciaValue = null,
        this.lancamentoPorImpostoSearchRequest = {
            dataInicialReferenciaValue: null,
            dataFinalReferenciaValue: null,
            transacaoId: [],
            impostoId: []
        };
    }

    pesquisar() {

        if (!this.dataInicialReferenciaValue && !this.dataFinalReferenciaValue){
         return this.campoemBranco = true
        }
        this.campoemBranco = false

        this.lancamentoPorImpostoSearchRequest.dataInicialReferenciaValue = this.dataInicialReferenciaValue ? this.utils.convertToSaveAnoMes(this.dataInicialReferenciaValue) : null
        this.lancamentoPorImpostoSearchRequest.dataFinalReferenciaValue = this.dataFinalReferenciaValue ? this.utils.convertToSaveAnoMes(this.dataFinalReferenciaValue) : null

        const data = JSON.parse(JSON.stringify(this.lancamentoPorImpostoSearchRequest));

        return  this.lancamentoPorImpostoService
            .pesquisaDadosDashboard(data)
            .subscribe((response) => {
                if(this.grafico){
                    this.grafico.destroy()
                }

                if (Array.isArray(response) && response.length === 0) {
                    this.DadosemBranco = true;
                    return; // Sai da função se o array estiver vazio
                }

                this.DadosemBranco = false; // Reseta o estado se o array não estiver vazio

                this.chartDataResponse =  this.processChartData(response)

                this.chartDataResponse[0].datasets = this.reorderDatasets(this.chartDataResponse[0].datasets)

                this.createChart(this.chartDataResponse[0], this.chartOptions);

                this.DadosemBranco = false

            })
    }


    getTransacoesList(){
        return this.lancamentoPorImpostoService.getTrasacoes().subscribe((response) => {
            this.transacaoList = response;
        })
    }
    getImpostoList(){
        return this.lancamentoPorImpostoService.getImpostos().subscribe((response) => {
            const impostoTemp = response;

            //informação de impostos abaixo estão presente em outra tabela
            impostoTemp.push({
                "tributoId": 5,
                "descricaoCompleta": "PARCELAMENTO",
                "descricaoResumida": "PARCELAMENTO",
                "sigla": "PARCELAMENTO",
                "codTributo": 5
            },
                {
                "tributoId": 99,
                "descricaoCompleta": "OUTROS",
                "descricaoResumida": "OUTROS",
                "sigla": "OUTROS",
                "codTributo": 99
            })

            this.impostoList = impostoTemp;
        })
    }
    //Limita a seleção para periodo de 1 ano
    onDataInicialChange(dataInicial: Date) {
        if (dataInicial) {
            const maxDate = new Date(dataInicial);
            maxDate.setMonth(maxDate.getMonth() + 11);
            this.maxDateFinal = maxDate;
        } else {
            this.maxDateFinal = null;
        }
        if (this.dataFinalReferenciaValue){
            this.dataFinalReferenciaValue = null
        }
    }

   processChartData(response: ChartData[]): ChartData[] {
        return response.map(chartData => ({
            ...chartData,
            datasets: chartData.datasets.map(dataset => ({
                ...dataset,
                backgroundColor: this.getBackgroundColor(dataset.label), // Função que define a cor de fundo com base no label
                barPercentage: this.getBarPercentage(dataset.label),     // Função que define o barPercentage com base no label
                categoryPercentage: 0.9                              // categoryPercentage padrão para todos
            }))
        }));
    }

// Função para definir a cor de fundo com base no label
   getBackgroundColor(label: string): string {
       if (label.includes('Recolhido')) {
           return this.colorEm_Recolhido; // Substitua por this.colorEm_Recolhido ou a variável de cor adequada
       } else {
           // Alterna entre colorEm_Aberto_grupo1 e colorEm_Aberto_grupo2
           if (this.alternateColorFlag) {
               this.alternateColorFlag = !this.alternateColorFlag; // Inverte a flag
               return this.colorEm_Aberto_grupo1; // Substitua pela cor adequada
           } else {
               this.alternateColorFlag = !this.alternateColorFlag; // Inverte a flag
               return this.colorEm_Aberto_grupo2; // Substitua pela cor adequada
           }
       }
    }

// Função para definir o barPercentage com base no label
   getBarPercentage(label: string): number {
        if (label.includes('Arrecadado')) {
            return 0.8;
        } else {
            return 1.4;
        }
    }

    // Função responável por ordenar os datasets para o Arracado venha antes do esperado
    reorderDatasets(datasets: any[]): any[] {
        // Primeiro, agrupar os datasets pelo stack
        const groupedByStack = datasets.reduce((acc, dataset) => {
            if (!acc[dataset.stack]) {
                acc[dataset.stack] = [];
            }
            acc[dataset.stack].push(dataset);
            return acc;
        }, {} as Record<string, any[]>);

        // Agora, ordenar cada grupo de stack e combinar os resultados

        const orderedDatasets = Object.values(groupedByStack)
            // @ts-ignore
            .map(stackDatasets => stackDatasets.sort((a, b) => {
                if (a.label.includes('Esperado') && b.label.includes('Arrecadado')) {
                    return -1; // Esperado vem antes de Arrecadado
                }
                if (a.label.includes('Arrecadado') && b.label.includes('Esperado')) {
                    return 1; // Arrecadado vem depois de Esperado
                }
                return 0; // Mantém a ordem se ambos forem "Esperado" ou ambos "Arrecadado"
            }))
            .reduce((acc, val) => acc.concat(val), []);

        return orderedDatasets;
    }

}
