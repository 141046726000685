import swal from "sweetalert2";
import { Message } from "primeng/api";
import { Atributo } from "./../../../models/atributo";
import {
    OperadorLogicoEnum,
    OperadorRelacionalEnum,
    RegraParametro,
} from "./../../../models/regra-parametro";
import { TipoCadastro } from "./../../../models/tipo-cadastro";
import { RegraSelecao } from "./../../../models/regra-selecao";
import { TipoCategoria } from "./../../../models/tipo-categoria";
import { Router } from "@angular/router";
import { Validators } from "@angular/forms";
import { RegraSelecaoService } from "./../regra-selecao.service";
import { FormGroup, FormBuilder } from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { SimNaoEnum } from "src/app/utils/enums/sim-nao.enum";
import { AlertsUtil } from "src/app/utils/alerts/alerts.util";
import {UtilsService} from "../../../shared/utils.service";
import { EstadoContaService } from "../../estado-conta/estado-conta.service";
import { EstadoConta } from "src/app/models/estado-conta-regra-selecao";

@Component({
    selector: "app-config-regra-selecao-cadastro",
    templateUrl: "./config-regra-selecao-cadastro.component.html",
    styleUrls: ["./config-regra-selecao-cadastro.component.scss"],
})
export class ConfigRegraSelecaoCadastroComponent implements OnInit {
    tituloPagina: string;
    blockedDocument = false;
    formGroupRegra: FormGroup;
    regraSelecao: RegraSelecao;
    submitted = false;
    situacaoEnum: any[] = [];
    tipoCadastros: any[];
    tipoCategoria: TipoCategoria;
    tipoCategorias: TipoCategoria[] = [];
    regraId: number;
    desabilitaDropdown = false;
    msgs1: Message[];
    ultimoGrupoID: number;
    dataTypeParametros: any[] = [];
    estadoContaList: EstadoConta[] = [];

    listaParametrosView: any[] = [];
    regrasParametros: RegraParametro[] = [];
    selectedParametros: any[] = [];
    selectedAtributo: any;
    max: number;

    ultimaCategoriaID: number;
    ultimoTipoCadastroaID: number;

    SIM: SimNaoEnum = SimNaoEnum.SIM;
    NAO: SimNaoEnum = SimNaoEnum.NAO;

    listaAtributos: Atributo[] = [];
    operadoresLogicoEnum = Object.keys(OperadorLogicoEnum).map((key) => ({
        label: OperadorLogicoEnum[key],
        value: key,
    }));
    operadoresRelacionalEnum = Object.keys(OperadorRelacionalEnum).map(
        (key) => ({ label: OperadorRelacionalEnum[key], value: key })
    );

    constructor(
        public regraSelecaoService: RegraSelecaoService,
        private utilService: UtilsService,
        private formBuilder: FormBuilder,
        private location: Location,
        private router: Router,
        private alertUtil: AlertsUtil,
        private estadoContaService: EstadoContaService
    ) {
        this.iniciaForm();
        if (
            this.router.getCurrentNavigation().extras?.state?.regraId !==
            undefined
        ) {
            this.regraId = JSON.parse(
                JSON.stringify(
                    this.router.getCurrentNavigation().extras.state.regraId
                )
            );
            this.findById(this.regraId);
        } else {
            this.carregarAtributos("");
        }
    }

    ngOnInit(): void {
        this.getEstadoConta();
        this.regraSelecaoService.getSituacoes().subscribe((response) => {
            this.situacaoEnum = response;
        });
        this.regraSelecaoService.getCategorias().subscribe((response) => {
            this.tipoCategorias = response;
        });
        this.regraSelecaoService.getTipoCadastro().subscribe((response) => {
            response.push({descricao: "TODOS", tipoCadastroId: "", tipoCodigoCadastro: ""})
            this.tipoCadastros = response;
        });     
    }

    findById(id: number) {

        this.regraSelecaoService.findById(id).subscribe((response) => {
            this.regraSelecao = response;
            this.tipoCategoria = response.tipoCategoria;
            this.listaParametrosView = response.regraParametros;
            this.selectedAtributo = this.listaParametrosView && this.listaParametrosView.length != 0 ? this.listaParametrosView[0] : null;
            this.calculateMax();
            this.listaParametrosView.forEach((element) => {
                if (element.atributo.datatype == "DATA" && element.valor != null) {
                    element.valor = this.utilService.convertToDateBr(element.valor);
                }
                if(element.atributo.datatype == "SELECT"){
                    element.valor = parseInt(element.valor);
                }
      
                element.atributoId = element.atributo.atributoId;
                element.datatype = {
                    atributoId: null,
                    dataType: "STRING",
                };
            });
            this.defineUltimoGrupoID();
            this.iniciaForm(response);
            this.carregarAtributos(response.tipoCategoria.tipoCategoriaId);
            this.carregarCategoriasByIdCadastro(response.tipoCategoria.tipoCadastro !== null ?response.tipoCategoria.tipoCadastro.tipoCadastroId : "");
            this.ultimaCategoriaID = response.tipoCategoria.tipoCategoriaId;
            this.ultimoTipoCadastroaID =
                response.tipoCategoria.tipoCadastro !== null ?response.tipoCategoria.tipoCadastro.tipoCadastroId : null;
            this.defineDataTypeParametros();
        });
    }

    onChangeAtributo(event: any) {
        let index = this.listaParametrosView.indexOf(event);
        this.listaParametrosView[index].atributo = this.listaAtributos.find(
            (element) => element.atributoId === event.atributoId
            );

        this.selectedAtributo = this.listaParametrosView[index].atributo;
        this.calculateMax();

        this.defineDataTypeParametros();
    }

    private calculateMax() {
        if (this.selectedAtributo.quantidadeDecimal != null) {
            this.selectedAtributo.tamanho = this.selectedAtributo.tamanho - this.selectedAtributo.quantidadeDecimal;
            this.max = (10 ** this.selectedAtributo.tamanho) - 1;
            let min = 10 ** this.selectedAtributo.quantidadeDecimal;
            this.max = this.max + ((min - 1) / min);
        } else {
            this.max = (10 ** this.selectedAtributo.tamanho) - 1;
        }
    }

    defineDataTypeParametros() {
        this.dataTypeParametros = [];
        let dataTypeParametros: any = {};
        this.listaParametrosView.forEach((element) => {
            if (
                element.atributo.nomeSchema != null &&
                element.atributo.nomeTabelaTipo != null
            ) {
                this.regraSelecaoService
                    .listarValoresAtributos(element.atributoId)
                    .subscribe((response) => {
                        dataTypeParametros = {
                            atributoId: element.atributoId,
                            datatype: "TABELA",
                            value: response,
                        };
                        element.datatype = dataTypeParametros;
                    });
            } else if (element.atributo.datatype != null) {
                dataTypeParametros = {
                    atributoId: element.atributoId,
                    datatype: element.atributo.datatype,
                };
                element.datatype = dataTypeParametros;
            } else {
                dataTypeParametros = {
                    atributoId: element.atributoId,
                    datatype: "STRING",
                };
                element.datatype = dataTypeParametros;
            }
            dataTypeParametros = {};
        });
    }

    carregarAtributos(id: any) {
        this.regraSelecaoService
            .getAtributosByIdCategoria(id)
            .subscribe((response) => {
                this.listaAtributos = response;
            });
    }
    carregarCategoriasByIdCadastro(id: any) {
        this.regraSelecaoService
            .getCategoriasByIdCadastro(id)
            .subscribe((response) => {
                this.tipoCategorias = response;
                this.desabilitaDropdown = false;
            });
    }

    onChangeTipoCadastro(e) {
        this.desabilitaDropdown = true;

        this.desabilitaDropdown = false;
        if (this.listaParametrosView.length === 0) {
            this.regraSelecaoService
                .getCategoriasByIdCadastro(e.value)
                .subscribe((response) => {
                    this.tipoCategorias = response;
                    this.desabilitaDropdown = false;
                });
            this.ultimoTipoCadastroaID = e.value;
        } else {
            swal.fire({
                title: "Atenção",
                text: "Deseja realmente alterar o Tipo Cadastro? Se houver parametros já adicionados os mesmos serão removidos!",
                icon: "warning",
                showCancelButton: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.listaParametrosView = [];
                    this.regraSelecaoService
                        .getCategoriasByIdCadastro(e.value)
                        .subscribe((response) => {
                            this.tipoCategorias = response;
                            this.desabilitaDropdown = false;
                        });
                    this.ultimoTipoCadastroaID = e.value;
                } else {
                    this.formGroupRegra.patchValue({
                        tipoCadastro: this.ultimoTipoCadastroaID,
                    });
                }
            });
        }

    }

    onChangeTipoCategoria(e) {
        this.desabilitaDropdown = true;
        let tipoCadastroFilter = this.tipoCategorias.filter(
            (x) => x.tipoCategoriaId == e.value
        );
        this.desabilitaDropdown = false;
        if (this.listaParametrosView.length === 0) {
            this.carregarAtributos(e.value);
            this.formGroupRegra.patchValue({
                tipoCadastro: tipoCadastroFilter[0].tipoCadastro !== null ? tipoCadastroFilter[0].tipoCadastro.tipoCadastroId : null,
            });
            this.ultimaCategoriaID = e.value;
        } else {
            swal.fire({
                title: "Atenção",
                text: "Deseja realmente alterar a categoria? Se houver parametros já adicionados os mesmos serão removidos!",
                icon: "warning",
                showCancelButton: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.listaParametrosView = [];
                    this.carregarAtributos(e.value);
                    this.formGroupRegra.patchValue({
                        tipoCadastro:
                            tipoCadastroFilter[0].tipoCadastro.tipoCadastroId,
                    });
                    this.ultimaCategoriaID = e.value;
                } else {
                    this.formGroupRegra.patchValue({
                        tipoCategoria: this.ultimaCategoriaID,
                    });
                }
            });
        }
    }
    newParametro() {
        if (this.formGroupRegra.controls.tipoCategoria.value != null) {
            let parametro = {
                atributo:
                    this.listaAtributos.length > 0
                        ? this.listaAtributos[0]
                        : null,
                atributoId:
                    this.listaAtributos.length > 0
                        ? this.listaAtributos[0].atributoId
                        : null,
                operadorLogico: null,
                operadorRelacional: null,
                regraParamId: this.regraId != null ? this.regraId : null,
                valor: "",
                datatype: {
                    atributoId: null,
                    dataType: "STRING",
                },
            };
            this.listaParametrosView.push(parametro);
            this.selectedAtributo = this.listaAtributos[0];
            this.calculateMax();
            this.defineDataTypeParametros();
        } else {
            swal.fire({
                title: "Atenção",
                text: "Selecione uma Categoria para poder adicionar parâmetros!",
                icon: "warning",
                showCancelButton: true,
            });
            return;
        }
    }

    removeParameter(obj: any) {
        let index = this.listaParametrosView.indexOf(obj);

        let grupo = this.listaParametrosView[index].grupo;
        this.listaParametrosView.splice(index, 1);
        let elementosComMesmoGrupo = this.listaParametrosView.filter(value => value.grupo == grupo);

        if (elementosComMesmoGrupo.length == 1) {
            elementosComMesmoGrupo.forEach(elemento => elemento.grupo = null);
        }
    }

    removeParameters() {
        if(!this.selectedParametros || this.selectedParametros.length < 2) {
            swal.fire({
                title: "Atenção",
                text: "Selecione um parâmetro excluir",
                icon: "warning",
            });

            return;
        }

        if(!this.selectedParametros.find(element => element.grupo != null)) {
            swal.fire({
                title: "Atenção",
                text: "Selecione um parâmetro que tenha um GRUPO",
                icon: "warning",
            });

            return;
        }

        swal.fire({
            title: "Atenção",
            text: "Deseja remover os parâmetros selecionados?",
            icon: "warning",
            showCancelButton: true,
        }).then((result) => {
            if (result.value) {
                this.selectedParametros.forEach((element) => {
                    let index = this.listaParametrosView.indexOf(element);
                    this.listaParametrosView.splice(index, 1);
                });
                this.selectedParametros = [];
            }
        });
    }

    defineUltimoGrupoID() {
        if (this.listaParametrosView.length > 1) {
            let grupo = this.listaParametrosView
                .filter((x) => x.grupo != null)
                .map((x) => x.grupo);

            this.ultimoGrupoID = grupo.length > 0 ?
                grupo.reduce((a, b) => Math.max(a, b)) : null;
        }
        if (this.ultimoGrupoID == null) {
            this.ultimoGrupoID = 0;
        } else {
            this.ordenaParametrosPorGrupoERegraParametroID();
        }
    }

    agruparSelecionados() {

        if(!this.selectedParametros || this.selectedParametros.length < 2) {
            swal.fire({
                title: "Atenção",
                text: "Selecione no mínimo dois parâmtros para realizar o agrupamento.",
                icon: "warning",
            });

            return;
        }

        if(this.selectedParametros.find(element => element.grupo != null)) {
            swal.fire({
                title: "Atenção",
                text: "Um item já agrupado não pode ser agrupado novamente.",
                icon: "warning"
            });
            return;
        }

        swal.fire({
            title: "Atenção",
            text: "Deseja agrupar os parâmetros selecionados? Os parâmetros selecionados serão agrupados em um único grupo!",
            icon: "warning",
            showCancelButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                this.selectedParametros.forEach((element) => {
                    element.grupo =
                    this.ultimoGrupoID != null ? this.ultimoGrupoID + 1 : 1;
                });

                this.defineUltimoGrupoID();
                this.ordenaParametrosPorGrupoERegraParametroID();
                this.selectedParametros = [];
            }
        });
    }

    removerGrupo(rowIndex) {

        if (this.selectedParametros &&
            (this.selectedParametros.length == 1 && this.selectedParametros[0].grupo == null) ||
            (this.selectedParametros.every(element => element.grupo == null))) {
                this.alertUtil.warning(`
                Selecione um parâmetro que tenha um grupo vinculado.`
            );
            return;
        }

        if(!this.selectedParametros || this.selectedParametros.length == 0) {
            this.alertUtil.warning(`
            Selecione um parâmetro desagrupar.`
            );
            return;
        }

        this.alertUtil.warning(`
            Deseja desagrupar os parâmetros selecionados?`
        ).then((result) => {
            if (result.isConfirmed) {
                let grupo;
                let elementosComMesmoGrupo;

                if (rowIndex != null) {
                    grupo = this.listaParametrosView[rowIndex].grupo;
                    this.listaParametrosView[rowIndex].grupo = null;
                    elementosComMesmoGrupo = this.listaParametrosView.filter(value => value.grupo == grupo);
                } else {
                    this.selectedParametros.forEach((element) => {
                        grupo = element.grupo;
                        element.grupo = null;
                    });
                    this.selectedParametros = [];
                    elementosComMesmoGrupo = this.listaParametrosView.filter(value => value.grupo == grupo);
                }

                if (elementosComMesmoGrupo.length == 1) {
                    elementosComMesmoGrupo.forEach(elemento => elemento.grupo = null);
                }

                this.defineUltimoGrupoID();
                this.ordenaParametrosPorGrupoERegraParametroID();
            }
        });
    }

    ordenaParametrosPorGrupoERegraParametroID() {
        this.listaParametrosView.sort((a, b) => {
            if (a.grupo > b.grupo) {
                return 1;
            }
            if (a.grupo < b.grupo) {
                return -1;
            }
            if (a.regraParamId > b.regraParamId) {
                return 1;
            }
            if (a.regraParamId < b.regraParamId) {
                return -1;
            }
            return 0;
        });
    }

    async iniciaForm(obj?: RegraSelecao) {
        this.formGroupRegra = this.formBuilder.group({
            regraId: [obj ? obj.regraId : null],
            descricao: [obj ? obj.descricao : null, Validators.required],
            situacao: [obj ? obj.situacao : "ATIVA", Validators.required],
            tipoCadastro: [
                obj ? obj.tipoCategoria.tipoCadastro != null ? obj.tipoCategoria.tipoCadastro.tipoCadastroId : "" : null
            ],
            tipoCategoria: [
                obj ? obj.tipoCategoria.tipoCategoriaId : null,
                Validators.required,
            ],
        });

        if (obj && this.regraId) {
            this.desabilitaDropdown = true;
            this.tituloPagina = "Editar Regra de Seleção";
        } else {
            this.tituloPagina = "Cadastrar Regra de Seleção";
        }
    }

    onSubmit() {
        this.submitted = false;
        if (!this.formGroupRegra.valid) {
            this.submitted = true;
            return;
        }
        let parametrosInvalidos = false;

        if(this.listaParametrosView.length == 0) {
            this.alertUtil.warning(`Pelo menos um parâmetro deve ser informado.`);
            return;
        }

        this.listaParametrosView.forEach((element) => {
            if (element.atributoId == null) {
                parametrosInvalidos = true;
            }
            if (element.valor == null || element.valor == "") {
                parametrosInvalidos = true;
            }
        });
        if (parametrosInvalidos) {
            this.alertUtil.warning(`
            Todos os parâmetros adicionados devem possuir um Atributo selecionado e valor informado.`
            );
            return;
        }
        this.blockedDocument = true;
        this.regraSelecao = this.generateRegraSelecao();

        this.regraSelecaoService.save(this.regraSelecao).subscribe(
            (data) => {
                this.findById(data.regraId);
                this.alertUtil.saveSuccess(`Regra de Seleção ${data.regraId} salva com sucesso`).then(
                    () => {
                        this.router.navigateByUrl('config-regra-selecao/config-regra-selecao-list');
                    }
                );
                this.blockedDocument = false;
                this.selectedParametros = [];
            },
            (error) => {
                this.handleError(error);
                this.blockedDocument = false;
            }
        );
    }

    onEditar(regra: RegraSelecao) {
        this.router
            .navigate(["config-regra-selecao/config-regra-selecao-cadastro"], {
                state: { regraId: regra.regraId },
            })
            .then(() => {});
    }

    generateRegraSelecao() {
        let regraSelecao = new RegraSelecao();
        regraSelecao.regraId = this.formGroupRegra.value.regraId;
        regraSelecao.descricao = this.formGroupRegra.value.descricao;
        regraSelecao.situacao = this.formGroupRegra.value.situacao;
        regraSelecao.tipoCategoria = new TipoCategoria();
        regraSelecao.tipoCategoria = this.tipoCategorias.find(
            (x) => x.tipoCategoriaId == this.formGroupRegra.value.tipoCategoria
        );
        regraSelecao.tipoCategoriaId = this.formGroupRegra.value.tipoCategoria;
        regraSelecao.tipoCategoria.tipoCadastro = new TipoCadastro();
        regraSelecao.tipoCategoria.tipoCadastro = this.tipoCadastros.find(
            (x) => x.tipoCadastroId == this.formGroupRegra.value.tipoCadastro
        );

        this.listaParametrosView.forEach((p) => {
            p.atributo = this.listaAtributos.find(
                (x) => x.atributoId == p.atributoId
            );
            if(p.datatype.datatype === "DATA") {
                p.valor = this.utilService.convertDateToSave(p.valor);
            }
        });
        regraSelecao.regraParametroSaveRequests = this.listaParametrosView;

        return regraSelecao;
    }

   
    getEstadoConta(){
        const objParams = {situacao: "ATIVA"};
        this.estadoContaService.getEstadoConta(objParams).subscribe((resp: EstadoConta[]) =>{
            this.estadoContaList = resp;    
        }, error =>{
            this.alertUtil.handleError(error)
        })
    }

    handleError(error: any): void {
        if (error.status === 500) {
            swal.fire(
                error.statusText,
                "Ocorreu um erro interno",
                "error"
            ).then((r) => {});
        } else if (error.status === 412) {
            swal.fire({
                title: "Atenção",
                text: `${error.error.message}`,
                icon: "warning",
            });
        } else if (error.status === 400) {
            const errors = error.error.errors;
            this.msgs1 = [];
            errors.forEach((e: { message: any }) => {
                this.msgs1.push({
                    severity: "error",
                    summary: "Erro",
                    detail: e.message,
                });
            });
        } else {
            swal.fire({
                title: "ERROR",
                text: `${error.error.message}`,
                icon: "error",
            });
        }
    }

    voltar() {
        this.location.back();
    }
}
