export enum ConditionType {
  IF = 'SE', ELSE = 'SENAO'
}

export enum CommandType {
  IF, ELSE, FORMULA, RELATIONAL, ARITHMETIC, ITEM, CONSTANT, AND, OR, SUB
}

export enum LogicalOperator {
  AND = 'E', OR = 'OU'
}

export interface Item {
  id?: number,
  name?: string;
}

export enum RuleType {
  FORMULA = 'FORMULA', CONDICIONAL = 'CONDICIONAL'
}

export interface Formula {
  item?: Item;
  arithmeticOperator?: ArithmeticOperator;
  constant?: string;
  formulaChildren?: Formula[];
}

export interface Conditional {
  conditionType?: ConditionType;
  constant?: string;
  item?: Item;
  logical?: LogicalOperator;
  relational?: RelationalOperator;
  returns?: Formula[];
  children?: Conditional[];
}

export enum RelationalOperator {
  EQ = '=',
  GT = '>',
  GE = '>=',
  LT = '<',
  LE = '<=',
  NOT_EQ = '!=',
  /**
  IS_NULL = 'IS NULL',
  IS_NOT_NULL = 'IS NOT NULL',
  LIKE = '%',
  IN = 'IN'
   */
}

export enum ArithmeticOperator {
  SUM = '+',
  MULTIPLICATION = '*',
  DIVISION = '/',
  SUBTRACTION = '-'
}

export interface Command {
  type?: CommandType;
  name?: string;
  icon?: string;
  enable?: boolean;
}

export interface RegraCalculoDto {
  condicionais?: Conditional[];
  formulas?: Formula[];
  tipoRegra?: RuleType;
}