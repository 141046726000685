import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { BeneficioFiscalService } from "../../service/beneficio-fiscal.service";
import swal from "sweetalert2";
import {
    ConfigContaId,
    ContaContempladaSaveRequests,
} from "src/app/models/conta-contemplada-save-request";
import { EventoBeneficioSaveAllRequest } from "src/app/models/evento-beneficio-save-all-request";
import { ActivatedRoute } from "@angular/router";
import { AlertsUtil } from "src/app/utils/alerts/alerts.util";
@Component({
    selector: "app-associar-conta-corrente",
    templateUrl: "./associar-conta-corrente.component.html",
    styleUrls: ["./associar-conta-corrente.component.scss"],
})
export class AssociarContaCorrenteComponent implements OnInit {
    eventoBeneficioId: number;
    contas: any[] = [];
    contaSelecionada: any;
    contaList: any[] = [];
    objParams: any;
    @Input() configEvento: any;
    @Input() associarContaCorrente: any[] = [];
    @Output() outProximaAba = new EventEmitter();
    @Output() outAbaAnterior = new EventEmitter();
    @Output() outRemovePorId = new EventEmitter();
    contaContempladaSaveRequests: ContaContempladaSaveRequests;
    eventoBeneficioSaveAllRequest: EventoBeneficioSaveAllRequest;
    configContaId: ConfigContaId;
    implantado;
    constructor(
        private beneficioService: BeneficioFiscalService,
        private activatedRouter: ActivatedRoute,
        private alertsUtil: AlertsUtil
    ) {
        this.activatedRouter.params.subscribe((eventoId) => {
            this.eventoBeneficioId = eventoId.id;
        });
    }

    ngOnInit(): void {
        this.getContasContempladas();
        this.populaCampos();
        this.eventoBeneficioSaveAllRequest =
            new EventoBeneficioSaveAllRequest();
    }

    populaCampos() {
        if (this.eventoBeneficioId) {
            if (!(this.contas.length === 0 && this.associarContaCorrente.length === 0)) {
                this.getDadosOrigemCredito();
            }
        } else if (this.associarContaCorrente) {
            this.contas = this.associarContaCorrente;
        }
    }

    getDadosOrigemCredito() {
        let contAux = [];
        let contAuxEdit = [];
        this.beneficioService
            .getContaContemPlada(this.eventoBeneficioId)
            .subscribe(
                (response) => {
                    response.forEach((element) => {
                        const objContaComtemplada = {
                            configContaId: element.configConta.configContaId,
                            nomeResumido: element.configConta.nomeResumido,
                            tributo: element.eventoBeneficio.tributo.sigla,
                            implantado: element.eventoBeneficio.implantado,
                            eventoBeneficio:  element.eventoContaContId
                        };
                        const objContaEdit =
                            {
                                configConta: { configContaId: element.configConta.configContaId },
                                eventoBeneficio: { eventoBeneficioId: this.eventoBeneficioId },
                                eventoContaContId: null,
                            }
                        contAux.push(objContaComtemplada);
                        contAuxEdit.push(objContaEdit);
                        this.implantado = element.eventoBeneficio.implantado
                    });
                    this.contas = contAux;
                },
                (erro) => {
                    this.handleError(erro);
                }

            );
    }

    getContasContempladas() {
        this.objParams = {
            situacao: "ATIVA",
            implantado: "SIM",
            tributoId: this.configEvento.tributo.tributoId,
            tipoCadastroId: this.configEvento.tipoCadastro.tipoCadastroId,
            dataFimVigencia: "01/01/1900",
        };
        this.beneficioService.getContas(this.objParams).subscribe(
            (resp) => {
                this.contaList = resp;
                if (!this.implantado && this.contaList.length <= 0) {
                    swal.fire({
                        title: "Atenção",
                        text: `Não há contas ativas e implantadas configuradas para o cadastro ${this.configEvento.tipoCadastro.descricao} e Tributo ${this.configEvento.tributo.sigla}`,
                        icon: "warning",
                    });
                }
            },
            (erro) => {
                this.handleError(erro);
            }
        );
    }

    addConta() {
        /**Salvando conta nova */
        if(!this.eventoBeneficioId) {
            this.eventoBeneficioId = 0;
        }
        if (!this.contaSelecionada) {
            swal.fire({
                icon: "warning",
                title: "Atenção",
                text: "Escolha uma conta para adicionar a lista!",
                showConfirmButton: true,
            });
            return;
        }

        if (
            this.contas.find(
                (p) => p.configContaId == this.contaSelecionada.configContaId
            )
        ) {
            swal.fire({
                icon: "warning",
                title: "Atenção",
                text: `A conta com o código ${this.contaSelecionada.configContaId} já existe na lista!`,
                showConfirmButton: true,
            });
            return;
        }

        this.contas = [
            ...this.contas,
            {
                configContaId: this.contaSelecionada.configContaId,
                nomeResumido: this.contaSelecionada.nomeResumido,
                situacao: this.contaSelecionada.situacao,
                tributo: this.contaSelecionada.tributo.sigla,
            },
        ];

        this.configContaId = this.contaSelecionada.configContaId;
        /**Obj saveRequest */
        this.eventoBeneficioSaveAllRequest.contaContempladaSaveRequests = [
            ...this.eventoBeneficioSaveAllRequest.contaContempladaSaveRequests,
            {
                configConta: { configContaId: this.configContaId },
                eventoBeneficio: { eventoBeneficioId: this.eventoBeneficioId },
                eventoContaContId: null,
            },
        ];
    }

    setContas() {
        return this.eventoBeneficioSaveAllRequest.contaContempladaSaveRequests;
    }

    removerConta(index, conta) {
        if(conta.eventoBeneficio){
            swal.fire({
                title: "Aviso",
                text: `Deseja remover a conta ${conta.configContaId} - ${conta.nomeResumido}.`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "SIM",
                cancelButtonText: "NÃO"
            }).then((result) => {
                if (result.isConfirmed) {
                    this.beneficioService
                        .deleteContaContemplada(conta.eventoBeneficio)
                        .subscribe(
                            (resp) => {
                              this.alertsUtil.saveSuccess('excluido');
                              this.getDadosOrigemCredito();
                            },
                            (error) => {
                                this.alertsUtil.handleError(error);
                            }
                        );
                }
            });
        } else {
            this.contas.splice(index, 1);
            this.removePorIdOutput(conta.configContaId);
            if(this.eventoBeneficioSaveAllRequest.contaContempladaSaveRequests.find(
                (aux) => aux.configConta.configContaId == conta.configContaId
            )){
                this.eventoBeneficioSaveAllRequest.contaContempladaSaveRequests = this.removerPorId(conta.configContaId);
            }
        }

    }

    // removerPorIdRepetido(id) {
    //     return this.eventoBeneficioSaveAllRequest.contaContempladaSaveRequests.filter(function(el) {
    //         return el.configConta.configContaId !== id;
    //     });
    // }

    removePorIdOutput(id){
        this.outRemovePorId.emit(id);
    }

    removerPorId(id){
        return this.eventoBeneficioSaveAllRequest.contaContempladaSaveRequests.filter((item) => item.configConta.configContaId !== id);
    }

    handleError(error: any): void {
        if (error.status === 500) {
            swal.fire(
                error.statusText,
                "Ocorreu um erro interno",
                "error"
            ).then((r) => {});
        } else {
            swal.fire({
                title: "Atenção",
                text: `${error.error.message}`,
                icon: "warning",
            });
        }
    }

    getContas() {
        return this.contas;
    }

    voltarAba() {
        this.outAbaAnterior.emit(0);
    }

    proximaAba() {
        if (this.contas.length > 0) {
            this.outProximaAba.emit(2);
        }
    }
}
