import {Injectable} from '@angular/core';
import {EventoPagamentoReceitas} from '../../../models/evento-pagamento-receitas';
import {ConfigEventoPagamento} from '../../../models/config-evento-pagamento';
import {EventoPagamentoReceitaDTO} from '../../../models/evento-pagamento-receita-dto';

@Injectable({
    providedIn: 'root'
})
export class EventoPagamentoParametroConfiguracaoModalService {
    displayMaximizable: boolean;
    dateStart: Date;
    eventoPagamentoReceita: EventoPagamentoReceitas
    eventoPagamento: ConfigEventoPagamento
    eventoPagamentoReceitaDTOLast: EventoPagamentoReceitaDTO
    constructor() {
    }
}
