import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { CookieService } from "ngx-cookie-service";
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const KEY = '_PROFILE';

@Injectable({
    providedIn: 'root'
})
export class AppTopbarService {
    protected _profile: Profile;
    protected _perfil: string;

    private $pontoPassagem = new BehaviorSubject(null);

    constructor(
        private readonly http: HttpClient,
        private cookieService: CookieService,
        private keycloakService: KeycloakService
    ) {
        const ponto = JSON.parse(localStorage.getItem('pontoPassagem'));
        this.$pontoPassagem.next(ponto);
    }

    protected findPerfilLogado(): Promise<string> {
        return this.http
            .get(`${environment.baseUrl}/perfis`, {responseType: 'text'})
            .pipe(
                tap((perfil) => {
                    const expires = new Date();
                    expires.setHours(expires.getHours() + 1);
                    this.cookieService.set(KEY, this.objectToBase64(perfil), { expires, domain: location.hostname, path: '/' });
                    this._perfil = perfil;
                })
            ).toPromise()
    }

    protected objectToBase64(value: Object): string {
        return btoa(JSON.stringify(value));
    }

    protected base64ToObject<T>(value: string): T {
        return JSON.parse(atob(value)) as T;
    }

    public async getPerfilLogado(): Promise<string> {
        if (this._perfil) return this._perfil;
        if (this.cookieService.check(KEY)) {
            const cookie = this.cookieService.get(KEY);
            this._perfil = this.base64ToObject<string>(cookie);

            if (this._perfil) {
                return this._perfil;
            }
        }

        await this.findPerfilLogado();
        return this._perfil;
    }

    public clearProfile(): void {
        this.limparCookies();
    }

    private limparCookies() {
        const expires = new Date();
        expires.setHours(expires.getHours() - 10);

        this.cookieService.set(
            KEY,
            this.objectToBase64(this._profile),
            { expires, domain: location.hostname, path: '/' }
        );
    }

}

export interface Profile {
    perfilId: number;
    perfil: string;
    acoes: Acao[];
}

export interface Acao {
    id: number;
    acao: string;
    descricao: string;
}
