import { Injectable } from '@angular/core';
import { CookieService } from "ngx-cookie-service";
import { BehaviorSubject } from 'rxjs';

const KEY = '_PROFILE';

@Injectable({
    providedIn: 'root'
})
export class AppTopbarService {
    protected _profile: Profile;

    private $pontoPassagem = new BehaviorSubject(null);

    constructor(
        private cookieService: CookieService
    ) {
        const ponto = JSON.parse(localStorage.getItem('pontoPassagem'));
        this.$pontoPassagem.next(ponto);
    }

    protected objectToBase64(value: Object): string {
        return btoa(JSON.stringify(value));
    }

    public clearProfile(): void {
        this.limparCookies();
    }

    private limparCookies() {
        const expires = new Date();
        expires.setHours(expires.getHours() - 10);

        this.cookieService.set(
            KEY,
            this.objectToBase64(this._profile),
            { expires, domain: location.hostname, path: '/' }
        );
    }

}

export interface Profile {
    perfilId: number;
    perfil: string;
    acoes: Acao[];
}

export interface Acao {
    id: number;
    acao: string;
    descricao: string;
}
