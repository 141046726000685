import { ConfigComposicaoConta } from "./config-composicao-conta";
import { ConfigConta } from "./config-conta";
import { ConfigContaBaseLegal } from "./config-conta-base-legal";
import { ConfigContaTipoCadastro } from "./config-conta-tipo-cadastro";
import { ConfigContaUnidOrganiza } from "./config-conta-unid-organiza";
import { ConfigModelo } from "./config-modelo";
import { ConfigReceita } from "./config-receita";
import { TipoCadastroSaveRequest } from "./tipoCadastroSaveRequest";

export class ConfigContaSaveAllRequest {
    configContaSaveRequest: ConfigConta;
    itemCalculoSaveRequest: ConfigComposicaoConta;
    regraCriacaoSaveRequest: ConfigModelo;
    receitaSaveRequest: ConfigReceita;
    baseLegalSaveRequest: ConfigContaBaseLegal;
    unidOrganizaSaveRequest: ConfigContaUnidOrganiza;
    tipoCadastroSaveRequests: ConfigContaTipoCadastro;

    constructor(init?: Partial<ConfigConta>) {
        if (init) {
            Object.assign(this, init);
        } else {
            this.configContaSaveRequest = null;
            this.itemCalculoSaveRequest = null;
            this.regraCriacaoSaveRequest = null;
            this.receitaSaveRequest = null;
            this.baseLegalSaveRequest = null;
            this.unidOrganizaSaveRequest = null;
            this.tipoCadastroSaveRequests = null;

        }
    }
}
