<p-card>
    <ng-template pTemplate="title"> Destinatário </ng-template>
    <ng-template pTemplate="content">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-6">
                <label>Descrição *</label>
                <input
                    #descricao="ngModel"
                    [(ngModel)]="destinatarioSaveRequest.descricao"
                    type="text"
                    pInputText
                    autocomplete="nope"
                    required
                    [disabled]="implantado"
                    />
                    <small *ngIf="descricao?.invalid && submitted" class="p-error"
                    >Campo obrigatório</small
                    >
                </div>
                <div class="p-field p-col-6">
                    <label>Regra</label>
                    <p-dropdown
                    #regraId="ngModel"
                    [(ngModel)]="destinatarioSaveRequest.regra.regraId"
                    optionValue="regraId"
                    placeholder="Selecione uma regra"
                    [options]="regraList"
                    [optionLabel]="'descricao'"
                    [disabled]="implantado"
                    required
                >
                </p-dropdown>
                <small *ngIf="regraId?.invalid && submitted" class="p-error"
                    >Campo obrigatório</small
                >
            </div>

        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <div class="grid grid-nogutter justify-content-end">
            <p-button
                label="Anterior"
                icon="pi pi-angle-left"
                (click)="voltarAba()"
            ></p-button>
            <p-button
                class="float-btn-next margin-right"
                label="Salvar"
                iconPos="right"
                (click)="salvar()"
            ></p-button>
        </div>
    </ng-template>
</p-card>
