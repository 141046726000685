import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Observable, throwError} from 'rxjs';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {TipoTransacaoEnum} from '../../../utils/enums/tipo-transacao-enum';
import {OperacaoTransacaoEnum} from '../../../utils/enums/operacao-transacao-enum';
import {SituacaoEnum} from '../../../utils/enums/situacao-enum';

@Injectable({
    providedIn: 'root'
})
export class EventoCompensacaoConfigService {
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private http: HttpClient) {
    }

    getById(id): Observable<any> {
        return this.http.get<any>(`${environment.baseUrl}/evento-compensacao/v2/${id}`)
            .pipe(
                catchError(this.handleError)
            );
    }

    getTransacoes(tipoTransacao: TipoTransacaoEnum,
                  operacao: OperacaoTransacaoEnum,
                  situacao: SituacaoEnum = SituacaoEnum.ATIVA): Observable<Array<any>> {

        return this.http.get<Array<any>>(`${environment.baseUrl}/transacao/search-nopag`, {
            params: {
                situacao,
                tipoTransacao,
                operacao
            }
        });
    }

    deleteEventoCompensacaoImputacao(id: number): Observable<void> {
        return this.http.delete<void>(`${environment.baseUrl}/evento-compensa-imputacao/${id}`);
    }
    deleteEventoCompensacaoConta(id: number): Observable<void> {
        return this.http.delete<void>(`${environment.baseUrl}/evento-compensacao-conta/${id}`);
    }

    private handleError(error: HttpErrorResponse) {
        if (error.status === 0) {
            console.error('An error occurred:', error.error);
        } else {
            console.error(`Backend returned code ${error.status}, body was: `, error.error);
        }
        return throwError(() => new Error('Something bad happened; please try again later.'));
    }
}
