<p-table [value]="contribuinteListSearch || []" dataKey="nome">
    <ng-template pTemplate="header">
        <tr>
            <th> </th>
            <th pSortableColumn="ie">Inscrição Estadual <p-sortIcon field="name"></p-sortIcon>
            </th>
            <th pSortableColumn="cpfCnpj">CPF/CNPJ <p-sortIcon field="price"></p-sortIcon>
            </th>
            <th pSortableColumn="nome">Nome/Razão Social <p-sortIcon field="category"></p-sortIcon>
            </th>
            <th pSortableColumn="endereco">Endereço <p-sortIcon field="rating"></p-sortIcon>
            </th>
            <th pSortableColumn="descSituacao">Situação Cadastral <p-sortIcon field="rating"></p-sortIcon>
            </th>
            <th> Ação </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-contribuinte let-expanded="expanded">

        <tr>
            <td>
                <button type="button" pButton pRipple [pRowToggler]="contribuinte"
                    (click)="searchDetails(contribuinte);" class="p-button-text p-button-rounded p-button-plain"
                    [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
            </td>
            <td>{{contribuinte.ie}}</td>
            <td>{{contribuinte.cpfcnpj }}</td>
            <td>{{contribuinte.nome }}</td>
            <td>{{contribuinte.endereco}}</td>
            <td>{{contribuinte.descSituacao}}</td>

            <td>
                <p-button icon="pi pi-search" (click)="op.toggle($event)" class="mr"></p-button>
                <p-button icon="pi pi-external-link" (click)="goToExtratoContaCorrente(contribuinte)" pTooltip="Extrato conta">
                </p-button>
            </td>

            <p-overlayPanel #op [style]="{width: '450px;'}">
                <ng-template pTemplate>
                    <p-table selectionMode="single" [value]="[contribuinte]" responsiveLayout="scroll">
                        <ng-template pTemplate="header">
        <tr>
            <th>Nome</th>
            <th>Bairro</th>
            <th>Endereço</th>
            <th>Vinculo</th>
            <th>Regime</th>
            <th>Desc. Pessoa</th>
            <th>Desc. Tipo</th>
            <th>UF</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-contribuinte>
        <tr [pSelectableRow]="rowData">
            <td>{{contribuinte.nome}}</td>
            <td>{{contribuinte.bairro}}</td>
            <td>{{contribuinte.endereco}}</td>
            <td>{{contribuinte.vinculo}}</td>
            <td>{{contribuinte.regime}}</td>
            <td>{{contribuinte.descPessoa}}</td>
            <td>{{contribuinte.descTipo}}</td>
            <td>{{contribuinte.uf}}</td>
        </tr>
    </ng-template>
</p-table>
</ng-template>
</p-overlayPanel>
</ng-template>


<ng-template pTemplate="rowexpansion" let-detailsContribuinte>
    <tr>
        <td colspan="7">
            <div class="p-p-3">
                <p-table [value]="[detailsContribuinte] || []" dataKey="cpfcnpj">
                    <ng-template pTemplate="header">
    <tr>
        <th pSortableColumn="id">Cep <p-sortIcon field="price"></p-sortIcon>
        </th>
        <th pSortableColumn="customer">Bairro <p-sortIcon field="customer"></p-sortIcon>
        </th>
        <th pSortableColumn="date">Cidade <p-sortIcon field="date"></p-sortIcon>
        </th>
        <th pSortableColumn="amount">Endereço<p-sortIcon field="amount"></p-sortIcon>
        </th>
        <th style="width: 4rem"></th>
    </tr>
</ng-template>

<ng-template pTemplate="body" let-detailsContribuinte>
    <tr>
        <td>{{detailsContribuinte?.cep}}</td>
        <td>{{detailsContribuinte?.bairro}}</td>
        <td>{{detailsContribuinte?.cidade}}</td>
        <td>{{detailsContribuinte?.endereco }}</td>
    </tr>
</ng-template>

<ng-template pTemplate="emptymessage">
    <tr>
        <td colspan="6">There are no order for this product yet.</td>
    </tr>
</ng-template>

</p-table>
</div>
</td>
</tr>
</ng-template>
</p-table>

<p-paginator [rows]="10" [totalRecords]="pagination.totalElements" (onPageChange)="onPageChange($event)">
</p-paginator>