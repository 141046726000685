export class ConfigContaSearchRequest {
    configContaId: string;
    nomeCompleto: string;
    nomeResumido: string;
    situacao: string;
    tipoConta: string;
    dataInicioVigencia: string;
    dataFimVigencia: string;
    origemInformacaoId: string;
    naturezaContaCorrenteId: string;
    tributoId: string;

    constructor(init?: Partial<ConfigContaSearchRequest>) {
        if (init) {
            Object.assign(this, init);
        } else {
            this.configContaId = '';
            this.nomeCompleto = '';
            this.nomeResumido = '';
            this.situacao = '';
            this.tipoConta = '';
            this.dataInicioVigencia = '';
            this.dataFimVigencia = '';
            this.origemInformacaoId = '';
            this.naturezaContaCorrenteId = '';
            this.tributoId = '';
        }
    }
}
