import {ConfigConta} from './config-conta';
import {RegraCriacaoConta} from './regra-criacao-conta';

export class ConfigContaRegraCriacao {
    configContaRegraCriacaoId: number;
    dataFimVigencia: string;
    dataInicioVigencia: string;
    situacao: string;
    configConta: ConfigConta;
    regraCriacaoConta: RegraCriacaoConta;
    constructor(init?: Partial<ConfigContaRegraCriacao>) {
        if (init) {
            Object.assign(this, init);
        } else {
            this.configContaRegraCriacaoId = null;
            this.dataFimVigencia = null;
            this.dataInicioVigencia = null;
            this.situacao = null;
            this.configConta = null;
            this.regraCriacaoConta = null;
        }
    }
}
