<p-table #dt [value]="resources?.content"
    [paginator]="resources && resources.totalElements != 0 && filterForm?.value?.size <= resources.totalElements"
    [rowHover]="true" dataKey="id" styleClass="p-datatable-customers" [lazy]="true" (onLazyLoad)="onPaginate($event)"
    [totalRecords]="(resources ? resources.totalElements : 0)" [rows]="filterForm?.value?.size"
    [rowsPerPageOptions]="[20, 30, 50, 100]" [lazyLoadOnInit]="false">
    <ng-template pTemplate="header">
        <ng-content select="[header]"></ng-content>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
        <ng-template select="[body]" [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{$implicit: rowData}">
        </ng-template>
    </ng-template>
    <ng-template pTemplate="summary">
        <div class="p-grid">
            <div class="p-col-12">
                <div class="p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-12">
                        <div style="text-align: right;" *ngIf="resources && !isVazio">
                            <strong>Total:</strong> {{ resources.totalElements ? resources.totalElements : 0 }}
                        </div>
                        <div *ngIf="!resources; else nao_encontrado" style="text-align: center;">
                            <button pButton pRipple type="button" label="Localizar" icon="pi pi-search"
                                (click)="onPaginate();"></button>
                        </div>
                        <ng-template #nao_encontrado>
                            <div *ngIf="isVazio">Nehum registro encontrado</div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</p-table>