import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class RegraCriacaoViewService {
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(
        private http: HttpClient,
    ) {
    }

    getSituacoes(): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${environment.baseUrl}/enums/regra-criacao-conta/situacao`);
    }

    getReferencias(): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${environment.baseUrl}/enums/regra-criacao-conta/referencia`);
    }

    getCriteriosUtilizacaoCredito(): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${environment.baseUrl}/enums/regra-criacao-conta/criterio-utilizacao-credito`);
    }

    getContagensEm(): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${environment.baseUrl}/tipos-contagens`);
    }

    getAContarDas(): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${environment.baseUrl}/tipos-datas-referencias`);
    }

    getRegraVencimentos(): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${environment.baseUrl}/regras-vencimentos`);
    }

    getVencimentosEmDiaUtil(): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${environment.baseUrl}/enums/vencimento-em-dia-util`);
    }

    getTributos(): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${environment.baseUrl}/tributo`);
    }

    loadRegra(id): Observable<any> {
        return this.http.get<any>(`${environment.baseUrl}/regra-criacao-conta/${id}`);
    }
}
