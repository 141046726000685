import { EventoPagtoCompensacoes } from "./evento-pagamento-compensacoes";
import { EventoPagtoContaExcecoes } from "./evento-pagamento-conta-excecoes";
import { EventoPagtoReceitaContas } from "./evento-pagamento-receita-contas";
import { EventoPagtoReceitaRegras } from "./evento-pagamento-receita-regras";

export class EventoPagamentoReceitas {
    codReceita: number;
    criarConta: string;
    dataInicioVigencia: string;
    dataFimVigencia: string;
    eventoPagamentoReceitaId: number;
    eventoPagtoReceitaContas: EventoPagtoReceitaContas[];
    eventoPagtoReceitaRegras: EventoPagtoReceitaRegras[];
    eventoPagtoCompensacoes: EventoPagtoCompensacoes[];
    eventoPagtoContaExcecoes: EventoPagtoContaExcecoes[];
    situacao: string;
    haTransbordo: string;

    constructor(init?: Partial<EventoPagamentoReceitas>) {
        if (init) {
            Object.assign(this, init);
        } else {
            this.codReceita = null;
            this.criarConta = "";
            this.dataInicioVigencia = "";
            this.dataFimVigencia = "";
            this.eventoPagamentoReceitaId = null;
            this.eventoPagtoReceitaContas = [];
            this.eventoPagtoReceitaRegras = [];
            this.eventoPagtoCompensacoes = [];
            this.eventoPagtoContaExcecoes = [];
            this.situacao = 'ATIVA';
            this.haTransbordo = 'NAO';
        }
    }
}
