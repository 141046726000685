import { ParamConfigEventoService } from "./param-config-evento.service";
import { ParamConfigEvento } from "./../models/param-config-evento";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";

@Injectable({
    providedIn: "root",
})
export class EventoGerenciarConfigService {
    private $permissoesEvento = new BehaviorSubject<ParamConfigEvento[]>([]);

    constructor(
        private http: HttpClient,
        private pces: ParamConfigEventoService
    ) {}

    carregarPermissoesEvento(eventoId: number) {
        if (eventoId == null) {
            this.$permissoesEvento.next([]);
            return;
        }
        this.pces.buscarPermissoes(eventoId).subscribe(
            (resp) => this.$permissoesEvento.next(resp),
            (err) =>
                Swal.fire(
                    "",
                    "Falha ao carregar permissoes do evento!",
                    "error"
                )
        );
    }

    onPermissoesCarregadas(){
        return this.$permissoesEvento.asObservable();
    }

    // PASSO CONFIGURAÇÃO - FIM
    getAllEstadoConta(): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${environment.baseUrl}/estado-conta`);
    }

    getAllSituacaoCobranca(): Observable<Array<any>> {
        return this.http.get<Array<any>>(
            `${environment.baseUrl}/situacao-cobranca`
        );
    }

    getAllSituacaoCredito(): Observable<Array<any>> {
        return this.http.get<Array<any>>(
            `${environment.baseUrl}/situacao-credito`
        );
    }

    getAllSaldoConta(): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${environment.baseUrl}/saldo-conta`);
    }
    // PASSO CONFIGURAÇÃO - FIM

    // PASSO CONTA
    getContaByNome(nomeCompleto: string) {
        return this.http.get(`${environment.baseUrl}/config-conta/search`, {
            params: { nomeCompleto, page: 0, size: 5 },
            headers: { ignoreSpinner: "true" },
        });
    }
    // PASSO CONTA - FIM

    // PASSO TRANSAÇÕES
    getAllRegraImputacao() {
        return this.http.get<Array<any>>(
            `${environment.baseUrl}/regra-imputacao/search-nopage`
        );
    }
    // PASSO TRANSAÇÕES - FIM

    // PASSO PERFIL
    getPerfis() {
        return this.http.get(
            `${environment.baseUrl}/perfil-visualizacao-conta`
        );
    }
    // PASSO PERFIL - FIM

    salvarConfiguracao(request) {
        return this.http.post(
            `${environment.baseUrl}/config-eventos/salvarConfiguracao`,
            request
        );
    }

    atualizarConfiguracao(eventosId, request) {
        return this.http.put(
            `${environment.baseUrl}/config-eventos/atualizarConfiguracao/${eventosId}`,
            request
        );
    }

    detalharConfiguracao(configuracaoEventosId) {
        return this.http.get<any>(
            `${environment.baseUrl}/config-eventos/detalharConfiguracao/${configuracaoEventosId}`
        );
    }
}
