<p-dialog
    [header]="simularBoolean ? 'Resultado' : 'Simular Contas'"
    [(visible)]="visible"
    [modal]="true"
    [style]="{ width: '80vw' }"
    [maximizable]="true"
    [baseZIndex]="10000"
    [draggable]="false"
    [resizable]="false"
>
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-12">
                <strong
                >Contribuinte:
                {{ insereMascara(dadosContribuinte?.baseCnpj) }} -
                {{ dadosContribuinte?.nome }}</strong
                >
                <br/>
            <!-- CONTAS SELECIONADAS -->
            <span *ngIf="!simularBoolean">
                <p>
                    <strong>Contas selecionadas para simulação:</strong>
                </p>
            </span>
            <!-- CONTAS SIMULADAS -->
            <span *ngIf="simularBoolean">
                <span><strong>Data da simulação: </strong>{{dataAtual | date : "dd/MM/yyyy" }}</span>
                <p>
                    <span><strong>Total de contas analisadas: </strong>{{listContasSimular.length}}</span>
                </p>
                <p>
                    <strong>Contas simuladas:</strong>
                </p>
            </span>
        </div>
    </div>
    <!-- GRID PRINCIPAL CONTAS SELECIONADA -->
    <p-table
        #dt1
        [value]="listContasSimular"
        [scrollable]="true"
        dataKey="contaCorrenteId"
    >
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 5rem"></th>
                <th>Conta</th>
                <th>Número do Documento</th>
                <th>Contribuinte</th>
                <th>Período de Referência</th>
                <th>Dt. Vencimento</th>
                <th>Estado da Conta</th>
                <th>Saldo da Conta</th>
                <th>Situação do Crédito</th>
                <th>Situação da Cobrança</th>
                <th *ngIf="simularBoolean">Ações</th>
            </tr>
        </ng-template>
        <ng-template
            pTemplate="body"
            let-conta
            let-expanded="expanded"
            let-rowIndex="rowIndex"
        >
            <tr class="selecao">
                <td>
                    <button
                        type="button"
                        pButton
                        pRipple
                        [pRowToggler]="conta"
                        class="p-button-text p-button-rounded p-button-plain"
                        [icon]="
                            expanded
                                ? 'pi pi-chevron-down'
                                : 'pi pi-chevron-right'
                        "
                        (click)="expandeEnvento(rowIndex)"
                    ></button>
                </td>
                <td>
                    {{ conta?.configContaId }} -
                    {{ conta?.configContaNomeResumido }}
                </td>
                <td>{{ conta.numeroDocumento }}</td>
                <td>{{ insereMascara(conta?.cpfCnpj2) }} - {{ conta.nome }}</td>
                <td>{{ converteMesAno(conta.periodoReferencia) }}</td>
                <td>{{ conta.dataVencimento | date : "dd/MM/yyyy" }}</td>
                <td>{{ conta.estadoContaDesc }}</td>
                <td>{{ conta.saldoTotalAtualizado | currency : "BRL" }}</td>
                <td>{{ conta.situacaoCreditoDesc }}</td>
                <td>{{ conta.situacaoCobrancaDesc }}</td>
                <td *ngIf="simularBoolean" class="selecao">
                    <i
                        pTooltip="Imprimir Simulação"
                        tooltipPosition="top"
                        class="fa-solid fa-file-pdf"
                        style="font-size: large; margin-right: 8px"
                        (click)="simulacaoExtratoContaCorrentePdf(conta)"
                    ></i>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" class="p-text-center">
            <ccp-detalhe-calculo-juros
                [conta]="listContasSimular[rowIndexConta]"
                [isSimular]="simularBoolean"
            ></ccp-detalhe-calculo-juros>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td class="p-text-center">
                    <div style="margin-left: 46%">
                        Nenhum registro encontrado
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <!-- GRID TOTAL CONTAS LANÇADAS -->
    <p-table
        #dt1
        [value]="contasSimuladaList"
        [scrollable]="true"
        dataKey="contaCorrenteId"
    >
        <ng-template pTemplate="caption">
            <div class="table-header p-text-center">
                Total Lançado Contas Selecionadas
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th>Principal</th>
                <th>Multa de Mora</th>
                <th>Juros de Mora</th>
                <th>Multa por Infração</th>
                <th>Valor Total</th>
            </tr>
        </ng-template>
        <ng-template
            pTemplate="body"
            let-conta
            let-expanded="expanded"
            let-rowIndex="rowIndex"
        >
            <tr class="selecao">
                <td
                    [ngClass]="{
                        'saldo-negativo': maior(conta?.valorLancadoPrincipal)
                    }"
                >
                    {{ conta?.valorLancadoPrincipal | currency : "BRL" }}
                </td>
                <td
                    [ngClass]="{
                        'saldo-negativo': maior(conta?.valorLancadoMulta)
                    }"
                >
                    {{ conta?.valorLancadoMulta | currency : "BRL" }}
                </td>
                <td
                    [ngClass]="{
                        'saldo-negativo': maior(conta?.valorLancadoJuros)
                    }"
                >
                    {{ conta?.valorLancadoJuros | currency : "BRL" }}
                </td>
                <td
                    [ngClass]="{
                        'saldo-negativo': maior(conta?.valorLancadoMultaInfra)
                    }"
                >
                    {{ conta?.valorLancadoMultaInfra | currency : "BRL" }}
                </td>
                <td
                    [ngClass]="{
                        'saldo-negativo': maior(conta?.valorTotalLancado)
                    }"
                >
                    {{ conta?.valorTotalLancado | currency : "BRL" }}
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td class="p-text-center">
                    <div style="margin-left: 46%">
                        Nenhum registro encontrado
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <!-- FIM LANÇADAS -->

    <!-- GRID TOTAL CONTAS ATUALIZADAS -->
    <p-table
        #dt1
        [value]="contasSimuladaList"
        [scrollable]="true"
        dataKey="contaCorrenteId"
    >
        <ng-template pTemplate="caption">
            <div class="table-header p-text-center">
                Total Atualizado Contas Selecionadas
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th>Principal</th>
                <th>Multa de Mora</th>
                <th>Juros de Mora</th>
                <th>Multa por Infração</th>
                <th>Valor Total</th>
            </tr>
        </ng-template>
        <ng-template
            pTemplate="body"
            let-conta
            let-expanded="expanded"
            let-rowIndex="rowIndex"
        >
            <tr class="selecao">
                <td
                    [ngClass]="{
                        'saldo-negativo': maior(conta?.valorAtualizadoPrincipal)
                    }"
                >
                    {{ conta?.valorAtualizadoPrincipal | currency : "BRL" }}
                </td>
                <td
                    [ngClass]="{
                        'saldo-negativo': maior(conta?.valorAtualizadoMulta)
                    }"
                >
                    {{ conta?.valorAtualizadoMulta | currency : "BRL" }}
                </td>
                <td
                    [ngClass]="{
                        'saldo-negativo': maior(conta?.valorAtualizadoJuros)
                    }"
                >
                    {{ conta?.valorAtualizadoJuros | currency : "BRL" }}
                </td>
                <td
                    [ngClass]="{
                        'saldo-negativo': maior(
                            conta?.valorAtualizadoMultaInfra
                        )
                    }"
                >
                    {{ conta?.valorAtualizadoMultaInfra | currency : "BRL" }}
                </td>
                <td
                    [ngClass]="{
                        'saldo-negativo': maior(conta?.saldoTotalAtualizado)
                    }"
                >
                    {{ conta?.saldoTotalAtualizado | currency : "BRL" }}
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td class="p-text-center">
                    <div style="margin-left: 46%">
                        Nenhum registro encontrado
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <!-- FIM ATUALIZADAS -->

     <!-- GRID TOTAL CONTAS SIMULADAS -->
     <p-table
     #dt1
     [value]="contasSimuladaList"
     [scrollable]="true"
     dataKey="contaCorrenteId"
     *ngIf="simularBoolean"
 >
     <ng-template pTemplate="caption">
         <div style="background-color: #a4bbd3;" class="table-header p-text-center conta-simulada-font">
             Total Atualizado Contas Simuladas
         </div>
     </ng-template>
     <ng-template pTemplate="header">
         <tr>
             <th>Principal</th>
             <th>Multa de Mora</th>
             <th>Juros de Mora</th>
             <th>Multa por Infração</th>
             <th>Valor Total</th>
         </tr>
     </ng-template>
     <ng-template
         pTemplate="body"
         let-conta
         let-expanded="expanded"
         let-rowIndex="rowIndex"
     >
         <tr class="selecao conta-simulada-font">
             <td
                 [ngClass]="{
                     'saldo-negativo': maior(conta?.valorSimuladoPrincipal)
                 }"
             >
                 {{ conta?.valorSimuladoPrincipal | currency : "BRL" }}
             </td>
             <td
                 [ngClass]="{
                     'saldo-negativo': maior(conta?.valorSimuladoMulta)
                 }"
             >
                 {{ conta?.valorSimuladoMulta | currency : "BRL" }}
             </td>
             <td
                 [ngClass]="{
                     'saldo-negativo': maior(conta?.valorSimuladoJuros)
                 }"
             >
                 {{ conta?.valorSimuladoJuros | currency : "BRL" }}
             </td>
             <td
                 [ngClass]="{
                     'saldo-negativo': maior(
                         conta?.valorSimuladoMultaInfra
                     )
                 }"
             >
                 {{ conta?.valorSimuladoMultaInfra | currency : "BRL" }}
             </td>
             <td
                 [ngClass]="{
                     'saldo-negativo': maior(conta?.saldoTotalSimulado)
                 }"
             >
                 {{ conta?.saldoTotalSimulado | currency : "BRL" }}
             </td>
         </tr>
     </ng-template>
     <ng-template pTemplate="emptymessage">
         <tr>
             <td class="p-text-center">
                 <div style="margin-left: 46%">
                     Nenhum registro encontrado
                 </div>
             </td>
         </tr>
     </ng-template>
    </p-table>
    <!-- FIM SIMULADAS -->
    
    <div class="grid grid-nogutter justify-content-end margin-bt">
        <button
            *ngIf="!simularBoolean"
            (click)="simular()"
            pButton label="Simular Multa e Juros"
            class="p-button p-mr-2"
         >
            <i class="fa-solid fa-calculator" style="margin-right: 8px"></i>
        </button>
        <button
            *ngIf="simularBoolean"
            (click)="hiddenDialog()"
            pButton label="Fechar"
            class="p-button p-mr-2"
         >
            <i class="fa-solid fa-close" style="margin-right: 8px"></i>
        </button>
    </div>
</p-dialog>

