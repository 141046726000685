import * as EventoPagamentoEventoCompensacaoModalActions from './evento-pagamento-evento-compensacao-modal-actions';

const eventoPagamentoEventoCompensacaoModalInitialstate = {
    displayMaximizable: false
};

// tslint:disable-next-line:max-line-length
export function eventoPagamentoEventoCompensacaoModalReducer(state = eventoPagamentoEventoCompensacaoModalInitialstate, action: EventoPagamentoEventoCompensacaoModalActions.SetDisplayMaximizable) {
    switch (action.type) {
        case EventoPagamentoEventoCompensacaoModalActions.SET_DISPLAY_MAXIMIZABLE:
            return {
                ...state,
                displayMaximizable: action.value
            };
        default:
            return state;
    }
}
