import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable()
export class LoaderInterceptors implements HttpInterceptor {
    private countRequests = 0;

    constructor(private spinner: NgxSpinnerService) { }


    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {

        if (request.headers.has('ignoreSpinner')) {
            const headers = request.headers.delete('ignoreSpinner');
            return next.handle(request.clone({ headers }));
        }

        this.countRequests++;
        this.spinner.show();

        return next.handle(request).pipe(
            finalize(() => {
                this.countRequests--;
                if (this.countRequests === 0) {
                    this.spinner.hide();
                }
            })
        );
    }
}
