<p-card>
    <ng-template pTemplate="title">Configuração de Evento de Estado</ng-template>
    <ng-template pTemplate="content">
        <form *ngIf="estadoForm" [formGroup]="estadoForm" (ngSubmit)="submit()" (ngReset)="reiniciarForm()">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-2">
                    <label>Código</label>
                    <input type="text" [value]="eventoEstadoId" disabled pInputText/>
                </div>
                <div class="p-field p-col-10">
                    <label>Descrição *</label>
                    <input formControlName="descricao" type="text" pInputText maxlength="50"/>
                    <p 
                    class="erro-validacao"
                    *ngIf="(estadoControls.descricao.touched)
                        && estadoControls.descricao.errors?.required">
                        Campo obrigatório
                    </p>
                </div>
                <div class="p-field p-col-4">
                    <label>Tipo Evento de Estado*</label>
                    <p-dropdown
                        (onChange)="eventoDeEstadoChange($event)" 
                        formControlName="tipoEvento" optionLabel="valor" optionValue="chave"
                        showClear="true" [autoDisplayFirst]="false" [options]="eventosComboList"
                        [disabled]="this.estadoEvento?.implantado === 'SIM'">
                    </p-dropdown>
                    <p 
                    class="erro-validacao"
                    *ngIf="(estadoControls.tipoEvento.touched)
                        && estadoControls.tipoEvento.errors?.required">
                        Campo obrigatório
                    </p>
                </div>
                <div class="p-field p-col-4">
                    <label>Tipo Processamento *</label>
                    <p-dropdown 
                        (onChange)="tipoProcessamentoChange($event)" 
                        formControlName="tipoProcessamento" optionLabel="valor" optionValue="chave"
                        showClear="true" [autoDisplayFirst]="false" [options]="processamentoComboList"
                        [disabled]="this.estadoEvento?.implantado === 'SIM'">
                    </p-dropdown>
                    <p 
                    class="erro-validacao"
                    *ngIf="(estadoControls.tipoProcessamento.touched)
                        && estadoControls.tipoProcessamento.errors?.required">
                        Campo obrigatório
                    </p>
                </div>
                <div class="p-field p-col-4">
                    <label>Situação</label>
                    <p-dropdown formControlName="situacao" optionLabel="valor" optionValue="chave" showClear="true"
                        [autoDisplayFirst]="false" [options]="situacaoComboList" [disabled]="!eventoEstadoId || this.estadoEvento?.implantado === 'NAO'">
                    </p-dropdown>
                </div>
                <div class="p-field p-col-4">
                    <label>Início da Vigência *</label>
                    <p-calendar formControlName="dataInicioVigencia" (onSelect)="onSelectDataMenor($event)"
                        name="dataInicioVigencia" [showIcon]="true" inputId="icon" dateFormat="dd/mm/yy"
                        name="dataInicioVigencia" [disabled]="this.estadoEvento?.implantado === 'SIM'">
                    </p-calendar>
                    <p 
                    class="erro-validacao"
                    *ngIf="(estadoControls.dataInicioVigencia.touched)
                        && estadoControls.dataInicioVigencia.errors?.required">
                        Campo obrigatório
                    </p>
                </div>
                <div class="p-field p-col-4">
                    <label>Fim da Vigência</label>
                    <p-calendar formControlName="dataFimVigencia" (onSelect)="onSelectDataMenor($event)"
                        name="dataFimVigencia" [showIcon]="true" inputId="icon" dateFormat="dd/mm/yy"
                        name="dataFimVigencia">
                    </p-calendar>
                </div>
                <div class="p-field p-col-4">
                    <label>Data Cadastro</label>
                    <p-calendar formControlName="dataCadastro" name="dataCadastro" [showIcon]="true" inputId="icon"
                        dateFormat="dd/mm/yy" name="dataCadastro" disabled="true">
                    </p-calendar>
                </div>
                <div class="p-field p-col-12" style="margin-top: 10px">
                    <p-checkbox 
                        [disabled]="this.estadoEvento?.implantado === 'SIM'"
                        binary="false" trueValue="SIM" falseValue="NAO" name="interrompePrazoPrescricional"
                        label="Interrompe prazo prescricional?" formControlName="interrompePrazo"></p-checkbox>
                </div>
                <!-- verificar valor tipo evento Mudanca estado -->
                <div *ngIf="estadoControls.tipoEvento.value === 'MUDANCA_DE_ESTADO' || estadoControls.tipoEvento.value === 'CRIACAO_DE_CONTA'" class="p-field p-col-4">
                    <label>Estado Depois *</label>
                    <p-dropdown 
                        formControlName="estadoDepois" optionLabel="descricao" optionValue="estadoContaId"
                        [autoDisplayFirst]="false"
                        showClear="true"
                        [options]="estadoComboDepoisList"
                        (onChange)="estadohange($event)"
                        [disabled]="this.estadoEvento?.implantado === 'SIM'"
                        >
                    </p-dropdown>
                    <p 
                    class="erro-validacao"
                    *ngIf="(estadoControls.estadoDepois.touched)
                        && estadoControls.estadoDepois.errors?.required">
                        Campo obrigatório
                    </p>
                </div>
                <div class="p-field p-col-12" style="margin-top: 10px">
                    <p-fieldset *ngIf="estadoControls.tipoEvento.value === 'MUDANCA_DE_ESTADO'" legend="Estado Antes">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-4">
                                <p-dropdown formControlName="estadoAntes" optionLabel="descricao"
                                     showClear="true" [autoDisplayFirst]="false"
                                    [options]="estadoComboAntesList"
                                    (onChange)="estadohange($event)" 
                                    >
                                </p-dropdown>
                            </div>
                            <div class="p-field p-col-2">
                                <button [disabled]="
                                        estadoControls.estadoAntes.value ===
                                        null
                                    " (click)="adicionaEstadoAntes()" icon="pi pi-plus" type="button" pButton
                                    pRipple></button>
                            </div>
                            <div class="p-field p-col-12">
                                <p-table [value]="estadoAntesList">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th>Estado</th>
                                            <th>Ação</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-e let-rowIndex="rowIndex">
                                        <tr>
                                            <td>{{ e.descricao || e.estadoConta.descricao }}</td>
                                            <td>
                                                <button
                                                    [disabled]="this.estadoEvento?.implantado === 'SIM' && e.eventoEstadoAntesId"
                                                    (click)="removerEstaoAntes(rowIndex,e)" 
                                                    pButton 
                                                    pRipple 
                                                    title="Excluir" 
                                                    type="button" 
                                                    icon="pi pi-trash"
                                                    class="p-button-rounded p-button-danger">
                                                </button>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </div>
                    </p-fieldset>
                    <div class="p-fluid p-formgrid p-grid" style="margin-top: 25px">
                        <div class="p-field p-col-4">
                            <label>Perfis Visualização</label>
                            <p-dropdown optionLabel="descricao" showClear="true" [autoDisplayFirst]="false"
                                [options]="perfisComboList" formControlName="perfilVisualizacao">
                            </p-dropdown>
                        </div>
                        <div class="p-field p-col-2" style="margin-top: 25px">
                            <button [disabled]="this.estadoControls.perfilVisualizacao.value === null" (click)="adicionaPerfilVizualizacao()" pButton pRipple label="Adicionar"
                                type="button" icon="pi pi-plus-circle"></button>
                        </div>
                        <div class="p-field p-col-12">
                            <p-table [value]="perfisList">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>Código</th>
                                        <th>Nome do Perfil</th>
                                        <th>Situação Associação</th>
                                        <th>Data Início Associação</th>
                                        <th>Data Fim Associação</th>
                                        <th>Ação</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-p let-rowIndex="rowIndex">
                                    <tr>
                                        <td>{{ p.codigoPerfil }}</td>
                                        <td>{{ p.descricao }}</td>
                                        <td>{{ p.situacao }}</td>
                                        <td>{{ p.dataInicioAssociacao | date: 'dd/MM/YYYY'}}</td>
                                        <td>{{ p.dataFimAssociacao | date: 'dd/MM/YYYY'}}</td>
                                        <td>
                                            <button *ngIf="p.eventoEstadoPerfilId && this.estadoEvento?.implantado == 'SIM'"
                                                (click)="ativaInativaPerfil(p)"
                                                pButton
                                                pRipple
                                                title="{{ p.situacao === 'ATIVA' ? 'Inativar' : 'Ativar' }}"
                                                type="button"
                                                icon="{{ p.situacao === 'ATIVA' ? 'pi pi-times' : 'pi pi-check' }}"
                                                icon="pi pi-pencil"
                                                class="p-button-rounded p-button-secondary"
                                             ></button>
                                            <button 
                                                *ngIf="this.estadoEvento?.implantado == 'NAO' || !p.eventoEstadoPerfilId" 
                                                (click)="removerPerfilVizualizacao(rowIndex, p)"
                                                pButton
                                                pRipple 
                                                title="Excluir" 
                                                type="button"
                                                icon="pi pi-trash"
                                                class="p-button-rounded p-button-danger"
                                                style="margin-left: 3px"
                                                ></button>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                    <div *ngIf="
                            estadoControls.tipoProcessamento.value === 'MANUAL'
                        " class="p-field p-col-12" style="margin-top: 10px">
                        <p-fieldset legend="Parâmetro de interesse">
                            <div class="p-fluid p-formgrid p-grid">
                                <div class="p-field p-col-4" style="margin-right: 10px">
                                    <label>Atributo</label>
                                    <p-dropdown formControlName="atributo" optionLabel="descricao"
                                         showClear="true" [autoDisplayFirst]="false"
                                        [options]="atributoComboList">
                                    </p-dropdown>
                                </div>
                                <div class="p-field" style="margin-right: 10px">
                                    <label class="p-mb-3">Obrigatório</label>
                                    <div class="p-formgrid p-grid">
                                        <div class="p-field-radiobutton p-col-6">
                                            <p-radioButton name="obrigatorio" inputId="SIM" label="Sim" value="SIM"
                                                formControlName="obrigatorio"></p-radioButton>
                                            <p-radioButton name="obrigatorio" inputId="NAO" label="Não" value="NAO"
                                                formControlName="obrigatorio"></p-radioButton>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-field p-col-2" style="margin-top: 25px">
                                    <button 
                                    (click)="adicionaParametroInteresse()"
                                    icon="pi pi-plus" type="button"
                                    pButton pRipple
                                    [disabled]="this.estadoControls.obrigatorio.value === null || this.estadoControls.atributo.value === null"
                                    ></button>
                                </div>
                                <div class="p-field p-col-12">
                                    <p-table [value]="parametroInteresseList">
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th>Atributo</th>
                                                <th>Descrição</th>
                                                <th>Obrigatório</th>
                                                <th>TipoDado</th>
                                                <th>Ação</th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-pi let-rowIndex="rowIndex">
                                            <tr>
                                                <td>Cod. {{ pi.atributoId || pi.atributo.atributoId }}</td>
                                                <td>{{pi.descricao || pi.atributo.descricao}}</td>
                                                <td>{{pi.obrigatorio}}</td>
                                                <td>{{pi.datatype || pi.atributo.datatype}}</td>
                                                <td>
                                                    <button
                                                    [disabled]="this.estadoEvento?.implantado == 'SIM' && pi.paramInteresseId"
                                                    (click)="removerParametroInteresse(rowIndex, pi)" 
                                                    pButton 
                                                    pRipple
                                                    title="Excluir" 
                                                    type="button" 
                                                    icon="pi pi-trash"
                                                    class="p-button-rounded p-button-danger">
                                                </button>
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                </div>
                            </div>
                        </p-fieldset>
                    </div>
                    <button style="width: 15%; margin-right: 8px" pButton type="button" label="Voltar" class="p-button-outlined p-mr-2"
                    (click)="voltar()"></button>
                    <button class="float-btn-next" style="width: 15%; margin-right: 8px" pRipple label=" Salvar "
                        type="submit" pButton></button>
                </div>
            </div>
        </form>
    </ng-template>
</p-card>