<p-card>
    <div class="grid grid-nogutter justify-content-end margin-bt">
        <button 
        pButton 
        label="Voltar" 
        icon="pi pi-angle-left" 
        class="p-button-outlined p-mr-2"
        routerLink="/"
        ></button>
        <button
        pButton 
        class="float-btn-next"
        label="Próxima"
        icon="pi pi-angle-right"
        iconPos="right"
        (click)="irRegistrarMemoriaCalculo()"
        [disabled]="!consultaContaObj.configContaId || naoPossuiEvento"
        ></button>
    </div>
    <ng-template pTemplate="title">
        {{ title }}
    </ng-template>
    <ng-template pTemplate="content">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-2">
                <label>Código</label>
                <!-- <input placeholder="Informe ou busque o número da conta" pKeyFilter="num" [(ngModel)]="configContaId" type="text" pInputText=""/> -->
                <div class="p-inputgroup">
                    <input (keydown.enter)="pesquisarConta()" placeholder="Informe ou busque a conta" pKeyFilter="num" type="text" maxlength="5" pInputText name="configContaId"
                    [(ngModel)]="configContaId">
                    <button icon="pi pi-search" type="button" pButton pRipple (click)="pesquisarConta()"></button>
                </div>
            </div>
            <div class="p-field p-col-12 p-md-2 top-btn-line">
                <button
                pButton
                type="button"
                icon="pi pi-trash"
                label="Limpar"
                class="p-mr-1 p-button-danger"
                (click)="limparCampoPesquisa()"
                ></button>
            </div>
        </div>
    </ng-template>
</p-card>
<p-card [style]="{'margin-top': '10px'}" *ngIf="consultaContaObj.configContaId">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12">
            <app-visualiza-config-conta></app-visualiza-config-conta>
        </div>
        <div class="p-field p-col-12 p-md-4 p-sm-6">
            <p-fieldset legend="Tipo de Cadastro">
                <div *ngFor="let item of tipoCadastroList" class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-6">
                        <strong>Tipo de Cadastro</strong>
                        <p>{{converteValue(item?.tipoCadastro?.descricao)}}</p>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <strong>Código do Cadastro</strong>
                        <p>{{item?.tipoCadastro?.tipoCodigoCadastro}}</p>
                    </div>
                </div>
            </p-fieldset>
        </div>
        <div class="p-field p-col-12 p-md-4 p-sm-6">
            <p-fieldset legend="Datas de Referência">
                <div class="p-fluid p-formgrid p-grid font-style">
                    <div class="p-field p-col-12 p-md-12">
                        <p-table [value]="consultaContaObj.tipoDataReferencias">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Tipo de Data</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-data>
                                <tr>
                                    <td>{{ data.tipoDataReferenciaId }} - {{ data.descricao }}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </p-fieldset>
        </div>
        <div class="p-field p-col-12 p-md-4 p-sm-6">
            <p-fieldset legend="Cota Única e Parcelas">
                <div class="field-checkbox p-mb-1; let i = index">
                    <p-checkbox
                        disabled
                        checked="true"
                        [binary]="true"
                        trueValue="1"
                        falseValue="2"
                        label="Permite cota única"
                        title="Permite cota única"
                        [(ngModel)]="consultaContaObj.permiteCotaUnica"
                    ></p-checkbox>
                    <span [style]="{'margin-left': '10px'}"></span>
                    <p-checkbox
                        disabled
                        checked="true"
                        [binary]="true"
                        trueValue="1"
                        falseValue="2"
                        label="Permite parcelar"
                        title="Permite parcelar"
                        [(ngModel)]="consultaContaObj.permiteParcelar"
                ></p-checkbox>
                </div>
            </p-fieldset>
        </div>

        <div class="p-field p-col-12 p-md-4 p-sm-6">
            <p-fieldset legend="Documentos">
                <div class="p-fluid p-formgrid p-grid font-style">
                    <div class="p-field p-col-12 p-md-12">
                        <p-table [value]="consultaContaObj.tipoNumeroDocumentos">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Código</th>
                                    <th>Tipo de Documento</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-doc>
                                <tr>
                                    <td>{{doc.tipoNumeroDocumentoId}}</td>
                                    <td>{{doc.descricao}}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </p-fieldset>
        </div>

        <div class="p-field p-col-12 p-md-4 p-sm-6">
            <p-fieldset legend="Eventos">
                <div class="p-fluid p-formgrid p-grid font-style">
                    <div class="p-field p-col-12 p-md-12">
                        <p-table [value]="consultaContaObj.configuracaoEventos">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Código</th>
                                    <th>Descrição do Evento</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-evento>
                                <tr>
                                    <td>{{evento?.configuracaoEventosId}}</td>
                                    <td>{{evento?.descricaoResumida}}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </p-fieldset>
        </div>

        <div class="p-field p-col-12 p-md-4 p-sm-12">
            <p-fieldset legend="Itens de Composição da Conta">
                <div class="p-fluid p-formgrid p-grid font-style">
                    <div class="p-field p-col-12 p-md-12">
                        <p-table [value]="consultaContaObj.configContaItensCalculo">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Código</th>
                                    <th>Campo</th>
                                    <th>Descrição</th>
                                    <th>Tipo</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-item>
                                <tr>
                                    <td>{{item.itemComposicaoId}}</td>
                                    <td>{{item.itemCampo}}</td>
                                    <td>{{item.itemDescricao}}</td>
                                    <td>{{item.itemTipo}}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </p-fieldset>
        </div>
       
    </div>

    <div class="grid grid-nogutter justify-content-end margin-bt">
        <button 
            pButton 
            label="Voltar" 
            icon="pi pi-angle-left" 
            class="p-button-outlined p-mr-2"
            routerLink="/"
        ></button>
        <button
            pButton 
            class="float-btn-next"
            label="Próxima"
            icon="pi pi-angle-right"
            iconPos="right"
            (click)="irRegistrarMemoriaCalculo()"
            [disabled]="!consultaContaObj.configContaId || naoPossuiEvento"
        ></button>
    </div>
</p-card>

<app-config-conta-search-modal
[multipleSelection]="false"
(outConfigConta)="outConfigConta($event)"
></app-config-conta-search-modal>