import { ConfigContaReceita } from "./config-conta-receita";

export class EventoPagtoReceitaContas {
    ordemPrioridade: number;
    configContaReceita: ConfigContaReceita;

    constructor(init?: Partial<EventoPagtoReceitaContas>) {
        if (init) {
            Object.assign(this, init);

        } else {
            this.ordemPrioridade = null;
            this.configContaReceita;
        }
    }
}
