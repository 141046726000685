import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { EnumService } from "src/app/compartilhado/services/enum.service";
import { Pageable } from "src/app/shared/pagination/pageable";
import Swal from "sweetalert2";
import { EventoGerenciarConfigService } from "../../services/evento-gerenciar-config.service";

interface AutoCompleteCompleteEvent {
    originalEvent: Event;
    query: string;
}

class ContaSelecao {
    eventoContaId: number;
    conta: any;
    criterioBuscaContaCorrente: string;
    inicioVigencia: string;
    fimVigencia: string;
    contaPosEvento: any;
    criaConta: boolean;
}

@Component({
    selector: "app-contas",
    templateUrl: "./contas.component.html",
    styleUrls: ["./contas.component.scss"],
})
export class ContasComponent implements OnInit, OnDestroy {
    @Input() form!: FormGroup;
    @Input() validarCampos: boolean;
    @Input() dadosCarregados: any;

    contasForm: FormGroup = this.fb.group({
        contasSelecionadas: [[] as ContaSelecao[], [Validators.required]],
    });

    contaSelecionada: ContaSelecao = new ContaSelecao();
    contaAutoCompleteOptions = [];
    criterioBuscaOptions = [];
    indexEdicao: number = null;
    implantado: boolean | null;

    subs = new Subscription();

    constructor(
        private egcService: EventoGerenciarConfigService,
        private fb: FormBuilder,
        private enumService: EnumService
    ) {}

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    ngOnInit(): void {
        this.contaSelecionada.criaConta = false;
        this.enumService
            .getByName("CriterioBuscaContaCorrenteEnum")
            .subscribe((res) => (this.criterioBuscaOptions = res));
        const contasForm = this.form.get("contasForm").value;

        if (contasForm) {
            this.contasForm = contasForm;
        } else {
            this.form.get("contasForm").setValue(this.contasForm);
        }

        if (this.dadosCarregados?.contas) {
            const valuesMapeados = this.dadosCarregados.contas.map((c) => ({
                ...c,
                criaConta: !!c.contaPosEvento,
            }));
            this.contasForm.get("contasSelecionadas").setValue(valuesMapeados);
            this.dadosCarregados.contas = null;
        }
        this.implantado = this.dadosCarregados?.implantado;
    }

    addCC() {
        if (
            !this.dataInicioVigenciaValida(this.contaSelecionada.inicioVigencia)
        ) {
            Swal.fire(
                "Formulário inválido",
                "Data início da Vigência deve ser igual ou maior que o início de vigência informado para a configuração do evento.",
                "error"
            );
            return;
        }

        if (
            this.contaSelecionada.fimVigencia &&
            !this.vigenciaValida(
                this.contaSelecionada.inicioVigencia,
                this.contaSelecionada.fimVigencia
            )
        ) {
            Swal.fire(
                "Formulário inválido",
                "Data fim não pode ser menor que a data início da vigência.",
                "error"
            );
            return;
        }

        const contasSelecionadas =
            this.contasForm.get("contasSelecionadas").value;

        if (!this.contaSelecionada.criaConta)
            this.contaSelecionada.contaPosEvento = null; //reseta conta pos evento caso o checkbox tenha sido alterado

        if (this.indexEdicao !== null) {
            contasSelecionadas[this.indexEdicao] = this.contaSelecionada;
        } else {
            contasSelecionadas.push(this.contaSelecionada);
        }
        this.contasForm.get("contasSelecionadas").setValue(contasSelecionadas);

        this.contaSelecionada = new ContaSelecao();
        this.indexEdicao = null;
    }

    editarCC(cc) {
        const contasSelecionadas =
            this.contasForm.get("contasSelecionadas").value;
        this.indexEdicao = contasSelecionadas.indexOf(cc);
        this.contaSelecionada = { ...contasSelecionadas[this.indexEdicao] };
    }

    removerCC(cc) {
        const contasSelecionadas =
            this.contasForm.get("contasSelecionadas").value;
        const index = contasSelecionadas.indexOf(cc);
        contasSelecionadas.splice(index, 1);
        this.contasForm.get("contasSelecionadas").setValue(contasSelecionadas);
    }

    buscarContaPorNome(event: AutoCompleteCompleteEvent) {
        this.egcService
            .getContaByNome(event.query)
            .pipe(debounceTime(500))
            .subscribe((s: Pageable<any>) => {
                this.contaAutoCompleteOptions = s.content;
            });
    }

    get desabilitaAdicionarCC() {
        return !(
            !!this.contaSelecionada.conta &&
            !!this.contaSelecionada.inicioVigencia &&
            ((this.contaSelecionada.criaConta &&
                !!this.contaSelecionada.contaPosEvento) ||
                !this.contaSelecionada.criaConta)
        );
    }

    private getFormatedDate(date: string) {
        if (!date) return null;
        const [dia, mes, ano] = date.split("/");
        const novaData = new Date(+ano, +mes - 1, +dia);
        novaData.setHours(0, 0, 0, 0);
        return novaData;
    }

    private vigenciaValida(inicioVigencia: string, fimVigencia: string) {
        const dtInicioVigencia = this.getFormatedDate(inicioVigencia);
        const dtFimVigencia = this.getFormatedDate(fimVigencia);
        return dtFimVigencia >= dtInicioVigencia;
    }

    private dataInicioVigenciaValida(inicioVigencia: string) {
        const dtInicioVigencia = this.getFormatedDate(inicioVigencia);
        // const hoje = new Date();
        // return dtInicioVigencia >= hoje;

        const dataInicioConfig = this.getFormatedDate(
            this.form.get("configuracaoForm").value.get("dataInicioVigencia")
                .value as string
        );

        // const dataFimConfig = this.getFormatedDate(
        //     this.form.get("configuracaoForm").value.get("dataFimVigencia")
        //         .value as string
        // );

        // if (!dataFimConfig) return dtInicioVigencia >= dataInicioConfig;
        return  dtInicioVigencia >= dataInicioConfig;
        // return (
        //     dtInicioVigencia >= dataInicioConfig &&
        //     dtInicioVigencia <= dataFimConfig
        // );
    }
}
