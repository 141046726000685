import { Injectable } from "@angular/core";
import { Message } from "primeng/api";

@Injectable({
    providedIn: "root",
})
export class RemoveParamsUtil {
    msgs1: Message[];
    constructor() { }

    removeParametroNaoUtilizado(params: any) {
        Object.keys(params).forEach((key) => {
          if (
            params[key] === null ||
            params[key] === '' ||
            params[key] === undefined
          ) {
            delete params[key];
          }
        });
        return params;
      }
}