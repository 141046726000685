import {Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {Store} from '@ngrx/store';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {UtilsService} from '../../../shared/utils.service';
import {EventoPagamentoRegraImputacaoModalService} from './evento-pagamento-regra-imputacao-modal.service';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { EventoPagtoRgrImputacaos } from 'src/app/models/evento-pagamento-regra-imputacoes';
import { Regra } from 'src/app/models/regra';
import Swal from 'sweetalert2';
import {ConfigEventoPagamento} from '../../../models/config-evento-pagamento';

@Component({
    selector: 'app-evento-pagamento-regra-imputacao-modal',
    templateUrl: './evento-pagamento-regra-imputacao-modal.component.html',
    styleUrls: ['./evento-pagamento-regra-imputacao-modal.component.scss']
})
export class EventoPagamentoRegraImputacaoModalComponent implements OnInit {

    blockedDocument = false;

    @Input() dataInicio: string;
    @Input() regraImputacaoLength: any;
    @Input() regraImputacaoEdit: any;
    @Input() eventoPagamento: any;

    validaData = false;
    dataInicioVigencia: string;
    regrasImputacao: EventoPagtoRgrImputacaos[];
    regraImputacao: EventoPagtoRgrImputacaos;
    eventoPagtoRgrImputacao: EventoPagtoRgrImputacaos;
    dateStart: Date;
    @Output() public eventoPagamentoImputadoSelecionado = new EventEmitter<EventoPagtoRgrImputacaos>();

    constructor(
        private storeBaseLegalSearchModal: Store<{ baseLegalSearchModal: { displayMaximizable: boolean } }>,
        private http: HttpClient,
        public utilsService: UtilsService,
        public eventoPagamentoRegraImputacaoModalService: EventoPagamentoRegraImputacaoModalService,
    ) {
    }

    ngOnInit(): void {
        this.eventoPagtoRgrImputacao = new EventoPagtoRgrImputacaos();
        this.regraImputacao = new EventoPagtoRgrImputacaos();
    }
    confirmar() {
        const eventoPagamento: ConfigEventoPagamento = this.eventoPagamentoRegraImputacaoModalService
            .eventoPagamento;
        const eventoPagtoRgrImputacaoLast: EventoPagtoRgrImputacaos = this.eventoPagamentoRegraImputacaoModalService
            .eventoPagtoRgrImputacaoLast || null;

        const inicioVigenciaEvento = this.utilsService.convertDateUsToSave(eventoPagamento.dataInicioVigencia);
        const inicioVigenciaNew = this.utilsService.convertDateUsToSave(this.dateStart);
        const inicioVigenciaLast = this.utilsService.convertDateUsToSave(eventoPagtoRgrImputacaoLast?.dataInicioVigencia);
        const fimVigenciaLast = this.utilsService.convertDateUsToSave(eventoPagtoRgrImputacaoLast?.dataFimVigencia);

        const diffDatasEvento = this.utilsService.diffInDays(inicioVigenciaNew, inicioVigenciaEvento);
        const diffAnterior = this.utilsService.diffInDays(inicioVigenciaNew, fimVigenciaLast);

        if (this.regraImputacao == null) {
            Swal.fire('Erro', 'Selecione uma regra', 'error').then();
        } else if (inicioVigenciaNew === '' || inicioVigenciaNew === null) {
            Swal.fire('Erro', 'Informe a data de início da vigência', 'error').then();
        } else if (diffDatasEvento != 0 && eventoPagamento.eventoPagtoRgrImputacaos.length == 0) {
            Swal.fire(
                "Atenção",
                `O início de vigência selecionada deve ser igual ao início de vigência do evento de compensação: ${this.utilsService.convertToDateBr(inicioVigenciaEvento)}`,
                "warning",
            ).then(null)
        } else if (eventoPagamento.eventoPagamentoTransacaos.length > 0 && inicioVigenciaLast !== null && diffAnterior <= 0) {
            Swal.fire('Erro', `Início da vigência informado deve ser maior que o início da vigência da transação anterior: <b>${this.utilsService.convertToDateBr(inicioVigenciaLast)}</b>`, 'error').then();
        } else {
            const eventoPagtoRgrImputacoes: any[] = eventoPagamento.eventoPagtoRgrImputacaos
            const i = eventoPagtoRgrImputacoes.some(r => {
                return  r.regraImputacao.id == this.regraImputacao.regraImputacao.id && (r.dataFimVigencia == "" || r.dataFimVigencia == null);
            })
            if (i) {
                Swal.fire("Atenção", "Regra de Imputação já associada ao evento de pagamento para o período de vigência informado.", "warning")
                    .then(null);
            } else {
                this.blockedDocument = true;
                this.select();
                this.blockedDocument = false;
            }
        }

        // const dtIniEventoPagamento = this.eventoPagamento.dataInicioVigencia
        // const diffDatas = this.utilsService.diffInDays(dtIniEventoPagamento, this.dateStart);
        // if (diffDatas != 0 && this.eventoPagamentoRegraImputacaoModalService.eventoPagamento.eventoPagtoRgrImputacaos.length == 0) {
        //     Swal.fire(
        //         "Atenção",
        //         `O início de vigência selecionada deve ser igual ao início de vigência do evento de compensação: ${this.utilsService.convertToDateBr(dtIniEventoPagamento)}`,
        //         "warning",
        //     ).then(null)
        // } else {
        //     this.blockedDocument = true;
        //     this.select();
        //     this.blockedDocument = false;
        // }
    }

    select() {
        this.regraImputacao.dataInicioVigencia = this.dataInicioVigencia;
       // this.regraImputacao.regraImputacao["regraId"] = this.regraImputacao.regraImputacao["id"];
        const regImputacao = JSON.parse(JSON.stringify(this.regraImputacao));
        this.eventoPagamentoImputadoSelecionado.emit(regImputacao);
        this.eventoPagamentoRegraImputacaoModalService.displayMaximizable = false;
    }

    onHide() {}

    onShow(): void {
        this.dateStart = this.eventoPagamentoRegraImputacaoModalService.dateStart;
        this.carregaRegrasImputacao();
        this.validaData = true;
        this.dataInicioVigencia = moment(this.dateStart).format('YYYY-MM-DD');
        this.validaConfirmar();
    }

    carregaRegrasImputacao(): void {
        this.getRegrasImputacao().subscribe(s => {
            // tslint:disable-next-line: no-string-literal
            this.regrasImputacao = s['content'];
            
            this.regraImputacao.regraImputacao = this.regraImputacaoEdit.regraImputacao;
            if(this.regraImputacaoEdit.eventoPagtoRgrImputacaoId) {
                this.dateStart = new Date(this.regraImputacaoEdit.dataInicioVigencia + "Z");
                this.dateStart.setDate(this.dateStart.getDate() + 1);
            }
        });
    }

    getRegrasImputacao(): Observable<Array<Regra>> {
        return this.http.get<Array<Regra>>(`${environment.baseUrl}/regra-imputacao/search?paged=false&situacao=ATIVA`);
    }


    onSelectDataInicioVigencia(event) {
        if (event) {
            this.dataInicioVigencia = moment(event).format('YYYY-MM-DD');
            return this.dataInicioVigencia;
        } else {
            return this.dataInicioVigencia = '';
        }
    }
    validaConfirmar() {
        return this.dateStart && !Array.isArray(this.regraImputacao.regraImputacao) ? false : true;
    }
}
