<div class="card">
    <p-toast></p-toast>
    <p-steps [model]="items" [readonly]="true"></p-steps>
</div>

<div class="stepsdemo-content">
    <p-card>
        <ng-template pTemplate="title">
            Unidade Organizacional
        </ng-template>

        <ng-template pTemplate="header">
            <div class="grid grid-nogutter justify-content-end" [style]="{padding: '10px'}">
                <p-button label="Anterior" (onClick)="prevPage()" icon="pi pi-angle-left"></p-button>
                <p-button class="float-btn-next" label="Próxima" (onClick)="nextPage()" icon="pi pi-angle-right" iconPos="right"></p-button>
                <p-button
                        [loading]="isLoading"
                        *ngIf="!configConta.configContaId"
                        class="float-btn-next"
                        label="Salvar Conta"
                        (onClick)="salvarContaAll()"
                        icon="pi pi-angle-right"
                        iconPos="right"
                        styleClass="p-button-success">
                </p-button>
            </div>
        </ng-template>

        <ng-template pTemplate="content">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12">
                    <app-identificacao-conta [configConta]="configConta"></app-identificacao-conta>
                </div>
                <div class="p-field p-col-2">
                    <label title="regraCriacaoContaId">Código *</label>
                    <div class="p-inputgroup">
                        <input #unidadeOrganizacionalId="ngModel" required type="text" pInputText name="unidadeOrganizacionalId"
                        [(ngModel)]="service.configContaUnidOrganiza.unidadeOrganizacional.unidadeOrganizacionalId" readonly>
                        <button [disabled]="service.configContaUnidOrganiza.configContaUnidOrganizaId" icon="pi pi-search" type="button" pButton pRipple (click)="searchModal()"></button>
                    </div>
                    <small *ngIf="(unidadeOrganizacionalId.invalid && submitted)" class="p-error">Campo obrigatório</small>
                </div>

                <div class="p-field p-col-10">
                    <label>Nome</label>
                    <input type="text" pInputText disabled [(ngModel)]="service.configContaUnidOrganiza.unidadeOrganizacional.descricao">
                </div>

                <div class="p-field p-col-4">
                    <label>Situação*</label>
                    <p-dropdown
                            [disabled]="!configConta?.configContaId || configConta.implantado === 'NAO' || !service.configContaUnidOrganiza.configContaUnidOrganizaId"
                            #situacao="ngModel"
                            name="situacao"
                            required
                            inputId="dropdown"
                            showClear="true"
                            optionLabel="valor"
                            optionValue="chave"
                            [autoDisplayFirst]="false"
                            [options]="situacoes"
                            [(ngModel)]="service.configContaUnidOrganiza.situacao"
                    >
                    </p-dropdown>
                    <small *ngIf="(situacao.invalid && submitted)" class="p-error">Campo obrigatório</small>
                </div>
                <div class="p-field p-col-4">
                    <label>Início da Vigência *</label>
                    <p-calendar
                            [disabled]="configConta.implantado === 'SIM' && service.configContaUnidOrganiza.configContaUnidOrganizaId"
                            #dataInicioVigencia="ngModel"
                            name="dataInicioVigencia"
                            dateFormat="dd/mm/yy"
                            [showIcon]="true"
                            [(ngModel)]="service.configContaUnidOrganiza.dataInicioVigencia"
                            (ngModelChange)="onSelectDataInicioVigencia($event)"
                            [required]="submitted"
                    ></p-calendar>
                    <small *ngIf="(dataInicioVigencia.invalid && submitted)" class="p-error">Campo obrigatório</small>
                </div>
                <div class="p-field p-col-4">
                    <label>Fim da Vigência</label>
                    <p-calendar
                            dateFormat="dd/mm/yy"
                            [showIcon]="true"
                            [(ngModel)]="service.configContaUnidOrganiza.dataFimVigencia"
                            (ngModelChange)="onSelectDataFimVigencia($event)"
                    ></p-calendar>
                </div>
            </div>

            <div class="p-field p-col-12 p-md-12" style="margin-top: 10px; text-align: right;">
                <button
                    *ngIf="service.configContaUnidOrganiza.configContaUnidOrganizaId == null"
                    pButton
                    type="button"
                    label="Adicionar Unidade Organizacional"
                    icon="pi pi-plus-circle"
                    (click)="save()"
                    [disabled]="!configConta.configContaId"
                ></button>
                <button
                    *ngIf="service.configContaUnidOrganiza.configContaUnidOrganizaId != null"
                    pButton
                    type="button"
                    label="Atualizar Unidade Organizacional"
                    icon="pi pi-pencil"
                    class="p-mr-1"
                    (click)="save()"
                ></button>
                <button
                    *ngIf="service.configContaUnidOrganiza.configContaUnidOrganizaId != null"
                    pButton
                    type="button"
                    label="Cancelar"
                    icon="pi pi-times"
                    class="p-button-secondary"
                    (click)="cancel()"
                ></button>
            </div>

            <p-table [value]="configContaUnidOrganizas">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Código</th>
                        <th>Nome</th>
                        <th>Situação</th>
                        <th>Início da Vigência</th>
                        <th>Fim da Vigência</th>
                        <th>Ações</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-configContaUnidOrganiza>
                    <tr>
                        <td>{{configContaUnidOrganiza.unidadeOrganizacional.unidadeOrganizacionalId}}</td>
                        <td>{{configContaUnidOrganiza.unidadeOrganizacional.descricao}}</td>
                        <td>{{configContaUnidOrganiza.situacao}}</td>
                        <td>{{utils.convertToDateBr(configContaUnidOrganiza.dataInicioVigencia)}}</td>
                        <td>{{utils.convertToDateBr(configContaUnidOrganiza.dataFimVigencia)}}</td>
                        <td>
                            <button pButton pRipple title="Editar" type="button" icon="pi pi-pencil" class="p-button-rounded p-mr-1" (click)="edit(configContaUnidOrganiza)"></button>
                            <button
                                 *ngIf="configConta.implantado =='SIM'"
                                pButton
                                pRipple
                                title="{{ configContaUnidOrganiza.situacao === 'ATIVA' ? 'Inativar' : 'Ativar' }}"
                                type="button"
                                icon="{{ configContaUnidOrganiza.situacao === 'ATIVA' ? 'pi pi-times' : 'pi pi-check' }}"
                                class="p-button-rounded p-button-secondary"
                                (click)="ativaInativar(configContaUnidOrganiza)"
                            ></button>
                            <button
                                *ngIf="configConta.implantado =='NAO'"
                                pButton
                                pRipple
                                title="Excluir"
                                type="button"
                                icon="pi pi-trash"
                                class="p-button-rounded p-button-danger"
                                style="margin-left: 3px"
                                (click)="delete(configContaUnidOrganiza)"
                            ></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td>
                            Nenhum registro localizado
                        </td>
                    </tr>
                </ng-template>
            </p-table>

            <app-unidade-organizacional-search-modal></app-unidade-organizacional-search-modal>
            <p-paginator [rows]="10" [totalRecords]="pagination.totalElements" (onPageChange)="onPageChange($event)"></p-paginator>
        </ng-template>

        <ng-template pTemplate="footer">
            <div class="grid grid-nogutter justify-content-end">
                <p-button label="Anterior" (onClick)="prevPage()" icon="pi pi-angle-left"></p-button>
                <p-button class="float-btn-next" label="Próxima" (onClick)="nextPage()" icon="pi pi-angle-right" iconPos="right"></p-button>
                <p-button
                   [loading]="isLoading"
                   *ngIf="!configConta.configContaId"
                   class="float-btn-next"
                   label="Salvar Conta"
                   (onClick)="salvarContaAll()"
                   icon="pi pi-angle-right"
                   iconPos="right"
                   styleClass="p-button-success">
                </p-button>
            </div>
        </ng-template>
    </p-card>
</div>
