import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Pagination } from 'src/app/shared/pagination/pagination';
import { AlertsUtil } from 'src/app/utils/alerts/alerts.util';
import { ConsultaContaCorrenteService } from '../../services/consulta-conta-corrente.service';
import { ModalEventoContaCorrenteComponent } from 'src/app/modal/modal-evento-conta-corrente/modal-evento-conta-corrente.component';

@Component({
  selector: 'ccp-detalhe-eventos-transacoes',
  templateUrl: './detalhe-eventos-transacoes.component.html',
  styleUrls: ['./detalhe-eventos-transacoes.component.scss']
})
export class DetalheEventosTransacoesComponent implements OnInit {

  /**verificar possibilidade - localestorage */
  @Input() conta;
  @ViewChild(ModalEventoContaCorrenteComponent, { static: false })
    modalEventoConta: ModalEventoContaCorrenteComponent;

  eventosList: any[] = [];
  transacaoList: any[] = [];
  transacaoListaCalculoTotal: any[] = [];
  pagination: Pagination<any>;
  transacaoListResponse: any[] = [];
  transacaoVinculadaEvento: any;

  constructor(
    private consultaContaCorrenteService: ConsultaContaCorrenteService,
    private alertUtil: AlertsUtil
    ) { }

  ngOnInit(): void {
    this.pagination = new Pagination<any>();
    this.conta = JSON.parse(localStorage.getItem('conta'));
    this.getDetalheEventos(this.conta?.contaCorrenteId);
  }

  getDetalheEventos(contaCorrenteId){
    this.consultaContaCorrenteService.getDetalheEventos(contaCorrenteId).subscribe((response: any) =>{
      this.pagination = response;
      this.eventosList = response?.content;
      const contaCorrenteEventoIds = [...new Set(this.eventosList.map(item => item.contaCorrenteEventoId))];
      this.listaTransacoesPush(contaCorrenteEventoIds);
   });
  }

  onPageChange(e) {
    this.pagination.pageable.pageNumber = e.page;
    this.getDetalheEventos(this.conta?.contaCorrenteId);
  }

  /**cada clique de evento, exibe as transações por evento */
  listaTransacoesPush(contaCorrenteEventoIds) {
    const contaCorrenteId = this.conta?.contaCorrenteId;
    const objParams = {
      contaCorrenteEventoId: contaCorrenteEventoIds,
      contaCorrenteId: contaCorrenteId
    };
    this.consultaContaCorrenteService.getTransacoes(objParams).subscribe((resp: any) =>{
        this.transacaoListResponse = resp;
        if(this.transacaoList.find(aux=> aux.contaCorrenteTransacaoId == this.transacaoListResponse[0].contaCorrenteTransacaoId)){
          return
        } else {
          this.transacaoListaCalculoTotal = [];
          this.calculaValor(this.transacaoListResponse)
        }
    }, error =>{
        this.alertUtil.handleError(error)
    })
  }

  /**cada clique de evento, busca as transações para este evento */
  buscaTransacoesVinculoEvento(contaCorrenteEventoId) {
    const contaCorrenteId = this.conta?.contaCorrenteId;
    const objParams = {
      contaCorrenteEventoId: contaCorrenteEventoId,
      contaCorrenteId: contaCorrenteId
    };
    this.consultaContaCorrenteService.getTransacoes(objParams).subscribe((resp: any) =>{
      this.transacaoVinculadaEvento = resp
    }, error =>{
        this.alertUtil.handleError(error)
    })
  }

  /**Validação para verificar se a transação tem vinculo com evento */
  vinculoEvento(contaCorrenteTransacao){
      if(this.transacaoVinculadaEvento?.find(aux=> aux.contaCorrenteTransacaoId === contaCorrenteTransacao)){
        return true;
      }
      return false;
  }

  calculaValor(transacaoList) {
    let calculo = 0;
    transacaoList.forEach((element) => {
    calculo = parseFloat(element?.valorTransacao) + calculo;
    this.transacaoListaCalculoTotal.push(
      {
        ...element,
        ...{valorTotal: calculo}
      });
      this.transacaoList = this.transacaoListaCalculoTotal;
    });
  }

  openModalEvento(evento){
    this.modalEventoConta.showDialog(evento);
  }

}
