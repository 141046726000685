import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { EventosFinanceirosService } from 'src/app/core/eventos-financeiros.service';
import { Transacao } from 'src/app/models/transacao';
import { TransacaoRegraCalculo } from 'src/app/models/transacao-regra-calculo';
import { AlertsUtil } from 'src/app/utils/alerts/alerts.util';
import swal from 'sweetalert2';
import { UtilsService } from "../../../../shared/utils.service";
import { BaseLegalSearchModalService } from '../../../base-legal/base-legal-search-modal/base-legal-search-modal.service';
import { ReducaoMultaPunitivaComponent } from '../../components/reducao-multa-punitiva/reducao-multa-punitiva.component';
import { PerfisAcessoService } from '../../services/perfis-acesso.service';
import { TransacaoService } from '../../services/transacao.service';

@Component({
    selector: 'app-cadastro-transacao-financeira',
    templateUrl: './cadastro-transacao-financeira.component.html',
    styleUrls: ['./cadastro-transacao-financeira.component.scss'],
    providers: [BaseLegalSearchModalService]
})
export class CadastroTransacaoFinanceiraComponent implements OnInit {

    formGroupTransacao: FormGroup;
    situacaoTransacaoEnum: any[];
    operacaoTransacaoEnum: any[];
    tipoTransacaoEnum: any[];
    tipoAliquotaEnum: any[];
    periodicidadeIncidenciaEnum: any[];
    naturezaItemCalculoEnum: any[];
    indiceList: any[];
    regraVencimentoList: any[];
    tipoContagemEnum: any[];
    dataReferenciaList: any[];
    geraTransacaoInicialEnum: any;
    tipoCapitalizacaoEnum: any[];
    tituloPagina: string;
    dadosEvento: any;
    eventoId: any;
    transacaoId: number;
    submitted = false;
    desabilitaDropdownOperacao = false;
    desabilitaDropdownSituacao = false;
    desabilitaCheckboxGeraTransacao = false;
    baseLegal = null;
    insereValueEnums = true;
    baseIncidencias: any[];
    transacaoRegraReducaoRequest: any;
    transacaoReferenciaRequest: any;
    formReducaoValido = false;
    formReferenciasValido = false;
    objRegraReducao: any;
    objReferencias: any;

    itemTransacaoRegraReducaoRequest: null;
    itemTransacaoReferenciaRequest: any;
    formGroupRegraCalculo: FormGroup;
    perfis: any[] = [];
    @ViewChild('regraReducaoComp') regraReducaoComp: any;
    @ViewChild('referencias') referenciasComp: any;

    beneficioImplantado: boolean;
    showDia = false;
    showMes = false;
    showQtdMeses = false;
    showQtdDias = false;
    showContagemDias = false;
    habilitaRegraCalculo = false;
    habilitaReducaoMulta = false;
    habilitaBaseIncidencia = false;
    habilitaReferencias = false;
    usarAliquota = false;
    usarIndice = false;
    @ViewChild('dataInicialBaseLegal')
    private dataInicioBaseLegal: any;
    @ViewChild('dataFinalBaseLegal')
    private dataFinalBaseLegal: any;

    @ViewChild(ReducaoMultaPunitivaComponent, { static: false })
    reducaoConta: ReducaoMultaPunitivaComponent;


    constructor(
        private formBuilder: FormBuilder,
        private location: Location,
        private activatedRouter: ActivatedRoute,
        public transacaoService: TransacaoService,
        public baseLegalSearchModalService: BaseLegalSearchModalService,
        private eventosFinanceiroService: EventosFinanceirosService,
        private perfisAcessoService: PerfisAcessoService,
        private router: Router,
        private utils: UtilsService,
        private alertUtil: AlertsUtil,
    ) {
        this.iniciaForm()
    }

    ngOnInit(): void {
        this.transacaoId = this.activatedRouter.snapshot.params['transacaoId'];
        this.eventoId = this.activatedRouter.snapshot.params['eventoId'];

        this.getEnums().then(data => {
            this.getTransacaoById(this.transacaoId);
        });

        this.transacaoService.getTipoTransacao().subscribe(items => {
            this.tipoTransacaoEnum = items;
        });
    }

    async getEnums() {
        this.operacaoTransacaoEnum = await this.transacaoService.operacaoTransacao().toPromise()
        this.situacaoTransacaoEnum = await this.transacaoService.getSituacao().toPromise()
        this.situacaoTransacaoEnum = this.situacaoTransacaoEnum.reverse();
        this.geraTransacaoInicialEnum = await this.transacaoService.getSimNao().toPromise()
        this.tipoAliquotaEnum = await this.transacaoService.getEnumTipoAliquota().toPromise()
        this.periodicidadeIncidenciaEnum = await this.transacaoService.getEnumPeriodicidadeIncidencia().toPromise()
        this.naturezaItemCalculoEnum = await (await this.transacaoService.getNaturezaItemCalculo().toPromise()).sort((a, b) => a.naturezaItemCalculoId - b.naturezaItemCalculoId)
        this.indiceList = await (await this.transacaoService.getIndiceList().toPromise()).map(data => {
            data.descricao = `${data.codigo} - ${data.descricao}`;
            return data;
        });
        this.regraVencimentoList = await this.transacaoService.getRegraVencimentoList().toPromise()
        this.dataReferenciaList = await this.transacaoService.getDataReferenciaList().toPromise()
        this.tipoContagemEnum = await this.transacaoService.getTipoContagem().toPromise()
        this.tipoCapitalizacaoEnum = await this.transacaoService.getEnumTipoCapitalizacao().toPromise();
        return new Promise(resolve => {
            resolve('');
        })
    }

    getEventoById(eventoId) {
        this.desabilitaDropdownSituacao = true;

        this.eventosFinanceiroService.getEventoById(eventoId).subscribe(
            (eventosResponse) => {
                this.dadosEvento = eventosResponse;
                this.tituloPagina = `NOVA: ${this.dadosEvento.descricaoCompleta}`;
                this.formGroupTransacao.patchValue({operacao: this.operacaoTransacaoEnum.find(o => o.chave == this.dadosEvento.operacao).chave});
                this.desabilitaDropdownOperacao = this.dadosEvento.operacao === "CREDITO_DEBITO" ? false : true;
                this.desabilitaCheckboxGeraTransacao = this.dadosEvento.geraTransacaoInicial === "NAO" ? true : false;
                this.habilitaBaseIncidencia = this.dadosEvento.temBaseIncidencia === "SIM";
                this.habilitaRegraCalculo = this.dadosEvento.temRegraCalculo === "SIM";
                this.habilitaReducaoMulta = this.dadosEvento.temRegraReducao === "SIM";
                this.habilitaReferencias = this.dadosEvento.temBeneficioFiscal === "SIM";

                if (this.habilitaBaseIncidencia) {
                    this.baseIncidenciasSelected.setValidators(Validators.required);
                } else {
                    this.baseIncidenciasSelected.setValidators(null);
                }
            },
            (error) => {
                let erroMsg = error.error.message ? error.error.message : `Evento relacionado a transação não encontrado: ${eventoId}`;
                this.router.navigate([`/`]);
                swal.fire({title: "Atenção", text: `${erroMsg}`, icon: "warning",});
            }
        );
    }

    changeBaseLegal(obj) {
        if(obj) {
            obj.tblVigenciaInicio = obj.tblVigenciaInicio ? moment(obj.tblVigenciaInicio, 'YYYY-MM-DD').format('DD/MM/YYYY') : "";
            obj.tblVigenciaFinal = obj.tblVigenciaInicio ? moment(obj.tblVigenciaFinal, 'YYYY-MM-DD').format('DD/MM/YYYY') : "";
            this.dataInicioBaseLegal.inputfieldViewChild.nativeElement.value = obj.tblVigenciaInicio;
            this.dataFinalBaseLegal.inputfieldViewChild.nativeElement.value = obj.tblVigenciaFinal;
        }

        this.baseLegal = obj;
    }

    getTransacaoById(transacaoId) {
        if (transacaoId) {
            this.transacaoService.getTransacao(transacaoId).subscribe(
                (transacao: any) => {
                    this.dadosEvento = transacao.eventos;
                    this.tituloPagina = `EDITAR: ${this.dadosEvento.descricaoCompleta}`;
                    this.eventoId = transacao.eventos.eventosId;
                    this.habilitaBaseIncidencia = transacao.eventos.temBaseIncidencia === "SIM";
                    this.habilitaRegraCalculo = transacao.eventos.temRegraCalculo === "SIM";
                    this.habilitaReducaoMulta = transacao.eventos.temRegraReducao === "SIM";
                    this.habilitaReferencias = transacao.eventos.temBeneficioFiscal === "SIM";
                    this.iniciaForm(transacao);

                    if (this.habilitaBaseIncidencia) {
                        this.baseIncidenciasSelected.setValidators(Validators.required);
                    } else {
                        this.baseIncidenciasSelected.setValidators(null);
                    }
                    this.beneficioImplantado = transacao.beneficioImplantado ? true : false;
                    this.desabilitaDropdownOperacao = transacao.eventos.operacao === "CREDITO_DEBITO" ? false : true;
                    this.desabilitaCheckboxGeraTransacao = transacao.eventos.geraTransacaoInicial === "NAO";
                    this.itemTransacaoRegraReducaoRequest = transacao.transacaoRegraReducaoRequest;
                    this.itemTransacaoReferenciaRequest = transacao.transacaoReferenciaRequest;
                    if(this.itemTransacaoReferenciaRequest) {
                        this.itemTransacaoReferenciaRequest =
                            {
                                ...this.itemTransacaoReferenciaRequest,
                                beneficioImplantado: this.beneficioImplantado,
                                tributoId: this.itemTransacaoReferenciaRequest.tributo.tributoId,
                                baseIncidencias: transacao.baseIncidencias?.map(
                                    baseIncidencia => baseIncidencia
                                )
                            }
                    }

                    if (transacao.baseLegal != null) {
                        this.changeBaseLegal(transacao.baseLegal);
                    }
                },
                (error) => {
                    let erroMsg = error.error.message ? error.error.message : `Transacao não encontrada: ${transacaoId}`;
                    this.router.navigate([`/gerenciar-transacao-financeira/evento/${this.eventoId}`]);
                    swal.fire({title: "Atenção", text: `${erroMsg}`, icon: "warning",});
                }
            );
        } else {
            this.getEventoById(this.eventoId);
        }
    }

    get baseIncidenciasSelected() {
        return this.formGroupTransacao ? this.formGroupTransacao.controls['baseIncidencias'] : null;
    }

    get regraCalculoControls() {
        return this.formGroupRegraCalculo.controls;
    }

    get temAliquotaMaxima() {
        return this.formGroupRegraCalculo ? this.formGroupRegraCalculo.controls['temAliquotaMaxima'] : null;
    }

    async iniciaForm(obj?) {
        if (obj?.dataCriacao != null) {
            obj.dataCriacao = this.utils.convertToDateBr(obj.dataCriacao);
        }

        this.formGroupTransacao = this.formBuilder.group({
            transacaoId: [obj ? obj.transacaoId : null],
            nomeCompleto: [obj ? obj.nomeCompleto : null, Validators.required],
            nomeResumido: [obj ? obj.nomeResumido : null, Validators.required],
            operacao: [obj ? this.operacaoTransacaoEnum.find(o => o.chave === obj.operacao).chave : null],
            situacao: [obj ? this.situacaoTransacaoEnum.find(s => s.chave === obj.situacao).chave : 'ATIVA'],
            geraTransacaoInicial: [obj ? this.geraTransacaoInicialEnum.find(g => g.chave === obj.geraTransacaoInicial).id : null],
            dataCriacao: [obj ? obj.dataCriacao : new Date()],
            tipoTransacao: [obj ? this.tipoTransacaoEnum.find(t => t.chave === obj.tipoTransacao) : null, Validators.required],
            observacao: [obj ? obj.observacao : null],
            idBaseLegal: [obj ? obj.idBaseLegal : null],
            idEventos: [obj ? obj.idEventos : null],
            baseIncidencias: [[]],
            transacaoRegraReducaoRequest: [],
            transacaoReferenciaRequest: [],
            naturezaItemCalculo: [obj ? obj.naturezaItemCalculo : null]
        });

        this.formGroupRegraCalculo = this.formBuilder.group({
            id: [null],
            tipoAliquota: [null, Validators.required],
            valorAliquota: [null, Validators.required],
            indice: [null, Validators.required],
            temAliquotaMaxima: [false, Validators.required],
            valorAliquotaMaxima: [{value: null, disabled: true}],
            periodicidadeIncidencia: [null, Validators.required],
            regraVencimento: [null, Validators.required],
            tipoDataReferencia: [null, Validators.required],
            tipoCapitalizacao:[null, Validators.required],
            diaIncidencia: [null],
            mesIncidencia: [null],
            tipoContagem: [null],
            qtdeMesIncidencia: [null],
            qtdeDiasIncidencia: [null],
        });

        if (obj && this.transacaoId) {

            this.formGroupTransacao.patchValue({
                baseIncidencias: this.habilitaBaseIncidencia ? obj.baseIncidencias?.map(
                    baseIncidencia => baseIncidencia
                ) : []
            });

            const regraCalculo: any = await this.transacaoService.getRegraCalculoByTransacaoId(obj.transacaoId).toPromise();
            if(regraCalculo) {
                this.formGroupRegraCalculo.patchValue({
                    ...regraCalculo,
                    regraVencimento: (this.habilitaRegraCalculo && regraCalculo) ? regraCalculo.regraVencimento?.regraVencimentoId : null,
                    tipoDataReferencia: (this.habilitaRegraCalculo && regraCalculo) ? regraCalculo.tipoDataReferencia?.tipoDataReferenciaId : null,
                    indice: (this.habilitaRegraCalculo && regraCalculo) ? regraCalculo.indice?.codigo : null,
                    tipoContagem: (this.habilitaRegraCalculo && regraCalculo) ? regraCalculo.tipoContagem?.tipoContagemId : null
                });

                const formControlRegraVencimento = this.formGroupRegraCalculo.get('regraVencimento');
                this.changeCheckboxTemAliquotaMaxima({checked: regraCalculo.temAliquotaMaxima})
                this.onChangeRegraVencimento(formControlRegraVencimento);
                this.changeTipoAliquota({value: regraCalculo.tipoAliquota});
            }
        }
    }

    handleAssociarPerfil(event) {
        this.perfis = event
    }

    showBasicDialog() {
        this.baseLegalSearchModalService.displayMaximizable = true;
    }

    onChangeGeraTransacaoInicial(event) {
        if (event.checked === '1') {
            this.formGroupTransacao.value.geraTransacaoInicial = 1;
        }
    }

    atualizarCampoForm(variavel, campo) {
        if (variavel) {
            this.formGroupRegraCalculo.get(campo).enable();
            this.formGroupRegraCalculo.get(campo).setValidators(Validators.required);
        } else {
            this.formGroupRegraCalculo.get(campo).disable();
            this.formGroupRegraCalculo.get(campo).setValidators(null);
            this.formGroupRegraCalculo.get(campo).setValue(null);
        }
    }

    changeTipoAliquota({value}) {
        this.usarAliquota = value === "FIXA";
        this.usarIndice = !this.usarAliquota;
        this.atualizarCampoForm(this.usarAliquota, 'valorAliquota');
        this.formGroupRegraCalculo.get('valorAliquota').setValidators([Validators.required, Validators.max(999.999999)]);
        this.atualizarCampoForm(this.usarIndice, 'indice');
    }

    changeCheckboxTemAliquotaMaxima(data) {
        this.atualizarCampoForm(data.checked, 'valorAliquotaMaxima')
        if (data.checked) {
            this.formGroupRegraCalculo.get('valorAliquotaMaxima').enable();
            this.formGroupRegraCalculo.get('valorAliquotaMaxima').setValidators([Validators.required, Validators.max(999.999999)]);
        } else {
            this.formGroupRegraCalculo.get('valorAliquotaMaxima').disable();
            this.formGroupRegraCalculo.get('valorAliquotaMaxima').setValue(null);
        }
    }

    onChangeRegraVencimento({value}) {
        const regraVencimento = this.regraVencimentoList.find(data => data.regraVencimentoId === value);
        if (regraVencimento) {
            this.showDia = regraVencimento.regraUtilizaDia == 1;
            this.showMes = regraVencimento.regraUtilizaMes == 1;
            this.showQtdDias = regraVencimento.regraUtilizaQtddias == 1;
            this.showQtdMeses = value === 9;

            this.atualizarCampoForm(this.showDia, 'diaIncidencia');
            this.atualizarCampoForm(this.showMes, 'mesIncidencia');
            this.atualizarCampoForm(this.showQtdMeses, 'qtdeMesIncidencia');
            this.atualizarCampoForm(this.showQtdDias, 'qtdeDiasIncidencia');
            this.atualizarCampoForm(this.showQtdDias, 'tipoContagem');
        }
    }

    changeFormReducao(obj) {
        this.objRegraReducao = obj;
    }

    changeFormReferencias(obj) {
        this.objReferencias = obj;
    }

    verificaFormReferenciasEstaValido(valido) {
        if (valido) {
            this.formReferenciasValido = true;
            this.constroiObjReferencias(this.objReferencias);
        } else {
            this.formReferenciasValido = false;
        }
    }

    verificaFormReducaoEstaValido(valido) {
        if (valido) {
            this.formReducaoValido = true;
            this.constroiObjReducaoBaseadoNaPerdaBeneficio(this.objRegraReducao);
        } else {
            this.formReducaoValido = false;
        }
    }

    constroiObjReducaoBaseadoNaPerdaBeneficio(obj) {
        this.transacaoRegraReducaoRequest = {
            transacaoRegraReducaoId: obj.transacaoRegraReducaoId,
            tipoDataRefereMomenIncid: {
                tipoDataReferenciaId: obj.tipoDataRefereMomenInicid.tipoDataReferenciaId,
            },
            haPerdaBeneficio: obj.haPerdaBeneficio.chave,
            tipoValorReducao: obj.tipoValorReducao,
            valorReducao: null,
            qtdDias: null,
            valorDecrescimo: null,
            qtdDiasSubSequente: null,
            tipoContagem: null,
            tipoDataReferenciaAcontar: null,
            regrasReducoesProgressivas: []
        }

        if (obj.haPerdaBeneficio.id == "1") {
            this.transacaoRegraReducaoRequest = {
                ...this.transacaoRegraReducaoRequest,
                valorReducao: obj.valorReducao,
            }
        } else if (obj.haPerdaBeneficio.id == "2") {
            this.transacaoRegraReducaoRequest = {
                ...this.transacaoRegraReducaoRequest,
                valorReducao: obj.valorReducao,
                qtdDias: obj.qtdDias,
                tipoContagem: {
                    tipoContagemId: obj.tipoContagem.tipoContagemId
                },
                tipoDataReferenciaAcontar: {
                    tipoDataReferenciaId: obj.tipoDataReferenciaAcontar.tipoDataReferenciaId,
                }
            }
        } else if (obj.haPerdaBeneficio.id == "3") {
            this.transacaoRegraReducaoRequest = {
                ...this.transacaoRegraReducaoRequest,
                valorReducao: obj.valorReducao,
                qtdDias: obj.qtdDias,
                valorDecrescimo: obj.valorDecrescimo,
                qtdDiasSubSequente: obj.qtdDiasSubSequente,
                tipoContagem: {
                    tipoContagemId: obj.tipoContagem.tipoContagemId
                },
                tipoDataReferenciaAcontar: {
                    tipoDataReferenciaId: obj.tipoDataReferenciaAcontar.tipoDataReferenciaId,
                },
                regrasReducoesProgressivas: obj.regraReducaoProgressivaFixoLista,
            }

        } else if (obj.haPerdaBeneficio.id == "4") {
            this.transacaoRegraReducaoRequest = {
                ...this.transacaoRegraReducaoRequest,
                tipoContagem: {
                    tipoContagemId: obj.tipoContagem.tipoContagemId
                },
                tipoDataReferenciaAcontar: {
                    tipoDataReferenciaId: obj.tipoDataReferenciaAcontar.tipoDataReferenciaId,
                },
                regrasReducoesProgressivas: obj.regraReducaoProgressivaVariavelLista,
            }
        }
    }

    constroiObjReferencias(obj) {
        this.transacaoReferenciaRequest = {
            transacaoReferenciaId: obj.transacaoReferenciaId,
            transacaoRegraReducaoId: obj.transacaoRegraReducaoId,
            transacaoId: obj.transacaoId,
            dataInicioVigencia: moment(obj.dataInicioVigencia, 'DD-MM-YYYY'),
            dataFimVigencia: obj.dataFimVigencia ? moment(obj.dataFimVigencia, 'DD-MM-YYYY') : null,
            pagamentoIntegral: obj.pagamentoIntegral,
            tipoReducao: obj.tipoReducao,
            tributoId: obj.tributoId,
            valorReducao: obj.valorReducao,
            tipoPagamento: obj.tipoPagamento,
        }

        this.baseIncidencias = obj.baseIncidencias;

    }

    generateTransaction() {
        if (this.habilitaReducaoMulta) {
            this.regraReducaoComp.criacaoRegraReducaoProgressiva()
        }

        if(this.habilitaReferencias) {
            this.referenciasComp.criacaoReferencias()
            if(!this.formReferenciasValido) return;
        }

        this.formGroupTransacao.patchValue({
            geraTransacaoInicial: this.formGroupTransacao.value.geraTransacaoInicial == 1 ? "SIM" : "NAO",
            transacaoRegraReducaoRequest: this.transacaoRegraReducaoRequest,
            transacaoReferenciaRequest: this.transacaoReferenciaRequest
        })

        if (this.habilitaReducaoMulta) {
            this.regraReducaoComp.criacaoRegraReducaoProgressiva()
        }

        this.formGroupTransacao.value.idEventos = this.eventoId;

        if (this.baseLegal?.baseLegalId) {
            this.formGroupTransacao.value.idBaseLegal = this.baseLegal.baseLegalId;
        }

        if (this.baseIncidencias) {
            this.formGroupTransacao.value.baseIncidencias = this.baseIncidencias;
        }

        if (this.formGroupTransacao.value.tipoTransacao) {
            this.formGroupTransacao.value.tipoTransacao = this.formGroupTransacao.value.tipoTransacao.chave;
        }
    }

    typeForm(): number {
        if (this.formGroupTransacao.valid) {
            if (this.habilitaRegraCalculo && this.formGroupRegraCalculo.valid) {
                return 1
            } else if (this.habilitaReducaoMulta && this.formReducaoValido) {
                return 2
            } else {
                return 0
            }
        } else {
            return -1
        }
    }

    onSubmit() {

        this.submitted = true;
        if(this.reducaoConta?.erroQtdDiaTabela || this.reducaoConta?.erroValorReducaoTabela){
            this.alertUtil.warning(`Verifique a quantidade de dias ou valor de reducao.`);
            return
        }
        this.generateTransaction();

        if(this.habilitaReferencias) {
            this.submitted = true
            if(!this.formReferenciasValido) return;
        }

        if (this.transacaoId) {
            if (this.habilitaRegraCalculo && this.formGroupRegraCalculo.valid) {
                this.editarTransacao('regraCalculo');
            } else if (this.habilitaReducaoMulta && this.formReducaoValido) {
                this.editarTransacao('regraReducao');
            } else if ((!this.habilitaRegraCalculo && !this.habilitaReducaoMulta) && this.formGroupTransacao.valid) {
                this.editarTransacao('transacao');
            } else {
                this.submitted = true;
            }
        } else {
            if (this.habilitaRegraCalculo && this.formGroupRegraCalculo.valid && this.formGroupTransacao.valid) {
                this.adicionarTransacao('regraCalculo');
            } else if (this.habilitaReducaoMulta && this.formReducaoValido && this.formGroupTransacao.valid) {
                this.adicionarTransacao('regraReducao');
            } else if ((!this.habilitaRegraCalculo && !this.habilitaReducaoMulta) && this.formGroupTransacao.valid) {
                this.adicionarTransacao('transacao');
            } else {
                this.submitted = true;
            }
        }
    }

    salvarRegraCalculo(result: Transacao, text) {
        let data: TransacaoRegraCalculo = this.formGroupRegraCalculo.getRawValue();

        data.transacao = result;
        data.indice = this.indiceList.find(indice => indice.codigo === data.indice);
        data.tipoDataReferencia = this.dataReferenciaList.find(dataReferencia => dataReferencia.tipoDataReferenciaId === data.tipoDataReferencia);
        data.regraVencimento = this.regraVencimentoList.find(regraVencimento => regraVencimento.regraVencimentoId === data.regraVencimento);
        data.temAliquotaMaxima = data.temAliquotaMaxima || false;
        data.tipoContagem = this.tipoContagemEnum.find(tipoContagem => tipoContagem.tipoContagemId === data.tipoContagem);
        console.log({data});
        this.transacaoService.saveOrUpdateRegraCalculo(data).subscribe(res => {
            swal.fire({icon: 'success', title: 'Sucesso', text: text, showConfirmButton: true, timer: 2500})
            this.router.navigate([`/gerenciar-transacao-financeira/evento/${this.eventoId}`]);
        });
    }

    adicionarTransacao(type) {
        this.transacaoService.postTransacao(this.formGroupTransacao.value).subscribe(async result => {
                this.transacaoId = result.transacaoId;
                this.submitted = false;
                if (this.perfis.length > 0) {
                    this.perfisAcessoService.savePerfil(this.perfis.map(res => ({
                        ...res,
                        transacaoId: result.transacaoId
                    }))).subscribe()
                }

                if (type === 'regraCalculo') {
                    this.salvarRegraCalculo(result, 'Salvo com sucesso!');
                } else if (type === 'transacao' || type === 'regraReducao') {
                    swal.fire({
                        icon: 'success',
                        title: 'Sucesso',
                        text: 'Salvo com sucesso!',
                        showConfirmButton: true,
                        timer: 2500
                    })
                    this.router.navigate([`/gerenciar-transacao-financeira/evento/${this.eventoId}`]);
                }
            },
            (error) => {
                this.handleError(error);
            });
    }

    editarTransacao(type) {
        this.transacaoService.putTransacao(this.transacaoId, this.formGroupTransacao.value).subscribe(async result => {
                this.transacaoId = result.transacaoId;
                this.submitted = false;
                if (this.perfis.length > 0) {
                    this.perfisAcessoService.savePerfil(this.perfis.map(res => ({
                        ...res,
                        transacaoId: result.transacaoId
                    }))).subscribe()
                }

                if (type === 'regraCalculo') {
                    this.salvarRegraCalculo(result, 'Editado com sucesso!');
                } else if (type === 'transacao' || type === 'regraReducao') {
                    swal.fire({
                        icon: 'success',
                        title: 'Sucesso',
                        text: 'Editado com sucesso!',
                        showConfirmButton: true,
                        timer: 2500
                    })
                    this.router.navigate([`/gerenciar-transacao-financeira/evento/${this.eventoId}`]);
                }
            },
            (error) => {
                this.handleError(error);
            });
    }

    limparBaseLegal() {
        swal.fire({
            title: "Atenção",
            text: "Deseja realmente remover a base legal?",
            icon: "warning",
            showCancelButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                this.changeBaseLegal(null);
            }
        });
    }

    handleError(error: any): void {
        swal.fire({
            title: "Atenção",
            text: error.error.errors
                    ? error.error.errors[0].message
                    : error.error.message ? `${error.error.message}` : `${error.error.error}`,
            icon: "warning",
        });
    }

    voltar() {
        this.location.back();
    }
}
