import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as moment from "moment";
import { InputNumber } from "primeng/inputnumber";
import { Sidebar } from "primeng/sidebar";
import { DateValidator } from "src/app/shared/DateValidator";
import { SimNaoEnum } from "src/app/utils/enums/sim-nao.enum";
import { environment } from "src/environments/environment";
import swal from "sweetalert2";
import { TransacaoService } from "../../services/transacao.service";

@Component({
    selector: "app-referencias",
    templateUrl: "./referencias.component.html",
    styleUrls: ["./referencias.component.scss"],
})
export class ReferenciasComponent implements OnInit {

    formGroupReferencias: FormGroup;
    naturezaItemCalculoList: any = [];
    naturezaItemCalculoSelecteds: any[] = [];
    perdaBeneficioEnum: any[];
    naturezaItemCalculoEnum: any[];

    tributos: any[];


    mode: string;
    currency: string;
    locale: string;
    tributo: any;

    formPreenchidoCorretamente: boolean;
    beneficioImplantado: boolean = false;
    

    @Output() changeForm = new EventEmitter();
    @Output() validaPreenchimentoForm = new EventEmitter();

    @Input() set transacaoRegraReducaoRequest(obj) {
        if (obj != null) {
            this.iniciaForm(obj);
        }
    }

    constructor(
        private http: HttpClient,
        private transacaoService: TransacaoService,
        private formBuilder: FormBuilder,
    ) {
        this.iniciaForm();
    }

    ngOnInit(): void {
        this.transacaoService.getNaturezaItemCalculo().subscribe((res) => {
            this.naturezaItemCalculoList = res
        });

        this.getEnums();

        this.changeCheckbox({ checked: true });
        this.http
            .get<Array<any>>(`${environment.baseUrl}/tributo`)
            .subscribe(response => {
                this.tributos = response;
            });
    }

    iniciaForm(obj?) {
        
        let validador = [
            DateValidator.dateLessThan('dataInicioVigencia', 'dataFimVigencia', { 'dataFimVigencia': true }),
        ];

        validador = !obj || !obj.transacaoReferenciaId ? validador.concat([
            DateValidator.intervalDateOneYear('dataInicioVigencia', { 'intervalDateOneYear': true }),
            DateValidator.intervalDateOneMonth('dataInicioVigencia', { 'intervalDateOneMonth': true }),
        ]) : validador;
            

        this.formGroupReferencias = this.formBuilder.group({
            transacaoReferenciaId: [obj ? obj.transacaoReferenciaId : null],
            transacaoRegraReducaoId: [obj ? obj.transacaoRegraReducaoId : null],
            transacaoId: [obj ? obj.transacaoId : null],
            dataInicioVigencia: [obj ? moment(obj.dataInicioVigencia).format('DD/MM/YYYY') : null, Validators.required],
            dataFimVigencia: [obj && obj.dataFimVigencia ? moment(obj.dataFimVigencia).format('DD/MM/YYYY') : null],
            pagamentoIntegral: [obj ? obj.pagamentoIntegral : null],
            tipoReducao: [obj ? obj.tipoReducao : 'FIXO', Validators.required],
            tributoId: [obj ? obj.tributoId : undefined, Validators.required],
            valorReducao: [obj ? obj.valorReducao : undefined, Validators.required],
            tipoPagamento: [obj ? obj.tipoPagamento : 'AVISTA', Validators.required],
            baseIncidencias: [[], Validators.required],
        },
            {
                validator: Validators.compose(validador)
            }
        );

        if(obj) {
            this.formGroupReferencias.patchValue({
                baseIncidencias: obj.baseIncidencias?.map(
                    baseIncidencia => baseIncidencia
                )
            });

            this.formGroupReferencias.get('dataInicioVigencia').markAsPending();
            this.formGroupReferencias.get('dataInicioVigencia').markAsTouched();

            this.beneficioImplantado = obj.beneficioImplantado;
        }
        if (this.formGroupReferencias.value.tipoReducao == 'FIXO') {
            this.changeValorTipoReducao(1);
        } else {
            this.changeValorTipoReducao(2);
        }
    }

    async getEnums() {
        this.naturezaItemCalculoEnum = await (await this.transacaoService.getNaturezaItemCalculo().toPromise()).sort((a, b) => a.naturezaItemCalculoId - b.naturezaItemCalculoId)

        return new Promise(resolve => {
            resolve('');
        })
    }

    get baseIncidenciasSelected() {
        return this.formGroupReferencias ? this.formGroupReferencias.controls['baseIncidencias'] : null;
    }

    get referenciasFormControl() {
        return this.formGroupReferencias.controls;
    }

    criacaoReferencias() {
        for (var i in this.formGroupReferencias.controls) {
            this.formGroupReferencias.controls[i].markAsTouched();
        }
        this.changeForm.emit(this.formGroupReferencias.value);
        this.validaPreenchimentoForm.emit(this.formGroupReferencias.valid);

    }

    changeValorTipoReducao(ev) {
        if (ev == 1) {
            this.mode = "currency";
            this.currency = "BRL";
            this.locale = "pt-BR";
        } else {
            this.mode = "";
        }
    }
    
    changeCheckbox(evento) {
        if (evento.checked) {
            this.formGroupReferencias
                .get("valorReducao")
                .setValidators(Validators.required);
        } else {
            this.formGroupReferencias.get("valorReducao").clearValidators();
        }
        this.formGroupReferencias.get("valorReducao").updateValueAndValidity();
    }

    onChange() {
        this.changeForm.emit(this.formGroupReferencias.value);
        this.validaPreenchimentoForm.emit(this.formGroupReferencias.valid);
    }

    onSelectDataInicioVigencia(event) {
        if (event) {
            this.formGroupReferencias.value.dataInicioVigencia = moment(event).format('DD/MM/YYYY');
        } else {
            this.formGroupReferencias.value.dataInicioVigencia = '';
        }
    }

    onSelectDataFimVigencia(event) {
        if (event) {
            this.formGroupReferencias.value.dataFimVigencia = moment(event).format('DD/MM/YYYY');
        } else {
            this.formGroupReferencias.value.dataFimVigencia = '';
        }
    }

    
    onClickTipoValorReducao(ev) {
        this.formGroupReferencias.get('valorReducao').setValue(null);
        this.formGroupReferencias.get('valorReducao').markAsUntouched();
        this.changeValorTipoReducao(ev);
    }

    handleError(error: any): void {
        if (error.status === 500) {
            swal.fire(
                error.statusText,
                "Ocorreu um erro interno",
                "error"
            ).then((r) => {});
        } else {
            swal.fire({
                title: "Atenção",
                text: `${error.error.message}`,
                icon: "warning",
            });
        }
    }
}
