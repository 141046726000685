import {RegraVencimento} from './regra-vencimento';
import {TipoContagem} from './tipo-contagem';
import {TipoDataReferencia} from './tipo-data-referencia';
import { Tributo } from './tributo';

export class RegraCriacaoConta {
    regraCriacaoContaId: number;
    anoVencimento: number;
    criterioUtilizacaoCredito: string;
    dataCriacao: string;
    descricaoCompleta: string;
    descricaoResumida: string;
    diaVencimento: number;
    mesVencimento: number;
    permiteBloqueioCertidao: string;
    permiteDareValorMenorSaldo: string;
    permiteDuplicidadeLancamento: string;
    permiteEmissaoDare: string;
    permiteLancamentoRetroativo: string;
    permitePrescricao: string;
    permiteUtilizarCreditoAuto: string;
    qtdDiasVencimento: number;
    qtdMesesVencimento: number;
    qtdPrescricao: number;
    qtdRetroativo: number;
    referenLancamentoRetroativo: string;
    referenciaPrescricao: string;
    situacao: string;
    vencimentoEmDiaUtil: string;
    regraVencimento: RegraVencimento;
    tipoContagemPrescricao: TipoContagem;
    tipoContagemRetroativo: TipoContagem;
    tipoContagemVencimento: TipoContagem;
    tipoDataReferenciaPrescricao: TipoDataReferencia;
    tipoDataReferenciaRetroativo: TipoDataReferencia;
    tipoDataReferenciaVencimento: TipoDataReferencia;
    tributo: Tributo;
    tributoId: number;
    permiteCotaUnica: string;
    permiteParcelar: string;
    regraVencimentoPrimeiraParcela: RegraVencimento;
    tipoDataReferenciaPrimeiraParcela: TipoDataReferencia;
    diaVencPrimeiraParcela: number;
    mesVencPrimeiraParcela: number;
    anoVencPrimeiraParcela: number;
    qtdDiasPrimeiraParcela: number;
    qtdMesesPrimeiraParcela: number;
    tipoContagemVencPrimeiraParcela: TipoContagem;
    regraVencimentoDemaisParcela: RegraVencimento;
    diaVencDemaisParcela: number;
    mesVencDemaisParcela: number;
    anoVencDemaisParcela: number;
    qtdDiasDemaisParcela: number;
    qtdMesesDemaisParcela: number;
    tipoContagemVencDemaisParcela: TipoContagem;

    constructor(init?: Partial<RegraCriacaoConta>) {
        if (init) {
            Object.assign(this, init);
        } else {
            this.regraCriacaoContaId = null;
            this.anoVencimento = null;
            this.criterioUtilizacaoCredito = null;
            this.dataCriacao = null;
            this.descricaoCompleta = null;
            this.descricaoResumida = null;
            this.diaVencimento = null;
            this.mesVencimento = null;
            this.permiteBloqueioCertidao = null;
            this.permiteDareValorMenorSaldo = null;
            this.permiteDuplicidadeLancamento = null;
            this.permiteEmissaoDare = null;
            this.permiteLancamentoRetroativo = null;
            this.permitePrescricao = null;
            this.permiteUtilizarCreditoAuto = null;
            this.qtdDiasVencimento = null;
            this.qtdMesesVencimento = null;
            this.qtdPrescricao = null;
            this.qtdRetroativo = null;
            this.referenLancamentoRetroativo = null;
            this.referenciaPrescricao = null;
            this.situacao = null;
            this.vencimentoEmDiaUtil = null;
            this.regraVencimento = null;
            this.tipoContagemPrescricao = null;
            this.tipoContagemRetroativo = null;
            this.tipoContagemVencimento = null;
            this.tipoDataReferenciaPrescricao = null;
            this.tipoDataReferenciaRetroativo = null;
            this.tipoDataReferenciaVencimento = null;
        }
    }
}
