import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AlertsUtil } from 'src/app/utils/alerts/alerts.util';
import { ConsultaContaCorrenteService } from '../../../services/consulta-conta-corrente.service';
import { MaskCpfOuCnpj } from 'src/app/utils/masklCpfCnpjList/maskCpfCnpj.util';
import { Pagination } from 'src/app/shared/pagination/pagination';
@Component({
  selector: 'ccp-detalhamento-conta',
  templateUrl: './detalhamento-conta.component.html',
  styleUrls: ['./detalhamento-conta.component.scss']
})
export class DetalhamentoContaComponent implements OnInit {

  @Output() outEtapaSelecaoConta = new EventEmitter();

  title: string = 'Conta';
  saldosList: any[] = [];
  blockedDocument: boolean;
  detalheConta: any;
  detalheContribuinte: any;
  saldoNegativo = true;
  valorTabPanel: any;
  contribuinte: any;
  pagination: Pagination<any>;
  detalheContaItcd: any;
  dataAtual: Date = new Date();
  detalheContaPosicaoSaldo: any[] = [];
  detalheContaAnexo: any;
  textoDocDownload: string;
  downloadAnexo: boolean;

  constructor(
    private alertsUtil: AlertsUtil,
    private maskCpfOuCnpj: MaskCpfOuCnpj,
    private consultaContaCorrenteService: ConsultaContaCorrenteService
  ) { }

  ngOnInit(): void {
    this.detalheConta = JSON.parse(localStorage.getItem('conta'));
    this.contribuinte = JSON.parse(localStorage.getItem('contribuinte'));
    this.getDetalheContaView();
    this.getDetalheContaDetalheDocumento();
    this.exibeDetalheIpvaItcdIcms();
  }

  exibeDetalheIpvaItcdIcms() {
    this.valorTabPanel = JSON.parse(localStorage.getItem('valueTabPanel'));

    switch (this.valorTabPanel) {
      case 'IPVA':
        this.getDetalheIpva();
        break;
      case 'ITCD':
        this.getDetalheItcd();
        break;
      default:
        break;
    }
  }

  getDetalheIpva() {
    const objparams =  {
      renavam: this.detalheConta?.codigoCadastro
    }
    this.blockedDocument = true;
        this.consultaContaCorrenteService.getDadosIpvaContribuinte(objparams).subscribe(
            (response: any) => {
                this.blockedDocument = false;
                this.detalheContribuinte = response?.content[0];
            },
            (error) => {
                this.blockedDocument = false;
                this.alertsUtil.handleError(error);
            }
        );

  }

  getDetalheItcd(){
    // numeroDocumento - 610002262
    // http://localhost:8080/detalheConta-corrente-api/contribuintes-itcd/search?page=0&size=10&cpfCnpj=24966096391&codTipo=3&tdiNumDecl=610002262
    this.pagination = new Pagination<any>();
    const objparams =  {
      cpfCnpj: this.detalheConta.cpfCnpj2,
      codTipo: 3,
      tdiNumDecl: this.detalheConta.numeroDocumento
    }

    this.blockedDocument = true;
        this.consultaContaCorrenteService.getDetalheItcd(objparams, this.pagination).subscribe(
            (response: any) => {
                this.blockedDocument = false;
                this.detalheContaItcd = response.content[0];
            },
            (error) => {
                this.blockedDocument = false;
                this.alertsUtil.handleError(error);
            }
        );
  }

  getDetalheContaDetalheDocumento() {
    this.blockedDocument = true;
    this.consultaContaCorrenteService.getDetalheConta(this.detalheConta?.contaCorrenteId).subscribe(
      (response: any) => {
        this.blockedDocument = false;
        this.detalheContaAnexo = response;
        this.detalheContaAnexo.contaCorrenteFinanceiros = response.contaCorrenteFinanceiros.sort((a,b) => a.naturezaItemCalculo?.ordemExibicao - b.naturezaItemCalculo?.ordemExibicao);
        this.textoDocDownload = `${this.detalheContaAnexo?.documentos?.nomeArquivo ? this.detalheContaAnexo?.documentos?.nomeArquivo : 'arquivo.pdf'}`
        if(this.detalheContaAnexo?.documentos?.formato === 'PDF' && this.detalheContaAnexo?.documentos?.documento){
          this.downloadAnexo = true
        }
      },
      (error) => {
        this.blockedDocument = false;
        this.alertsUtil.handleError(error);
      }
    )
  }

  irSelecionarConta(){
    this.outEtapaSelecaoConta.emit(1);
  }

  converteValue(ev){
    let ano =  ev?.toString().substring(0, 4);
    let mes = ev?.toString().substring(4, 6);
    return `${mes}/${ano}`
  }

  downloadFile(){
    if(this.downloadAnexo) {
      /**Gera PDF */
      let nomeArquivo: string = this.detalheContaAnexo?.documentos?.nomeArquivo !== null ? `${this.detalheContaAnexo?.documentos?.nomeArquivo}` : `arquivo.pdf`
      var b64 = `${this.detalheContaAnexo?.documentos?.documento}`
      const source = `${b64}`;
      const link = document.createElement("a");
      link.href = source;
      link.download = nomeArquivo
      link.click();

    } else if(this.detalheContaAnexo?.documentos?.formato === 'JSON'){
      /**Gera JSON */
      let nomeArquivo = 'file'
      let json: any = `${this.detalheContaAnexo?.documentos?.documento}`
      const fileJson = JSON.stringify(json)
      var a = document.createElement("a")
      a.href = URL.createObjectURL(
          new Blob([fileJson], {type:"application/json"})
      )
      a.download = `${nomeArquivo}.json`
      a.click()
    }
  }

  maior(ev){
    if(ev < 0){
      return true;
    }
    return false;
  }

  insereMascara(cpfOuCnpj) {
    return this.maskCpfOuCnpj.inserirMascara(cpfOuCnpj);
  }

  converteMesAno(data){
    let ano =  data?.toString().substring(0, 4);
    let mes = data?.toString().substring(4, 6);

    return `${mes}/${ano}`
  }

  getDetalheContaView() {
    this.detalheContaPosicaoSaldo.push(this.detalheConta);
  }

}
