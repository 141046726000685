import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss']
})
export class InputNumberComponent implements OnInit {

  @Input('for-name') forName!: string;
  @Input() name!: string;
  @Input() form!: FormGroup;
  @Input('use-grouping') useGrouping: boolean = false;
  @Input('max-fraction-digits') maxFractionDigits: any = null;
  @Input('min-fraction-digits') minFractionDigits: any = null;
  @Input() money: boolean = false;
  @Input() placeholder: boolean = false;
  @Input() max: number = 100000000;
  @Input() min: number = 0;

  @Output('blur') blur: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  onBlur(event: any): void {
    this.blur.emit(event);
  }
}
