import {ConfigConta} from './config-conta';
import {UnidadeOrganizacional} from './unidade-organizacional';

export class ConfigContaUnidOrganiza {
    configContaUnidOrganizaId: number;
    dataFimVigencia: string;
    dataInicioVigencia: string;
    situacao: string = "ATIVA";
    configConta: ConfigConta;
    unidadeOrganizacional: UnidadeOrganizacional;
    constructor(init?: Partial<ConfigContaUnidOrganiza>) {
        if (init) {
            Object.assign(this, init);
        } else {
            this.configContaUnidOrganizaId = null;
            this.dataFimVigencia = null;
            this.dataInicioVigencia = null;
            this.situacao = "ATIVA";
            this.configConta = null;
            this.unidadeOrganizacional = null;
        }
    }
}
