import {Component, OnInit, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {Router} from '@angular/router';
import {RegraCriacaoConta} from '../../../models/regra-criacao-conta';
import {RegraCriacaoContaSearchResponse} from '../../../responses/regra-criacao-conta-search-response';
import {RegraCriacaoSearchRequest} from '../../../request/RegraCriacaoSearchRequest';
import {Pagination} from '../../../shared/pagination/pagination';
import {RegraCriacaoListServiceService} from './regra-criacao-list-service.service';
import swal from 'sweetalert2';
import { Message } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { RemoveParamsUtil } from 'src/app/utils/paramsRemove/paramsRemove.util';
@Component({
    selector: "app-regra-criacao-list",
    templateUrl: "./regra-criacao-list.component.html",
    styleUrls: ["./regra-criacao-list.component.scss"],
    providers: [RegraCriacaoListServiceService],
})
export class RegraCriacaoListComponent implements OnInit {
    regrasCriacao: RegraCriacaoContaSearchResponse[];
    regraCriacaoSearchRequest: RegraCriacaoSearchRequest;
    pagination: Pagination<any>;
    situacaoes: any[];
    msgs1: Message[];
    @ViewChild('paginator', { static: true }) paginator: Paginator;

    constructor(
        private router: Router,
        private removeParametro: RemoveParamsUtil,
        public regraCriacaoListService: RegraCriacaoListServiceService
    ) {}

    ngOnInit(): void {
        this.regraCriacaoSearchRequest = new RegraCriacaoSearchRequest();
        this.pagination = new Pagination<any>();
        this.pesquisar();
        this.regraCriacaoListService.getSituacoes().subscribe((response) => {
            this.situacaoes = response;
        });
        // this.regraCriacaoSearchRequest.situacao = "ATIVA";
    }
    pesquisar(resetPage?) {     
        this.regraCriacaoSearchRequest.situacao === null ? this.regraCriacaoSearchRequest.situacao = '' 
        : this.regraCriacaoSearchRequest.situacao;
        const data = JSON.parse(JSON.stringify(this.regraCriacaoSearchRequest));
        data.dataCriacao = data.dataCriacao === "" ||
         data.dataCriacao === null ? 
         "" : moment(data.dataCriacao).format("YYYY-MM-DD");

        this.removeParametro.removeParametroNaoUtilizado(data);
        
        this.regraCriacaoListService
            .pesquisarRegrasCriacao(data, this.pagination)
            .subscribe((response) => {
                this.pagination = response;
                this.regrasCriacao = response.content;
                this.regraCriacaoListService.blockedDocument = false;
            });
    }
    clear() {
        this.regraCriacaoSearchRequest = new RegraCriacaoSearchRequest();
            this.pagination.pageable.pageNumber = 0;
            setTimeout(() => {
                this.paginator.changePage(0);
            }, 600);
    }
    novaRegra() {
        this.router
            .navigate(["/regra-criacao/regra-criacao-view"])
            .then(() => {});
    }
    convertDateBr(data) {
        return data == null ? "" : moment(data).format("DD/MM/YYYY");
    }
    onEditar(regra: RegraCriacaoConta) {
        this.router
            .navigate(["/regra-criacao/regra-criacao-view"], {
                state: { regraCriacaoConta: regra },
            })
            .then(() => {});
    }
    onPageChange(e) {
        this.pagination.pageable.pageNumber = e.page;
        this.pesquisar();
    }
    excluir(modelo) {
        swal.fire({
            title: "Confirmação",
            text: `Deseja excluir o modelo ${modelo.regraCriacaoContaId} - ${modelo.descricaoResumida} ?`,
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
        }).then(
            (result) => {
                if (result.value) {
                    this.regraCriacaoListService
                        .deleteModelo(modelo.regraCriacaoContaId)
                        .subscribe((resp) => {
                            swal.fire('Sucesso', 'Excluido com sucesso', 'success').then( () => {
                                this.pesquisar();
                            });
                        });
                }
            },
            (error) => {
                if (error.status === 500) {
                    swal.fire(
                        error.statusText,
                        "Ocorreu um erro interno",
                        "error"
                    ).then((r) => {});
                } else if (error.status === 412) {
                    swal.fire(
                        "Erro de Validação",
                        error.error.message,
                        "error"
                    ).then((r) => {});
                } else  {
                    const errors = error.error.errors;
                    this.msgs1 = [];
                    errors.forEach((e: { message: any }) => {
                        this.msgs1.push({
                            severity: "error",
                            summary: "Erro",
                            detail: e.message,
                        });
                    });
                }
            }
        );
    }
}
