import { Directive, ElementRef, Input, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { TiposParamsConfigEventos } from "./../models/tipos-params-config-evento";
import { EventoGerenciarConfigService } from "./../services/evento-gerenciar-config.service";

@Directive({
    selector: "[paramEventoVisivel]",
})
export class ParamEventoVisivelDirective implements OnDestroy, OnInit {
    @Input("parametro") parametro:
        | TiposParamsConfigEventos[]
        | TiposParamsConfigEventos;
    private subs = new Subscription();

    constructor(
        private elementRef: ElementRef,
        private eventoGerenciarConfigService: EventoGerenciarConfigService
    ) {}

    ngOnInit(): void {
        this.elementRef.nativeElement.style.display = "block";
        this.eventoGerenciarConfigService
            .onPermissoesCarregadas()
            .subscribe((resp) => {
                const fistVisivel = resp.sort((a, b) => a.visivel ? -1 : 2)
                const config = this.temElementoComum(fistVisivel, this.parametro);
                if (config && !config.visivel) {
                    this.elementRef.nativeElement.style.display = "none";
                } else {
                    this.elementRef.nativeElement.style.display = "block";
                }
            });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    temElementoComum(arr1, arr2) {
        return arr1.find((item) => {
            if (arr2 instanceof Array) {
                return arr2.includes(item.parametro);
            } else {
                return item.parametro === arr2;
            }
        });
    }
}
