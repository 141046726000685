export class LancamentoPorImpostoSearchRequest {
    dataInicialReferenciaValue: any | null;
    dataFinalReferenciaValue: any | null;
    impostoId: number[];
    transacaoId: number[];

    constructor() {
        this.dataInicialReferenciaValue = null;
        this.dataFinalReferenciaValue = null;
        this.impostoId = [];
        this.transacaoId = [];
    }
}
