import { ConfigConta } from "./config-conta"

export class PerfilVisualizacaoConta {
    configConta?: ConfigContaId
    perfilVisualizacaoContaId: number
    codigoPerfil: string
    situacao: string
    dataInicioVigencia: string
    dataFimVigencia: string

    constructor(init?: Partial<PerfilVisualizacaoConta>) {
        if (init) {
            Object.assign(this, init);
        } else {
            this.configConta = null;
            this.perfilVisualizacaoContaId= null;
            this.codigoPerfil= null;
            this.situacao= 'ATIVA';
            this.dataInicioVigencia= null;
            this.dataFimVigencia= null;
        }
    }
}

export class ConfigContaId {
    configContaId: any

    constructor(init?: Partial<ConfigContaId>) {
        if (init) {
            Object.assign(this, init);
        } else {
            this.configContaId = null;
        }
    }
}
