import * as EventoPagamentoTransacaoFinanceiraModalActions from './evento-pagamento-transacao-financeira-modal-actions';

const eventoPagamentoTransacaoFinanceiraModalInitialstate = {
    displayMaximizable: false
};

// tslint:disable-next-line:max-line-length
export function eventoPagamentoTransacaoFinanceiraModalReducer(state = eventoPagamentoTransacaoFinanceiraModalInitialstate, action: EventoPagamentoTransacaoFinanceiraModalActions.SetDisplayMaximizable) {
    switch (action.type) {
        case EventoPagamentoTransacaoFinanceiraModalActions.SET_DISPLAY_MAXIMIZABLE:
            return {
                ...state,
                displayMaximizable: action.value
            };
        default:
            return state;
    }
}
