import {Injectable} from '@angular/core';
import {ConfigEventoPagamento} from '../../../models/config-evento-pagamento';
import {EventoPagtoRgrImputacaos} from '../../../models/evento-pagamento-regra-imputacoes';

@Injectable({
    providedIn: 'root'
})
export class EventoPagamentoRegraImputacaoModalService {
    displayMaximizable: boolean;
    dateStart: Date;
    eventoPagamento: ConfigEventoPagamento;
    eventoPagtoRgrImputacaoLast: EventoPagtoRgrImputacaos;
    constructor() {
    }
}
