import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { EventosFinanceirosService } from "src/app/core/eventos-financeiros.service";

@Component({
    selector: "app-selecao-evento",
    templateUrl: "./selecao-evento.component.html",
    styleUrls: ["./selecao-evento.component.css"],
})
export class SelecaoEventoComponent implements OnInit {
    items = [
        { label: "Selecionar Categoria" },
        { label: "Transação" },
    ];
    eventos = [{ label: "Selecione uma categoria", value: null }];
    valor = null;

    constructor(
        private eventosService: EventosFinanceirosService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.eventosService.getAllEventos().subscribe((resp) => {
            resp.map((r) => ({
                label: r.descricao,
                value: r.eventosId,
            })).forEach((r) => this.eventos.push(r));
        });
    }

    confirmar(){
        this.router.navigate(['gerenciar-transacao-financeira/evento/',this.valor]);
    }

    voltar() {
        this.router.navigate(['/']);
    }
}
