import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Pagination} from '../../../shared/pagination/pagination';
import {ConfigContaReceitaSearchRequest} from '../../../request/config-conta-receita-search-request';
import {Pageable} from '../../../shared/pagination/pageable';

@Injectable({
    providedIn: 'root',
})
export class ConfigReceitaService {

    constructor(private http: HttpClient) {
    }

    getByCodTipoConta(codTipoConta, codTributoId) {
        let url = `receita?codTributo=${codTipoConta}`;
        if (codTributoId != null) {
            url = `${url}&codTributoId=${codTributoId}`;
        }
        return this.http.get<Array<any>>(
            `${environment.baseUrl}/${url}`
        );
    }

    configReceitaService(
        configContaReceitaSearchRequest: ConfigContaReceitaSearchRequest,
        pagination: Pagination<any>
    ) {
        return this.http.get<Pageable<any>>(
            `${environment.baseUrl}/config-conta-receita/search`, {
                params: {
                    ...configContaReceitaSearchRequest,
                    page: pagination.pageable.pageNumber,
                    size: pagination.size
                }
            }
        );
    }

    postContaReceita(model) {
        return this.http.post<any>(
            `${environment.baseUrl}/config-conta-receita`, model);
    }


    ativaInativa(configContaId: any) {
        return this.http.put<any>(
            `${environment.baseUrl}/config-conta-receita/ativa-inativa/${configContaId}`, {});
    }

    getSubcodigosByCodReceita(codReceita: any) {
        return this.http.get<any>(
            `${environment.baseUrl}/receita/subcodigo/list-all-nopage/${codReceita}`, {});
            // `${environment.baseUrl}/receita/subcodigo/list-all-nopage/${105}`, {});
    }

    remove(configContaId: any) {
        return this.http.delete<any>(
            `${environment.baseUrl}/config-conta-receita/${configContaId}`, {});
    }
}
