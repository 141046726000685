import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ConfigComposicaoConta } from 'src/app/models/config-composicao-conta';
import { Pageable } from 'src/app/shared/pagination/pageable';
import { Pagination } from 'src/app/shared/pagination/pagination';
import { ConfigContaItemCalculoSearchRequest } from 'src/app/request/ConfigContaItemCalculoSearchRequest';

@Injectable({
    providedIn: 'root',
})
export class ConfigComposicaoContaService {
    constructor(private http: HttpClient) { }

    getItemCalculo (
        configContaItemCalculoSearchRequest: ConfigContaItemCalculoSearchRequest,
        pagination: Pagination<any>): Observable<Pageable<any>> {
        return this.http.get<Pageable<any>>(
            `${environment.baseUrl}/config-conta-item-calculo/search`, {
            params: {
                ...configContaItemCalculoSearchRequest,
                page: pagination.pageable.pageNumber,
                size: pagination.size
            }
        }
        );
    }

    getNatureza (): Observable<Array<any>> {
        return this.http.get<Array<any>>(
            `${environment.baseUrl}/natureza-item-calculo`
        );
    }

    getSituacoes (): Observable<Array<any>> {
        return this.http.get<Array<any>>(
            `${environment.baseUrl}/enums/config-conta-item-calculo/situacao`
        );
    }

    postItemCalculo (itemCalculo: ConfigComposicaoConta): Observable<any> {
        return this.http.post<ConfigComposicaoConta>(
            `${environment.baseUrl}/config-conta-item-calculo`, itemCalculo
        );
    }

    ativaInativaItemCalculo (id: string): Observable<void> {
        return this.http.put<void>(`${environment.baseUrl}/config-conta-item-calculo/${id}/ativa-inativa`, {});
    }

    deleteItemCalculo(id: string): Observable<void> {
        return this.http.delete<void>(
            `${environment.baseUrl}/config-conta-item-calculo/${id}`);
    }

    getItemComposicaoCalculo (): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${environment.baseUrl}/item-composicao`);
    }

    getItemComposicaoByOrigemInformacaoId (origemInformacaoId: number): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${environment.baseUrl}/item-composicao/get-by-origem-informacao/${origemInformacaoId}`);
    }


    postValidaSQL (regraCaclculo: any): Observable<any> {
        return this.http.post<any>(`${environment.baseUrl}/config-conta-item-calculo/sql`, regraCaclculo);
    }
}
