<p-card>
    <div class="grid grid-nogutter justify-content-end margin-bt">
        <button
            pButton
            label="Voltar"
            icon="pi pi-angle-left"
            class="p-button-outlined p-mr-2"
            (click)="irSelecaoContribuinte()"
        ></button>
    </div>
    <ng-template pTemplate="title">
        {{ title }}
    </ng-template>

    <p-tabView (onChange)="handleChange($event)"  [(activeIndex)]="indexTabAtiva">
        <p class="p-mt-3 font-subtitulo">
            <small
                >Contribuinte:
                {{ insereMascara(valueContribuinte?.baseCnpj) }} -
                {{ valueContribuinte?.nome }}</small
            >
        </p>

        <div class="p-fluid p-formgrid p-grid">
            <div *ngIf="exibirFiltroRenavan()" class="p-field p-col-12 p-md-2">
                <label>Renavam</label>
                <p-dropdown
                    [options]="renavamList"
                    optionLabel="renavam"
                    optionValue="idRenavam"
                    showClear="true"
                    placeholder="Selecione"
                    [autoDisplayFirst]="true"
                    [(ngModel)]="renavamValue"
                    (onChange)="ativarInativarCampos()"
                    [disabled]="isRenavamDisabled"
                ></p-dropdown>
            </div>
            <div *ngIf="exibirFiltroIe()" class="p-field p-col-12 p-md-2">
                <label>Inscrição Estadual</label>
                <p-dropdown
                    [options]="contribuinteRelacaoProfissionalList"
                    optionLabel="label"
                    optionValue="value"
                    showClear="true"
                    placeholder="Selecione"
                    [autoDisplayFirst]="true"
                    [(ngModel)]="contribuinteValue"
                    (onChange)="ativarInativarCampos()"
                    [disabled]="isContribuinteDisabled"
                ></p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label>Conta</label>
                <p-multiSelect
                    [options]="contaList"
                    optionLabel="nomeCompleto"
                    optionValue="configContaId"
                    placeholder="Selecione"
                    display="chip"
                    resetFilterOnHide="true"
                    showClear="true"
                    [(ngModel)]="contaValue"
                    (onFilter)="limiteCaractere($event)"
                ></p-multiSelect>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label>Estado da Conta</label>
                <p-multiSelect
                    [options]="estadoContaList"
                    optionLabel="descricao"
                    optionValue="estadoContaId"
                    placeholder="Selecione"
                    display="chip"
                    resetFilterOnHide="true"
                    showClear="true"
                    [(ngModel)]="estadoValue"
                    (onFilter)="limiteCaractere($event)"
                ></p-multiSelect>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label>Situação do Crédito</label>
                <p-multiSelect
                    [options]="situacaoCreditoList"
                    optionLabel="descricao"
                    optionValue="situacaoCreditoId"
                    placeholder="Selecione"
                    display="chip"
                    resetFilterOnHide="true"
                    showClear="true"
                    [(ngModel)]="situacaoCreditoValue"
                    (onFilter)="limiteCaractere($event)"
                ></p-multiSelect>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label>Saldo da Conta</label>
                <p-multiSelect
                    [options]="saldoList"
                    optionLabel="descricao"
                    optionValue="saldoContaId"
                    placeholder="Selecione"
                    showClear="true"
                    display="chip"
                    resetFilterOnHide="true"
                    [(ngModel)]="saldoValue"
                    (onFilter)="limiteCaractere($event)"
                ></p-multiSelect>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label>Situação de Cobrança</label>
                <p-multiSelect
                    [options]="situaCobrancaList"
                    optionLabel="descricao"
                    optionValue="situacaoCobrancaId"
                    placeholder="Selecione"
                    display="chip"
                    resetFilterOnHide="true"
                    showClear="true"
                    [(ngModel)]="situacaoCobrancaValue"
                    (onFilter)="limiteCaractere($event)"
                ></p-multiSelect>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label>Tipo de Documento</label>
                <p-dropdown
                    [options]="tipoDocumentoList"
                    optionLabel="descricao"
                    optionValue="tipoNumeroDocumentoId"
                    placeholder="Selecione"
                    showClear="true"
                    resetFilterOnHide="true"
                    [autoDisplayFirst]="true"
                    [(ngModel)]="tipoNumeroDocumentoValue"
                    (onChange)="tipoDocumentoChange()"
                ></p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label>Número de Documento </label>
                <input
                    [disabled]="!tipoNumeroDocumentoValue"
                    maxlength="20"
                    placeholder="Informe o número"
                    showClear="true"
                    [(ngModel)]="numeroDocumentoValue"
                    pInputText
                />
            </div>
            <ng-container *ngIf="abaSelecionada === 'PARCELAMENTO'">
                <div class="p-field p-col-12 p-md-2">
                    <label style="white-space: nowrap">Tributo</label>
                    <p-dropdown
                        optionLabel="sigla"
                        showClear="true"
                        placeholder="Selecione"
                        [autoDisplayFirst]="false"
                        [options]="tributoModels"
                        [(ngModel)]="tipoTributo"
                    ></p-dropdown>
                </div>
            </ng-container>
            <div class="p-field p-col-12 p-md-2">
                <label title="dataInicio" style="white-space: nowrap"
                    >Período de Referência Inicial *</label
                >
                <p-calendar
                    view="month"
                    dateFormat="mm/yy"
                    [showIcon]="true"
                    placeholder="Informe periodo inicial"
                    [maxDate]="dataFinalReferenciaValue"
                    [(ngModel)]="dataInicialReferenciaValue"
                ></p-calendar>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label style="white-space: nowrap"
                    >Período de Referência Final *</label
                >
                <p-calendar
                    view="month"
                    appendTo="body"
                    dateFormat="mm/yy"
                    [showIcon]="true"
                    placeholder="Informe o periodo final"
                    [disabled]="!dataInicialReferenciaValue"
                    [minDate]="dataInicialReferenciaValue"
                    [(ngModel)]="dataFinalReferenciaValue"
                >
                </p-calendar>
            </div>
            <div class="p-field p-col-12 p-md-2 top-btn-line">
                <button
                    pButton
                    type="button"
                    icon="pi pi-search"
                    label="Pesquisar"
                    class="p-mr-1 p-text-left"
                    [disabled]="
                        !dataInicialReferenciaValue || !dataFinalReferenciaValue
                    "
                    (click)="filtroConta()"
                ></button>
            </div>
            <div class="p-field p-col-12 p-md-2 top-btn-line">
                <button
                    pButton
                    type="button"
                    icon="pi pi-trash"
                    label="Limpar"
                    class="p-mr-1 p-button-danger"
                    (click)="limparFiltros()"
                ></button>
            </div>
        </div>

        <!-- PANEL IPVA -->
        <p-tabPanel header="IPVA" [disabled]="blockedDocument">
            <p-table
                #dt1
                [value]="listContasIpvaItcdIcms"
                [(selection)]="selectedProducts"
                [scrollable]="true"
                [globalFilterFields]="[
                    'configContaId',
                    'tipoCadastroDesc',
                    'codigoCadastro',
                    'nome',
                    'tipoRelacionamentoDesc',
                    'numeroDocumento',
                    'periodoReferencia',
                    'dataVencimento',
                    'estadoConta?.descricao',
                    'saldoTotalAtualizado',
                    'situacaoCredito?.descricao',
                    'situacaoCobranca?.descricao'
                ]"
                dataKey="contaCorrenteId"
            >
                <ng-template pTemplate="caption">
                    <div class="p-d-flex">
                        <span class="p-input-icon-left p-ml-auto">
                            <i class="pi pi-search"></i>
                            <input
                                pInputText
                                type="text"
                                (input)="
                                    dt1.filterGlobal(
                                        $event.target.value,
                                        'contains'
                                    )
                                "
                                placeholder="Pesquisar na página"
                            />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th class="td-icon">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th class="td-icon"></th>
                        <th pSortableColumn="configContaId">
                            Conta
                            <p-sortIcon field="configContaId"></p-sortIcon>
                        </th>
                        <!-- <th pSortableColumn="tipoCadastroDesc">
                            Tipo de Cadastro
                            <p-sortIcon field="tipoCadastroDesc"></p-sortIcon>
                        </th>
                        <th pSortableColumn="codigoCadastro">
                            Código Cadastro
                            <p-sortIcon field="codigoCadastro"></p-sortIcon>
                        </th>
                        <th pSortableColumn="nome">
                            Contribuinte <p-sortIcon field="nome"></p-sortIcon>
                        </th> -->
                        <!-- <th pSortableColumn="tipoRelacionamentoDesc">
                            Tipo de Relacionamento
                            <p-sortIcon
                                field="tipoRelacionamentoDesc"
                            ></p-sortIcon>
                        </th> -->
                        <th pSortableColumn="tipoDocumento">
                            Tipo de Documento
                            <p-sortIcon field="numeroDocumento"></p-sortIcon>
                        </th>
                        <th pSortableColumn="numeroDocumento">
                            Número do Documento
                            <p-sortIcon field="numeroDocumento"></p-sortIcon>
                        </th>
                        <th pSortableColumn="periodoReferencia">
                            Período de Referência
                            <p-sortIcon field="periodoReferencia"></p-sortIcon>
                        </th>
                        <th pSortableColumn="parcela">
                            Cota/Parcela
                            <p-sortIcon field="parcela"></p-sortIcon>
                        </th>
                        <th pSortableColumn="dataVencimento">
                            Vencimento
                            <p-sortIcon field="dataVencimento"></p-sortIcon>
                        </th>
                        <th pSortableColumn="estadoConta?.descricao">
                            Estado da Conta
                            <p-sortIcon
                                field="estadoConta?.descricao"
                            ></p-sortIcon>
                        </th>
                        <th pSortableColumn="saldoTotalAtualizado">
                            Saldo da Conta
                            <p-sortIcon
                                field="saldoTotalAtualizado"
                            ></p-sortIcon>
                        </th>
                        <th pSortableColumn="situacaoCredito?.descricao">
                            Situação do Crédito
                            <p-sortIcon
                                field="situacaoCredito?.descricao"
                            ></p-sortIcon>
                        </th>
                        <th pSortableColumn="situacaoCobranca?.descricao">
                            Situação da Cobrança
                            <p-sortIcon
                                field="situacaoCobranca?.descricao"
                            ></p-sortIcon>
                        </th>
                        <th>Ações</th>
                    </tr>
                </ng-template>
                <ng-template
                    pTemplate="body"
                    let-conta
                    let-expanded="expanded"
                    let-rowIndex="rowIndex"
                >
                    <tr class="selecao">
                        <td class="td-icon">
                            <p-tableCheckbox [value]="conta"></p-tableCheckbox>
                        </td>
                        <td class="td-icon">
                            <button
                                type="button"
                                pButton
                                pRipple
                                [pRowToggler]="conta"
                                class="p-button-text p-button-rounded p-button-plain"
                                [icon]="
                                    expanded
                                        ? 'pi pi-chevron-down'
                                        : 'pi pi-chevron-right'
                                "
                                (click)="expandeEnvento(rowIndex)"
                            ></button>
                        </td>
                        <td>
                            {{ conta?.configContaId }} -
                            {{ conta?.configContaNomeResumido }}
                        </td>
                        <!-- <td>{{ conta.tipoCadastroDesc }}</td>
                        <td>{{ conta.codigoCadastro }}</td>
                        <td>
                            {{ insereMascara(conta?.cpfCnpj2) }} -
                            {{ conta.nome }}
                        </td> -->
                        <!-- <td>{{ conta?.tipoRelacionamentoDesc }}</td> -->
                        <td>{{ conta.tipoNumeroDocumentoDesc }}</td>
                        <td>{{ conta.numeroDocumento }}</td>
                        <td>{{ converteMesAno(conta.periodoReferencia) }}</td>
                        <td>{{ conta.parcela }}</td>
                        <td>
                            {{ conta.dataVencimento | date : "dd/MM/yyyy" }}
                        </td>
                        <td>{{ conta.estadoContaDesc }}</td>
                        <td>
                            {{ conta.saldoTotalAtualizado | currency : "BRL" }}
                        </td>
                        <td>{{ conta.situacaoCreditoDesc }}</td>
                        <td>{{ conta.situacaoCobrancaDesc }}</td>
                        <td class="selecao">
                            <i
                                (click)="openModal(valueContribuinte, conta)"
                                pTooltip="Gerar Extrato"
                                tooltipPosition="top"
                                class="fa-solid fa-file-pdf"
                                style="font-size: large; margin-right: 8px"
                            ></i>
                            <i
                                (click)="irDetalhamentoConta(conta)"
                                pTooltip="Detalhe Conta"
                                tooltipPosition="top"
                                class="fa fa-list"
                                aria-hidden="true"
                                style="font-size: large; margin-right: 8px"
                            ></i>
                            <i
                                *ngIf="conta?.dareBloqueado == 'NAO'"
                                (click)="getContas(conta)"
                                pTooltip="Emitir DARE"
                                tooltipPosition="top"
                                class="fa-regular fa-file selecao"
                                aria-hidden="true"
                                style="font-size: large"
                            ></i>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="rowexpansion" class="p-text-center">
                    <ccp-detalhe-calculo-juros
                        [conta]="listContasIpvaItcdIcms[rowIndexConta]"
                    ></ccp-detalhe-calculo-juros>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td class="p-text-center">
                            <div style="margin-left: 46%">
                                Nenhum registro encontrado
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <p-paginator
                [rows]="20"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="{first} - {last} de {totalRecords}"
                [first]="pagination?.pageable?.offset"
                [rowsPerPageOptions]="[20, 50, 100]"
                [totalRecords]="pagination.totalElements"
                (onPageChange)="onPageChangeIpva($event)"
            ></p-paginator>
        </p-tabPanel>
        <!-- FIM PANEL IPVA -->

        <!-- PANEL ITCD -->
        <p-tabPanel header="ITCD" [disabled]="blockedDocument">
            <p-table
                #dt2
                [value]="listContasIpvaItcdIcms"
                [(selection)]="selectedProducts"
                [scrollable]="true"
                [globalFilterFields]="[
                    'configContaId',
                    'numeroDocumento',
                    'nomeContribuinte',
                    'periodoReferencia',
                    'dataVencimento',
                    'estadoContaDescricao',
                    'saldoTotalAtualizado',
                    'situacaoCreditoDescricao'
                ]"
                dataKey="contaCorrenteId"
            >
                <ng-template pTemplate="caption">
                    <div class="p-d-flex">
                        <span class="p-input-icon-left p-ml-auto">
                            <i class="pi pi-search"></i>
                            <input
                                pInputText
                                type="text"
                                (input)="
                                    dt2.filterGlobal(
                                        $event.target.value,
                                        'contains'
                                    )
                                "
                                placeholder="Pesquisar na página"
                            />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th class="td-icon">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th class="td-icon"></th>
                        <th pSortableColumn="configContaId">
                            Conta
                            <p-sortIcon field="configContaId"></p-sortIcon>
                        </th>
                        <!-- <th pSortableColumn="tipoCadastroDesc">
                            Tipo de Cadastro
                            <p-sortIcon field="tipoCadastroDesc"></p-sortIcon>
                        </th>
                        <th pSortableColumn="codigoCadastro">
                            Código Cadastro
                            <p-sortIcon field="codigoCadastro"></p-sortIcon>
                        </th>
                        <th pSortableColumn="nomeContribuinte">
                            Contribuinte
                            <p-sortIcon field="nomeContribuinte"></p-sortIcon>
                        </th> -->
                        <!-- <th pSortableColumn="">
                            Tipo de Relacionamento
                            <p-sortIcon field=""></p-sortIcon>
                        </th> -->
                        <th pSortableColumn="tipoDocumento">
                            Tipo de Documento
                            <p-sortIcon field="numeroDocumento"></p-sortIcon>
                        </th>
                        <th pSortableColumn="numeroDocumento">
                            Número do Documento
                            <p-sortIcon field="numeroDocumento"></p-sortIcon>
                        </th>
                        <th pSortableColumn="periodoReferencia">
                            Período de Referência
                            <p-sortIcon field="periodoReferencia"></p-sortIcon>
                        </th>
                        <th pSortableColumn="dataVencimento">
                            Vencimento
                            <p-sortIcon field="dataVencimento"></p-sortIcon>
                        </th>
                        <th pSortableColumn="estadoContaDescricao">
                            Estado da Conta
                            <p-sortIcon
                                field="estadoContaDescricao"
                            ></p-sortIcon>
                        </th>
                        <th pSortableColumn="saldoTotalAtualizado">
                            Saldo da Conta
                            <p-sortIcon
                                field="saldoTotalAtualizado"
                            ></p-sortIcon>
                        </th>
                        <th pSortableColumn="situacaoCreditoDescricao">
                            Situação do Crédito
                            <p-sortIcon
                                field="situacaoCreditoDescricao"
                            ></p-sortIcon>
                        </th>
                        <th pSortableColumn="situacaoCobrancaDescricao">
                            Situação da Cobrança
                            <p-sortIcon
                                field="situacaoCobrancaDescricao"
                            ></p-sortIcon>
                        </th>
                        <th>Ações</th>
                    </tr>
                </ng-template>
                <ng-template
                    pTemplate="body"
                    let-contaItcd
                    let-expanded="expanded"
                    let-rowIndex="rowIndex"
                >
                    <tr class="selecao">
                        <td class="td-icon">
                            <p-tableCheckbox
                                [value]="contaItcd"
                            ></p-tableCheckbox>
                        </td>
                        <td class="td-icon">
                            <button
                                type="button"
                                pButton
                                pRipple
                                [pRowToggler]="contaItcd"
                                class="p-button-text p-button-rounded p-button-plain"
                                [icon]="
                                    expanded
                                        ? 'pi pi-chevron-down'
                                        : 'pi pi-chevron-right'
                                "
                                (click)="expandeEnvento(rowIndex)"
                            ></button>
                        </td>
                        <td>
                            {{ contaItcd.configContaId }} -
                            {{ contaItcd.configContaNomeResumido }}
                        </td>
                        <!-- <td>{{ contaItcd.tipoCadastroDesc }}</td>
                        <td>{{ contaItcd.codigoCadastro }}</td>
                        <td>
                            {{ insereMascara(contaItcd?.cpfCnpj2) }} -
                            {{ contaItcd.nome }}
                        </td> -->
                        <!-- <td>{{ contaItcd.tipoRelacionamentoDesc }}</td> -->
                        <td>{{ contaItcd.tipoNumeroDocumentoDesc }}</td>
                        <td>{{ contaItcd.numeroDocumento }}</td>
                        <td>
                            {{ converteMesAno(contaItcd.periodoReferencia) }}
                        </td>
                        <td>
                            {{ contaItcd.dataVencimento | date : "dd/MM/yyyy" }}
                        </td>
                        <td>{{ contaItcd.estadoContaDesc }}</td>
                        <td>
                            {{
                                contaItcd.saldoTotalAtualizado
                                    | currency : "BRL"
                            }}
                        </td>
                        <td>{{ contaItcd.situacaoCreditoDesc }}</td>
                        <td>{{ contaItcd.situacaoCobrancaDesc }}</td>
                        <td class="selecao">
                            <i
                                (click)="
                                    openModal(valueContribuinte, contaItcd)
                                "
                                pTooltip="Gerar Extrato"
                                tooltipPosition="top"
                                class="fa-solid fa-file-pdf"
                                style="font-size: large; margin-right: 8px"
                            ></i>
                            <i
                                (click)="irDetalhamentoConta(contaItcd)"
                                pTooltip="Detalhe Conta"
                                tooltipPosition="top"
                                class="fa fa-list"
                                aria-hidden="true"
                                style="font-size: large; margin-right: 8px"
                            ></i>
                            <i
                                *ngIf="contaItcd?.dareBloqueado == 'NAO'"
                                (click)="getContas(contaItcd)"
                                pTooltip="Emitir DARE"
                                tooltipPosition="top"
                                class="fa-regular fa-file selecao p-mr-2"
                                aria-hidden="true"
                                style="font-size: large"
                            ></i>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="rowexpansion" class="p-text-center">
                    <ccp-detalhe-calculo-juros
                        [conta]="listContasIpvaItcdIcms[rowIndexConta]"
                    ></ccp-detalhe-calculo-juros>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td class="p-text-center">
                            <div style="margin-left: 46%">
                                Nenhum registro encontrado
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <p-paginator
                [rows]="20"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="{first} - {last} de {totalRecords}"
                [first]="pagination?.pageable?.offset"
                [rowsPerPageOptions]="[20, 50, 100]"
                [totalRecords]="pagination.totalElements"
                (onPageChange)="onPageChange($event)"
            ></p-paginator>
        </p-tabPanel>
        <!-- FIM PANEL ITCD -->

        <!-- PANEL ICMS -->
        <p-tabPanel header="ICMS" [disabled]="blockedDocument">
            <p-table
                #dt3
                [value]="listContasIpvaItcdIcms"
                [(selection)]="selectedProducts"
                [scrollable]="true"
                [globalFilterFields]="[
                    'configContaId',
                    'numeroDocumento',
                    'nomeContribuinte',
                    'periodoReferencia',
                    'dataVencimento',
                    'estadoContaDescricao',
                    'saldoTotalAtualizado',
                    'situacaoCreditoDescricao',
                    'situacaoCobrancaDescricao'
                ]"
                dataKey="contaCorrenteId"
            >
                <ng-template pTemplate="caption">
                    <div class="p-d-flex">
                        <span class="p-input-icon-left p-ml-auto">
                            <i class="pi pi-search"></i>
                            <input
                                pInputText
                                type="text"
                                (input)="
                                    dt3.filterGlobal(
                                        $event.target.value,
                                        'contains'
                                    )
                                "
                                placeholder="Pesquisar na página"
                            />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 4rem">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th style="width: 5rem"></th>
                        <th pSortableColumn="configContaId">
                            Conta
                            <p-sortIcon field="configContaId"></p-sortIcon>
                        </th>
                        <!-- <th pSortableColumn="tipoCadastroDesc">
                            Tipo de Cadastro
                            <p-sortIcon field="tipoCadastroDesc"></p-sortIcon>
                        </th> -->
                        <!-- <th pSortableColumn="codigoCadastro">
                            Código Cadastro
                            <p-sortIcon field="codigoCadastro"></p-sortIcon>
                        </th> -->
                        <!-- <th pSortableColumn="nomeContribuinte">
                            Contribuinte
                            <p-sortIcon field="nomeContribuinte"></p-sortIcon>
                        </th> -->
                        <th pSortableColumn="tipoDocumento">
                            Tipo de Documento
                            <p-sortIcon field="numeroDocumento"></p-sortIcon>
                        </th>
                        <th pSortableColumn="numeroDocumento">
                            Número do Documento
                            <p-sortIcon field="numeroDocumento"></p-sortIcon>
                        </th>
                        <th pSortableColumn="">
                            Período de Referência
                            <p-sortIcon field=""></p-sortIcon>
                        </th>
                        <th pSortableColumn="">
                            Vencimento <p-sortIcon field=""></p-sortIcon>
                        </th>
                        <th pSortableColumn="">
                            Estado da Conta <p-sortIcon field=""></p-sortIcon>
                        </th>
                        <th pSortableColumn="">
                            Saldo da Conta <p-sortIcon field=""></p-sortIcon>
                        </th>
                        <th pSortableColumn="">
                            Situação do Crédito
                            <p-sortIcon field=""></p-sortIcon>
                        </th>
                        <th pSortableColumn="">
                            Situação da Cobrança
                            <p-sortIcon field=""></p-sortIcon>
                        </th>
                        <th (click)="openModal(valueContribuinte, contaIcms)">
                            Ações
                        </th>
                    </tr>
                </ng-template>
                <ng-template
                    pTemplate="body"
                    let-contaIcms
                    let-expanded="expanded"
                    let-rowIndex="rowIndex"
                >
                    <tr>
                        <td>
                            <p-tableCheckbox
                                [value]="contaIcms"
                            ></p-tableCheckbox>
                        </td>
                        <td>
                            <button
                                type="button"
                                pButton
                                pRipple
                                [pRowToggler]="contaIcms"
                                class="p-button-text p-button-rounded p-button-plain"
                                [icon]="
                                    expanded
                                        ? 'pi pi-chevron-down'
                                        : 'pi pi-chevron-right'
                                "
                                (click)="expandeEnvento(rowIndex)"
                            ></button>
                        </td>
                        <td>
                            {{ contaIcms.configContaId }} -
                            {{ contaIcms.configContaNomeResumido }}
                        </td>
                        <!-- <td>{{ contaIcms.tipoCadastroDesc }}</td> -->
                        <!-- <td>{{ contaIcms.codigoCadastro }}</td> -->
                        <!-- <td>
                            {{ insereMascara(contaIcms?.cpfCnpj2) }} -
                            {{ contaIcms.nome }}
                        </td> -->
                        <td>{{ contaIcms.tipoNumeroDocumentoDesc }}</td>
                        <td>{{ contaIcms.numeroDocumento }}</td>
                        <td>
                            {{ converteMesAno(contaIcms.periodoReferencia) }}
                        </td>
                        <td>
                            {{ contaIcms.dataVencimento | date : "dd/MM/yyyy" }}
                        </td>
                        <td>{{ contaIcms.estadoContaDesc }}</td>
                        <td>
                            {{
                                contaIcms.saldoTotalAtualizado
                                    | currency : "BRL"
                            }}
                        </td>
                        <td>{{ contaIcms.situacaoCreditoDesc }}</td>
                        <td>{{ contaIcms.situacaoCobrancaDesc }}</td>
                        <td class="selecao">
                            <i
                                (click)="
                                    openModal(valueContribuinte, contaIcms)
                                "
                                pTooltip="Gerar Extrato"
                                tooltipPosition="top"
                                class="fa-solid fa-file-pdf"
                                style="font-size: large; margin-right: 8px"
                            ></i>
                            <i
                                (click)="irDetalhamentoConta(contaIcms)"
                                pTooltip="Detalhe Conta"
                                tooltipPosition="top"
                                class="fa fa-list"
                                aria-hidden="true"
                                style="font-size: large; margin-right: 8px"
                            ></i>
                            <i
                                *ngIf="contaIcms?.dareBloqueado == 'NAO'"
                                (click)="getContas(contaIcms)"
                                pTooltip="Emitir DARE"
                                tooltipPosition="top"
                                class="fa-regular fa-file selecao"
                                aria-hidden="true"
                                style="font-size: large"
                            ></i>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="rowexpansion" class="p-text-center">
                    <ccp-detalhe-calculo-juros
                        [conta]="listContasIpvaItcdIcms[rowIndexConta]"
                    ></ccp-detalhe-calculo-juros>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td class="p-text-center">
                            <div style="margin-left: 46%">
                                Nenhum registro encontrado
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <p-paginator
                [rows]="20"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="{first} - {last} de {totalRecords}"
                [first]="pagination?.pageable?.offset"
                [rowsPerPageOptions]="[20, 50, 100]"
                [totalRecords]="pagination.totalElements"
                (onPageChange)="onPageChange($event)"
            ></p-paginator>
        </p-tabPanel>
        <!-- FIM PANEL ICMS -->

        <!-- PANEL IMPLANTADO -->
        <p-tabPanel header="PARCELAMENTO" [disabled]="blockedDocument">
            <p-table
                [value]="listContasIpvaItcdIcms"
                [(selection)]="selectedProducts"
                [scrollable]="true"
                [globalFilterFields]="[
                    'configContaId',
                    'numeroDocumento',
                    'nomeContribuinte',
                    'periodoReferencia',
                    'dataVencimento',
                    'estadoContaDescricao',
                    'saldoTotalAtualizado',
                    'situacaoCreditoDescricao'
                ]"
                dataKey="contaCorrenteId"
                #dt4
            >
                <ng-template pTemplate="caption">
                    <div class="p-d-flex">
                        <span class="p-input-icon-left p-ml-auto">
                            <i class="pi pi-search"></i>
                            <input
                                pInputText
                                type="text"
                                (input)="
                                    dt4.filterGlobal(
                                        $event.target.value,
                                        'contains'
                                    )
                                "
                                placeholder="Pesquisar na página"
                            />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th class="td-icon">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th class="td-icon"></th>
                        <th pSortableColumn="configContaId">
                            Conta
                            <p-sortIcon field="configContaId"></p-sortIcon>
                        </th>
                        <!-- <th pSortableColumn="tipoCadastroDesc">
                            Tipo de Cadastro
                            <p-sortIcon field="tipoCadastroDesc"></p-sortIcon>
                        </th>
                        <th pSortableColumn="codigoCadastro">
                            Código Cadastro
                            <p-sortIcon field="codigoCadastro"></p-sortIcon>
                        </th>
                        <th pSortableColumn="nomeContribuinte">
                            Contribuinte
                            <p-sortIcon field="nomeContribuinte"></p-sortIcon>
                        </th> -->
                        <!-- <th pSortableColumn="">
                            Tipo de Relacionamento
                            <p-sortIcon field=""></p-sortIcon>
                        </th> -->
                        <th pSortableColumn="tipoDocumento">
                            Tipo de Documento
                            <p-sortIcon field="numeroDocumento"></p-sortIcon>
                        </th>
                        <th pSortableColumn="numeroDocumento">
                            Número do Documento
                            <p-sortIcon field="numeroDocumento"></p-sortIcon>
                        </th>
                        <th pSortableColumn="periodoReferencia">
                            Período de Referência
                            <p-sortIcon field="periodoReferencia"></p-sortIcon>
                        </th>
                        <th pSortableColumn="dataVencimento">
                            Vencimento
                            <p-sortIcon field="dataVencimento"></p-sortIcon>
                        </th>
                        <th pSortableColumn="estadoContaDescricao">
                            Estado da Conta
                            <p-sortIcon
                                field="estadoContaDescricao"
                            ></p-sortIcon>
                        </th>
                        <th pSortableColumn="saldoTotalAtualizado">
                            Saldo da Conta
                            <p-sortIcon
                                field="saldoTotalAtualizado"
                            ></p-sortIcon>
                        </th>
                        <th pSortableColumn="situacaoCreditoDescricao">
                            Situação do Crédito
                            <p-sortIcon
                                field="situacaoCreditoDescricao"
                            ></p-sortIcon>
                        </th>
                        <th pSortableColumn="situacaoCobrancaDescricao">
                            Situação da Cobrança
                            <p-sortIcon
                                field="situacaoCobrancaDescricao"
                            ></p-sortIcon>
                        </th>
                        <th>Ações</th>
                    </tr>
                </ng-template>
                <ng-template
                    pTemplate="body"
                    let-contaItcd
                    let-expanded="expanded"
                    let-rowIndex="rowIndex"
                >
                    <tr class="selecao">
                        <td class="td-icon">
                            <p-tableCheckbox
                                [value]="contaItcd"
                            ></p-tableCheckbox>
                        </td>
                        <td class="td-icon">
                            <button
                                type="button"
                                pButton
                                pRipple
                                [pRowToggler]="contaItcd"
                                class="p-button-text p-button-rounded p-button-plain"
                                [icon]="
                                    expanded
                                        ? 'pi pi-chevron-down'
                                        : 'pi pi-chevron-right'
                                "
                                (click)="expandeEnvento(rowIndex)"
                            ></button>
                        </td>
                        <td>
                            {{ contaItcd.configContaId }} -
                            {{ contaItcd.configContaNomeResumido }}
                        </td>
                        <!-- <td>{{ contaItcd.tipoCadastroDesc }}</td>
                        <td>{{ contaItcd.codigoCadastro }}</td>
                        <td>
                            {{ insereMascara(contaItcd?.cpfCnpj2) }} -
                            {{ contaItcd.nome }}
                        </td> -->
                        <td>{{ contaItcd.tipoNumeroDocumentoDesc }}</td>
                        <!-- <td>{{ contaItcd.tipoRelacionamentoDesc }}</td> -->
                        <td>{{ contaItcd.numeroDocumento }}</td>
                        <td>
                            {{ converteMesAno(contaItcd.periodoReferencia) }}
                        </td>
                        <td>
                            {{ contaItcd.dataVencimento | date : "dd/MM/yyyy" }}
                        </td>
                        <td>{{ contaItcd.estadoContaDesc }}</td>
                        <td>
                            {{
                                contaItcd.saldoTotalAtualizado
                                    | currency : "BRL"
                            }}
                        </td>
                        <td>{{ contaItcd.situacaoCreditoDesc }}</td>
                        <td>{{ contaItcd.situacaoCobrancaDesc }}</td>
                        <td class="selecao">
                            <i
                                (click)="
                                    openModal(valueContribuinte, contaItcd)
                                "
                                pTooltip="Gerar Extrato"
                                tooltipPosition="top"
                                class="fa-solid fa-file-pdf"
                                style="font-size: large; margin-right: 8px"
                            ></i>
                            <i
                                (click)="irDetalhamentoConta(contaItcd)"
                                pTooltip="Detalhe Conta"
                                tooltipPosition="top"
                                class="fa fa-list"
                                aria-hidden="true"
                                style="font-size: large; margin-right: 8px"
                            ></i>
                            <i
                                *ngIf="contaItcd?.dareBloqueado == 'NAO'"
                                (click)="getContas(contaItcd)"
                                pTooltip="Emitir DARE"
                                tooltipPosition="top"
                                class="fa-regular fa-file selecao p-mr-2"
                                aria-hidden="true"
                                style="font-size: large"
                            ></i>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="rowexpansion" class="p-text-center">
                    <ccp-detalhe-calculo-juros
                        [conta]="listContasIpvaItcdIcms[rowIndexConta]"
                    ></ccp-detalhe-calculo-juros>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td class="p-text-center">
                            <div style="margin-left: 46%">
                                Nenhum registro encontrado
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <p-paginator
                [rows]="20"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="{first} - {last} de {totalRecords}"
                [first]="pagination?.pageable?.offset"
                [rowsPerPageOptions]="[20, 50, 100]"
                [totalRecords]="pagination.totalElements"
                (onPageChange)="onPageChange($event)"
            ></p-paginator>
        </p-tabPanel>
        <!-- FIM PANEL IMPLANTADO -->

        <!-- PANEL HONORARIOS -->
        <p-tabPanel header="HONORARIOS" [disabled]="blockedDocument">
            <p-table
                #dt5
                [value]="listContasIpvaItcdIcms"
                [(selection)]="selectedProducts"
                [scrollable]="true"
                [globalFilterFields]="[
                    'configContaId',
                    'numeroDocumento',
                    'nomeContribuinte',
                    'periodoReferencia',
                    'dataVencimento',
                    'estadoContaDescricao',
                    'saldoTotalAtualizado',
                    'situacaoCreditoDescricao'
                ]"
                dataKey="contaCorrenteId"
            >
                <ng-template pTemplate="caption">
                    <div class="p-d-flex">
                        <span class="p-input-icon-left p-ml-auto">
                            <i class="pi pi-search"></i>
                            <input
                                pInputText
                                type="text"
                                (input)="
                                    dt5.filterGlobal(
                                        $event.target.value,
                                        'contains'
                                    )
                                "
                                placeholder="Pesquisar na página"
                            />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th class="td-icon">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th class="td-icon"></th>
                        <th pSortableColumn="configContaId">
                            Conta
                            <p-sortIcon field="configContaId"></p-sortIcon>
                        </th>
                        <!-- <th pSortableColumn="tipoCadastroDesc">
                            Tipo de Cadastro
                            <p-sortIcon field="tipoCadastroDesc"></p-sortIcon>
                        </th>
                        <th pSortableColumn="codigoCadastro">
                            Código Cadastro
                            <p-sortIcon field="codigoCadastro"></p-sortIcon>
                        </th>
                        <th pSortableColumn="nomeContribuinte">
                            Contribuinte
                            <p-sortIcon field="nomeContribuinte"></p-sortIcon>
                        </th>
                        <th pSortableColumn="">
                            Tipo de Relacionamento
                            <p-sortIcon field=""></p-sortIcon>
                        </th>-->
                        <th pSortableColumn="tipoDocumento">
                            Tipo de Documento
                            <p-sortIcon field="numeroDocumento"></p-sortIcon>
                        </th>
                        <th pSortableColumn="numeroDocumento">
                            Número do Documento
                            <p-sortIcon field="numeroDocumento"></p-sortIcon>
                        </th>
                        <th pSortableColumn="periodoReferencia">
                            Período de Referência
                            <p-sortIcon field="periodoReferencia"></p-sortIcon>
                        </th>
                        <th pSortableColumn="dataVencimento">
                            Vencimento
                            <p-sortIcon field="dataVencimento"></p-sortIcon>
                        </th>
                        <th pSortableColumn="estadoContaDescricao">
                            Estado da Conta
                            <p-sortIcon
                                field="estadoContaDescricao"
                            ></p-sortIcon>
                        </th>
                        <th pSortableColumn="saldoTotalAtualizado">
                            Saldo da Conta
                            <p-sortIcon
                                field="saldoTotalAtualizado"
                            ></p-sortIcon>
                        </th>
                        <th pSortableColumn="situacaoCreditoDescricao">
                            Situação do Crédito
                            <p-sortIcon
                                field="situacaoCreditoDescricao"
                            ></p-sortIcon>
                        </th>
                        <th pSortableColumn="situacaoCobrancaDescricao">
                            Situação da Cobrança
                            <p-sortIcon
                                field="situacaoCobrancaDescricao"
                            ></p-sortIcon>
                        </th>
                        <th>Ações</th>
                    </tr>
                </ng-template>
                <ng-template
                    pTemplate="body"
                    let-contaItcd
                    let-expanded="expanded"
                    let-rowIndex="rowIndex"
                >
                    <tr class="selecao">
                        <td class="td-icon">
                            <p-tableCheckbox
                                [value]="contaItcd"
                            ></p-tableCheckbox>
                        </td>
                        <td class="td-icon">
                            <button
                                type="button"
                                pButton
                                pRipple
                                [pRowToggler]="contaItcd"
                                class="p-button-text p-button-rounded p-button-plain"
                                [icon]="
                                    expanded
                                        ? 'pi pi-chevron-down'
                                        : 'pi pi-chevron-right'
                                "
                                (click)="expandeEnvento(rowIndex)"
                            ></button>
                        </td>
                        <td>
                            {{ contaItcd.configContaId }} -
                            {{ contaItcd.configContaNomeResumido }}
                        </td>
                        <!-- <td>{{ contaItcd.tipoCadastroDesc }}</td>
                        <td>{{ contaItcd.codigoCadastro }}</td>
                        <td>
                            {{ insereMascara(contaItcd?.cpfCnpj2) }} -
                            {{ contaItcd.nome }}
                        </td>
                        <td>{{ contaItcd.tipoRelacionamentoDesc }}</td> -->
                        <td>{{ contaItcd.tipoNumeroDocumentoDesc }}</td>
                        <td>{{ contaItcd.numeroDocumento }}</td>
                        <td>
                            {{ converteMesAno(contaItcd.periodoReferencia) }}
                        </td>
                        <td>
                            {{ contaItcd.dataVencimento | date : "dd/MM/yyyy" }}
                        </td>
                        <td>{{ contaItcd.estadoContaDesc }}</td>
                        <td>
                            {{
                                contaItcd.saldoTotalAtualizado
                                    | currency : "BRL"
                            }}
                        </td>
                        <td>{{ contaItcd.situacaoCreditoDesc }}</td>
                        <td>{{ contaItcd.situacaoCobrancaDesc }}</td>
                        <td class="selecao">
                            <i
                                (click)="
                                    openModal(valueContribuinte, contaItcd)
                                "
                                pTooltip="Gerar Extrato"
                                tooltipPosition="top"
                                class="fa-solid fa-file-pdf"
                                style="font-size: large; margin-right: 8px"
                            ></i>
                            <i
                                (click)="irDetalhamentoConta(contaItcd)"
                                pTooltip="Detalhe Conta"
                                tooltipPosition="top"
                                class="fa fa-list"
                                aria-hidden="true"
                                style="font-size: large; margin-right: 8px"
                            ></i>
                            <!-- <i *ngIf="contaItcd?.dareBloqueado == 'NAO'" (click)="getContas(contaItcd)" pTooltip="Emitir DARE" tooltipPosition="top" class="fa-regular fa-file selecao p-mr-2" aria-hidden="true" style="font-size: large"></i> -->
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="rowexpansion" class="p-text-center">
                    <ccp-detalhe-calculo-juros
                        [conta]="listContasIpvaItcdIcms[rowIndexConta]"
                    ></ccp-detalhe-calculo-juros>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td class="p-text-center">
                            <div style="margin-left: 46%">
                                Nenhum registro encontrado
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <p-paginator
                [rows]="20"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="{first} - {last} de {totalRecords}"
                [first]="pagination?.pageable?.offset"
                [rowsPerPageOptions]="[20, 50, 100]"
                [totalRecords]="pagination.totalElements"
                (onPageChange)="onPageChange($event)"
            ></p-paginator>
        </p-tabPanel>
        <!-- FIM PANEL HONORARIOS -->

        <!-- PANEL OUTROS -->
        <p-tabPanel header="OUTROS" [disabled]="blockedDocument">
            <p-table
                #dt6
                [value]="listContasIpvaItcdIcms"
                [(selection)]="selectedProducts"
                [scrollable]="true"
                [globalFilterFields]="[
                    'configContaId',
                    'numeroDocumento',
                    'nomeContribuinte',
                    'periodoReferencia',
                    'dataVencimento',
                    'estadoContaDescricao',
                    'saldoTotalAtualizado',
                    'situacaoCreditoDescricao'
                ]"
                dataKey="contaCorrenteId"
            >
                <ng-template pTemplate="caption">
                    <div class="p-d-flex">
                        <span class="p-input-icon-left p-ml-auto">
                            <i class="pi pi-search"></i>
                            <input
                                pInputText
                                type="text"
                                (input)="
                                    dt6.filterGlobal(
                                        $event.target.value,
                                        'contains'
                                    )
                                "
                                placeholder="Pesquisar na página"
                            />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th class="td-icon">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th class="td-icon"></th>
                        <th pSortableColumn="configContaId">
                            Conta
                            <p-sortIcon field="configContaId"></p-sortIcon>
                        </th>
                        <!-- <th pSortableColumn="tipoCadastroDesc">
                            Tipo de Cadastro
                            <p-sortIcon field="tipoCadastroDesc"></p-sortIcon>
                        </th>
                        <th pSortableColumn="codigoCadastro">
                            Código Cadastro
                            <p-sortIcon field="codigoCadastro"></p-sortIcon>
                        </th>
                        <th pSortableColumn="nomeContribuinte">
                            Contribuinte
                            <p-sortIcon field="nomeContribuinte"></p-sortIcon>
                        </th>
                        <th pSortableColumn="">
                            Tipo de Relacionamento
                            <p-sortIcon field=""></p-sortIcon>
                        </th>-->
                        <th pSortableColumn="tipoDocumento">
                            Tipo de Documento
                            <p-sortIcon field="numeroDocumento"></p-sortIcon>
                        </th>
                        <th pSortableColumn="numeroDocumento">
                            Número do Documento
                            <p-sortIcon field="numeroDocumento"></p-sortIcon>
                        </th>
                        <th pSortableColumn="periodoReferencia">
                            Período de Referência
                            <p-sortIcon field="periodoReferencia"></p-sortIcon>
                        </th>
                        <th pSortableColumn="dataVencimento">
                            Vencimento
                            <p-sortIcon field="dataVencimento"></p-sortIcon>
                        </th>
                        <th pSortableColumn="estadoContaDescricao">
                            Estado da Conta
                            <p-sortIcon
                                field="estadoContaDescricao"
                            ></p-sortIcon>
                        </th>
                        <th pSortableColumn="saldoTotalAtualizado">
                            Saldo da Conta
                            <p-sortIcon
                                field="saldoTotalAtualizado"
                            ></p-sortIcon>
                        </th>
                        <th pSortableColumn="situacaoCreditoDescricao">
                            Situação do Crédito
                            <p-sortIcon
                                field="situacaoCreditoDescricao"
                            ></p-sortIcon>
                        </th>
                        <th pSortableColumn="situacaoCobrancaDescricao">
                            Situação da Cobrança
                            <p-sortIcon
                                field="situacaoCobrancaDescricao"
                            ></p-sortIcon>
                        </th>
                        <th>Ações</th>
                    </tr>
                </ng-template>
                <ng-template
                    pTemplate="body"
                    let-contaItcd
                    let-expanded="expanded"
                    let-rowIndex="rowIndex"
                >
                    <tr class="selecao">
                        <td class="td-icon">
                            <p-tableCheckbox
                                [value]="contaItcd"
                            ></p-tableCheckbox>
                        </td>
                        <td class="td-icon">
                            <button
                                type="button"
                                pButton
                                pRipple
                                [pRowToggler]="contaItcd"
                                class="p-button-text p-button-rounded p-button-plain"
                                [icon]="
                                    expanded
                                        ? 'pi pi-chevron-down'
                                        : 'pi pi-chevron-right'
                                "
                                (click)="expandeEnvento(rowIndex)"
                            ></button>
                        </td>
                        <td>
                            {{ contaItcd.configContaId }} -
                            {{ contaItcd.configContaNomeResumido }}
                        </td>
                        <!-- <td>{{ contaItcd.tipoCadastroDesc }}</td>
                        <td>{{ contaItcd.codigoCadastro }}</td>
                        <td>
                            {{ insereMascara(contaItcd?.cpfCnpj2) }} -
                            {{ contaItcd.nome }}
                        </td>
                        <td>{{ contaItcd.tipoRelacionamentoDesc }}</td> -->
                        <td>{{ contaItcd.tipoNumeroDocumentoDesc }}</td>
                        <td>{{ contaItcd.numeroDocumento }}</td>
                        <td>
                            {{ converteMesAno(contaItcd.periodoReferencia) }}
                        </td>
                        <td>
                            {{ contaItcd.dataVencimento | date : "dd/MM/yyyy" }}
                        </td>
                        <td>{{ contaItcd.estadoContaDesc }}</td>
                        <td>
                            {{
                                contaItcd.saldoTotalAtualizado
                                    | currency : "BRL"
                            }}
                        </td>
                        <td>{{ contaItcd.situacaoCreditoDesc }}</td>
                        <td>{{ contaItcd.situacaoCobrancaDesc }}</td>
                        <td class="selecao">
                            <i
                                (click)="
                                    openModal(valueContribuinte, contaItcd)
                                "
                                pTooltip="Gerar Extrato"
                                tooltipPosition="top"
                                class="fa-solid fa-file-pdf"
                                style="font-size: large; margin-right: 8px"
                            ></i>
                            <i
                                (click)="irDetalhamentoConta(contaItcd)"
                                pTooltip="Detalhe Conta"
                                tooltipPosition="top"
                                class="fa fa-list"
                                aria-hidden="true"
                                style="font-size: large; margin-right: 8px"
                            ></i>
                            <i
                                *ngIf="contaItcd?.dareBloqueado == 'NAO'"
                                (click)="getContas(contaItcd)"
                                pTooltip="Emitir DARE"
                                tooltipPosition="top"
                                class="fa-regular fa-file selecao p-mr-2"
                                aria-hidden="true"
                                style="font-size: large"
                            ></i>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="rowexpansion" class="p-text-center">
                    <ccp-detalhe-calculo-juros
                        [conta]="listContasIpvaItcdIcms[rowIndexConta]"
                    ></ccp-detalhe-calculo-juros>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td class="p-text-center">
                            <div style="margin-left: 46%">
                                Nenhum registro encontrado
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <p-paginator
                [rows]="20"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="{first} - {last} de {totalRecords}"
                [first]="pagination?.pageable?.offset"
                [rowsPerPageOptions]="[20, 50, 100]"
                [totalRecords]="pagination.totalElements"
                (onPageChange)="onPageChange($event)"
            ></p-paginator>
        </p-tabPanel>
        <!-- FIM PANEL OUTROS -->
    </p-tabView>

    <div class="grid grid-nogutter justify-content-between margin-bt align-items-center button-row">
        <div class="left-buttons">
            <button
                pButton
                label="Voltar"
                icon="pi pi-angle-left"
                class="p-button-outlined p-mr-2"
                (click)="irSelecaoContribuinte()"
            ></button>
            <button
                pButton
                label="Simular"
                class="p-button p-mr-2"
                [disabled]="selectedProducts?.length == 0"
                (click)="simularHistoricoLancamento()"
            >
                <i class="fa-solid fa-calculator" style="margin-right: 8px"></i>
            </button>
        </div>
        <div class="right-buttons">
            <button
                pButton
                type="button"
                icon="pi pi-file-excel"
                label="Excel"
                class="p-button-success p-mr-2"
                (click)="exportarExcel()"
                [disabled]="desabilitarExports()"
            ></button>
            <button
                pButton
                type="button"
                icon="pi pi-file-pdf"
                label="PDF"
                class="p-button-danger p-mr-2"
                (click)="exportarPdf()"
                [disabled]="desabilitarExports()"
            ></button>
        </div>
    </div>
</p-card>

<modal-extrato-conta-corrente
    [abaSelecionada]="abaSelecionada"
></modal-extrato-conta-corrente>
<ccp-modal-simular-historico
    [listContasSimular]="selectedProducts"
    [abaSelecionada]="abaSelecionada"
></ccp-modal-simular-historico>
<modal-alerta-honorario
    [contaList]="contaDareVinculadaList"
    (outEmitDare)="postEmitirDare()"
></modal-alerta-honorario>
