<p-card [style]="{'margin-top': '10px'}">
    <div class="grid grid-nogutter justify-content-end margin-bt">
        <button
            pButton
            label="Voltar"
            icon="pi pi-angle-left"
            class="p-button-outlined p-mr-2"
            (click)="irMemoriaCalculo()"
        ></button>
        <button
        pButton
            class="float-btn-next"
            label="Próxima"
            icon="pi pi-angle-right"
            iconPos="right"
            (click)="irConfirmacao()"
        ></button>
    </div>

    <ng-template pTemplate="title">
        Contas Associadas
    </ng-template>
   
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12" style="margin-top: 20px;">
            <app-visualiza-config-conta></app-visualiza-config-conta>
        </div>
    </div>

    <!-- <p-fieldset
        title="Pesquisa da Conta"
        legend="Pesquisa da Conta"
        > -->
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
                <h5><strong>Pesquisa da Conta</strong></h5>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <p-button
                label="Buscar Conta"
                icon="pi pi-search" 
                (click)="openModa()"
               >
                </p-button>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <p-button
                    label="Remover Conta"
                    icon="pi pi-minus-circle"
                    class="p-mr-1 p-text-left"
                    (click)="removerItemPorId()"
                ></p-button>
            </div>

            <div class="p-field p-col-12">

                <p-table [value]="pagination.content" 
                         [(selection)]="contasSelecionadas"
                        [scrollable]="true"
                >
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 2rem">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th pSortableColumn="configContaId">
                            Conta <p-sortIcon field="configContaId"></p-sortIcon>
                        </th>
                        <th pSortableColumn="tipoCadastroId">
                            Tipo Cadastro <p-sortIcon field="tipoCadastroId"></p-sortIcon>
                        </th>
                        <th pSortableColumn="codigoCadastro">
                            Código Cadastro
                            <p-sortIcon field="codigoCadastro"></p-sortIcon>
                        </th>
                        <th pSortableColumn="nome">
                            Contribuinte
                            <p-sortIcon field="nome"></p-sortIcon>
                        </th>
                        <th pSortableColumn="numeroDocumento">
                            Número Documento
                            <p-sortIcon field="numeroDocumento"></p-sortIcon>
                        </th>
                        <th pSortableColumn="periodoReferencia">
                            Período Referência
                            <p-sortIcon field="periodoReferencia"></p-sortIcon>
                        </th>
                        <th pSortableColumn="parcela">
                            Cota/Pacela
                            <p-sortIcon field="parcela"></p-sortIcon>
                        </th>
                        <th pSortableColumn="dataVencimento">
                            Vencimento
                            <p-sortIcon field="dataVencimento"></p-sortIcon>
                        </th>
                        <th pSortableColumn="estadoContaDesc">
                            Estado da Conta
                            <p-sortIcon field="estadoContaDesc"></p-sortIcon>
                        </th>
                        <th pSortableColumn="saldoContaDesc">
                            Saldo da Conta
                            <p-sortIcon field="saldoContaDesc"></p-sortIcon>
                        </th>
                        <th pSortableColumn="situacaoCreditoDesc">
                            Situação Crédito
                            <p-sortIcon field="situacaoCreditoDesc"></p-sortIcon>
                        </th>
                        <th pSortableColumn="situacaoCobrancaDesc">
                            Situação Cobrança
                            <p-sortIcon field="situacaoCobrancaDesc"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                    <tr class="selecao">
                        <td style="width: 2rem">
                            <p-tableCheckbox [value]="item"></p-tableCheckbox>
                        </td>
                        <td>{{ item?.configContaId }}</td>
                        <td>{{ item?.tipoCadastroId }}</td>
                        <td>{{ item?.codigoCadastro }}</td>
                        <td>{{ item?.nome }}</td>
                        <td>{{ item?.numeroDocumento }}</td>
                        <td>{{ item?.periodoReferencia }}</td>
                        <td>{{ item?.contaCorrenteCotaUnicaId ? item?.contaCorrenteCotaUnicaId : '-' }}/{{ item?.parcela }}</td>
                        <td>{{ item?.dataVencimento }}</td>
                        <td>{{ item?.estadoContaDesc }}</td>
                        <td>{{ item?.saldoContaDesc }}</td>
                        <td>{{ item?.situacaoCreditoDesc }}</td>
                        <td>{{ item?.situacaoCobrancaDesc }}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td class="p-text-center">
                            <div style="margin-left: 46%">
                                Nenhum registro encontrado
                            </div>
                        </td>
                    </tr>
                </ng-template>
                </p-table>
                <!-- <p-paginator [rows]="10" [totalRecords]="pagination.totalElements" (onPageChange)="onChange($event)"></p-paginator> -->
            </div>

            
        </div>
    <!-- </p-fieldset> -->

    <div class="grid grid-nogutter justify-content-end" style="margin-top: 20px;">
        <button
            pButton
            label="Voltar"
            icon="pi pi-angle-left"
            class="p-button-outlined p-mr-2"
            (click)="irMemoriaCalculo()"
        ></button>
        <button
            [disabled]="pagination.content.length == 0"
            pButton
            class="float-btn-next"
            label="Próxima"
            icon="pi pi-angle-right"
            iconPos="right"
            [disabled]="false"
            (click)="irConfirmacao()"
        ></button>
    </div>
</p-card>

<contas-associadas-buscar (selectConta)="selectBaseLegal($event)"></contas-associadas-buscar>