import {NaturezaContaCorrente} from './natureza-conta-corrente';
import {OrigemInformacao} from './origem-informacao';
import {Periodicidade} from './periodicidade';
import {TipoDataReferencia} from './tipo-data-referencia';
import {Tributo} from './tributo';
import {ConfigContaTipoCadastro} from './config-conta-tipo-cadastro';
import {TipoCadastro} from './tipo-cadastro';
import { TipoCadastroSaveRequest } from './tipoCadastroSaveRequest';

export class ConfigConta {
    configContaId: number = null;
    dataFimVigencia: string = null;
    dataInicioVigencia: string = null;
    nomeCompleto: string = null;
    nomeResumido: string = null;
    situacao: string = null;
    tipoConta: string = null;
    implantado: string = null;
    origemInformacao: OrigemInformacao = null;
    periodicidade: Periodicidade = null;
    tipoDataReferencia: TipoDataReferencia = null;
    tributo: Tributo = null;
    naturezaContaCorrente: NaturezaContaCorrente = null;
    configContaTipoCadastros?: TipoCadastroSaveRequest[];
    // configContaTipoCadastros: ConfigContaTipoCadastro[] = [];

    constructor(init?: Partial<ConfigConta>) {
        if (init) {
            Object.assign(this, init);
        }
        // else {
        //     this.configContaId = null;
        //     this.dataFimVigencia = null;
        //     this.dataInicioVigencia = null;
        //     this.nomeCompleto = null;
        //     this.nomeResumido = null;
        //     this.situacao = null;
        //     this.tipoConta = null;
        //     this.origemInformacao = null;
        //     this.periodicidade = null;
        //     this.tipoDataReferencia = null;
        //     this.naturezaContaCorrente = null;
        //     this.tributo = null;
        //     this.configContaTipoCadastros = [];
        // }
    }
}
