
    <p-fieldset legend="Conta">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-2">
                <strong>Código da Conta</strong>
                <p>{{conta?.configContaId}}</p>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <strong>Nome Resumido</strong>
                <p>{{conta?.nomeResumido}}</p>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <strong>Inicio da Vigência</strong>
                <p>{{conta?.dataInicioVigencia | date: 'dd/MM/yyyy'}}</p>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <strong>Situação</strong>
                <p>{{conta?.situacao}}</p>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <strong>Implantada</strong>
                <p>{{conta?.implantado}}</p>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <strong>Origem da Informação</strong>
                <p>{{conta?.origemInformacao.sigla}}</p>
            </div>
        </div>    
    </p-fieldset>
