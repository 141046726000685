import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AcaoHelper {
    private static _roles: string[];

    static set roles(value: string[]) {
        AcaoHelper._roles = value;
    }

    static get roles(): string[] {
        return AcaoHelper._roles;
    }

    public static pode(acao: string): boolean {
        return this.roles?.includes(acao);
    }
}
