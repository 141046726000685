import { Component, Output, EventEmitter, ViewChild } from '@angular/core';
import { ConsultaContaVeiculoComponent } from 'src/app/component/criacao-conta-corrente/consulta-conta-veiculo/consulta-conta-veiculo.component';
import { UtilsService } from 'src/app/shared/utils.service';

@Component({
  selector: 'modal-evento-conta-corrente',
  templateUrl: './modal-evento-conta-corrente.component.html',
  styleUrls: ['./modal-evento-conta-corrente.component.scss']
})
export class ModalEventoContaCorrenteComponent {
  visible: boolean;
  blockedDocument: boolean;
  @ViewChild(ConsultaContaVeiculoComponent, { static: false })
  consultaConta: ConsultaContaVeiculoComponent;
  detalheEvento: any;
  dataHora: string;


  constructor(private utilsService: UtilsService) { 
  }

  showDialog(evento) {
    this.visible = true;
    this.detalheEvento = evento;
    this.converteDataHora(this.detalheEvento.dataCriacao);
  }

  hiddenDialog(){
    this.visible = false;
  }

  converteDataHora(dataCriacao){
    this.dataHora = 
    `${this.utilsService.convertToDateBr(dataCriacao.substring(0, 10))} às ${dataCriacao.substring(11, 20)}`;
  }

}
