export class UnidadeOrganizacional {
    unidadeOrganizacionalId: number;
    descricao: string;
    constructor(init?: Partial<UnidadeOrganizacional>) {
        if (init) {
            Object.assign(this, init);
        } else {
            this.unidadeOrganizacionalId = null;
            this.descricao = null;
        }
    }
}
