import { EventoPagamentoReceitas } from 'src/app/models/evento-pagamento-receitas';
import {Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import {BaseLegalSearchRequest} from '../../../request/base-legal-search-request';
import {Store} from '@ngrx/store';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {UtilsService} from '../../../shared/utils.service';
import {
    ConfigContaBaseLegalTabViewService
} from '../../configurar-conta-corrente/config-conta-base-legal-tab-view/config-conta-base-legal-tab-view.service';
import {EventoPagamentoEventoCompensacaoModalService} from './evento-pagamento-evento-compensacao-modal.service';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { EventoPagtoCompensacoes } from 'src/app/models/evento-pagamento-compensacoes';

@Component({
    selector: 'app-evento-pagamento-evento-compensacao-modal',
    templateUrl: './evento-pagamento-evento-compensacao-modal.component.html',
    styleUrls: ['./evento-pagamento-evento-compensacao-modal.component.scss']
})
export class EventoPagamentoEventoCompensacaoModalComponent implements OnInit {

    blockedDocument = false;

    @Input() dataInicio: string;
    @Input() eventoCompensacaoLength: any;

    @Output() eventoCompensacaoSelecionado = new EventEmitter<EventoPagtoCompensacoes>();

    validaData = false;

    eventosCompensacao: EventoPagtoCompensacoes[];
    eventoCompensacao: EventoPagtoCompensacoes;
    dataInicioVigencia: string;
    dateStart: Date;

    constructor(
        private storeBaseLegalSearchModal: Store<{ baseLegalSearchModal: { displayMaximizable: boolean } }>,
        private http: HttpClient,
        public utilsService: UtilsService,
        public eventoPagamentoEventoCompensacaoModalService: EventoPagamentoEventoCompensacaoModalService,
    ) {
    }

    ngOnInit(): void {
        this.eventoCompensacao = new EventoPagtoCompensacoes();
    }

    onShow(): void {
        this.carregaEventosCompensacao();
        this.dateStart = null;
        if (this.eventoCompensacaoLength === 0) {
            this.dateStart = this.eventoPagamentoEventoCompensacaoModalService.dateStart
            this.validaData = true;
            this.dataInicioVigencia = moment(this.dateStart).format('YYYY-MM-DD');
        } else { this.validaData = false; }
        this.validaConfirmar();
    }

    carregaEventosCompensacao(): void {
        this.getEventosCompensacao().subscribe(s => {
            // tslint:disable-next-line: no-string-literal
            this.eventosCompensacao = s['content'];
        });
    }

    getEventosCompensacao(): Observable<Array<EventoPagtoCompensacoes>> {
        return this.http.get<Array<EventoPagtoCompensacoes>>(`${environment.baseUrl}/evento-compensacao/search`);
    }

    confirmar() {
        this.blockedDocument = true;
        this.blockedDocument = false;
        this.select();
    }

    select() {
        const evCompensacao = JSON.parse(JSON.stringify(this.eventoCompensacao));
        evCompensacao.dataInicioVigencia = this.dataInicioVigencia;
        this.eventoCompensacaoSelecionado.emit(evCompensacao);
        this.eventoPagamentoEventoCompensacaoModalService.displayMaximizable = false;
    }

    onHide() {}


    onSelectDataInicioVigencia(event) {
        if (event) {
            this.dataInicioVigencia = moment(event).format('YYYY-MM-DD');
        } else {
            this.dataInicioVigencia = '';
        }
        return this.dataInicioVigencia;
    }
    validaConfirmar() {
        return this.dateStart && !Array.isArray(this.eventoCompensacao.eventoCompensacao) ? false : true;
    }
}
