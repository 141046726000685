import { Component, OnInit } from "@angular/core";

import { AppMainComponent } from "./app.main.component";
import { MenuResetPageIndexService } from "./menu/menu-reset-page.service";
import {AppTopbarService} from "./app.topbar.service";

@Component({
    selector: "app-menu",
    template: `
        <ul class="layout-menu">
            <li
                app-menuitem
                *ngFor="let item of model; let i = index"
                [item]="item"
                [index]="i"
                [root]="true"
            ></li>
        </ul>
    `,
})
export class AppMenuComponent implements OnInit {

    isGestor: boolean = false;
    isAdmin: boolean = false;
    model: any[] = [];

    constructor(
        public app: AppMainComponent,
        private menuResetPageIndexService: MenuResetPageIndexService,
        private appTopbarService: AppTopbarService
    ) { }

    async ngOnInit() {
        const perfilLogado = await this.appTopbarService.getPerfilLogado();
        this.isGestor = perfilLogado === 'PF_001'
        this.isAdmin = perfilLogado === 'ADMIN';

        this.menuMain();
    }

    menuMain() {
        this.adicionarMenuHome();
        this.adicionarMenuDashboard();
        this.adicionarMenuAdministracao();
        this.adicionarMenuContaCorrente();
    }

    adicionarMenuHome() {
        this.model.push(
            {
                label: "Home",
                icon: "pi pi-fw pi-home",
                routerLink: ["/"],
            }
        );
    }

    adicionarMenuDashboard() {
        this.model.push(
            {
                label: "Dashboard",
                icon: "pi pi-fw pi-chart-bar",
                items: [
                    {
                        label: "Arrecadação Esperada X Realizada",
                        icon: "pi pi-fw pi-align-left",
                        routerLink: ["/dashboard/lancamento-por-imposto",],
                    },
                    {
                        label: "Imposto/Conta em Atraso",
                        icon: "pi pi-fw pi-align-left",
                        routerLink: ["/dashboard/imposto-em-atraso"],
                    },
                    {
                        label: "Imposto/Contas Pagas",
                        icon: "pi pi-fw pi-align-left",
                        routerLink: ["/dashboard/imposto-conta-pagas"],
                    },
                    {
                        label: "Evento por período",
                        icon: "pi pi-fw pi-align-left",
                        routerLink: ["/dashboard/evento-por-periodo"],
                    },
                    {
                        label: "Relatório",
                        icon: "pi pi-fw pi-align-left",
                        routerLink: ["relatorios/relatorio-por-periodo"]
                    }
                ],
            }
        );
    }

    adicionarMenuAdministracao() {
        if (this.isGestor || this.isAdmin) {
            this.model.push({
                label: "Configurações",
                icon: "pi pi-fw pi-cog",
                items: [
                    {
                        label: "Contas Correntes",
                        icon: "pi pi-fw pi-align-left",
                        items: [
                            {
                                label: "Gerenciar Modelos",
                                icon: "pi pi-fw pi-align-left",
                                routerLink: [
                                    "/regra-criacao/regra-criacao-list",
                                ],
                            },
                            {
                                label: "Gerenciar Contas",
                                icon: "pi pi-fw pi-align-left",
                                routerLink: ["/config-conta/config-conta-list"],
                            },
                        ],
                    },
                    {
                        label: "Transações",
                        icon: "fa-solid fa-arrow-right-arrow-left",
                        routerLink: ["/gerenciar-transacao-financeira/selecionar-evento"]
                        // items: [
                        //     {
                        //         label: "Gerenciar Transações",
                        //         icon: "pi pi-fw pi-align-left",
                        //         routerLink: ["/gerenciar-transacao-financeira/selecionar-evento"],
                        //     }
                        // ],
                    },
                    {
                        label: "Estado da Conta",
                        icon: "pi pi-fw pi-align-left",
                        routerLink: ["/estado-conta/estado-conta-list"]
                        // items: [
                        //     {
                        //         label: "Gerenciar Modelo",
                        //         icon: "pi pi-fw pi-align-left",
                        //         routerLink: [
                        //             "/estado-conta/estado-conta-list",
                        //         ],
                        //     },
                        //     {
                        //         label: "Gerenciar Eventos",
                        //         icon: "pi pi-fw pi-align-left",
                        //         routerLink: [
                        //             "/evento-estado/evento-estado-list",
                        //         ],
                        //     },
                    },
                    {
                        label: "Eventos",
                        icon: "pi pi-fw pi-align-left",
                        routerLink: ["/evento-pesquisa-config",]
                        // items: [
                        //     {
                        //         label: "Lançamento",
                        //         icon: "pi pi-list",
                        //         routerLink: [
                        //             "/evento-lancamento/evento-lancamento-list",
                        //         ],
                        //     },
                        //     {
                        //         label: "Pagamento",
                        //         icon: "pi pi-fw pi-align-left",
                        //         routerLink: [
                        //             "/evento-pagamento/evento-pagamento-list",
                        //         ],
                        //     },
                        //     {
                        //         label: "Compensação",
                        //         icon: "pi pi-fw pi-align-left",
                        //         routerLink: [
                        //             "/evento-compensacao/evento-compensacao-list",
                        //         ],
                        //     },
                        //     {
                        //         label: "Beneficio Fiscal",
                        //         icon: "pi pi-fw pi-align-left",
                        //         routerLink: [
                        //             "/evento-beneficio/evento-beneficio-list",
                        //         ],
                        //     }
                        // ],
                    },
                    {
                        label: "Gerais",
                        icon: "pi pi-fw pi-align-left",
                        items: [
                            {
                                label: "Regra de Imputação",
                                icon: "pi pi-fw pi-align-left",
                                routerLink: [
                                    "/config-regra-geral/regra-imputacao",
                                ],
                            },
                            {
                                label: "Regra de Seleção",
                                icon: "pi pi-fw pi-sliders-v",
                                routerLink: [
                                    "/config-regra-selecao/config-regra-selecao-list",
                                ],
                            },
                        ]
                    },
                    {
                        label: "Integração",
                        icon: "pi pi-fw pi-align-left",
                        items: [
                            {
                                label: "Configuração de Operadores",
                                icon: "pi pi-fw pi-align-left",
                                routerLink: [
                                    "/config-operadores/gerenciar-operadores",
                                ],
                            },
                            {
                                label: "Configuração Fluxos",
                                icon: "pi pi-fw pi-align-left",
                                routerLink: [
                                    "/config-fluxos/gerenciar-fluxos",
                                ],
                            },
                        ]
                    },
                ],
            })
        }
    }

    adicionarMenuContaCorrente() {
        let subItensContaCorrente = [];

        if (this.isGestor || this.isAdmin) {
            subItensContaCorrente.push(
                {
                    label: "Abertura",
                    icon: "pi pi-file-o",
                    routerLink: [
                        "/criacao-conta"
                    ],
                    command: (event) => {
                        this.menuResetPageIndexService.resetIndex();
                    },
                }
            );
        }

        subItensContaCorrente.push(
            {
                label: "Consulta",
                icon: "pi pi-search",
                routerLink: [
                    "/consultar-conta-veiculo"
                ],
                command: (event) => {
                    this.menuResetPageIndexService.resetIndex();
                },
            },
        );

        this.model.push({
                label: "Conta Corrente",
                icon: "pi pi-user",
                items: subItensContaCorrente
            }
        );
    }

}
