import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import { TransacaoSearchRequest } from 'src/app/request/TransacaoSearchRequest';

@Injectable({
  providedIn: 'root'
})
export class BeneficioFiscalService {

  httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient) { }

  getContas(objParams): Observable<Array<any>> {
    const params = new HttpParams({ fromObject: objParams});
    return this.http.get<Array<any>>(`${environment.baseUrl}/config-conta`,{params});
  }

  getTrasacao(transacaoSearchRequest: TransacaoSearchRequest): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${environment.baseUrl}/transacao/search-trans-ref`,{
      params: {
        ...transacaoSearchRequest
      }
    });
  }

  getRegra(objParams): Observable<Array<any>> {
    const params =  new HttpParams({ fromObject: objParams});
    return this.http.get<Array<any>>(`${environment.baseUrl}/regra`, { params });
  }

  getTipoCadastro(): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${environment.baseUrl}/tipo-cadastro`);
  }

  eventoBeneficioSaveAll(objSaveRequest): Observable<any[]> {
    return this.http.post<any[]>(`${environment.baseUrl}/evento-beneficio/save-all`, objSaveRequest);
  }

  getEventoBeneficio(eventoBeneficioId): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${environment.baseUrl}/evento-beneficio/${eventoBeneficioId}`);
  }

  getOrigemCredito(objParams): Observable<Array<any>> {
    const params = new HttpParams({ fromObject: objParams});
    return this.http.get<Array<any>>(`${environment.baseUrl}/origem-credito`,{params});
  }

  getContaContemPlada(eventoBeneficioId): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${environment.baseUrl}/conta-contemplada/evento-beneficio/${eventoBeneficioId}`);
  }

  getTransacoes(eventoBeneficioId): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${environment.baseUrl}/evento-transacao-regra/evento-beneficio/${eventoBeneficioId}`);
  }

  getDestinatario(eventoBeneficioId): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${environment.baseUrl}/destinatario/evento-beneficio/${eventoBeneficioId}`);
  }

  getTpoEvento(request): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${environment.baseUrl}/eventos`, {
        params: {
            ...request,
        },
    });
  }

  getDescConfigConta(configContaId): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${environment.baseUrl}/config-conta/search?configContaId=${configContaId}`);
  }

  deleteContaContemplada(eventoBeneficioId): Observable<void> {
    return this.http.delete<void>(
        `${environment.baseUrl}/conta-contemplada/${eventoBeneficioId}`);
  }

  deleteTransacaoRegras(eventoBeneficioId): Observable<void> {
    return this.http.delete<void>(
        `${environment.baseUrl}/evento-transacao-regra/${eventoBeneficioId}`);
  }
}
