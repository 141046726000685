import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { Pageable } from 'src/app/shared/pagination/pageable';
import { Pagination } from 'src/app/shared/pagination/pagination';
import { environment } from 'src/environments/environment';

import { BaseResourceService } from '../../components/base-resource-service/base-resource-service';

@Injectable({
  providedIn: 'root'
})
export class SelecionarContribInternaService extends BaseResourceService {

  protected http: HttpClient;

  constructor(protected injector: Injector) {
    super(`${environment.baseUrl}/enums`, injector);
  }

  public getTipoCadastro(): Observable<any[]> {
    return this.getUtilList(`${this.apiPath}/name/TipoCadastro`);
  }

  public search(filtro, pagination: Pagination<any>): Observable<any> {
    return this.http.get<Pageable<any>>(
      `${environment.baseUrl}/contribuinte/search`, {
        params: {
          ...filtro,
          page: pagination.pageable.pageNumber,
          size: pagination.size
        },
      }
    )
  }

  public searchVeiculo(filtro: any, pagination: Pagination<any>): Observable<any> {
    return this.http.get<Pageable<any>>(
      `${environment.baseUrl}/ipva/search`, {
        params: {
          ...filtro,
          page: pagination.pageable.pageNumber,
          size: pagination.size
        },
      }
    )
  }

  public searchContribuinteDetails(cpfcnpj: string): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/contribuinte/cadastro-economico/${cpfcnpj}`);
  }

  public searchVeiculoDetails(idRenavam: string): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/ipva/${idRenavam}`);
  }

  public getFinanceiroContaCorrente(value: string): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/conta-corrente/financeiro`, {
        params: {
          "codigoCadastro": value
        } 
      } 
    );
  }

  public searchContaCorrente(value: string): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/conta-corrente/search`, {
        params: {
          "codigoCadastro": value
        } 
      } 
    );
  }
}
