import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import * as moment from "moment";

import { BaseLegalSearchModalService } from "../base-legal-search-modal/base-legal-search-modal.service";
import { BaseLegalService } from "../base-legal-search-modal/base-legal.service";

@Component({
    selector: "app-base-legal-search",
    templateUrl: "./base-legal-search.component.html",
    styleUrls: ["./base-legal-search.component.scss"],
})
export class BaseLegalSearchComponent implements OnInit {
    @ViewChild("dataInicialBaseLegal")
    private dataInicioBaseLegal: any;

    @ViewChild("dataFinalBaseLegal")
    private dataFinalBaseLegal: any;

    beneficioImplantado: boolean;
    baseLegal: any = null;
    @Output() baseLegalChange = new EventEmitter<any>();
    @Input() baseLegalId: number;
    @Input() disabled: boolean | null;

    constructor(
        private baseLegalSearchModalService: BaseLegalSearchModalService,
        public baseLegalService: BaseLegalService
    ) {}

    ngOnInit(): void {
        if (!!this.baseLegalId) {
            this.baseLegalService
                .getBaseLegal(this.baseLegalId)
                .subscribe((obj) => {
                    this.baseLegal = obj;
                    this.dataInicioBaseLegal.inputfieldViewChild.nativeElement.value =
                        obj.tblVigenciaInicio;
                    this.dataFinalBaseLegal.inputfieldViewChild.nativeElement.value =
                        obj.tblVigenciaFinal;
                });
        }
    }

    showBasicDialog(): void {
        this.baseLegalSearchModalService.displayMaximizable = true;
    }

    changeBaseLegal(obj: any): void {
        obj.tblVigenciaInicio = obj.tblVigenciaInicio
            ? moment(obj.tblVigenciaInicio, "YYYY-MM-DD").format("DD/MM/YYYY")
            : "";
        obj.tblVigenciaFinal = obj.tblVigenciaInicio
            ? moment(obj.tblVigenciaFinal, "YYYY-MM-DD").format("DD/MM/YYYY")
            : "";

        this.baseLegal = obj;
        this.baseLegalChange.emit(this.baseLegal);
        this.dataInicioBaseLegal.inputfieldViewChild.nativeElement.value =
            obj.tblVigenciaInicio;
        this.dataFinalBaseLegal.inputfieldViewChild.nativeElement.value =
            obj.tblVigenciaFinal;
    }
}
