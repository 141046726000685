import {ConfigContaRegraCriacao} from '../../../models/config-conta-regra-criacao';
import {ConfigConta} from '../../../models/config-conta';
import * as RegraCriacaoViewActions from './regra-criacao-view.actions';

const modelInit = new ConfigContaRegraCriacao();
modelInit.configConta = new ConfigConta();

const regraCriacaoViewInitialState = {
    configContaRegraCriacao: modelInit,
    configsContasRegrasCriacoes: []
};

export function regraCriacaoViewReducer(state = regraCriacaoViewInitialState, action: RegraCriacaoViewActions.SetConfigContaRegraCriacao) {
    switch (action.type) {
        case RegraCriacaoViewActions.SET_CONFIG_CONTA_REGRA_CRIACAO:
            return {
                ...state,
                // configContaRegraCriacao: {...state.configContaRegraCriacao, action.payload}
                configContaRegraCriacao: action.payload
            };
        default:
            return state;
    }
}

export function regrasCriacoesViewReducer(state = regraCriacaoViewInitialState, action: RegraCriacaoViewActions.SetConfigsContasRegrasCriacoes) {
    switch (action.type) {
        case RegraCriacaoViewActions.SET_CONFIGS_CONTAS_REGRAS_CRIACOES:
            return {
                ...state,
                // configContaRegraCriacao: {...state.configContaRegraCriacao, action.payload}
                configsContasRegrasCriacoes: action.payload
            };
        default:
            return state;
    }
}
