<p-card header="Configuração de Evento de Pagamento">
    <div
        style="
            margin-right: 8px;
            margin-top: 10px;
            margin-bottom: 10px;
            text-align: right !important;
        "
    >
        <button
            (click)="novaTransacao()"
            pButton
            type="button"
            icon="pi pi-plus-circle"
            label="Novo Evento"
            class="p-mr-1 p-text-left botao-adicionar"
        ></button>
    </div>

    <p-table
        [value]="pagination?.content || []">
        <ng-template pTemplate="header">
            <tr>
                <th>Código</th>
                <th>Descrição</th>
                <th>Implantado?</th>
                <th>Situação</th>
                <th>Início Vigência</th>
                <th>Fim Vigência</th>
                <th style="width: 125px">Ações</th>
            </tr>
            <tr>
                <th>
                    <p-columnFilter
                        type="text"
                        field="eventoPagamentoId"
                    ></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter
                        type="text"
                        field="descricao"
                    ></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter
                        field="implantado"
                        matchMode="equals"
                        [showMenu]="false"
                    >
                        <ng-template
                            pTemplate="filter"
                            let-value
                            let-filter="filterCallback"
                        >
                            <p-dropdown
                                [ngModel]="value"
                                [options]="simNaos"
                                (onChange)="filter($event.value)"
                                placeholder="Todos"
                                [showClear]="true"
                            >
                                <ng-template let-option pTemplate="item">
                                    <p-badge
                                        [value]="option.label"
                                        size="small"
                                        [severity]="
                                            option.value === 'SIM'
                                                ? 'success'
                                                : 'danger'
                                        "
                                    ></p-badge>
                                </ng-template>
                            </p-dropdown>
                        </ng-template>
                    </p-columnFilter>
                </th>
                <th>
                    <p-columnFilter
                        field="situacao"
                        matchMode="equals"
                        [showMenu]="false"
                    >
                        <ng-template
                            pTemplate="filter"
                            let-value
                            let-filter="filterCallback"
                        >
                            <p-dropdown
                                [ngModel]="value"
                                [options]="situacoes"
                                (onChange)="filter($event.value)"
                                placeholder="Todos"
                                [showClear]="true"
                            >
                                <ng-template let-option pTemplate="item">
                                    <p-badge
                                        [value]="option.label"
                                        size="small"
                                        [severity]="
                                            option.value === 'ATIVA'
                                                ? 'success'
                                                : 'danger'
                                        "
                                    ></p-badge>
                                </ng-template>
                            </p-dropdown>
                        </ng-template>
                    </p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="date" field="dataInicioVigencia" display="menu">
                        <ng-template pTemplate="filter" let-filter="filterCallback">
                            <p-calendar dateFormat="dd/mm/yy" [readonlyInput]="true" (onSelect)="filter($event)"></p-calendar>
                        </ng-template>
                    </p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="date" field="dataFimFigencia" display="menu">
                        <ng-template pTemplate="filter" let-filter="filterCallback">
                            <p-calendar dateFormat="dd/mm/yy" [readonlyInput]="true" (onSelect)="filter($event)"></p-calendar>
                        </ng-template>
                    </p-columnFilter>
                </th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-pagamento>
            <tr>
                <td>{{ pagamento.eventoPagamentoId }}</td>
                <td>{{ pagamento.descricao }}</td>
                <td>{{ pagamento.implantado }}</td>
                <td>{{ pagamento.situacao }}</td>
                <td>{{ convertDateBr(pagamento.dataInicioVigencia) }}</td>
                <td>{{ convertDateBr(pagamento.dataFimFigencia) }}</td>
                <td>
                    <button
                        pButton
                        pRipple
                        title="Editar"
                        type="button"
                        icon="pi pi-pencil"
                        class="p-button-rounded p-button-primary"
                        style="margin-right: 3px"
                        (click)="onEditar(pagamento)"
                        [routerLink]="[
                            '/evento-pagamento/evento-pagamento-cadastrar',
                            pagamento.eventoPagamentoId
                        ]"
                        routerLinkActive="router-link-active"
                    ></button>
                    <button
                        *ngIf="pagamento.implantado === 'NAO'"
                        pButton
                        pRipple
                        title="Excluir"
                        type="button"
                        icon="pi pi-trash"
                        class="p-button-rounded p-button-danger"
                        style="margin-right: 3px"
                        (click)="onExcluir(pagamento)"
                    ></button>
                    <button
                        *ngIf="pagamento.implantado === 'SIM'"
                        pButton
                        pRipple
                        title="{{
                            pagamento.situacao === 'ATIVA'
                                ? 'Desativar'
                                : 'Ativar'
                        }}"
                        type="button"
                        icon="{{
                            pagamento.situacao === 'ATIVA'
                                ? 'pi pi-times'
                                : 'pi pi-check'
                        }}"
                        class="p-button-rounded p-button-secondary"
                        (click)="onAtivaInativaPagamento(pagamento)"
                    ></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <p-paginator
        [rows]="10"
        [totalRecords]="pagination?.totalElements"
        (onPageChange)="onPageChange($event)"
    ></p-paginator>
</p-card>
