import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import swal from 'sweetalert2';
import { KeycloakService } from 'keycloak-angular';
@Injectable()
export class ErrorInterceptors implements HttpInterceptor {
  constructor(private keyCloak: KeycloakService, /*private service: AppTopbarService*/) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.has('X-Skip-Error-Interceptor')) {
      const headers = request.headers.delete('X-Skip-Error-Interceptor');
      return next.handle(request.clone({ headers }));
    }

    return next.handle(request).pipe(
      catchError((err) => {
        swal.close();
        if (err.status === 422) {
          swal.fire('Aviso', err.error.message, 'warning');
          return throwError(err);
        } else if (err.status === 400) {
          if (err.error?.errors) {
            let details = '';
            err.error.errors?.forEach((element: any) => {
              details += `${element.message} <br>`;
            });
            swal.fire({
              title: 'Campos Obrigatórios',
              icon: 'warning',
              html: details
            });
          } else {
            swal.fire('Aviso', err?.error?.message, 'warning');
          }
          return throwError(err);
        } else if (err.status === 401) {
          swal.fire({
            title: 'Sessão expirada!',
            text: 'É necesário realizar uma nova autenticação.',
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: 'Fazer Login Novamente',
            showLoaderOnConfirm: true,
            preConfirm: (login) => {
              this.keyCloak.logout();
              //this.service.clearProfile();
            },
            allowOutsideClick: false
          });
        } else if (err.status === 500) {
          swal.fire('Falha', 'Erro Interno de Servidor', 'error');
          return throwError(err);
        } else if (err.status === 404) {
          swal.fire('Falha', err.error.message || 'Não encontrado!', 'error');
          return throwError(err);
        } else if (err.status === 417) {
          swal.fire('Falha', err.error?.message, 'error');
          return throwError(err);
        } else if (err.status === 503) {
           console.log('ignora representante');
        } else if (err.status === 0) {
          swal.fire({
            title: 'Falha',
            text: 'Falha de comunicação com o servidor.',
            icon: 'error',
            showCancelButton: true,
            cancelButtonText: 'OK',
            confirmButtonText: 'Sair',
            showLoaderOnConfirm: true,
            preConfirm: (login) => {
              this.keyCloak.logout();
              //this.service.clearProfile();
            },
            allowOutsideClick: false
          });
          return throwError(err);
        } else if (err.status === 503) {
          swal.fire({
            title: 'Serviço Indiponível!',
            text: 'Tente novamente mais tarde.',
            icon: 'error',
            showCancelButton: true,
            cancelButtonText: 'OK',
            confirmButtonText: 'Sair',
            showLoaderOnConfirm: true,
            preConfirm: (login) => {
              this.keyCloak.logout();
              //this.service.clearProfile();
            },
            allowOutsideClick: false
          });
          return throwError(err);
        } else {
          swal.fire(err.error?.error, err.error?.message, 'error');
          return throwError(err);
        }
        return throwError(err);
      })
    );
  }
}
