import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class EventoPagamentoEventoCompensacaoModalService {
    displayMaximizable: boolean;
    dateStart: Date;
    constructor() {
    }
}
