<p-card>
    <ng-template pTemplate="title"> Contas Contempladas </ng-template>
    <ng-template pTemplate="content">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-6">
                <label>Conta</label>
                <p-dropdown
                placeholder="Selecione uma conta"
                [options]="contaList"
                [optionLabel]="'nomeResumido'"
                [(ngModel)]="contaSelecionada"
                ></p-dropdown>
            </div>
            <div class="p-field p-col-6">
                <button
                    style="float: right"
                    class="margin-position p-mr-1"
                    pButton
                    type="button"
                    icon="pi pi-plus-circle"
                    label="Adicionar Conta"
                    (click)="addConta()"
                    [disabled]="implantado == 'SIM'"
                ></button>
            </div>
            <div class="p-field p-col-12">
                <p-table [value]="contas" [paginator]="true" [rows]="5">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Código</th>
                            <th>Descrição</th>
                            <th>Tributo</th>
                            <th>Ação</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-c let-rowIndex="rowIndex">
                        <tr>
                            <td>{{ c.configContaId }}</td>
                            <td>{{ c.nomeResumido }}</td>
                            <td>{{ c.tributo }}</td>
                            <td>
                                <button
                                    pButton
                                    pRipple
                                    title="Remover"
                                    type="button"
                                    icon="pi pi-trash"
                                    class="p-button-rounded p-button-danger"
                                    style="margin-left: 3px"
                                    (click)="removerConta(rowIndex, c)"
                                    [disabled]="c.implantado == 'SIM'"
                                ></button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <div class="grid grid-nogutter justify-content-end">
            <p-button 
                label="Anterior"
                icon="pi pi-angle-left"
                (click)="voltarAba()"
            ></p-button>
            <p-button
                class="float-btn-next margin-right"
                label="Próxima"
                icon="pi pi-angle-right"
                iconPos="right"
                [disabled]="contas.length <= 0"
                (click)="proximaAba()"
            ></p-button>
        </div>
    </ng-template>
</p-card>
