export enum TiposParamsConfigEventos {
    //PASSO 1 - EVENTOS
    //PASSO 2 - CONFIGURACAO
    P2_MUDA_ESTADO_CONTA='P2_MUDA_ESTADO_CONTA',
    P2_MUDA_SITUCAO_COBRANCA='P2_MUDA_SITUCAO_COBRANCA',
    P2_MUDA_SITUCAO_CREDITO='P2_MUDA_SITUCAO_CREDITO',
    P2_LANCA_HONORARIO='P2_LANCA_HONORARIO',
    //PASSO 3 - CONTAS

    //PASSO 4 - TRANSACOES
    P4_PULA_ETAPA='P4_PULA_ETAPA',
    P4_QNT_TRANSACAO='P4_QNT_TRANSACAO',
    P4_DEBITO_APENAS='P4_DEBITO_APENAS',
    P4_CREDITO_APENAS='P4_CREDITO_APENAS',
    //PASSO 5 - VISUALIZACAO
    //PASSO 6 - BASE LEGAL
}
