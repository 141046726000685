<span *ngFor="let condition of conditions">

    <div *ngIf="condition.item" class="p-field p-col-3" style="float: left">

        <span class="p-input-icon-left timeselector">
            <i class="pi pi-search"></i>
            <input class="cursor" readonly (click)="op.toggle($event)" type="text" pInputText
                placeholder="Item de Composição" [(ngModel)]="condition.item.name"
                [ngModelOptions]="{standalone: true}" />
            <p-overlayPanel #op [showCloseIcon]="true" [dismissable]="true" [style]="{width: '500px'}">
                <p-accordion>
                    <p-accordionTab *ngFor="let item of groupArr"
                        [header]="item.origemInformacaoCategori.descricaoReduzida">
                        <table>
                            <span *ngIf="item.groupItem" (click)="closeTimeselector($event, op)">
                                <tr>
                                    <th>Código</th>
                                    <th>Campo</th>
                                    <th>Descrição</th>
                                </tr>
                                <tr class="hoverTable" *ngFor="let itemComposicao of item.groupItem"
                                    (click)="itemCalculo(condition.item, itemComposicao)">
                                    <td>{{itemComposicao.itemCodigo}}</td>
                                    <td>{{itemComposicao.itemCampo}}</td>
                                    <td>{{itemComposicao.itemDescricao}}</td>
                                </tr>
                            </span>
                        </table>
                    </p-accordionTab>
                </p-accordion>
            </p-overlayPanel>
        </span>
    </div>

    <div *ngIf="condition.relational" style="display: block;float:left;" class="p-field p-col-1">
        <p-dropdown [options]="operators | keyvalue" optionLabel="value" optionValue="key"
            [(ngModel)]="condition.relational" [ngModelOptions]="{standalone: true}"></p-dropdown>
    </div>

    <div *ngIf="condition.constant != undefined || condition.constant" class="p-field p-col-3"
        style="display: block;float:left;">
        <input pInputText [(ngModel)]="condition.constant" placeholder="Constante" type="text"
            [ngModelOptions]="{standalone: true}">
    </div>

    <div *ngIf="condition.logical != undefined || condition.logical" class="p-field p-col-1"
        style="display: block;float:left;">
        <input pInputText *ngIf="condition.logical" [(ngModel)]="condition.logical" readonly type="text">
    </div>


    <div *ngIf="condition.children">
        <div style="font-size: 16px;">(</div>
        <div class="children"
            style="border: 1px solid; border-color: #ccc; min-height: 120px; padding: 10px; clear: both;min-width: 800px;">
            <app-conditional [groupArr]="groupArr" [conditions]="condition?.children"></app-conditional>
        </div>
        <div *ngIf="condition.children" style="font-size: 16px;">)</div>
    </div>
</span>