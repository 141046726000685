import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Pagination } from "src/app/shared/pagination/pagination";
import { environment } from "src/environments/environment";
import { Pageable } from "src/app/shared/pagination/pageable";
import { Component, OnInit } from "@angular/core";
import * as moment from "moment";
import swal from "sweetalert2";
import { Message } from "primeng/api";

@Component({
    selector: "app-evento-compensacao",
    templateUrl: "./evento-compensacao-list.component.html",
    styleUrls: ["./evento-compensacao-list.component.scss"],
})
export class EventoCompensacaoListComponent implements OnInit {
    //TODO: adicionar rota para cadastro de evento de compensação
    blockedDocument = false;
    pagination: Pagination<any> = new Pagination();
    msgs1: Message[];
    tipoBotao: string;
    iconeBotao: string;
    corBotao: string;
    situacoes: any[];
    simNaos: any[];

    constructor(private http: HttpClient, private router: Router) {}

    ngOnInit(): void {
        this.search();
        this.situacoes = [
            { label: "Ativa", value: "ATIVA" },
            { label: "Inativa", value: "INATIVA" }
        ];
        this.simNaos = [
            { label: "Sim", value: "SIM" },
            { label: "Não", value: "NAO" }
        ];
    }

    onPageChange(e) {
        this.pagination.pageable.pageNumber = e.page;
        this.search();
    }

    novaTransacao() {
        const novaConta = 'NOVA';
        this.router.navigate(['/evento-compensacao/config'], {state: {novaConta}}).then(r => {});
    }

    ativaInativaPagamento(pagamento: any) {
        pagamento.implantado === 'SIM' ? this.ativaDesativaCompensacao(pagamento) : this.excluiCompensacao(pagamento);
    }

    ativaDesativaCompensacao(pagamento: any) {
        const ativarDesativar = pagamento.situacao === 'ATIVA' ? 'desativar' : 'ativar';
        const ativoInativo = pagamento.situacao === 'ATIVA' ? 'desativado' : 'ativado';
        swal.fire({
            text: `Deseja ${ativarDesativar} o evento de compensação
            ${pagamento.eventoCompensacaoId} - ${pagamento.descricao}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
        }).then((result) => {
            if (result.value) {
                this.http
                .put<any>(
                    `${environment.baseUrl}/evento-compensacao/${pagamento.eventoCompensacaoId}/ativa-inativa`, {}
                ).subscribe(
                        (response) => {
                            swal.fire(
                                'Sucesso',
                                `${ativoInativo} com sucesso`,
                                'success'
                            ).then((r) => this.search());
                        },
                        (error) => {
                            if (error.status === 500 || error.status === 404) {
                                swal.fire(
                                    error.statusText,
                                    'Ocorreu um erro interno',
                                    'error'
                                ).then((r) => {});
                            } else if (error.status === 412) {
                                swal.fire(
                                    'Erro de Validação',
                                    error.error.message,
                                    'error'
                                ).then((r) => {});
                            } else if (error.status === 400) {
                                const errors = error.error.errors;
                                this.msgs1 = [];
                                errors.forEach((e) => {
                                    this.msgs1.push({
                                        severity: 'error',
                                        summary: 'Erro',
                                        detail: e.message,
                                    });
                                });
                            }
                        }
                    );
            } else {
                result.dismiss === swal.DismissReason.cancel;
            }
        });
    }
    onEditar(pagamento: any){
        this.router
            .navigate(['/evento-compensacao/config'], {state: {eventoCompensacao: pagamento}})
            .then(r => {});
     }
    excluiCompensacao(pagamento: any){
        swal.fire({
            text: `Deseja excluir o evento de compensação ${pagamento.eventoCompensacaoId} -
             ${pagamento.descricao}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
        }).then((result) => {
            if (result.value) {
                this.http
                .delete<any>(
                    `${environment.baseUrl}/evento-compensacao/${pagamento.eventoCompensacaoId}`,

                ).subscribe(
                        (response) => {
                            swal.fire(
                                'Sucesso',
                                'Excluído com sucesso',
                                'success'
                            ).then((r) => this.search());
                        },
                        (error) => {
                            if (error.status === 500 || error.status === 404) {
                                swal.fire(
                                    error.statusText,
                                    'Ocorreu um erro interno',
                                    'error'
                                ).then((r) => {});
                            } else if (error.status === 412) {
                                swal.fire(
                                    'Erro de Validação',
                                    error.error.message,
                                    'error'
                                ).then((r) => {});
                            } else if (error.status === 400) {
                                const errors = error.error.errors;
                                this.msgs1 = [];
                                errors.forEach((e) => {
                                    this.msgs1.push({
                                        severity: 'error',
                                        summary: 'Erro',
                                        detail: e.message,
                                    });
                                });
                            }
                        }
                    );
            } else {
                result.dismiss === swal.DismissReason.cancel;
            }
        });
    }

    scrollToTop() {
        (function smoothscroll() {
            const currentScroll =
                document.documentElement.scrollTop || document.body.scrollTop;
            if (currentScroll > 0) {
                window.requestAnimationFrame(smoothscroll);
                window.scrollTo(0, currentScroll - currentScroll / 8);
            }
        })();
    }

    podeAtivar(item: any): boolean {
        if (item.implantado === 'SIM') {
            this.corBotao = 'secondary';
            if (item.situacao === 'INATIVA') {
                this.tipoBotao = 'Ativar';
                this.iconeBotao = 'check';
            } else {
                this.tipoBotao = 'Inativar';
                this.iconeBotao = 'times';
            }
            return true;
        } else {
            return false;
        }
    }

    podeDeletar(item: any): boolean {
        if (item.implantado === 'NAO'){
            this.corBotao = 'danger';
            this.tipoBotao = 'Excluir';
            this.iconeBotao = 'trash';
            return true;
        } else {
            return false;
        }
    }

    search() {
        this.blockedDocument = true;
        this.http
            .get<Pageable<any>>(
                `${environment.baseUrl}/evento-compensacao/search`,
                {
                    params: {
                        size: 10,
                        page: this.pagination.pageable.pageNumber,
                    },
                }
            )
            .subscribe(
                (response) => {
                    this.pagination = response;
                    this.blockedDocument = false;
                    this.pagination
                        .content
                        .forEach(r => {
                            r.dataInicioVigencia = r.dataInicioVigencia === null ? null : this.toLocaleDate(r.dataInicioVigencia);
                            r.dataFimVigencia = r.dataFimVigencia === null ? null : this.toLocaleDate(r.dataFimVigencia)
                        });
                },
                (error) => {
                    this.blockedDocument = false;
                }
            );
    }

    toLocaleDate(date){
        return new Date(`${date} 23:59:59`);
    }

    convertDateBr(data) {
        return data == null ? "" : moment(data).format("DD/MM/YYYY");
    }

}
