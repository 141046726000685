 
 

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SelecionarContribInternaListComponent } from './selecionar-contrib-interna-list/selecionar-contrib-interna-list.component';
import { SelecionarContribInternaComponent } from './selecionar-contrib-interna.component';

const routes: Routes = [
  {path: 'selecionar-contrib-interna', component: SelecionarContribInternaComponent},
  {path: 'app-selecionar-contrib-interna-list', component: SelecionarContribInternaListComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SelecionarContribInternaRoutingModule { }
