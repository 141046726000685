
import {createAction, createReducer, on, props} from '@ngrx/store';
import {ConfigContaRegraCriacao} from '../models/config-conta-regra-criacao';
import {ConfigConta} from '../models/config-conta';

const modelInit = new ConfigContaRegraCriacao();
modelInit.configConta = new ConfigConta();

export class IContasCorrentesTabViewState {
    configContaRegraCriacao: ConfigContaRegraCriacao;
}

export const contasCorrentesTabViewInitialState: IContasCorrentesTabViewState = {
    configContaRegraCriacao: {
        ...modelInit
    }
};

export const setConfigConta = createAction('[ContasCorrentesTabView] Seta configContas', props<{ cc: ConfigConta }>());
export const setConfigContaRegraCriacao = createAction('[ContasCorrentesTabView] Seta configContaRegraCriacao', props<{ ccrc: ConfigContaRegraCriacao }>());

export const contasCorrentesTabViewReducer = createReducer(
    contasCorrentesTabViewInitialState,
    on(setConfigConta, (state, {cc}) => {
        state = {
            ...state,
            configContaRegraCriacao: {
                ...state.configContaRegraCriacao,
                configConta: cc
            }
        };
        return state;
    }),
    on(setConfigContaRegraCriacao, (state, {ccrc}) => {
        state = {
            ...state,
            configContaRegraCriacao: ccrc
        };
        return state;
    }),

);
