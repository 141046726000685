import {EventoPagamentoTransacaos} from './../../../models/evento-pagamento-transacoes';
import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Pagination} from 'src/app/shared/pagination/pagination';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';
import * as moment from 'moment';
import swal from 'sweetalert2';
import Swal from 'sweetalert2';
import {Message} from 'primeng/api';
import {
    EventoPagamentoTransacaoFinanceiraModalService
} from '../evento-pagamento-transacao-financeira-modal/evento-pagamento-transacao-financeira-modal.service';
import {
    EventoPagamentoRegraImputacaoModalService
} from './../evento-pagamento-regra-imputacao-modal/evento-pagamento-regra-imputacao-modal.service';
import {
    EventoPagamentoParametroConfiguracaoModalService
} from '../evento-pagamento-parametro-configuracao-modal/evento-pagamento-parametro-configuracao-modal.service';
import {ConfigEventoPagamento} from 'src/app/models/config-evento-pagamento';
import {EventoPagtoRgrImputacaos} from 'src/app/models/evento-pagamento-regra-imputacoes';
import {EventoPagamentoReceitas} from 'src/app/models/evento-pagamento-receitas';
import {EventoPagamentoService} from '../evento-pagamento.service';
import {AlertsUtil} from 'src/app/utils/alerts/alerts.util';
import {UtilsService} from '../../../shared/utils.service';
import {EventoPagamentoReceitaDTO} from '../../../models/evento-pagamento-receita-dto';

@Component({
  selector: 'app-evento-pagamento-cadastro',
  templateUrl: './evento-pagamento-cadastro.component.html',
  styleUrls: ['./evento-pagamento-cadastro.component.scss'],
  providers: [
    EventoPagamentoTransacaoFinanceiraModalService,
    EventoPagamentoRegraImputacaoModalService,
    EventoPagamentoParametroConfiguracaoModalService
    ]
})
export class EventoPagamentoCadastroComponent implements OnInit {

    @Input() configContaReceita: EventoPagamentoReceitas;
    blockedDocument = false;
    pagination: Pagination<any> = new Pagination();
    msgs1: Message[];
    situacoes: any[];
    situacao: string;
    receitas: EventoPagamentoReceitas[];
    tributos: any[];
    tributo: any;
    contasReceita: any[];
    contaReceita: string;
    transacoesPagamento: any[];
    transacaoPagamento: string;
    dataInicioVigencia: string;
    dataFimVigencia: string;
    checkboxDisabled = false;
    receitaTributaria: number;
    dateStart: Date;
    dateEnd: Date;
    request: any;
    eventoPagamentoCompleto: any;
    eventoPagamento: ConfigEventoPagamento;
    eventoPagamentoTransacao: EventoPagamentoTransacaos;
    eventoPagamentoTransacaos: EventoPagamentoTransacaos[];
    eventoPagtoRgrImputacao: EventoPagtoRgrImputacaos;
    eventoPagamentoReceitas: EventoPagamentoReceitas[];
    eventoPagamentoReceita: EventoPagamentoReceitas;
    eventoPagamentoTransacaosLength: number;
    regraImputacaoLength: number;
    receitaLength: number;
    indexEditarTransacao: any;
    eventoPagamentoAux: string;
    eventoPagamentoTransacaoIdAux: any = null;
    transacaoPagamentoEdit: any;
    regraImputacaoEdit: any;
    regraImputacaoAux: any;
    regraImputacaoIdAux: any;
    indexEditarImputacao: any;

    constructor(
        private http: HttpClient,
        private router: Router,
        private eventoPagamentoTransacaoFinanceiraModalService: EventoPagamentoTransacaoFinanceiraModalService,
        private eventoPagamentoRegraImputacaoModalService: EventoPagamentoRegraImputacaoModalService,
        private eventoPagamentoParametroConfiguracaoModalService: EventoPagamentoParametroConfiguracaoModalService,
        private pagamentoService: EventoPagamentoService,
        private alertUtil: AlertsUtil,
        private utilsService: UtilsService,
        private route: Router
        ) {
           this.request = this.router.getCurrentNavigation().extras;
        }

    ngOnInit(): void {
        this.eventoPagamentoTransacaosLength = 0;
        this.regraImputacaoLength = 0;
        this.receitaLength = 0;
        this.receitaTributaria = 1;
        this.onPageChange({ page: 1 });
        this.carregaSituacoes();
        this.carregaTributos();
        this.eventoPagamento = new ConfigEventoPagamento();

        this.eventoPagamento.dataInicioVigencia = '';
        this.eventoPagamento.dataFimVigencia = '';
        this.eventoPagamento.implantado = 'NAO';
        if (this.request.state) {
            this.getPagamento(this.request.state.eventoPagamento.eventoPagamentoId);
        } else {
            this.eventoPagamento.situacao = 'ATIVA';
        }
    }

    getPagamento(eventoPagamentoId){
        this.getById(eventoPagamentoId).subscribe((r) => {
            this.eventoPagamentoCompleto = r;
            this.eventoPagamento = this.eventoPagamentoCompleto;
            this.dateStart = this.eventoPagamento.dataInicioVigencia ? moment(this.eventoPagamento.dataInicioVigencia).toDate() : null
            this.dateEnd = this.eventoPagamento.dataFimVigencia ? moment(this.eventoPagamento.dataFimVigencia).toDate() : null
        });
    }

    getById(id): Observable<any> {
        return this.http.get<any>(`${environment.baseUrl}/evento-pagamento/find-one/${id}`);
    }
    carregaNextId(): void {
        this.getNextId().subscribe(rnt => {
            this.eventoPagamento.eventoPagamentoId = Number(rnt);
        });
    }

    getNextId(): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${environment.baseUrl}/evento-pagamento/next-id`);
    }

    carregaReceitaNaoTributada(): void {
        this.getReceitaNaoTributada().subscribe(rnt => {
            this.eventoPagamentoReceitas = rnt;
        });
    }
    onChange(tributo: any){
        if (tributo.value) {
            this.carregaReceitaTributada(tributo.value.codTributo);
        } else {
            this.eventoPagamentoReceitas = [];
            this.eventoPagamentoReceita = null
        }
    }

    onChangeReceitaTributaria(event) {
        const checked = event.checked;
        if (checked === 0) {
            this.tributo = null
            this.carregaReceitaNaoTributada();
        }
    }

    carregaReceitaTributada(tributo: number): void {
        this.getReceitaTributada(tributo).subscribe(rnt => {
            this.eventoPagamentoReceitas = rnt;
        });
    }

    getReceitaNaoTributada(): Observable<Array<any>> {
        // return this.http.get<Array<any>>(`${environment.baseUrl}/receita?codTributo=0&codTributoId=1`);
        return this.http.get<Array<any>>(`${environment.baseUrl}/receita/search?tributaria=0`);
    }

    getReceitaTributada(codTributo: number): Observable<Array<any>> {
        // return this.http.get<Array<any>>(`${environment.baseUrl}/receita?codTributo=1&codTributoId=${idTributo}`);
        return this.http.get<Array<any>>(`${environment.baseUrl}/receita/search?tributaria=1&vinculoConta=1&codTributo=${codTributo}`);
        //tributaria, vinculoConta, tributoId
    }
    carregaTributos(): void {
        this.getTributos().subscribe(t => {
            this.tributos = t;
        });
    }

    getTributos(): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${environment.baseUrl}/tributo`);
    }

    carregaSituacoes(): void {
        this.getSituacoes().subscribe(s => {
            this.situacoes = s;
        });
    }
    getSituacoes(): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${environment.baseUrl}/enums/config-conta/situacao`);
    }
    onPageChange(e) {
        this.pagination.pageable.pageNumber = e.page;
    }

    novaReceita(): void {
        this.configContaReceita = this.eventoPagamentoReceita;

        const service = this.eventoPagamentoParametroConfiguracaoModalService

        if (this.eventoPagamento.eventoPagamentoReceitaDTOs.length === 0) {
            service.dateStart = this.dateStart;
        } else {
            service.dateStart = null;
        }

        service.eventoPagamento = JSON.parse(JSON.stringify(this.eventoPagamento))
        service.eventoPagamentoReceitaDTOLast =
            this.eventoPagamento
                .eventoPagamentoReceitaDTOs[0] || null

        service.eventoPagamentoReceita = this.eventoPagamentoReceita;
        service.displayMaximizable = true;
    }

    editarTransacao(pagamento: any, indexEditarTransacao): void {
        this.eventoPagamentoAux = 'EDIT';
        this.eventoPagamentoTransacaoIdAux = pagamento?.eventoPagamentoTransacaoId;
        this.indexEditarTransacao = indexEditarTransacao;
        this.eventoPagamento.dataInicioVigencia = pagamento.dataInicioVigencia;
        this.eventoPagamentoTransacao = pagamento;
        this.transacaoPagamentoEdit = pagamento;
        this.eventoPagamentoTransacaoFinanceiraModalService.dateStart = moment(pagamento.dataInicioVigencia).toDate()
        this.eventoPagamentoTransacaoFinanceiraModalService.displayMaximizable = true;
    }
    
    editaReceita(receita: any): void {
        this.configContaReceita = receita;
        this.configContaReceita['nomeReceita'] = receita.eventoPagtoReceitaContas[0].configContaReceita.receita.nomeReceita;
        this.eventoPagamentoParametroConfiguracaoModalService.displayMaximizable = true;
    }

    novaTransacao(): void {
        const service = this.eventoPagamentoTransacaoFinanceiraModalService;

        this.transacaoPagamentoEdit = new EventoPagamentoTransacaos();
        this.eventoPagamentoAux = null;
        this.eventoPagamentoTransacaoIdAux = null;

        if (this.eventoPagamento.eventoPagamentoTransacaos.length === 0) {
            service.dateStart = this.dateStart;
        } else {
            service.dateStart = null;
        }

        service.eventoPagamento = JSON.parse(JSON.stringify(this.eventoPagamento))

        service.eventoPagamentoTransacaoLast =
            this.eventoPagamento
            .eventoPagamentoTransacaos[0] || null

        service.displayMaximizable = true;
    }

    novaImputacao(): void {
        this.regraImputacaoEdit = new EventoPagtoRgrImputacaos();
        this.regraImputacaoAux = null;
        this.regraImputacaoIdAux = null;

        const service = this.eventoPagamentoRegraImputacaoModalService

        if (this.eventoPagamento.eventoPagtoRgrImputacaos.length === 0) {
            service.dateStart = this.dateStart;
        } else {
            service.dateStart = null;
        }

        service.eventoPagamento = JSON.parse(JSON.stringify(this.eventoPagamento));
        service.eventoPagtoRgrImputacaoLast =
            this.eventoPagamento
                .eventoPagtoRgrImputacaos[0] || null
        service.displayMaximizable = true;
    }

    editarImputacao(imputacao: any, index) {
        this.regraImputacaoAux = 'EDIT';
        this.indexEditarImputacao = index;
        this.regraImputacaoIdAux = imputacao.eventoPagtoRgrImputacaoId;
        this.regraImputacaoEdit = imputacao;
        this.eventoPagtoRgrImputacao = imputacao; 
        this.eventoPagamentoRegraImputacaoModalService.dateStart = moment(imputacao.dataInicioVigencia).toDate()
        this.eventoPagamentoRegraImputacaoModalService.displayMaximizable = true;
    }

    removePagamentoTrasacao(index): Observable<any> {
        this.eventoPagamento.eventoPagamentoTransacaos.splice(index, 1);
        this.eventoPagamentoTransacaosLength = this.eventoPagamento.eventoPagamentoTransacaos.length;
        if (this.eventoPagamentoTransacaosLength) {
            this.eventoPagamento.eventoPagamentoTransacaos[0].dataFimVigencia = null;
        }
        return  new Observable((e) => {true});
    }

    removeTabelaReceita(index: any): Observable<any>  {
        this.eventoPagamento.eventoPagamentoReceitas.splice(index, 1);
        this.eventoPagamento.eventoPagamentoReceitaDTOs.splice(index, 1);
        this.receitaLength = this.eventoPagamento.eventoPagamentoReceitas.length;
        return  new Observable((e) => {true});
    }

    removePagamentoImputado(index): Observable<any> {
        this.eventoPagamento.eventoPagtoRgrImputacaos.splice(index, 1);
        this.regraImputacaoLength = this.eventoPagamento.eventoPagtoRgrImputacaos.length;
        if (this.regraImputacaoLength) {
            this.eventoPagamento.eventoPagtoRgrImputacaos[0].dataFimVigencia = null;
        }
        return  new Observable((e) => {true});
    }

    inserePagamento(pagamento: any): void {
        this.http
        .post<any>(
            `${environment.baseUrl}/evento-pagamento`, pagamento,

        ).subscribe(
            (response) => {
                swal.fire(
                    'Sucesso',
                    `Evento de Pagamento ${response.eventoPagamentoId} Cadastrado com sucesso`,
                    'success'
                ).then((r) => this.search());
            },
            (error) => {
                if (error.status === 500 || error.status === 404) {
                    swal.fire(
                        error.statusText,
                        'Ocorreu um erro interno',
                        'error'
                    ).then((r) => {});
                } else if (error.status === 412) {
                    swal.fire(
                        'Erro de Validação',
                        error.error.message,
                        'error'
                    ).then((r) => {});
                } else if (error.status === 400) {
                    const errors = error.error.errors;
                    this.msgs1 = [];
                    errors.forEach((e) => {
                        this.msgs1.push({
                            severity: 'error',
                            summary: 'Erro',
                            detail: e.message,
                        });
                    });
                }
            }
        );
    }

    atualizaPagamento(pagamento: any): void {
        this.http
        .put<any>(
            `${environment.baseUrl}/evento-pagamento`, pagamento,

        ).subscribe(
            (response) => {
                swal.fire(
                    'Sucesso',
                    `Evento de pagamento ${response.eventoPagamentoId} alterado com sucesso`,
                    'success'
                ).then((r) => this.search());
            },
            (error) => {
                if (error.status === 500 || error.status === 404) {
                    swal.fire(
                        error.statusText,
                        'Ocorreu um erro interno',
                        'error'
                    ).then((r) => {});
                } else if (error.status === 412) {
                    swal.fire(
                        'Erro de Validação',
                        error.error.message,
                        'error'
                    ).then((r) => {});
                } else if (error.status === 400) {
                    const errors = error.error.errors;
                    this.msgs1 = [];
                    errors.forEach((e) => {
                        this.msgs1.push({
                            severity: 'error',
                            summary: 'Erro',
                            detail: e.message,
                        });
                    });
                }
            }
        );
    }

    excluiTransacao(index, transacao: any){
        const eventoPagamentoId = this.eventoPagamento.eventoPagamentoId;
        const eventoPagamentoTransacaoId = transacao.eventoPagamentoTransacaoId;
        this.alertUtil.confirm(`Deseja excluir a associação da transação: [${transacao.transacao.transacaoId} - ${transacao.transacao.nomeResumido}] com o evento de pagamento?`
        ).then((result) => {
            if (result.value) {
                this.removePagamentoTrasacao(index);
            }
        });
    }

    excluiImputacao(index, imputacao: any){
        const eventoPagamentoId = this.eventoPagamento.eventoPagamentoId;
        const eventoPagtoRgrImputacaoId = imputacao.eventoPagtoRgrImputacaoId;
        this.alertUtil.confirm(`Deseja excluir a associação da regra de imputação: 
        [${imputacao.regraImputacao.id} - ${imputacao.regraImputacao.descricao}] com o evento de pagamento?`)
        .then((result) => {
            if (result.value) {
                this.removePagamentoImputado(index);
            } else {
                result.dismiss === swal.DismissReason.cancel;
            }
        });
    }

    excluiReceita(index, receita: any){
        const eventoPagamentoId = this.eventoPagamento?.eventoPagamentoId;
        const eventoPagtoRgrImputacaoId = receita.eventoPagamentoReceitaId;
        // Deseja excluir a associação da receita:
        //  [${receita.codReceita} - ${receita.eventoPagtoReceitaContas[0]?.configContaReceita.receita.nomeReceita}] com o evento de pagamento?`
        this.alertUtil.confirm(`
            Deseja excluir a associação da receita:
             [${receita.codReceita} - ${receita.nomeReceita}] com o evento de pagamento?`
            ).then((result) =>{
                if (result.value) {
                    // this.removeTabelaReceita(index);
                    if(eventoPagtoRgrImputacaoId){
                        this.pagamentoService
                            .deleteReceita(eventoPagamentoId, eventoPagtoRgrImputacaoId)
                            .subscribe(resp => {
                                this.alertUtil.saveSuccess(`Excluído com sucesso`).then((resp) => {
                                    if(resp.isConfirmed) {
                                        this.getPagamento(eventoPagamentoId);
                                    }
                                 });
                             },
                            erro => {
                                this.alertUtil.handleError(erro);
                            }
                        )
                    } else {
                        this.removeTabelaReceita(index);
                    }
                } else {
                    result.dismiss === swal.DismissReason.cancel;
                }
            })
    }

    scrollToTop() {
        (function smoothscroll() {
            const currentScroll =
                document.documentElement.scrollTop || document.body.scrollTop;
            if (currentScroll > 0) {
                window.requestAnimationFrame(smoothscroll);
                window.scrollTo(0, currentScroll - currentScroll / 8);
            }
        })();
    }

    search() {
        this.blockedDocument = true;
        this.router.navigateByUrl('evento-pagamento/evento-pagamento-list');
    }

    onSelectDataInicioVigencia(event) {
        if (event) {
            this.eventoPagamento.dataInicioVigencia = moment(event).format('YYYY-MM-DD');
        } else {
            this.eventoPagamento.dataInicioVigencia = '';
        }
        return  this.eventoPagamento.dataInicioVigencia;
    }

    onSelectDataFimVigencia(event) {
        if (event) {
            this.eventoPagamento.dataFimVigencia = moment(event).format('YYYY-MM-DD');
        } else {
            this.eventoPagamento.dataFimVigencia = '';
        }
        return this.eventoPagamento.dataFimVigencia;
    }

    convertDateBr(data) {
        return data == null ? '' : moment(data).format('YYYY-MM-DD');
    }

    eventoPagamentoSelecionado(evento: EventoPagamentoTransacaos): any {
        // const duplicated = this.eventoPagamento.eventoPagamentoTransacaos.some((r: any) => {
        //     return r.transacao.transacaoId == evento.transacao.transacaoId
        // })
        //
        // if (duplicated && this.eventoPagamentoAux !== 'EDIT') {
        //     return Swal.fire("Erro", "Regra já adicionada", "error")
        //         .then()
        // }

        if (this.eventoPagamento.eventoPagamentoTransacaos.length > 0) {
            const dataInicioRegraAnterior = this.eventoPagamento
                .eventoPagamentoTransacaos[0]
                .dataInicioVigencia

            const dataInicioRegraNova = evento.dataInicioVigencia

            if (this.utilsService.diffInDays(dataInicioRegraNova, dataInicioRegraAnterior) <= 0) {
                const x: any = this.eventoPagamento.eventoPagamentoTransacaos[0]
                const descricaoRegra = x.transacao.nomeResumido
                return Swal.fire("Atenção", `Início da vigência informado deve ser maior que o início da vigência da regra anterior: ${descricaoRegra} - Início da Vigência ${this.utilsService.convertToDateBr(dataInicioRegraAnterior)}`, "warning")
            }

            this.eventoPagamento
                .eventoPagamentoTransacaos[0]
                .dataFimVigencia = this.utilsService.subtractDays(dataInicioRegraNova, 1);
        }


        evento.eventoPagamentoTransacaoId = this.eventoPagamentoTransacaoIdAux;

        if(this.eventoPagamentoAux === 'EDIT'){
            const transacao: any = this.eventoPagamento.eventoPagamentoTransacaos;
                this.eventoPagamento.eventoPagamentoTransacaos.splice(this.indexEditarTransacao, 1);
        }

        this.eventoPagamento.eventoPagamentoTransacaos.push(evento);

        this.eventoPagamento
            .eventoPagamentoTransacaos
            .sort((a, b) => {
                if (a.dataFimVigencia === null || a.dataFimVigencia === "") {
                    return -1;
                } else if (b.dataFimVigencia === null || b.dataFimVigencia === "") {
                    return 1;
                }
                return 0;
            })
        this.eventoPagamentoTransacaosLength = this.eventoPagamento.eventoPagamentoTransacaos.length;
    }

    removeContaContempladaPorId(id) {
        return this.eventoPagamento.eventoPagamentoTransacaos.filter(
            (item: any) => item.transacao.transacaoId !== id
        );
    }

  

    eventoPagamentoImputadoSelecionado(evento): any {
        // const duplicated = this.eventoPagamento.eventoPagtoRgrImputacaos.some((r: any) => {
        //     return r.regraImputacao.id == evento.regraImputacao.id
        // })
        //
        // if (duplicated && this.regraImputacaoAux !== 'EDIT') {
        //     return Swal.fire("Erro", "Regra já adicionada", "error")
        //         .then()
        // }

        if (this.eventoPagamento.eventoPagtoRgrImputacaos.length > 0) {
            const dataInicioRegraAnterior = this.eventoPagamento
                .eventoPagtoRgrImputacaos[0]
                .dataInicioVigencia

            const dataInicioRegraNova = evento.dataInicioVigencia

            if (this.utilsService.diffInDays(dataInicioRegraNova, dataInicioRegraAnterior) <= 0) {
                const x: any = this.eventoPagamento.eventoPagtoRgrImputacaos[0]
                const descricaoRegra = x.regraImputacao.descricao
                return Swal.fire("Atenção", `Início da vigência informado deve ser maior que o início da vigência da regra anterior: ${descricaoRegra} - Início da Vigência ${this.utilsService.convertToDateBr(dataInicioRegraAnterior)}`, "warning")
            }

            this.eventoPagamento
                .eventoPagtoRgrImputacaos[0]
                .dataFimVigencia = this.utilsService.subtractDays(dataInicioRegraNova, 1);

        }

        evento.eventoPagtoRgrImputacaoId = this.regraImputacaoIdAux;
        if(this.regraImputacaoAux === 'EDIT'){
            this.eventoPagamento.eventoPagtoRgrImputacaos.splice(this.indexEditarImputacao, 1);
        }
        this.eventoPagamento.eventoPagtoRgrImputacaos.push(evento);
        this.eventoPagamento
            .eventoPagtoRgrImputacaos
            .sort((a, b) => {
                if (a.dataFimVigencia === null || a.dataFimVigencia === "") {
                    return -1;
                } else if (b.dataFimVigencia === null || b.dataFimVigencia === "") {
                    return 1;
                }
                return 0;
            })

        this.regraImputacaoLength = this.eventoPagamento.eventoPagtoRgrImputacaos.length;
    }

    pagamentoReceitaEditada(evento): any {
        const duplicated = this.eventoPagamento.eventoPagamentoReceitaDTOs.some(r => {
            return r.codReceita == evento.codReceita && (!r.dataFimVigencia)
        })

        if (duplicated) {
            Swal.fire("Erro", "Já adicionado", "error").then()
        } else {

            if (this.eventoPagamento.eventoPagamentoReceitaDTOs.length > 0) {
                const dataInicioRegraAnterior = this.eventoPagamento
                    .eventoPagamentoReceitaDTOs[0]
                    .dataInicioVigencia

                const dataInicioRegraNova = evento.dataInicioVigencia

                if (this.utilsService.diffInDays(dataInicioRegraNova, dataInicioRegraAnterior) <= 0) {
                    const x: any = this.eventoPagamento.eventoPagamentoReceitaDTOs[0]
                    const descricaoRegra = x.nomeReceita
                    return Swal.fire("Atenção", `Início da vigência informado deve ser maior que o início da vigência da regra anterior: ${descricaoRegra} - Início da Vigência ${this.utilsService.convertToDateBr(dataInicioRegraAnterior)}`, "warning")
                }

                this.eventoPagamento
                    .eventoPagamentoReceitaDTOs[0]
                    .dataFimVigencia = this.utilsService.subtractDays(dataInicioRegraNova, 1);

                this.eventoPagamento
                    .eventoPagamentoReceitas[0]
                    .dataFimVigencia = this.utilsService.subtractDays(dataInicioRegraNova, 1);
            }

            this.eventoPagamento.eventoPagamentoReceitas.push(evento);

            const eventoPagamentoReceitaDto = new EventoPagamentoReceitaDTO();
            eventoPagamentoReceitaDto.codReceita = evento.codReceita
            eventoPagamentoReceitaDto.codTributo = evento.eventoPagtoReceitaContas[0].configContaReceita.receita.codTributo
            eventoPagamentoReceitaDto.dataInicioVigencia = evento.dataInicioVigencia
            eventoPagamentoReceitaDto.dataFimVigencia = evento.dataFimVigencia
            eventoPagamentoReceitaDto.nomeReceita = evento.eventoPagtoReceitaContas[0].configContaReceita.receita.nomeReceita
            eventoPagamentoReceitaDto.situacao = evento.situacao

            this.eventoPagamento.eventoPagamentoReceitaDTOs.push(eventoPagamentoReceitaDto);
            this.eventoPagamento
                .eventoPagamentoReceitaDTOs
                .sort((a, b) => {
                    if (a.dataFimVigencia === null || a.dataFimVigencia === "" || a.dataFimVigencia === undefined) {
                        return -1;
                    } else if (b.dataFimVigencia === null || b.dataFimVigencia === "" || b.dataFimVigencia === undefined) {
                        return 1;
                    }
                    return 0;
                })

            this.eventoPagamento
                .eventoPagamentoReceitas
                .sort((a, b) => {
                    if (a.dataFimVigencia === null || a.dataFimVigencia === "" || a.dataFimVigencia === undefined) {
                        return -1;
                    } else if (b.dataFimVigencia === null || b.dataFimVigencia === "" || b.dataFimVigencia === undefined) {
                        return 1;
                    }
                    return 0;
                })

            this.receitaLength = this.eventoPagamento.eventoPagamentoReceitas.length;
        }

    }
    salvar(){
        if (this.request.state) {
            this.atualizaPagamento(this.eventoPagamento);
        } else {
            this.inserePagamento(this.eventoPagamento);
        }
    }

    voltar() {
        this.route
            .navigateByUrl('/evento-pagamento/evento-pagamento-list')
            .then();
    }

    disableInicioVigencia(): boolean {
        const transacoes = this.eventoPagamento.eventoPagamentoTransacaos.length > 0
        const imputacoes = this.eventoPagamento.eventoPagtoRgrImputacaos.length > 0
        const implantado = this.eventoPagamento.implantado === 'SIM'
        return (transacoes || imputacoes || implantado)
    }

    disableAdicionarReceita(): boolean {
        const inicioVigencia = this.dateStart
        const receitaTributaria = this.receitaTributaria
        const receita = this.eventoPagamentoReceita
        const tributo = this.tributo
        const result = inicioVigencia != null && (receitaTributaria == 0  && receita != null ||
            receitaTributaria == 1 && tributo != null && receita != null);
        return !result
    }
}