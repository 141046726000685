
export class ConfigContaRegraCriacaoSaveRequest {
    configContaId: number
    configContaRegraCriacaoId: number
    dataFimVigencia: string
    dataInicioVigencia: string
    regraCriacaoContaId: number
    situacao: string

   constructor() {
        this.configContaId = null;
        this.configContaRegraCriacaoId = null;
        this.dataFimVigencia = '';
        this.dataInicioVigencia = '';
        this.regraCriacaoContaId = null;
        this.situacao = ''
    }
}
