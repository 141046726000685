<div class="card">
    <p-toast></p-toast>
    <p-steps [model]="items" [readonly]="true"></p-steps>
</div>

<div class="stepsdemo-content">
    <p-card>
        <ng-template pTemplate="title"> Receita </ng-template>

        <ng-template pTemplate="header">
            <div class="grid grid-nogutter justify-content-end" [style]="{padding: '10px'}">
                <p-button
                        label="Anterior"
                        (onClick)="prevPage()"
                        icon="pi pi-angle-left"
                ></p-button>
                <p-button
                        [loading]="isLoading"
                        *ngIf="!configConta.configContaId"
                        class="float-btn-next"
                        label="Salvar Conta"
                        (onClick)="salvarContaAll()"
                        iconPos="right"
                        styleClass="p-button-success"
                >
                </p-button>
                <p-button
                        class="float-btn-next margin-right"
                        label="Próxima"
                        (onClick)="nextPage()"
                        icon="pi pi-angle-right"
                        iconPos="right"
                        [disabled]="!configConta.configContaId"
                ></p-button>
            </div>
        </ng-template>

        <ng-template pTemplate="content">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12">
                    <app-identificacao-conta
                        [configConta]="configConta"
                    ></app-identificacao-conta>
                </div>
            </div>
            <form [formGroup]="formGroupReceita" (ngSubmit)="onSubmit()">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12">
                        <p-fieldset legend="Receitas Associados">
                            <div class="p-field p-col-12">
                                <label>Receita *</label>
                                <p-dropdown
                                    formControlName="receita"
                                    showClear="true"
                                    optionLabel="displayLabel"
                                    [autoDisplayFirst]="false"
                                    [options]="service.receitas"
                                    [disabled]="isEdit"
                                >
                                </p-dropdown>
                                <p class="erro-validacao" *ngIf="(receitaFormControl.receita.touched ||submitted) && receitaFormControl.receita.errors?.required">
                                    Campo obrigatório
                                </p>
                            </div>
                            <div style="margin-top: 20px">
                                <p-fieldset legend="Situação e Vigência da Associação">
                                    <div style="text-align: -webkit-left" class="p-fluid p-formgrid p-grid">
                                        <div class="p-field p-col-2">
                                            <label>Situação *</label>
                                            <p-dropdown
                                                formControlName="situacao"
                                                showClear="true"
                                                [optionLabel]="'label'"
                                                [autoDisplayFirst]="false"
                                                [options]="status"
                                                [dataKey]="'label'"
                                                [disabled]="!configConta?.configContaId || configConta.implantado === 'NAO' || !contaCorrenteService.configReceita.configContaReceitaId"
                                            >
                                            </p-dropdown>

                                            <p class="erro-validacao" *ngIf="receitaFormControl.situacao.invalid && submitted">
                                                Campo obrigatório
                                            </p>
                                        </div>
                                        <div class="p-field p-col-3">
                                            <label>Início da Vigência* </label>
                                            <p-calendar
                                                formControlName="dataInicioVigencia"
                                                [showIcon]="true"
                                                inputId="icon"
                                                dateFormat="dd/mm/yy"
                                                name="dataInicioVigencia"
                                                [disabled]="disableDataInicioVigencia"
                                                (ngModelChange)="onChangeDataInicioVigencia($event)"
                                            >
                                            </p-calendar>
                                            <p class="erro-validacao" *ngIf="receitaFormControl.dataInicioVigencia.invalid && submitted">
                                                Campo obrigatório
                                            </p>
                                        </div>
                                        <div class="p-field p-col-3">
                                            <label>Fim da Vigência</label>
                                            <p-calendar
                                                formControlName="dataFimVigencia"
                                                [showIcon]="true"
                                                inputId="icon"
                                                dateFormat="dd/mm/yy"
                                                name="dataFimVigencia"
                                            >
                                            </p-calendar>
                                        </div>
                                    </div>

                                    <div style="text-align: -webkit-left" class="p-fluid p-formgrid p-grid">
                                        <div class="p-field p-col-1 p-md-3"></div>
                                        <div class="p-field p-col-1 p-md-3"></div>
                                        <div class="p-field p-col-12 p-md-12" style="margin-top: 0; float: right">
                                            <div *ngIf="isEdit" class="p-field p-col-1 p-md-3" style="float: inherit">
                                                <button
                                                    pButton
                                                    type="button"
                                                    label="Cancelar"
                                                    icon="pi pi-times"
                                                    class="p-button-secondary"
                                                    (click)="cancelarEdicao()"
                                                ></button>
                                            </div>
                                            <div class="p-field p-col-1 p-md-3" style="float: inherit">
                                                <button
                                                    [disabled]="!configConta?.configContaId"
                                                    type="submit"
                                                    pButton
                                                    [label]="btnSalvarAtualizar"
                                                    [icon]="this.icone"
                                                ></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-field col-12">
                                        <p-table [value]="configReceitaConta">
                                            <ng-template pTemplate="header">
                                                <tr>
                                                    <th>Código</th>
                                                    <th>Nome da Receita</th>
                                                    <th>Situação</th>
                                                    <th>Inicio Vigência</th>
                                                    <th>Fim Vigência</th>
                                                    <th>Ações</th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-configReceitaConta>
                                                <tr>
                                                    <td>{{configReceitaConta.receita.codReceita}}</td>
                                                    <td>{{configReceitaConta.receita.nomeReceita}}</td>
                                                    <td>{{configReceitaConta.situacao}}</td>
                                                    <td>{{convertDateBr(configReceitaConta.dataInicioVigencia)}}</td>
                                                    <td>{{convertDateBr(configReceitaConta.dataFimVigencia)}}</td>
                                                    <td>
                                                        <button
                                                            pButton
                                                            pRipple
                                                            title="Editar"
                                                            type="button"
                                                            icon="pi pi-pencil"
                                                            class="p-button-rounded"
                                                            (click)="editarItemReceita(configReceitaConta)"
                                                        ></button>

                                                        <button
                                                            *ngIf="configConta.implantado =='SIM'"
                                                            pButton
                                                            pRipple
                                                            title="{{ configReceitaConta.situacao === 'ATIVA' ? 'Inativar' : 'Ativar' }}"
                                                            type="button"
                                                            icon="{{ configReceitaConta.situacao === 'ATIVA' ? 'pi pi-times' : 'pi pi-check' }}"
                                                            class="p-button-rounded p-button-secondary"
                                                            (click)="removeConfirm(configReceitaConta.configContaReceitaId, configReceitaConta)"
                                                        ></button>
                                                        <button
                                                        *ngIf="configConta.implantado =='NAO'"
                                                            pButton
                                                            pRipple
                                                            title="Excluir"
                                                            type="button"
                                                            icon="pi pi-trash"
                                                            class="p-button-rounded p-button-danger"
                                                            (click)="removeConfigContaReceitaConfirm(configReceitaConta.configContaReceitaId, configReceitaConta)"
                                                        ></button>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="emptymessage">
                                                <tr>
                                                    <td>Nenhum registro localizado</td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                        <p-paginator
                                            [rows]="10"
                                            [totalRecords]="pagination.totalElements"
                                            (onPageChange)="onPageChange($event)"
                                        ></p-paginator>
                                    </div>
                                </p-fieldset>
                            </div>
                        </p-fieldset>
                    </div>
                </div>
            </form>
        </ng-template>

        <ng-template pTemplate="footer">
            <div class="grid grid-nogutter justify-content-end">
                <p-button
                    label="Anterior"
                    (onClick)="prevPage()"
                    icon="pi pi-angle-left"
                ></p-button>
                <p-button
                    [loading]="isLoading"
                    *ngIf="!configConta.configContaId"
                    class="float-btn-next"
                    label="Salvar Conta"
                    (onClick)="salvarContaAll()"
                    iconPos="right"
                    styleClass="p-button-success"
                >
                </p-button>
                <p-button
                    class="float-btn-next margin-right"
                    label="Próxima"
                    (onClick)="nextPage()"
                    icon="pi pi-angle-right"
                    iconPos="right"
                    [disabled]="!configConta.configContaId"
                ></p-button>
            </div>
        </ng-template>
    </p-card>
</div>
