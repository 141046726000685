<p-card *ngIf="!carregandoEvento">
    <p-steps
        [model]="items"
        [activeIndex]="1"
        [readonly]="false"
        class="time-line"
    ></p-steps>
</p-card>
<br />
<p-card [header]="tituloPagina">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-2">
            <label>Tipo Evento</label>
            <input
                style="border: none"
                [ngModel]="evento?.tipoEvento?.descricaoTipoEvento"
                readonly
                type="text"
                pInputText
            />
        </div>
        <div class="p-field p-col-12 p-md-3">
            <label>Fato</label>
            <input
                style="border: none"
                [ngModel]="evento?.fatoEvento?.descricao"
                readonly
                type="text"
                pInputText
            />
        </div>
        <div class="p-field p-col-12 p-md-3">
            <label>Operação</label>
            <input
                style="border: none"
                [value]="operacaoEnum"
                readonly
                type="text"
                pInputText
            />
        </div>
        <div class="p-field p-col-12 p-md-3">
            <label>Gera transação inicial</label>
            <input
                style="border: none"
                [value]="simNaoEnum"
                readonly
                type="text"
                pInputText
            />
        </div>
    </div>
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-2 p-md-2">
            <label>Código</label>
            <p-inputNumber
                [(ngModel)]="filtroTransacao.codigo"
                [useGrouping]="false"
            ></p-inputNumber>
        </div>
        <div class="p-field p-col-10 p-md-10">
            <label>Nome</label>
            <input
                type="text"
                pInputText
                [(ngModel)]="filtroTransacao.nome"
                maxlength="50"
            />
        </div>
    </div>
    <div
        style="
            margin-right: 8px;
            margin-top: 10px;
            margin-bottom: 10px;
            text-align: right !important;
        "
    >
        <button
            (click)="pesquisar()"
            pButton
            type="button"
            icon="pi pi-search"
            label="Pesquisar"
            class="p-mr-1 p-text-left botao-pesquisa"
        ></button>
        <button
            (click)="novaTransacao()"
            pButton
            type="button"
            icon="pi pi-plus-circle"
            label="Nova Transação"
            class="p-mr-1 p-text-left botao-adicionar"
            [disabled]="!podeCadastrarTransacao()"
        ></button>
    </div>
    <div class="p-field p-col-12">
        <p-fieldset legend="Lista de Transações">
            <div class="p-field p-col-12">
                <p-table
                    [value]="listaTransacoes"
                    [paginator]="true"
                    [rows]="10"
                    [showCurrentPageReport]="true"
                    responsiveLayout="scroll"
                    currentPageReportTemplate="{first} - {last} de {totalRecords}"
                    [rowsPerPageOptions]="[10, 30, 40, 50]"
                >
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Código</th>
                            <th>Nome Transação</th>
                            <th>Situação</th>
                            <th>Ações</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-transacao>
                        <tr>
                            <td>{{ transacao.transacaoId }}</td>
                            <td>{{ transacao.nomeResumido }}</td>
                            <td>{{ transacao.situacao }}</td>
                            <td>
                                <button
                                    pButton
                                    pRipple
                                    title="Editar"
                                    type="button"
                                    icon="pi pi-pencil"
                                    class="p-button-rounded p-button botao-editar"
                                    (click)="editarTransacao(transacao.transacaoId)"
                                    [disabled]="!podeEditarTransacao()"
                                ></button>
                                <button
                                    pButton
                                    pRipple
                                    title="Excluir"
                                    type="button"
                                    icon="pi pi-trash"
                                    class="p-button-rounded p-button-danger"
                                    style="margin-left: 3px"
                                    (click)="deletar(transacao)"
                                    [disabled]="!podeExcluirTransacao()"
                                ></button>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="12" style="text-align: center">
                                Nenhum registro localizado
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </p-fieldset>
    </div>
</p-card>
