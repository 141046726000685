import { Regra } from "./regra";

export class EventoPagtoReceitaRegras {
    dataInicioVigencia: string;
    dataFimVigencia: string;
    regra: Regra[];

    constructor(init?: Partial<EventoPagtoReceitaRegras>) {
        if (init) {
            Object.assign(this, init);
        } else {
            this.dataInicioVigencia = "";
            this.dataFimVigencia = "";
            this.regra = [];
        }
    }
}
