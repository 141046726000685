import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {ConfigContaUnidOrganiza} from '../../../models/config-conta-unid-organiza';
import {UnidadeOrganizacional} from '../../../models/unidade-organizacional';
import { Pagination } from 'src/app/shared/pagination/pagination';
import { Pageable } from 'src/app/shared/pagination/pageable';
import { UnidadeOrganizacionalSearchRequest } from 'src/app/request/unidade-organizacional-search-request';

@Injectable({
  providedIn: 'root'
})
export class ConfigContaUnidOrganizaTabViewService {
  configContaUnidOrganiza: ConfigContaUnidOrganiza;
  unidadeOrganizacional: UnidadeOrganizacional;
  constructor(
      private http: HttpClient
  )
  {
      this.configContaUnidOrganiza = new ConfigContaUnidOrganiza();
      this.configContaUnidOrganiza.unidadeOrganizacional = new UnidadeOrganizacional();

      this.unidadeOrganizacional = new UnidadeOrganizacional();
  }

    getSituacoes(): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${environment.baseUrl}/enums/config-conta/situacao`);
    }

    getConfigContaUnidOrganizaSearch(searchRequest: UnidadeOrganizacionalSearchRequest,
        pagination: Pagination<any>): Observable<Pageable<any>> {
        return this.http.get<Pageable<any>>(
           `${environment.baseUrl}/config-conta-unid-organiza/search`, {
               params: {
                   ...searchRequest,
                   page: pagination.pageable.pageNumber,
                   size: pagination.size
               }
           }
        );
    }

    ativaInativaUnidOrganiza (id: number): Observable<void> {
        return this.http.put<void>(`${environment.baseUrl}/config-conta-unid-organiza/${id}/ativa-inativa`, {});
    }

    deleteConfigUnidadeOrganiza(id: number): Observable<void> {
        return this.http.delete<void>(
            `${environment.baseUrl}/config-conta-unid-organiza/${id}`);
    }


}
