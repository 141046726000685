<div class="p-grid">
    <div class="p-col-12 p-d-flex p-jc-center">
        <h5>
            Configuração da Categoria
            {{ evento?.eventosId + " - " + evento?.descricao }}
        </h5>
    </div>
    <div class="p-col-12" *ngIf="implantado">
        <p-messages severity="info">
            <ng-template pTemplate>
                <div class="ml-2">
                    Não é permitido editar a configuração um evento implantado.
                </div>
            </ng-template>
        </p-messages>
    </div>
    <div class="p-col-12">
        <form [formGroup]="configuracaoForm">
            <div class="p-grid">
                <div class="p-col-4 p-fluid">
                    <label>Descrição*</label>
                    <input
                        type="text"
                        pInputText
                        formControlName="descricaoResumida"
                    />
                    <p
                        class="erro-validacao"
                        *ngIf="
                            (configuracaoForm.controls.descricaoResumida
                                .touched ||
                                validarCampos) &&
                            configuracaoForm.controls.descricaoResumida.errors
                                ?.required
                        "
                    >
                        Campo obrigatório
                    </p>
                </div>
                <div class="p-col-8 p-fluid">
                    <label>Descrição Completa*</label>
                    <input
                        type="text"
                        pInputText
                        formControlName="descricaoCompleta"
                    />
                    <p
                        class="erro-validacao"
                        *ngIf="
                            (configuracaoForm.controls.descricaoCompleta
                                .touched ||
                                validarCampos) &&
                            configuracaoForm.controls.descricaoCompleta.errors
                                ?.required
                        "
                    >
                        Campo obrigatório
                    </p>
                </div>
                <!-- <div class="p-col-4 p-fluid">
                    <label>Tipo de Processamento*</label>
                    <p-dropdown
                        [options]="tipoProcessamentoList"
                        formControlName="tipoProcessamento"
                        optionValue="chave"
                        optionLabel="valor"
                        [showClear]="true"
                        placeholder="Não selecionado"
                    ></p-dropdown>
                    <p
                        class="erro-validacao"
                        *ngIf="
                            (configuracaoForm.controls.tipoProcessamento
                                .touched ||
                                validarCampos) &&
                            configuracaoForm.controls.tipoProcessamento.errors
                                ?.required
                        "
                    >
                        Campo obrigatório
                    </p>
                </div> -->
                <div class="p-col-2 p-fluid">
                    <label>Início da Vigência</label>
                    <p-calendar
                        formControlName="dataInicioVigencia"
                        dateFormat="dd/mm/yy"
                        dataType="string"
                        [showIcon]="true"
                    ></p-calendar>
                </div>
                <div class="p-col-2 p-fluid">
                    <label>Fim da Vigência</label>
                    <p-calendar
                        formControlName="dataFimVigencia"
                        dateFormat="dd/mm/yy"
                        dataType="string"
                        [showIcon]="true"
                    ></p-calendar>
                </div>
                <div class="p-col-12"></div>

                <div
                    class="p-col-12 p-fluid"
                    paramEventoVisivel
                    [parametro]="tiposParam.P2_MUDA_SITUCAO_COBRANCA"
                >
                    <div class="p-grid">
                        <div
                            class="field-checkbox p-col-4 p-d-flex p-jc-flex-start p-ai-center"
                            style="gap: 5px"
                        >
                            <p-checkbox
                                formControlName="mudaSituacaoCobranca"
                                [binary]="true"
                                inputId="mudaSituacaoCobranca"
                            ></p-checkbox>
                            <label for="mudaSituacaoCobranca">
                                Muda o situação da cobrança pós evento?</label
                            >
                        </div>
                        <div
                            class="p-col-8 p-fluid"
                            *ngIf="configuracaoForm.value.mudaSituacaoCobranca"
                        >
                            <label>Situação da Cobrança pós evento*</label>
                            <p-dropdown
                                [options]="situacaoCobrancaList"
                                formControlName="situacaoCobrancaId"
                                optionValue="situacaoCobrancaId"
                                optionLabel="descricao"
                                [showClear]="true"
                                placeholder="Não selecionado"
                            ></p-dropdown>
                            <p
                                class="erro-validacao"
                                *ngIf="
                                    (configuracaoForm.controls
                                        .situacaoCobrancaId.touched ||
                                        validarCampos) &&
                                    configuracaoForm.controls.situacaoCobrancaId
                                        .errors?.required
                                "
                            >
                                Campo obrigatório
                            </p>
                        </div>
                    </div>
                </div>
                <div class="p-col-12"></div>
                <div
                    class="p-col-4"
                    paramEventoVisivel
                    [parametro]="[
                        tiposParam.P2_MUDA_SITUCAO_CREDITO,
                        tiposParam.P2_MUDA_ESTADO_CONTA
                    ]"
                >
                    <div class="p-col-12 p-fluid">
                        <label>Saldo da Conta</label>
                        <input type="text" pInputText value="Débito" disabled />
                    </div>
                    <div class="p-col-12 p-fluid">
                        <input
                            type="text"
                            pInputText
                            value="Crédito"
                            disabled
                        />
                    </div>
                    <div class="p-col-12 p-fluid">
                        <input type="text" pInputText value="Zero" disabled />
                    </div>
                </div>
                <div
                    class="p-col-4"
                    paramEventoVisivel
                    [parametro]="tiposParam.P2_MUDA_SITUCAO_CREDITO"
                >
                    <div class="p-col-12 p-fluid">
                        <label>Situação do Crédito Pós Evento</label>
                        <p-dropdown
                            [options]="situacaoCreditoList"
                            formControlName="situacaoDebitoPosEvento"
                            optionValue="situacaoCreditoId"
                            optionLabel="descricao"
                            [showClear]="true"
                            placeholder="Não selecionado"
                        ></p-dropdown>
                        <p
                            class="erro-validacao"
                            *ngIf="
                                (configuracaoForm.controls
                                    .situacaoDebitoPosEvento.touched ||
                                    validarCampos) &&
                                configuracaoForm.controls
                                    .situacaoDebitoPosEvento.errors?.required
                            "
                        >
                            Campo obrigatório
                        </p>
                    </div>
                    <div class="p-col-12 p-fluid">
                        <p-dropdown
                            [options]="situacaoCreditoList"
                            formControlName="situacaoCreditoPosEvento"
                            optionValue="situacaoCreditoId"
                            optionLabel="descricao"
                            [showClear]="true"
                            placeholder="Não selecionado"
                        ></p-dropdown>
                        <p
                            class="erro-validacao"
                            *ngIf="
                                (configuracaoForm.controls
                                    .situacaoCreditoPosEvento.touched ||
                                    validarCampos) &&
                                configuracaoForm.controls
                                    .situacaoCreditoPosEvento.errors?.required
                            "
                        >
                            Campo obrigatório
                        </p>
                    </div>
                    <div class="p-col-12 p-fluid">
                        <p-dropdown
                            [options]="situacaoCreditoList"
                            formControlName="situacaoZeroPosEvento"
                            optionValue="situacaoCreditoId"
                            optionLabel="descricao"
                            [showClear]="true"
                            placeholder="Não selecionado"
                        ></p-dropdown>
                        <p
                            class="erro-validacao"
                            *ngIf="
                                (configuracaoForm.controls.situacaoZeroPosEvento
                                    .touched ||
                                    validarCampos) &&
                                configuracaoForm.controls.situacaoZeroPosEvento
                                    .errors?.required
                            "
                        >
                            Campo obrigatório
                        </p>
                    </div>
                </div>
                <div
                    class="p-col-4"
                    paramEventoVisivel
                    [parametro]="tiposParam.P2_MUDA_ESTADO_CONTA"
                >
                    <div class="p-col-12 p-fluid">
                        <label>Estado da Conta pós evento</label>
                        <p-dropdown
                            [options]="estadoContaList"
                            formControlName="estadoContaDebitoPosEvento"
                            optionValue="estadoContaId"
                            optionLabel="descricao"
                            [showClear]="true"
                            placeholder="Não selecionado"
                        ></p-dropdown>
                        <p
                            class="erro-validacao"
                            *ngIf="
                                (configuracaoForm.controls
                                    .estadoContaDebitoPosEvento.touched ||
                                    validarCampos) &&
                                configuracaoForm.controls
                                    .estadoContaDebitoPosEvento.errors?.required
                            "
                        >
                            Campo obrigatório
                        </p>
                    </div>
                    <div class="p-col-12 p-fluid">
                        <p-dropdown
                            [options]="estadoContaList"
                            formControlName="estadoContaCreditoPosEvento"
                            optionValue="estadoContaId"
                            optionLabel="descricao"
                            [showClear]="true"
                            placeholder="Não selecionado"
                        ></p-dropdown>
                        <p
                            class="erro-validacao"
                            *ngIf="
                                (configuracaoForm.controls
                                    .estadoContaCreditoPosEvento.touched ||
                                    validarCampos) &&
                                configuracaoForm.controls
                                    .estadoContaCreditoPosEvento.errors
                                    ?.required
                            "
                        >
                            Campo obrigatório
                        </p>
                    </div>
                    <div class="p-col-12 p-fluid">
                        <p-dropdown
                            [options]="estadoContaList"
                            formControlName="estadoContaZeroPosEvento"
                            optionValue="estadoContaId"
                            optionLabel="descricao"
                            [showClear]="true"
                            placeholder="Não selecionado"
                        ></p-dropdown>
                        <p
                            class="erro-validacao"
                            *ngIf="
                                (configuracaoForm.controls
                                    .estadoContaZeroPosEvento.touched ||
                                    validarCampos) &&
                                configuracaoForm.controls
                                    .estadoContaZeroPosEvento.errors?.required
                            "
                        >
                            Campo obrigatório
                        </p>
                    </div>
                </div>

                <div class="p-col-12"></div>
                <div
                    class="p-col-12 p-fluid"
                    paramEventoVisivel
                    [parametro]="tiposParam.P2_LANCA_HONORARIO"
                >
                    <div class="p-grid">
                        <div
                            class="field-checkbox p-col-12 p-d-flex p-jc-flex-start p-ai-center"
                            style="gap: 5px"
                        >
                            <p-checkbox
                                formControlName="lancaHonorario"
                                [binary]="true"
                                inputId="lancaHonorario"
                            ></p-checkbox>
                            <label for="lancaHonorario">
                                Lança Honorários?</label
                            >
                        </div>
                    </div>
                </div>

                <div
                    class="p-col-12"
                    *ngIf="configuracaoForm.value.lancaHonorario"
                    paramEventoVisivel
                    [parametro]="tiposParam.P2_LANCA_HONORARIO"
                >
                    <p-fieldset legend="Honorários">
                        <div class="p-grid">
                            <div class="p-col-2 p-fluid">
                                <div
                                    *ngFor="
                                        let tipoHonorario of tipoHonorariosList
                                    "
                                    class="field-checkbox"
                                >
                                    <p-radioButton
                                        [inputId]="tipoHonorario.chave"
                                        name="category"
                                        [value]="tipoHonorario.chave"
                                        [(ngModel)]="
                                            configuracaoForm.value.honorario
                                                .tipoHonorario
                                        "
                                        [ngModelOptions]="{ standalone: true }"
                                        (onClick)="
                                            configuracaoForm.value.honorario.valorHonorario =
                                                null;
                                            updateHonorarioValidator()
                                        "
                                        [disabled]="implantado"
                                    ></p-radioButton>
                                    <label [for]="tipoHonorario.chave">{{
                                        tipoHonorario.valor
                                    }}</label>
                                </div>
                            </div>
                            <div
                                class="p-col-2 p-fluid"
                                *ngIf="
                                    configuracaoForm.value.honorario
                                        .tipoHonorario === 'FIXO'
                                "
                            >
                                <label>Valor R$*</label>
                                <p-inputNumber
                                    [(ngModel)]="
                                        configuracaoForm.value.honorario
                                            .valorHonorario
                                    "
                                    [ngModelOptions]="{ standalone: true }"
                                    (ngModelChange)="updateHonorarioValidator()"
                                    mode="decimal"
                                    [minFractionDigits]="2"
                                    [maxFractionDigits]="2"
                                    [useGrouping]="false"
                                    #valorHonorarioRS="ngModel"
                                    [disabled]="implantado"
                                >
                                </p-inputNumber>
                                <p
                                    class="erro-validacao"
                                    *ngIf="
                                        configuracaoForm.controls.honorario
                                            .errors?.valorHonorario &&
                                        (valorHonorarioRS.control.touched ||
                                            validarCampos)
                                    "
                                >
                                    Campo obrigatório
                                </p>
                            </div>
                            <div
                                class="p-col-2 p-fluid"
                                *ngIf="
                                    configuracaoForm.value.honorario
                                        .tipoHonorario === 'PERCENTUAL'
                                "
                            >
                                <label>Valor %*</label>
                                <p-inputNumber
                                    inputId="percent"
                                    [(ngModel)]="
                                        configuracaoForm.value.honorario
                                            .valorHonorario
                                    "
                                    [ngModelOptions]="{ standalone: true }"
                                    (ngModelChange)="updateHonorarioValidator()"
                                    [max]="100"
                                    [maxFractionDigits]="2"
                                    mode="decimal"
                                    #valorHonorarioPorc="ngModel"
                                    [disabled]="implantado"
                                ></p-inputNumber>
                                <p
                                    class="erro-validacao"
                                    *ngIf="
                                        configuracaoForm.controls.honorario
                                            .errors?.valorHonorario &&
                                        (valorHonorarioPorc.control.touched ||
                                            validarCampos)
                                    "
                                >
                                    Campo obrigatório
                                </p>
                            </div>
                            <div class="p-col-4 p-fluid">
                                <label>Conta*</label>
                                <p-autoComplete
                                    [forceSelection]="true"
                                    [suggestions]="contaAutoCompleteOptions"
                                    field="nomeCompleto"
                                    (completeMethod)="
                                        buscarContaPorNome($event)
                                    "
                                    [(ngModel)]="
                                        configuracaoForm.value.honorario
                                            .configContaHonorario
                                    "
                                    [ngModelOptions]="{ standalone: true }"
                                    (ngModelChange)="updateHonorarioValidator()"
                                    #configContaHonorario="ngModel"
                                    [disabled]="implantado"
                                >
                                </p-autoComplete>
                                <p
                                    class="erro-validacao"
                                    *ngIf="
                                        configuracaoForm.controls.honorario
                                            .errors?.configContaHonorario &&
                                        (configContaHonorario.control.touched ||
                                            validarCampos)
                                    "
                                >
                                    Campo obrigatório
                                </p>
                            </div>
                            <div class="p-col-4 p-fluid">
                                <label>Descrição*</label>
                                <input
                                    #descricaoHonorario="ngModel"
                                    type="text"
                                    pInputText
                                    [(ngModel)]="
                                        configuracaoForm.value.honorario
                                            .descricaoHonorario
                                    "
                                    [ngModelOptions]="{ standalone: true }"
                                    (ngModelChange)="updateHonorarioValidator()"
                                    [disabled]="implantado"
                                />
                                <p
                                    class="erro-validacao"
                                    *ngIf="
                                        configuracaoForm.controls.honorario
                                            .errors?.descricaoHonorario &&
                                        (descricaoHonorario.control.touched ||
                                            validarCampos)
                                    "
                                >
                                    Campo obrigatório
                                </p>
                            </div>
                        </div>
                    </p-fieldset>
                </div>
            </div>
        </form>
    </div>
    <div class="p-col-12 p-fluid">
        <h6>Não aplicar esse evento para:</h6>
    </div>
    <div class="p-col-12 p-fluid">
        <div class="p-grid">
            <div class="p-col-3 p-fluid">
                <label>Saldo da Conta</label>
                <p-dropdown
                    [options]="saldoContaList"
                    [(ngModel)]="bloqueioConta.idSaldoConta"
                    optionValue="saldoContaId"
                    optionLabel="descricao"
                    [showClear]="true"
                    placeholder="Não selecionado"
                ></p-dropdown>
            </div>
            <div class="p-col-2 p-fluid">
                <label>Situação do Crédito</label>
                <p-dropdown
                    [options]="situacaoCreditoList"
                    [(ngModel)]="bloqueioConta.idSituacaoCredito"
                    optionValue="situacaoCreditoId"
                    optionLabel="descricao"
                    [showClear]="true"
                    placeholder="Não selecionado"
                ></p-dropdown>
            </div>
            <div class="p-col-3 p-fluid">
                <label>Estado da Conta</label>
                <p-dropdown
                    [options]="estadoContaList"
                    [(ngModel)]="bloqueioConta.idEstadoConta"
                    optionValue="estadoContaId"
                    optionLabel="descricao"
                    [showClear]="true"
                    placeholder="Não selecionado"
                ></p-dropdown>
            </div>
            <div class="p-col-3 p-fluid">
                <label>Situação de Cobrança</label>
                <p-dropdown
                    [options]="situacaoCobrancaList"
                    [(ngModel)]="bloqueioConta.idSituacaoCobranca"
                    optionValue="situacaoCobrancaId"
                    optionLabel="descricao"
                    [showClear]="true"
                    placeholder="Não selecionado"
                ></p-dropdown>
            </div>
            <div class="p-col-1 p-fluid">
                <label><br /></label>
                <button
                    pButton
                    pRipple
                    type="button"
                    icon="pi pi-plus"
                    class="p-button-rounded"
                    (click)="adicionarExcecao()"
                    [disabled]="desabilitaBotaoAdicionarBloqueio || implantado"
                ></button>
            </div>
        </div>
    </div>
    <div class="p-col-12">
        <h6>Lista de exceções</h6>
        <p-table [value]="configuracaoForm.value.listaBloqueio">
            <ng-template pTemplate="header">
                <tr>
                    <th>Saldo da Conta</th>
                    <th>Situação do Crédito</th>
                    <th>Estado da Conta</th>
                    <th>Situação de Cobrança</th>
                    <th *ngIf="!implantado"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-excecao>
                <tr>
                    <td>
                        {{ descricaoSaldoConta(excecao.idSaldoConta) || "-" }}
                    </td>
                    <td>
                        {{
                            descricaoSituacaoCredito(
                                excecao.idSituacaoCredito
                            ) || "-"
                        }}
                    </td>
                    <td>
                        {{ descricaoEstadoConta(excecao.idEstadoConta) || "-" }}
                    </td>
                    <td>
                        {{
                            descricaoSituacaoCobranca(
                                excecao.idSituacaoCobranca
                            ) || "-"
                        }}
                    </td>
                    <td *ngIf="!implantado">
                        <button
                            pButton
                            pRipple
                            type="button"
                            icon="pi pi-trash"
                            class="p-button-rounded p-button-danger"
                            (click)="excluirExcecao(excecao)"
                            [disabled]="implantado"
                        ></button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td class="p-text-center" colspan="10">-</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
