import {Component, OnInit} from '@angular/core';
import {Pageable} from '../../../shared/pagination/pageable';
import {EventoBeneficioResponse} from '../../../responses/evento-beneficio-response';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import Swal from 'sweetalert2';
import {Router} from '@angular/router';
import {Pagination} from 'src/app/shared/pagination/pagination';
import {EnumsService} from 'src/app/core/enums.service';
import {TipoEvento} from '../../../utils/enums/tipo-evento.enum';

@Component({
    selector: "app-evento-beneficio-list",
    templateUrl: "./evento-beneficio-list.component.html",
    styleUrls: ["./evento-beneficio-list.component.scss"],
})
export class EventoBeneficioListComponent implements OnInit {
    eventoBeneficioResponses: EventoBeneficioResponse[];
    blockedDocument = false;
    pagination: Pagination<any>;

    implantadoEnum: any[];
    eventos: any[];
    situacaoEnum: any[];
    tipoEventoEnum: any[];
    tributoEnum: any[];
    simNaos: any[];
    situacoes: any[];

    constructor(
        private http: HttpClient,
        private route: Router,
        private enumsService: EnumsService,
        ) {}

    ngOnInit(): void {
        this.pagination = new Pagination<any>();
        this.search();
        this.getTributo();
        this.getTipoEvento();
        this.getEventos();
        this.simNaos = [
            { label: "Sim", value: "SIM" },
            { label: "Não", value: "NAO" }
        ];
        this.situacoes = [
            { label: "Ativa", value: "ATIVA" },
            { label: "Inativa", value: "INATIVA" }
        ];
    }

    getTributo(){
        this.http.
            get<any>(`${environment.baseUrl}/tributo`).subscribe(
                (response) => {
                    this.tributoEnum = response;
                });
    }
    getTipoEvento(){
        this.http.
            get<any>(`${environment.baseUrl}/tipo-evento`).subscribe(
                (response) => {
                    this.tipoEventoEnum = response;
                });
    }
    getEventos(){
        this.http.
            get<any>(`${environment.baseUrl}/eventos`, {
                params: {
                    tipoEventoId: TipoEvento.BENEFICIO_FISCAL
                }
        }).subscribe(
                (response) => {
                    this.eventos = response;
                });
    }

    search() {
        this.blockedDocument = true;
        this.http
            .get<Pageable<EventoBeneficioResponse>>(
                `${environment.baseUrl}/evento-beneficio/search`,
                {
                    params: {
                       size: 10,
                       page: this.pagination.pageable.pageNumber,
                    },
                }
            )
            .subscribe(
                (response) => {
                    this.blockedDocument = false;
                    this.pagination = response;
                    this.eventoBeneficioResponses = response.content;
                    this.eventoBeneficioResponses.forEach(r => {
                        r.dataInicioVigencia = r.dataInicioVigencia === null ? null : new Date(r.dataInicioVigencia)
                        r.dataFimVigencia = r.dataFimVigencia === null ? null : new Date(r.dataFimVigencia)
                    })
                },
                (error) => (this.blockedDocument = false)
            );
    }

    onPageChange(e) {
        this.pagination.pageable.pageNumber = e.page;
        this.search();
    }

    activateDeactivate(eb): void {
        const textoSituacao = eb.situacao === 'ATIVA' ? 'desativar' : 'ativar';
        Swal.fire({
            text: `Deseja ${textoSituacao} o benefício fiscal [${eb.eventoBeneficioId} - ${eb.descricao}]?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
        }).then((result) => {
            if (result.value) {
                this.http
                    .put<any>(
                        `${environment.baseUrl}/evento-beneficio/${eb.eventoBeneficioId}/ativa-inativa`,
                        {}
                    )
                    .subscribe(
                        (response) => {
                            this.search();
                        },
                        () => (this.blockedDocument = false)
                    );
            }
        });
    }
    excluirBeneficio(eb): void {
        Swal.fire({
            text: `Deseja excluir o benefício fiscal [${eb.eventoBeneficioId} - ${eb.descricao}]?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
        }).then((result) => {
            if (result.value) {
                this.http
                    .delete<any>(
                        `${environment.baseUrl}/evento-beneficio/${eb.eventoBeneficioId}`,
                        {}
                    )
                    .subscribe(
                        (response) => {
                            this.search();
                        },
                        () => (this.blockedDocument = false)
                    );
            }
        });
    }

    novoBeneficio() {
        this.route.navigate(["/config-beneficio-fiscal"]);
    }

    novoBeneficioEditar(eventoBeneficioId) {
        this.route.navigate([`/config-beneficio-fiscal/${eventoBeneficioId}`]);
    }
}
