import { Component, OnInit, ViewChild } from "@angular/core";
import swal from "sweetalert2";
import { MenuItem, MessageService } from "primeng/api";
import { ContaContempladaSaveRequests } from "src/app/models/conta-contemplada-save-request";
import { EventoBeneficioSaveAllRequest } from "src/app/models/evento-beneficio-save-all-request";
import { ConfigContaId, EventoBeneficioSaveRequest } from "src/app/models/evento-beneficio-save-request";
import { destinatarioSaveRequest } from "src/app/request/destinatario-save-request";
import { OrigemCreditoSaveRequests } from "src/app/request/origem-credito-save-requests";
import { UtilsService } from "src/app/shared/utils.service";
import { SimNaoEnum } from "src/app/utils/enums/sim-nao.enum";
import { AssociarContaCorrenteComponent } from "./components/associar-conta-corrente/associar-conta-corrente.component";
import { AssociarTransacaoERegraSelecaoComponent } from "./components/associar-transacao-e-regra-selecao/associar-transacao-e-regra-selecao.component";
import { CadastrarDestinatarioBeneficioFiscalComponent } from "./components/cadastrar-destinatario-beneficio-fiscal/cadastrar-destinatario-beneficio-fiscal.component";
import { EventoBeneficioFiscalComponent } from "./components/evento-beneficio-fiscal/evento-beneficio-fiscal.component";
import { BeneficioFiscalService } from "./service/beneficio-fiscal.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertsUtil } from "src/app/utils/alerts/alerts.util";

@Component({
    selector: "app-config-evento-beneficio-fiscal",
    templateUrl: "./config-evento-beneficio-fiscal.component.html",
    styleUrls: ["./config-evento-beneficio-fiscal.component.scss"],
})
export class ConfigEventoBeneficioFiscalComponent implements OnInit {
    @ViewChild(EventoBeneficioFiscalComponent, { static: false })
    eventoBeneficioFiscal: EventoBeneficioFiscalComponent;

    @ViewChild(AssociarContaCorrenteComponent, { static: false })
    associarConta: AssociarContaCorrenteComponent;

    @ViewChild(AssociarTransacaoERegraSelecaoComponent, { static: false })
    associarTrasacao: AssociarTransacaoERegraSelecaoComponent;

    @ViewChild(CadastrarDestinatarioBeneficioFiscalComponent, { static: false })
    cadastrarDestinatarioBeneficio: CadastrarDestinatarioBeneficioFiscalComponent;

    items: MenuItem[];
    activeIndex: number = 0;
    nextPage: boolean = true;
    configEvento: any;
    tipoCreditoTributario: any;
    associarContaCorrente: any;
    associarTransacaoRegra: any;
    cadastrarDestinatario: any;
    descricaoConta: any;
    eventoBeneficioSaveAllRequest: EventoBeneficioSaveAllRequest;
    eventoBeneficioSaveRequest: EventoBeneficioSaveRequest;
    contaContempladaSaveRequests: ContaContempladaSaveRequests;
    destinatarioSaveRequest: destinatarioSaveRequest;
    origemCreditoSaveRequests: OrigemCreditoSaveRequests;
    configConta: ConfigContaId;
    isLoading = false;
    implantadoValue: any;

    constructor(
        private messageService: MessageService,
        private util: UtilsService,
        private activatedRouter: ActivatedRoute,
        private beneficioFiscalService: BeneficioFiscalService,
        private route: Router,
        private alertUtil: AlertsUtil
    ) {}

    ngOnInit(): void {
        this.eventoBeneficioSaveAllRequest =
            new EventoBeneficioSaveAllRequest();
        this.items = [
            {
                label: "Benefício Fiscal",
                // command: (event: any) => {
                // },
            },
            {
                label: "Contas Contempladas",
                // command: (event: any) => {
                // },
            },
            {
                label: "Transações/ Regras",
                // command: (event: any) => {
                // },
            },
            {
                label: "Destinatário",
                // command: (event: any) => {
                // },
            },
        ];
        this.activatedRouter.params.subscribe((eventoId) => {
            if(eventoId.id){
                this.carregaContas(eventoId.id);
            }
        });
    }

    carregaContas(id) {

        this.beneficioFiscalService.getContaContemPlada(id).subscribe(
            resp => {
                this.associarContaCorrente = resp;
            }
        );
        this.beneficioFiscalService.getTransacoes(id).subscribe(
            resp => {
                this.associarTransacaoRegra = resp;
            }
        );
        this.beneficioFiscalService.getDestinatario(id).subscribe(
            resp => {
                this.cadastrarDestinatario = resp;
            }
        );
    }

    salvar(configEvento) {
        const data = configEvento;
        data.dataInicioVigencia = this.util.convertDateToSave(
            data.dataInicioVigencia
        );
        data.dataFimVigencia = this.util.convertDateToSave(
            data.dataFimVigencia
        );
    }

    outProximaAba(eventIndexPage) {
        this.activeIndex = eventIndexPage;
        switch (this.activeIndex) {
            case 1:
                this.configEvento =
                    this.eventoBeneficioFiscal.formGroupEvento.value;
                this.descricaoConta = this.eventoBeneficioFiscal.descricaoConta;
                this.prepareEventoBeneficioSaveRequest();
                this.prepareOrigemCreditoSaveRequests();
                break;
            case 2:
                this.associarContaCorrente = this.associarConta.getContas();
                this.prepareContaContempladaSaveRequests();
                break;
            case 3:
                this.associarTransacaoRegra =
                    this.associarTrasacao.getTrasacao();
                this.prepareTransacaoRegraSaveRequests();
                break;
            default:
                break;
        }
    }

    outAbaAnterior(eventoAbaAnterior) {
        this.activeIndex = eventoAbaAnterior;
        switch (this.activeIndex) {
            case 0:
                this.associarContaCorrente = this.associarConta.getContas();
                break;
            case 1:
                this.associarTransacaoRegra =
                    this.associarTrasacao.getTrasacao();
                break;
            case 2:
                this.cadastrarDestinatario =
                    this.cadastrarDestinatarioBeneficio.getDestinatario();
                break;
            default:
                break;
        }
    }

    outValidacaoTpEvento(value) {
        this.implantadoValue = value['implantadoInput'];
        if (this.associarTransacaoRegra?.length > 0) {
            swal.fire({
                title: "Atenção",
                text: "Tem certeza que deseja alterar o campo tipo evento? os dados da aba Transações/Regras serão perdidos.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "SIM",
                cancelButtonText: "NÃO",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.associarTransacaoRegra = [];
                } else {
                    this.eventoBeneficioFiscal.eventoFormControl.evento.setValue(
                        value['valorCarregado']
                    );
                }
            });
        }
        /**Caso implantado === 'NAO' deletar a transação ao salvar */
        if (this.implantadoValue === "NAO") {
            swal.fire({
                title: "Atenção",
                text: "Tem certeza que deseja alterar o campo tipo evento? Ao salvar  o benefício os dados da Transações/Regras serão removidos e não poderão ser recuperados.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "SIM",
                cancelButtonText: "NÃO",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.associarTransacaoRegra = [];
                } else {
                    this.eventoBeneficioFiscal.eventoFormControl.evento.setValue(
                        value['valorCarregado']
                    );
                }
            });
        }
    }

    outValidacaoTributo(value) {
        this.implantadoValue = value['implantadoInput'];
        if (
            this.associarTransacaoRegra?.length > 0 ||
            this.associarContaCorrente?.length > 0
        ) {
            swal.fire({
                title: "Atenção",
                text: `Tem certeza que deseja alterar o campo tributo?
                 os dados da etapa Contas Contempladas e da etapa Transações/Regras serão perdidos.`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "SIM",
                cancelButtonText: "NÃO",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.associarTransacaoRegra = [];
                    this.associarContaCorrente = [];
                } else {
                    this.eventoBeneficioFiscal.eventoFormControl.tributo.setValue(
                        value['valorCarregado']
                    );
                }
            });
        }
    }

    outValidacaoTpCadastro(value) {
        this.implantadoValue = value['implantadoInput'];
        if (
            this.cadastrarDestinatario ||
            this.associarContaCorrente?.length > 0
        ) {
            swal.fire({
                title: "Atenção",
                text: `Tem certeza que deseja alterar o campo Tipo Cadastro?
                 os dados da etapa contas contempladas e destinatário serão perdidos.`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "SIM",
                cancelButtonText: "NÃO",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.cadastrarDestinatario = null;
                    this.associarContaCorrente = [];
                } else {
                    this.eventoBeneficioFiscal.eventoFormControl.tipoCadastro.setValue(
                        value['valorCarregado']
                    );
                }
            });
        }
    }

    /**Prepare eventoBeneficioSaveRequest */
    prepareEventoBeneficioSaveRequest() {
        // let data: any;
        const configContaId =
            this.eventoBeneficioFiscal.eventoFormControl.configConta.value;
        const eventosId =
            this.eventoBeneficioFiscal.eventoFormControl.eventos.value['eventosId'];
        const lancaHonorarios =
            this.eventoBeneficioFiscal.eventoFormControl.lancaHonorarios.value;
        const tributoId =
            this.eventoBeneficioFiscal.eventoFormControl.tributo.value['tributoId'];
        const tipoCadastroId =
            this.eventoBeneficioFiscal.eventoFormControl.tipoCadastro.value['tipoCadastroId'];
        this.eventoBeneficioFiscal.eventoFormControl.configConta.setValue({
            configContaId,
        });
        this.eventoBeneficioFiscal.eventoFormControl.eventos.setValue({
            eventosId,
        });
        this.eventoBeneficioFiscal.eventoFormControl.lancaHonorarios.setValue(
            lancaHonorarios === true ? SimNaoEnum.SIM : SimNaoEnum.NAO
        );
        this.eventoBeneficioFiscal.eventoFormControl.tributo.setValue({
            tributoId,
        });
        this.eventoBeneficioFiscal.eventoFormControl.tipoCadastro.setValue({
            tipoCadastroId,
        });

        const data = JSON.parse(
            JSON.stringify(this.eventoBeneficioFiscal.formGroupEvento.value)
        );
        if (configContaId) {
            /**obj recebe valor formEvento e converte data formato YYY-MM-DD */
            data.dataInicioVigencia = data.dataInicioVigencia
                ? this.util.convertDateUsToSave(data.dataInicioVigencia)
                : "";
            data.dataFimVigencia = data.dataFimVigencia
                ? this.util.convertDateUsToSave(data.dataFimVigencia)
                : "";
            /**obj save */
            this.eventoBeneficioSaveAllRequest.eventoBeneficioSaveRequest =
                data;
        } else {
            const objEventoBeneficioSave = {
                eventoBeneficioId: this.eventoBeneficioFiscal.eventoBeneficioId,
                dataFimVigencia:
                    this.eventoBeneficioFiscal.eventoFormControl.dataFimVigencia
                        .value,
                dataInicioVigencia:
                    this.eventoBeneficioFiscal.eventoFormControl
                        .dataInicioVigencia.value,
                descricao:
                    this.eventoBeneficioFiscal.eventoFormControl.descricao
                        .value,
                eventos:
                    this.eventoBeneficioFiscal.eventoFormControl.eventos.value,
                lancaHonorarios:
                    this.eventoBeneficioFiscal.eventoFormControl.lancaHonorarios
                        .value,
                situacao:
                    this.eventoBeneficioFiscal.eventoFormControl.situacao.value,
                tipoConsolidacao:
                    this.eventoBeneficioFiscal.eventoFormControl
                        .tipoConsolidacao.value,
                tipoHonorarios:
                    this.eventoBeneficioFiscal.eventoFormControl.tipoHonorarios
                        .value,
                tributo:
                    this.eventoBeneficioFiscal.eventoFormControl.tributo.value,
                tipoCadastro:
                    this.eventoBeneficioFiscal.eventoFormControl.tipoCadastro
                        .value,
                valorHonorarios:
                    this.eventoBeneficioFiscal.eventoFormControl.valorHonorarios
                        .value,
            };
            const data = JSON.parse(JSON.stringify(objEventoBeneficioSave));
            /**obj recebe valor formEvento e converte data formato YYY-MM-DD */
            data.dataInicioVigencia = data.dataInicioVigencia
                ? this.util.convertDateUsToSave(data.dataInicioVigencia)
                : "";
            data.dataFimVigencia = data.dataFimVigencia
                ? this.util.convertDateUsToSave(data.dataFimVigencia)
                : "";
            /**obj save */
            this.eventoBeneficioSaveAllRequest.eventoBeneficioSaveRequest =
                data;
        } 
    }

    /**Prepare contaContempladaSaveRequests */
    prepareContaContempladaSaveRequests() {
        if (this.associarConta.setContas().length > 0) {
            this.eventoBeneficioSaveAllRequest.contaContempladaSaveRequests.push(
                ...this.associarConta.setContas()
            );
        }
    }

    outRemoveContaContempladaPorId(id) {
        this.eventoBeneficioSaveAllRequest.contaContempladaSaveRequests =
            this.removeContaContempladaPorId(id);
    }

    removeContaContempladaPorId(id) {
        return this.eventoBeneficioSaveAllRequest.contaContempladaSaveRequests.filter(
            (item) => item.configConta.configContaId !== id
        );
    }

    /**Prepare transacaoRegraSaveRequests */
    prepareTransacaoRegraSaveRequests() {
        // this.eventoBeneficioSaveAllRequest.transacaoRegraSaveRequests = this.associarTrasacao.setTransacoes();
        if (this.associarTrasacao.setTransacoes().length > 0) {
            this.eventoBeneficioSaveAllRequest.transacaoRegraSaveRequests.push(
                ...this.associarTrasacao.setTransacoes()
            );
        }
    }

    outRemoveTransacaoPorId(id) {
        this.eventoBeneficioSaveAllRequest.transacaoRegraSaveRequests =
            this.removeTransacaoPorId(id);
    }

    removeTransacaoPorId(id) {
        return this.eventoBeneficioSaveAllRequest.transacaoRegraSaveRequests.filter(
            (item) => item.transacao.transacaoId !== id
        );
    }

    /**Prepare origemCreditoSaveRequests*/
    prepareOrigemCreditoSaveRequests() {
        this.eventoBeneficioSaveAllRequest.origemCreditoSaveRequests =
            this.eventoBeneficioFiscal.setOrigemCredito();
    }

    /**Prepare destinatarioSaveRequest And SaveAll*/
    prepareDestinatarioSaveRequest(objDestinatario) {
        this.eventoBeneficioSaveAllRequest.destinatarioSaveRequest =
            objDestinatario;
        const objSave = JSON.parse(
            JSON.stringify(this.eventoBeneficioSaveAllRequest)
        );
        this.isLoading = true;
        this.beneficioFiscalService.eventoBeneficioSaveAll(objSave).subscribe(
            (resp: any) => {
                this.alertUtil.saveSuccess(`Evento de Benefício Fiscal ${resp.eventoBeneficioId} salvo com sucesso`).then(
                    () => {
                        this.activeIndex = 0;
                        this.resetCampos();
                        this.route.navigateByUrl(`evento-beneficio/evento-beneficio-list`);
                    }
                );
                setTimeout(() => {
                    this.isLoading = false;
                }, 3000);
            },
            (error) => {
                this.alertUtil.handleError(error);
            }
        );
    }

    resetCampos(evento?) {
        if (evento) {
            this.resetAbaContaAndTransacaoAndDestinatario();
        } else {
            this.resetAbaCadastro();
        }
    }

    resetAbaCadastro() {
        this.eventoBeneficioSaveAllRequest =
            new EventoBeneficioSaveAllRequest();
        this.configEvento = null;
        this.associarContaCorrente = null;
        this.associarTransacaoRegra = null;
        this.cadastrarDestinatario = null;
        // this.descricaoConta = null;
    }

    resetAbaContaAndTransacaoAndDestinatario() {
        this.configEvento = null;
        this.associarContaCorrente = null;
        this.associarTransacaoRegra = null;
        this.cadastrarDestinatario = null;
        // this.descricaoConta = null;
    }
}


