<div class="login-body">
    <div class="logo">
        <img src="assets/layout/images/harmony-logo.png" alt="harmony-layout"/>
        <span class="app-name">HARMONY</span>
    </div>
    <div class="login-panel">
        <img src="assets/layout/images/template/image-login-mobile.jpg" alt="harmony-layout" class="mobile"/>
        <div class="left-part">
            <span class="login-panel-header">Sign in To Harmony</span>
            <span class="login-panel-subheader">Welcome, please use the form to sign-in Harmony network</span>
            <div style="margin-top:32px">
                <div class="p-col-12">
                    <label>Enter e-mail address</label>
                    <input type="text" autocomplete="off" pInputText/>
                </div>
                <div class="p-col-12">
                    <label>Enter password</label>
                    <input type="password" autocomplete="off" pInputText feedback="false"/>
                </div>
            </div>
            <div class="p-col-6">
                <button type="button" pButton label="Login" class="p-button-raised"[routerLink]="['/']"></button>
            </div>
        </div>
        <div class="right-part">
            <img src="assets/layout/images/template/image-login.png" alt="harmony-layout"/>
        </div>
    </div>
</div>
