import { TransacaoId } from './../../../models/transacao-contemplada-save-request';
import {Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import {BaseLegalSearchRequest} from '../../../request/base-legal-search-request';
import {Store} from '@ngrx/store';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {UtilsService} from '../../../shared/utils.service';
import {
    ConfigContaBaseLegalTabViewService
} from '../../configurar-conta-corrente/config-conta-base-legal-tab-view/config-conta-base-legal-tab-view.service';
import {EventoPagamentoRegraIdentificacaoModalService} from './evento-pagamento-regra-identificacao-modal.service';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { EventoPagtoReceitaRegras } from 'src/app/models/evento-pagamento-receita-regras';
import { Regra } from 'src/app/models/regra';

@Component({
    selector: 'app-evento-pagamento-regra-identificacao-modal',
    templateUrl: './evento-pagamento-regra-identificacao-modal.component.html',
    styleUrls: ['./evento-pagamento-regra-identificacao-modal.component.scss']
})
export class EventoPagamentoRegraIdentificacaoModalComponent implements OnInit {

    blockedDocument = false;

    listaRegras: EventoPagtoReceitaRegras[];
    regraSelecionada: EventoPagtoReceitaRegras;
    dataInicioVigencia: string;
    dateStart: Date;
    validaData = false;

    @Input() dataInicio: any;
    @Input() regraLength: any;
    @Output() public regraIdentificacaoSelecionada = new EventEmitter<EventoPagtoReceitaRegras>();

    constructor(
        private storeBaseLegalSearchModal: Store<{ baseLegalSearchModal: { displayMaximizable: boolean } }>,
        private http: HttpClient,
        public utilsService: UtilsService,
        public eventoPagamentoRegraIdentificacaoModalService: EventoPagamentoRegraIdentificacaoModalService,
    ) {
    }

    ngOnInit(): void {

        this.regraSelecionada = new EventoPagtoReceitaRegras();
    }

    confirmar() {
        this.blockedDocument = true;
        this.blockedDocument = false;
        this.select();
    }

    onShow(): void {
        this.dataInicio = new Date();
        this.carregaRegraIdentificacao();
        this.dateStart = null;
        if (this.regraLength === 0) {
            this.dateStart = this.eventoPagamentoRegraIdentificacaoModalService.dateStart
            this.validaData = true;
            this.dataInicioVigencia = moment(this.dateStart).format('YYYY-MM-DD');
        } else {
            this.validaData = false;
        }
        this.validaConfirmar();
    }

    carregaRegraIdentificacao(): void {
        this.getRegraIdentificacao().subscribe(ri => {
            // tslint:disable-next-line: no-string-literal
            this.listaRegras = ri;
        });
    }

    getRegraIdentificacao(): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${environment.baseUrl}/regra?situacao=ATIVA&tipoUso=PAGAMENTO`);
    }

    select() {
        const regSelecionada = JSON.parse(JSON.stringify(this.regraSelecionada));
        regSelecionada.dataInicioVigencia = this.dataInicioVigencia;
        this.regraIdentificacaoSelecionada.emit(regSelecionada);
        this.eventoPagamentoRegraIdentificacaoModalService.displayMaximizable = false;
    }

    onHide() {}


    onSelectDataInicioVigencia(event) {
        if (event) {
            this.dataInicioVigencia = moment(event).format('YYYY-MM-DD');
        } else {
            this.dataInicioVigencia = '';
        }
        return this.dataInicioVigencia;
    }
    validaConfirmar() {
        return this.dateStart && !Array.isArray(this.regraSelecionada.regra) ? false : true;
    }
}
