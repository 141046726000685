<p-card header="Regras de Seleção">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-2">
            <label title="regraCriacaoContaId">Código</label>
            <input
                type="text"
                pInputText
                [(ngModel)]="regraSelecaoSearchRequest.regraId"
            />
        </div>

        <div class="p-field p-col-12 p-md-3">
            <label>Status</label>
            <p-dropdown
                optionLabel="valor"
                optionValue="chave"
                showClear="false"
                [autoDisplayFirst]="false"
                [options]="situacaoes"
                [(ngModel)]="regraSelecaoSearchRequest.situacao"
            ></p-dropdown>
        </div>

        <div class="p-field p-col-12 p-md-3">
            <label>Tipo Cadastro</label>
            <p-dropdown
                optionLabel="descricao"
                optionValue="tipoCadastroId"
                showClear="true"
                [autoDisplayFirst]="false"
                [options]="tipoCadastros"
                [(ngModel)]="regraSelecaoSearchRequest.tipoCadastroId"
                (onChange)="onTipoCadastroChange($event)"
            ></p-dropdown>
        </div>

        <div class="p-field p-col-12 p-md-3">
            <label>Categoria</label>
            <p-dropdown
                optionLabel="descricao"
                optionValue="tipoCategoriaId"
                showClear="true"
                [autoDisplayFirst]="false"
                [options]="tipoCategorias"
                [(ngModel)]="regraSelecaoSearchRequest.tipoCategoriaId"
            ></p-dropdown>
        </div>

        <div class="p-field p-col-12 p-md-8">
            <label>Descrição da Regra</label>
            <input
                type="text"
                pInputText
                autocomplete="nope"
                [(ngModel)]="regraSelecaoSearchRequest.descricao"
            />
        </div>
    </div>
    <div
        style="
            margin-top: 10px;
            margin-bottom: 10px;
            text-align: right !important;
        "
    >
        <button
            pButton
            type="button"
            class="p-mr-1"
            (click)="pesquisar()"
            label="Pesquisar"
            icon="pi pi-search"
        ></button>
        <button
            pButton
            type="button"
            class="p-mr-1 p-button-danger"
            (click)="clearFilter()"
            label="Limpar filtro"
            icon="pi pi-trash"
        ></button>
        <button
            pButton
            type="button"
            label="Nova Regra"
            class="p-button-secondary"
            (click)="nova()"
        ></button>
    </div>

    <p-table [value]="regrasSelecao">
        <ng-template pTemplate="header">
            <tr>
                <th>Código</th>
                <th>Descrição</th>
                <th>Tipo Cadastro</th>
                <th>Categoria</th>
                <th>Situação</th>
                <th style="width: 8rem">Ação</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-regra>
            <tr>
                <td>{{ regra.regraId }}</td>
                <td>{{ regra.descricao }}</td>
                <td>{{ regra.tipoCategoria.tipoCadastro?.descricao }}</td>
                <td>{{ regra.tipoCategoria.descricao }}</td>
                <td>{{ regra.situacao }}</td>
                <td>
                    <button
                        pButton
                        pRipple
                        title="Editar"
                        type="button"
                        icon="pi pi-pencil"
                        class="p-button-rounded p-button-warning"
                        (click)="onEditar(regra)"
                    ></button>
                    <button
                        pButton
                        pRipple
                        title="Inativar/Ativar"
                        type="button"
                        icon="pi pi-times"
                        class="p-button-rounded p-button-secondary"
                        (click)="excluir(regra)"
                        style="margin-left: 3px"
                    ></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <p-paginator
        [rows]="10"
        [totalRecords]="pagination.totalElements"
        (onPageChange)="onPageChange($event)"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{first} - {last} de {totalRecords}"
    ></p-paginator>
</p-card>
