<div class="p-grid">
    <div class="p-col-12">
        <form [formGroup]="eventosForm">
            <div class="p-grid">
                <div class="p-col-12 p-fluid">
                    <label>Categoria do Evento *</label>
                    <p-dropdown
                        [options]="eventosList"
                        optionLabel="descricao"
                        optionValue="eventosId"
                        formControlName="eventosId"
                        [showClear]="true"
                        placeholder="Selecione uma opção"
                        (ngModelChange)="carregarEvento()"
                        [disabled]="edicaoCategoria"
                    ></p-dropdown>
                    <p
                        class="erro-validacao"
                        *ngIf="
                            (eventosForm.controls.eventosId.touched ||
                                validarCampos) &&
                            eventosForm.controls.eventosId.errors?.required
                        "
                    >
                        Campo obrigatório
                    </p>
                </div>
                <br />
                <div class="p-col-12 p-fluid">
                    <label>Descrição</label>
                    <input
                        type="text"
                        pInputText
                        [value]="eventoSelecionado?.descricaoCompleta"
                        disabled
                    />
                </div>
                <div class="p-col-6 p-fluid">
                    <label>Operação</label>
                    <input
                        type="text"
                        pInputText
                        [value]="eventoSelecionado?.operacaoDescricao"
                        disabled
                    />
                </div>
                <div class="p-col-6 p-fluid">
                    <label>Tipo Evento</label>
                    <input
                        type="text"
                        pInputText
                        [value]="
                            eventoSelecionado?.tipoEvento?.descricaoTipoEvento
                        "
                        disabled
                    />
                </div>
                <div class="p-col-12">
                    <h4>Lista de Eventos</h4>
                    <p-table
                        [value]="configuracaoEventosList"
                        dataKey="configuracaoEventosId"
                        [tableStyle]="{ 'min-width': '100%' }"
                        [globalFilterFields]="[
                            'operconfiguracaoEventosIdacao',
                            'descricaoResumida',
                            'descricaoCompleta',
                            'tipoProcessamento'
                        ]"
                    >
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="configuracaoEventosId">
                                    Id
                                    <p-sortIcon
                                        field="configuracaoEventosId"
                                    ></p-sortIcon>
                                </th>
                                <th pSortableColumn="descricaoResumida">
                                    Descrição Evento
                                    <p-sortIcon
                                        field="descricaoResumida"
                                    ></p-sortIcon>
                                </th>
                                <th pSortableColumn="descricaoCompleta">
                                    Descrição Completa Evento
                                    <p-sortIcon
                                        field="descricaoCompleta"
                                    ></p-sortIcon>
                                </th>
                                <th pSortableColumn="tipoProcessamento">
                                    Tipo de Processamento
                                    <p-sortIcon
                                        field="tipoProcessamento"
                                    ></p-sortIcon>
                                </th>
                                <th pSortableColumn="">
                                    Implantado
                                    <p-sortIcon field=""></p-sortIcon>
                                </th>
                                <th>Ações</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-ev>
                            <tr>
                                <td>{{ ev.configuracaoEventosId }}</td>
                                <td>{{ ev.descricaoResumida }}</td>
                                <td>{{ ev.descricaoCompleta }}</td>
                                <td>{{ ev.tipoProcessamento }}</td>
                                <td>{{ ev.implantado }}</td>
                                <td>
                                    <button
                                        pButton
                                        pRipple
                                        title="Editar"
                                        type="button"
                                        icon="pi pi-pencil"
                                        class="p-button-rounded btn-action"
                                        [routerLink]="[
                                            '/evento-gerenciar-config/' +
                                                eventosForm.value.eventosId +
                                                '/' +
                                                ev.configuracaoEventosId
                                        ]"
                                    ></button>
                                    <button
                                        pButton
                                        pRipple
                                        title="Excluir"
                                        type="button"
                                        icon="pi pi-trash"
                                        class="p-button-rounded p-button-danger"
                                        style="margin-left: 3px"
                                    ></button>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td class="p-text-center" colspan="10">
                                    Nenhum registro encontrado
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </form>
    </div>
</div>
