

<div class="p-card-content">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-6">
            <label>Perfis</label>
            <p-dropdown [options]="perfisList" [optionLabel]="'descricao'" [(ngModel)]="perfilSelecionado" placeholder="Selecione">
            </p-dropdown>
        </div>
        <div class="p-field p-col-6">
            <button
            style="float: right;"
            class="margin-position p-mr-1"
            pButton
            type="button"
            icon="pi pi-plus-circle"
            label="Adicionar Perfil"
            (click)="addPerfis()"
            ></button>
        </div>
        <div class="p-field p-col-12">
            <p-table [value]="perfis" [paginator]="true" [rows]="5">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Código</th>
                        <th>Nome do Perfil</th>
                        <th>Situação Associação</th>
                        <th>Data do cadastro da Associação</th>
                        <th>Data de fim da Associação</th>
                        <th>Ação</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-p let-rowIndex="rowIndex">
                    <tr>
                        <td>{{p.codigoPerfil}}</td>
                        <td>{{p.descricao}}</td>
                        <td>{{p.situacao}}</td>
                        <td>
                            <p-calendar
                                [showIcon]="true" 
                                inputId="icon" 
                                dateFormat="dd/mm/yy" 
                                name="dataCadastro"
                                [disabled]="true"
                                [(ngModel)]="p.dataCadastro">
                            </p-calendar>
                        </td>
                        <td>
                            <p-calendar
                                [showIcon]="true" 
                                inputId="icon" 
                                dateFormat="dd/mm/yy" 
                                name="dataFim"
                                [disabled]="true"
                                [(ngModel)]="p.dataFim">
                            </p-calendar>
                        </td>
                        <td>
                            <button
                                pButton
                                pRipple
                                title="{{p.transacaoPerfilId && p.situacao === 'ATIVA' ? 'Desativar' : p.transacaoPerfilId && p.situacao === 'INATIVA' ? 'Ativar' : 'Remover'}}"
                                type="button"
                                icon="{{p.transacaoPerfilId && p.situacao === 'ATIVA' ? 'pi pi-times' : p.transacaoPerfilId && p.situacao === 'INATIVA' ? 'pi pi-check' : 'pi pi-trash'}}"
                                [ngClass]="{'p-button-warning': p.transacaoPerfilId}"
                                class="p-button-rounded p-button-secondary"
                                (click)="removePerfil(p, rowIndex)"
                                style="margin-left: 3px;"
                            ></button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    
        <!-- <div class="p-field p-col-10">
        </div>
        <div class="p-field p-col-2">
            <button type="button" (click)="salvarPerfis()" [disabled]="perfisSave.length === 0" pButton label="Salvar perfil"></button>
        </div> -->

    </div>
</div>