import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ConfigConta} from '../../models/config-conta';
import swal from 'sweetalert2';
import {Message} from 'primeng/api';
import {ConfigurarContaCorrenteService} from './configurar-conta-corrente.service';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {ConfigComposicaoContaComponent} from './config-composicao-conta/config-composicao-conta.component';
import {AssociarModeloContaCorrenteComponent} from './associar-modelo-conta-corrente/associar-modelo-conta-corrente.component';
import {
    ConfigContaUnidOrganizaTabViewComponent
} from './config-conta-unid-organiza-tab-view/config-conta-unid-organiza-tab-view.component';
import {ConfigAssociarReceitaTabViewComponent} from './config-associar-receita-tab-view/config-associar-receita-tab-view.component';
import {ConfigContaCorrenteTabViewComponent} from './config-conta-corrente-tab-view/config-conta-corrente-tab-view.component';
import {ContaCorrenteService} from '../../core/conta-corrente.service';

@Component({
    selector: 'app-configurar-conta-corrente',
    templateUrl: './configurar-conta-corrente.component.html',
    styleUrls: ['./configurar-conta-corrente.component.scss'],
    providers: [ContaCorrenteService],
})
export class ConfigurarContaCorrenteComponent implements OnInit {
    @Output() public configContaEmit = new EventEmitter<void>();
    @ViewChild(ConfigComposicaoContaComponent, {static: false})
    configComposicaoContaComponent: ConfigComposicaoContaComponent;
    @ViewChild(AssociarModeloContaCorrenteComponent, {static: false})
    associarModeloContaCorrenteComponent: AssociarModeloContaCorrenteComponent;
    @ViewChild(ConfigContaUnidOrganizaTabViewComponent, {static: false})
    configContaUnidOrganizacional: ConfigContaUnidOrganizaTabViewComponent;
    @ViewChild(ConfigAssociarReceitaTabViewComponent, {static: false})
    configReceita: ConfigAssociarReceitaTabViewComponent;
    @ViewChild(ConfigContaCorrenteTabViewComponent, {static: false})
    configContaCorrente: ConfigContaCorrenteTabViewComponent;

    msgs1: Message[];

    situacoes: any[];
    tiposConta: any[];
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
        }),
    };

    blockedDocument = false;

    configModeloEvent: any;
    configReceitaEvent: any;
    configComposicaoEvent: any;
    btnSalvarReceita: boolean;
    salvarEditarConta = 'Salvar';
    labelBtnProximo = 'Próximo';
    btnProximo = true;

    constructor(
        private http: HttpClient,
        private configurarContaCorrenteService: ConfigurarContaCorrenteService,
        private router: Router,
        private location: Location,
        public contaCorrenteService: ContaCorrenteService
    ) {
        // if (this.router.getCurrentNavigation().extras?.state?.configConta !== undefined) {
        //
        //     this.contaCorrenteService
        //         .configConta = JSON.parse(JSON.stringify(this.router.getCurrentNavigation().extras.state.configConta));
        //
        //     this.contaCorrenteService.configConta.dataInicioVigencia = this
        //         .configurarContaCorrenteService
        //         .convertDateToEdit(this.contaCorrenteService.configConta.dataInicioVigencia);
        //
        //     this.contaCorrenteService.configConta.dataFimVigencia = this
        //         .configurarContaCorrenteService
        //         .convertDateToEdit(this.contaCorrenteService.configConta.dataFimVigencia);
        //
        // } else {
        //     this.initConfigConta();
        // }
    }

    // initConfigConta(): void {
    //     this.contaCorrenteService.configConta = new ConfigConta();
    //     this.contaCorrenteService.configConta.tipoConta = 'TRIBUTARIA';
    // }

    outConfigConta(eventoConfigConta) {
        this.contaCorrenteService.configConta = eventoConfigConta;
    }

    habilitaSalvar(evento) {
        if (evento === 'CONFIG_MODELO') {
            this.configModeloEvent = true;
        } else if (evento === 'CONFIG_RECEITA') {
            this.configReceitaEvent = true;
        } else if (evento === 'CONFIG_COMPOSICAO') {
            this.configComposicaoEvent = true;
        }

        if (this.configModeloEvent && this.configReceitaEvent && this.configComposicaoEvent) {
            this.btnSalvarReceita = true;
            this.salvarEditarConta = 'Salvar Conta';
        }
    }

    handleChange(e) {
        const index = e.index;
        switch (index) {
            // ABA COMPOSICAO
            case 0:
                this.btnSalvarReceita = false;
                this.btnProximo = true;
                break;
            case 1:
                // this.btnSalvarReceita = false;
                this.btnProximo = false;
                this.configComposicaoContaComponent.iniciaData();
                this.configComposicaoContaComponent.initTabConfigComposicaoConta();
                break;
            // ABA MODELO
            case 2:
                // this.btnSalvarReceita = false;
                this.btnProximo = false;
                this.associarModeloContaCorrenteComponent.init();
                break;
            // ABA RECEITA
            case 3:
                // this.btnSalvarReceita = false;
                this.btnProximo = false;
                this.configReceita.init();
                break;
            // ABA UNIDADE ORG
            case 4:
                // this.btnSalvarReceita = false;
                this.btnProximo = false;
                this.configContaUnidOrganizacional.init();
                break;
             // ABA UNIDADE ORG
             case 4:
                // this.btnSalvarReceita = false;
                this.btnProximo = false;
                this.configContaUnidOrganizacional.init();
                break;
            default:
                break;
        }
    }

    ngOnInit(): void {
        this.msgs1 = [];
    }

    saveProximo() {
        this.configContaCorrente.salvar();
    }

    // esta ação salvar temporária só confirmará para o usuário, a conta corrente é criada antes e config-conta-corrente-tab-view.
    alertSaveConta() {
        swal.fire(
            'Sucesso',
            'Conta Corrente salva com sucesso',
            'success'
        ).then((r) => {
        });
        if (this.contaCorrenteService.configConta.configContaId) {
            this.configContaCorrente.salvar();
        }
    }

    voltar() {
        this.location.back();
    }
}
