export class EventoPagamentoReceitaDTO {
    eventoPagamentoReceitaId: number = null;
    codReceita: number = null;
    codTributo: number = null;
    dataInicioVigencia: string = null;
    dataFimVigencia: string = null;
    nomeReceita: string = null;
    sigla: string = null;
    situacao: string = null;
    tributaria: string = null;
    constructor(init?: Partial<EventoPagamentoReceitaDTO>) {
        if (init) {
            Object.assign(this, init);
        }
    }
}
