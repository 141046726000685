import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Pageable } from "src/app/shared/pagination/pageable";
import { Pagination } from "src/app/shared/pagination/pagination";
import { environment } from "src/environments/environment";
import { RelatorioConta } from "../../../models/relatorio-conta";

@Injectable({
    providedIn: "root",
})
export class ConsultaContaCorrenteService {
    httpOptions = {
        headers: new HttpHeaders({
            "Content-Type": "application/json",
        }),
    };
    // private routerInfo: BehaviorSubject<any>;

    constructor(private http: HttpClient) {
        // this.routerInfo = new BehaviorSubject<any>(false);
    }

    getContribuinteSearchPage(
        objParams,
        pagination: Pagination<any>
    ): Observable<Pageable<any>> {
        return this.http.get<Pageable<any>>(
            `${environment.baseUrl}/contribuinte/search`,
            {
                params: {
                    ...objParams,
                    page: pagination.pageable.pageNumber,
                    size: pagination.size,
                },
            }
        );
    }

    getContribuinte(objParams): Observable<Pageable<any>> {
        return this.http.get<Pageable<any>>(
            `${environment.baseUrl}/contribuinte/search`,
            {
                params: {
                    ...objParams,
                    // page: pagination.pageable.pageNumber,
                    // size: pagination.size
                },
            }
        );
    }

    getRelacaoProfissional(cpfCnpj): Observable<any[]> {
        return this.http.get<any[]>(
            `${environment.baseUrl}/contribuinte/relacao-profissional/${cpfCnpj}`
        );
    }

    getDadosIpvaContribuinte(objParams): Observable<any> {
        return this.http.get<Pageable<any>>(
            `${environment.baseUrl}/ipva/search`,
            {
                params: {
                    ...objParams,
                },
            }
        );
    }

    getRenavamPageable(objParams, pagination: Pagination<any>) {
        return this.http.get<Pageable<any>>(
            `${environment.baseUrl}/ipva/search`,
            {
                params: {
                    ...objParams,
                    page: pagination.pageable.pageNumber,
                    size: pagination.size,
                },
            }
        );
    }

    getContaViewConta(
        objParams,
        pagination: Pagination<any>,
        tabValue
    ): Observable<Pageable<any>> {
        return this.http.get<Pageable<any>>(
            `${environment.baseUrl}/view-conta-corrente/${tabValue}`,
            {
                params: {
                    ...objParams,
                    page: pagination.pageable.pageNumber,
                    size: pagination.size,
                },
            }
        );
    }

    getXls(objParams: any, tabValue: string): Observable<RelatorioConta> {
        return this.http.post<RelatorioConta>(
            `${environment.baseUrl}/relatorio-conta/${tabValue}/xls`,
            objParams
        );
    }

    getPdf(objParams: any, tabValue: string): Observable<RelatorioConta> {
        return this.http.post<RelatorioConta>(
            `${environment.baseUrl}/relatorio-conta/${tabValue}/pdf`,
            objParams
        );
    }

    getAllByCpfCnpj(cpfOuCnpj): Observable<any> {
        return this.http.get<any>(
            `${environment.baseUrl}/view-conta-corrente/get-all-by-cpf-cnpj/${cpfOuCnpj}`
        );
    }

    getContaIpvaItcdIcms(
        objParams,
        pagination: Pagination<any>
    ): Observable<Pageable<any>> {
        return this.http.get<Pageable<any>>(
            `${environment.baseUrl}/conta-corrente/view/search`,
            {
                params: {
                    ...objParams,
                    page: pagination.pageable.pageNumber,
                    size: pagination.size,
                },
            }
        );
    }

    getDetalheConta(contaCorrenteId: any): Observable<any> {
        return this.http.get<Pageable<any>>(
            `${environment.baseUrl}/conta-corrente/${contaCorrenteId}/financeiro`,
            {}
        );
    }

    getDetalheContaView(contaCorrenteId: any): Observable<any> {
        return this.http.get<Pageable<any>>(
            `${environment.baseUrl}/conta-corrente/${contaCorrenteId}/view`,
            {}
        );
    }

    getDetalheEventos(
        contaCorrenteId: any,
        page = 0,
        descricaoEvento
    ): Observable<any> {
        return this.http.get<Pageable<any>>(
            `${environment.baseUrl}/conta-corrente/${contaCorrenteId}/eventos`,
            { params: { page, size: 15, descricaoEvento: descricaoEvento ?? "" } }
        );
    }

    getTransacoes(objParams): Observable<any> {
        return this.http.get<Pageable<any>>(
            `${environment.baseUrl}/conta-corrente/transacoes`,
            {
                params: {
                    ...objParams,
                },
            }
        );
    }

    getTransacoesAgrupadaPorEventoData(contaCorrenteId): Observable<any> {
        return this.http.get<Pageable<any>>(
            `${environment.baseUrl}/conta-corrente/transacoes/agrupada-evento-data`,
            {
                params: {
                    contaCorrenteId,
                },
            }
        );
    }

    getEstadoConta(objParams): Observable<Array<any>> {
        return this.http.get<Array<any>>(
            `${environment.baseUrl}/estado-conta`,
            {
                params: {
                    ...objParams,
                },
            }
        );
    }

    getContaVinculada(
        objParams,
        pagination: Pagination<any>
    ): Observable<Pageable<any>> {
        return this.http.get<Pageable<any>>(
            `${environment.baseUrl}/conta-corrente/view/search`,
            {
                params: {
                    ...objParams,
                    page: pagination.pageable.pageNumber,
                    size: pagination.size,
                },
            }
        );
    }

    getSituacaoCredito(): Observable<Array<any>> {
        return this.http.get<Array<any>>(
            `${environment.baseUrl}/situacao-credito`
        );
    }

    getSituacaoCobranca(): Observable<Array<any>> {
        return this.http.get<Array<any>>(
            `${environment.baseUrl}/situacao-cobranca`
        );
    }

    getSaldoConta(): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${environment.baseUrl}/saldo-conta`);
    }

    getTipoNumeroDocumento(): Observable<Array<any>> {
        return this.http.get<Array<any>>(
            `${environment.baseUrl}/tipos-numero-documento`
        );
    }

    getContaCorrente(objParams): Observable<Array<any>> {
        return this.http.get<Array<any>>(
            `${environment.baseUrl}/conta-corrente/search`,
            {
                params: {
                    ...objParams,
                    paged: false,
                    size: 1000,
                },
            }
        );
    }

    // getContaCorrente(objParams): Observable<Array<any>> {
    //   const params = new HttpParams({ fromObject: objParams});
    //   return this.http.get<Array<any>>(`${environment.baseUrl}/conta-corrente/search`, {params});
    // }

    getContaCorrenteSearch(
        objParams,
        pagination: Pagination<any>
    ): Observable<Array<any>> {
        return this.http.get<Array<any>>(
            `${environment.baseUrl}/conta-corrente/view/search`,
            {
                params: {
                    ...objParams,
                    page: pagination.pageable.pageNumber,
                    size: pagination.size,
                },
            }
        );
    }

    gerarPDF(objParams): Observable<Blob> {
        return this.http.get(
            `${environment.baseUrl}/conta-corrente/extrato-conta/pdf`,
            {
                responseType: "blob",
                params: {
                    ...objParams,
                },
            }
        );
    }

    /** Inicio Filtros Conta IPVA, ITCD e ICMS */
    listFiltroContaIpvaItcdIcms(siglaTributo: any): Observable<Pageable<any>> {
        return this.http.get<Pageable<any>>(
            `${environment.baseUrl}/config-conta/sigla-tributo/${siglaTributo}`
        );
    }

    listFiltroContaAll(): Observable<Pageable<any>> {
        return this.http.get<Pageable<any>>(
            `${environment.baseUrl}/config-conta`
        );
    }

    listFiltroContaItcdOrIcms(
        objParams,
        tipoConta,
        pagination: Pagination<any>
    ): Observable<any> {
        return this.http.get<Pageable<any>>(
            `${environment.baseUrl}/conta-corrente/${tipoConta}`,
            {
                params: {
                    ...objParams,
                    page: pagination.pageable.pageNumber,
                    size: 200,
                },
            }
        );
    }

    /** Fim Filtros Conta IPVA, ITCD e ICMS */

    getDetalheItcd(
        objParams,
        pagination: Pagination<any>
    ): Observable<Pageable<any>> {
        return this.http.get<Pageable<any>>(
            `${environment.baseUrl}/contribuintes-itcd/search`,
            {
                params: {
                    ...objParams,
                    page: pagination.pageable.pageNumber,
                    size: pagination.size,
                },
            }
        );
    }

    /**Verifica se se a conta que originou a ação de emissão de DARE possui conta de honorários vinculada a ela */
    getContaVinculadaDare(contaCorrenteId): Observable<Pageable<any>> {
        return this.http.get<Pageable<any>>(
            `${environment.baseUrl}/conta-corrente/${contaCorrenteId}/relacoes`
        );
    }
}
