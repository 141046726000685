import {EventoCompensacao} from './evento-compensacao';
import {RegraImputacao} from './regra-imputacao';

export class EventoCompensaImputacao {
    ideventoCompensaImputacao: number;
    dataFimVigencia: string;
    dataInicioVigencia: string;
    eventoCompensacao: EventoCompensacao;
    regraImputacao: RegraImputacao;

    constructor(init?: Partial<EventoCompensaImputacao>) {
        if (init) {
            Object.assign(this, init);
        } else {
            this.ideventoCompensaImputacao = null;
            this.dataFimVigencia = null;
            this.dataInicioVigencia = null;
            this.eventoCompensacao = null;
            this.regraImputacao = null;
        }
    }
}
