import { ConfigEventoCompensacao } from "./config-evento-compensacao";

export class EventoPagtoCompensacoes {
    dataInicioVigencia: string;
    dataFimVigencia: string;
    eventoCompensacao: ConfigEventoCompensacao[];

    constructor(init?: Partial<EventoPagtoCompensacoes>) {
        if (init) {
            Object.assign(this, init);
        } else {
            this.dataInicioVigencia = "";
            this.dataFimVigencia = "";
            this.eventoCompensacao = [];
        }
    }
}
