import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EventoLancamento } from 'src/app/models/evento-lancamento';
import { environment } from 'src/environments/environment';
import { Pageable } from '../../shared/pagination/pageable';
import { Pagination } from '../../shared/pagination/pagination';

@Injectable({
  providedIn: 'root'
})
export class EventoPesquisaConfigService {

  constructor(private http: HttpClient) { }

  getEventos(objParams, pagination: Pagination<any>): Observable<Pageable<any>> {
    return this.http.get<Pageable<any>>(`${environment.baseUrl}/eventos/search`, {
      params: {
        ...objParams,
        page: pagination.pageable.pageNumber,
        size: pagination.size
      }
    });
  }

  getConfigEventos(objParams, pagination: Pagination<any>): Observable<Pageable<any>> {
    return this.http.get<Pageable<any>>(`${environment.baseUrl}/config-eventos/search`, {
      params: {
        ...objParams,
        page: pagination.pageable.pageNumber,
        size: pagination.size
      }
    });
  }

  getConfigEventosByTipoEventoId(objParams, pagination): Observable<Pageable<any>> {
    return this.http.get<Pageable<any>>(`${environment.baseUrl}/config-eventos/search`, {
      params: {
        ...objParams,
        page: 0,
        size: 1000
      }
    });
  }

  getConfigByConfiguracaoEventosId(objParams, pagination): Observable<Pageable<any>> {
    return this.http.get<Pageable<any>>(`${environment.baseUrl}/evento-config-conta/search`, {
      params: {
        ...objParams,
        page: 0,
        size: 1000
      }
    });
  }

  getConfigConfiguracaoConta(objParams, pagination): Observable<Pageable<any>> {
    return this.http.get<Pageable<any>>(`${environment.baseUrl}/evento-config-conta/search`, {
      params: {
        ...objParams,
        page: pagination.pageable.pageNumber,
        size: pagination.size
      }
    });
  }

  getOperacao(): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${environment.baseUrl}/enums/evento/operacao`,);
  }

  getTipoEvento(): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${environment.baseUrl}/tipo-evento`,);
  }

  getTributo(): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${environment.baseUrl}/tributo`);
  }

  getTipoCadastro(): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${environment.baseUrl}/tipo-cadastro`);
  }

  // getEventoLancamentoById(objParams): Observable<Pageable<any>> {
  //   const params =  new HttpParams({ fromObject: objParams});
  //   return this.http.get<Pageable<any>>(`${environment.baseUrl}/evento-lancamento/search`, {params});
  // }


}
