<p-card header="Configuração de Eventos de Estado">
    <div style="text-align: right">
        <button
            pButton
            type="button"
            icon="pi pi-plus-circle"
            label="Novo Evento"
            class="p-mr-1 p-text-left botao-adicionar"
            (click)="irConfigEvento()"
        ></button>
    </div>
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-1">
            <label>Código</label>
            <input type="number" [(ngModel)]="eventoEstadoId" pInputText />
        </div>
        <div class="p-field p-col-4">
            <label>Descrição *</label>
            <input
                maxlength="100"
                [(ngModel)]="descricao"
                type="text"
                pInputText
            />
        </div>
        <div class="p-field p-col-2">
            <label>Tipo Evento</label>
            <p-dropdown
                [(ngModel)]="tipoEvento"
                [readonly]="false"
                inputId="dropdown"
                name="tipoEvento"
                [options]="tipoEventoEnum"
                [optionLabel]="'valor'"
                [optionValue]="'chave'"
                [autoDisplayFirst]="false"
                showClear="true"
            >
            </p-dropdown>
        </div>
        <div class="p-field p-col-2">
            <label>Situação</label>
            <p-dropdown
                [(ngModel)]="situacao"
                [readonly]="false"
                inputId="dropdown"
                name="situacao"
                [options]="situacaoEnum"
                [optionLabel]="'valor'"
                [optionValue]="'chave'"
                [autoDisplayFirst]="false"
                showClear="true"
            >
            </p-dropdown>
        </div>
        <div class="p-field p-col-3" style="margin-top: 24px">
            <span class="p-buttonset">
                <button
                    (click)="pesquisaFiltro()"
                    pButton
                    label="Pesquisar"
                    icon="pi pi-search"
                    iconPos="right"
                    class="p-mr-1 p-button-primary"
                ></button>
                <button
                    pButton
                    type="button"
                    label="Limpar"
                    icon="pi pi-trash"
                    iconPos="right"
                    class="p-mr-1 p-button-danger"
                    (click)="limpar()"
                ></button>
            </span>
        </div>
    </div>
    <p-table [value]="eventosEstadoList">
        <ng-template pTemplate="header">
            <tr>
                <th>Tipo Evento</th>
                <th>Código</th>
                <th>Descrição</th>
                <th>Implantado?</th>
                <th>Situação</th>
                <th>Início da Vigência</th>
                <th>Fim da Vigência</th>
                <th>Ação</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-evento let-rowIndex="rowIndex">
            <tr>
                <td>{{ getValorEnum(evento.tipoEvento, tipoEventoEnum) }}</td>
                <td>{{ evento.eventoEstadoId }}</td>
                <td>{{ evento.descricao }}</td>
                <td>{{ getValorEnum(evento.implantado, implantadoEnum) }}</td>
                <td>{{ getValorEnum(evento.situacao, situacaoEnum) }}</td>
                <td>{{ evento.dataInicioVigencia | date : "dd/MM/YYYY" }}</td>
                <td>{{ evento.dataFimVigencia | date : "dd/MM/YYYY" }}</td>
                <td>
                    <button
                        pButton
                        pRipple
                        title="Editar"
                        type="button"
                        icon="pi pi-pencil"
                        class="p-button-rounded p-button botao-editar"
                        style="margin-left: 3px"
                        (click)="irConfigEvento(evento.eventoEstadoId)"
                    ></button>
                    <button
                        *ngIf="evento?.implantado == 'SIM'"
                        pButton
                        pRipple
                        title="{{
                            evento.situacao === 'ATIVA' ? 'Desativar' : 'Ativar'
                        }}"
                        type="button"
                        icon="{{
                            evento.situacao === 'ATIVA'
                                ? 'pi pi-times'
                                : 'pi pi-check'
                        }}"
                        class="p-button-rounded p-button-secondary"
                        style="margin-left: 3px"
                        (click)="
                            putAtivaInativa(
                                evento.eventoEstadoId,
                                evento.descricao,
                                evento.situacao === 'ATIVA'
                                    ? 'desativar'
                                    : 'ativar'
                            )
                        "
                    ></button>
                    <button
                        *ngIf="evento?.implantado == 'NAO'"
                        (click)="excluir(rowIndex, evento)"
                        pButton
                        pRipple
                        title="Excluir"
                        type="button"
                        icon="pi pi-trash"
                        class="p-button-rounded p-button-danger"
                        style="margin-left: 3px"
                    ></button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="12" style="text-align: center">
                    Nenhum registro localizado
                </td>
            </tr>
        </ng-template>
    </p-table>
    <p-paginator
        [rows]="10"
        [totalRecords]="pagination.totalElements || 0"
        (onPageChange)="onPageChange($event)"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{first} - {last} de {totalRecords}"
    ></p-paginator>
</p-card>
