import {Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {BaseLegalSearchRequest} from '../../../request/base-legal-search-request';
import {Store} from '@ngrx/store';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {UtilsService} from '../../../shared/utils.service';
import {
    ConfigContaBaseLegalTabViewService
} from '../../configurar-conta-corrente/config-conta-base-legal-tab-view/config-conta-base-legal-tab-view.service';
import {EventoPagamentoContaExcecaoModalService} from './evento-pagamento-conta-excecao-modal.service';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { EventoPagtoContaExcecoes } from 'src/app/models/evento-pagamento-conta-excecoes';
import { constrainPoint } from '@fullcalendar/core';

@Component({
    selector: 'app-evento-pagamento-conta-excecao-modal',
    templateUrl: './evento-pagamento-conta-excecao-modal.component.html',
    styleUrls: ['./evento-pagamento-conta-excecao-modal.component.scss']
})
export class EventoPagamentoContaExcecaoModalComponent implements OnInit {

    @Output() public eventoExcessaoSelecionado = new EventEmitter<EventoPagtoContaExcecoes>();

    blockedDocument = false;
    @Input() dataInicio: string;
    @Input() eventoPagtoContaExcecoesLength: any;


    validaData = false;
    contasExcecao: EventoPagtoContaExcecoes[];
    contaExcecao: EventoPagtoContaExcecoes;
    dataInicioVigencia: string;
    dateStart: Date;

    constructor(
        private storeBaseLegalSearchModal: Store<{ baseLegalSearchModal: { displayMaximizable: boolean } }>,
        private http: HttpClient,
        public utilsService: UtilsService,
        public eventoPagamentoContaExcecaoModalService: EventoPagamentoContaExcecaoModalService,
    ) {
    }

    ngOnInit(): void {
        this.contaExcecao = new EventoPagtoContaExcecoes();
    }
    onShow(): void {
        this.carregaContaExcecao(this.eventoPagamentoContaExcecaoModalService.eventoPagamentoReceita.codReceita);
        this.dateStart = null;
        if (this.eventoPagtoContaExcecoesLength === 0) {
            this.dateStart = this.eventoPagamentoContaExcecaoModalService.dateStart;
            this.validaData = true;
            this.dataInicioVigencia = moment(this.dateStart).format('YYYY-MM-DD');
        } else { this.validaData = false; }
        this.validaConfirmar();
    }

    carregaContaExcecao(codReceita: any): void {
        this.getContaExcecao(codReceita).subscribe(ce => {
            // tslint:disable-next-line: no-string-literal
            this.contasExcecao = ce['content'];
        });
    }

    getContaExcecao(codReceita: any): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${environment.baseUrl}/config-conta-receita/search?paged=false&situacao=ATIVA&codReceita=${codReceita}`);
    }
    confirmar() {
        this.blockedDocument = true;
        this.blockedDocument = false;
        this.select();
    }

    select() {
        const ctaExcecao = JSON.parse(JSON.stringify(this.contaExcecao));
        ctaExcecao.dataInicioVigencia = this.dataInicioVigencia;
        this.eventoExcessaoSelecionado.emit(ctaExcecao);
        this.eventoPagamentoContaExcecaoModalService.displayMaximizable = false;
    }

    onHide() {}


    onSelectDataInicioVigencia(event) {
        if (event) {
            this.dataInicioVigencia = moment(event).format('YYYY-MM-DD');
        } else {
            this.dataInicioVigencia = '';
        }
        return this.dataInicioVigencia;
    }
    validaConfirmar() {
        return this.dateStart && !Array.isArray(this.contaExcecao.configContaReceita) ? false : true;
    }
}
