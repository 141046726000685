export class Pagination<T> {
    content?: T[];
    pageable?: {
        sort?: {
            sorted?: boolean;
            unsorted?: boolean;
            empty?: boolean
        };
        offset?: number;
        pageNumber?: number;
        pageSize?: number;
        paged?: boolean;
        unpaged?: boolean;
    };
    last?: boolean;
    totalElements?: number;
    totalPages?: number;
    size?: number;
    number?: number;
    sort?: {
        sorted?: boolean;
        unsorted?: boolean;
        empty?: boolean;
    };
    first?: boolean;
    numberOfElements?: number;
    empty?: boolean;

    constructor(init?: Partial<Pagination<any>>) {
        if (init) {
            Object.assign(this, init);
        } else {
            this.content = [];
            this.pageable = {
                sort: {
                    sorted: false,
                    unsorted: false,
                    empty: false
                },
                offset: 0,
                pageNumber: 0,
                pageSize: 0,
                paged: false,
                unpaged: false
            };
            this.last = false;
            this.totalElements = 0;
            this.totalPages = 0;
            this.size = 10;
            this.number = 0;
            this.sort = {
                sorted: false,
                unsorted: false,
                empty: false
            };
            this.first = true;
            this.numberOfElements = 0;
            this.empty = false;
        }
    }
}
