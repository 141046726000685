import {Component, OnInit} from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { KeycloakService } from 'keycloak-angular';
import {AppTopbarService} from "./app.topbar.service";
import {environment} from './../environments/environment';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnInit {

    userProfileName: string;
    perfil: string;

    constructor(
        public app: AppMainComponent,
        private keyCloak: KeycloakService,
        private appTopbarService: AppTopbarService
    ) { }

    async ngOnInit(): Promise<void> {
        if (this.keyCloak.getKeycloakInstance().authenticated) {
            this.userProfileName = this.keyCloak.getKeycloakInstance().tokenParsed.name;
            this.perfil = await this.appTopbarService.getPerfilLogado();
        }
        if (!this.keyCloak.getKeycloakInstance().authenticated || this.keyCloak.isTokenExpired()) {
            this.logOff();
        }
    }

    logOff(): void {
        this.keyCloak.logout(environment.redirectUrl);
        this.appTopbarService.clearProfile();
    }

}
