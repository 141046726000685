<p-dialog
    header="A conta selecionada possui honorários vinculados"
    [(visible)]="visible"
    [modal]="true"
    [style]="{ width: '90vw' }"
    [maximizable]="true"
    [baseZIndex]="10000"
    [draggable]="false"
    [resizable]="false"
>
    <p-table
        [value]="contaList"
        [scrollable]="true"
    >
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="contaCorrenteId">
                    Tipo <p-sortIcon field="contaCorrenteId"></p-sortIcon>
                </th>
                <th pSortableColumn="tipoCadastroId">
                    Conta <p-sortIcon field="tipoCadastroId"></p-sortIcon>
                </th>
                <th pSortableColumn="codigoCadastro">
                    Número do Documento
                    <p-sortIcon field="codigoCadastro"></p-sortIcon>
                </th>
                <th pSortableColumn="nomeResponsavel">
                    Contribuinte
                    <p-sortIcon field="nomeResponsavel"></p-sortIcon>
                </th>
                <th pSortableColumn="numeroDocumento">
                    Perído de Referência
                    <p-sortIcon field="numeroDocumento"></p-sortIcon>
                </th>
                <th pSortableColumn="periodoReferencia">
                    Dt. Vencimento
                    <p-sortIcon field="periodoReferencia"></p-sortIcon>
                </th>
                <th pSortableColumn="parcela">
                    Estado da Conta
                    <p-sortIcon field="parcela"></p-sortIcon>
                </th>
                <th pSortableColumn="dataVencimento">
                    Saldo da Conta
                    <p-sortIcon field="dataVencimento"></p-sortIcon>
                </th>
                <th pSortableColumn="estadoContaDesc">
                    Sit. Crédito
                    <p-sortIcon field="estadoContaDesc"></p-sortIcon>
                </th>
                <th pSortableColumn="saldoContaDesc">
                    Sit. Cobrança
                    <p-sortIcon field="saldoContaDesc"></p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-conta>
            <tr class="selecao">
                <td>{{ conta?.tipo }}</td>
                <td>{{ conta?.conta }}</td>
                <td>{{ conta?.numeroDocumento }}</td>
                <td>{{ conta?.contribuinte }}</td>
                <td>{{ conta?.periodoReferencia }}</td>
                <td>{{ conta?.dataVencimento | date: "dd/MM/yyyy" }}</td>
                <td>{{ conta?.estadoConta }}</td>
                <td>{{ conta?.saldoConta }}</td>
                <td>{{ conta?.situacaoCredito }}</td>
                <td>{{ conta?.situacaoCobranca }}</td>
            </tr>
        </ng-template>

    </p-table>


    <div class="p-mt-3" style="text-align: right">
        <button
            pButton
            label="Cancelar"
            icon="pi pi-close-circle"
            class="p-button-outlined p-mr-2"
            (click)="hiddenDialog()"
        ></button>
        <button
            pButton
            class="float-btn-next"
            label="Emitir DARE"
            icon="pi pi-plus-circle"
            (click)="emitirDare()"
        ></button>
    </div>
</p-dialog>