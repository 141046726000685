import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {LancamentoPorImpostoSearchRequest} from "../../../request/LancamentoPorImpostoSearchRequest";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class LancamentoPorImpostoService {
    blockedDocument = false;
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

  constructor(
      private http: HttpClient,
  ) { }

    pesquisaDadosDashboard(lancamentoPorImpostoSearchRequest: LancamentoPorImpostoSearchRequest): Observable<any>{
      this.blockedDocument = true;
      return this.http.get<any>(`${environment.baseUrl}/dashboard/lancamento-por-imposto`, {
          params: {
              ...lancamentoPorImpostoSearchRequest
          }
      });
    }

    getTrasacoes(): Observable<Array<any>> {
      return this.http.get<Array<any>>(`${environment.baseUrl}/transacao/resumo`);
    }

    getImpostos(): Observable<Array<any>> {
      return this.http.get<Array<any>>(`${environment.baseUrl}/tributo/filtrar?id=1&id=2&id=3`)
    }

}
