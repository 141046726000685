<p-dialog header="Regra de Imputação" (onHide)="onHide()"
          [(visible)]="visible"
          [modal]="true"
          [style]="{width: '60vw'}"
          [maximizable]="true"
          [baseZIndex]="10000"
          [draggable]="true"
          [resizable]="false">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-12">
            <label>Regra de Imputação *</label>
            <p-dropdown
                appendTo="body"
                optionLabel="descricao"
                showClear="true"
                [autoDisplayFirst]="false"
                [options]="regrasInputacoes"
                [(ngModel)]="eventoCompensaImputacao.regraImputacao"
                [disabled]="receivedParams.operacaoCrud !== 0"
            ></p-dropdown>
        </div>
    </div>

    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-12">
            <label>Início da Vigência *</label>
            <p-calendar
                    appendTo="body"
                    dateFormat="dd/mm/yy"
                    [showIcon]="true"
                    [(ngModel)]="eventoCompensaImputacao.dataInicioVigencia"
                    (ngModelChange)="onSelectDataInicioVigencia($event)"
            ></p-calendar>
        </div>

    </div>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-times" (click)="visible=false" label="Fechar"></p-button>
        <p-button icon="pi pi-check" (click)="confirmar()" label="Confirmar"></p-button>

<!--        <p-button icon="pi pi-search" (click)="search()" label="Pesquisar"></p-button>-->
<!--        <p-button *ngIf="multipleSelection" icon="pi pi-check" (click)="confirmarSelecionados()" label="Confirmar"></p-button>-->
    </ng-template>
</p-dialog>
