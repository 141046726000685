import {Injectable} from '@angular/core';
import {ConfigEventoPagamento} from '../../../models/config-evento-pagamento';
import {EventoPagamentoTransacaos} from '../../../models/evento-pagamento-transacoes';
import {EventoPagamentoTransacao} from '../../../models/evento-pagamento-transacao';

@Injectable({
    providedIn: 'root'
})
export class EventoPagamentoTransacaoFinanceiraModalService {
    displayMaximizable: boolean;
    dateStart: Date;
    eventoPagamento: ConfigEventoPagamento;
    eventoPagamentoTransacaoLast: EventoPagamentoTransacao;
    constructor() {
    }
}
