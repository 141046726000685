import { CpfCnpjPipe } from './../../compartilhado/pipes/cpf.pipe';
import { SelecionarContribInternaRoutingModule } from './selecionar-contrib-interna-routing.module';
import { SelecionarContribInternaListComponent } from './selecionar-contrib-interna-list/selecionar-contrib-interna-list.component';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelecionarContribInternaComponent } from './selecionar-contrib-interna.component';
import { CompartilhadoModule } from 'src/app/compartilhado/compartilhado.module';
import { FieldsetModule } from 'primeng/fieldset';
import { RadioButtonModule } from 'primeng/radiobutton';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import { SelecionarVeiculoListComponent } from './selecionar-veiculo-list/selecionar-veiculo-list.component';
import { InscEstadualPipe } from 'src/app/compartilhado/pipes/inscricao-estadual.pipe';



@NgModule({
  declarations: [
    SelecionarContribInternaComponent,
    SelecionarContribInternaListComponent,
    SelecionarVeiculoListComponent,
    CpfCnpjPipe,
    InscEstadualPipe,
  ],
  imports: [
    CommonModule,
    SelecionarContribInternaRoutingModule,
    CompartilhadoModule,
    FieldsetModule,
    RadioButtonModule,
    OverlayPanelModule
  ],
  providers:    [ InscEstadualPipe,CpfCnpjPipe ]
})
export class SelecionarContribInternaModule { }
