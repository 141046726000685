import { ConfigContaReceita } from "./config-conta-receita";

export class EventoPagtoContaExcecoes {
    dataInicioVigencia: string;
    dataFimVigencia: string;
    configContaReceita: ConfigContaReceita[];

    constructor(init?: Partial<EventoPagtoContaExcecoes>) {
        if (init) {
            Object.assign(this, init);
        } else {
            this.dataInicioVigencia = '';
            this.dataFimVigencia = '';
            this.configContaReceita = [];
        }
    }
}
