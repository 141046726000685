// import { ConfigContaContribuinte, Contribuinte } from './../../models/contribuinte';
import { Component, OnInit } from '@angular/core';
import { Pagination } from 'src/app/shared/pagination/pagination';
import { Contribuinte } from 'src/app/models/contribuinte';
import { Veiculo } from 'src/app/models/veiculo';
import { SelecionarContribInternaService } from 'src/app/compartilhado/services/selecionar-contrib-interna/selecionar-contrib-interna.service';
@Component({
  selector: 'app-selecionar-contrib-interna',
  templateUrl: './selecionar-contrib-interna.component.html',
  styleUrls: ['./selecionar-contrib-interna.component.scss']
})

export class SelecionarContribInternaComponent implements OnInit {

  pagination: Pagination<any>;

  public contribuinte: Contribuinte;
  public veiculo: Veiculo;

  public tiposCadastro: any;
  public tipoContaSelected: any;
  public veiculosListSearch: any;
  public contribuinteListSearch: any;

  constructor(public selecionarContribInternaService: SelecionarContribInternaService) { }

  ngOnInit(): void {
    this.contribuinte = new Contribuinte();
    this.veiculo = new Veiculo();
    this.pagination = new Pagination<any>();
    this.loadTipoCadastro();
  }

  private loadTipoCadastro() {
    this.selecionarContribInternaService.getTipoCadastro().subscribe((response) => {
      this.tiposCadastro = response;
      this.tipoContaSelected = response[0].chave;
    });
  }

  search() {
    const filtro = this.buildContribuinteFilter();

    this.selecionarContribInternaService.search(filtro, this.pagination)
      .subscribe((response) => {
        this.pagination = response;
        this.contribuinteListSearch = response.content;
      }, (error) => {
      });
  }

  searchVeiculo() {
    const filtro = this.buildVeiculoFilter();

    this.selecionarContribInternaService.searchVeiculo(filtro, this.pagination)
      .subscribe((response) => {
        this.pagination = response;
        this.veiculosListSearch = response.content;
      }, (error) => {
      });
  }

  private buildContribuinteFilter(): any {
    let contribuinte = {
      tipo: 'CONTRIBUINTE',
      tipoPessoa: this.tipoContaSelected
    }

    if (this.contribuinte.cpfCnpj) {
      const cpfCnpjSemMascara = this.contribuinte.cpfCnpj.toString()
        .replace('.', '').replace('.', '')
        .replace('-', '')
        .replace('/', '');
      contribuinte['cpfCnpj'] = Number(cpfCnpjSemMascara);
    }
    if (this.contribuinte.ie) contribuinte['inscricaoEstadual'] = this.contribuinte.ie;
    if (this.contribuinte.nome) contribuinte['nome'] = this.contribuinte.nome;

    return contribuinte;
  }

  private buildVeiculoFilter(): any {
    let veiculo = {
      tipoPessoa: this.tipoContaSelected
    }

    if (this.veiculo.chassi) veiculo['chassi'] = this.veiculo.chassi;
    if (this.veiculo.renavam) veiculo['renavam'] = this.veiculo.renavam;
    if (this.veiculo.placa) veiculo['placa'] = this.veiculo.placa;

    return veiculo

  }

  onPageChange(pageNumber) {
    this.pagination.pageable.pageNumber = pageNumber;

    if (this.tipoContaSelected === 'JURIDICA' || this.tipoContaSelected === 'FISICA') {
      this.search();
    } else if (this.tipoContaSelected === 'VEICULO') {
      this.searchVeiculo();
    }
  }

  limpar() {
    this.contribuinteListSearch = null;
    this.veiculosListSearch = null;

    this.contribuinte.cpfCnpj = null;
    this.contribuinte.ie = null;
    this.contribuinte.nome = null;

    this.veiculo.chassi = null;
    this.veiculo.renavam = null;
    this.veiculo.placa = null;
  }


  formatDocument(event: any) {
    const cpfCnpj = event.target.value.toString();
    const parts = this.unFormat(cpfCnpj).split(".");

    if (parts[0].length <= 11) {
      this.contribuinte.cpfCnpj = this.cpf_mask(parts[0]);
    } else if (parts[0].length > 11 && parts[0].length <= 14) {
      this.contribuinte.cpfCnpj = this.cnpj(parts[0]);
    }
  };

  unFormat(cpfCnpj: string): string {
    if (!cpfCnpj) {
      return '';
    }
    return cpfCnpj.replace(/\D/g, '').replace(/,/g, '');
  };

  cpf_mask(v) {
    v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
    v = v.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); //Coloca um hífen entre o terceiro e o quarto dígitos
    return v;
  }

  cnpj(v) {
    v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
    v = v.replace(/^(\d{2})(\d)/, '$1.$2'); //Coloca ponto entre o segundo e o terceiro dígitos
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3'); //Coloca ponto entre o quinto e o sexto dígitos
    v = v.replace(/\.(\d{3})(\d)/, '.$1/$2'); //Coloca uma barra entre o oitavo e o nono dígitos
    v = v.replace(/(\d{4})(\d)/, '$1-$2'); //Coloca um hífen depois do bloco de quatro dígitos
    return v;
  }
}
