import * as ConfigurarContaCorrenteActions from './configurar-conta-corrente.actions';
import {ConfigContaBaseLegal} from '../../models/config-conta-base-legal';

const modelInit = new ConfigContaBaseLegal();
// modelInit.configConta = new ConfigConta();

const regraCriacaoViewInitialState = {
    configContaBaseLegal: modelInit
};

// tslint:disable-next-line:max-line-length|
export function configContaBaseLegalReducer(state = regraCriacaoViewInitialState, action: ConfigurarContaCorrenteActions.SetConfigContaBaseLegal) {
    switch (action.type) {
        case ConfigurarContaCorrenteActions.SET_CONFIG_CONTA_BASE_LEGAL:
            return {
                ...state,
                configContaBaseLegal: action.payload
            };
        default:
            return state;
    }
}
