
<form [formGroup]="formGroupReferencias" (change)="onChange()">
    <div class="p-card-content">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-2">
                <p-fieldset legend="Base de Incidência*">
                    <div *ngFor="let baseIncidencias of naturezaItemCalculoEnum;" style="margin: 2px;">
                        <p-checkbox name="baseIncidencia"
                            [disabled]="beneficioImplantado"
                            [label]="baseIncidencias.descricao"
                            [value]="baseIncidencias.naturezaItemCalculoId"
                            [formControl]="baseIncidenciasSelected">
                        </p-checkbox>
                    </div>
                    <p
                        class="erro-validacao"
                        *ngIf="(formGroupReferencias.controls.baseIncidencias.touched) &&
                            formGroupReferencias.controls.baseIncidencias.errors?.required"
                    >
                        Campo obrigatório
                    </p>
                </p-fieldset>
            </div>

            <div class="p-field p-col-10">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-2" >
                        <p-fieldset legend="Tipo Pagto*" >
                            <div class="p-field-radiobutton">
                                <p-radioButton value="AVISTA" formControlName="tipoPagamento" [disabled]="beneficioImplantado"></p-radioButton>
                                <label>À Vista</label>
                            </div>
                            <p class="erro-validacao" *ngIf="(formGroupReferencias.controls.tipoPagamento.touched)
                            && formGroupReferencias.controls.tipoPagamento.errors?.required">
                                Campo obrigatório
                            </p>
                        </p-fieldset>
                    </div>
                    <div class="p-field p-col-5">
                        <p-fieldset legend="Tipo do Valor da Redução*">
                            <div class="p-fluid p-formgrid p-grid">
                                <div class="p-field p-col-5" style="display: flex; flex-direction: column; place-content: space-evenly;">
                                    <p-radioButton
                                        (onClick)="onClickTipoValorReducao(1)"
                                        [disabled]="beneficioImplantado"
                                        name="tipoReducao"
                                        inputId="FIXO"
                                        label="Fixo"
                                        value="FIXO"
                                        formControlName="tipoReducao"
                                        ></p-radioButton>

                                        <p-radioButton
                                        (onClick)="onClickTipoValorReducao(2)"
                                        [disabled]="beneficioImplantado"
                                        name="tipoReducao"
                                        label="Percentual"
                                        inputId="PERCENTUAL"
                                        value="PERCENTUAL"
                                        formControlName="tipoReducao"
                                        ></p-radioButton>
                                </div>
                                <div class="p-field p-col-7">
                                    <label>{{this.formGroupReferencias.value.tipoReducao == 'FIXO' ? 'Valor R$ *' : 'Percentual % *'}}</label>
                                        <p-inputNumber
                                        *ngIf="mode"
                                        formControlName="valorReducao"
                                        maxlength="15"
                                        [mode]="mode"
                                        [currency]="currency"
                                        [locale]="locale"
                                        [maximizable]="true"
                                        [baseZIndex]="90"
                                        [draggable]="false"
                                        [resizable]="false"
                                      ></p-inputNumber>
                                      <p-inputNumber
                                        suffix="%"
                                        maxlength="4"
                                        [max]="100"
                                        *ngIf="!mode"
                                        step="0,01"
                                        value="0,01"
                                        min="0,01"
                                        max="100,00"
                                        formControlName="valorReducao"
                                      ></p-inputNumber>
                                    <p class="erro-validacao" *ngIf="(formGroupReferencias.controls.valorReducao.touched)
                                        && formGroupReferencias.controls.valorReducao.errors?.required">
                                        Campo obrigatório
                                    </p>
                                </div>
                            </div>
                        </p-fieldset>
                    </div>
                    <div class="p-field p-col-5">
                        <p-fieldset legend="Vigência*">
                            <div class="p-fluid p-formgrid p-grid">
                                <div class="p-field p-col-6" style="display: flex; flex-direction: column; place-content: space-evenly;">
                                    <label title="dataInicioVigencia">Início*</label>
                                    <p-calendar
                                            [disabled]="beneficioImplantado"
                                            appendTo="body"
                                            dateFormat="dd/mm/yy"
                                            [showIcon]="true"
                                            formControlName="dataInicioVigencia"
                                            (onChange)="onSelectDataInicioVigencia($event)"
                                    ></p-calendar>
                                    <p class="erro-validacao" *ngIf="(formGroupReferencias.controls.dataInicioVigencia.touched) 
                                        && formGroupReferencias.controls.dataInicioVigencia.errors?.required">
                                        Campo obrigatório
                                    </p>
                                    <p class="erro-validacao"
                                    *ngIf="(formGroupReferencias.controls.dataInicioVigencia.touched) 
                                            && formGroupReferencias.hasError('intervalDateOneMonth')
                                            && !formGroupReferencias.hasError('intervalDateOneYear')">
                                        A data de Inicio não pode ser superior a 1 Mês
                                    </p>
                                    <p class="erro-validacao"
                                        *ngIf="(formGroupReferencias.controls.dataInicioVigencia.touched) 
                                                && formGroupReferencias.hasError('intervalDateOneYear')">
                                        A data de Inicio não pode ser inferior a 1 Ano
                                    </p>
                                </div>
                                <div class="p-field p-col-6">
                                    <label title="dataFimVigencia">Fim da Vigência</label>
                                    <p-calendar
                                            dateFormat="dd/mm/yy"
                                            appendTo="body"
                                            [showIcon]="true"
                                            formControlName="dataFimVigencia"
                                            (onChange)="onSelectDataFimVigencia($event)"
                                    ></p-calendar>
                                    <p class="erro-validacao" *ngIf="(formGroupReferencias.controls.dataInicioVigencia.touched) 
                                        && formGroupReferencias.hasError('dataFimVigencia')">
                                        Campo Fim menor que o campo Início
                                    </p>
                                </div>
                            </div>
                        </p-fieldset>
                    </div>
                </div>
            </div>
            <div class="p-field p-col-8 p-md-8">
                <label>Tributo*</label>
                <p-dropdown
                    optionLabel="sigla"
                    optionValue="tributoId"
                    showClear="true"
                    [autoDisplayFirst]="false"
                    [options]="tributos"
                    formControlName="tributoId"
                    [disabled]="beneficioImplantado"
                ></p-dropdown>
                <p class="erro-validacao" *ngIf="(formGroupReferencias.controls.tributoId.touched) 
                    && formGroupReferencias.controls.tributoId.errors?.required">
                    Campo obrigatório
                </p>
            </div>
            <div class="p-field p-col-3 p-md-3" style="align-self: flex-end; text-align: right;">
                <div class="field-checkbox p-mb-1">
                    <p-checkbox
                    [disabled]="beneficioImplantado"
                    binary="false"
                    trueValue="1"
                    falseValue="0"
                    formControlName="pagamentoIntegral"
                    label="Exige Pagamento Integral?"
                    (onChange)="changeCheckbox($event)">
                    </p-checkbox>
                </div>
            </div>
        </div>
    </div>
</form>