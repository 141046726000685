import { BaseLegal } from "./base-legal";

export class Transacao {
    transacaoId: number;
    //descricao: string;
    baseLegal: BaseLegal;
    constructor(init?: Partial<Transacao>) {
        if (init) {
            Object.assign(this, init);
        } else {
            this.transacaoId = null;
            //this.descricao = null;
            this.baseLegal = null;
        }
    }
}
