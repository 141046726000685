export class Tributo {
    tributoId: number;
    descricaoCompleta: string;
    descricaoResumida: string;
    sigla: string;
    constructor(init?: Partial<Tributo>) {
        if (init) {
            Object.assign(this, init);
        } else {
            this.tributoId = null;
            this.descricaoCompleta = null;
            this.descricaoResumida = null;
            this.sigla = null;
        }
    }
}
