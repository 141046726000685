<div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-2 drag-column border-formula">
        <p-radioButton name="tipoRegra" (onClick)="limpar()" value="FORMULA" [(ngModel)]="tipoRegra" label="Fórmula">
        </p-radioButton>
        <p-radioButton name="tipoRegra" (onClick)="limpar()" value="CONDICIONAL" [(ngModel)]="tipoRegra"
            label="Condicional"></p-radioButton>
        <div *ngFor="let command of commands">
            <div pDraggable="aa" (onDragStart)="dragStart($event, command)" (onDragEnd)="dragEnd($event)">
                <div class="command-style command-item-detail"><em [class]="command.icon"></em><strong>
                        {{command.name}}</strong></div>
            </div>
        </div>
    </div>
    <div class="p-field p-col-9 drop-column border-formula" pDroppable="aa" (onDrop)="drop($event)">
        <fieldset style="min-height: 100px; padding: 10px;" class="form-group border p-3" *ngIf="formulas && bloqueiFormulaPai">
            <legend class="w-auto px-2">Fórmula</legend>
            <app-formula [formulas]="formulas" [groupArr]="groupArr"></app-formula>
        </fieldset>
        <div *ngIf="condicionais">
            <fieldset *ngFor="let condicional of condicionais" style="padding: 10px;" class="form-group border p-3">
                <legend class="w-auto px-2">{{condicional.conditionType}}</legend>
                <app-conditional [groupArr]="groupArr" [conditions]="condicional.children"></app-conditional>
                <ul *ngIf="condicional.returns" style="padding: 0;">
                    <li style="padding: 0; margin-left: 0;">
                        <div class="p-col-12">
                            <fieldset style="min-height: 100px; padding: 10px;" class="form-group border p-3">
                                <legend class="w-auto px-2">Fórmula</legend>
                                <app-formula [operandoExpRelacional]="true" [formulas]="condicional.returns" [groupArr]="groupArr"></app-formula>
                            </fieldset>
                        </div>
                    </li>
                </ul>
            </fieldset>

        </div>

    </div>
</div>

<div class="p-field p-col-12 p-md-12" style="margin-top: 10px; text-align: right">
    <button pButton type="button" icon="" class="p-mr-1" label="Validar Regra" (click)="validRule()"></button>
    <button pButton type="button" icon="" class="p-mr-1" label="Limpar" (click)="limpar()"></button>
</div>

<p-dialog header="Validação de SQL" [modal]="true" [(visible)]="sqlVisible">
    <div class="p-md-12">
        <div style="height: 250px;overflow: scroll">
            <pre>
                <code [highlight]="sqlReturn"></code>
            </pre>
        </div>
    </div>
    <div class="p-md-12" *ngIf="erroValidacao">
        <div style="height: 150px;overflow: scroll">
            <pre style="color: red">{{erroValidacao}}</pre>
        </div>
    </div>
</p-dialog>