import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-visualiza-config-conta",
    templateUrl: "./visualiza-config-conta.component.html",
    styleUrls: ["./visualiza-config-conta.component.scss"],
})
export class VisualizaConfigContaComponent implements OnInit {

    conta: any;

    constructor() {}

    ngOnInit(): void {
        this.conta = JSON.parse(localStorage.getItem("consultaContaObj"));
    }
}
