import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Pagination } from "src/app/shared/pagination/pagination";
import { AlertsUtil } from "src/app/utils/alerts/alerts.util";
import { ConsultaContaCorrenteService } from "../../../services/consulta-conta-corrente.service";
import { Router } from "@angular/router";
import { KeycloakService } from "keycloak-angular";
import { MenuResetPageIndexService } from "src/app/menu/menu-reset-page.service";

@Component({
    selector: "ccp-pesquisa-contribuinte",
    templateUrl: "./pesquisa-contribuinte.component.html",
    styleUrls: ["./pesquisa-contribuinte.component.scss"],
})
export class PesquisaContribuinteComponent implements OnInit {
    @Output() outEtapaVeiculo = new EventEmitter();

    title: string = "Pesquisar Contribuinte";
    titleSelecionarContribuinte: string = "Selecionar Contribuinte";
    contribuinteList: any[] = [];
    valueIdentificacao: any;
    contribuinteValue: any;
    pagination: Pagination<any>;
    placeholder: any = "";
    ipvaList: any[] = [];
    blockedDocument: boolean;
    maskCustom: any = "";
    valueMask: any;
    max: any;
    tipoPessoa: any = null;
    msgNenhumRegistroEncontrado = `Não foi encontrado nenhum registro para o parâmetro informado.`;
    valueNumber: boolean;
    loginContribuinte: boolean;
    userProfile: any;
    token: any;
    perfilContribuinte: boolean;

    constructor(
        private alertsUtil: AlertsUtil,
        private keycloakService: KeycloakService,
        private router: Router,
        private consultaService: ConsultaContaCorrenteService
    ) {
    }

    ngOnInit(): void {
        localStorage.removeItem('filtrosCCAnterior');
        this.pagination = new Pagination<any>();
        this.pagination.size = 20;
        this.contribuinteList = [
            {
                value: "cpfCnpj",
                label: "CPF",
            },
            {
                value: "cpfCnpj",
                label: "CNPJ",
            },
            {
                value: "baseCnpj",
                label: "Base CNPJ",
            },
            {
                value: "nome",
                label: "Nome/Nome Empresarial",
            },
            {
                value: "placa",
                label: "Placa",
            },
            {
                value: "chassi",
                label: "Chassi",
            },
            {
                value: "renavam",
                label: "Renavam",
            },
            {
                value: "inscricaoEstadual",
                label: "Inscrição Estadual",
            },
        ];
        this.decodificaToken();
    }

    decodificaToken() {
        if (this.keycloakService.isLoggedIn()) {
            const token: any = this.keycloakService.getKeycloakInstance();
            const tokenDecode = token.tokenParsed;
            const clientId = token.clientId;

            this.userProfile = {
                roles: tokenDecode.resource_access,
            };

            const roles = this.userProfile.roles[clientId].roles;

            this.perfilContribuinte = roles.indexOf('PF_009') > -1;

            if(roles.length === 1 && this.perfilContribuinte){
                this.loginContribuinte = true;
                this.verificaLoginContribuinte(tokenDecode);
            }
        }
    }

    verificaLoginContribuinte(tokenDecode) {

        this.valueIdentificacao = this.cpfOuCnpjlogin(tokenDecode);
        /**se contribuinte */
        this.valueMask = true;
        this.valueNumber = false;
        if(this.valueIdentificacao.length === 11) {
            this.contribuinteValue = {
                value: "cpfCnpj",
                label: "CPF",
            };
            this.maskCustom = "999.999.999-99";
            this.tipoPessoa = "FISICA";

        } else if(this.valueIdentificacao.length === 14) {
            this.contribuinteValue = {
                value: "cpfCnpj",
                label: "CNPJ",
            };
            this.maskCustom = "99.999.999/9999-99";
            this.tipoPessoa = "JURIDICA";
        }
        this.searchConta();
    }

    cpfOuCnpjlogin(tokenDecode){
        if(tokenDecode.preferred_username.length === 11 || tokenDecode.preferred_username.length === 14) {
            return tokenDecode.preferred_username;
        }
        return tokenDecode.cpfCnpj;
    }

    paramContribuinte(ev) {
        this.valueIdentificacao = null;

        const vogal =
            ev.value?.label == "Base CNPJ" ||
            ev.value?.label == "Placa" ||
            ev.value?.label == "Inscrição Estadual"
                ? "a"
                : "o";
        const msgParam = ev.value?.label;
        let capitalized: any = "";
        if (msgParam?.length > 0) {
            capitalized = msgParam[0]?.toUpperCase() + msgParam.substr(1); // Ricardo
        }

        this.placeholder = `Informe ${vogal} ${capitalized}`;

        if (ev.value == null) {
            this.placeholder = "";
        }

        /**monta mascara */
        switch (ev.value?.label) {
            case "CPF":
                this.maskCustom = "999.999.999-99";
                this.valueMask = true;
                this.tipoPessoa = "FISICA";
                this.valueNumber = false;
                break;
            case "CNPJ":
                this.maskCustom = "99.999.999/9999-99";
                this.valueMask = true;
                this.tipoPessoa = "JURIDICA";
                this.valueNumber = false;
                break;
            case "Base CNPJ":
                this.maskCustom = "99.999.999";
                this.valueMask = true;
                this.tipoPessoa = "JURIDICA";
                this.valueNumber = false;
                break;
            case "Nome/Nome Empresarial":
                this.valueMask = false;
                this.max = 150;
                this.tipoPessoa = null;
                this.valueNumber = false;
                break;
            case "Placa":
                this.max = 7;
                this.valueMask = false;
                this.tipoPessoa = null;
                this.valueNumber = false;
                break;
            case "Chassi":
                this.max = 21;
                this.valueMask = false;
                this.tipoPessoa = null;
                this.valueNumber = false;
                break;
            case "Renavam":
                this.max = 11;
                this.valueMask = false;
                this.tipoPessoa = null;
                this.valueNumber = true;
                this.max = 22;
                break;
            case "Inscrição Estadual":
                this.valueMask = false;
                this.tipoPessoa = null;
                this.valueNumber = true;
                this.max = 10;
                break;
            default:
                this.valueMask = false;
                break;
        }
    }

    limparPesquisa() {
        this.ipvaList = [];
        this.valueIdentificacao = null;
        this.contribuinteValue = null;
        this.placeholder = "";
        this.pagination = new Pagination<any>();
    }

    onPageChange(e) {
        this.pagination.pageable.pageNumber = e.page;
        this.pagination.size = e.rows;
        this.pesquisar();
    }

    pesquisar() {
        if (this.validacaoCaractere()) {
            return;
        }
        const objparams = {
            cpfCnpj:
                this.contribuinteValue.value == "cpfCnpj"
                    ? this.valueIdentificacao.replace(/[^a-zA-Z0-9]/g, "")
                    : null,
            baseCnpj:
                this.contribuinteValue.value == "baseCnpj"
                    ? this.valueIdentificacao.replace(/[^a-zA-Z0-9]/g, "")
                    : null,
            placa:
                this.contribuinteValue.value == "placa"
                    ? this.valueIdentificacao
                    : null,
            chassi:
                this.contribuinteValue.value == "chassi"
                    ? this.valueIdentificacao.replace(/[^a-zA-Z0-9]/g, "")
                    : null,
            renavam:
                this.contribuinteValue.value == "renavam"
                    ? this.valueIdentificacao
                    : null,
            nome:
                this.contribuinteValue.value == "nome"
                    ? this.valueIdentificacao
                    : null,
            inscricaoEstadual:
                this.contribuinteValue.value == "inscricaoEstadual"
                    ? this.valueIdentificacao
                    : null,
            tipoPessoa: this.tipoPessoa,
        };

        Object.keys(objparams).forEach((key) => {
            if (objparams[key] === null || objparams[key] === "") {
                delete objparams[key];
            }
        });
        setTimeout(() => {
            this.searchConta(objparams);
        }, 300);
    }

    validacaoCaractere() {
        if (
            this.contribuinteValue?.value == "nome" &&
            this.valueIdentificacao?.length < 3
        ) {
            return true;
        }
        return false;
    }

    searchConta(objparams?) {
        this.blockedDocument = true;
        this.consultaService
            .getContribuinteSearchPage(objparams, this.pagination)
            .subscribe(
                (response) => {
                    this.blockedDocument = false;
                    this.pagination = response;
                    this.ipvaList = response.content;
                    if (this.ipvaList.length == 0) {
                        this.alertsUtil.warning(
                            this.msgNenhumRegistroEncontrado
                        );
                    }
                },
                (error) => {
                    this.blockedDocument = false;
                    this.alertsUtil.handleError(error);
                }
            );
    }

    /**Valida Contribuinte sem contas cadastradas  */
    irSelecaoConta(contribuinte) {
        localStorage.setItem('contribuinte', JSON.stringify(contribuinte));
        localStorage.setItem('valueFiltroPrincipal', this.valueIdentificacao);
        localStorage.setItem('razaoFiltroPrincipal', contribuinte.nome);
        localStorage.setItem('labelFiltroPrincipal', this.contribuinteValue.label);

        this.outEtapaVeiculo.emit(1);
    }

    inserirMascara(cpfOuCnpj) {
        if (cpfOuCnpj.length == 11) {
            const maskCpf = cpfOuCnpj.replace(
                /(\d{3})(\d{3})(\d{3})(\d{2})/g,
                "$1.$2.$3-$4"
            );
            return maskCpf;
        } else if (cpfOuCnpj.length == 14) {
            const maskCnpj = cpfOuCnpj.replace(
                /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                "$1.$2.$3/$4-$5"
            );
            return maskCnpj;
        }
    }

    consultaDebitoContribuinte(contribuinte) {
        localStorage.setItem("contribuinte", JSON.stringify(contribuinte));
        this.router.navigateByUrl(
            `/consultar-debito-contribuinte/${contribuinte?.baseCnpj}`
        );
    }
}
