import * as BaseLegalSearchModalActions from './base-legal-search-modal-actions';

const baseLegalSearchModalInitialstate = {
    displayMaximizable: false
};

// tslint:disable-next-line:max-line-length
export function baseLegalSearchModalReducer(state = baseLegalSearchModalInitialstate, action: BaseLegalSearchModalActions.SetDisplayMaximizable) {
    switch (action.type) {
        case BaseLegalSearchModalActions.SET_DISPLAY_MAXIMIZABLE:
            return {
                ...state,
                displayMaximizable: action.value
            };
        default:
            return state;
    }
}
