import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseLegal } from "src/app/models/base-legal";
import { Transacao } from "src/app/models/transacao";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class TransacaoService {
    transacao: Transacao;
    httpOptions = {
        headers: new HttpHeaders({
            "Content-Type": "application/json",
        }),
    };

    constructor(private http: HttpClient) {
        this.transacao = new Transacao();
        this.transacao.baseLegal = new BaseLegal();
    }

    getTransacaoByIdEventos(eventoId, codigo = null, nome = null): Observable<Array<any>> {
        let params = new HttpParams();
        if (nome) {
            params = params.append("nome", nome);
        }

        if (codigo) {
            params = params.append("codigo", codigo.toString());
        }
        return this.http.get<Array<any>>(
            `${environment.baseUrl}/transacao/get-by-eventos/${eventoId}`,
            { params }
        );
    }

    ativaInativaTransacao(transacaoId: number): Observable<void> {
        return this.http.put<void>(
            `${environment.baseUrl}/transacao/${transacaoId}/ativa-inativa`,
            {}
        );
    }

    operacaoTransacao(): Observable<Array<any>> {
        return this.http.get<Array<any>>(
            `${environment.baseUrl}/enums/transacao/operacao`
        );
    }

    getSituacao(): Observable<Array<any>> {
        return this.http.get<Array<any>>(
            `${environment.baseUrl}/enums/situacao`
        );
    }

    getSimNao(): Observable<Array<any>> {
        return this.http.get<Array<any>>(
            `${environment.baseUrl}/enums/sim-nao`
        );
    }

    getTipoTransacao(): Observable<Array<any>> {
        return this.http.get<Array<any>>(
            `${environment.baseUrl}/enums/transacao/tipo`
        );
    }

    getTipoHonorarios(): Observable<Array<any>> {
        return this.http.get<Array<any>>(
            `${environment.baseUrl}/enums/tipo-honorarios`
        );
    }

    getTipoCconsolidado(): Observable<Array<any>> {
        return this.http.get<Array<any>>(
            `${environment.baseUrl}/enums/tipo-consolidado`
        );
    }

    getEnumSimNao(): Observable<Array<any>> {
        return this.http.get<Array<any>>(
            `${environment.baseUrl}/enums/sim-nao`
        );
    }

    getTipoCredito() {
        return this.http.get<Array<any>>(`${environment.baseUrl}/tipo-credito`);
    }

    postTransacao(transacao): Observable<any> {
        return this.http.post<Observable<any>>(
            `${environment.baseUrl}/transacao/lancamento`,
            JSON.stringify(transacao),
            this.httpOptions
        );
    }

    putTransacao(transacaoId, transacao) {
        return this.http.put<any>(
            `${environment.baseUrl}/transacao/lancamento/${transacaoId}`,
            transacao
        );
    }

    getTransacao(transacaoId: number): Observable<any> {
        return this.http.get<any>(
            `${environment.baseUrl}/transacao/${transacaoId}`
        );
    }

    getNaturezaItemCalculo(): Observable<Array<any>> {
        return this.http.get<Array<any>>(
            `${environment.baseUrl}/natureza-item-calculo`
        );
    }

    getTiposDatasReferencia(): Observable<Array<any>> {
        return this.http.get<Array<any>>(
            `${environment.baseUrl}/tipos-datas-referencias`
        );
    }

    getTiposContagens(): Observable<Array<any>> {
        return this.http.get<Array<any>>(
            `${environment.baseUrl}/tipos-contagens`
        );
    }

    getEnumTipoAliquota() {
        return this.http.get<any>(`${environment.baseUrl}/enums/aliquota/tipo`);
    }

    getEnumPeriodicidadeIncidencia() {
        return this.http.get<any>(
            `${environment.baseUrl}/enums/periodicidade-incidencia`
        );
    }

    getIndiceList() {
        return this.http.get<any>(`${environment.baseUrl}/indice`);
    }

    getIndiceById(id) {
        return this.http.get<any>(`${environment.baseUrl}/indice/id/${id}`);
    }

    getRegraVencimentoList() {
        return this.http.get<any>(`${environment.baseUrl}/regras-vencimentos`);
    }

    getDataReferenciaList() {
        return this.http.get<any>(
            `${environment.baseUrl}/tipos-datas-referencias`
        );
    }

    getTipoContagem() {
        return this.http.get<any>(`${environment.baseUrl}/tipos-contagens`);
    }

    saveOrUpdateRegraCalculo(regraCalculo) {
        if (regraCalculo.id) {
            return this.http.put<any>(
                `${environment.baseUrl}/transacao-regra-calculo`,
                regraCalculo
            );
        }
        return this.http.post<any>(
            `${environment.baseUrl}/transacao-regra-calculo`,
            regraCalculo
        );
    }

    getRegraCalculoByTransacaoId(transacaoId) {
        return this.http.get(
            `${environment.baseUrl}/transacao-regra-calculo/id/${transacaoId}`
        );
    }

    getRegraReducaoProgressivaByTransacaoId(transacaoId) {
        return this.http.get<any>(
            `${environment.baseUrl}/transacao/${transacaoId}/regra-reducao-progressiva`
        );
    }

    getAllEventos(): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${environment.baseUrl}/eventos`);
    }

    getTipoDocumento(): Observable<Array<any>> {
        return this.http.get<Array<any>>(
            `${environment.baseUrl}/tipos-numero-documento`
        );
    }

    getTItemComposicao(): Observable<Array<any>> {
        return this.http.get<Array<any>>(
            `${environment.baseUrl}/item-composicao`
        );
    }

    getEnumTipoCapitalizacao() {
        return this.http.get<any>(
            `${environment.baseUrl}/enums/tipo-capitalizacao`
        );
    }

    deletarTransacao(id){
        return this.http.delete<string[]>(
            `${environment.baseUrl}/transacao/${id}`
        );
    }
}
