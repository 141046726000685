import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ConfigurarContaCorrenteService } from 'src/app/component/configurar-conta-corrente/configurar-conta-corrente.service';
import { TransacaoService } from 'src/app/component/transacao-financeira/services/transacao.service';
import { EventoBeneficioSaveAllRequest } from 'src/app/models/evento-beneficio-save-all-request';
import { EventosSearchRequest } from 'src/app/request/eventos-search-request';
import { UtilsService } from 'src/app/shared/utils.service';
import swal from "sweetalert2";
import * as ConfigContaSearchModalActions from '../../../config-conta/config-conta-search-modal/config-conta-search-modal-actions';
import { BeneficioFiscalService } from '../../service/beneficio-fiscal.service';


@Component({
    selector: "evento-beneficio-fiscal",
    templateUrl: "./evento-beneficio-fiscal.component.html",
    styleUrls: ["./evento-beneficio-fiscal.component.scss"],
})
export class EventoBeneficioFiscalComponent implements OnInit {
    eventoBeneficioId: any;
    eventosId: any;
    submitted = false;
    situacao: any;
    formGroupEvento: FormGroup;
    situacaoTransacaoEnum: any[];
    eventoCarregado: any[];
    eventosList: any[];
    tpCadastroCarregado: any[];
    tipoCadastroList: any[];
    tributoCarregado: any[];
    tipoTributoDominio: any[];
    tipoCreditoDominio: any[];
    listCreditoValue: any[] = [];
    tipoConsolidadoEnum: any[] = [];
    tipoHonorarioEnum: any[] = [];
    simNaoEnum: any[] = [];
    exibeHonorarios: number;
    validFormHonorario: boolean = true;
    mode: any;
    currency: string;
    locale: string;
    @Input() configEvento: any;
    @Output() outProximaAba = new EventEmitter();
    @Output() limpaCampos = new EventEmitter();
    @Output() outValidacaoTpEvento = new EventEmitter();
    @Output() outValidacaoTributo = new EventEmitter();
    @Output() outValidacaoTpCadastro = new EventEmitter();
    @Input() descricaoConta: any;
    eventoBeneficioSaveAllRequest: EventoBeneficioSaveAllRequest;
    eventosSearchRequest: EventosSearchRequest;
    objParams: { eventoBeneficioId: any; };
    objParamsConfigConta: { configContaId: any; };
    arrayTemp: any = [];
    implantadoInput: any ;
    validaProximaAux: boolean;
    eventoBeneficio: any;

    constructor(
        private formBuilder: FormBuilder,
        public transacaoService: TransacaoService,
        private configurarContaCorrenteService: ConfigurarContaCorrenteService,
        private util: UtilsService,
        private activatedRouter: ActivatedRoute,
        private beneficioFiscalService: BeneficioFiscalService,
        private storeConfigContaSearchModal: Store<{
            configContaSearchModal: { displayMaximizable: boolean };
        }>,
        private router: Router
    ) {
        this.activatedRouter.params.subscribe((eventoId) => {
            this.eventoBeneficioId = eventoId.id;
        });
    }

    ngOnInit(): void {
        this.carregaCampos();
        this.eventoBeneficioSaveAllRequest = new EventoBeneficioSaveAllRequest();
        this.eventosSearchRequest = new EventosSearchRequest();
        this.closeModal();
    }

    carregaCampos(){
        this.eventosId = null;
        this.getEnums();
        this.getTipoCadastro();
        this.iniciaForm(this.configEvento);
        if(this.eventoBeneficioId && !this.configEvento){
            this.getDadosEvendoPut();
            this.getDadosOrigemCredito();
        }
        if(!this.descricaoConta){
            this.descricaoConta = '';
        }
    }

    getDadosEvendoPut(){
        if(this.eventoBeneficioId){
            this.beneficioFiscalService.getEventoBeneficio(this.eventoBeneficioId).subscribe((response: any) => {
                let obj = this.listCreditoValue;
                let objEvento = {...response,...{tipoCredito: obj}};
                this.iniciaForm(objEvento);
                this.getDescConfigConta();
                this.implantadoInput = response.implantado;
                this.eventoBeneficio = response;
            }, error => {
                this.handleError(error);
            });
        }
    }


    getDadosOrigemCredito(){
        let arrayAux = [];
        this.objParams = {
            eventoBeneficioId: this.eventoBeneficioId,
        };
        this.beneficioFiscalService.getOrigemCredito(this.objParams).subscribe(response => {
            response.forEach(element => {
                arrayAux.push(element.tipoCredito.tipoCreditoId);
            });
            this.eventoFormControl.tipoCredito.setValue(arrayAux);
        });
    }


    showBasicDialog() {
        this.storeConfigContaSearchModal.dispatch(
            new ConfigContaSearchModalActions.SetDisplayMaximizable(true)
        );
    }

    proximaAba() {
        if(this.eventoFormControl.lancaHonorarios.value == false || this.eventoFormControl.lancaHonorarios.value == 'NAO'){
            this.eventoFormControl.valorHonorarios.setValue(null);
            this.eventoFormControl.configConta.setValue(null);
            this.formGroupEvento.get("valorHonorarios").clearValidators();
            this.formGroupEvento.get("configConta").clearValidators();
            this.formGroupEvento.get("valorHonorarios").updateValueAndValidity();
            this.formGroupEvento.get("configConta").updateValueAndValidity();
        }
        if(this.validaForm()){
            this.outProximaAba.emit(1);
        }
    }

    iniciaForm(configEvento: any) {
        this.formGroupEvento = this.formBuilder.group({
            configConta: [configEvento?.configConta?.configContaId ? configEvento?.configConta?.configContaId : configEvento?.configConta ? configEvento?.configConta : null],
            dataFimVigencia: [
                configEvento?.dataFimVigencia
                ? this.util.convertToEditBrUniversal(configEvento.dataFimVigencia) : null],
            dataInicioVigencia: [
                configEvento
                    ? new Date(configEvento.dataInicioVigencia)
                    : null,
                [Validators.required],
            ],
            descricao: [
                configEvento ? configEvento.descricao : null,
                [Validators.required, Validators.maxLength(100)],
            ],
            eventoBeneficioId: [configEvento ? configEvento.eventoBeneficioId : null],
            eventos: [
                configEvento?.eventos ? configEvento?.eventos
                    : configEvento?.evento ? configEvento?.evento : null, Validators.required,
            ],
            lancaHonorarios: [configEvento?.lancaHonorarios == true ? 'SIM' : 'NAO'],
            situacao: [configEvento ? configEvento.situacao: null],
            // temBeneficioFiscal: [null],
            tipoConsolidacao: [configEvento ? configEvento.tipoConsolidacao: "INDIVIDUALIZADA", Validators.required],
            tipoHonorarios: [configEvento ? configEvento.tipoHonorarios: "FIXO"],
            tributo: [
                configEvento?.tributo ? configEvento?.tributo
                : configEvento?.tributo?.tributoId ? configEvento?.tributo.tributoId : null, Validators.required],
            valorHonorarios: [configEvento ? configEvento.valorHonorarios: null],

            tipoCredito: [configEvento ? configEvento.tipoCredito : null, Validators.required],
            tipoCadastro: [
                configEvento?.tipoCadastro ? configEvento?.tipoCadastro
                : configEvento?.tipoCadastro.tipoCadastroId ? configEvento?.tipoCadastro.tipoCadastroId: null, Validators.required]
        });
        this.tributoCarregado = configEvento?.tributo;
        this.eventoCarregado = configEvento?.eventos ? configEvento?.eventos : configEvento?.evento ? configEvento?.evento : null;
        this.tpCadastroCarregado = configEvento?.tipoCadastro;
        this.validaCheckHonorarios(configEvento);
        this.eventoFormControl.valorHonorarios.setValue(configEvento?.valorHonorarios);
    }

     get tipoCreditoSelected () {
        return this.formGroupEvento ? this.formGroupEvento.controls['tipoCredito'] : null;
    }

    get eventoFormControl() {
        return this.formGroupEvento.controls;
    }

    validaCheckHonorarios(configEvento){
        if(!configEvento) {
            // this.changeCheckbox({ checked: true });
            this.changeValorHonorario(1);
            this.eventoFormControl.lancaHonorarios.setValue(true);
        } else {
            this.changeValorHonorario(this.eventoFormControl.tipoHonorarios.value === "FIXO" ? 1 : 2 )
            if(configEvento.lancaHonorarios == 'SIM' ||configEvento.lancaHonorarios == true ){
                this.changeCheckbox({ checked: true });
                this.eventoFormControl.lancaHonorarios.setValue(true);
            } else {
                this.eventoFormControl.lancaHonorarios.setValue(false);
                this.changeCheckbox({ checked: false });
            }
        }
    }

    changeCheckbox(evento) {
        if (evento.checked && this.eventoFormControl.lancaHonorarios.value == true || this.eventoFormControl.lancaHonorarios.value == 'SIM') {
            this.formGroupEvento
                .get("valorHonorarios")
                .setValidators(Validators.required);
            this.formGroupEvento
                .get("configConta")
                .setValidators(Validators.required);
                if(this.eventoFormControl.tipoHonorarios.value == '2'){
                    this.formGroupEvento.get("valorHonorarios").setValidators([Validators.required, Validators.maxLength(2)]);
                }
        } else if(!evento.checked) {
            /**resetar campos check honorarios */
            this.eventoFormControl.valorHonorarios.setValue(null);
            this.eventoFormControl.configConta.setValue(null);
            this.formGroupEvento.get("valorHonorarios").clearValidators();
            this.formGroupEvento.get("configConta").clearValidators();
        }
        this.formGroupEvento.get("valorHonorarios").updateValueAndValidity();
        this.formGroupEvento.get("configConta").updateValueAndValidity();
    }

    async getEnums() {
        this.tipoCreditoDominio = await this.transacaoService
            .getTipoCredito()
            .toPromise();
        this.situacaoTransacaoEnum = await this.transacaoService
            .getSituacao()
            .toPromise();
        this.tipoConsolidadoEnum = await this.transacaoService
            .getTipoCconsolidado()
            .toPromise();
        this.tipoHonorarioEnum = await this.transacaoService
            .getTipoHonorarios()
            .toPromise();
        this.simNaoEnum = await this.transacaoService
                .getEnumSimNao()
                .toPromise();
        this.eventosList = await this.beneficioFiscalService
            .getTpoEvento(this.eventosRequest())
            .toPromise();
        this.tipoTributoDominio = await this.configurarContaCorrenteService
            .getTributos()
            .toPromise();
        this.situacaoTransacaoEnum = this.situacaoTransacaoEnum.reverse();
        this.valorPadraoCampos();
        return new Promise((resolve) => {
            resolve("");
        });

    }

    eventosRequest(){
       const data = JSON.parse(JSON.stringify(this.eventosSearchRequest));
        data.tipoEventoDescricao = "Benefício Fiscal";
       return data;
    }

    valorPadraoCampos() {
        this.eventoFormControl.situacao.setValue(this.situacaoTransacaoEnum[0]);
    }

    setOrigemCredito(){
        if(!this.eventoBeneficioId) {
            this.eventoBeneficioId = 0;
            }
            this.arrayTemp = this.eventoFormControl.tipoCredito.value;
                for (let i = 0; i < this.arrayTemp.length; i++){
                    this.eventoBeneficioSaveAllRequest.origemCreditoSaveRequests = [
                        ...this.eventoBeneficioSaveAllRequest.origemCreditoSaveRequests,
                        {
                            eventoBeneficioId: this.eventoBeneficioId,
                            origemCreditoId: 0,
                            tipoCreditoId: this.arrayTemp[i]
                        }
                    ];
            }
        return this.eventoBeneficioSaveAllRequest?.origemCreditoSaveRequests;
    }

    validaForm() {
        this.submitted = true;
        if (this.formGroupEvento.valid) {
            return true;
        }
        return false;
    }

    onSelectDataMenor(event?) {
        let dataInicio = this.util.convertDateToSave(
            this.eventoFormControl.dataInicioVigencia.value
        );
        let dataFim = this.util.convertDateToSave(
            this.eventoFormControl.dataFimVigencia.value
        );
        if (dataFim < dataInicio) {
            swal.fire(
                "Erro Vigência",
                "A Data Fim não pode ser inferior a Data Inicio",
                "info"
            ).then((r) => {
                this.eventoFormControl.dataFimVigencia.setValue(null);
            });
        }
        if (dataInicio) {
            this.calculaInterValoDatas(dataInicio);
        }
    }

    calculaInterValoDatas(dataInicio) {
        let dataAtual: any = this.util.convertDateToSave(new Date());

        const now = new Date(); // Data de hoje
        const past = new Date(dataInicio); // Outra data no passado
        const diff = Math.abs(now.getTime() - past.getTime()); // Subtrai uma data pela outra
        const days = Math.ceil(diff / (1000 * 60 * 60 * 24)); // Divide o total pelo total de milisegundos correspondentes a 1 dia. (1000 milisegundos = 1 segundo).

        if (days >= 365) {
            swal.fire(
                "Aviso",
                "A data de Inicio não pode ser inferior a 1 Ano",
                "info"
            ).then((r) => {
                this.eventoFormControl.dataFimVigencia.setValue(null);
            });
        }

        if (dataInicio > dataAtual && days > 30) {
            swal.fire(
                "Aviso",
                "A data de Inicio não pode ser superior a 1 Mês",
                "info"
            ).then((r) => {
                this.eventoFormControl.dataInicioVigencia.setValue(null);
            });
        }
    }

    changeValorHonorario(ev) {
        this.eventoFormControl.valorHonorarios.setValue(null);
        // this.changeCheckbox({ checked: true });
        if (ev == 1) {
            this.mode = "currency";
            this.currency = "BRL";
            this.locale = "pt-BR";
        } else {
            this.mode = null;
        }
    }

    outConfigConta(ev) {
        this.eventoFormControl.configConta.setValue(ev.configContaId);
        this.descricaoConta = ev.nomeCompleto;
        this.closeModal();
    }

    closeModal(){
        this.storeConfigContaSearchModal.dispatch(
            new ConfigContaSearchModalActions.SetDisplayMaximizable(false)
        );
    }

    /**Valida se alterou outras abas */
    validacaoTributo() {
        this.outValidacaoTributo.emit(
            {
                "implantadoInput": this.implantadoInput,
                "valorCarregado": this.tributoCarregado
            }
        );
    }
    /**Valida se alterou outras abas */
    validacaoTpEvento(){
        this.outValidacaoTpEvento.emit(
            {
                "implantadoInput": this.implantadoInput,
                "valorCarregado": this.eventoCarregado
            }
        );
    }
    /**Valida se alterou outras abas */
    validacaoTpCadastro(){
        this.outValidacaoTpCadastro.emit(
            {
                "implantadoInput": this.implantadoInput,
                "valorCarregado": this.tpCadastroCarregado
            }
        );
    }

    handleError(error: any): void {
        if (error.status === 500) {
            swal.fire(
                error.statusText,
                "Ocorreu um erro interno",
                "error"
            ).then((r) => {});
        } else {
            swal.fire({
                title: "Atenção",
                text: `${error.error.message}`,
                icon: "warning",
            });
        }
      }

      getDescConfigConta() {
        const configContaId = this.eventoFormControl.configConta.value;
        if(this.eventoFormControl.configConta.value) {
            this.beneficioFiscalService.getDescConfigConta(configContaId).subscribe((response: any) => {
                const descConta = response.content[0].nomeCompleto;
                this.descricaoConta = descConta;
            }, error => {
                this.handleError(error);
            });
        }
      }

      voltar(){
        this.router.navigateByUrl('evento-beneficio/evento-beneficio-list')
      }

      getTipoCadastro() {
        this.beneficioFiscalService.getTipoCadastro().subscribe(
          (resp: any) => {
              this.tipoCadastroList = resp;
          },
          (erro) => {
            this.handleError(erro);
          }
      );
      }

}
