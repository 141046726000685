import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MenuItem } from "primeng/api";
import { finalize } from "rxjs/operators";
import { EventosFinanceirosService } from "src/app/core/eventos-financeiros.service";
import swal from "sweetalert2";
import { TransacaoService } from "../../services/transacao.service";

class FiltroTransacao {
    codigo: number;
    nome: string;
}

@Component({
    selector: "gerenciar-transacao-financeira",
    templateUrl: "./gerenciar-transacao-financeira.component.html",
    styleUrls: ["./gerenciar-transacao-financeira.component.scss"],
})
export class GerenciarTransacaoFinanceiraComponent implements OnInit {
    tituloPagina: string = "";
    evento: any;
    eventoId: any;
    listaTransacoes = [];
    transacaoSituacao: any[];
    transacaoSimNao: any[];
    eventoOperacao: any[];
    operacaoEnum: any;
    simNaoEnum: any;
    dadosEvento: any;
    carregandoEvento = true;
    filtroTransacao = new FiltroTransacao();
    formGroupTransacao: FormGroup = this.formBuilder.group({
        descricaoTipoEvento: [null],
        descricao: [null],
        operacao: [null],
        geraTransacaoInicial: [null],
    });
    passoAtivo;
    items: MenuItem[] = [
        {
            label: "Selecionar Categoria",
            command: () =>
                this.router.navigate([`/gerenciar-transacao-financeira/selecionar-evento`]),
        },
        { label: "Transação de [NOME]" },
    ];

    constructor(
        private activatedRouter: ActivatedRoute,
        private eventosFinanceiroService: EventosFinanceirosService,
        private formBuilder: FormBuilder,
        private transacaoService: TransacaoService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.activatedRouter.params.subscribe((eventoId) => {
            this.eventoId = eventoId;
            this.getEnums();
        });
    }

    getEnums() {
        this.transacaoService.getSituacao().subscribe((r) => {
            this.transacaoSituacao = r;
            this.transacaoService.getSimNao().subscribe((r) => {
                this.transacaoSimNao = r;
                this.eventosFinanceiroService
                    .operacaoEvento()
                    .subscribe((r) => {
                        this.eventoOperacao = r;
                        this.getEventoById();
                        this.getTransacaoByIdEventos();
                    });
            });
        });
    }

    getEventoById() {
        this.eventosFinanceiroService
            .getEventoById(this.eventoId.eventoId)
            .subscribe(
                (eventosResponse) => {
                    this.evento = eventosResponse;
                    this.tituloPagina = this.evento.descricaoCompleta;
                    this.items[1].label =
                        "Transação de " + this.evento.descricao;
                    this.operacaoEnum = this.eventoOperacao.find(
                        (o) => o.chave == this.evento.operacao
                    ).valor;
                    this.simNaoEnum = this.transacaoSimNao.find(
                        (sn) => sn.chave == this.evento.geraTransacaoInicial
                    ).valor;
                },
                (error) => {
                    let erroMsg;
                    if (error.error.message) {
                        erroMsg = error.error.message;
                    } else {
                        erroMsg = `Erro ao consultar com tipo evento ${this.eventoId.eventoId}`;
                    }
                    swal.fire({
                        title: "Atenção",
                        text: `${erroMsg}`,
                        icon: "warning",
                    });
                }
            );
    }

    getTransacaoByIdEventos() {
        this.carregandoEvento = true;
        this.transacaoService
            .getTransacaoByIdEventos(
                this.eventoId.eventoId,
                this.filtroTransacao.codigo,
                this.filtroTransacao.nome
            )
            .pipe(finalize(() => (this.carregandoEvento = false)))
            .subscribe(
                (response) => {
                    this.listaTransacoes = response;
                },
                (error) => {
                    let erroMsg;
                    if (error.error.message) {
                        erroMsg = error.error.message;
                    } else {
                        erroMsg = `Erro ao consultar transação com eventoId ${this.eventoId.eventoId}`;
                    }
                    swal.fire({
                        title: "Atenção",
                        text: `${erroMsg}`,
                        icon: "warning",
                    });
                }
            );
    }

    ativaInativaTransacao(transacao) {
        let situacao = transacao.situacao == "ATIVA" ? "desativar" : "ativar";
        swal.fire({
            text: `Deseja ${situacao} a transação ${transacao.nomeResumido}?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
        }).then((result) => {
            if (result.value) {
                this.transacaoService
                    .ativaInativaTransacao(transacao.transacaoId)
                    .subscribe(
                        (resp) => {
                            this.getTransacaoByIdEventos();
                        },
                        (error) => {
                            let erroMsg;
                            if (error.error.message) {
                                erroMsg = error.error.message;
                            } else {
                                erroMsg = `Erro ao ativar/inativar transação com id ${transacao.transacaoId}`;
                            }
                            swal.fire({
                                title: "Atenção",
                                text: `${erroMsg}`,
                                icon: "warning",
                            });
                        }
                    );
            } else {
                result.dismiss === swal.DismissReason.cancel;
            }
        });
    }

    pesquisar() {
        this.getTransacaoByIdEventos();
    }

    novaTransacao() {
        this.router.navigate([
            `/gerenciar-transacao-financeira/${this.eventoId.eventoId}/cadastro`,
        ]);
    }

    editarTransacao(transacaoId: number) {
        this.router.navigate([
            `/gerenciar-transacao-financeira/${this.eventoId.eventoId}/cadastro/${transacaoId}`,
        ]);
    }

    deletar(transacao) {
        swal.fire({
            text: `Deseja excluir a transação ${transacao.nomeResumido}?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
        }).then((result) => {
            if (result.value) {
                this.transacaoService
                    .deletarTransacao(transacao.transacaoId)
                    .subscribe((resp) => {
                        if (resp && resp.length > 0) {
                            var list = resp.map((r) => "<li>" + r + "</li>");
                            swal.fire({
                                title: "Atenção",
                                icon: "warning",
                                html: `
                                <p>Não é possível excluir a transação, pois a mesma possui vínculo com os eventos:</p>
                                <ul>
                                ${list}
                                </ul>
                            `,
                            });
                        }
                        this.pesquisar();
                    });
            } else {
                result.dismiss === swal.DismissReason.cancel;
            }
        });
    }
}
