import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import { TransacaoSearchRequest } from 'src/app/request/TransacaoSearchRequest';

@Injectable({
  providedIn: 'root'
})
export class ExtratoContaCorrenteService {

  httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient) {
    
   }

   getTributo(): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${environment.baseUrl}/tributo`);
  }

   getConfigConta(objFilter): Observable<Array<any>> {
    const params = new HttpParams({ fromObject: objFilter});
    return this.http.get<Array<any>>(`${environment.baseUrl}/config-conta?`, {params});
  }

  getEstadoConta(): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${environment.baseUrl}/estado-conta`);
  }

  getTributoByVeiculo(objParams): Observable<Array<any>> {
    const params = new HttpParams({ fromObject: objParams});
    return this.http.get<Array<any>>(`${environment.baseUrl}/tributo`, {params});
  }

  getContaCorrente(objParams): Observable<Array<any>> {
    const params = new HttpParams({ fromObject: objParams});
    return this.http.get<Array<any>>(`${environment.baseUrl}/conta-corrente/search`, {params});
  }

  getContaCorrenteAll(objParams): Observable<Array<any>> {
    const params = new HttpParams({ fromObject: objParams});
    return this.http.get<Array<any>>(`${environment.baseUrl}/conta-corrente/financeiro`, {params});
  }
  
  getContribuinte(cpfCnpj): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${environment.baseUrl}/contribuinte/cadastro-economico/${cpfCnpj}`);
  }

  getContribuinteSearch(objParams): Observable<Array<any>> {
    const params = new HttpParams({ fromObject: objParams});
    return this.http.get<Array<any>>(`${environment.baseUrl}/contribuinte/search`, {params});
    // http://localhost:8081/conta-corrente-api/contribuinte/search?cpfCnpj=12563243000117&tipoPessoa=JURIDICA
  }

  getVeiculo(renavam): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${environment.baseUrl}/ipva/${renavam}`);
  }

  getIpvaSearch(objParams): Observable<Array<any>> {
    const params = new HttpParams({ fromObject: objParams});

    return this.http.get<Array<any>>(`${environment.baseUrl}/ipva/search`, {params});
  }

  getVeiculoSearch(objParams): Observable<any> {
    const params = new HttpParams({ fromObject: objParams});
    return this.http.get<any>(`${environment.baseUrl}/ipva/search`, {params});
  }

}
