export class Contribuinte {
    bairro: string;
    cep: string;
    cidade: number;
    cpfCnpj: number;
    cxPostal: string;
    dddFax: number;
    dddFone: number;
    descPessoa: string;
    descRegime: string;
    descSituacao: string;
    descTipo: string;
    email: string;
    endereco: string;
    fax: string;
    fone: string;
    ie: number;
    ieruc: number;
    nome: string;
    tipoPessoa: string;
    regime: number;
    resLatitude: number;
    resLongitude: number;
    situacao: number;
    tipo: string;
    uf: string;

    constructor(init?: Partial<Contribuinte>) {
        if (init) {
            Object.assign(this, init);
        } else {
            this.uf = null;
            this.tipo = null;
            this.situacao = null;
            this.resLongitude = null;
            this.resLatitude = null;
            this.endereco = null;
            this.regime = null;

            this.tipoPessoa = null;
            this.nome = null;
            this.ieruc = null;
            this.ie = null;
            this.fone = null;
            this.fax = null;
            this.email = null;

            this.descTipo = null;
            this.descSituacao = null;
            this.descRegime = null;
            this.descPessoa = null;
            this.dddFone = null;
            this.dddFax = null;
            this.cxPostal = null;
            this.cpfCnpj = null;

            this.bairro = null;
            this.cep = null;
            this.cidade = null;

        }
    }
}


export class ContribuinteDetails extends Contribuinte{
    bairro: string;
    cep: string;
    cidade: number;
    cpfcnpj: number;
    cxPostal: string;
    dddFax: number;
    dddFone: number;
    descPessoa: string;
    descRegime: string;
    descSituacao: string;
    descTipo: string;
    email: string;
    endereco: string;
    fax: string;
    fone: string;
    ie: number;
    ieruc: number;
    nome: string;
    tipoPessoa: string;
    regime: number;
    resLatitude: number;
    resLongitude: number;
    situacao: number;
    tipo: string;
    uf: string;


}
