import { NgModule } from '@angular/core';
import { CompartilhadoModule } from 'src/app/compartilhado/compartilhado.module';
import { BaseLegalSearchModalComponent } from './base-legal-search-modal/base-legal-search-modal.component';
import { BaseLegalSearchComponent } from './base-legal-search/base-legal-search.component';
import { DialogModule } from 'primeng/dialog';


@NgModule({
  declarations: [
    BaseLegalSearchModalComponent,
    BaseLegalSearchComponent
  ],
  imports: [
    CompartilhadoModule,
    DialogModule
  ],
  exports: [
    BaseLegalSearchModalComponent,
    BaseLegalSearchComponent 
  ]
})
export class BaseLegalModule { }
