<p-toast></p-toast>
<div class="card">
    <p-steps [model]="items" [(activeIndex)]="activeIndex" [readonly]="nextPage" class="time-line"></p-steps>
</div>
<evento-beneficio-fiscal
    (outProximaAba)="outProximaAba($event)"
    (limpaCampos)="resetCampos($event)"
    (outValidacaoTpEvento)="outValidacaoTpEvento($event)"
    (outValidacaoTributo)="outValidacaoTributo($event)"
    (outValidacaoTpCadastro)="outValidacaoTpCadastro($event)"
    *ngIf="activeIndex == 0"
    [configEvento]="configEvento"
    [descricaoConta]="descricaoConta"
    [tipoCreditoTributario]="tipoCreditoTributario"
></evento-beneficio-fiscal>

<app-associar-conta-corrente
    (outProximaAba)="outProximaAba($event)"
    (outAbaAnterior)="outAbaAnterior($event)"
    (outRemovePorId)="outRemoveContaContempladaPorId($event)"
    *ngIf="activeIndex == 1"
    [configEvento]="configEvento"
    [associarContaCorrente]="associarContaCorrente"
></app-associar-conta-corrente>

<app-associar-transacao-e-regra-selecao
    (outProximaAba)="outProximaAba($event)"
    (outAbaAnterior)="outAbaAnterior($event)"
    (outRemovePorId)="outRemoveTransacaoPorId($event)"
    *ngIf="activeIndex == 2"
    [configEvento]="configEvento"
    [associarTransacaoRegra]="associarTransacaoRegra"
></app-associar-transacao-e-regra-selecao>

<app-cadastrar-destinatario-beneficio-fiscal
    (outAbaAnterior)="outAbaAnterior($event)"
    (saveAllRequest)="prepareDestinatarioSaveRequest($event)"
    *ngIf="activeIndex == 3"
    [configEvento]="configEvento"
    [cadastrarDestinatario]="cadastrarDestinatario"
></app-cadastrar-destinatario-beneficio-fiscal>
