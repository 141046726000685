import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { EnumsService } from "src/app/core/enums.service";
import { EstadoContaService } from "src/app/component/estado-conta/estado-conta.service";
import { Pagination } from "src/app/shared/pagination/pagination";
import { AlertsUtil } from "src/app/utils/alerts/alerts.util";
import swal from "sweetalert2";
import {AcoesContaCorrenteHelper} from "../../../shared/acoes-helper/acoes-conta-corrente-helper";

@Component({
    selector: "app-estado-conta-list",
    templateUrl: "./estado-conta-list.component.html",
    styleUrls: ["./estado-conta-list.component.scss"],
})
export class EstadoContaListComponent implements OnInit {
    estadoList = [];
    blockedDocument = false;
    pagination: Pagination<any>;
    situacaoEnum: any[] = [];
    estadoContaId: any = "";
    situacao: any = "";
    descricao: any = "";
    objEstadoParams: { estadoContaId?: any; situacao?: any; descricao?: any };

    constructor(
        private estadoService: EstadoContaService,
        private enumsService: EnumsService,
        private alertsUtil: AlertsUtil,
        private router: Router
    ) {}

    ngOnInit(): void {
        if (!this.podeListarEstadoConta()) {
            this.router.navigate(['/']);
        }

        this.pagination = new Pagination<any>();
        this.pesquisar();
        this.getEnums();
    }

    onPageChange(e) {
        this.pagination.pageable.pageNumber = e.page;
        this.pesquisar();
    }

    novoEvento() {
        this.router.navigateByUrl(
            "config-conta/estado-conta/config-modelo-estado"
        );
    }

    editarEvento(estadoContaId) {
        this.router.navigateByUrl(
            `config-conta/estado-conta/config-modelo-estado/${estadoContaId}`
        );
    }

    pesquisar(objEstadoParams?) {
        if (!objEstadoParams) {
            objEstadoParams = this.objEstadoParams;
        }
        this.blockedDocument = true;
        this.estadoService
            .getModeloEstado(objEstadoParams, this.pagination)
            .subscribe(
                (resp: any) => {
                    this.blockedDocument = false;
                    this.pagination = resp;
                    this.estadoList = resp.content;
                },
                (error) => {
                    this.alertsUtil.handleError(error);
                }
            );
    }

    pesquisaFiltro() {
        this.blockedDocument = false;
        if (this.situacao === null) {
            this.situacao = "";
        }
        if (this.estadoContaId === null) {
            this.estadoContaId = "";
        }
        this.objEstadoParams = {
            estadoContaId: this.estadoContaId,
            situacao: this.situacao,
            descricao: this.descricao,
        };
        this.pagination.pageable.pageNumber = 0;
        this.pesquisar(this.objEstadoParams);
    }

    limparFiltro() {
        this.blockedDocument = false;
        this.estadoContaId = "";
        this.situacao = "";
        this.descricao = "";
        this.objEstadoParams = {
            estadoContaId: this.estadoContaId,
            situacao: this.situacao,
            descricao: this.descricao,
        };
        this.pesquisar(this.objEstadoParams);
    }

    async getEnums() {
        this.situacaoEnum = await this.enumsService.getSituacao().toPromise();
        return new Promise((resolve) => {
            resolve("");
        });
    }

    putAtivaInativa(modeloEstadoId, desc, ativaInativa) {
        swal.fire({
            title: "Aviso",
            text: `Deseja ${ativaInativa} o modelo ${modeloEstadoId} - ${desc}.`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "SIM",
            cancelButtonText: "NÃO",
        }).then((result) => {
            if (result.isConfirmed) {
                this.estadoService.putAtivaInativa(modeloEstadoId).subscribe(
                    (resp) => {
                        this.alertsUtil.saveSuccess("Atualizado");
                        this.objEstadoParams = {};
                        this.pesquisar();
                    },
                    (error) => {
                        this.alertsUtil.handleError(error);
                    }
                );
            }
        });
    }

    delete(estado) {
        this.alertsUtil
            .confirm(
                `
            Deseja excluir o modelo de código ${estado.estadoContaId} - ${estado.descricao} ?`
            )
            .then((result) => {
                if (result.isConfirmed) {
                    this.estadoService.delete(estado.estadoContaId).subscribe(
                        (resp) => {
                            this.alertsUtil
                                .saveSuccess(`Modelo excluído com sucesso`)
                                .then(() => {
                                    this.pesquisar();
                                });
                        },
                        (error) => {
                            this.alertsUtil.handleError(error);
                        }
                    );
                }
            });
    }

    podeCadastrarEstadoConta(): boolean {
        return AcoesContaCorrenteHelper.podeCadastrarEstadoConta;
    }

    podeEditarEstadoConta(): boolean {
        return AcoesContaCorrenteHelper.podeEditarEstadoConta;
    }

    podeExcluirEstadoConta(): boolean {
        return AcoesContaCorrenteHelper.podeExcluirEstadoConta;
    }

    podeListarEstadoConta(): boolean {
        return AcoesContaCorrenteHelper.podeListarEstadoConta;
    }

}
