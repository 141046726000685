import { Content } from '@angular/compiler/src/render3/r3_ast';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ConsultaContaCorrenteService } from 'src/app/component/criacao-conta-corrente/services/consulta-conta-corrente.service';
import { Pagination } from 'src/app/shared/pagination/pagination';
import { UtilsService } from 'src/app/shared/utils.service';
import { AlertsUtil } from 'src/app/utils/alerts/alerts.util';
import { RemoveParamsUtil } from 'src/app/utils/paramsRemove/paramsRemove.util';

@Component({
    selector: "contas-associadas-buscar",
    templateUrl: "./modal-contas-associadas-buscar.component.html",
    styleUrls: ["./modal-contas-associadas-buscar.component.scss"],
})
export class ModalContasAssociadasBuscarComponent implements OnInit {
    @Output() selectConta = new EventEmitter<string>();

    pagination = new Pagination<any>();
    contasSelecionadas: any[] = [];
    visible: boolean;
    valueContribuinte: any;
    blockedDocument: boolean;
    /**Atributos filtro */
    contribuinteValue: any;
    contaValue: any;
    dataInicialReferenciaValue: any;
    dataFinalReferenciaValue: any;
    numeroDocumentoValue: any;
    numeroContaValue: any;
    tipoNumeroDocumentoValue: any;
    /**Dominios */
    contribuinteList: any[] = [];
    contaList: any[];
    tipoDocumentoList: any[];
    consultaContaObj: any;
    valueFiltroTpCadastro: null;
    objTpCadastro: any;

    tipoContaView = 'ipva'
    contasView = [
      {
          valor: 'ipva',
          chave: 'IPVA',
      },
      {
          valor: 'itcd',
          chave: 'ITCD',
      },
      {
          valor: 'icms',
          chave: 'ICMS',
      },
      {
          valor: 'parcelamento',
          chave: 'PARCELAMENTO',
      },
      {
          valor: 'honorarios',
          chave: 'HONORÁRIOS',
      },
      {
          valor: 'outros',
          chave: 'OUTROS',
      },
  ]

    constructor(
        private alertsUtil: AlertsUtil,
        private utilService: UtilsService,
        private removeParamsUtil: RemoveParamsUtil,
        private consultaContaCorrenteService: ConsultaContaCorrenteService
    ) {}

    ngOnInit(): void {       
        this.getDropdownFiltro();
        this.consultaContaObj = JSON.parse(localStorage.getItem("consultaContaObj"));
        this.pagination = new Pagination<any>();
    }

    /**Lista para combo dropdown */
    onPageChange(e) {
      this.pagination.size = e.rows;
      this.pagination.pageable.pageNumber = e.page;
      this.filtroConta();
    }

    showDialog(evento?: any) {
        this.valueFiltroTpCadastro = null;
        this.objTpCadastro = JSON.parse(localStorage.getItem("objTpCadastroParam"));
        this.contasSelecionadas = [];
        this.visible = true;
    }

    close(evento?: any) {
        this.visible = false;
        this.limparFiltros();
    }

    adicionaConta() {
        const value: any = this.contasSelecionadas;
        this.selectConta.emit(value);
        this.close();
    }

    /**Valores lista Dropdown Filtros */
    async getDropdownFiltro() {
        this.tipoDocumentoList = await this.consultaContaCorrenteService
            .getTipoNumeroDocumento()
            .toPromise();
        /**filtro conta */
        this.getContribuinteInscricaoEstadual();
        this.listFiltroConta();
        return new Promise((resolve) => {
            resolve("");
        });
    }

    getContribuinteInscricaoEstadual() {
        /**Lista inscrição estadual */
        const objParamsIcms = {
            // cpfCnpj: this.valueContribuinte?.baseCnpj,
        };
        this.blockedDocument = true;
        this.consultaContaCorrenteService
            .getContribuinte(objParamsIcms)
            .subscribe(
                (response: any) => {
                    this.blockedDocument = false;
                    this.contribuinteList = response?.content;
                },
                (error) => {
                    this.blockedDocument = false;
                    this.alertsUtil.handleError(error);
                }
            );
    }

    listFiltroConta() {
        this.consultaContaCorrenteService
            .listFiltroContaAll()
            .subscribe(
                (response: any) => {
                    this.blockedDocument = false;
                    this.contaList = response;
                },
                (error) => {
                    this.blockedDocument = false;
                    this.alertsUtil.handleError(error);
                }
            );
    }

    tipoDocumentoChange() {
        if (this.tipoNumeroDocumentoValue == null) {
            /**reset campo Nº documento */
            this.numeroDocumentoValue = null;
        }
    }

    limparFiltros() {
        this.tipoContaView = 'ipva';
        this.contribuinteValue = null;
        this.contaValue = null;
        this.numeroContaValue = null;
        this.tipoNumeroDocumentoValue = null;
        this.numeroDocumentoValue = null;
        this.dataInicialReferenciaValue = null;
        this.dataFinalReferenciaValue = null;
        this.pagination = new Pagination<any>();
    }

    filtroConta() {
        
        this.blockedDocument = true;
        let filtroTpCadastroCpfOuCnpj = null;
        let tipoCadastro = null;
        /**CPF OU CNPJ */
        if(this.objTpCadastro?.tpCadastroId === 3) {
            filtroTpCadastroCpfOuCnpj = this.objTpCadastro?.tpCadastroValue;
        } else {
            tipoCadastro = this.objTpCadastro?.tpCadastroValue;
        }
      this.blockedDocument = true;
        const objParams = {
            configContaId: this.contaValue?.length > 1 ? [this.contaValue] : this.contaValue,
            periodoReferenciaInicial: this.utilService.convertToSaveAnoMes(this.dataInicialReferenciaValue),
            periodoReferenciaFinal: this.utilService.convertToSaveAnoMes(this.dataFinalReferenciaValue),
            tipoNumeroDocumentoId: this.tipoNumeroDocumentoValue,
            numeroDocumento: this.numeroDocumentoValue,
            cpfCnpjResponsavel: filtroTpCadastroCpfOuCnpj,
            codigoCadastro: tipoCadastro
        };
        this.removeParamsUtil.removeParametroNaoUtilizado(objParams);

        // this.consultaContaCorrenteService
        //     .getContaCorrenteSearch(objParams, this.pagination)
        //     .subscribe(
        //         (response: any) => {
        //             this.pagination = response;
        //             this.blockedDocument = false;
        //         },
        //         (error) => {
        //             this.blockedDocument = false;
        //             this.alertsUtil.handleError(error);
        //         }
        //     );

        
            this.blockedDocument = true;
              this.consultaContaCorrenteService.getContaViewConta(objParams, this.pagination, this.tipoContaView).subscribe(
                (response: any) => {
                  this.pagination = response;
                  this.blockedDocument = false;
                }, (error) => {
                  setTimeout(() => {
                    this.blockedDocument = false;
                  }, 500);
                  this.alertsUtil.handleError(error);
                }
              )
    }

    valorTpCadastro() {
        return `${this.objTpCadastro.tpCadastroId} - ${this.objTpCadastro.tpCadastroValue}`
    }
}



