
<div class="layout-topbar">

    <a href="/" class="logo-container">
        <!-- <img class="logo" alt="logo" src="assets/layout/images/logo-sefaz.png" /> -->
        <span class="app-name">CCP</span>
    </a>
    </div>
<div class="login-body" style="background: none !important;">
    <div class="logo">

    </div>
    <div class="login-panel">
        <div class="left-part">
            <span class="login-panel-header">Autenticação</span>
            <div style="margin-top:32px">
                <div class="p-col-12">
                    <label>Usuário</label>
                    <br>
                    <input type="text" autocomplete="off" pInputText/>
                </div>
                <div class="p-col-12">
                    <label>Senha</label>
                    <br>
                    <input type="password" autocomplete="off" pInputText feedback="false"/>
                </div>
            </div>
            <div class="p-col-6">
                <button type="button" pButton label="Entrar" class="p-button-raised"[routerLink]="['/home']"></button>
            </div>
        </div>
    </div>
</div>
