import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-input-area',
  templateUrl: './input-area.component.html',
  styleUrls: ['./input-area.component.scss']
})
export class InputAreaComponent implements OnInit {

  @Input('for-name') forName!: string;
  @Input() name!: string;
  @Input() form!: FormGroup;

  constructor() { }

  ngOnInit(): void {
  }

}
