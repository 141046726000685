<p-card>
    <ng-template pTemplate="title">
        Benefício Fiscal
    </ng-template>
    <ng-template pTemplate="content">
        <form *ngIf="formGroupEvento" [formGroup]="formGroupEvento">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-2">
                    <label>Código</label>
                    <input type="text" formControlName="eventoBeneficioId" disabled pInputText>
                </div>
                <div class="p-field p-col-10">
                    <label>Descrição *</label>
                    <input [attr.disabled]="implantadoInput === 'SIM' ? true : null" formControlName="descricao" maxlength="100" type="text" pInputText [maximizable]="true" [baseZIndex]="1000" [draggable]="false" [resizable]="false">
                    <p
                    class="erro-validacao"
                    *ngIf="(eventoFormControl.descricao.touched || submitted)
                        && eventoFormControl.descricao.errors?.required">
                        Campo obrigatório
                    </p>
                </div>
                <div class="p-field p-col-2">
                    <label>Tipo Evento</label>
                    <p-dropdown
                        optionLabel="descricao"
                        formControlName="eventos"
                        showClear="true"
                        [autoDisplayFirst]="false"
                        [options]="eventosList"
                        [disabled]="implantadoInput === 'SIM' ? true : null"
                        (onChange)="validacaoTpEvento()"
                        >
                    </p-dropdown>
                    <p
                    class="erro-validacao"
                    *ngIf="(eventoFormControl.eventos.touched || submitted)
                        && eventoFormControl.eventos.errors?.required">
                        Campo obrigatório
                    </p>
                </div>
                <div class="p-field p-col-2">
                    <label>Tipo Cadastro</label>
                    <p-dropdown
                        optionLabel="descricao"
                        formControlName="tipoCadastro"
                        showClear="true"
                        [autoDisplayFirst]="false"
                        [options]="tipoCadastroList"
                        (onChange)="validacaoTpCadastro()"
                        [disabled]="implantadoInput === 'SIM' ? true : null"
                    ></p-dropdown>
                    <p
                    class="erro-validacao"
                     *ngIf="(eventoFormControl.tipoCadastro.touched || submitted)
                        && eventoFormControl.tipoCadastro.errors?.required">
                        Campo obrigatório
                    </p>
                </div>
                <div class="p-field p-col-4">
                    <label>Tributo</label>
                    <p-dropdown
                        optionLabel="sigla"
                        formControlName="tributo"
                        showClear="true"
                        [autoDisplayFirst]="false"
                        [options]="tipoTributoDominio"
                        (onChange)="validacaoTributo()"
                        [disabled]="implantadoInput === 'SIM' ? true : null"
                        >
                    </p-dropdown>
                    <p
                    class="erro-validacao"
                    *ngIf="(eventoFormControl.tributo.touched || submitted)
                        && eventoFormControl.tributo.errors?.required">
                        Campo obrigatório
                    </p>
                </div>
                <div class="p-field p-col-4">
                    <label>Situação</label>
                    <p-dropdown
                        [disabled]="!eventoBeneficioId || this.implantadoInput"
                        formControlName="situacao"
                        [options]="situacaoTransacaoEnum"
                        [optionLabel]="'valor'"
                        [optionValue]="'chave'"
                        [dataKey]="'valor'"
                        [autoDisplayFirst]="true"
                        >
                    </p-dropdown>
                </div>
                <div class="p-field p-col-2">
                    <p-fieldset legend="Tipo Crédito Tributário">
                        <div *ngFor="let tipoCredito of tipoCreditoDominio" class="field-checkbox p-mb-1; let i = index">
                            <p-checkbox
                                name="tipoCredito"
                                [label]="tipoCredito.descricao"
                                [value]="tipoCredito.tipoCreditoId"
                                [formControl]="tipoCreditoSelected"
                                [disabled]="implantadoInput === 'SIM' ? true : null"
                            ></p-checkbox>
                            <!-- <label [for]="tipoCredito.tipoCreditoId" style="cursor: pointer"> {{tipoCredito.descricao}}</label> -->
                        </div>
                        <p class="erro-validacao" *ngIf="(eventoFormControl.tipoCredito.touched || submitted)
                        && eventoFormControl.tipoCredito.errors?.required">
                            Campo obrigatório
                         </p>
                </p-fieldset>
                </div>
                <div class="p-field p-col-8">
                    <p-fieldset legend="Vigência">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-6">
                                <label>Inicio da Vigência</label>
                                <p-calendar
                                    (onSelect)="onSelectDataMenor($event)"
                                    formControlName="dataInicioVigencia"
                                    name="dataInicioVigencia"
                                    [showIcon]="true"
                                    inputId="icon"
                                    dateFormat="dd/mm/yy"
                                    name="dataFimVigencia"
                                    [disabled]="implantadoInput === 'SIM' ? true : null"
                                    >
                                </p-calendar>
                                <p
                                class="erro-validacao"
                                *ngIf="(eventoFormControl.dataInicioVigencia.touched || submitted)
                                    && eventoFormControl.dataInicioVigencia.errors?.required">
                                    Campo obrigatório
                                </p>
                            </div>
                            <div class="p-field p-col-6">
                                <label>Fim da Vigência</label>
                                <p-calendar
                                    (onSelect)="onSelectDataMenor($event)"
                                    formControlName="dataFimVigencia"
                                    name="dataFimVigencia"
                                    [showIcon]="true"
                                    inputId="icon"
                                    dateFormat="dd/mm/yy"
                                    name="dataFimVigencia"
                                    >
                                </p-calendar>
                            </div>
                        </div>
                    </p-fieldset>
                </div>
                <div class="p-field p-col-2">
                    <p-fieldset legend="Tipo Consolidação">
                        <div *ngFor="let tipo of tipoConsolidadoEnum">
                            <div class="p-field-radiobutton">
                                <p-radioButton
                                value="true"
                                [value]="tipo.chave"
                                formControlName="tipoConsolidacao"
                                [disabled]="implantadoInput === 'SIM' ? true : null"
                                ></p-radioButton>
                                <label [for]="tipo"> {{tipo.valor}}</label>
                            </div>
                            <p class="erro-validacao" *ngIf="(eventoFormControl.tipoConsolidacao.touched || submitted)
                            && eventoFormControl.tipoConsolidacao.errors?.required">
                                Campo obrigatório
                             </p>
                        </div>
                    </p-fieldset>
                </div>
                <div class="p-field p-col-2">
                    <div class="field-checkbox p-mb-1">
                        <p-checkbox
                        binary="true"
                        formControlName="lancaHonorarios"
                        label="Lança honorários?"
                        (onChange)="changeCheckbox($event)"
                        [disabled]="implantadoInput === 'SIM' ? true : null"
                        >
                        </p-checkbox>
                    </div>
                </div>
                <div *ngIf="this.formGroupEvento.controls.lancaHonorarios.value" class="p-field p-col-12 margin-bottom">
                    <p-fieldset legend="Honorários">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-2">
                                <div *ngFor="let honorario of tipoHonorarioEnum">
                                    <div class="p-field-radiobutton">
                                        <p-radioButton
                                            [value]="honorario.chave"
                                            formControlName="tipoHonorarios"
                                            (click)="changeValorHonorario(honorario.id)"
                                            [disabled]="implantadoInput === 'SIM' ? true : null"
                                        ></p-radioButton>
                                        <label [for]="honorario"> {{honorario.valor}}</label>
                                    </div>
                                </div>
                            </div>
                        <div class="p-field p-col-2">
                            <label>{{eventoFormControl.tipoHonorarios.value == 'FIXO' ? 'Valor R$ ' : 'Percentual % *'}}</label>
                            <p-inputNumber
                                [disabled]="implantadoInput === 'SIM' ? true : null"
                                *ngIf="mode"
                                formControlName="valorHonorarios"
                                maxlength="15"
                                [mode]="mode"
                                [currency]="currency"
                                [locale]="locale"
                                [maximizable]="true"
                                [baseZIndex]="90"
                                [draggable]="false"
                                [resizable]="false"
                                ></p-inputNumber>
                                <p-inputNumber
                                suffix="%"
                                [disabled]="implantadoInput === 'SIM' ? true : null"
                                maxlength="3"
                                [max]="100"
                                *ngIf="!mode"
                                step="0,01"
                                value="0,01"
                                min="0,01"
                                max="100,00"
                                formControlName="valorHonorarios"
                                ></p-inputNumber>
                            <p class="erro-validacao" *ngIf="(eventoFormControl.valorHonorarios.touched || submitted)
                                && eventoFormControl.valorHonorarios.errors?.required">
                                    Campo obrigatório
                            </p>
                        </div>
                        <div class="p-field p-col-2">
                            <label title="regraCriacaoContaId">Conta</label>
                            <div class="p-inputgroup">
                                <input formControlName="configConta" disabled required type="number" pInputText/>
                                <button icon="pi pi-search" type="button" pButton pRipple (click)="showBasicDialog()"></button>
                            </div>
                            <p class="erro-validacao" *ngIf="(eventoFormControl.configConta.touched || submitted)
                            && eventoFormControl.configConta.errors?.required">
                                Campo obrigatório
                        </p>
                        </div>
                        <div class="p-field p-col-6">
                            <label>Descrição *</label>
                            <input [value]="descricaoConta" disabled type="text" pInputText>
                        </div>
                    </div>
                    </p-fieldset>
                </div>
            </div>
            <div class="grid grid-nogutter justify-content-end margin-top">
                <p-button
                label="Voltar"
                icon="pi pi-angle-left"
                (click)="voltar()"
            ></p-button>
                <p-button class="float-btn-next" label="Próxima" (onClick)="proximaAba()" icon="pi pi-angle-right" iconPos="right"></p-button>
            </div>
        </form>
    </ng-template>
</p-card>

<app-config-conta-search-modal [multipleSelection]="false" (outConfigConta)="outConfigConta($event)"></app-config-conta-search-modal>
