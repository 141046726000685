<p-messages [(value)]="msgs1" [enableService]="false"></p-messages>
<p-dialog header="Pesquisar Unidade Organizacional" (onHide)="onHide()" [(visible)]="service.displayMaximizable" [modal]="true" [style]="{width: '60vw'}" [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-4">
            <label>Código</label>
            <input type="text" pInputText autocomplete="nope" [(ngModel)]="unidadeOrganizacionalSearchRequest.unidadeOrganizacionalId">
        </div>
        <div class="p-field p-col-8">
            <label>Descrição</label>
            <input type="text" pInputText autocomplete="nope" [(ngModel)]="unidadeOrganizacionalSearchRequest.descricao">
        </div>
    </div>

    <p-table [value]="unidadeOrganizacionalSearchResponses">
        <ng-template pTemplate="header">
            <tr>
                <th>Código</th>
                <th>Descrição</th>
                <th style="width: 100px;">Ações</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-uosr>
            <tr>
                <td>{{uosr.unidadeOrganizacionalId}}</td>
                <td>{{uosr.descricao}}</td>
                <td>
                    <button pButton pRipple title="Selecionar" type="button" icon="pi pi-check" class="p-button-rounded p-button-warning" (click)="select(uosr)"></button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td>
                    Nenhum registro localizado
                </td>
            </tr>
        </ng-template>
    </p-table>
    <p-paginator [rows]="10" [totalRecords]="pagination.totalElements" (onPageChange)="onPageChange($event)"></p-paginator>

    <ng-template pTemplate="footer">
        <p-button icon="pi pi-times" (click)="service.displayMaximizable=false" label="Fechar"></p-button>
        <p-button icon="pi pi-search" (click)="search()" label="Pesquisar"></p-button>
    </ng-template>
</p-dialog>

