export class TransacaoSearchRequest {
    situacao: string;
    transacaoId: string;
    eventosId: string;
    tributoId: string;

    constructor() {
        this.situacao = '';
        this.transacaoId = '';
        this.eventosId = '';
        this.tributoId = '';
    }
}
