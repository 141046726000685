export const environment = {
  production: true,
  appId: '#CCP',
  ccpContext: 'conta-corrente-api',
  baseUrl: 'https://dev.techlead.com.br/conta-corrente-api',
  redirectUrl: 'https://ccp.techlead.com.br/',

  // SSO
  ssoUrl: 'https://sso-pladif.techlead.com.br/auth/',
  ssoClient: 'ccp',
  realm: 'sefaz-internos'
};
