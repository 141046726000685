import { Component, Input, OnInit } from "@angular/core";
import * as moment from "moment";
import { UtilsService } from "../utils.service";

@Component({
    selector: "app-identificacao-conta",
    templateUrl: "./identificacao-conta.component.html",
    styleUrls: ["./identificacao-conta.component.scss"],
})
export class IdentificacaoContaComponent implements OnInit {
    @Input() configConta;
    identificacaoConta: string = "";

    constructor(
        private utilService: UtilsService
        ) {
    }

    ngOnInit(): void {
        this.montaIdentificacaoConta();
    }

    montaIdentificacaoConta() {
        this.convertDateChild();
        let contaCorrente = "";
        if (this.configConta.configContaId) {
            contaCorrente = `${this.configConta.configContaId} -`;
        }
        this.identificacaoConta = `
        ${contaCorrente} ${this.configConta.nomeResumido} | 
        Situação: ${this.configConta.situacao} | 
        Vigência: ${this.configConta.dataInicioVigencia}
        `;
        if (this.configConta.dataInicioVigencia.length !== 10) {
            this.convertDateChild();
        }
    }

    convertDateChild() {
        // if (this.configConta.dataInicioVigencia.length !== 10) {
            this.configConta.dataInicioVigencia = this.utilService.convertDateToEdit(
                this.configConta.dataInicioVigencia
            );
        // }
    }
    convertDateBr(data) {
        return data == null ? "" : moment(data).format("DD/MM/YYYY");
    }
}
