import { Component, Input, OnInit } from '@angular/core';
import { ConsultaContaCorrenteService } from 'src/app/component/criacao-conta-corrente/services/consulta-conta-corrente.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { AlertsUtil } from 'src/app/utils/alerts/alerts.util';
import { MaskCpfOuCnpj } from 'src/app/utils/masklCpfCnpjList/maskCpfCnpj.util';
import { RemoveParamsUtil } from 'src/app/utils/paramsRemove/paramsRemove.util';

@Component({
  selector: 'modal-extrato-conta-corrente',
  templateUrl: './modal-extrato-conta-corrente.component.html',
  styleUrls: ['./modal-extrato-conta-corrente.component.scss']
})
export class ModalExtratoContaCorrenteComponent {

  @Input() abaSelecionada: any;

  posicaoData: any;
  maxDate = new Date();
  blockedDocument: boolean;
  contaCorrenteContribuinte: any;

  constructor(
    private maskCpfOuCnpj: MaskCpfOuCnpj,
    private util: UtilsService,
    private alertUtil: AlertsUtil,
    private removeParamsUtil: RemoveParamsUtil,
    private service: ConsultaContaCorrenteService
    ){}

  dadosContribuinte: any;
  visible: boolean;
  extratoValue: any;
  valoresExtratoList = [
    {nome: 'Atual'},
    {nome: 'Data'},
  ]

  showDialog(dadosContribuinte, contaCorrenteContribuinte) {
    this.dadosContribuinte = dadosContribuinte;
    this.contaCorrenteContribuinte = contaCorrenteContribuinte
    this.extratoValue = this.valoresExtratoList[0].nome;
    this.visible = true;
    this.posicaoData = new Date();
  }

  hiddenDialog(){
    this.visible = false;
  }

  insereMascara(cpfOuCnpj) {
    return this.maskCpfOuCnpj.inserirMascara(cpfOuCnpj);
  }

  gerarExtrato(){
    this.blockedDocument = true;
    const objParams = {
      cpfCnpj: this.dadosContribuinte?.cpfcnpj,
      contaCorrenteId: this.contaCorrenteContribuinte?.contaCorrenteId,
      posicaoData: this.util.convertDateToSave(this.posicaoData),
      viewContaCorrenteEnum: this.abaSelecionada
    }
    this.removeParamsUtil.removeParametroNaoUtilizado(objParams);
    this.service
    .gerarPDF(objParams)
    .subscribe((response) => {
      this.blockedDocument = false;
       const blob = new Blob([response], { type: 'application/pdf' }); 
       const data = window.URL.createObjectURL(blob); 
       const link = document.createElement('a'); 
       link.href = data; window?.open(data, '_blank');
      },
      (error) => {
        this.alertUtil.handleError(error);
        this.blockedDocument = false;
      }
    )
  }
  
  onChangeExtratoValue(event){
    if(this.extratoValue?.nome == 'Atual'){
      this.posicaoData = new Date();
    }
  }

}
