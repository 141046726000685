export class ImpostoPagoSearchRequest {
    impostoId: number[];
    contaId: number[];
    dataInicialReferenciaValue: any | null;
    dataFinalReferenciaValue: any | null;

    constructor() {
        this.impostoId = [];
        this.contaId = [];
        this.dataInicialReferenciaValue = null;
        this.dataFinalReferenciaValue = null;
    }
}
