import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Pageable } from "./../../../shared/pagination/pageable";
import { environment } from "./../../../../environments/environment";
import { Pagination } from "./../../../shared/pagination/pagination";
import * as moment from "moment";
import Swal from 'sweetalert2';

@Component({
    selector: "app-evento-pagamento-list",
    templateUrl: "./evento-pagamento-list.component.html",
    styleUrls: ["./evento-pagamento-list.component.scss"],
})
export class EventoPagamentoListComponent implements OnInit {
    blockedDocument = false;
    simNaos: any[];
    situacoes: any[];
    pagination: Pagination<any> = new Pagination();

    constructor(private http: HttpClient, private router: Router) {}

    ngOnInit(): void {
        this.onPageChange({ page: 0 });
        this.simNaos = [
            { label: "Sim", value: "SIM" },
            { label: "Não", value: "NAO" }
        ];
        this.situacoes = [
            { label: "Ativa", value: "ATIVA" },
            { label: "Inativa", value: "INATIVA" }
        ];
    }

    onPageChange(e) {
        this.pagination.pageable.pageNumber = e.page;
        this.search();
    }

    novaTransacao() {
        this.router.navigate(["evento-pagamento/evento-pagamento-cadastrar"]);
    }

    ativaInativaPagamento(pagamento) {
        this.blockedDocument = true;
        this.http
            .put<void>(
                `${environment.baseUrl}/evento-pagamento/ativar-desativar`,
                {},
                {
                    params: {
                        eventoPagamentoId: pagamento.eventoPagamentoId,
                    },
                },


            )
            .subscribe((response) => {
                    this.blockedDocument = false;
                    this.search();
                }, (error) => {
                    this.blockedDocument = false;
                }
            );
    }

    search() {
        this.blockedDocument = true;
        this.http
            .get<Pageable<any>>(
                `${environment.baseUrl}/evento-pagamento/search`,
                {
                    params: {
                        size: 10,
                        page: this.pagination.pageable.pageNumber,
                    },
                }
            )
            .subscribe(
                (response) => {
                    this.pagination = response;
                    this.blockedDocument = false;
                    this.pagination
                    .content
                    .forEach(r => {
                        r.dataInicioVigencia = r.dataInicioVigencia === null ? null : this.toLocaleDate(r.dataInicioVigencia);
                        r.dataFimVigencia = r.dataFimVigencia === null ? null : this.toLocaleDate(r.dataFimFigencia);
                    });
            },
            (error) => {
                this.blockedDocument = false;
            }
        );
    }

    toLocaleDate(date){
        return new Date(`${date} 23:59:59`);
    }

    onEditar(pagamento) {
        this.router.navigate(['/evento-pagamento/evento-pagamento-cadastrar'], {state: {eventoPagamento: pagamento}})
        .then(r => {});
    }

    onExcluir(pagamento) {
        this.confirmDelete(`Confirma a exclusão do evento ${pagamento.eventoPagamentoId} - ${pagamento.descricao}?`)
            .then(result => {
                if (result.value) {
                    this.remove(pagamento);
                }
            });
    }

    remove(pagamento) {
        this.http
            .delete<void>(`${environment.baseUrl}/evento-pagamento/${pagamento.eventoPagamentoId}`)
            .subscribe(() => {
                Swal.fire("Sucesso", "Excluído com sucesso", "success")
                    .then(() => {
                        this.search();
                    });
            });

    }

    convertDateBr(data) {
        return data == null ? "" : moment(data).format("DD/MM/YYYY");
    }   
    
    convertDateUS(data) {
        return data == null ? "" : moment(data).format("YYYY-MM-DD");
    }
    

    confirmDelete(msg = 'Confirma a exclusão?') {
        return Swal.fire({
            text: msg,
            icon: 'warning',
            title: "Atenção",
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
        });
    }

    onAtivaInativaPagamento(pagamento) {
        const opcao = pagamento.situacao === 'ATIVA' ? 'desativar' : 'ativar';
        this.confirmDelete(`Deseja ${opcao} o evento de pagamento ${pagamento.eventoPagamentoId} - ${pagamento.descricao}`)
            .then(r => {
                if (r.value) {
                    this.ativaInativaPagamento(pagamento);
                }
            });
    }
}
