import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EnumsService } from 'src/app/core/enums.service';
import { ConsultaContaObj } from 'src/app/models/consulta-config-conta';
import { AlertsUtil } from 'src/app/utils/alerts/alerts.util';
import { CriacaoContaCorrenteService } from '../../services/criacao-conta-corrente.service';
import * as ConfigContaSearchModalActions from '../../../config-conta/config-conta-search-modal/config-conta-search-modal-actions';
import { Store } from '@ngrx/store';
import { ConfigurarContaCorrenteService } from 'src/app/component/configurar-conta-corrente/configurar-conta-corrente.service';
import {AcoesContaCorrenteHelper} from "../../../../shared/acoes-helper/acoes-conta-corrente-helper";
@Component({
    selector: "app-consulta-config-conta",
    templateUrl: "./consulta-config-conta.component.html",
    styleUrls: ["./consulta-config-conta.component.scss"],
})
export class ConsultaConfigContaComponent implements OnInit {
    @Output() outEtapas = new EventEmitter();

    consultaContaObj: ConsultaContaObj = {
        configContaId: null,
        nomeCompleto: '',
        nomeResumido: '',
        dataInicioVigencia: '',
        dataFimVigencia: null,
        situacao: '',
        implantado: '',
        tipoConta: '',
        tipoCadastro: {
            tipoCadastroId: null,
            descricao: '',
            tipoCodigoCadastro: ''
        },
        permiteCotaUnica: null,
        permiteParcelar: null,
        origemInformacao: {
            origemInformacaoId: null,
            nome: '',
            sigla: ''
        },
        tipoDataReferencias: [
            {
                tipoDataReferenciaId: null,
                descricao: ''
            }
        ],
        configContaItemCalculo: [
            {
                configContaItemCalculoId: null,
                itemCalculoNome: '',
                naturezaItemCalculo: {
                    naturezaItemCalculoId: null,
                    descricao: ''
                }
            }
        ],
        tipoNumeroDocumentos: [
            {
            tipoNumeroDocumentoId: null,
            descricao: ''
            }
     ],
        configuracaoEventos: [],
        configContaTipoCadastros: []
    }

    title: string = "Consulta de Configuração da Conta";
    configContaId: any;
    blockedDocument: boolean;
    permiteCotaUnica = true;
    tipoCodigoCadastroEnum: any[];
    naoPossuiEvento: boolean;
    tipoCadastroList: any[];

    constructor(
        private router: Router,
        private alertUtil: AlertsUtil,
        private criacaoContaCorrenteService: CriacaoContaCorrenteService,
        private configurarContaCorrenteService: ConfigurarContaCorrenteService,
        private enumService: EnumsService,
        private storeConfigContaSearchModal: Store<{
            configContaSearchModal: { displayMaximizable: boolean };
        }>,
    ) {}

    ngOnInit(): void {
        if (!this.podeAbrirContaCorrente) {
            this.router.navigate(['/']);
        }

        localStorage.clear();
        this.closeModal();
    }

    pesquisarConta() {
        if(this.configContaId) {
            this.getConta(this.configContaId);
        } else {
            this.showBasicDialog();
        }
    }

    limparCampoPesquisa(){
      this.configContaId = null;
      this.consultaContaObj.configContaId = null;
    }

    getConta(id) {
      this.blockedDocument = true;
        this.criacaoContaCorrenteService.getConta(id).subscribe(
            (resp: ConsultaContaObj) => {
              this.blockedDocument = false;
              this.consultaContaObj = resp;
              this.tipoCadastroList = resp.configContaTipoCadastros;
              this.alertVerificaEventos(this.consultaContaObj)
              this.getEnum(this.consultaContaObj.tipoCadastro?.tipoCodigoCadastro);
              localStorage.setItem('consultaContaObj',JSON.stringify(this.consultaContaObj));
            },
            (error) => {
                this.blockedDocument = false;
                this.alertUtil.handleError(error);
            }
        );
    }

    alertVerificaEventos(objConsulta){
        if(objConsulta.configuracaoEventos.length == 0){
            this.naoPossuiEvento = true;
            this.alertUtil.warning(`A Conta informada não possui evento de abertura de Conta.`)
        } else {
            this.naoPossuiEvento = false;
        }
    }

    async getEnum(param: string){
        this.tipoCodigoCadastroEnum = []
        let tipoCodigCadastro: any = await this.enumService.getEnumNome('TipoCodigoCadastro').toPromise();
        this.tipoCodigoCadastroEnum = tipoCodigCadastro.filter(aux=> aux.chave == param);
    }


    irRegistrarMemoriaCalculo() {
        this.outEtapas.emit(1);
    }

    /**Evento modal*/
    outConfigConta(conta?) {
        this.configContaId = conta.configContaId;
        this.closeModal();
        this.pesquisarConta();
    }

    showBasicDialog() {
        this.storeConfigContaSearchModal.dispatch(
            new ConfigContaSearchModalActions.SetDisplayMaximizable(true)
        );
    }

    closeModal(){
        this.storeConfigContaSearchModal.dispatch(
            new ConfigContaSearchModalActions.SetDisplayMaximizable(false)
        );
    }

    converteValue(value){
        return value.replace(/\b\w/g, char => char.toUpperCase());
    }

    podeAbrirContaCorrente(): boolean {
        return AcoesContaCorrenteHelper.podeAbrirContaCorrente;
    }

}
