
<p-card header="Configuração de Evento de Pagamento">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-2">
            <label>Código</label>
            <input
                type="text"
                pInputText
                [disabled]="true"
                [(ngModel)]="eventoPagamento.eventoPagamentoId"
            />
        </div>
        <div class="p-field p-col-12 p-md-9">
            <label>Descrição *</label>
            <input
                type="text"
                pInputText
                [(ngModel)]="eventoPagamento.descricao"
                [disabled]="eventoPagamento.implantado === 'SIM'"
            />
        </div>

        <div class="p-field p-col-12 p-md-5">
            <label title="situacao">Situação*</label>
            <p-dropdown
                [disabled]="(eventoPagamento.implantado !== 'SIM' || eventoPagamento.eventoPagamentoId === null)"
                optionLabel="valor"
                optionValue="chave"
                showClear="true"
                [options]="situacoes"
                [(ngModel)]="eventoPagamento.situacao"
                [autoDisplayFirst]="false"
            ></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-3">
            <label title="dataInicioVigencia">Início da Vigência*</label>
            <p-calendar
                dateFormat="dd/mm/yy"
                [showIcon]="true"
                [(ngModel)]="dateStart"
                (ngModelChange)="onSelectDataInicioVigencia($event)"
                [disabled]="disableInicioVigencia()"
            ></p-calendar>
<!--                [disabled]="eventoPagamento.implantado === 'SIM' || eventoPagamento.eventoPagamentoTransacaos.length > 0"-->
        </div>
        <div class="p-field p-col-12 p-md-3">
            <label title="dataFimVigencia">Fim da Vigência</label>
            <p-calendar
                dateFormat="dd/mm/yy"
                [showIcon]="true"
                [(ngModel)]="dateEnd"
                (ngModelChange)="onSelectDataFimVigencia($event)"
            ></p-calendar>
        </div>
        <div class="p-col-12 p-md-2" style="margin-top: 10px; margin-bottom: 30px">
            <button
                [disabled]="!dateStart"
                (click)="novaTransacao()"
                pButton
                type="button"
                icon="pi pi-plus-circle"
                label="Adicionar Transação"
                class="p-mr-1 p-text-left"
            ></button>
        </div>
    </div>

    <p-table [value]="eventoPagamento.eventoPagamentoTransacaos || []">
        <ng-template pTemplate="header">
            <tr>
                <th>Código</th>
                <th>Transação Financeira de Pagamento</th>
                <th>Início Vigência</th>
                <th>Fim Vigência</th>
                <th>Ações</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-pagamento let-rowIndex="rowIndex">
            <tr>
                <td>{{ pagamento.transacao.transacaoId }}</td>
                <td>{{ pagamento.transacao.nomeResumido }}</td>
                <td>{{ pagamento.dataInicioVigencia | date: "dd/MM/yyyy" }}</td>
                <td>{{ pagamento.dataFimVigencia | date: "dd/MM/yyyy" }}</td>
                <td>
<!--                    <button-->
<!--                        *ngIf="eventoPagamento.implantado === 'NAO' && rowIndex == 0"-->
<!--                        pButton-->
<!--                        pRipple-->
<!--                        title="Editar"-->
<!--                        type="button"-->
<!--                        icon="pi pi-pencil"-->
<!--                        class="p-button-rounded p-button botao-editar"-->
<!--                        (click)="editarTransacao(pagamento, rowIndex)"-->
<!--                    ></button>-->
                    <button
                        *ngIf="eventoPagamento.implantado === 'NAO' && rowIndex == 0"
                        pButton
                        pRipple
                        title="Excluir"
                        type="button"
                        icon="pi pi-trash"
                        class="p-button-rounded p-button-danger"
                        style="margin-left: 3px"
                        (click)="excluiTransacao(rowIndex, pagamento)"
                    ></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-col-12 p-md-3" style="margin-top: 50px; margin-bottom: 30px">
            <button
                [disabled]="!dateStart"
                pButton
                (click)="novaImputacao()"
                type="button"
                icon="pi pi-plus-circle"
                label="Adicionar Regra de Imputação"
                class="p-mr-1 p-text-left"
            ></button>
        </div>
    </div>
    <p-table [value]="eventoPagamento.eventoPagtoRgrImputacaos || []">
        <ng-template pTemplate="header">
            <tr>
                <th>Regra de Imputação</th>
                <th>Início Vigência</th>
                <th>Fim Vigência</th>
                <th>Ações</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-imputacao let-rowIndex="rowIndex">
            <tr>
                <td>{{ imputacao.regraImputacao.descricao }}</td>
                <td>{{ imputacao.dataInicioVigencia | date: "dd/MM/yyyy" }}</td>
                <td>{{ imputacao.dataFimVigencia | date: "dd/MM/yyyy" }}</td>
                <td>
<!--                    <button-->
<!--                        *ngIf="eventoPagamento.implantado === 'NAO' && rowIndex === 0"-->
<!--                        pButton-->
<!--                        pRipple-->
<!--                        title="Editar"-->
<!--                        type="button"-->
<!--                        icon="pi pi-pencil"-->
<!--                        class="p-button-rounded p-button botao-editar"-->
<!--                        (click)="editarImputacao(imputacao, rowIndex)"-->
<!--                    ></button>-->
                    <button
                        *ngIf="eventoPagamento.implantado === 'NAO' && rowIndex === 0"
                        pButton
                        pRipple
                        title="Excluir"
                        type="button"
                        icon="pi pi-trash"
                        class="p-button-rounded p-button-danger"
                        style="margin-left: 3px"
                        (click)="excluiImputacao(rowIndex, imputacao)"
                    ></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-col-12 p-md-12" style="margin-top: 50px">
            <div class="p-field-checkbox">
                <p-checkbox
                    [disabled]="checkboxDisabled"
                    binary="false"
                    [trueValue]="1"
                    [falseValue]="0"
                    [(ngModel)]="receitaTributaria"
                    label="Receita Tributária?"
                    title="Receita Tributária?"
                    (onChange)="onChangeReceitaTributaria($event)"
                ></p-checkbox>
            </div>
        </div>

        <div class="p-field p-col-12 p-md-6" *ngIf="receitaTributaria == 1">
            <label title="Tributo">Tributo</label>
            <p-dropdown
                optionLabel="descricaoResumida"
                showClear="true"
                (onChange)="onChange($event)"
                [options]="tributos"
                [(ngModel)]="tributo"
                [autoDisplayFirst]="false"
                appendTo="body"
            >
                <ng-template let-item pTemplate="item">
                    {{item.sigla}} - {{item.descricaoCompleta}}
                </ng-template>

                <ng-template let-item pTemplate="selectedItem">
                    {{item.sigla}} - {{item.descricaoCompleta}}
                </ng-template>
            </p-dropdown>
        </div>

        <div class="p-field p-col-12 p-md-8">
            <label title="situacao">Receitas</label>
            <p-dropdown
                optionLabel="nomeReceita"
                showClear="true"
                [options]="eventoPagamentoReceitas"
                [(ngModel)]="eventoPagamentoReceita"
                [autoDisplayFirst]="false"
            >
                <ng-template let-item pTemplate="item">
                    {{item.codReceita}} - {{item.nomeReceita}}
                </ng-template>

                <ng-template let-item pTemplate="selectedItem">
                    {{item.codReceita}} - {{item.nomeReceita}}
                </ng-template>
            </p-dropdown>
        </div>
        <div
            class="p-col-12 p-md-2"
            style="margin-top: 24px; margin-bottom: 50px"
        >
            <button
                [disabled]="disableAdicionarReceita()"
                pButton
                (click)="novaReceita()"
                type="button"
                icon="pi pi-plus-circle"
                label="Adicionar Receita"
                class="p-mr-1 p-text-left"
            ></button>
        </div>
    </div>
    <div class="p-card-title">Lista de Receitas</div>
<!--    <p-table [value]="eventoPagamento.eventoPagamentoReceitas || []">-->
    <p-table [value]="eventoPagamento.eventoPagamentoReceitaDTOs || []">
        <ng-template pTemplate="header">
            <tr>
                <th>Código Receita</th>
                <th>Nome Receita</th>
                <th>Tritubaria?</th>
                <th>Código Tributo</th>
                <th>Sigla Tributo</th>
                <th>Situação</th>
                <th>Início Vigência</th>
                <th>Fim Vigência</th>
                <th>Ações</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
            <tr>
                <td>{{ item.codReceita }}</td>
                <td>{{ item.nomeReceita }}</td>
                <td>{{ item.tributaria}}</td>
                <td>{{ item.codTributo }}</td>
                <td>{{ item.sigla }}</td>
                <td>{{ item.situacao }}</td>
                <td>{{ item.dataInicioVigencia | date: "dd/MM/yyyy" }}</td>
                <td>{{ item.dataFimVigencia | date: "dd/MM/yyyy" }}</td>


<!--                <td>{{ item.codReceita }}</td>-->
<!--                <td>{{ item.eventoPagtoReceitaContas[0]?.configContaReceita.receita.nomeReceita }}</td>-->
<!--                <td>{{ item.tributaria === 1 ? "SIM" : "NAO" }}</td>-->
<!--                <td>{{ item.eventoPagtoReceitaContas[0]?.configContaReceita.receita.codTributo }}</td>-->
<!--                <td>{{ item.codTributo }}</td>-->
<!--                <td>{{ item.situacao }}</td>-->
<!--                <td>{{ item.dataInicioVigencia | date: "dd/MM/yyyy" }}</td>-->
<!--                <td>{{ item.dataFimVigencia | date: "dd/MM/yyyy" }}</td>-->
                <td>
                    <button
                        *ngIf="eventoPagamento.implantado === 'NAO' || item.eventoPagamentoReceitaId === null"
                        pButton
                        pRipple
                        title="Excluir"
                        type="button"
                        icon="pi pi-trash"
                        class="p-button-rounded p-button-danger"
                        style="margin-left: 3px"
                        (click)="excluiReceita(rowIndex, item)"
                    ></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <ng-template pTemplate="footer">
        <div class="p-field p-col-12" style="margin-top: 10px">
            <button
                class="float-btn-next"
                style="width: 15%; margin-right: 8px"
                label="Salvar"
                icon="pi pi-save"
                (click)="salvar()"
                pButton
            ></button>
            <button
                style="margin-right: 5px;"
                pButton
                type="button"
                label="Voltar"
                class="p-button-outlined p-mr-2"
                (click)="voltar()"
            ></button>
        </div>
        <br />
    </ng-template>
</p-card>
<app-evento-pagamento-transacao-financeira-modal
    (eventoPagamentoSelecionado)="eventoPagamentoSelecionado($event)"
    [(dataInicio)]="eventoPagamento.dataInicioVigencia"
    [(transacaoPagamentoEdit)]="transacaoPagamentoEdit"
    [(eventoPagamento)]="eventoPagamento"
    [(eventoPagamentoTransacaosLength)]="eventoPagamentoTransacaosLength"
></app-evento-pagamento-transacao-financeira-modal>

<app-evento-pagamento-regra-imputacao-modal
    (eventoPagamentoImputadoSelecionado)="eventoPagamentoImputadoSelecionado($event)"
    [(dataInicio)]="eventoPagamento.dataInicioVigencia"
    [(regraImputacaoLength)]="regraImputacaoLength"
    [(regraImputacaoEdit)]="regraImputacaoEdit"
    [(eventoPagamento)]="eventoPagamento"
></app-evento-pagamento-regra-imputacao-modal>

<app-evento-pagamento-parametro-configuracao-modal
    [configContaReceita]="configContaReceita"
    (pagamentoReceitaEditada)="pagamentoReceitaEditada($event)"
    [(dataInicio)]="eventoPagamento.dataInicioVigencia"
    [(receitaLength)]="receitaLength"
></app-evento-pagamento-parametro-configuracao-modal>

