import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class UnidadeOrganizacionalSearchModalService {
    displayMaximizable = false;

    constructor() {
    }
}
