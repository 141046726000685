<p-dialog
    header="Regra de Identificação da Conta"
    (onHide)="onHide()"
    (onShow)="onShow()"
    [(visible)]="eventoPagamentoRegraIdentificacaoModalService.displayMaximizable"
    [modal]="true"
    [style]="{ width: '50vw', height: '20vw' }"
    [maximizable]="true"
    [baseZIndex]="10000"
    [draggable]="false"
    [resizable]="false"
>
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-6">
            <label title="Regra">Regra*</label>
            <p-dropdown
                optionLabel="descricao"
                showClear="true"
                [autoDisplayFirst]="false"
                [options]="listaRegras"
                [(ngModel)]="regraSelecionada.regra"
                appendTo="body"
            ></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-4">
            <label title="dataInicioVigencia">Início da Vigência</label>
            <p-calendar
                [disabled]="validaData"
                appendTo="body"
                dateFormat="dd/mm/yy"
                [showIcon]="true"
                [(ngModel)]="dateStart"
                (ngModelChange)="onSelectDataInicioVigencia($event)"
            ></p-calendar>
        </div>
    </div>

    <ng-template pTemplate="footer">
        <p-button
            icon="pi pi-times"
            (click)="eventoPagamentoRegraIdentificacaoModalService.displayMaximizable = false"
            label="Fechar"
        ></p-button>
        <p-button
            [disabled]="validaConfirmar()"
            icon="pi pi-save"
            (click)="confirmar()"
            label="Confirmar"
        ></p-button>
    </ng-template>
</p-dialog>

