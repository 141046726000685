import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import * as moment from 'moment';
import { ConfigContaSaveAllRequest } from 'src/app/models/config-conta-save-all-request';
import { environment } from 'src/environments/environment';
import { MemoriaCalculoModel } from 'src/app/models/memoria-calculo.model';

@Injectable({
    providedIn: 'root'
})
export class CriacaoContaCorrenteService {
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };
    private routerInfo: BehaviorSubject<any>;

    constructor(
        private http: HttpClient,
    ) {
        this.routerInfo = new BehaviorSubject<any>(false);
    }

    getConta(configContaId) {
        return this.http.get<any>(`${environment.baseUrl}/config-conta/${configContaId}/consultar`);
    }

    postMemoriaCaluculo(memoriaCalculoModel: MemoriaCalculoModel) {
        return this.http.post<MemoriaCalculoModel>(
            `${environment.baseUrl}/memoria-calculo`, memoriaCalculoModel);
    }

}
