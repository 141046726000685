export class TipoCadastro {
    tipoCadastroId: number = null;
    descricao: string = null;
    tipoCodigoCadastro: string = null;

    constructor(init?: Partial<TipoCadastro>) {
        if (init) {
            Object.assign(this, init);
        }
        // else {
        //     this.tipoCadastroId = null;
        //     this.descricao = null;
        // }
    }
}
