<div>
    <strong style="margin-bottom: 25px">Eventos</strong>
</div>

<p-table
    #dt1
    [value]="eventosList"
    [globalFilterFields]="[
        'dataEvento',
        'configuracaoEventos?.eventos?.descricao',
        'saldoConta?.descricao',
        'situacaoCredito?.descricao',
        'estadoConta.descricao',
        'situacaoCobranca.descricao'
    ]"
>
    <ng-template pTemplate="caption">
        <div class="p-d-flex" style="margin-left: auto !important; width: 16%">
            <span class="p-input-icon-left p-ml-auto">
                <i class="pi pi-search"></i>
                <input
                    pInputText
                    type="text"
                    (input)="dt1.filterGlobal($event.target.value, 'contains')"
                    placeholder="Pesquisar na lista"
                />
            </span>
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th pSortableColumn="dataEvento">
                Data do Evento <p-sortIcon field="dataEvento"></p-sortIcon>
            </th>
            <th pSortableColumn="configuracaoEventos.eventos.descricao">
                Nome
                <p-sortIcon
                    field="configuracaoEventos.eventos.descricao"
                ></p-sortIcon>
            </th>
            <th pSortableColumn="saldoConta.descricao">
                Efeito no Saldo da Conta
                <p-sortIcon field="saldoConta.descricao"></p-sortIcon>
            </th>
            <th pSortableColumn="situacaoCredito.descricao">
                Efeito na Situação do Crédito
                <p-sortIcon field="situacaoCredito.descricao"></p-sortIcon>
            </th>
            <th pSortableColumn="estadoConta.descricao">
                Estado da Conta
                <p-sortIcon field="estadoConta.descricao"></p-sortIcon>
            </th>
            <th pSortableColumn="situacaoCobranca.descricao">
                Situação da Cobrança
                <p-sortIcon field="situacaoCobranca.descricao"></p-sortIcon>
            </th>
            <th>Ações <p-sortIcon></p-sortIcon></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-evento>
        <tr
            class="selecao"
            (click)="buscaTransacoesVinculoEvento(evento.contaCorrenteEventoId)"
        >
            <td>{{ evento?.dataEvento | date : "dd/MM/yyyy" }}</td>
            <td>{{ evento?.configuracaoEventos?.eventos?.descricao }}</td>
            <td>{{ evento?.saldoConta?.descricao }}</td>
            <td>{{ evento?.situacaoCredito?.descricao }}</td>
            <td>{{ evento?.estadoConta?.descricao }}</td>
            <td>{{ evento?.situacaoCobranca?.descricao }}</td>
            <td>
                <i
                    class="pi pi-search"
                    style="cursor: pointer"
                    (click)="openModalEvento(evento)"
                ></i>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td class="p-text-center" colspan="9">
                Nenhum registro encontrado
            </td>
        </tr>
    </ng-template>
</p-table>
<p-paginator
    [rows]="10"
    [totalRecords]="pagination.totalElements"
    (onPageChange)="onPageChange($event)"
></p-paginator>
<div>
    <strong style="margin-bottom: 25px">Transações</strong>
</div>

<p-table
    #dt2
    [value]="transacaoList"
    [globalFilterFields]="['dataTransacao', 'transacao.nomeResumido']"
>
    <ng-template pTemplate="caption">
        <div class="p-d-flex" style="margin-left: auto !important; width: 16%">
            <span class="p-input-icon-left p-ml-auto">
                <i class="pi pi-search"></i>
                <input
                    pInputText
                    type="text"
                    (input)="dt2.filterGlobal($event.target.value, 'contains')"
                    placeholder="Pesquisar na lista"
                />
            </span>
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th pSortableColumn="dataTransacao">
                Data da Transação<p-sortIcon field="dataTransacao"></p-sortIcon>
            </th>
            <th pSortableColumn="transacao.nomeResumido">
                Nome <p-sortIcon field="transacao.nomeResumido"></p-sortIcon>
            </th>
            <th>Valor Principal</th>
            <th>Valor Juros</th>
            <th>Valor Multa de Mora</th>
            <th>Valor Multa por Infração</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
        <tr
            class="selecao"
            [ngClass]="{
                'evento-selecionado': vinculoEvento(
                    item.contaCorrenteTransacaoId
                )
            }"
        >
            <td>{{ item.dataTransacao | date : "dd/MM/yyyy" }}</td>
            <td>{{ item.transacao.nomeResumido }}</td>
            <td>
                {{
                    item.naturezaItemCalculo.naturezaItemCalculoId == 1
                        ? (item.valorTransacao | currency : "BRL")
                        : ""
                }}
            </td>
            <td>
                {{
                    item.naturezaItemCalculo.naturezaItemCalculoId == 3
                        ? (item.valorTransacao | currency : "BRL")
                        : ""
                }}
            </td>
            <td>
                {{
                    item.naturezaItemCalculo.naturezaItemCalculoId == 2
                        ? (item.valorTransacao | currency : "BRL")
                        : ""
                }}
            </td>
            <td>
                {{
                    item.naturezaItemCalculo.naturezaItemCalculoId == 4
                        ? (item.valorTransacao | currency : "BRL")
                        : ""
                }}
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td class="p-text-center" colspan="9">
                Nenhum registro encontrado
            </td>
        </tr>
    </ng-template>
</p-table>

<modal-evento-conta-corrente></modal-evento-conta-corrente>
