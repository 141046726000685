

export class EstadoEventoSaveRequest {
    eventoEstado: EventoEstado
    eventoEstadoPerfil: EventoEstadoPerfil[]
    eventoEstadoAntes: EventoEstadoAntes[];
    paramInteresse: ParamInteresse[];
  }
  export class EventoEstado {
    eventoEstadoId: any
    estadoConta: EstadoContaDepois
    // eventos: Eventos
    descricao: string
    tipoEvento: string
    tipoProcessamento: string
    situacao: string
    interrompePrazo: string
    dataInicioVigencia: string
    dataFimVigencia: string
    dataCadastro: string
    implantado: any
    criterioBusca: CriterioBuscaContaCorrente

    constructor(init?: Partial<EventoEstado>) {
      if (init) {
          Object.assign(this, init);
      } else {
          this.eventoEstadoId = 0;
          this.estadoConta = null
          // this.eventos = null
          this.descricao = ''
          this.tipoEvento = ''
          this.tipoProcessamento = ''
          this.situacao = ''
          this.interrompePrazo = ''
          this.dataInicioVigencia = ''
          this.dataFimVigencia = ''
      }
    }
  }

  export enum CriterioBuscaContaCorrente{
    NUMERO_DOCUMENTO = 1,
    PERIODO_REFERENCIA = 2
  }

  export class ParamInteresse {
    atributo: Atributo;
    eventoEstado: EventoEstado;
    obrigatorio: string;
    paramInteresseId: any;
  }

  export class Atributo {
    atributoId: any;
  }

  export class EstadoContaDepois {
    estadoContaId: number
  }
  export class Eventos {
    eventosId: number
  }
  export class EventoEstadoPerfil {
    codigoPerfil: string
    dataFimAssociacao: string
    dataInicioAssociacao: string
    eventoEstado: EventoEstado
    eventoEstadoPerfilId: number
    situacao: string
  }
  export class EventoEstadoAntes {
    estadoConta: EstadoConta
    eventoEstadoId: EventoEstadoId
    eventoEstadoAntesId: number
  }
  export class EstadoConta {
    estadoContaId: any
    // constructor(init?: Partial<EstadoConta>) {
    //   if (init) {
    //       Object.assign(this, init);
    //   } else {
    //       this.estadoContaId = 1;
    //   }
    // }
  }
  export class EventoEstadoId {
    eventoEstadoId: number
    constructor(init?: Partial<EstadoConta>) {
      if (init) {
          Object.assign(this, init);
      } else {
          this.eventoEstadoId = 0;
      }
    }
  }
