<p-card header="Configuração do Modelo de Estado de Conta Corrente">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-2">
            <label>Código</label>
            <input [value]="estadoContaId" type="text" pInputText [disabled]="true" />
        </div>
        <div class="p-field p-col-12 p-md-8">
            <label>Descrição *</label>
            <input 
            #descricao="ngModel" maxlength="50" type="text" pInputText [(ngModel)]="modeloDeEstadoContaCorrente.descricao" name="nomdeDoEstado" required/>
            <small *ngIf="(descricao?.invalid && submitted)" class="p-error">Campo obrigatório</small>
        </div>

        <!-- <div class="p-field p-col-12 p-md-4">
            <label title="situacao">Situação *</label>
            <p-dropdown
                optionLabel="valor"
                optionValue="chave"
                showClear="true"
                [autoDisplayFirst]="false"
                [options]="situacoes"
                [(ngModel)]="modeloDeEstadoConta.situacao"
                [disabled]="!modeloDeEstadoConta.estadoContaId"
            ></p-dropdown>
        </div> -->
        <div class="p-field p-col-12 p-md-2">
            <label title="dataInicioVigencia">Data do Cadastro</label>
            <p-calendar 
            disabled 
            dateFormat="dd/mm/yy" 
            [showIcon]="true" 
            [(ngModel)]="modeloDeEstadoContaCorrente.dataCadastro"
            ></p-calendar>
        </div>
    </div>
    <!-- <p-fieldset
        title="Efeito sobre a Conta Corrente"
        legend="Efeito sobre a Conta Corrente"
    >
        <div class="p-fluid p-formgrid p-grid">
            <div
                *ngFor="let item of ListaEfeitoSobreContaCorrente"
                class="p-col-12 p-md-4"
            >
                <div class="p-field-radiobutton">
                    <p-radioButton
                        [name]="item.chave"
                        [inputId]="item.chave"
                        [value]="item.chave"
                        [(ngModel)]="modeloDeEstadoConta.efeitoSobreConta"
                        (click)="onChangeEfeitoSobreContaCorrente(item.chave)"
                    ></p-radioButton>
                    <label style="cursor: pointer" [for]="item.chave">{{item.valor}}</label>
                </div>                
            </div>
        </div>
    </p-fieldset>
    <small *ngIf="(modeloDeEstadoConta?.efeitoSobreConta == null && submitted)" class="p-error">Campo obrigatório</small>
    <div class="p-col-12 p-md-12">
        <div class="p-field-checkbox">
            <p-checkbox
                [disabled]="checkboxDisabled"
                binary="false"
                trueValue="SIM"
                falseValue="NAO"
                [(ngModel)]="modeloDeEstadoConta.atualizaSaldoConta"
                label="Atualiza o saldo da Conta Corrente?"
                title="Atualiza o saldo da Conta Corrente?"
            ></p-checkbox>
        </div>
    </div>
    <div class="p-col-12 p-md-12">
        <div class="p-field-checkbox">
            <p-checkbox
                binary="false"
                trueValue="SIM"
                falseValue="NAO"
                [(ngModel)]="modeloDeEstadoConta.permiteDare"
                label="Permite emitir DARE?"
                title="Permite emitir DARE?"
            ></p-checkbox>
        </div>
    </div>
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-6">
            <label title="situacao">Perfis Visualização</label>
            <p-dropdown
                optionLabel="descricao"
                optionValue="codigoPerfil"
                showClear="true"
                [autoDisplayFirst]="false"
                [options]="perfisVisualizacao"
                [(ngModel)]="idEstadoPerfil"
            ></p-dropdown>
        </div>
        <div
            style="
                margin-top: 24px;
                margin-bottom: 10px;
                text-align: right !important;
            "
        >
            <button
                pButton
                type="button"
                icon="pi pi-plus-circle"
                label="Adicionar"
                class="p-mr-1 p-text-left"
                (click)="adicionaPerfilVizualizacao()"
            ></button>
        </div>
    </div> -->
    <!-- <p-table [value]="listaEventoEstadoPerfil">
        <ng-template pTemplate="header">
            <tr>
                <th>Código</th>
                <th>Nome do Perfil</th>
                <th>Situação Associação</th>
                <th>Data do Cad Associação</th>
                <th>Data do Fim Associação</th>
                <th>Ação</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
            <tr>
                <td>{{ item.codigoPerfil }}</td>
                <td>{{ item.descricao }}</td>
                <td>{{ item.situacao }}</td>
                <td>
                    {{ convertDateBr(item.dataInicioAssociacao) }}
                </td>
                <td>
                    {{ convertDateBr(item.dataFimAssociacao) }}
                </td>
                <td>
                    <button
                        *ngIf="item.estadoPerfilId"
                        pButton
                        pRipple
                        title="{{ item.situacao === 'ATIVA' ? 'Inativar' : 'Ativar' }}"
                        type="button"
                        icon="{{ item.situacao === 'ATIVA' ? 'pi pi-times' : 'pi pi-check' }}"
                        class="p-button-rounded p-button-secondary"
                        (click)="ativarInativar(item)"
                     ></button>
                    <button
                        pButton
                        pRipple
                        title="Excluir"
                        type="button"
                        icon="pi pi-trash"
                        class="p-button-rounded p-button-danger"
                        (click)="delete(rowIndex, item)"
                        style="margin-left: 3px"
                    ></button>
                </td>
            </tr>
        </ng-template>
        <p-paginator
            [rows]="10"
            [totalRecords]="pagination.totalElements"
            (onPageChange)="onPageChange($event)"
        ></p-paginator>
    </p-table> -->
   
    <div style="margin-top: 20px;">
        <button 
            style="width: 15%; margin-right: 8px" 
            pButton type="button" 
            label="Voltar" 
            class="p-button-outlined p-mr-2"
            (click)="voltar()">
        </button>
        <button 
            class="float-btn-next" 
            style="width: 15%; margin-right: 8px" 
            pRipple 
            label=" Salvar " 
            (click)="salvar()"
            type="button" 
            pButton>
        </button>
    </div>

</p-card>
