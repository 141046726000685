<p-card header="Configuração de Eventos de Lançamento de Crédito">
    <div style="text-align: right">
        <button
            pButton
            type="button"
            icon="pi pi-plus-circle"
            label="Novo Evento"
            class="p-mr-1 p-text-left botao-adicionar"
            (click)="configEventoLancamento()"
        ></button>
    </div>
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-2">
            <label>Código</label>
            <input type="text" pKeyFilter="num" [(ngModel)]="eventoLancamentoId" pInputText>
        </div>
        <div class="p-field p-col-6">
            <label>Descrição</label>
            <input maxlength="100" [(ngModel)]="descricao" type="text" pInputText>
        </div>
        <div class="p-field p-col-2">
            <label>Situação</label>
            <p-dropdown
                [(ngModel)]="situacao"
                [readonly]="false"
                inputId="dropdown"
                name="situacao"
                [options]="situacaoEnum"
                [optionLabel]="'valor'"
                [optionValue]="'chave'"
                [autoDisplayFirst]="false"
                showClear="true"
                >
            </p-dropdown>
        </div>
        <div class="p-field p-col-2" style="margin-top: 26px;">
            <p-button
            (click)="pesquisaFiltro()"
            label="Pesquisar"
            icon="pi pi-search"
            iconPos="right"
            ></p-button>
        </div>
    </div>
    <p-table [value]="eventoLancamentoList">
        <ng-template pTemplate="header">
            <tr>
                <th>Código</th>
                <th>Descrição do Evento</th>
                <th>Implantado?</th>
                <th>Evento Estado</th>
                <th>Transação Lançamento</th>
                <th>Situação</th>
                <th>Início Vigência</th>
                <th>Fim Vigência</th>
                <th>Ação</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-el>
            <tr>
                <td>{{ el.eventoLancamentoId }}</td>
                <td>{{ el.descricao }}</td>
                <td>{{ getValorEnum(el.implantado, implantadoEnum) }}</td>
                <td>{{ el.eventoEstado.descricao }}</td>
                <td>{{ el.transacao.nomeResumido }}</td>
                <td>{{ getValorEnum(el.situacao, situacaoEnum) }}</td>
                <td>{{ convertDateBr(el.dataInicioVigencia)}}</td>
                <td>{{ convertDateBr(el.dataFimVigencia) }}</td>
                <td>
                    <button
                        pButton
                        pRipple
                        title="Editar"
                        type="button"
                        icon="pi pi-pencil"
                        class="p-button-rounded p-button botao-editar"
                        style="margin-left: 3px;"
                        (click)="configEventoLancamento(el.eventoLancamentoId)"
                    ></button>
                    <button *ngIf="el.implantado === 'SIM'"
                        pButton
                        pRipple
                        title="{{ el.situacao === 'ATIVA' ? 'Desativar' : 'Ativar' }}"
                        type="button"
                        icon="{{ el.situacao === 'ATIVA' ? 'pi pi-times' : 'pi pi-check' }}"
                        class="p-button-rounded p-button-secondary"
                        style="margin-left: 3px"
                        (click)="putAtivaInativa(el)"
                    ></button>
                    <button *ngIf="el.implantado === 'NAO'"
                        pButton
                        pRipple
                        title="Excluir"
                        type="button"
                        icon="pi pi-trash"
                        class="p-button-rounded p-button-danger"
                        style="margin-left: 3px"
                        (click)="delete(el)"
                    ></button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="12" style="text-align: center">Nenhum registro localizado</td>
            </tr>
        </ng-template>
    </p-table>
    <p-paginator [rows]="10" [totalRecords]="pagination.totalElements" (onPageChange)="onPageChange($event)"></p-paginator>
</p-card>
