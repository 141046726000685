import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

import {ConfigComposicaoConta} from 'src/app/models/config-composicao-conta';
import {NaturezaItemCalculo} from 'src/app/models/natureza-item-calculo';
import {ConfigComposicaoContaService} from './config-composicao-conta.service';

import {MenuItem, Message} from 'primeng/api';

import * as moment from 'moment';
import swal from 'sweetalert2';
import {UtilsService} from 'src/app/shared/utils.service';
import {Pagination} from 'src/app/shared/pagination/pagination';
import {ConfigContaItemCalculoSearchRequest} from 'src/app/request/ConfigContaItemCalculoSearchRequest';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {ModalItemComposicaoComponent} from 'src/app/modal/modal-item-composicao/modal-item-composicao.component';
import {Router} from '@angular/router';
import {RegraCalculoDto} from 'src/app/shared/condition/condition-node';
import {ConditionComponent} from 'src/app/shared/condition/condition.component';
import {ContaCorrenteService} from 'src/app/core/conta-corrente.service';
import {RegraCriacaoSearchRequest} from '../../../request/RegraCriacaoSearchRequest';
import {RegraCriacaoListServiceService} from '../../regra-criacao/regra-criacao-list/regra-criacao-list-service.service';
import { AlertsUtil } from 'src/app/utils/alerts/alerts.util';
import Swal from 'sweetalert2';
import {Location} from "@angular/common";
import { RemoveParamsUtil } from 'src/app/utils/paramsRemove/paramsRemove.util';


@Component({
    selector: 'app-config-composicao-conta-tab-view',
    templateUrl: './config-composicao-conta.component.html',
    styleUrls: ['./config-composicao-conta.component.scss'],
})
export class ConfigComposicaoContaComponent implements OnInit {
    msgs1: Message[];
    msgTemporaria: any[];
    naturezaItem: NaturezaItemCalculo[] = [];
    configComposicaoConta: ConfigComposicaoConta[];
    configItemConta = new ConfigComposicaoConta();
    situacao: any[];
    pagination: Pagination<any>;
    configContaItemCalculoSearchRequest: ConfigContaItemCalculoSearchRequest;
    habilitaCancelar: boolean;
    icone = 'pi pi-plus-circle';
    btnSalvarAtualizar: string = 'Adicionar';
    exibeFormula: boolean;
    exibeItem: boolean;
    exibeConstante: boolean;
    itemComposicaoCalculo: any = [];
    groupArr: any[];
    itemComposicaoInput: any = '';
    constante: any = '';
    dialog: DynamicDialogRef;
    configConta;
    @Output() configContaEmit = new EventEmitter();
    @Output() outHabilitaSalvar = new EventEmitter();
    regraCalculo: RegraCalculoDto;
    @ViewChild(ConditionComponent, {static: false})
    condition: ConditionComponent;

    inforAba: any;
    submitted: boolean = false;
    items: MenuItem[];
    objComposicao: any;
    situacaoOptions = [
        {id: '1', valor: 'Ativa', chave: 'ATIVA'},
        {id: '0', valor: 'Inativa', chave: 'INATIVA'},
    ];
    erroSql: boolean;

    constructor(
        private configComposicaoContaService: ConfigComposicaoContaService,
        private utilService: UtilsService,
        public dialogService: DialogService,
        private router: Router,
        private location: Location,
        private contaCorrenteService: ContaCorrenteService,
        private utils: UtilsService,
        private alertUtil: AlertsUtil,
        private regraCriacaoService: RegraCriacaoListServiceService,
        private removeParamsUtil: RemoveParamsUtil,
    ) {
        this.treatNextPage();
    }

    treatNextPage(): void {
        let redirect = this.router
            .getCurrentNavigation().extras?.state?.nextPageUntil;
        if(redirect) {
            if(this.router.url !== redirect) {
                this.ngOnInit();
                this.nextPage();
            }
        }
    };

    ngOnInit(): void {
        this.carregaCampos();
        this.configContaItemCalculoSearchRequest = new ConfigContaItemCalculoSearchRequest();
        this.pagination = new Pagination<any>();
        this.items = this.contaCorrenteService.getEtapaConta();

        if (this.configConta.configContaId !== null) {
            this.initTabConfigComposicaoConta();
        }

        this.getNaturezaItemComposicao();
        this.getListaItem();
        this.regraCampoSituacaoAndData();
        this.configContaEmit.emit(this.configConta);
        this.converteData();
    }

    isReaderFieldSituacao() {
        return this.configConta.configContaId == null || ((this.habilitaCancelar && this.configConta.implantado === 'SIM') || this.configConta.implantado === 'NAO');
    }

    converteData() {
        if (this.configConta.configContaId) {
            if (this.objComposicao?.dataInicioVigencia) {
                this.objComposicao.dataInicioVigencia = this.utilService.convertToDateBr(this.objComposicao.dataInicioVigencia);
            }
            if (this.objComposicao?.dataFimVigencia) {
                this.objComposicao.dataFimVigencia = this.utilService.convertToDateBr(this.objComposicao.dataFimVigencia);
            }
        } else {
            this.objComposicao.dataInicioVigencia = this.utilService.convertToEditBrUniversal(this.objComposicao.dataInicioVigencia);
            this.objComposicao.dataFimVigencia = this.utilService.convertToEditBrUniversal(this.objComposicao.dataFimVigencia);
        }
    }

    carregaCampos() {

        const configContaStorage = sessionStorage.getItem('objConta');
        if (configContaStorage) {
            const objConta = JSON.parse(configContaStorage);
            this.getItemComposicaoByOrigemInformacaoId(objConta.origemInformacao);
            this.configConta = objConta;
            this.configComposicaoConta = [];
        }

        if (this.configConta?.configContaId) {
            this.configComposicaoConta = [];
        }

        const objComposicaoStorage = sessionStorage.getItem("objComposicao");
        if (objComposicaoStorage) {
            this.objComposicao = JSON.parse(objComposicaoStorage)
        } else {
            this.objComposicao = new ConfigComposicaoConta();
        }
        this.regraCalculo = this.objComposicao?.regraCalculo;
        // this.regraCalculo = this.contaCorrenteService.configComposicaoConta.regraCalculo;

        setTimeout(() => {
            if (this.regraCalculo) {
                let jsonData = JSON.stringify(this.regraCalculo);
                this.condition?.setRegra(jsonData);
            }
        }, 500);
    }

    regraCampoSituacaoAndData() {
        if (this.configConta.configContaId == null) {
            this.objComposicao.situacao = this.configConta.situacao;
            this.objComposicao.dataInicioVigencia = this.utilService.convertToEditBrUniversal(this.configConta.dataInicioVigencia);
        }
    }

    getAutomaticNextPage() {
        let redirect = this.router
            .getCurrentNavigation()?.extras?.state?.nextPageUntil;

        if(redirect) {
            if(this.router.url !== redirect) {
                return redirect;
            }
        }

        return null;
    }

    nextPage() {
        const dtIni = this.utilService.convertToSaveUniversal(this.objComposicao.dataInicioVigencia)
        const dtFim = this.utilService.convertToSaveUniversal(this.objComposicao.dataFimVigencia)
        const diff = this.utilService.diffInDays(dtFim, dtIni);

        if (diff < 0) {
            Swal.fire("Erro", "A data final da vigência não pode ser menor que a data inicial da vigência", "error")
                .then(null)
            return false
        }

        if (this.configConta.configContaId) {
            let automaticNextPage = this.getAutomaticNextPage();
            this.router
                .navigate(['/config-modelo'], {
                    state: { nextPageUntil: automaticNextPage },
                })
                .then(() => {});
            return true;
        }
        let msgRegraCalculo = `É necessário que a regra de cálculo seja validada, monte a formula e valide a regra.`;
        if (this.condition.sqlReturn === '') {
            swal.fire({
                title: 'Atenção',
                text: msgRegraCalculo,
                icon: 'warning',
            }).then(() => {});
            return false;
        } else {
            const tipoConta = this.configConta?.tipoConta
            const data: RegraCriacaoSearchRequest = new RegraCriacaoSearchRequest();
            data.situacao = "ATIVA"
            // data.tributoId = "6";
            if (tipoConta === 'NAO_TRIBUTARIA') {
                data.tributoId = null;
            } else if (tipoConta === 'TRIBUTARIA') {
                data.tributoId = this.configConta?.tributo?.tributoId;
            }

            let objParams = this.removeParamsUtil.removeParametroNaoUtilizado(data);
            
            this.regraCriacaoService
                .pesquisarRegrasCriacao(objParams, this.pagination)
                .subscribe((response) => {
                    if (response.content.length == 0) {
                        swal.fire("Atenção", "Não há modelo de conta cadastrado", "warning").then(() => {})
                    } else {
                        this.contaCorrenteService.condition = this.condition;
                        this.condition.enviarDados();
                        this.objComposicao.regraCalculo = this.regraCalculo;
                        this.contaCorrenteService.configComposicaoConta = this.objComposicao;

                        const data = JSON.parse(JSON.stringify(this.objComposicao))
                        data.dataInicioVigencia = this.utilService.convertToSaveUniversal(data.dataInicioVigencia)
                        data.dataFimVigencia = this.utilService.convertToSaveUniversal(data.dataFimVigencia)
                        sessionStorage.setItem("objComposicao", JSON.stringify(data))

                        if (this.validaEditarESalvar()) {
                            this.router
                                .navigate(['/config-modelo'])
                                .then(() => {});
                        }

                    }
                });
        }
        return true;
    }

    validaEditarESalvar() {
        this.submitted = true;
        if (
            this.objComposicao.itemCalculoNome &&
            this.objComposicao.itemCalculoSigla &&
            this.objComposicao.naturezaItemCalculoId &&
            this.objComposicao.situacao &&
            this.objComposicao.dataInicioVigencia
        ) {
            return true;
        }
        return false;
    }

    prevPage() {
        if (!this.contaCorrenteService.condition) {
            this.contaCorrenteService.condition = this.condition;
        }
        this.condition = this.contaCorrenteService.condition;
        this.condition.enviarDados();
        this.contaCorrenteService.configComposicaoConta.regraCalculo = this.regraCalculo;
        this.contaCorrenteService.configComposicaoConta = this.objComposicao;
        setTimeout(() => {
            this.router.navigate(['/config-conta-corrente']);
        }, 500);
    }

    initTabConfigComposicaoConta() {
        this.getItemComposicao();
        this.getItemComposicaoByOrigemInformacaoId(this.configConta.origemInformacao);
    }

    removeDrop() {
        this.exibeFormula = false;
        this.exibeItem = false;
        this.exibeConstante = false;
        this.constante = '';
        this.itemComposicaoInput = '';
    }

    validarRegra() {
        this.dialog = this.dialogService.open(ModalItemComposicaoComponent, {
            header: 'Choose a Product',
            width: '50%',
            contentStyle: {'max-height': '500px', overflow: 'auto'},
            baseZIndex: 10000,
        });
    }

    getNaturezaItemComposicao() {
        this.configComposicaoContaService
            .getNatureza()
            .subscribe((response) => {
                this.naturezaItem = response;
            });
    }

    getItemComposicao() {
        const data = JSON.parse(JSON.stringify(this.configContaItemCalculoSearchRequest));
        data.configContaId =
            this.configConta.configContaId === null
                ? ''
                : this.configConta.configContaId;
        this.configComposicaoContaService
            .getItemCalculo(data, this.pagination)
            .subscribe((response) => {
                this.configComposicaoConta = response.content;
                this.pagination = response;
            }, erro => {
            });
    }

    getItemComposicaoByOrigemInformacaoId(origemInformacao) {
        this.configComposicaoContaService
            .getItemComposicaoByOrigemInformacaoId(origemInformacao.origemInformacaoId)
            .subscribe((response) => {
                this.itemComposicaoCalculo = response;
                this.groupArr = this.itemComposicaoCalculo.reduce(
                    (r, {origemInformacaoCategori}, i) => {
                        if (
                            !r.some(
                                (o) =>
                                    o.origemInformacaoCategori
                                        .descricaoReduzida ==
                                    origemInformacaoCategori.descricaoReduzida
                            )
                        ) {
                            r.push({
                                origemInformacaoCategori,
                                groupItem: this.itemComposicaoCalculo.filter(
                                    (v) =>
                                        v.origemInformacaoCategori
                                            .descricaoReduzida ==
                                        origemInformacaoCategori.descricaoReduzida
                                ),
                            });
                        }
                        return r;
                    },
                    []
                );
                if (!this.groupArr.length) {
                    swal.fire({
                        title: 'Atenção',
                        text: `Não há itens de composição cadastrado para origem [${origemInformacao.nome}]`,
                        icon: 'warning',
                    }).then(r => {
                        this.prevPage();
                    });
                }
            });
    }

    itemCalculo(item) {
        this.itemComposicaoInput = item;
    }

    onPageChange(e) {
        this.pagination.pageable.pageNumber = e.page;
        this.getItemComposicao();
    }

    getListaItem() {
        this.configComposicaoContaService
            .getSituacoes()
            .subscribe((response) => {
                this.situacao = response;
                this.configItemConta.situacao = 'ATIVA';
            });
    }

    convertDateChild() {
        this.configConta.dataInicioVigencia = this.convertDateBr(
            this.configConta.dataInicioVigencia
        );
    }

    convertDateBr(data) {
        return data == null ? '' : moment(data).format('DD/MM/YYYY');
    }

    iniciaData() {
        if (!this.configItemConta.configContaItemCalculoId === null) {
            this.configItemConta.dataInicioVigencia =
                this.configConta.dataInicioVigencia;
            this.configItemConta.dataFimVigencia =
                this.configConta.dataFimVigencia;
        }
    }

    salvar() {
        if (this.validaEditarESalvar()) {
            this.condition.enviarDados();
            if(this.erroSql){
                this.alertUtil.warning(`A fórmula não pode conter erro na validação SQL`);
                return;
            }
            this.msgTemporaria = [];

            const data = JSON.parse(JSON.stringify(this.objComposicao));
            if (this.objComposicao.dataInicioVigencia) {
                data.dataInicioVigencia = this.utilService.convertDateToSave(
                    this.objComposicao.dataInicioVigencia
                );
            }
            if (this.objComposicao.dataFimVigencia) {
                data.dataFimVigencia = this.utilService.convertDateToSave(
                    this.objComposicao.dataFimVigencia
                );
            }
            data.configContaId = this.configConta.configContaId;
            data.regraCalculo = this.regraCalculo;
            this.configComposicaoContaService.postItemCalculo(data).subscribe(
                (response) => {
                    this.submitted = false;
                    swal.fire('Sucesso', 'Salvo com sucesso', 'success').then(
                        () => {
                            this.getItemComposicao();
                        }
                    );
                    this.cancelarEdicao();
                    this.outHabilitaSalvar.emit('CONFIG_COMPOSICAO');
                    this.condition.limpar();
                },
                (error) => {
                    this.alertUtil.handleError(error);
                    this.submitted = false;
                }
            );
        }
    }

    editarItemCaluculo(obj) {
        this.habilitaCancelar = true;
        this.icone = 'pi pi-pencil';
        this.btnSalvarAtualizar = 'Atualizar item de Cálculo';
        this.objComposicao.configContaId = this.configConta.configContaId;

        this.objComposicao.dataInicioVigencia = this.convertDateBr(
            obj.dataInicioVigencia
        );
        this.objComposicao.dataFimVigencia = this.convertDateBr(
            obj.dataFimVigencia
        );
        this.objComposicao.naturezaItemCalculoId =
            obj.naturezaItemCalculo.naturezaItemCalculoId;
        this.objComposicao.itemCalculoNome = obj.itemCalculoNome;
        this.objComposicao.itemCalculoSigla = obj.itemCalculoSigla;
        this.objComposicao.configContaItemCalculoId =
            obj.configContaItemCalculoId;
        this.objComposicao.situacao = obj.situacao;
        this.objComposicao.comentario = obj.comentario;
        this.objComposicao.regraCalculo = obj.regraCaclculoJson;
        this.regraCalculo = obj.regraCaclculoJson;
        this.contaCorrenteService.configComposicaoConta.regraCalculo =
            obj.regraCalculoJson;
        this.condition.setRegra(obj.regraCalculoJson);
        this.onChangeDataInicioVigencia(this.objComposicao.dataInicioVigencia);
    }

    ativarInativarItemCalculo(itemCalculo) {
        let msgItemCalculo = itemCalculo.situacao === 'ATIVA' ? 'inativar' : 'ativar';
        if(this.configComposicaoConta.length == 1) {
            this.alertUtil.warning(`Para salvar a conta deverá haver ao menos um item de composição.`);
            return
        }
        if (this.configConta.implantado == 'NAO') {
            this.alertUtil.confirm(`
            Deseja excluir o item de cálculo ${itemCalculo.itemCalculoNome}.`).then(result => {
                if(result.isConfirmed) {
                    this.configComposicaoContaService
                    .deleteItemCalculo(itemCalculo.configContaItemCalculoId)
                    .subscribe((resp) => {
                        this.alertUtil.saveSuccess(`Excluído com sucesso`).then(
                            () => {
                                this.getItemComposicao();
                            }
                        );
                    });
                }
            })
        } else {
            this.alertUtil.confirm(`
            Deseja ${msgItemCalculo} o item de cálculo ${itemCalculo.itemCalculoNome}.`).then(result => {
                if (result.isConfirmed) {
                    this.configComposicaoContaService
                        .ativaInativaItemCalculo(itemCalculo.configContaItemCalculoId)
                        .subscribe((resp) => {
                            this.alertUtil.saveSuccess(`Excluído com sucesso`).then(
                                () => {
                                    this.getItemComposicao();
                                }
                            );
                        });
                }
            })
        }
    }

    cancelarEdicao() {
        this.submitted = false
        this.habilitaCancelar = false;
        this.icone = 'pi pi-plus-circle';
        this.btnSalvarAtualizar = 'Adicionar';
        this.configItemConta = new ConfigComposicaoConta();
        this.regraCalculo = {};
        this.objComposicao = {};
        this.condition.clear();
    }

    closeTimeselector(event, element) {
        element.hide(event);
    }

    receberResposta(event) {
        this.regraCalculo = event;
    }

    onChangeDataInicioVigencia(event) {
        let data = this.utilService.convertDateToSave(event);
        let dataVigenciaConta = this.utilService.convertDateToSave(this.configConta.dataInicioVigencia);

        if (data < dataVigenciaConta) {
            swal.fire({
                title: 'Aviso',
                text: 'A data inicio da vigência não pode ser menor que a data da vigência da conta.',
                icon: 'warning',
            }).then((result) => {
                this.objComposicao.dataInicioVigencia = new Date();
            });
        }
    }

    erroSqlOutput(erroValidacao){
        this.erroSql = erroValidacao;
    }

}
