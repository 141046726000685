export class UnidadeOrganizacionalSearchRequest {
    unidadeOrganizacionalId: string;
    descricao: string;

    constructor(init?: Partial<UnidadeOrganizacionalSearchRequest>) {
        if (init) {
            Object.assign(this, init);
        } else {
            this.unidadeOrganizacionalId = '';
            this.descricao = '';
        }
    }
}
