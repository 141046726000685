import {Component, OnInit, Output, EventEmitter, Input, ViewEncapsulation} from '@angular/core';
import {BaseLegalSearchRequest} from '../../../request/base-legal-search-request';
import {Store} from '@ngrx/store';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {UtilsService} from '../../../shared/utils.service';
import {
    ConfigContaBaseLegalTabViewService
} from '../../configurar-conta-corrente/config-conta-base-legal-tab-view/config-conta-base-legal-tab-view.service';
import {EventoPagamentoTransacaoFinanceiraModalService} from './evento-pagamento-transacao-financeira-modal.service';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { EventoPagamentoTransacaos } from 'src/app/models/evento-pagamento-transacoes';
import Swal from 'sweetalert2';
import {ConfigEventoPagamento} from '../../../models/config-evento-pagamento';
import {EventoPagamentoTransacao} from '../../../models/evento-pagamento-transacao';

@Component({
    selector: 'app-evento-pagamento-transacao-financeira-modal',
    templateUrl: './evento-pagamento-transacao-financeira-modal.component.html',
    styleUrls: ['./evento-pagamento-transacao-financeira-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class EventoPagamentoTransacaoFinanceiraModalComponent implements OnInit {

    blockedDocument = false;

    @Input() dataInicio: string;
    @Input() transacaoPagamentoEdit: any;
    @Input() eventoPagamento: any;
    @Input() eventoPagamentoTransacaosLength: any;

    dataInicioVigencia: string = null;
    validaData = false;
    transacoesPagamento: EventoPagamentoTransacaos[];
    eventoPagamentoTransacao: EventoPagamentoTransacaos;
    pagamentoTransacao: EventoPagamentoTransacaos;
    dateStart: Date;
    @Output() public eventoPagamentoSelecionado = new EventEmitter<EventoPagamentoTransacaos>();

    constructor(
        private storeEventoPagamentoTransacaoFinanceiraModal:
            Store<{ eventoPagamentoTransacaoFinanceiraModal: { displayMaximizable: boolean } }>,
        private http: HttpClient,
        public utilsService: UtilsService,
        public eventoPagamentoTransacaoFinanceiraModalService: EventoPagamentoTransacaoFinanceiraModalService,
    ) {
    }

    ngOnInit(): void {
        this.eventoPagamentoTransacao = new EventoPagamentoTransacaos();
        this.pagamentoTransacao = new EventoPagamentoTransacaos();
    }

    onShow(): void {
        this.dateStart = this.eventoPagamentoTransacaoFinanceiraModalService.dateStart;
        this.carregaTransacoesPagamento();
            this.validaData = true;
            this.dataInicioVigencia = moment(this.dateStart).format('YYYY-MM-DD');
        this.validaConfirmar();
    }

    carregaTransacoesPagamento(): void {
        this.getTransacoesPagamento().subscribe((s) => {
            // tslint:disable-next-line: no-string-literal
            this.transacoesPagamento = s["content"];
            this.pagamentoTransacao.transacao = this.transacaoPagamentoEdit.transacao;

            if (this.transacaoPagamentoEdit.eventoPagamentoTransacaoId) {
                this.dateStart = new Date(this.transacaoPagamentoEdit.dataInicioVigencia + "Z");
                this.dateStart.setDate(this.dateStart.getDate() + 1);
            }
        });
    }

    getTransacoesPagamento(): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${environment.baseUrl}/transacao/search?paged=false&situacao=ATIVA&eventosId=6`);
    }

    confirmar() {
        const eventoPagamento: ConfigEventoPagamento = this.eventoPagamentoTransacaoFinanceiraModalService
            .eventoPagamento;
        const eventoPagamentoTransacaoLast: EventoPagamentoTransacao = this.eventoPagamentoTransacaoFinanceiraModalService
            .eventoPagamentoTransacaoLast || null;

        const inicioVigenciaEvento = this.utilsService.convertDateUsToSave(eventoPagamento.dataInicioVigencia);
        const inicioVigenciaNew = this.utilsService.convertDateUsToSave(this.dateStart);
        const inicioVigenciaLast = this.utilsService.convertDateUsToSave(eventoPagamentoTransacaoLast?.dataInicioVigencia);
        const fimVigenciaLast = this.utilsService.convertDateUsToSave(eventoPagamentoTransacaoLast?.dataFimVigencia);

        const diffDatasEvento = this.utilsService.diffInDays(inicioVigenciaNew, inicioVigenciaEvento);
        const diffAnterior = this.utilsService.diffInDays(inicioVigenciaNew, fimVigenciaLast);

        if (this.pagamentoTransacao.transacao == null) {
            Swal.fire('Erro', 'Selecione uma transação', 'error').then(() => {});
        } else if (inicioVigenciaNew === '' || inicioVigenciaNew === null) {
            Swal.fire('Erro', 'Informe a data de início da vigência', 'error').then();
        } else if (diffDatasEvento != 0 && eventoPagamento.eventoPagamentoTransacaos.length == 0) {
            Swal.fire(
                "Atenção",
                `O início de vigência selecionada deve ser igual ao início de vigência do evento de compensação: ${this.utilsService.convertToDateBr(inicioVigenciaEvento)}`,
                "warning",
            ).then(null)
        } else if (eventoPagamento.eventoPagamentoTransacaos.length > 0 && inicioVigenciaLast !== null && diffAnterior <= 0) {
            Swal.fire('Erro', `Início da vigência informado deve ser maior que o início da vigência da transação anterior: <b>${this.utilsService.convertToDateBr(inicioVigenciaLast)}</b>`, 'error').then();
        } else {
            const eventoPagamentoTransacoes: any[] = eventoPagamento.eventoPagamentoTransacaos
            const i = eventoPagamentoTransacoes.some(r => {
                return  r.transacao.transacaoId == this.pagamentoTransacao.transacao.transacaoId && (r.dataFimVigencia == "" || r.dataFimVigencia == null);
            })
            if (i) {
                Swal.fire("Atenção", "Transação já associada ao evento de pagamento para o período de vigência informado.", "warning")
                    .then(null);
            } else {
                this.blockedDocument = true;
                this.blockedDocument = false;
                this.select();
                const pagTransacao = JSON.parse(JSON.stringify(this.pagamentoTransacao));
                pagTransacao.dataInicioVigencia = this.dataInicioVigencia;
                this.eventoPagamentoSelecionado.emit(pagTransacao);
            }
        }
    }

    select() {
        this.eventoPagamentoTransacaoFinanceiraModalService.displayMaximizable = false;
    }

    onHide() {}


    onSelectDataInicioVigencia(event) {
        if (event) {
            this.dataInicioVigencia = moment(event).format('YYYY-MM-DD');
            return this.dataInicioVigencia;
        } else {
            return this.dataInicioVigencia = '';
        }
    }
    validaConfirmar() {
        return this.dateStart && !Array.isArray(this.pagamentoTransacao.transacao) ? false : true;
    }
}
