import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { ArithmeticOperator, Formula, Item } from 'src/app/shared/condition/condition-node';

@Component({
  selector: 'app-formula',
  templateUrl: './formula.component.html',
  styleUrls: ['./formula.component.scss']
})
export class FormulaComponent implements OnInit {

  arithmetic = ArithmeticOperator;
  @Input() formulas: Formula[];
  @Input() groupArr: any[];
  @Input() operandoExpRelacional: boolean = false;

  constructor() { }

  ngOnInit (): void {
  }

  itemCalculo (item: Item, selectedItem: any) {
    item.id = selectedItem.itemComposicaoId;
    item.name = selectedItem.itemCampo;
  }

  closeTimeselector (event, element) {
    element.hide(event);
  }
}
