import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Pagination } from "src/app/shared/pagination/pagination";
import { Pageable } from "src/app/shared/pagination/pageable";
import {
    ConfigContaId,
    PerfilVisualizacaoConta,
} from "src/app/models/perfis-visualizacao";

@Injectable({
    providedIn: "root",
})
export class ConfigContaPerfilVisualizacaoTabViewService {
    perfilVisualizacaoConta: PerfilVisualizacaoConta;
    configConta: ConfigContaId;
    constructor(private http: HttpClient) {
        this.perfilVisualizacaoConta = new PerfilVisualizacaoConta();
    }

    getPerfilKeyCloak(): Observable<Array<any>> {
        return this.http.get<Array<any>>(
            `${environment.baseUrl}/keycloak/roles`
        );
    }

    postPerfil(objSaveRequest): Observable<any[]> {
        return this.http.post<any[]>(
            `${environment.baseUrl}/perfil-visualizacao-conta`,
            objSaveRequest
        );
    }

    getPerfilSearch(
        objParams: any,
        pagination: Pagination<any>
    ): Observable<Pageable<any>> {
        return this.http.get<Pageable<any>>(
            `${environment.baseUrl}/perfil-visualizacao-conta`,
            {
                params: {
                    ...objParams,
                    page: pagination.pageable.pageNumber,
                    size: pagination.size,
                },
            }
        );
    }

    ativaInativaPerfil(id: number): Observable<void> {
        return this.http.put<void>(
            `${environment.baseUrl}/perfil-visualizacao-conta/${id}/ativa-inativa`,
            {}
        );
    }

    deletePerfil(id: number): Observable<void> {
        return this.http.delete<void>(
            `${environment.baseUrl}/perfil-visualizacao-conta/${id}`
        );
    }
}
