import {Component, OnInit} from '@angular/core';
import {UnidadeOrganizacionalSearchModalService} from './unidade-organizacional-search-modal.service';
import {UnidadeOrganizacionalSearchRequest} from '../../../request/unidade-organizacional-search-request';
import {Pageable} from '../../../shared/pagination/pageable';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {UnidadeOrganizacionalSearchResponse} from '../../../responses/unidade-organizacional-search-response';
import {UtilsService} from '../../../shared/utils.service';
import {
    ConfigContaUnidOrganizaTabViewService
} from '../../configurar-conta-corrente/config-conta-unid-organiza-tab-view/config-conta-unid-organiza-tab-view.service';
import {UnidadeOrganizacional} from '../../../models/unidade-organizacional';
import {Pagination} from "../../../shared/pagination/pagination";
import { Message } from 'primeng/api';
import swal from "sweetalert2";

@Component({
    selector: 'app-unidade-organizacional-search-modal',
    templateUrl: './unidade-organizacional-search-modal.component.html',
    styleUrls: ['./unidade-organizacional-search-modal.component.scss'],
    providers: []
})
export class UnidadeOrganizacionalSearchModalComponent implements OnInit {
    msgs1: Message[];
    blockedDocument = false;
    unidadeOrganizacionalSearchRequest: UnidadeOrganizacionalSearchRequest;
    unidadeOrganizacionalSearchResponses: UnidadeOrganizacionalSearchResponse[];
    pagination: Pagination<any>;
    constructor(
        public service: UnidadeOrganizacionalSearchModalService,
        private configContaUnidOrganizaTabViewService: ConfigContaUnidOrganizaTabViewService,
        private http: HttpClient,
        public utils: UtilsService
    ) {
    }

    ngOnInit(): void {
        this.pagination = new Pagination<any>();
        this.unidadeOrganizacionalSearchRequest = new UnidadeOrganizacionalSearchRequest();
        this.unidadeOrganizacionalSearchResponses = [];
    }

    onHide() {
        this.service.displayMaximizable = false;
    }

    search() {
        this.blockedDocument = true;
        this.http
            .get<Pageable<any>>(`${environment.baseUrl}/unidade-organizacional/search`, {
                params: {
                    ...this.unidadeOrganizacionalSearchRequest,
                    size: 5,
                    page: this.pagination.pageable.pageNumber
                }
            })
            .subscribe(response => {
                this.pagination = response;
                this.unidadeOrganizacionalSearchResponses = response.content;
                this.blockedDocument = false;
            }, error => {
                this.blockedDocument = false;
                this.handleError(error);
            });
    }

    select(uosr: UnidadeOrganizacional) {
        this.configContaUnidOrganizaTabViewService.configContaUnidOrganiza.unidadeOrganizacional =  uosr;
        this.unidadeOrganizacionalSearchResponses = [];
        this.service.displayMaximizable = false;
    }

    onPageChange(e) {
        this.pagination.pageable.pageNumber = e.page;
        this.search();
    }

    handleError(error: any) {
        if (error.status === 500) {
            swal.fire(
                error.statusText,
                "Ocorreu um erro interno",
                "error"
            );
        } else if (error.status === 412) {
            swal.fire({
                title: "Atenção",
                text: `${error.error.message}`,
                icon: "warning",
            });
        } else if (error.status === 400) {
            const errors = error.error.errors;

            this.msgs1 = [];
            errors.forEach((e: { message: any }) => {
                this.msgs1.push({
                    severity: "error",
                    summary: "Erro",
                    detail: e.message,
                });
            });
        }else {
            swal.fire({
                title: "Errror",
                text: `${error.error.message}`,
                icon: "error",
            });
        }
    }
}
