<p-card>
    <ng-template pTemplate="title"> Transação / Regra </ng-template>
    <ng-template pTemplate="content">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-5">
                <label>Transação</label>
                <p-dropdown
                placeholder="Selecione"
                [options]="transacaoList"
                [optionLabel]="'nomeResumido'"
                [(ngModel)]="transacaoSelecionada"
                ></p-dropdown>
            </div>
            <div class="p-field p-col-5">
                <label>Regra</label>
                <p-dropdown
                placeholder="Selecione"
                [options]="regraList"
                [optionLabel]="'descricao'"
                [(ngModel)]="regraSelecionada"
                ></p-dropdown>
            </div>
            <div class="p-field p-col-2">
                <button
                    style="float: right"
                    class="margin-top"
                    pButton
                    type="button"
                    icon="pi pi-plus-circle"
                    label="Adicionar"
                    (click)="addTransacaoAndRegra()"
                    [disabled]="implantado == 'SIM'"
                ></button>
            </div>
            <div class="p-field p-col-12">
                <p-table [value]="transacoesAndRegras" [paginator]="true" [rows]="5">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Transação</th>
                            <th>Descrição</th>
                            <th>Operação</th>
                            <th>Regra</th>
                            <th>Desc Regra</th>
                            <th>Ação</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
                        <tr>
                            <td>{{ item.transacaoId }}</td>
                            <td>{{ item.descricao }}</td>
                            <td>{{ item.operacao }}</td>
                            <td>{{ item.regraId }}</td>
                            <td>{{ item.descRegra }}</td>
                            <td>
                                <button
                                    pButton
                                    pRipple
                                    title="'Remover'"
                                    type="button"
                                    icon="pi pi-trash"
                                    class="p-button-rounded p-button-danger"
                                    style="margin-left: 3px"
                                    (click)="removerConta(rowIndex, item)"
                                    [disabled]="item.implantado == 'SIM'"
                                ></button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <div class="grid grid-nogutter justify-content-end">
            <p-button 
                label="Anterior"
                icon="pi pi-angle-left"
                (click)="voltarAba()"
            ></p-button>
            <p-button
                class="float-btn-next margin-right"
                label="Próxima"
                icon="pi pi-angle-right"
                iconPos="right"
                [disabled]="transacoesAndRegras.length <= 0"
                (click)="proximaAba()"
            ></p-button>
        </div>
    </ng-template>
</p-card>
