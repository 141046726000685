<p-card [header]="tituloPagina">
    <form [formGroup]="formGroupRegra">
        <div class="p-card-content">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-2 p-md-2">
                    <label>Código</label>
                    <input
                        type="text"
                        disabled
                        pInputText=""
                        formControlName="regraId"
                    />
                </div>

                <div class="p-field p-col-10 p-md-9">
                    <label>Descrição *</label>
                    <input
                        type="text"
                        pInputText=""
                        formControlName="descricao"
                        maxlength="255"
                    />
                    <p
                        class="erro-validacao"
                        *ngIf="
                            (formGroupRegra.controls.descricao.touched ||
                                submitted) &&
                            formGroupRegra.controls.descricao.errors?.required
                        "
                    >
                        Campo obrigatório
                    </p>
                </div>

                <div class="p-field p-col-3">
                    <label>Situação *</label>
                    <p-dropdown
                        formControlName="situacao"
                        [options]="situacaoEnum"
                        [optionLabel]="'valor'"
                        [optionValue]="'chave'"
                        [disabled]="!this.regraSelecao"
                    >
                    </p-dropdown>
                </div>

                <div class="p-field p-col-3">
                    <label>Tipo Cadastro</label>
                    <p-dropdown
                        formControlName="tipoCadastro"
                        [options]="tipoCadastros"
                        [optionLabel]="'descricao'"
                        [optionValue]="'tipoCadastroId'"
                        [autoDisplayFirst]="false"
                        (onChange)="onChangeTipoCadastro($event)"
                        [disabled]="this.regraSelecao?.beneficioImplantado"
                    >
                </p-dropdown>
                </div>

                <div class="p-field p-col-3">
                    <label>Categoria *</label>
                    <p-dropdown
                        formControlName="tipoCategoria"
                        [options]="tipoCategorias"
                        [optionLabel]="'descricao'"
                        [optionValue]="'tipoCategoriaId'"
                        [autoDisplayFirst]="false"
                        (onChange)="onChangeTipoCategoria($event)"
                        [disabled]="this.regraSelecao?.beneficioImplantado"
                    >
                </p-dropdown>
                <p
                    class="erro-validacao"
                    *ngIf="
                        (formGroupRegra.controls.tipoCategoria
                            .touched ||
                            submitted) &&
                        formGroupRegra.controls.tipoCategoria.errors
                            ?.required
                    "
                >
                    Campo obrigatório
                </p>
                </div>
            </div>
        </div>

        <div class="card">
            <p-table
                #dt
                [value]="listaParametrosView"
                [paginator]="false"
                responsiveLayout="stack"
                [(selection)]="selectedParametros"
                [rowHover]="false"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [showCurrentPageReport]="true"


            >
                <ng-template pTemplate="caption">
                    <div
                        class="flex align-items-center justify-content-between"
                    >
                        <h5 class="m-0">Parâmetros</h5>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 15rem">
                            <button
                                pTooltip="Adicionar parâmetro"
                                pButton
                                pRipple
                                icon="pi pi-plus"
                                class="p-button-success mr-2"
                                (click)="newParametro()"
                                [disabled]="this.regraSelecao?.beneficioImplantado"
                            ></button>
                            <button
                                [disabled]="selectedParametros.length < 1"
                                pButton
                                pRipple
                                icon="pi pi-trash"
                                class="p-button-danger mr-2"
                                pTooltip="Desagrupar Selecionados"
                                (click)="removerGrupo(null)"
                                style="margin-left: 1rem"
                                [disabled]="this.regraSelecao?.beneficioImplantado"
                            ></button>
                            <button
                                [disabled]="selectedParametros.length < 2"
                                pTooltip="Agrupar Selecionados"
                                pButton
                                pRipple
                                icon="pi pi-wallet"
                                class="p-button mr-2"
                                (click)="agruparSelecionados()"
                                style="margin-left: 1rem"
                                [disabled]="this.regraSelecao?.beneficioImplantado"
                            ></button>
                        </th>
                        <th style="width: 3rem">
                            <p-tableHeaderCheckbox [disabled]="this.regraSelecao?.beneficioImplantado"></p-tableHeaderCheckbox>
                        </th>
                        <th *ngIf="ultimoGrupoID > 0">Grupo</th>
                        <th>E / OU</th>
                        <th>Atributo</th>
                        <th>Operador</th>
                        <th style="width: 250px">Valor</th>
                    </tr>
                </ng-template>
                <ng-template
                    pTemplate="body"
                    let-parametro
                    style="min-height: 6rem"
                    let-rowIndex="rowIndex"
                >
                    <tr class="linha">
                        <td>
                            <button
                                pButton
                                pRipple
                                icon="pi pi-trash"
                                class="p-button-danger mr-2"
                                (click)="removeParameter(parametro)"
                                [disabled]="this.regraSelecao?.beneficioImplantado"
                            ></button>
                        </td>
                        <td>
                            <p-tableCheckbox *ngIf="listaParametrosView.length > 1"
                                [value]="parametro"
                                [disabled]="this.regraSelecao?.beneficioImplantado"
                            ></p-tableCheckbox>
                        </td>
                        <td *ngIf="ultimoGrupoID > 0">
                            {{ parametro.grupo != null ? parametro.grupo : "" }}
                        </td>
                        <td>
                            <p-dropdown *ngIf="rowIndex != 0"
                                class="dropdown"
                                [(ngModel)]="parametro.operadorRelacional"
                                [ngModelOptions]="{ standalone: true }"
                                [options]="operadoresRelacionalEnum"
                                [optionLabel]="'label'"
                                [optionValue]="'label'"
                                [disabled]="this.regraSelecao?.beneficioImplantado"
                            >
                            </p-dropdown>
                        </td>
                        <td>
                            <p-dropdown
                                [(ngModel)]="parametro.atributoId"
                                [ngModelOptions]="{ standalone: true }"
                                [options]="listaAtributos"
                                [optionLabel]="'descricao'"
                                [optionValue]="'atributoId'"
                                (onChange)="onChangeAtributo(parametro)"
                                [disabled]="this.regraSelecao?.beneficioImplantado"
                            >
                            </p-dropdown>
                        </td>
                        <td>
                            <p-dropdown
                                [(ngModel)]="parametro.operadorLogico"
                                [ngModelOptions]="{ standalone: true }"
                                [options]="operadoresLogicoEnum"
                                [optionLabel]="'label'"
                                [optionValue]="'value'"
                                [disabled]="this.regraSelecao?.beneficioImplantado"
                            >
                            </p-dropdown>
                        </td>
                        <td *ngIf="parametro.datatype.datatype === 'ALFANUMERICO'">
                            <input
                                type="text"
                                pInputText=""
                                [(ngModel)]="parametro.valor"
                                [ngModelOptions]="{ standalone: true }"
                                [maxLength]="parametro.atributo? parametro.atributo.tamanho : selectedAtributo ? selectedAtributo.tamanho : 1"
                                [disabled]="this.regraSelecao?.beneficioImplantado"
                            />
                        </td>
                        <td *ngIf="parametro.datatype?.datatype === null">
                            <input
                                type="text"
                                pInputText=""
                                [(ngModel)]="parametro.valor"
                                [ngModelOptions]="{ standalone: true }"
                                [maxLength]="selectedAtributo ? selectedAtributo.tamanho : 30"
                                [disabled]="this.regraSelecao?.beneficioImplantado"
                            />
                        </td>
                        <td *ngIf="parametro.datatype.datatype === 'NUMERICO'">
                            <p-inputNumber
                                [(ngModel)]="parametro.valor"
                                [ngModelOptions]="{ standalone: true }"
                                oninput="this.value=this.value.slice(0,this.maxLength)"
                                [max]="parametro.tamanho * 9"
                                mode="decimal" [maxFractionDigits]="2" [useGrouping]="false"
                                [disabled]="this.regraSelecao?.beneficioImplantado"
                                [max]="max" >
                            </p-inputNumber>

                        </td>
                        <td *ngIf="parametro.datatype.datatype === 'DATA'">
                            <p-calendar
                                dateFormat="dd/mm/yy"
                                [showIcon]="true"
                                [(ngModel)]="parametro.valor"
                                [ngModelOptions]="{ standalone: true }"
                                [disabled]="this.regraSelecao?.beneficioImplantado"
                            ></p-calendar>
                        </td>
                        <td *ngIf="parametro.datatype.datatype === 'MES_ANO'">
                            <p-inputMask
                                [(ngModel)]="parametro.valor"
                                [ngModelOptions]="{ standalone: true }"
                                mask="99/9999"
                                [disabled]="this.regraSelecao?.beneficioImplantado">
                            </p-inputMask>
                        </td>
                        <td *ngIf="parametro.datatype.datatype === 'TABELA'">
                            <p-dropdown id="tabela"
                                [(ngModel)]="parametro.valor"
                                [ngModelOptions]="{ standalone: true }"
                                [options]="parametro.datatype.value"
                                [disabled]="this.regraSelecao?.beneficioImplantado"
                            >
                            </p-dropdown>
                        </td>
                        <td *ngIf="parametro.datatype.datatype === 'SELECT'">
                            <p-dropdown 
                                [ngModelOptions]="{ standalone: true }"
                                [options]="estadoContaList"
                                optionLabel="descricao"
                                optionValue="estadoContaId"
                                [(ngModel)]="parametro.valor"
                                [autoDisplayFirst]="false"
                                [disabled]="this.regraSelecao?.beneficioImplantado"
                                >
                            </p-dropdown>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                    <div
                        class="flex align-items-center justify-content-between"
                    >
                        Total de
                        {{
                            listaParametrosView ? listaParametrosView.length : 0
                        }}
                        parâmetros adicionados.
                    </div>
                </ng-template>
            </p-table>
        </div>

        <div class="p-mt-3" style="text-align: right">
            <button
                type="button"
                pButton
                label="Voltar"
                class="p-button-outlined p-mr-2"
                (click)="voltar()"
            ></button>
            <button
                type="submit"
                pButton
                label="Salvar"
                (click)="onSubmit()"
            ></button>
        </div>
    </form>
</p-card>
