import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import * as moment from 'moment';
import { ConfigContaSaveAllRequest } from 'src/app/models/config-conta-save-all-request';

@Injectable({
    providedIn: 'root'
})
export class ConfigurarContaCorrenteService {
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };
    private routerInfo: BehaviorSubject<any>;

    constructor(
        private http: HttpClient,
    ) {
        this.routerInfo = new BehaviorSubject<any>(false);
    }

    getValue(): Observable<any> {
        return this.routerInfo.asObservable();
    }
    setValue(newValue): void {
        this.routerInfo.next(newValue);
    }

    convertDateToEdit(date = null) {
        return date == null ? null : moment(date).format('DD/MM/YYYY');
    }

    convertDateToSave(date = null) {
        return date == null ? null : moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }

    getSituacoes(): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${environment.baseUrl}/enums/config-conta/situacao`);
    }

    getTiposConta(): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${environment.baseUrl}/enums/config-conta/tipo-conta`);
    }

    getTiposDatasReferencias(): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${environment.baseUrl}/tipos-datas-referencias`);
    }

    getPeriodicidades(): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${environment.baseUrl}/periodicidade`);
    }

    getOrigemInformacaos(id, origemInformacaoId): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${environment.baseUrl}/origem-informacao/findByTipoOrigemInformacao?tipoOrigemInformacao=${id}&origemInformacaoId=${origemInformacaoId}`);
    }

    getOrigemInformacaoByTipoOrigem(tipoOrigem): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${environment.baseUrl}/origem-informacao?tipoOrigem=${tipoOrigem}`);
    }

    getTributos(): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${environment.baseUrl}/tributo`);
    }

    getTiposCadastro(): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${environment.baseUrl}/tipo-cadastro`);
    }

    getNaturezasContaCorrente(): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${environment.baseUrl}/natureza-conta-corrente`);
    }

    postContaSaveAll(requestSaveAll: ConfigContaSaveAllRequest): Observable<any> {
        return this.http.post<ConfigContaSaveAllRequest>(
            `${environment.baseUrl}/config-conta/save-all`, requestSaveAll
        );
    }

    getConfigContaId(configContaId) {
        return this.http.get<any>(`${environment.baseUrl}/config-conta/edit/${configContaId}`);
    }
    
    getConsultarConta(configContaId) {
        return this.http.get<any>(`${environment.baseUrl}/config-conta/${configContaId}`);
    }

}
