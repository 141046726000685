import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'iePipe' })
export class InscEstadualPipe implements PipeTransform {
    transform(value: string|number,
        ocultarAlgunsValores: boolean = false): string {
  let valorFormatado = value + '';

  valorFormatado = valorFormatado
      .padStart(9, '0')
      .substr(0, 9)
      .replace(/[^0-9]/, '')


  if (ocultarAlgunsValores) {
      valorFormatado =
            valorFormatado.substr(0, 7) + '-' + valorFormatado.substr(8);
  }

  return valorFormatado;
}
}
