import { TipoCadastro } from './../../../models/tipo-cadastro';
import { RegraSelecaoService } from '../regra-selecao.service';

import { RegraSelecao } from "./../../../models/regra-selecao";
import { ConfigRegraSelecaoSearchRequest } from "./../../../request/config-regra-selecao-search-request";
import { Component, OnInit } from "@angular/core";
import { Pagination } from "../../../shared/pagination/pagination";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { ConfigContaRegraCriacao } from "../../../models/config-conta-regra-criacao";
import swal from "sweetalert2";
import { Message } from "primeng/api";

@Component({
    selector: "app-config-regra-selecao-list",
    templateUrl: "./config-regra-selecao-list.component.html",
    styleUrls: ["./config-regra-selecao-list.component.scss"],
})
export class ConfigRegraSelecaoListComponent implements OnInit {
    displayMaximizable: boolean;
    situacaoes: any[];
    tipoCategorias: any[];
    tipoCadastros: any[];
    regraSelecaoSearchRequest: ConfigRegraSelecaoSearchRequest;
    pagination: Pagination<any>;
    regrasSelecao: RegraSelecao[];
    msgs1: Message[];
    blockedDocument = false;

    httpOptions = {
        headers: new HttpHeaders({
            "Content-Type": "application/json",
        }),
    };

    constructor(
        private http: HttpClient,
        public regraSelecaoListService: RegraSelecaoService,
        private router: Router
    ) {
        this.regraSelecaoSearchRequest = new ConfigRegraSelecaoSearchRequest();
    }
    ngOnInit(): void {
        this.blockedDocument = true;

        this.pagination = new Pagination<any>();
        this.regraSelecaoSearchRequest = new ConfigRegraSelecaoSearchRequest();
        this.regraSelecaoSearchRequest.situacao = null
            ? (this.regraSelecaoSearchRequest.situacao = "Ativa")
            : "";
        this.regraSelecaoListService.getSituacoes().subscribe((response) => {
            this.situacaoes = response;
            this.regraSelecaoSearchRequest.situacao =this.situacaoes[1].chave;
            this.pesquisar();
        });
        this.regraSelecaoListService.getTipoCadastro().subscribe((response) => {
            this.tipoCadastros = response;
        });
        this.regraSelecaoListService.getCategorias().subscribe((response) => {
            this.tipoCategorias = response;
        });
    }

    nova() {
        let novaRegra = "NOVA";
        this.router.navigate(['config-regra-selecao/config-regra-selecao-cadastro'], {state: { novaRegra }});
    }

    onEditar(regra: RegraSelecao) {
        this.router
            .navigate(["config-regra-selecao/config-regra-selecao-cadastro"], {
                state: { regraId: regra.regraId },
            })
            .then(() => {});
    }

    onPageChange(e) {
        this.pagination.pageable.pageNumber = e.page;
        this.pesquisar();
    }

    onTipoCadastroChange(e) {
        this.blockedDocument = true;
        if (e.value != null) {
            this.regraSelecaoListService.getCategoriasByIdCadastro(e.value).subscribe((response) => {
                this.tipoCategorias = response;
                this.blockedDocument = false;
            });
        }else{
            this.regraSelecaoListService.getCategorias().subscribe((response) => {
                this.tipoCategorias = response;
                this.blockedDocument = false;
            });
        }

    }


    pesquisar() {
        this.blockedDocument = true;
        const data = JSON.parse(JSON.stringify(this.regraSelecaoSearchRequest));
        data.situacao == null ? (data.situacao = "") : "";
        data.tipoCategoriaId == null ? (data.tipoCategoriaId = "") : "";
        data.tipoCadastroId == null ? (data.tipoCadastroId = "") : "";
        this.regraSelecaoListService
            .searchRegras(data, this.pagination)
            .subscribe((response) => {
                this.pagination = response;
                this.regrasSelecao = response.content;
                this.blockedDocument = false;
            }, (error) => {
                this.blockedDocument = false;
            }

            );
    }

    clearFilter() {
        this.blockedDocument = true;
        this.regraSelecaoSearchRequest.situacao = ""
        this.regraSelecaoSearchRequest.regraId = ""
        this.regraSelecaoSearchRequest.descricao = ""
        this.regraSelecaoSearchRequest.situacao = ""
        this.regraSelecaoSearchRequest.tipoCategoriaId = ""
        this.regraSelecaoSearchRequest.tipoCadastroId = ""

        const data = JSON.parse(JSON.stringify(this.regraSelecaoSearchRequest));
        data.situacao == null ? (data.situacao = "") : "";
        data.tipoCategoriaId == null ? (data.tipoCategoriaId = "") : "";
        data.tipoCadastroId == null ? (data.tipoCadastroId = "") : "";
        this.regraSelecaoListService
            .searchRegras(data, this.pagination)
            .subscribe((response) => {
                this.pagination = response;
                this.regrasSelecao = response.content;
                this.blockedDocument = false;
            }, (error) => {
                this.blockedDocument = false;
            }

            );
    }

    excluir(regra) {
        swal.fire({
            title: "Confirmação",
            text: `Deseja ${
                regra.situacao === "ATIVA" ? "INATIVAR" : "ATIVAR"
            }  a regra ${regra.regraId} - ${regra.descricao} ?`,
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
        }).then(
            (result) => {
                if (result.value) {
                    this.blockedDocument = true;
                    this.regraSelecaoListService
                        .mudaSituacao(regra.regraId)
                        .subscribe((resp) => {
                            this.blockedDocument = false;
                            swal.fire(
                                "Sucesso",
                                "Atualizado com sucesso",
                                "success"
                            ).then(() => {
                                this.blockedDocument = true;
                                this.pesquisar();
                            });
                        });
                }
            },
            (error) => {
                if (error.status === 500) {
                    swal.fire(
                        error.statusText,
                        "Ocorreu um erro interno",
                        "error"
                    ).then((r) => {});
                } else if (error.status === 412) {
                    swal.fire(
                        "Erro de Validação",
                        error.error.message,
                        "error"
                    ).then((r) => {});
                } else {
                    const errors = error.error.errors;
                    this.msgs1 = [];
                    errors.forEach((e: { message: any }) => {
                        this.msgs1.push({
                            severity: "error",
                            summary: "Erro",
                            detail: e.message,
                        });
                    });
                }
            }
        );
    }
}
