<p-toast></p-toast>
<p-card [style]="{'margin-top': '10px'}">
    <div class="grid grid-nogutter justify-content-end margin-bt">
        <button
            pButton
            label="Voltar"
            icon="pi pi-angle-left"
            class="p-button-outlined p-mr-2"
            (click)="irRConsultaConta()"
        ></button>
        <button
        pButton
            class="float-btn-next"
            [label]="labelBtnProximaESalvar"
            icon="pi pi-angle-right"
            iconPos="right"
            (click)="irConfirmacaoOuSalvar()"
        ></button>
    </div>
    <ng-template pTemplate="title">
        {{ title }}
    </ng-template>
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12" style="margin-top: 20px;">
            <app-visualiza-config-conta></app-visualiza-config-conta>
        </div>
    </div>

    <!-- FORMULÁRIO -->
    <div class="p-fluid p-formgrid p-grid">
            <form [formGroup]="memoriaCalculoForm" class="p-fluid p-formgrid p-grid">
            <div class="p-field p-md-6 p-sm-6">
                <p-fieldset legend="Tipo de Cadastro">
                    <div class="p-fluid p-formgrid p-grid font-style">
                        <div class="p-field p-col-12 p-md-6">
                            <label>Tipo de Cadastro *</label>
                            <p-dropdown
                            placeholder="Selecione"
                            [options]="tipoCadastroList"
                            [optionLabel]="'tipoCadastro.descricao'"
                            [optionValue]="'tipoCadastro.tipoCadastroId'"
                            [disabled]="hiddenItensConfirm == 3"
                            formControlName="tipoCadastro"
                            (onChange)="verificaValorTipoDeCadastro($event)"
                            ></p-dropdown>
                            <small class="p-error" *ngIf="(memoriaFormControl.tipoCadastro.touched || submitted)
                            && memoriaFormControl.tipoCadastro.errors?.required">
                                Campo obrigatório
                            </small>
                        </div>
                        <div *ngIf="memoriaFormControl.tipoCadastro.value || hiddenItensConfirm == 3" class="p-field p-col-12 p-md-6">
                            <label for="">{{labelCustomInput}} &nbsp;&nbsp;</label>
                            <p-inputMask
                                *ngIf="valueMask && hiddenItensConfirm != 3"
                                formControlName="inscEstadual"
                                [mask]="maskCustom"
                            ></p-inputMask>
                            <input
                                *ngIf="!valueMask && hiddenItensConfirm != 3"
                                maxlength="14"
                                placeholder="Informe CPF ou CNPJ"
                                formControlName="inscEstadual"
                                type="text"
                                pInputText=""
                            />
                            <input
                                *ngIf="hiddenItensConfirm == 3"
                                disabled
                                [value]="memoriaFormControl.inscEstadual.value"
                                type="text"
                                pInputText=""
                            />
                            <small class="p-error" *ngIf="(memoriaFormControl.inscEstadual.touched || submitted)
                                        && memoriaFormControl.inscEstadual.errors?.required">
                                Campo obrigatório
                            </small>
                        </div>
                    </div>
                </p-fieldset>
            </div>

            <div class="p-field p-md-6 p-sm-6">
                <p-fieldset legend="Documentos">
                    <div class="p-fluid p-formgrid p-grid font-style">
                        <div class="p-field p-col-12 p-md-8">
                            <label>Tipo de Documento*</label>
                            <p-dropdown
                            placeholder="Selecione"
                            [options]="documentoList"
                            [optionLabel]="'descricao'"
                            [optionValue]="'tipoNumeroDocumentoId'"
                            [disabled]="hiddenItensConfirm == 3"
                            formControlName="tipoDocumento"
                            ></p-dropdown>
                            <small class="p-error" *ngIf="(memoriaFormControl.tipoDocumento.touched || submitted)
                                            && memoriaFormControl.tipoDocumento.errors?.required">
                                    Campo obrigatório
                            </small>
                        </div>

                        <div class="p-field p-col-12 p-md-4">
                            <label for="">Número do Documento*</label>
                             <input
                            *ngIf="hiddenItensConfirm != 3"
                            formControlName="numeroDocumento"
                            [pKeyFilter]="somenteLetrasNumeros"
                            type="text"
                            maxlength="20"
                            pInputText
                            >
                            <input
                            *ngIf="hiddenItensConfirm == 3"
                            [disabled]="hiddenItensConfirm == 3"
                            [value]="memoriaFormControl.numeroDocumento.value"
                            type="text"
                            maxlength="20"
                            pInputText
                            >
                            <small class="p-error" *ngIf="(memoriaFormControl.numeroDocumento.touched || submitted)
                                            && memoriaFormControl.numeroDocumento.errors?.required">
                                    Campo obrigatório
                            </small>
                        </div>
                    </div>
                </p-fieldset>
            </div>

            <div class="p-field p-md-6 p-sm-12">
                <p-fieldset legend="Cota Única e Parcelas">
                    <div class="p-fluid p-formgrid p-grid font-style">
                        <div class="p-field p-col-12 p-md-5">
                            <label>Criar Cota Única<span *ngIf="consultaContaObj.permiteCotaUnica == '1'">*</span></label>
                            <div class="p-field-radiobutton" *ngFor="let item of enumSimNao">
                                <p-radioButton
                                name=""
                                [inputId]="item.chave"
                                [value]="item.chave"
                                [style]="{'margin-right':'20px;'}"
                                [disabled]="hiddenItensConfirm == 3 || consultaContaObj.permiteCotaUnica == '0'"
                                formControlName="criarCotaUnica"
                                ></p-radioButton>
                                <label [for]="item">{{item.valor}}</label>
                            </div>
                            <small class="p-error" *ngIf="(memoriaFormControl.criarCotaUnica.touched || submitted)
                                        && memoriaFormControl.criarCotaUnica.errors?.required">
                                Campo obrigatório
                        </small>
                        </div>
                        <div class="p-field p-col-12 p-md-2">
                            <label for="">Quantidade de Parcelas</label>
                            <p-inputNumber formControlName="qtdParcelas" [disabled]="hiddenItensConfirm == 3 || consultaContaObj.permiteParcelar == '0'"></p-inputNumber>
                            <small class="p-error" *ngIf="memoriaFormControl.qtdParcelas.errors?.required">
                                    Campo obrigatório
                            </small>
                        </div>
                        <div class="p-field p-col-12 p-md-2">
                            <label for="">Valor mínimo da Parcela </label>
                            <p-inputNumber
                            *ngIf="mode"
                            formControlName="valorMinimoParcela"
                            maxlength="15"
                            [mode]="mode"
                            [currency]="currency"
                            [locale]="locale"
                            [maximizable]="true"
                            [baseZIndex]="90"
                            [draggable]="false"
                            [resizable]="false"
                            [disabled]="hiddenItensConfirm == 3 || consultaContaObj.permiteParcelar == '0'"
                            (onBlur)="insereValidacao()"
                          ></p-inputNumber>
                        </div>
                        <div class="p-field p-col-12 p-md-3">
                            <label for="">Valor mínimo para Parcelamento *</label>
                            <p-inputNumber
                            *ngIf="mode"
                            formControlName="valorMinimoParcelamento"
                            maxlength="15"
                            [mode]="mode"
                            [currency]="currency"
                            [locale]="locale"
                            [maximizable]="true"
                            [baseZIndex]="90"
                            [draggable]="false"
                            [resizable]="false"
                            [disabled]="hiddenItensConfirm == 3 || consultaContaObj.permiteParcelar == '0'"
                            (onBlur)="insereValidacao()"
                          ></p-inputNumber>
                        </div>

                    </div>
                </p-fieldset>
            </div>

            <div class="p-field p-md-6 p-sm-6">
                <p-fieldset legend="Eventos">
                    <div class="p-fluid p-formgrid p-grid font-style">
                        <div class="p-field p-col-12 p-md-12">
                            <div *ngFor="let tipo of consultaContaObj.configuracaoEventos">
                                <div class="p-field-radiobutton">
                                    <p-radioButton
                                    [disabled]="this.consultaContaObj.configuracaoEventos.length == '1'"
                                    [inputId]="tipo.configuracaoEventosId"
                                    [value]="tipo.configuracaoEventosId"
                                    formControlName="configuracaoEventosId"
                                    ></p-radioButton>
                                    <label [for]="tipo"> {{tipo.descricaoResumida}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </p-fieldset>
            </div>

        </form>

           <!-- NG MODEL -->

           
           <div class="p-field p-md-6 p-sm-12">
            <p-fieldset legend="Itens de Composição da Conta">
                <div class="p-fluid p-formgrid p-grid font-style">
                    <div class="p-field p-col-12 p-md-3">
                        <label for="">Número da Parcela</label>
                        <input
                        *ngIf="hiddenItensConfirm != 3"
                        [(ngModel)]="numeroParcela"
                        [pKeyFilter]="somenteLetrasNumeros"
                        type="text"
                        maxlength="20"
                        pInputText
                        >
                        <input
                        *ngIf="hiddenItensConfirm == 3"
                        [(ngModel)]="numeroParcela"
                        [disabled]="hiddenItensConfirm == 3"
                        type="text"
                        maxlength="20"
                        pInputText
                        >
                    </div>
                    <div class="p-field p-col-12 p-md-3">
                        <label for="">Valor do Honorário</label>
                        <p-inputNumber
                        *ngIf="mode"
                        [(ngModel)]="valorHonorario"
                        maxlength="15"
                        [mode]="mode"
                        [currency]="currency"
                        [locale]="locale"
                        [maximizable]="true"
                        [baseZIndex]="90"
                        [draggable]="false"
                        [resizable]="false"
                        [disabled]="hiddenItensConfirm == 3"
                      ></p-inputNumber>
                    </div>
                    <div class="p-field p-col-12 p-md-12">
                        <p-table [value]="itensComposicaoMemoriaDeCalculo">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Código</th>
                                    <th>Campo</th>
                                    <th>Descrição</th>
                                    <th>Tipo</th>
                                    <th>Valor da Origem</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
                                <tr>
                                    <td>{{item.itemComposicaoId }}</td>
                                    <td>{{item.itemCampo}}</td>
                                    <td>{{item.itemDescricao}}</td>
                                    <td>{{item.itemTipo}}</td>
                                    <td>
                                        <p-inputNumber
                                        [disabled]="hiddenItensConfirm == 3"
                                        inputId="minmaxfraction"
                                        mode="decimal"
                                        [minFractionDigits]="2"
                                        [maxFractionDigits]="6"
                                        *ngIf="item.itemTipo == 'DECIMAL'"
                                        [(ngModel)]="item.valorOrigem"
                                        placeholder="Números decimais"
                                        (focus)="validaItensDeComposicaoList(item.itemTipo)"
                                        (blur)="validaItensDeComposicaoList(item.itemTipo)"
                                        (keydown)="validaItensDeComposicaoList(item.itemTipo)"
                                        (click)="validaItensDeComposicaoList(item.itemTipo)"
                                        (ngModelChange)="validaItensDeComposicaoList(item.valorOrigem)"
                                      ></p-inputNumber>
                                      <input
                                        *ngIf="item.itemTipo == 'NUMERICO'"
                                        (focus)="validaItensDeComposicaoList(item.itemTipo)"
                                        (blur)="validaItensDeComposicaoList(item.itemTipo)"
                                        (keydown)="validaItensDeComposicaoList(item.itemTipo)"
                                        (click)="validaItensDeComposicaoList(item.itemTipo)"
                                        pKeyFilter="num"
                                        type="text"
                                        maxlength="100"
                                        pInputText
                                        [(ngModel)]="item.valorOrigem"
                                        [disabled]="hiddenItensConfirm == 3"
                                        placeholder="Apenas números"
                                        >
                                        <input
                                        *ngIf="item.itemTipo == 'ALFANUMERICO'"
                                        (focus)="validaItensDeComposicaoList(item.itemTipo)"
                                        (blur)="validaItensDeComposicaoList(item.itemTipo)"
                                        (keydown)="validaItensDeComposicaoList(item.itemTipo)"
                                        (click)="validaItensDeComposicaoList(item.itemTipo)"
                                        [pKeyFilter]="somenteLetrasNumeros"
                                        type="text"
                                        maxlength="100"
                                        pInputText
                                        [(ngModel)]="item.valorOrigem"
                                        [disabled]="hiddenItensConfirm == 3"
                                        placeholder="Letras e números"
                                        >
                                        <p-calendar
                                        *ngIf="item.itemTipo == 'DATA'"
                                        (ngModelChange)="validaItensDeComposicaoList($event)"
                                        dateFormat="dd/mm/yy"
                                        appendTo="body"
                                        [showIcon]="true"
                                        [(ngModel)]="item.valorOrigem"
                                        [disabled]="hiddenItensConfirm == 3"
                                    ></p-calendar>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td class="p-text-center" colspan="6">
                                        Nenhuma item de Composição encontrado
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <small class="p-error" *ngIf="validacaoNaturezaCalculo && submitted">
                            <p>Informe todos os Valores de Origem</p>
                        </small>
                    </div>
                </div>
            </p-fieldset>
        </div>

    <!-- <div class="p-fluid p-formgrid p-grid"> -->
        <div class="p-field p-md-6 p-sm-6">
            <p-fieldset legend="Datas de Referência">
                <div class="p-fluid p-formgrid p-grid font-style">
                    <div class="p-field p-col-12 p-md-12">
                        <p-table [value]="tiposDatasReferenciaList">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Tipo de Data</th>
                                    <th>Data</th>
                                    <th class="p-text-center">Ocorreu evento</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
                                <tr>
                                    <td>{{ data.tipoDataReferenciaId }} - {{data.descricao}}</td>
                                    <td>
                                        <p-calendar
                                            dateFormat="dd/mm/yy"
                                            appendTo="body"
                                            [showIcon]="true"
                                            [(ngModel)]="data.dataInformada"
                                            (ngModelChange)="verificaCamposDatas($event)"
                                            [disabled]="hiddenItensConfirm == 3"
                                        ></p-calendar>
                                    </td>
                                    <td class="p-text-center">
                                        <p-radioButton
                                        [value]="data.tipoDataReferenciaId"
                                        [(ngModel)]="opcaoSelecionada"
                                        (onClick)="verificaEventoSim($event, rowIndex)"
                                        [disabled]="hiddenItensConfirm == 3 || tiposDatasReferenciaList.length === 1"
                                        ></p-radioButton>
                                   </td>

                                </tr>
                            </ng-template>

                            <ng-template pTemplate="emptymessage">
                                  <tr>
                                    <td class="p-text-center" colspan="6">
                                        Nenhuma registro encontrado
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>

                        <small class="p-error" *ngIf="!validacaoOcorreuEvento && submitted">
                            <p>Deve haver ao menos uma Data de Referência com o campo 'Ocorreu evento' SIM </p>
                        </small>

                        <small class="p-error" *ngIf="validacaoDataReferencia && submitted">
                            <p>Informe todas as datas de referência</p>
                        </small>
                    </div>
                </div>
            </p-fieldset>
        </div>

        <div class="p-field p-md-6 p-sm-6">
            <p-fieldset legend="Anexos">
                <div class="p-fluid p-formgrid p-grid font-style" *ngIf="hiddenItensConfirm == 1">
                    <div class="p-field p-md-6 p-sm-12">
                        <label>Anexar Documento</label>
                        <div class="col">
                            <input
                            (change)="upload($event)"
                            type="file"
                            id="avatar" name="avatar"
                            accept=".pdf, .json">
                        </div>
                    </div>
                    <div class="p-field p-md-3 p-sm-12">
                        <label>Formato do Documento</label>
                        <div *ngFor="let formato of formatoDocumentoEnum">
                            <div class="p-field-radiobutton">
                                <p-radioButton
                                [value]="formato.chave"
                                [style]="{'margin-right': '20px;'}"
                                [(ngModel)]="formatoArquivo"
                                ></p-radioButton>
                                <label [for]="formato"> {{formato.valor}}</label>
                            </div>
                        </div>
                    </div>
                    <div
                    class="p-field p-col-12 p-md-4"
                    style="margin-top: 10px;">
                        <button
                            pButton
                            pRipple
                            label="Adicionar"
                            type="button"
                            icon="pi pi-plus-circle"
                            (click)="adicionarAnexo()"
                            [disabled]="anexos.length > 0 || !formatoArquivo || !nomeArquivo"
                        ></button>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid font-style">
                    <div class="p-field p-col-12 p-md-12">
                        <p-table [value]="anexos">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Arquivo</th>
                                    <th>Formato</th>
                                    <th>Ações</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
                                <tr>
                                    <td>{{ item?.arquivo }}</td>
                                    <td>{{ item?.formato }}</td>
                                    <td><button
                                        pButton
                                        pRipple
                                        title="Excluir"
                                        type="button"
                                        icon="pi pi-trash"
                                        class="p-button-rounded p-button-danger"
                                        [disabled]="hiddenItensConfirm == 3"
                                        (click)="removeAnexo(rowIndex)"
                                    ></button></td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </p-fieldset>
        </div>
    <!-- </div> -->
        </div>




    <div class="p-fluid p-formgrid p-grid">
         <div *ngIf="hiddenItensConfirm == 3" class="p-field p-md-12 p-sm-12">
                <p-fieldset legend="Contas associadas">
                    <p-table
                    [value]="contaAssociadaList"
                    [(selection)]="contasSelecionadas"
                    [scrollable]="true"
                >
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="contaCorrenteId">
                                Conta <p-sortIcon field="contaCorrenteId"></p-sortIcon>
                            </th>
                            <th pSortableColumn="tipoCadastroId">
                                Tipo Cadastro <p-sortIcon field="tipoCadastroId"></p-sortIcon>
                            </th>
                            <th pSortableColumn="codigoCadastro">
                                Código Cadastro
                                <p-sortIcon field="codigoCadastro"></p-sortIcon>
                            </th>
                            <th pSortableColumn="nomeResponsavel">
                                Contribuinte
                                <p-sortIcon field="nomeResponsavel"></p-sortIcon>
                            </th>
                            <th pSortableColumn="numeroDocumento">
                                Número Documento
                                <p-sortIcon field="numeroDocumento"></p-sortIcon>
                            </th>
                            <th pSortableColumn="periodoReferencia">
                                Período Referência
                                <p-sortIcon field="periodoReferencia"></p-sortIcon>
                            </th>
                            <th pSortableColumn="parcela">
                                Cota/Pacela
                                <p-sortIcon field="parcela"></p-sortIcon>
                            </th>
                            <th pSortableColumn="dataVencimento">
                                Vencimento
                                <p-sortIcon field="dataVencimento"></p-sortIcon>
                            </th>
                            <th pSortableColumn="estadoContaDesc">
                                Estado da Conta
                                <p-sortIcon field="estadoContaDesc"></p-sortIcon>
                            </th>
                            <th pSortableColumn="saldoContaDesc">
                                Saldo da Conta
                                <p-sortIcon field="saldoContaDesc"></p-sortIcon>
                            </th>
                            <th pSortableColumn="situacaoCreditoDesc">
                                Situação Crédito
                                <p-sortIcon field="situacaoCreditoDesc"></p-sortIcon>
                            </th>
                            <th pSortableColumn="situacaoCobrancaDesc">
                                Situação Cobrança
                                <p-sortIcon field="situacaoCobrancaDesc"></p-sortIcon>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-item>
                        <tr class="selecao">
                            <td>{{ item?.contaCorrenteId }}</td>
                            <td>{{ item?.tipoCadastroId }}</td>
                            <td>{{ item?.codigoCadastro }}</td>
                            <td>{{ item?.nomeResponsavel }}</td>
                            <td>{{ item?.numeroDocumento }}</td>
                            <td>{{ item?.periodoReferencia }}</td>
                            <td>{{ item?.contaCorrenteCotaUnicaId ? item?.contaCorrenteCotaUnicaId : '-' }}/{{ item?.parcela }}</td>
                            <td>{{ item?.dataVencimento }}</td>
                            <td>{{ item?.estadoContaDesc }}</td>
                            <td>{{ item?.saldoContaDesc }}</td>
                            <td>{{ item?.situacaoCreditoDesc }}</td>
                            <td>{{ item?.situacaoCobrancaDesc }}</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td class="p-text-center">
                                <div style="margin-left: 46%">
                                    Nenhum registro encontrado
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                </p-fieldset>
            </div>
    </div>

    <div class="grid grid-nogutter justify-content-end margin-bt">
        <button
            pButton
            label="Voltar"
            icon="pi pi-angle-left"
            class="p-button-outlined p-mr-2"
            (click)="irRConsultaConta()"
        ></button>
        <button
        pButton
            class="float-btn-next"
            [label]="labelBtnProximaESalvar"
            icon="pi pi-angle-right"
            iconPos="right"
            (click)="irConfirmacaoOuSalvar()"
        ></button>
    </div>

</p-card>
