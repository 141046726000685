
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SelecionarContribInternaService } from 'src/app/compartilhado/services/selecionar-contrib-interna/selecionar-contrib-interna.service';
import { ContaCorrenteFinanceiros, FinanceiroConta } from 'src/app/models/financeiro';
import { UtilsService } from 'src/app/shared/utils.service';
import { AlertsUtil } from 'src/app/utils/alerts/alerts.util';
import { VisualizaExtratoVeiculoComponent } from './components/visualiza-extrato-veiculo/visualiza-extrato-veiculo.component';
import { ExtratoContaCorrenteService } from './service/extrato-conta-corrente.service';

@Component({
    selector: 'app-extrato-conta-corrente',
    templateUrl: './extrato-conta-corrente.component.html',
    styleUrls: ['./extrato-conta-corrente.component.scss']
})
export class ExtratoContaCorrenteComponent implements OnInit {
    selectedCountry: string;
    contaSelected: any[];
    countries: any[];
    contasList: any[];
    listaVeiculos: any[] = [];
    renavam;
    inscEstadual;

    selectedConta: string;
    tributos: any[];
    selectedTributo: any[]
    selectedEstadoConta: any[]
    contas: any[];
    dataInicio: any;
    dataFim: any = '';
    estadoConta: any;
    checkTributario: any = 'TRIBUTARIA';
    checkNaoTributario: any;
    valor: boolean = true;

    contribuinteSelecionado: any;
    financeiroContribuinteSelecionado:FinanceiroConta[];
    dadosDoContasDoContribuinteSelecionado: any;
    dadosDebito: FinanceiroConta[] ;
    @ViewChild(VisualizaExtratoVeiculoComponent, { static: false })
    visualizaVeiculo: VisualizaExtratoVeiculoComponent;
    tipoCadastro: string;
    contaCorrenteFinanceiros: ContaCorrenteFinanceiros[]= [];
    desconto: number;
    valorTotal: number;
    valorParcela: number;
    dadosCarregados: boolean = false;
    value: any;
    messageService: any;


    constructor(
        private router: Router,
        private extratoContaCorrenteService: ExtratoContaCorrenteService,
        private util: UtilsService,
        private alertUtil: AlertsUtil,
        private selecionarContribInternaService: SelecionarContribInternaService
    ) {
        this.contribuinteSelecionado = JSON.parse(localStorage.getItem('contribuinteSelecionado'));
    }

    ngOnInit(): void {
        this.progressBar()
        this.handleChange(this.contribuinteSelecionado.pessoa);
        this.getEnums();
        this.getContaCorrenteData();
        this.searchFinanceiroContaCorrente();

    }
    handleChange(tipoCadastro) {
        switch (tipoCadastro) {
            // TAB-?
            case "FISICA":
                this.tipoCadastro = '';
                break;
            // TAB-?
            case "JURIDICA":
                this.tipoCadastro = '';
                break;
            // TAB-VEICULO
            case "IPVA":
                this.tipoCadastro = 'RENAVAM'
                break;
            default:
                this.tipoCadastro = '';
                break;
        }
    }

    progressBar(){
        let interval = setInterval(() => {
            this.value = this.value + Math.floor(Math.random() * 10) + 1;
            if (this.value >= 100) {
                this.value = 100;
                this.messageService.add({severity: 'info', summary: 'Success', detail: 'Process Completed'});
                clearInterval(interval);
            }
        }, 2000);

    }

    pesquisar() {
        const objFilter = {
            tipoConta: this.checkTributario,
            renavam: this.renavam,
            inscEstadual: this.inscEstadual,
            tributo: this.selectedTributo,
            conta: this.selectedConta,
            dataInicio: this.util.convertDateToSave(this.dataInicio).substring(0, 7).replace(/[^0-9]/g, ''),
            dataFim: this.util.convertDateToSave(this.dataFim).substring(0, 7).replace(/[^0-9]/g, ''),
            estadoConta: this.selectedEstadoConta
        }
        this.visualizaVeiculo.pesquisaEmit(objFilter);
    }

    voltar() {
        this.router.navigateByUrl('/selecionar-contrib-interna/selecionar-contrib-interna');
    }

    onChangeConta(ev) {

    }

    async getEnums() {
        const objParams = {
            situacao: 'ATIVA',
            tipoCodigoCadastro: this.tipoCadastro
        }
        this.extratoContaCorrenteService.getConfigConta(objParams).subscribe(
            (resp: any) => {
                this.contas = resp
            }
        )
        this.extratoContaCorrenteService.getEstadoConta().subscribe(
            (resp: any) => {
                this.estadoConta = resp
            }
        )
        this.tributos = await this.extratoContaCorrenteService
            .getTributo()
            .toPromise();
        return new Promise((resolve) => {
            resolve("");
        });
    }

    onSelectDataMenor(event?) {
        let dataInicio = this.util.convertDateToSave(
            this.dataInicio
        );
        let dataFim = this.util.convertDateToSave(
            this.dataFim
        );
        if (dataFim < dataInicio) {
            this.alertUtil.warning(
                "A Data Fim não pode ser inferior a Data Inicio"
            ).then((r) => {
                this.dataFim = '';
            });
        }
        if (dataInicio) {
            this.calculaInterValoDatas(dataInicio);
        }

    }

    calculaInterValoDatas(dataInicio) {
        let dataAtual: any = this.util.convertDateToSave(new Date());
        const now = new Date(); // Data de hoje
        const past = new Date(dataInicio); // Outra data no passado
        const diff = Math.abs(now.getTime() - past.getTime()); // Subtrai uma data pela outra
        const days = Math.ceil(diff / (1000 * 60 * 60 * 24)); // Divide o total pelo total de milisegundos correspondentes a 1 dia. (1000 milisegundos = 1 segundo).

        if (dataInicio > dataAtual && days > 1825) {
            this.alertUtil.warning(
                `O período não pode ser maior que cinco anos`
            ).then((r) => {
                this.dataInicio = null;
                this.dataFim = '';
            });
        }
    }

    private getContaCorrenteData() {
        let filtro = null;
        if (this.contribuinteSelecionado.pessoa && this.contribuinteSelecionado.pessoa === 'FISICA' ||
            this.contribuinteSelecionado.pessoa && this.contribuinteSelecionado.pessoa === 'JURIDICA') {
            filtro = this.contribuinteSelecionado.ie
        } else {
            filtro = this.contribuinteSelecionado.idRenavam;
        }
        this.selecionarContribInternaService.searchContaCorrente(filtro).subscribe(resp => {
            this.dadosDoContasDoContribuinteSelecionado = resp.content;
        });
    }

    private searchFinanceiroContaCorrente() {
        let filtro = null;
        if (this.contribuinteSelecionado.pessoa && this.contribuinteSelecionado.pessoa === 'FISICA' ||
            this.contribuinteSelecionado.pessoa && this.contribuinteSelecionado.pessoa === 'JURIDICA') {
            filtro = this.contribuinteSelecionado.ie
        } else {
            filtro = this.contribuinteSelecionado.idRenavam;
        }
        this.selecionarContribInternaService.getFinanceiroContaCorrente(filtro).subscribe(resp => {
            this.financeiroContribuinteSelecionado = resp;
            this.dadosDebito = this.financeiroContribuinteSelecionado;
            this.dadosCarregados=true;
        });
    }
    public getValorFinanceiro(contaCorrenteFinanceiros: any[], descricao: string): void {
        const financeiro = contaCorrenteFinanceiros.find(financ => financ.naturezaItemCalculo.descricao === descricao);
        return financeiro ? financeiro.valorLancado : 0;
    }

}
