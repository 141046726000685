import { SimNaoEnum } from '../utils/enums/sim-nao.enum';
import { RegraParametro } from './regra-parametro';
import { TipoCategoria } from './tipo-categoria';

export class RegraSelecao {
    regraId: string;
    descricao: string;
    situacao: string;
    tipoCategoriaId: string;
    beneficioImplantado: boolean;
    tipoCategoria: TipoCategoria;
    regraParametros: RegraParametro[];
    regraParametroSaveRequests: RegraParametro[] = [];


    constructor(init?: Partial<RegraSelecao>) {
        if (init) {
            Object.assign(this, init);
        } else {
            this.regraId = null;
            this.descricao = null;
            this.situacao = null;
            this.tipoCategoriaId = null;
            this.situacao = null;
        }
    }
}
