<p-dialog
    header="Transação Financeira de Pagamento"
    (onHide)="onHide()"
    (onShow)="onShow()"
    [(visible)]="eventoPagamentoTransacaoFinanceiraModalService.displayMaximizable"
    [modal]="true"
    [style]="{ width: '60vw' }"
    [maximizable]="true"
    [baseZIndex]="10000"
    [draggable]="true"
    [resizable]="false"
>
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-6">
            <label title="Transação">Transação*</label>
            <p-dropdown
                optionLabel="nomeResumido"
                showClear="true"
                [autoDisplayFirst]="false"
                [options]="transacoesPagamento"
                [(ngModel)]="pagamentoTransacao.transacao"
                appendTo="body"
                [disabled]="eventoPagamento.implantado === 'NAO' && transacaoPagamentoEdit?.eventoPagamentoTransacaoId !== null"
                ></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-4">
            <label title="dataInicioVigencia">Início da Vigência</label>
            <p-calendar
                [disabled]="eventoPagamento.implantado === 'SIM'"
                appendTo="body"
                dateFormat="dd/mm/yy"
                [showIcon]="true"
                [(ngModel)]="dateStart"
                (ngModelChange)="onSelectDataInicioVigencia($event)"
            ></p-calendar>
        </div>
    </div>

    <ng-template pTemplate="footer">
        <p-button
            icon="pi pi-times"
            (click)="
                eventoPagamentoTransacaoFinanceiraModalService.displayMaximizable = false
            "
            label="Fechar"
        ></p-button>
        <p-button
            [disabled]="validaConfirmar()"
            icon="pi pi-save"
            (click)="confirmar()"
            label="Confirmar"
        ></p-button>
    </ng-template>
</p-dialog>


