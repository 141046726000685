<div>
    <strong style="margin-bottom: 25px">Eventos</strong>
</div>

<p-table
    #dt1
    [value]="eventosList?.content"
    [paginator]="true"
    [rowHover]="true"
    [rows]="15"
    [lazyLoadOnInit]="false"
    [lazy]="true"
    [totalRecords]="eventosList?.totalElements"
    [first]="eventosList?.pageable?.offset"
    (onLazyLoad)="paginar($event)"
>
    <ng-template pTemplate="caption">
        <div class="p-d-flex" style="margin-left: auto !important; width: 16%">
            <span class="p-input-icon-left p-ml-auto">
                <i class="pi pi-search"></i>
                <input
                    pInputText
                    type="text"
                    (input)="dt1.filterGlobal($event.target.value, 'contains')"
                    placeholder="Pesquisar na lista"
                />
            </span>
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th>Data do Evento</th>
            <th>Nome</th>
            <th>Efeito no Saldo da Conta</th>
            <th>Efeito na Situação do Crédito</th>
            <th>Estado da Conta</th>
            <th class="text-right">Valor</th>
            <th class="text-right">Ações</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-evento>
        <tr
            class="selecao"
            [ngClass]="{
                'evento-selecionado': vinculoEvento(
                    evento.contaCorrenteEventoId
                )
            }"
        >
            <td
                (click)="
                    buscaTransacoesVinculoEvento(evento.contaCorrenteEventoId)
                "
            >
                {{ evento?.dataEvento | date : "dd/MM/yyyy" }}
            </td>
            <td
                (click)="
                    buscaTransacoesVinculoEvento(evento.contaCorrenteEventoId)
                "
            >
                {{ evento?.configuracaoEventos?.eventos?.descricao }}
            </td>
            <td
                (click)="
                    buscaTransacoesVinculoEvento(evento.contaCorrenteEventoId)
                "
            >
                {{ evento?.saldoConta?.descricao }}
            </td>
            <td
                (click)="
                    buscaTransacoesVinculoEvento(evento.contaCorrenteEventoId)
                "
            >
                {{ evento?.situacaoCredito?.descricao }}
            </td>
            <td
                (click)="
                    buscaTransacoesVinculoEvento(evento.contaCorrenteEventoId)
                "
            >
                {{ evento?.estadoConta?.descricao }}
            </td>
            <td
                (click)="
                    buscaTransacoesVinculoEvento(evento.contaCorrenteEventoId)
                "
                class="text-right"
            >
                {{ evento?.valorTotalTransacoes | currency : "BRL" : "" }}
            </td>
            <td class="text-right">
                <i
                    class="pi pi-search"
                    style="cursor: pointer"
                    (click)="openModalEvento(evento)"
                ></i>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td class="p-text-center" colspan="9">
                Nenhum registro encontrado
            </td>
        </tr>
    </ng-template>
</p-table>
<div>
    <strong style="margin-bottom: 25px">Transações</strong>
</div>

<p-table
    #dt2
    [value]="transacaoList"
    [rowHover]="true"
    [globalFilterFields]="[
        'dataTransacao',
        'nomeResumido',
        'valorPrincipal',
        'valorMultaMora',
        'valorJurosMora',
        'valorMultaInfracao',
        'valorTotal'
    ]"
>
    <ng-template pTemplate="caption">
        <div class="p-d-flex" style="margin-left: auto !important; width: 16%">
            <span class="p-input-icon-left p-ml-auto">
                <i class="pi pi-search"></i>
                <input
                    pInputText
                    type="text"
                    (input)="dt2.filterGlobal($event.target.value, 'contains')"
                    placeholder="Pesquisar na lista"
                />
            </span>
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th>Data</th>
            <th>Nome</th>
            <th class="text-right">Principal</th>
            <th class="text-right">Juros</th>
            <th class="text-right">Multa (Mora)</th>
            <th class="text-right">Multa (Infração)</th>
            <th class="text-right">Saldo</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
        <tr
            class="selecao"
            (click)="buscaTransacoesVinculoEvento(item.contaCorrenteEventoId)"
            [ngClass]="{
                'evento-selecionado': vinculoEvento(item.contaCorrenteEventoId)
            }"
        >
            <td>{{ item.dataTransacao }}</td>
            <td>{{ item.nomeResumido }}</td>
            <td class="text-right">
                {{ item.valorPrincipal | currency : "BRL" : "" }}
            </td>
            <td class="text-right">
                {{ item.valorMultaMora | currency : "BRL" : "" }}
            </td>
            <td class="text-right">
                {{ item.valorJurosMora | currency : "BRL" : "" }}
            </td>
            <td class="text-right">
                {{ item.valorMultaInfracao | currency : "BRL" : "" }}
            </td>
            <td class="text-right">
                {{ item.valorTotal | currency : "BRL" : "" }}
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td class="p-text-center" colspan="9">
                Nenhum registro encontrado
            </td>
        </tr>
    </ng-template>
</p-table>

<modal-evento-conta-corrente></modal-evento-conta-corrente>
