import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EnumsService } from 'src/app/core/enums.service';
import { Pagination } from 'src/app/shared/pagination/pagination';
import { UtilsService } from 'src/app/shared/utils.service';
import { AlertsUtil } from 'src/app/utils/alerts/alerts.util';
import swal from "sweetalert2";
import { EventoLancamentoService } from '../evento-lancamento.service';

@Component({
    selector: "app-evento-lancamento-list",
    templateUrl: "./evento-lancamento-list.component.html",
    styleUrls: ["./evento-lancamento-list.component.scss"],
})
export class EventoLancamentoListComponent implements OnInit {
    eventoLancamentoList = [];
    blockedDocument = false;
    pagination: Pagination<any>;
    situacaoEnum: any[] = [];
    implantadoEnum: any[] = [];
    eventoLancamentoId: any = "";
    situacao: any = "";
    descricao: any = "";
    objParams: { eventoLancamentoId?: any; situacao?: any; descricao?: any };

    constructor(
        private eventoLancamentoService: EventoLancamentoService,
        private enumsService: EnumsService,
        private alertsUtil: AlertsUtil,
        private utilService: UtilsService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.pagination = new Pagination<any>();
        this.init();
           
    }

    async init() {
        await this.getEnum();
        this.pesquisar();  
    }

    onPageChange(e) {
        this.pagination.pageable.pageNumber = e.page;
        this.pesquisar();  
    }

    pesquisar(objParams?) {
        if (!objParams) {
            objParams = this.objParams;
        }
        this.blockedDocument = true;
        this.eventoLancamentoService.getEventoLancamento(objParams, this.pagination).subscribe(
            (resp: any) => {
                this.blockedDocument = false;
                this.pagination = resp;
                this.eventoLancamentoList = resp.content;
            },
            (error) => {
                this.alertsUtil.handleError(error);
            }
        );
    }

    pesquisaFiltro() {
        this.blockedDocument = false;
        if (this.situacao === null) {
            this.situacao = "";
        }
        if (this.eventoLancamentoId === null) {
            this.eventoLancamentoId = "";
        }
        this.objParams = {
            eventoLancamentoId: this.eventoLancamentoId,
            situacao: this.situacao,
            descricao: this.descricao,
        };
        this.pesquisar(this.objParams);
    }

    async getEnum() {
        this.situacaoEnum = await this.enumsService.getSituacao().toPromise();
        this.implantadoEnum = await this.enumsService.getEnumNome("SimNaoEnum").toPromise();
    }

    getValorEnum(chave, enumName) {
       let valor = enumName.find(e => e.chave === chave) 
       return valor.valor;
    }

    putAtivaInativa(el) {
        let text = el.situacao === 'ATIVA' ? 'inativar' : 'ativar';
        swal.fire({
            title: "Aviso",
            text: `Deseja ${text} o evento de lançamento de crédito ${el.eventoLancamentoId} - ${el.descricao}?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "SIM",
            cancelButtonText: "NÃO"
        }).then((result) => {
            if (result.isConfirmed) {
                this.eventoLancamentoService
                    .putAtivaInativa(el.eventoLancamentoId)
                    .subscribe(
                        (resp) => {
                          this.alertsUtil.saveSuccess('Atualizado!');
                          this.objParams = {};
                          this.pesquisar();
                        },
                        (error) => {
                            this.alertsUtil.handleError(error);
                        }
                    );
            }
        });
    }
    
    delete(el) {
        swal.fire({
            text: `Deseja excluir o evento de lançamento de crédito ${el.eventoLancamentoId} - ${el.descricao}?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
        }).then((result) => {
            if (result.value) {
                this.eventoLancamentoService
                    .delete(el.eventoLancamentoId)
                    .subscribe(
                        (response) => {
                            swal.fire(
                                "Sucesso",
                                "Removido com sucesso",
                                "success"
                            ).then((r) => this.pesquisar());
                        },
                        (error) => {
                            if (error.status === 500 || error.status === 404) {
                                swal.fire(
                                    error.statusText,
                                    "Ocorreu um erro interno",
                                    "error"
                                ).then((r) => {});
                            } else {
                                swal.fire(
                                    "Erro de Negócio",
                                    error.error.message,
                                    "error"
                                ).then((r) => {});
                            } 
                        }
                    );
            } else {
               swal.DismissReason.cancel;
            }
        });
    }

    convertDateBr(data) {
        return this.utilService.convertToDateBr(data);
    }

    configEventoLancamento(eventoLancamentoId?){
        if(eventoLancamentoId){
            this.router.navigateByUrl(`/config-evento-lancamento/${eventoLancamentoId}`);
        } else {
            this.router.navigateByUrl('/config-evento-lancamento');
        }
    }

}
