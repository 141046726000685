import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PerfisAcessoService } from '../../services/perfis-acesso.service'
import * as moment from 'moment'
import swal from "sweetalert2";
import { AlertsUtil } from "src/app/utils/alerts/alerts.util";

@Component({
    selector: "app-associar-perfil",
    templateUrl: "./associar-perfil.component.html",
    styleUrls: ["./associar-perfil.component.scss"],
})
export class AssociarPerfilComponent implements OnInit {

    perfilSelecionado: any;
    perfis: any[] = [];
    perfisSave: any[] = [];
    perfisList: any = [];
    @Input() transacaoId: any;
    @Output() onChange: EventEmitter<any> = new EventEmitter();

    constructor(
        private perfisAcessoService: PerfisAcessoService,
        private alertUtil: AlertsUtil,
    ) {}

    ngOnInit(): void {
        this.perfisAcessoService.getPerfis().subscribe((res) => {
            this.perfisList = res
        }, erro => {
            this.alertUtil.handleError(erro);
        },
        );

        this.getListaPerfis();
    }

    getListaPerfis() {
        if (this.transacaoId) {
            this.perfisAcessoService
                .getPerfisByTransacao(this.transacaoId)
                .subscribe((res) => {
                    this.perfis = res.map((r) => ({
                        ...r,       
                        transacaoPerfilId: r.transacaoPerfilId,
                        codigoPerfil: r.codigoPerfil,
                        descricao: r.descricao,
                        dataFim: r.dataFim ? moment(r.dataFim, "YYYY-MM-DD").format(
                            "DD/MM/YYYY"
                        ) : null,
                        dataCadastro: r.dataCadastro ? moment(
                            r.dataCadastro,
                            "YYYY-MM-DD"
                        ).format("DD/MM/YYYY") : null,
                    }));

                    this.perfis = [
                        ...this.perfis,
                        ...this.perfisSave
                    ];
                });
        }
    }

    addPerfis() {
        if (!this.perfilSelecionado) {
            swal.fire({
                icon: "warning",
                title: "Atenção",
                text: "Escolha um perfil!",
                showConfirmButton: true,
            });
            return;
        }

        if ( this.perfis.find( (p) => p.codigoPerfil == this.perfilSelecionado.codigoPerfil ) ) {
            swal.fire({
                icon: "warning",
                title: "Atenção",
                text: `O perfil ${this.perfilSelecionado.codigoPerfil} já existe na lista!`,
                showConfirmButton: true,
            });
            return;
        }

        this.perfis = [
            ...this.perfis,
            {
                codigoPerfil: this.perfilSelecionado.codigoPerfil,
                descricao: this.perfilSelecionado.descricao,
                situacao: "ATIVA",
                dataCadastro: moment(new Date(), "YYYY-MM-DD").format("DD/MM/YYYY"),
                transacaoPerfilId: this.perfilSelecionado.transacaoPerfilId,
                transacaoId: this.transacaoId ? this.transacaoId : null,
            },
        ];

        this.perfisSave.push({
            codigoPerfil: this.perfilSelecionado.codigoPerfil,
            descricao: this.perfilSelecionado.descricao,
            situacao: "ATIVA",
            transacaoId: this.transacaoId ? this.transacaoId : null,
            transacaoPerfilId: this.perfilSelecionado.transacaoPerfilId,
        });
        this.perfilSelecionado = null;

        this.onChange.emit(
            this.perfisSave
        );
    }

    removePerfil(transacaoPerfil: any, index) {
        if (transacaoPerfil.transacaoPerfilId) {
            this.perfisAcessoService.putAtivaInativaTransacaoPerfil(transacaoPerfil.transacaoPerfilId).subscribe(
                (response) => {
                    swal.fire(
                        "Sucesso",
                        "Atualizado com sucesso",
                        "success"
                    ).then(() => {
                        this.getListaPerfis();
                    });
                },
                    (erro) => {
                        this.handleError(erro);
                    }
                );
        } else {
            let index2 = this.perfisSave.findIndex(
                (res) => res.codigoPerfil === transacaoPerfil.codigoPerfil
            );

            this.perfis.splice(index, 1);
            if (index2 >= 0) {
                this.perfisSave.splice(index2, 1);
                this.onChange.emit(
                    this.perfisSave.map((res) => ({
                        ...res,
                        dataFim: moment(res.dataFim, "DD/MM/YYYY").format(
                            "YYYY-MM-DD"
                        ),
                    }))
                );
            }
        }
    }

    /* salvarPerfis() {
        this.perfisAcessoService.savePerfil(this.perfisSave).subscribe(
            (response) => {
                swal.fire("Sucesso", "Salvo com sucesso", "success").then(
                    () => {
                        this.getListaPerfis();
                    }
                );
            },
            (erro) => {
                this.handleError(erro);
            }
        );
    } */

    handleError(error: any): void {
        if (error.status === 500) {
            swal.fire(
                error.statusText,
                "Ocorreu um erro interno",
                "error"
            ).then((r) => {});
        } else {
            swal.fire({
                title: "Atenção",
                text: `${error.error.message}`,
                icon: "warning",
            });
        }
    }
}
