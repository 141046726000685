import { Injectable } from "@angular/core";
import { Message } from "primeng/api";
import swal from "sweetalert2";

@Injectable({
    providedIn: "root",
})
export class AlertsUtil {
    msgs1: Message[];
    constructor() { }

    handleError (e: any): void {

        if (e.status >= 500) {
            swal.fire(
                e.statusText,

                "Ocorreu um erro interno",

                "error"
            ).then((r) => { });
        } else if (e.status >= 401 && e.status <= 403) {
            swal.fire(
                "Não Autorizado",

                "Você não possui permissão para acessar essa funcionalidade. Contate o administrador do sistema.",

                "error"
            ).then((r) => { });
        } else if (e.status === 404) {
            swal.fire(
                "Erro de Validação",

                e.error.message,

                "error"
            ).then((r) => { });
        } else if (e.status == 400) {
            let errors: any = [];
            errors = e?.erros?.errors;
            this.msgs1 = [];
            let msgAux: any = [];
            if(errors) {
                errors.forEach((e: { message: any }) => {
                    this.msgs1.push({
                        severity: "error",

                        summary: "Erro",

                        detail: e.message,
                    });
                    msgAux.push(e.message)
                });
            } else {
                msgAux = e.error.message
            }
            swal.fire({
                title: "Atenção",
                text: `${msgAux}`,
                icon: "warning",
            });

        } else if(e.status == 412){
            swal.fire({
                title: "Atenção",
                text: `${e.error.message ? e.error.message : e.error}`,
                icon: "warning",
            });
        } else {
            swal.fire({
                title: "Erro",
                text: `Erro inesperado`,
                icon: "error",
            });
        }
    }

    async saveSuccess (msg) {
        return await swal.fire("Sucesso", ` ${msg}.`, "success");
    }

    async confirm (msg) {
        return await swal.fire({
            title: "Confirmação",
            text: `${msg}`,
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "SIM",
            cancelButtonText: "NÃO",
        });
    }

    async confirmClose(msg) {
        return await swal.fire({
            title: "Confirmação",
            html: `
            <button id="custom-close-button" style="position: absolute; top: 10px; right: 10px; border: none; background: none; font-size: 30px; cursor: pointer;">&times;</button>
            <p>${msg}</p>
        `,
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "SIM",
            cancelButtonText: "NÃO",
            willClose: () => {},
            didOpen: () => {
                const closeButton = document.getElementById('custom-close-button') as any;
                closeButton.addEventListener('click', () => {
                    closeButton.clicked = true;
                    swal.close();
                });
            }
        });
    }

    async warning (msg) {
        return await swal.fire('Atenção', `${msg}`, 'warning')
    }
}
