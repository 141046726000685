<div class="card">
    <p-toast></p-toast>
    <p-steps [model]="items" [readonly]="true"></p-steps>
</div>

<div class="stepsdemo-content">
    <p-card>
        <ng-template pTemplate="title">
            Modelo
        </ng-template>

        <ng-template pTemplate="header">
            <div class="grid grid-nogutter justify-content-end" [style]="{padding: '10px'}">
                <p-button label="Anterior" (onClick)="prevPage()" icon="pi pi-angle-left"></p-button>
                <p-button class="float-btn-next" label="Próxima" (onClick)="nextPage()" icon="pi pi-angle-right" iconPos="right"></p-button>
            </div>
        </ng-template>

        <ng-template pTemplate="content">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12">
                    <app-identificacao-conta [configConta]="configConta"></app-identificacao-conta>
                </div>
            </div>
            <form [formGroup]="formGroupModelo" (ngSubmit)="onSubmit()">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12">
                    <p-fieldset legend="Modelos Associados">
                        <div class="p-field p-col-12">
                            <label>Modelo*</label>
                            <p-dropdown
                                optionLabel="displayLabel"
                                optionValue="regraCriacaoContaId"
                                formControlName="regraCriacaoContaId"
                                showClear="true"
                                [autoDisplayFirst]="false"
                                [options]="regraCriacaoConta"
                                [disabled]="habilitaCancelar"
                            >
                            </p-dropdown>
                            <p class="danger" *ngIf="(modeloFormControl.regraCriacaoContaId.touched || submitted)
                                        && modeloFormControl.regraCriacaoContaId.errors?.required">
                                Campo obrigatório
                            </p>
                        </div>
                        <div class="p-field p-col-3">
                            <label>Início da Vigência*</label>
                            <p-calendar
                                [showIcon]="true"
                                inputId="icon"
                                dateFormat="dd/mm/yy"
                                name="dataInicioVigencia"
                                formControlName="dataInicioVigencia"
                                [disabled]="!configConta.configContaId"
                                required
                            >
                            </p-calendar>
                            <p class="danger"  *ngIf="(modeloFormControl.dataInicioVigencia.touched || submitted)
                                        && modeloFormControl.dataInicioVigencia.errors?.required">
                               Campo obrigatório
                            </p>
                        </div>
                        <div class="p-field p-col-12 p-md-12" style="margin-top: 0; float: right">
                            <div *ngIf="habilitaCancelar" class="p-field p-col-1 p-md-1" style="float: inherit;">
                                <button
                                pButton
                                type="button"
                                label="Cancelar"
                                icon="pi pi-times"
                                class="p-button-secondary"
                                (click)="reset()"
                                ></button>
                            </div>
                            <div class="p-field p-col-1 p-md-2" style="float: inherit;">
                                <button
                                [disabled]="!configConta?.configContaId"
                                style="width: auto;float: inherit"
                                pButton
                                type="submit"
                                [icon]="this.icone"
                                class="p-mr-1"
                                [label]="btnSalvarAtualizar"
                                ></button>
                            </div>
                        </div>
                        <div class="p-field p-col-12">
                            <p-fieldset legend="Vigência das Associações">
                                <div class="p-field p-col-12">
                                    <p-table [value]="configContaRegraCriacao">
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th>Código*</th>
                                                <th>Descrição do Modelo*</th>
                                                <th>Início Vigência*</th>
                                                <th>Fim Vigência</th>
                                                <th>Ações</th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-configContaRegraCriacao>
                                            <tr>
                                                <td>{{configContaRegraCriacao.regraCriacaoConta.regraCriacaoContaId}}</td>
                                                <td>{{configContaRegraCriacao.regraCriacaoConta.descricaoResumida}}</td>
                                                <td>{{convertDateBr(configContaRegraCriacao.dataInicioVigencia)}}</td>
                                                <td>{{convertDateBr(configContaRegraCriacao.dataFimVigencia)}}</td>
                                                <td>
                                                    <button
                                                        pButton
                                                        pRipple
                                                        title="Editar"
                                                        type="button"
                                                        icon="pi pi-pencil"
                                                        class="p-button-rounded"
                                                        *ngIf="configContaRegraCriacao.situacao == 'ATIVA' &&
                                                         configContaRegraCriacao.dataInicioVigencia > dataAtual"
                                                        (click)="editar(configContaRegraCriacao)"
                                                        [disabled]="configConta.implantado == 'SIM'"
                                                    ></button>
                                                    <button
                                                        pButton
                                                        pRipple
                                                        title="Inativar"
                                                        type="button"
                                                        icon="pi pi-trash"
                                                        class="p-button-rounded p-button-danger"
                                                        *ngIf="configContaRegraCriacao.situacao == 'ATIVA' &&
                                                         configContaRegraCriacao.dataInicioVigencia > dataAtual"
                                                        (click)="remove(configContaRegraCriacao)"
                                                        style="margin-left: 3px;"
                                                        [disabled]="configConta.implantado == 'SIM'"
                                                    ></button>
                                                </td>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="emptymessage">
                                            <tr>
                                                <td colspan="12" style="text-align: center">
                                                    Nenhum registro localizado
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                </div>
                            </p-fieldset>
                            <p-paginator [rows]="10" [totalRecords]="pagination.totalElements" (onPageChange)="onPageChange($event)"></p-paginator>
                        </div>
                    </p-fieldset>
                </div>
            </div>
            </form>
        </ng-template>

        <ng-template pTemplate="footer">
            <div class="grid grid-nogutter justify-content-end">
                <p-button label="Anterior" (onClick)="prevPage()" icon="pi pi-angle-left"></p-button>
                <p-button class="float-btn-next" label="Próxima" (onClick)="nextPage()" icon="pi pi-angle-right" iconPos="right"></p-button>
            </div>
        </ng-template>
    </p-card>
</div>