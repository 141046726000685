import {
    Component,
    EventEmitter,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { ConfigurarContaCorrenteService } from "src/app/component/configurar-conta-corrente/configurar-conta-corrente.service";
import { ModalAlertaHonorarioComponent } from "src/app/modal/modal-alerta-honorario/modal-alerta-honorario.component";
import { ModalExtratoContaCorrenteComponent } from "src/app/modal/modal-extrato-conta-corrente/modal-extrato-conta-corrente.component";
import { ModalSimularHistoricoComponent } from "src/app/modal/modal-simular-historico/modal-simular-historico.component";
import { Pagination } from "src/app/shared/pagination/pagination";
import { UtilsService } from "src/app/shared/utils.service";
import { AlertsUtil } from "src/app/utils/alerts/alerts.util";
import { MaskCpfOuCnpj } from "src/app/utils/masklCpfCnpjList/maskCpfCnpj.util";
import { RemoveParamsUtil } from "src/app/utils/paramsRemove/paramsRemove.util";
import { ArrecadaCaoDareService } from "../../../services/arrecadacao-dare.service";
import { ConsultaContaCorrenteService } from "../../../services/consulta-conta-corrente.service";
import { RelatorioConta } from "../../../../../models/relatorio-conta";
import swal from "sweetalert2";
import Swal from "sweetalert2";

enum TipoRelatorio {
    XLS = 'Excel',
    PDF = 'PDF'
}

@Component({
    selector: "ccp-selecao-conta",
    templateUrl: "./selecao-conta.component.html",
    styleUrls: ["./selecao-conta.component.scss"],
})
export class SelecaoContaComponent implements OnInit {
    @Output() outEtapaSelecaoConta = new EventEmitter();
    @ViewChild(ModalExtratoContaCorrenteComponent, { static: false })
    modalExtratoConta: ModalExtratoContaCorrenteComponent;

    @ViewChild(ModalSimularHistoricoComponent, { static: false })
    modalSimularHistorico: ModalSimularHistoricoComponent;

    @ViewChild(ModalAlertaHonorarioComponent, { static: false })
    modalAlertaPossuiHonorario: ModalAlertaHonorarioComponent;

    title: string = "Selecionar Conta";
    valueContribuinte: any;
    listContasIpvaItcdIcms: any[] = [];

    contribuinteIeList: number[] = [];
    relacaoProfissionalIeList: any[] = [];
    renavamList: any[] = [];
    renavamValue: any;
    inscricaoValue: any;
    pagination: Pagination<any>;
    paginationRenavam: Pagination<any>;
    blockedDocument: boolean;
    tipoConta: any;
    abaSelecionada: string;
    objParamsFiltro: any;
    estadoContaList: any[];
    situacaoCreditoList: any[];
    saldoList: any[];
    situaCobrancaList: any[];
    tipoDocumentoList: any[];
    contaList: any[];
    contaDareVinculadaList: any[];

    /**Atributos filtro */
    contaValue: any;
    estadoValue: any;
    situacaoCreditoValue: any;
    saldoValue: any;
    situacaoCobrancaValue: any;
    dataInicialReferenciaValue: any;
    dataFinalReferenciaValue: any;
    tipoDocumentoValue: any;
    numeroDocumentoValue: any;
    tributoId: any;
    tipoNumeroDocumentoValue: any;
    msgNenhumRegistroEncontrado = `Não foi encontrado nenhum registro para `;
    rowIndexConta: any;
    contribuinteValue: any;
    tipoTributo: any;
    /**Remover OS 09 - blockeBtnFiltro */
    blockeBtnFiltro: boolean = true;
    selectedProducts: any[] = [];
    objParamsConta: {
        idsContas: any[];
        dataVencimento: any;
        informacoesComplementares: any;
    };
    oxen: any;

    tributoModels: any[];
    indexTabAtiva = 0;

    pesquisaRealizada = false;
    imprimirDetalhes: boolean = false;
    exportandoDocumentos: boolean = false;
    deveLimparFiltros = true; //false qnd carrega filtrosUsadosAnteriormente

    isRenavamDisabled: boolean = false;
    isContribuinteDisabled: boolean = false;

    constructor(
        private consultaContaCorrenteService: ConsultaContaCorrenteService,
        private arrecadaCaoDareService: ArrecadaCaoDareService,
        private alertsUtil: AlertsUtil,
        private utilService: UtilsService,
        private removeParamsUtil: RemoveParamsUtil,
        private utils: UtilsService,
        private maskCpfOuCnpj: MaskCpfOuCnpj,
        private configContaService: ConfigurarContaCorrenteService
    ) {}

    ngOnInit(): void {
        const filtrosUsadosAnteriormente = JSON.parse(
            localStorage.getItem("filtrosCCAnterior")
        );

        if (filtrosUsadosAnteriormente) {
            this.deveLimparFiltros = false;
            this.popularFiltros(filtrosUsadosAnteriormente.objParams);
            this.abaSelecionada = filtrosUsadosAnteriormente.abaSelecionada;
            this.indexTabAtiva = filtrosUsadosAnteriormente.indexTabAtiva;
            this.handleChange({ index: this.indexTabAtiva }); //forcando rodar a logica pq so setar o indexTavAtiva nao foi
            this.tributoId = filtrosUsadosAnteriormente.tributoId;
            this.pagination = filtrosUsadosAnteriormente.pagination;
            this.pagination.size = filtrosUsadosAnteriormente.paginationSize;
            this.paginationRenavam =
                filtrosUsadosAnteriormente.paginationRenavam;

            this.filtroConta();
        } else {
            this.abaSelecionada = "IPVA";
            this.indexTabAtiva = 0;
            this.tributoId = "1";
            this.pagination = new Pagination<any>();
            this.pagination.size = 20;
            this.paginationRenavam = new Pagination<any>();
        }

        setTimeout(() => {
            this.initStorage();
            this.getDropdownFiltro();
        }, 500);

        this.configContaService.getTributos().subscribe((response) => {
            this.tributoModels = response;
        });
    }

    initStorage() {
        this.valueContribuinte = JSON.parse(
            localStorage.getItem("contribuinte")
        );
        /**Valor tab view padrão setItem(IPVA)*/
        localStorage.setItem("valueTabPanel", JSON.stringify("IPVA"));
        if (this.valueContribuinte) {
            setTimeout(() => {
                this.getRenavamPageable(
                    this.valueContribuinte,
                    JSON.parse(localStorage.getItem("valueTabPanel"))
                );
            }, 500);
        }
    }

    private popularFiltros(values) {
        this.valueContribuinte = values.valueContribuinte;
        this.contaValue = values.contaValue;
        this.estadoValue = values.estadoValue;
        this.situacaoCreditoValue = values.situacaoCreditoValue;
        this.saldoValue = values.saldoValue;
        this.situacaoCobrancaValue = values.situacaoCobrancaValue;
        this.dataInicialReferenciaValue = new Date(values.dataInicialReferenciaValue);
        this.dataFinalReferenciaValue = new Date(values.dataFinalReferenciaValue);
        this.contribuinteValue = values.contribuinteValue;
        this.tipoNumeroDocumentoValue = values.tipoNumeroDocumentoValue;
        this.numeroDocumentoValue = values.numeroDocumentoValue;
        this.renavamValue =values.renavamValue;

        console.log({ a: this.dataInicialReferenciaValue, b: this.dataFinalReferenciaValue})
    }

    /**acao pesquisar contas */
    filtroConta() {
        this.gerarFiltros();
        /**faz consulta */
        this.getContaViewConta(this.objParamsFiltro);
    }

    private gerarFiltros() {
        /**Reset check box */
        if (!this.exportandoDocumentos) {
            this.selectedProducts = [];
        }

        const razaoFiltroPrincipal = localStorage.getItem('razaoFiltroPrincipal')
            .replace('\"', "")
            .replace('\"', "");
        const valueFiltroPrincipal = localStorage.getItem('valueFiltroPrincipal');
        const labelFiltroPrincipal = localStorage.getItem('labelFiltroPrincipal');

        this.objParamsFiltro = {
            cpfCnpjResponsavel: this.valueContribuinte.baseCnpj,
            codigoCadastro: this.getCodigoCadastro(),
            configContaId: this.contaValue,
            estadoContaId: this.estadoValue,
            situacaoCreditoId: this.situacaoCreditoValue,
            saldoContaId: this.saldoValue,
            situacaoCobrancaId: this.situacaoCobrancaValue,
            periodoReferenciaInicial: this.dataInicialReferenciaValue
                ? this.utils.convertToSaveAnoMes(
                    this.dataInicialReferenciaValue
                )
                : null,
            periodoReferenciaFinal: this.dataFinalReferenciaValue
                ? this.utils.convertToSaveAnoMes(this.dataFinalReferenciaValue)
                : null,
            tipoNumeroDocumentoId: this.tipoNumeroDocumentoValue,
            numeroDocumento: this.numeroDocumentoValue,
            tributoId: null,
            codTributo: this.tipoTributo ? this.tipoTributo.codTributo : null,
            razaoFiltroPrincipal: razaoFiltroPrincipal ? razaoFiltroPrincipal : null,
            valueFiltroPrincipal: valueFiltroPrincipal ? valueFiltroPrincipal : null,
            labelFiltroPrincipal: labelFiltroPrincipal ? labelFiltroPrincipal : null,
            imprimirDetalhes: this.imprimirDetalhes,
            listVwContaCorrenteRequest: (this.selectedProducts && this.selectedProducts.length > 0) ? this.selectedProducts : null
        };

        if(this.contribuinteValue){
            var dadosIeRelacaoProf = this.relacaoProfissionalIeList.find((c: any) => c.ieContribuinte === this.contribuinteValue[0]);
            var tipoCadastroId = dadosIeRelacaoProf ? dadosIeRelacaoProf?.tipoCadastroId : 1;// 1 = inscricao estadual
            this.objParamsFiltro.tipoCadastroId = tipoCadastroId;
        }
        this.objParamsFiltro =
            this.removeParamsUtil.removeParametroNaoUtilizado(
                this.objParamsFiltro
            );

        const backup = {
            abaSelecionada: this.abaSelecionada,
            indexTabAtiva: this.indexTabAtiva,
            tributoId: this.tributoId,
            pagination: this.pagination,
            paginationSize: this.pagination.size,
            paginationRenavam: this.paginationRenavam,
            objParams: {
                contribuinteValue: this.contribuinteValue,
                valueContribuinte: this.valueContribuinte,
                renavamValue: this.renavamValue,
                contaValue: this.contaValue,
                estadoValue: this.estadoValue,
                situacaoCreditoValue: this.situacaoCreditoValue,
                saldoValue: this.saldoValue,
                situacaoCobrancaValue: this.situacaoCobrancaValue,
                dataInicialReferenciaValue: this.dataInicialReferenciaValue,
                dataFinalReferenciaValue: this.dataFinalReferenciaValue,
                tipoNumeroDocumentoValue: this.tipoNumeroDocumentoValue,
                numeroDocumentoValue: this.numeroDocumentoValue,
                tipoTributo: this.tipoTributo,
            },
        };
        //salvando parametros usados na consulta para qnd navegar no detalhe e voltar novamente

        localStorage.setItem("filtrosCCAnterior", JSON.stringify(backup));
    }

    getCodigoCadastro() {
        switch (this.abaSelecionada) {
            case "IPVA":
                return this.renavamValue ? [this.renavamValue] : [];
            case "ICMS":
                return this.contribuinteValue ? [this.contribuinteValue] : [];
            case "PARCELAMENTO":
            case "HONORARIOS":
            case "OUTROS":
                let strings = [];

                if (this.renavamValue && this.renavamValue !== '') {
                    strings.push(this.renavamValue);
                }

                if (this.contribuinteValue && this.contribuinteValue !== '') {
                    strings.push(this.contribuinteValue);
                }

                return strings;
            default:
                return [];
        }
    }

    getRenavamPageable(contribuinte: any, valuePanel?: any) {
        // this.blockedDocument = true;
        let objParamsContribuinte: any;

        if ((objParamsContribuinte = valuePanel === "IPVA")) {
            /**Lista renavam */
            const objParamsIpva = {
                cpfCnpjRenavam: contribuinte.baseCnpj,
            };
            /**verificar necessidade do tamanho */
            this.paginationRenavam.size = 20;
            this.blockedDocument = true;
            this.consultaContaCorrenteService
                .getRenavamPageable(objParamsIpva, this.paginationRenavam)
                .subscribe(
                    (response: any) => {
                        this.paginationRenavam = response;
                        this.lazyRenavam(this.paginationRenavam.totalPages);

                        this.blockedDocument = false;
                    },
                    (error) => {
                        this.blockedDocument = false;
                        this.alertsUtil.handleError(error);
                    }
                );
        }
    }

    lazyRenavam(totalPages) {
        if (totalPages == 0) {
            return;
        }

        const objParams = {
            cpfCnpjRenavam: this.valueContribuinte.baseCnpj,
        };
        setTimeout(() => {
            for (let index = 0; index < totalPages; index++) {
                this.blockeBtnFiltro = true;
                this.paginationRenavam.pageable.pageNumber = index;
                this.consultaContaCorrenteService
                    .getRenavamPageable(objParams, this.paginationRenavam)
                    .subscribe(
                        (response: any) => {
                            this.blockedDocument = true;
                            this.paginationRenavam = response;
                            if (this.paginationRenavam.totalElements > 0) {
                                this.paginationRenavam.content.forEach(
                                    (element) => {
                                        this.renavamList.push({
                                            renavam: element.renavam,
                                            idRenavam: element.idRenavam,
                                        });
                                        if (
                                            this.paginationRenavam
                                                .totalElements ==
                                            this.renavamList.length
                                        ) {
                                            this.blockeBtnFiltro = false;
                                        }
                                    }
                                );
                            } else {
                                this.blockeBtnFiltro = false;
                            }
                            this.blockedDocument = false;
                        },
                        (error) => {
                            this.blockedDocument = false;
                            this.blockeBtnFiltro = false;
                            this.alertsUtil.handleError(error);
                        }
                    );
            }
        }, 1000);
    }

    validaQtdRenavam(evento: any) {
        if (this.renavamValue.length > 10) {
            this.alertsUtil
                .warning(`O limite de 10 itens foi atingido. Refaça a seleção.`)
                .then((result) => {
                    if (result.isConfirmed) {
                        this.renavamValue = undefined;
                    }
                });
        }
    }

    limiteCaractere(evento) {
        if (evento?.filter.length > 50) {
            this.alertsUtil.warning(
                `Foi informado um grande número de caracteres no filtro de pesquisa.`
            );
        }
    }

    getContribuinteInscricaoEstadual() {
        /**Lista inscrição estadual */
        const objParamsIcms = {
            cpfCnpj: this.valueContribuinte?.baseCnpj,
        };
        this.consultaContaCorrenteService
            .getContribuinte(objParamsIcms)
            .subscribe(
                (response: any) => {
                    this.contribuinteIeList = response?.content?.map(
                        (c) => c.ieDv
                    );
                },
                (error) => {
                    this.alertsUtil.handleError(error);
                }
            );

        this.consultaContaCorrenteService
            .getRelacaoProfissional(this.valueContribuinte?.baseCnpj)
            .subscribe(
                (response: any) => {
                    this.relacaoProfissionalIeList = response;
                },
                (error) => {
                    this.alertsUtil.handleError(error);
                }
            );
    }

    get contribuinteRelacaoProfissionalList() {
        const mapIe = new Set();
        this.relacaoProfissionalIeList.forEach((rp) => mapIe.add(rp.ieContribuinte));
        this.contribuinteIeList.forEach((c) => mapIe.add(c));
        return Array.from(mapIe).map((v) => ({ label: v, value: v }));
    }

    onPageChangeIpva(e) {
        this.pagination.size = e.rows;
        this.pagination.pageable.pageNumber = e.page;
        this.getContaViewConta(this.objParamsFiltro);
    }

    onPageChange(e) {
        this.pagination.size = e.rows;
        this.pagination.pageable.pageNumber = e.page;
        this.getContaViewConta(this.objParamsFiltro);
    }

    checkIeClear(event){
        if(!event.value || event.value.length == 0){
            this.contribuinteValue = null;
        }
    }

    getContaViewConta(objParams) {
        this.blockedDocument = true;
        const tabValue = this.abaSelecionada.toLowerCase();

        this.consultaContaCorrenteService
            .getContaViewConta(objParams, this.pagination, tabValue)
            .subscribe(
                (response: any) => {
                    this.pagination = response;
                    this.listContasIpvaItcdIcms = response.content;

                    this.pesquisaRealizada = this.listContasIpvaItcdIcms.length > 0;

                    if (!this.pesquisaRealizada) {
                        let complemento = 'o CPF/CNPJ consultado.';

                        if (this.exibirFiltroRenavan() && this.renavamValue && this.renavamValue.length !== '') {
                            complemento = 'o Renavam consultado.';
                        }

                        if (this.exibirFiltroIe() && this.contribuinteValue && this.contribuinteValue !== '') {
                            complemento = 'a Inscrição Estadual consultada.';
                        }

                        this.alertsUtil.warning(
                            this.msgNenhumRegistroEncontrado + complemento
                        );
                    }
                    this.blockedDocument = false;
                },
                (error) => {
                    setTimeout(() => {
                        this.blockedDocument = false;
                    }, 500);
                    this.alertsUtil.handleError(error);
                }
            );
    }

    irSelecaoContribuinte() {
        this.outEtapaSelecaoConta.emit(0);
    }

    irDetalhamentoConta(conta) {
        localStorage.setItem("conta", JSON.stringify(conta));
        this.outEtapaSelecaoConta.emit(2);
    }

    converteValue(ev) {
        let ano = ev.toString().substring(0, 4);
        let mes = ev.toString().substring(4, 6);
        return `${mes}/${ano}`;
    }

    handleChange(ev) {
        this.limparFiltros();
        this.tipoConta = ev?.index;

        /**reset objParams */
        this.objParamsFiltro = null;
        switch (this.tipoConta) {
            case 0:
                localStorage.setItem("valueTabPanel", JSON.stringify("IPVA"));
                this.listFiltroContaIpvaItcdIcms("IPVA");

                break;
            case 1:
                localStorage.setItem("valueTabPanel", JSON.stringify("ITCD"));
                // this.listFiltroContaItcdOrIcms('ITCD');
                this.listFiltroContaIpvaItcdIcms("ITCD");
                break;
            case 2:
                localStorage.setItem("valueTabPanel", JSON.stringify("ICMS"));
                // this.listFiltroContaItcdOrIcms('ICMS');
                this.listFiltroContaIpvaItcdIcms("ICMS");
                this.getContribuinteInscricaoEstadual();
                break;
            case 3:
                localStorage.setItem(
                    "valueTabPanel",
                    JSON.stringify("PARCELAMENTO")
                );
                this.listFiltroContaIpvaItcdIcms("PARCELAMENTO");
                this.getContribuinteInscricaoEstadual();
                break;
            case 4:
                localStorage.setItem(
                    "valueTabPanel",
                    JSON.stringify("HONORARIOS")
                );
                this.listFiltroContaIpvaItcdIcms("HONORARIOS");
                this.getContribuinteInscricaoEstadual();
                break;
            case 5:
                localStorage.setItem("valueTabPanel", JSON.stringify("OUTROS"));
                this.listFiltroContaIpvaItcdIcms("OUTROS");
                this.getContribuinteInscricaoEstadual();
                break;
            default:
                break;
        }
        this.abaSelecionada = JSON.parse(localStorage.getItem("valueTabPanel"));
        this.tributoId = this.verificaTributoId();
        // this.getContribuinteByRenavam();
    }

    verificaTributoId() {
        let tributoId: any;
        if (this.abaSelecionada == "IPVA") {
            tributoId = "4";
        } else if (this.abaSelecionada == "ICMS") {
            tributoId = "2";
        } else if (this.abaSelecionada == "ITCD") {
            tributoId = "1";
        } else {
            return null;
        }
        return tributoId;
    }

    limparFiltros() {

        this.pesquisaRealizada = false;
        if(!this.deveLimparFiltros){
            // nao deve limpar filtros quando volta do detalhe e o filtrosCCAnterior for verificado
            this.deveLimparFiltros = true;
            return;
        }
        this.pagination = new Pagination<any>();
        this.pagination.size = 20;
        (this.renavamValue = undefined),
            (this.contaValue = null),
            (this.estadoValue = null),
            (this.situacaoCreditoValue = null),
            (this.saldoValue = null),
            (this.situacaoCobrancaValue = null),
            (this.dataInicialReferenciaValue = null),
            (this.dataFinalReferenciaValue = null),
            (this.numeroDocumentoValue = null),
            (this.tributoId = null),
            (this.listContasIpvaItcdIcms = []),
            (this.tipoNumeroDocumentoValue = null),
            (this.contribuinteValue = null),
            (this.selectedProducts = []);

        this.ativarInativarCampos();
    }

    /**Valores lista Dropdown Filtros */
    async getDropdownFiltro() {
        const paramsEstadoConta = {
            situacao: "ATIVA",
        };
        const paramsContaCorrente = {
            siglaTributo: this.abaSelecionada,
        };
        this.estadoContaList = await this.consultaContaCorrenteService
            .getEstadoConta(paramsEstadoConta)
            .toPromise();
        this.situacaoCreditoList = await this.consultaContaCorrenteService
            .getSituacaoCredito()
            .toPromise();
        this.saldoList = await this.consultaContaCorrenteService
            .getSaldoConta()
            .toPromise();
        this.situaCobrancaList = await this.consultaContaCorrenteService
            .getSituacaoCobranca()
            .toPromise();
        this.tipoDocumentoList = await this.consultaContaCorrenteService
            .getTipoNumeroDocumento()
            .toPromise();
        /**filtro conta */
        this.listFiltroContaIpvaItcdIcms("IPVA");

        return new Promise((resolve) => {
            resolve("");
        });
    }

    openModal(dadosContribuinte, contaCorrenteContribuinte) {
        this.modalExtratoConta.showDialog(
            dadosContribuinte,
            contaCorrenteContribuinte
        );
    }

    simularHistoricoLancamento() {
        this.modalSimularHistorico.showDialog(this.valueContribuinte);
    }

    insereMascara(cpfOuCnpj) {
        return this.maskCpfOuCnpj.inserirMascara(cpfOuCnpj);
    }

    converteMesAno(data) {
        let ano = data?.toString().substring(0, 4);
        let mes = data?.toString().substring(4, 6);
        return `${mes}/${ano}`;
    }

    /** Inicio Filtros Conta IPVA, ITCD e ICMS */
    listFiltroContaIpvaItcdIcms(abaSelecionada) {
        let siglaTributo = abaSelecionada;
        this.consultaContaCorrenteService
            .listFiltroContaIpvaItcdIcms(siglaTributo)
            .subscribe(
                (response: any) => {
                    this.blockedDocument = false;
                    this.contaList = response;
                },
                (error) => {
                    this.blockedDocument = false;
                    this.alertsUtil.handleError(error);
                }
            );
    }

    listFiltroContaItcdOrIcms(valuePanel) {
        let objParams = {};
        let tipoConta = valuePanel === "ITCD" ? "itcd" : "icms";
        this.blockedDocument = true;
        objParams = {
            // siglaTributo: valuePanel,
            cpfCnpj: this.valueContribuinte.cpfcnpj,
        };
        this.consultaContaCorrenteService
            .listFiltroContaItcdOrIcms(objParams, tipoConta, this.pagination)
            .subscribe(
                (response) => {
                    this.blockedDocument = false;
                    this.contaList = response.content;
                },
                (error) => {
                    this.blockedDocument = false;
                    this.alertsUtil.handleError(error);
                }
            );
    }

    tipoDocumentoChange() {
        if (this.tipoNumeroDocumentoValue == null) {
            /**reset campo Nº documento */
            this.numeroDocumentoValue = null;
        }
    }

    expandeEnvento(evento) {
        /**Index para o componente tabela juros */
        this.rowIndexConta = evento;
    }

    /**Verifica se se a conta que originou a ação de emissão de DARE possui conta de honorários vinculada a ela */
    getContas(conta) {
        /**há honorários vinculados  */
        this.blockedDocument = true;
        this.consultaContaCorrenteService
            .getContaVinculadaDare(conta.contaCorrenteId)
            .subscribe(
                (response: any) => {
                    this.blockedDocument = false;
                    if (response.length > 0) {
                        this.contaDareVinculadaList = response;
                        this.prepareIdsContas(
                            conta,
                            this.contaDareVinculadaList
                        );
                        if (this.objParamsConta.idsContas.length > 1) {
                            this.openModalAlertaHonorario();
                        } else {
                            this.postEmitirDare();
                        }
                    } else {
                        this.alertsUtil.warning(
                            "A conta não possui honorários vinculados"
                        );
                    }
                },
                (error) => {
                    this.alertsUtil.handleError(error);
                    this.blockedDocument = false;
                }
            );
    }

    openModalAlertaHonorario() {
        this.modalAlertaPossuiHonorario.showDialog();
    }

    prepareIdsContas(conta, contaDareVinculadaList) {
        let idsContas: any[] = [];
        contaDareVinculadaList.forEach((element) => {
            idsContas.push(element.contaCorrenteId);
        });

        this.objParamsConta = {
            idsContas: idsContas,
            dataVencimento: conta.dataVencimento,
            informacoesComplementares: null,
        };
    }

    postEmitirDare() {
        this.blockedDocument = true;
        this.arrecadaCaoDareService
            .postArrecadacaoDare(this.objParamsConta)
            .subscribe(
                (data) => {
                    this.blockedDocument = false;
                    this.alertsUtil.saveSuccess(`Dare emitido com sucesso.`);
                    setTimeout(() => {
                        this.emitePdfDare(data.base64);
                    }, 600);
                },
                (e) => {
                    this.blockedDocument = false;
                    this.alertsUtil.handleError(e);
                }
            );
        this.modalAlertaPossuiHonorario.hiddenDialog();
    }

    exportarExcel() {
        this.exportarRelatorio(TipoRelatorio.XLS);
    }

    exportarPdf() {
        this.exportarRelatorio(TipoRelatorio.PDF);
    }

    exportarRelatorio(tipoRelatorio: TipoRelatorio) {
        this.alertsUtil.confirmClose('Você deseja gerar o relatório detalhado?').then((result) => {
            if (result.isConfirmed) {
                const isConfirmed = true;
                this.executarExportacao(tipoRelatorio, isConfirmed);
            } else if (result.dismiss === swal.DismissReason.cancel || result.dismiss === swal.DismissReason.close) {
                const isConfirmed = false;
                this.executarExportacao(tipoRelatorio, isConfirmed);
            }
        });
    }

    executarExportacao(tipoRelatorio: TipoRelatorio, isConfirmed: boolean) {
        this.imprimirDetalhes = isConfirmed;
        this.exportandoDocumentos = true;

        this.gerarFiltros();

        const tabValue = this.abaSelecionada.toLowerCase();
        const paramsFiltro = this.objParamsFiltro;

        let successMessage = tipoRelatorio + ' emitido com sucesso!';
        let serviceMethod;
        let baixarArquivoMethod;

        if (tipoRelatorio === TipoRelatorio.XLS) {
            serviceMethod = this.consultaContaCorrenteService.getXls;
            baixarArquivoMethod = this.utilService.baixarXlsFromBase64;
        } else if (tipoRelatorio === TipoRelatorio.PDF) {
            serviceMethod = this.consultaContaCorrenteService.getPdf;
            baixarArquivoMethod = this.utilService.baixarPdfFromBase64;
        }

        serviceMethod.call(this.consultaContaCorrenteService, paramsFiltro, tabValue).subscribe(
            (response: RelatorioConta) => {
                this.blockedDocument = false;
                this.alertsUtil.saveSuccess(successMessage);

                if (response.arquivo) {
                    setTimeout(() => {
                        baixarArquivoMethod.call(this.utilService, response.arquivo, tabValue);
                    }, 600);
                } else {
                    this.alertsUtil.warning(response.erro);
                }
            },
            (error) => {
                this.blockedDocument = false;
                this.alertsUtil.handleError(error);
            }
        );

        this.exportandoDocumentos = false;
    }

    desabilitarExports():boolean {
        return !this.pesquisaRealizada;
    }

    emitePdfDare(dataResponse) {
        this.utilService.geraPdfStringBase64(dataResponse);
    }

    isAbaSelecionadaIpva() {
        return this.abaSelecionada === 'IPVA';
    }

    isAbaSelecionadaIcms() {
        return this.abaSelecionada === 'ICMS';
    }

    isAbaSelecionadaParcelamento() {
        return this.abaSelecionada === 'PARCELAMENTO';
    }

    isAbaSelecionadaHonorarios() {
        return this.abaSelecionada === 'HONORARIOS';
    }

    isAbaSelecionadaOutros() {
        return this.abaSelecionada === 'OUTROS';
    }

    isAbaSelecionadaOutrosParcelamentoHonorario() {
        return this.isAbaSelecionadaHonorarios() || this.isAbaSelecionadaOutros() || this.isAbaSelecionadaParcelamento();
    }

    exibirFiltroRenavan(): boolean {
        return this.isAbaSelecionadaIpva() || (this.isAbaSelecionadaOutrosParcelamentoHonorario() && this.renavamList.length > 0);
    }

    exibirFiltroIe(): boolean {
        return this.isAbaSelecionadaIcms() || (this.isAbaSelecionadaOutrosParcelamentoHonorario() && this.contribuinteRelacaoProfissionalList.length > 0);
    }

    ativarInativarCampos() {
        if (!this.exibirFiltroRenavan() || !this.exibirFiltroIe())
            return;

        if (this.renavamValue) {
            this.contribuinteValue = null;
            this.isContribuinteDisabled = true;
        } else if (this.contribuinteValue) {
            this.renavamValue = null;
            this.isRenavamDisabled = true;
        } else {
            this.isContribuinteDisabled = false;
            this.isRenavamDisabled = false;
        }
    }

}
