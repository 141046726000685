import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PerfilService {

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(
        private http: HttpClient
    ) { }

    public getAcoesByUsuarioSSO(idsso: string): Promise<string[]> {
        const params = new URLSearchParams();
        params.set('idsso' , idsso);
        return this.http.get<string[]>(`${environment.baseUrl}/perfil/list-roles-by-usario-sso?${params.toString()}`).pipe().toPromise();
    }

}
