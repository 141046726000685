import { Component, OnInit } from '@angular/core';
import { MenuItem, MessageService, PrimeNGConfig } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    providers: [MessageService],
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

    menuMode = 'static';

    theme = 'absolution';

    inputStyle = 'outlined';

    ripple: boolean;


    constructor(private primengConfig: PrimeNGConfig, public messageService: MessageService) {
    }

    ngOnInit() {
        this.primengConfig.setTranslation({
            dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
            dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
            dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
            monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
              'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
            monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
            today: 'Hoje',
            clear: 'Limpar',
            startsWith: 'Começa com',
            accept: 'Aceitar',
            reject: 'Cancelar',
            contains: 'Contém',
            notContains: 'Não Contém',
            endsWith: 'Termina com',
            equals: 'Igual a',
            notEquals: 'Diferente de',
            noFilter: 'Sem filtros',
            dateIs: 'Igual a data',
            dateIsNot: 'Diferente da data',
            dateBefore: 'Antes da data',
            dateAfter: 'Após a data',
            apply: 'Pesquisar',
            addRule: 'Adicionar Regra',
            removeRule: 'Remover Regra',
            matchAll: 'Corresponder a todos',
            matchAny: 'Corresponder a qualquer',
            emptyMessage: 'Resultado não encontrado',
            emptyFilterMessage: 'Resultado não encontrado',
            dateFormat: 'dd/mm/yy'
        });
        this.primengConfig.ripple = true;
        this.ripple = true;
    }
}
