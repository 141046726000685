import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { KeycloakService } from "keycloak-angular";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import swal from "sweetalert2";
@Injectable()
export class ErrorInterceptors implements HttpInterceptor {
    constructor(
        private keyCloak: KeycloakService /*private service: AppTopbarService*/
    ) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (request.headers.has("X-Skip-Error-Interceptor")) {
            const headers = request.headers.delete("X-Skip-Error-Interceptor");
            return next.handle(request.clone({ headers }));
        }

        return next.handle(request).pipe(
            catchError((httpErr: HttpErrorResponse) => {
                if (httpErr.error instanceof Blob) {
                    httpErr.error.text().then((text) => {
                        const error = JSON.parse(text);
                        this.lancarModalErro(httpErr, error);
                    });
                } else {
                    this.lancarModalErro(httpErr, httpErr?.error);
                }
                return throwError(httpErr);
            })
        );
    }

    lancarModalErro(httpErr: HttpErrorResponse, error) {
        if ([422, 412].includes(httpErr.status)) {
            swal.fire("Aviso", error.message, "warning");
        } else if (httpErr.status === 400) {
            if (error?.errors) {
                let details = "";
                error.errors?.forEach((element: any) => {
                    details += `${element.message} <br>`;
                });
                swal.fire({
                    title: "Campos Obrigatórios",
                    icon: "warning",
                    html: details,
                });
            } else {
                swal.fire("Aviso", error?.message, "warning");
            }
        } else if (httpErr.status === 401) {
            swal.fire({
                title: "Sessão expirada!",
                text: "É necesário realizar uma nova autenticação.",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Fazer Login Novamente",
                showLoaderOnConfirm: true,
                preConfirm: (login) => {
                    this.keyCloak.logout();
                    //this.service.clearProfile();
                },
                allowOutsideClick: false,
            });
        } else if (httpErr.status === 500) {
            swal.fire("Falha", "Erro Interno de Servidor", "error");
        } else if (httpErr.status === 404) {
            swal.fire("Falha", error.message || "Não encontrado!", "error");
        } else if (httpErr.status === 417) {
            swal.fire("Falha", error?.message, "error");
        } else if (httpErr.status === 503) {
            console.log("ignora representante");
        } else if (httpErr.status === 0) {
            swal.fire({
                title: "Falha",
                text: "Falha de comunicação com o servidor.",
                icon: "error",
                showCancelButton: true,
                cancelButtonText: "OK",
                confirmButtonText: "Sair",
                showLoaderOnConfirm: true,
                preConfirm: (login) => {
                    this.keyCloak.logout();
                    //this.service.clearProfile();
                },
                allowOutsideClick: false,
            });
        } else if (httpErr.status === 503) {
            swal.fire({
                title: "Serviço Indiponível!",
                text: "Tente novamente mais tarde.",
                icon: "error",
                showCancelButton: true,
                cancelButtonText: "OK",
                confirmButtonText: "Sair",
                showLoaderOnConfirm: true,
                preConfirm: (login) => {
                    this.keyCloak.logout();
                    //this.service.clearProfile();
                },
                allowOutsideClick: false,
            });
        } else {
            swal.fire(error?.error, error?.message, "error");
        }
    }
}
