<p-card>
    <div class="acoesBusca">
        <h2
            *ngIf="
                contribuinteSelecionado.pessoa === 'JURIDICA' ||
                contribuinteSelecionado.pessoa === 'FISICA'
            "
        >
            {{ contribuinteSelecionado.nome }} -
            {{ contribuinteSelecionado.cpfcnpj }}
        </h2>
        <div class="grid grid-nogutter justify-content-end">
            <button
                (click)="voltar()"
                pButton
                type="button"
                label="Voltar"
                class="p-button-outlined p-mr-2"
            ></button>
            <button
                (click)="pesquisar()"
                pButton
                type="button"
                class="p-mr-1"
                label="Pesquisar"
                icon="pi pi-search"
                [disabled]="
                    !renavam || dataInicio == null || !selectedEstadoConta
                "
            ></button>
        </div>
    </div>
    <div class="appExtrato">
        <div class="filtroLaterral">
            <p-accordion [multiple]="true">
                <p-accordionTab header="Tipo Conta:" [selected]="true">
                    <div class="p-col-12 p-md-12">
                        <div style="margin-top: 10px" class="p-field-checkbox">
                            <div class="checkConta">
                                <div class="field-radiobutton">
                                    <p-radioButton
                                        name="city"
                                        value="TRIBUTARIA"
                                        [(ngModel)]="checkTributario"
                                        inputId="city1"
                                    ></p-radioButton>
                                    <label for="city1">Tributário</label>
                                </div>
                                <div class="field-radiobutton">
                                    <p-radioButton
                                        name="city"
                                        value="NAO_TRIBUTARIA"
                                        [(ngModel)]="checkTributario"
                                        inputId="city2"
                                    ></p-radioButton>
                                    <label for="city2">Não Tributário</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </p-accordionTab>
                <p-accordionTab header="Renavan:" [selected]="true">
                    <div class="p-col-12 p-md-12">
                        <input
                            required
                            name="renavam"
                            maxlength="11"
                            onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || (event.charCode === 44)"
                            [(ngModel)]="renavam"
                            type=""
                            pInputText
                        />
                        <small *ngIf="renavam === '1'" class="p-error"
                            >Campo obrigatório</small
                        >
                    </div>
                </p-accordionTab>
                <p-accordionTab header="Insc. Estadual:" [selected]="true">
                    <div class="inf-Filtros p-field p-col-12 p-md-3">
                        <label title="insc estadual">Insc. Estadual</label>
                        <input
                            onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || (event.charCode === 44)"
                            [(ngModel)]="inscEstadual"
                            maxlength="9"
                            type=""
                            pInputText
                        />
                    </div>
                </p-accordionTab>
                <p-accordionTab header="Tributo:" [selected]="true">
                    <div class="inf-Filtros p-field p-col-12 p-md-3">
                        <p-multiSelect
                            [options]="tributos"
                            [(ngModel)]="selectedTributo"
                            optionLabel="sigla"
                        >
                        </p-multiSelect>
                    </div>
                </p-accordionTab>
                <p-accordionTab header="Conta:" [selected]="true">
                    <div class="inf-Filtros p-field p-col-12 p-md-3">
                        <p-multiSelect
                            [options]="contas"
                            [(ngModel)]="selectedConta"
                            optionLabel="nomeResumido"
                        >
                        </p-multiSelect>
                    </div>
                </p-accordionTab>
                <p-accordionTab header="Período (MM/AAAA):" [selected]="true">
                    <div class="inf-Filtros p-field">
                        <label title="dataInicio">Periodo Inícial</label>
                        <p-calendar
                            (onSelect)="onSelectDataMenor($event)"
                            [(ngModel)]="dataInicio"
                            appendTo="body"
                            view="month"
                            dateFormat="mm/yy"
                            [showIcon]="true"
                        ></p-calendar>
                    </div>
                    <div class="inf-Filtros p-field">
                        <label title="dataInicio">Periodo Final</label>
                        <p-calendar
                            (onSelect)="onSelectDataMenor($event)"
                            [(ngModel)]="dataFim"
                            appendTo="body"
                            view="month"
                            dateFormat="mm/yy"
                            [showIcon]="true"
                            [disabled]="dataInicio == null"
                        >
                        </p-calendar>
                    </div>
                </p-accordionTab>
                <p-accordionTab header="Estado da Conta" [selected]="true">
                    <p-multiSelect
                        [options]="estadoConta"
                        [(ngModel)]="selectedEstadoConta"
                        optionLabel="descricao"
                        scrollHeight="250px"
                        defaultLabel="Selecione Estado da Conta"
                        display="chip"
                    ></p-multiSelect>
                </p-accordionTab>
            </p-accordion>
        </div>
        <p-progressBar *ngIf="!dadosCarregados" [value]="value"></p-progressBar>
        <p-card
            *ngIf="dadosCarregados"
            header="Extrato Conta Corrente"
            class="extratoConta"
        >
            <p-tabView (onChange)="handleChange($event)">
                <!-- TAB Pessoa Fisica -->
                <p-tabPanel
                    header="Pessoa Física"
                    [disabled]="!(contribuinteSelecionado.pessoa === 'FISICA')"
                    [selected]="contribuinteSelecionado.pessoa === 'FISICA'"
                >
                    <p-accordion [multiple]="true">
                        <p-accordionTab
                        header=" CPF: {{
                            contribuinteSelecionado.cpfcnpj
                        }} - Contribuinte: {{
                            contribuinteSelecionado.nome
                        }}"

                        >
                            <p-accordionTab header="Tributo: IPVA">
                                <p-accordion header="Conta: 6 - IPVA Auto">
                                    <p-accordionTab
                                        header="Contrato: 997 - ICMS Imposto"
                                    >
                                    </p-accordionTab>
                                    <p-accordionTab
                                        header="Contrato: 999 -ICMS Importação"
                                        [selected]="true"
                                    >
                                        <p-table
                                            styleClass="p-datatable-gridlines"
                                            [tableStyle]="{ width: '100%' }"
                                        >
                                            <ng-template pTemplate="header">
                                                <tr>
                                                    <th>Nº Docto</th>
                                                    <th>Ano</th>
                                                    <th>Parcela</th>
                                                    <th>Vencimento</th>
                                                    <th>Principal</th>
                                                    <th>Juros</th>
                                                    <th>Multa</th>
                                                    <th>Desconto</th>
                                                    <th>Total</th>
                                                    <th>Esado</th>
                                                    <th>Ação</th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body">
                                                <tr>
                                                    <td>
                                                        {{
                                                            contaCorrente.numeroDocumento
                                                        }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            contaCorrente.parcela
                                                        }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            contaCorrente.periodoReferencia
                                                        }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            getValorFinanceiro(
                                                                contaCorrente.contaCorrenteFinanceiros,
                                                                "Principal"
                                                            ) | currency : "R$ "
                                                        }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            getValorFinanceiro(
                                                                contaCorrente.contaCorrenteFinanceiros,
                                                                "Juros de Mora"
                                                            ) | currency : "R$ "
                                                        }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            getValorFinanceiro(
                                                                contaCorrente.contaCorrenteFinanceiros,
                                                                "Multa de Mora"
                                                            ) | currency : "R$ "
                                                        }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            contaCorrente?.desconto | currency : "R$ "
                                                        }}
                                                        {{
                                                            0 | currency : "R$ "
                                                        }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            contaCorrente.valorTotalLancado
                                                                | currency
                                                                    : "R$ "
                                                        }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            contaCorrente
                                                                .estadoConta
                                                                .descricao
                                                        }}
                                                    </td>
                                                    <td>
                                                        <p-button
                                                            [disabled]="true"
                                                            icon="pi pi-search"
                                                            class="mr"
                                                        ></p-button>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </p-accordionTab>
                                </p-accordion>
                            </p-accordionTab>
                        </p-accordionTab>
                    </p-accordion>
                </p-tabPanel>

                <!-- TAB Pessoa Juridica -->
                <p-tabPanel
                    header="Pessoa Juridica"
                    [disabled]="
                        !(contribuinteSelecionado.pessoa === 'JURIDICA')
                    "
                    [selected]="contribuinteSelecionado.pessoa === 'JURIDICA'"
                >
                    <p-accordion [multiple]="true">
                        <!-- for -->
                        <p-accordionTab
                            header="Inscrição Estadual: {{
                                contribuinteSelecionado.ie
                            }}: - CNPJ: {{
                                contribuinteSelecionado.cpfcnpj
                            }} - Razão Social: {{
                                contribuinteSelecionado.nome
                            }}"
                        >
                            <!-- for -->
                            <p-accordionTab header="Tributos">
                                <p-accordionTab
                                    *ngFor="let contaCorrente of dadosDebito"
                                    header="Contrato:{{
                                        contaCorrente.contaCorrenteId
                                    }} - ICMS: {{
                                        contaCorrente.configConta.nomeResumido
                                    }} - Valor Total: {{
                                        contaCorrente.valorTotalLancado
                                            | currency : 'R$ '
                                    }}"
                                >
                                    <table>
                                        <tr>
                                            <th>Nº Docto</th>
                                            <th>Parcela</th>
                                            <th>Período</th>
                                            <th>Principal</th>
                                            <th>Juros</th>
                                            <th>Multa</th>
                                            <th>Desconto</th>
                                            <th>Total</th>
                                            <th>Estado</th>
                                            <th>Ação</th>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{
                                                    contaCorrente.numeroDocumento
                                                }}
                                            </td>
                                            <td>{{ contaCorrente.parcela }}</td>
                                            <td>
                                                {{
                                                    contaCorrente.periodoReferencia
                                                }}
                                            </td>
                                            <td>
                                                {{
                                                    getValorFinanceiro(
                                                        contaCorrente.contaCorrenteFinanceiros,
                                                        "Principal"
                                                    ) | currency : "R$ "
                                                }}
                                            </td>
                                            <td>
                                                {{
                                                    getValorFinanceiro(
                                                        contaCorrente.contaCorrenteFinanceiros,
                                                        "Juros de Mora"
                                                    ) | currency : "R$ "
                                                }}
                                            </td>
                                            <td>
                                                {{
                                                    getValorFinanceiro(
                                                        contaCorrente.contaCorrenteFinanceiros,
                                                        "Multa de Mora"
                                                    ) | currency : "R$ "
                                                }}
                                            </td>
                                            <td>
                                                {{
                                                    contaCorrente?.desconto | currency : "R$ "
                                                }}
                                                {{
                                                    0 | currency : "R$ "
                                                }}
                                            </td>
                                            <td>
                                                {{
                                                    contaCorrente.valorTotalLancado
                                                        | currency : "R$ "
                                                }}
                                            </td>
                                            <td>
                                                {{
                                                    contaCorrente.estadoConta
                                                        .descricao
                                                }}
                                            </td>
                                            <td>
                                                <p-button
                                                    [disabled]="true"
                                                    icon="pi pi-search"
                                                    class="mr"
                                                ></p-button>
                                            </td>
                                        </tr>
                                    </table>
                                </p-accordionTab>
                            </p-accordionTab>
                        </p-accordionTab>
                    </p-accordion>
                </p-tabPanel>

                <!-- TAB Veiculo -->
                <p-tabPanel
                    header="Veículo"
                    [disabled]="!contribuinteSelecionado.chassi"
                    [selected]="contribuinteSelecionado.chassi"
                >
                    <p-accordion [multiple]="true">
                        <!-- for -->
                        <p-accordionTab
                            header="Renavam: {{
                                contribuinteSelecionado.idRenavam
                            }}: - CNPJ: {{
                                contribuinteSelecionado.chassi
                            }} - Proprietário{{ contribuinteSelecionado.nome }}"
                        >
                            <!-- for -->
                            <p-accordionTab header="Tributos">
                                <p-accordionTab
                                    *ngFor="let contaCorrente of dadosDebito"
                                    header="Contrato:{{
                                        contaCorrente.contaCorrenteId
                                    }} - Imposto: {{
                                        contaCorrente.configConta.nomeResumido
                                    }} - Valor Total: {{
                                        contaCorrente.valorTotalLancado
                                            | currency : 'R$ '
                                    }}"
                                >
                                    <table>
                                        <tr>
                                            <th>Nº Docto</th>
                                            <th>Ano</th>
                                            <th>Parcela</th>
                                            <th>Vencimeno</th>
                                            <th>Principal</th>
                                            <th>Juros</th>
                                            <th>Multa</th>
                                            <th>Desconto</th>
                                            <th>Total</th>
                                            <th>Estado</th>
                                            <th>Ação</th>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{
                                                    contaCorrente.numeroDocumento
                                                }}
                                            </td>
                                            <td>
                                                {{
                                                    contaCorrente.periodoReferencia
                                                }}
                                            </td>
                                            <td>{{ contaCorrente.parcela }}</td>
                                            <td>
                                                {{
                                                    contaCorrente.dataVencimento
                                                }}
                                            </td>
                                            <td>
                                                {{
                                                    getValorFinanceiro(
                                                        contaCorrente.contaCorrenteFinanceiros,
                                                        "Principal"
                                                    ) | currency : "R$ "
                                                }}
                                            </td>
                                            <td>
                                                {{
                                                    getValorFinanceiro(
                                                        contaCorrente.contaCorrenteFinanceiros,
                                                        "Juros de Mora"
                                                    ) | currency : "R$ "
                                                }}
                                            </td>
                                            <td>
                                                {{
                                                    getValorFinanceiro(
                                                        contaCorrente.contaCorrenteFinanceiros,
                                                        "Multa de Mora"
                                                    ) | currency : "R$ "
                                                }}
                                            </td>
                                            <td>
                                                {{
                                                    contaCorrente?.desconto | currency : "R$ "
                                                }}
                                                {{
                                                    0 | currency : "R$ "
                                                }}
                                            </td>
                                            <td>
                                                {{
                                                    contaCorrente.valorTotalLancado
                                                        | currency : "R$ "
                                                }}
                                            </td>
                                            <td>
                                                {{
                                                    contaCorrente.estadoConta
                                                        .descricao
                                                }}
                                            </td>
                                            <td>
                                                <p-button
                                                    [disabled]="true"
                                                    icon="pi pi-search"
                                                    class="mr"
                                                ></p-button>
                                            </td>
                                        </tr>
                                    </table>
                                </p-accordionTab>
                            </p-accordionTab>
                        </p-accordionTab>
                    </p-accordion>
                    <!-- <ccp-visualiza-extrato-veiculo [listaVeiculos]="listaVeiculos"></ccp-visualiza-extrato-veiculo> -->
                </p-tabPanel>
            </p-tabView>

            <ng-template pTemplate="footer">
                <div class="grid grid-nogutter justify-content-end">
                    <button
                        (click)="voltar()"
                        pButton
                        type="button"
                        label="Voltar"
                        class="p-button-outlined p-mr-2"
                    ></button>
                </div>
            </ng-template>
        </p-card>
    </div>
</p-card>
