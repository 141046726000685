<p-card header="Gráfico de Eventos por Período">
    <div class="p-fluid p-formgrid p-grid">

        <div class="p-field p-col-12 p-md-3">
            <label title="dataInicio" style="white-space: nowrap"
            >Período de Referência Inicial *</label
            >
            <p-calendar
                view="month"
                dateFormat="mm/yy"
                [showIcon]="true"
                placeholder="Informe periodo inicial"
                [maxDate]="dataFinalReferenciaValue"
                [(ngModel)]="dataInicialReferenciaValue"
                (ngModelChange)="onDataInicialChange($event)"
                [readonlyInput]="true"
            ></p-calendar>
            <small *ngIf="campoemBranco" class="p-error">Campo obrigatório</small>
        </div>
        <div class="p-field p-col-12 p-md-3">
            <label style="white-space: nowrap"
            >Período de Referência Final *</label
            >
            <p-calendar
                view="month"
                appendTo="body"
                dateFormat="mm/yy"
                [showIcon]="true"
                placeholder="Informe o periodo final"
                [disabled]="!dataInicialReferenciaValue"
                [minDate]="dataInicialReferenciaValue"
                [maxDate]="maxDateFinal"
                [(ngModel)]="dataFinalReferenciaValue"
                [readonlyInput]="true"
            >
            </p-calendar>
            <small *ngIf="campoemBranco" class="p-error">Campo obrigatório</small>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label title="Transações">Transações</label>
            <p-multiSelect
                [options]="transacaoList"
                optionLabel="nomeResumido"
                optionValue="transacaoId"
                placeholder="Selecione"
                display="chip"
                resetFilterOnHide="true"
                showClear="true"
                [(ngModel)]="eventoPorPeriodoSearchRequest.transacaoId"
            ></p-multiSelect>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label title="situacao">Imposto</label>
            <p-multiSelect
                [options]="impostoList"
                optionLabel="sigla"
                optionValue="tributoId"
                placeholder="Selecione"
                display="chip"
                resetFilterOnHide="true"
                showClear="true"
                [(ngModel)]="eventoPorPeriodoSearchRequest.impostoId"
            ></p-multiSelect>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label title="conta">Conta</label>
            <p-multiSelect
                [options]="contaList"
                optionLabel="nomeResumido"
                optionValue="configContaId"
                showClear="true"
                [(ngModel)]="eventoPorPeriodoSearchRequest.contaId"
            ></p-multiSelect>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label title="conta">Evento</label>
            <p-multiSelect
                [options]="eventoList"
                optionLabel="descricao"
                optionValue="eventosId"
                showClear="true"
                [(ngModel)]="eventoPorPeriodoSearchRequest.eventoId"
            ></p-multiSelect>
        </div>

    </div>
    <div
        style="
            margin-top: 10px;
            margin-bottom: 10px;
            text-align: right !important;
        "
    >
        <button
            title="Limpar pesquisa"
            pButton
            type="button"
            icon="pi pi-trash"
            label="Limpar"
            (click)="clear()"
            class="p-mr-1 p-button-danger"
        ></button>
        <button
            pButton
            type="button"
            icon="pi pi-search"
            label="Pesquisar"
            (click)="pesquisar()"
            class="p-mr-1 p-text-left"
        ></button>
    </div>
    <div class="chart-container" >
        <h2 *ngIf="DadosemBranco">Não existe dados para essa consulta</h2>
        <canvas #meuCanvas></canvas>
    </div>
</p-card>
