import { EventoPagamentoCadastroComponent } from './../evento-pagamento-cadastro/evento-pagamento-cadastro.component';
import {Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {Store} from '@ngrx/store';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {UtilsService} from '../../../shared/utils.service';
import {EventoPagamentoParametroConfiguracaoModalService} from './evento-pagamento-parametro-configuracao-modal.service';
import * as moment from 'moment';
import swal from "sweetalert2";
import { Observable } from 'rxjs';
import { EventoPagamentoRegraIdentificacaoModalService } from '../evento-pagamento-regra-identificacao-modal/evento-pagamento-regra-identificacao-modal.service';
import { EventoPagamentoContaExcecaoModalService } from '../evento-pagamento-conta-excecao-modal/evento-pagamento-conta-excecao-modal.service';
import { EventoPagamentoEventoCompensacaoModalService } from '../evento-pagamento-evento-compensacao-modal/evento-pagamento-evento-compensacao-modal.service';
import { EventoPagamentoReceitas } from 'src/app/models/evento-pagamento-receitas';
import { Receita } from 'src/app/models/receita';
import { EventoPagtoReceitaContas } from 'src/app/models/evento-pagamento-receita-contas';
import { ConfigContaReceita } from 'src/app/models/config-conta-receita';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-evento-pagamento-parametro-configuracao-modal',
    templateUrl: './evento-pagamento-parametro-configuracao-modal.component.html',
    styleUrls: ['./evento-pagamento-parametro-configuracao-modal.component.scss'],
    //providers: [EventoPagamentoParametroConfiguracaoModalService,EventoPagamentoRegraIdentificacaoModalService,EventoPagamentoContaExcecaoModalService,EventoPagamentoEventoCompensacaoModalService]
})
export class EventoPagamentoParametroConfiguracaoModalComponent implements OnInit {

    @Input() configContaReceita: Receita;
    @Input() dataInicio:any;
    @Input() receitaLength:any;
    @Output() public pagamentoReceitaEditada = new EventEmitter<EventoPagamentoReceitas>();

    validaData = false;

    blockedDocument = false;
    textoHeader: string;
    contasReceita: Receita[];
    contaReceita: EventoPagtoReceitaContas;
    situacoes: any[];
    situacao: any;
    dateStart: Date;
    dateEnd: Date;
    checkboxDisabled = false;
    criarContaCorrente: string;
    checkboxTransbordoDisabled = false;
    criarTransbordo: string;
    dataInicioVigencia: string;
    dataFimVigencia: string;
    pagamentoReceita: EventoPagamentoReceitas;
    eventoPagtoCompensacoesLength: number;
    eventoPagtoContaExcecoesLength: number;
    eventoPagtoReceitaRegrasLength: number;

    constructor(
        private storeBaseLegalSearchModal: Store<{ baseLegalSearchModal: { displayMaximizable: boolean } }>,
        private http: HttpClient,
        public utilsService: UtilsService,
        public eventoPagamentoParametroConfiguracaoModalService: EventoPagamentoParametroConfiguracaoModalService,
        private eventoPagamentoRegraIdentificacaoModalService: EventoPagamentoRegraIdentificacaoModalService,
        private eventoPagamentoContaExcecaoModalService: EventoPagamentoContaExcecaoModalService,
        private eventoPagamentoEventoCompensacaoModalService: EventoPagamentoEventoCompensacaoModalService,
    ) {
    }

    ngOnInit(): void {
        this.pagamentoReceita = new EventoPagamentoReceitas();
        this.contaReceita = new EventoPagtoReceitaContas();
    }
    onShow(): void {
        this.textoHeader =  "Parâmetros de Configuração da Receita " +
            this.configContaReceita.codReceita + ' - ' + this.configContaReceita.nomeReceita;
        this.carregaContasAssociadas();
        this.carregaSituacoes();
        this.pagamentoReceita.criarConta = 'NAO';
        this.eventoPagtoCompensacoesLength = 0;
        this.eventoPagtoContaExcecoesLength = 0;
        this.eventoPagtoReceitaRegrasLength = 0;
        if (this.receitaLength === 0) {
            this.dateStart = this.eventoPagamentoParametroConfiguracaoModalService.dateStart
            this.validaData = true;
            this.dataInicioVigencia = moment(this.dateStart).format('YYYY-MM-DD');
        } else { this.validaData = false; }
    }

    carregaSituacoes(): void {
        this.getSituacoes().subscribe(s => {
            this.situacoes = s;
            this.situacao = s[1]['chave']

        });
    }
    getSituacoes(): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${environment.baseUrl}/enums/config-conta/situacao`);
    }
    novaExcecaoRegra(): void {
        if (this.pagamentoReceita.eventoPagtoContaExcecoes.length === 0) {
            this.eventoPagamentoContaExcecaoModalService.dateStart = this.dateStart;
        } else {
            // this.pagamentoReceita
            //     .eventoPagtoContaExcecoes[0]
            //     .dataFimVigencia = moment().format("YYYY-MM-DD");

            // this.eventoPagamentoContaExcecaoModalService.dateStart =
            //     moment(this.utilsService.addDays(this.pagamentoReceita.eventoPagtoContaExcecoes[0].dataFimVigencia, 1)).toDate()
        }
        this.eventoPagamentoContaExcecaoModalService.displayMaximizable = true;
        this.eventoPagamentoContaExcecaoModalService.eventoPagamentoReceita = this.eventoPagamentoParametroConfiguracaoModalService.eventoPagamentoReceita
    }
    novoCompesacaoAutomatico(): void {
        if (this.pagamentoReceita.eventoPagtoCompensacoes.length === 0) {
            this.eventoPagamentoEventoCompensacaoModalService.dateStart = this.dateStart;
        } else {
            // this.pagamentoReceita
            //     .eventoPagtoCompensacoes[0]
            //     .dataFimVigencia = moment().format("YYYY-MM-DD");
            //
            // this.eventoPagamentoEventoCompensacaoModalService.dateStart =
            //     moment(this.utilsService.addDays(this.pagamentoReceita.eventoPagtoCompensacoes[0].dataFimVigencia, 1)).toDate()
        }
        this.eventoPagamentoEventoCompensacaoModalService.displayMaximizable = true;
    }
    setOrdem(index): void {
        index += 1;
        return this.contaReceita.ordemPrioridade = index;
    }
    novaConta(): void {
        const selected: any = this.contaReceita.configContaReceita

        const duplicated = this.pagamentoReceita.eventoPagtoReceitaContas.some((r: any) => {
            return r.configContaReceita.configContaReceitaId ==  selected.configContaReceitaId
        })

        if (duplicated) {
            Swal.fire("Erro", "Conta já adicionada", "error")

        } else {
            const ctaRec = Object.assign({}, this.contaReceita);
            ctaRec.ordemPrioridade = this.contaReceita.ordemPrioridade + 1;
            this.pagamentoReceita.eventoPagtoReceitaContas.push(ctaRec);
        }
    }

    removeContaAssociada(pagamento): void {
        this.pagamentoReceita.eventoPagtoReceitaContas.splice(pagamento, 1);
    }
    carregaContasAssociadas(): void {
        this.getcarregaContasAssociadas().subscribe(s => {
            this.contasReceita = s['content'];
        });
    }
    getcarregaContasAssociadas(): Observable<Array<ConfigContaReceita>> {
        if(this.pagamentoReceita.eventoPagtoReceitaContas.some(rc => rc.configContaReceita.receita.codReceita !== this.configContaReceita.codReceita)) {
            this.pagamentoReceita = new EventoPagamentoReceitas();
        }
        return this.http.get<Array<ConfigContaReceita>>(`${environment.baseUrl}/config-conta-receita/search?codReceita=${this.configContaReceita.codReceita}&paged=false&situacao=ATIVA`);
    }
    confirmar() {
        this.blockedDocument = true;
        this.select();
    }
    novaRegraIdentificacao(): void {
        if (this.pagamentoReceita.eventoPagtoReceitaRegras.length === 0) {
            this.eventoPagamentoRegraIdentificacaoModalService.dateStart = this.dateStart;
        } else {
            // this.pagamentoReceita
            //     .eventoPagtoReceitaRegras[0]
            //     .dataFimVigencia = moment().format("YYYY-MM-DD");

            this.eventoPagamentoRegraIdentificacaoModalService.dateStart =
                moment(this.utilsService.addDays(this.pagamentoReceita.eventoPagtoReceitaRegras[0].dataFimVigencia, 1)).toDate()

        }
        this.eventoPagamentoRegraIdentificacaoModalService.displayMaximizable = true;
    }

    select() {
        this.pagamentoReceita.dataInicioVigencia = this.dataInicioVigencia;
        this.pagamentoReceita.dataFimVigencia = this.dataFimVigencia;
        this.pagamentoReceita.situacao = this.situacao;
        this.pagamentoReceita.codReceita = this.configContaReceita.codReceita;
        const pgtReceita = Object.assign({}, this.pagamentoReceita);
        this.pagamentoReceitaEditada.emit(pgtReceita);
        this.eventoPagamentoParametroConfiguracaoModalService.displayMaximizable = false;
        this.blockedDocument = false;
    }

    onHide() {}

    eventoCompensacaoSelecionado(eventoCompensacao): any {
        const duplicated = this.pagamentoReceita.eventoPagtoCompensacoes.some((r: any) => {
            return r.eventoCompensacao.eventoCompensacaoId == eventoCompensacao.eventoCompensacao.eventoCompensacaoId
        })

        if (duplicated) {
            return Swal.fire("Erro", "Evento já adicionadao", "error")
                .then()
        }

        if (this.pagamentoReceita.eventoPagtoCompensacoes.length > 0) {
            const dataInicioRegraAnterior = this.pagamentoReceita
                .eventoPagtoCompensacoes[0]
                .dataInicioVigencia

            const dataInicioRegraNova = eventoCompensacao.dataInicioVigencia

            if (this.utilsService.diffInDays(dataInicioRegraNova, dataInicioRegraAnterior) <= 0) {
                const x: any = this.pagamentoReceita.eventoPagtoCompensacoes[0]
                const descricaoRegra = x.eventoCompensacao.descricao
                return Swal.fire("Atenção", `Início da vigência informado deve ser maior que o início da vigência da regra anterior: ${descricaoRegra} - Início da Vigência ${this.utilsService.convertToDateBr(dataInicioRegraAnterior)}`, "warning")
            }

            this.pagamentoReceita
                .eventoPagtoCompensacoes[0]
                .dataFimVigencia = this.utilsService.subtractDays(dataInicioRegraNova, 1);

        }

        this.pagamentoReceita.eventoPagtoCompensacoes.push(eventoCompensacao);

        this.pagamentoReceita
            .eventoPagtoCompensacoes
            .sort((a, b) => {
                if (a.dataFimVigencia === null || a.dataFimVigencia === "") {
                    return -1;
                } else if (b.dataFimVigencia === null || b.dataFimVigencia === "") {
                    return 1;
                }
                return 0;
            })
        this.eventoPagtoCompensacoesLength = this.pagamentoReceita.eventoPagtoCompensacoes.length;
    }

    removePagtoCompensacoes(eventoPagtoReceitaRegras): Observable<any> {
        this.pagamentoReceita.eventoPagtoCompensacoes.splice(eventoPagtoReceitaRegras, 1);
        this.eventoPagtoCompensacoesLength = this.pagamentoReceita.eventoPagtoCompensacoes.length;
        if (this.eventoPagtoCompensacoesLength) {
            this.pagamentoReceita.eventoPagtoCompensacoes[0].dataFimVigencia = null;
        }
        return  new Observable((e) => {true});
    }

    eventoExcessaoSelecionado(evento): any {
        const duplicated = this.pagamentoReceita.eventoPagtoContaExcecoes.some((r: any, i) => {
            return r.configContaReceita.configContaReceitaId === evento.configContaReceita.configContaReceitaId
        })

        if (duplicated) {
            return Swal.fire("Erro", "Conta já adicionada", "error")
                .then()
        }

        if (this.pagamentoReceita.eventoPagtoContaExcecoes.length > 0) {
            const dataInicioRegraAnterior = this.pagamentoReceita
                .eventoPagtoContaExcecoes[0]
                .dataInicioVigencia

            const dataInicioRegraNova = evento.dataInicioVigencia

            if (this.utilsService.diffInDays(dataInicioRegraNova, dataInicioRegraAnterior) <= 0) {
                const x: any = this.pagamentoReceita.eventoPagtoContaExcecoes[0]
                const descricaoRegra = x.configContaReceita.receita.descricao
                return Swal.fire("Atenção", `Início da vigência informado deve ser maior que o início da vigência da regra anterior: ${descricaoRegra} - Início da Vigência ${this.utilsService.convertToDateBr(dataInicioRegraAnterior)}`, "warning")
            }

            this.pagamentoReceita
                .eventoPagtoContaExcecoes[0]
                .dataFimVigencia = this.utilsService.subtractDays(dataInicioRegraNova, 1);

        }

        this.pagamentoReceita.eventoPagtoContaExcecoes.push(evento);
        this.pagamentoReceita
            .eventoPagtoContaExcecoes
            .sort((a, b) => {
                if (a.dataFimVigencia === null || a.dataFimVigencia === "") {
                    return -1;
                } else if (b.dataFimVigencia === null || b.dataFimVigencia === "") {
                    return 1;
                }
                return 0;
            })

        this.eventoPagtoContaExcecoesLength = this.pagamentoReceita.eventoPagtoContaExcecoes.length;
    }

    removeExcessaoSelecionado(evento): Observable<any> {
        this.pagamentoReceita.eventoPagtoContaExcecoes.splice(evento, 1);
        this.eventoPagtoContaExcecoesLength = this.pagamentoReceita.eventoPagtoContaExcecoes.length;
        if (this.eventoPagtoContaExcecoesLength) {
            this.pagamentoReceita.eventoPagtoContaExcecoes[0].dataFimVigencia = null;
        }
        return  new Observable((e) => {true});
    }

    regraIdentificacaoSelecionada(evento): any {
        const duplicated = this.pagamentoReceita.eventoPagtoReceitaRegras.some((r: any) => {
            return r.regra.regraId == evento.regra.regraId
        })

        if (duplicated) {
            return Swal.fire("Erro", "Regra já adicionada", "error")
                .then()
        }

        if (this.pagamentoReceita.eventoPagtoReceitaRegras.length > 0) {
            const dataInicioRegraAnterior = this.pagamentoReceita
                .eventoPagtoReceitaRegras[0]
                .dataInicioVigencia

            const dataInicioRegraNova = evento.dataInicioVigencia

            if (this.utilsService.diffInDays(dataInicioRegraNova, dataInicioRegraAnterior) <= 0) {
                const x: any = this.pagamentoReceita.eventoPagtoReceitaRegras[0]
                const descricaoRegra = x.regra.descricao
                return Swal.fire("Atenção", `Início da vigência informado deve ser maior que o início da vigência da regra anterior: ${descricaoRegra} - Início da Vigência ${this.utilsService.convertToDateBr(dataInicioRegraAnterior)}`, "warning")
            }

            this.pagamentoReceita
                .eventoPagtoReceitaRegras[0]
                .dataFimVigencia = this.utilsService.subtractDays(dataInicioRegraNova, 1);

        }

        this.pagamentoReceita
            .eventoPagtoReceitaRegras
            .push(evento);

        this.pagamentoReceita
            .eventoPagtoReceitaRegras
            .sort((a, b) => {
                if (a.dataFimVigencia === null || a.dataFimVigencia === '') {
                    return -1;
                } else if (b.dataFimVigencia === null || b.dataFimVigencia === '') {
                    return 1;
                }
                return 0;
            });

        this.eventoPagtoReceitaRegrasLength = this.pagamentoReceita.eventoPagtoReceitaRegras.length;
    }

    removeIdentificacaoConta(listaIdentificacao: any): Observable<any> {
        this.pagamentoReceita.eventoPagtoReceitaRegras.splice(listaIdentificacao, 1);
        this.eventoPagtoReceitaRegrasLength = this.pagamentoReceita.eventoPagtoReceitaRegras.length;
        if (this.eventoPagtoReceitaRegrasLength) {
            this.pagamentoReceita.eventoPagtoReceitaRegras[0].dataFimVigencia = null;
        }
        return  new Observable((e) => {true});
    }

    onSelectDataInicioVigencia(event) {
        if (event) {
            this.dataInicioVigencia = moment(event).format('YYYY-MM-DD');
            this.pagamentoReceita.dataInicioVigencia = this.dataInicioVigencia;
        } else {
            this.dataInicioVigencia = '';
        }
        return this.pagamentoReceita.dataInicioVigencia;
    }

    onSelectDataFimVigencia(event) {
        if (event) {
            this.dataFimVigencia = moment(event).format('YYYY-MM-DD');
            this.pagamentoReceita.dataFimVigencia = this.dataFimVigencia;
        } else {
            this.dataFimVigencia = '';
        }
        return this.pagamentoReceita.dataFimVigencia;
    }
    setCriarTransbordo() {
        this.pagamentoReceita.haTransbordo = this.criarTransbordo;
    }
    setContaCorrente() {
        this.pagamentoReceita.criarConta = this.criarContaCorrente;
    }

    excluiIdentificacaoConta(eventoPagtoReceitaRegras: any) {
        swal.fire({
            text: `Deseja excluir a associação da identificação da conta: [${eventoPagtoReceitaRegras.regra.regraId} - ${eventoPagtoReceitaRegras.regra.descricao}] com o evento de pagamento?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
        }).then((result) => {
            if (result.value) {
                this.removeIdentificacaoConta(eventoPagtoReceitaRegras)
                    .subscribe(
                        (response: any) => {
                            swal.fire(
                                'Sucesso',
                                'Excluído com sucesso',
                                'success'
                            );
                        },
                    );
            } else {
                result.dismiss === swal.DismissReason.cancel;
            }
        });
    }
    excluiPagtoExcessoes(eventoPagtoContaExcecoes: any) {
        swal.fire({
            text: `Deseja excluir a associação da excessão: [${eventoPagtoContaExcecoes.configContaReceita.configContaReceitaId} - ${eventoPagtoContaExcecoes.configContaReceita.nomeCompleto}] com o evento de pagamento?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
        }).then((result) => {
            if (result.value) {
                this.removeExcessaoSelecionado(eventoPagtoContaExcecoes).subscribe(
                        (response: any) => {
                            swal.fire(
                                'Sucesso',
                                'Excluído com sucesso',
                                'success'
                            );
                        },
                    );
            } else {
                result.dismiss === swal.DismissReason.cancel;
            }
        });
    }
    excluiPagtoCompensacoes(eventoPagtoCompensacoes: any) {
        swal.fire({
            text: `Deseja excluir a associação da compensação: [${eventoPagtoCompensacoes.eventoCompensacao.eventoCompensacaoId} - ${eventoPagtoCompensacoes.eventoCompensacao.descricao}] com o evento de pagamento?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
        }).then((result) => {
            if (result.value) {
                this.removePagtoCompensacoes(eventoPagtoCompensacoes).subscribe(
                        (response: any) => {
                            swal.fire(
                                'Sucesso',
                                'Excluído com sucesso',
                                'success'
                            );
                        },
                    );
            } else {
                result.dismiss === swal.DismissReason.cancel;
            }
        });
    }

}
