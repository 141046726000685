import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class MaskCpfOuCnpj {

    inserirMascara(cpfOuCnpj: any) {
      if (cpfOuCnpj?.length == 11) {
          const maskCpf = cpfOuCnpj.replace(
              /(\d{3})(\d{3})(\d{3})(\d{2})/g,
              "$1.$2.$3-$4"
          );
          return maskCpf;
      } else if (cpfOuCnpj?.length == 14) {
          const maskCnpj = cpfOuCnpj.replace(
              /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
              "$1.$2.$3/$4-$5"
          );
          return maskCnpj;
      } else {
        return cpfOuCnpj;
      }
  }
}