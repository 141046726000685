import { RegraImputacao } from "./regra-imputacao";

export class EventoPagtoRgrImputacaos {
    eventoPagtoRgrImputacaoId: number
    dataInicioVigencia: string;
    dataFimVigencia: string;
    // regraImputacao: RegraImputacao[];
    regraImputacao: RegraImputacao;

    constructor(init?: Partial<EventoPagtoRgrImputacaos>) {
        if (init) {
            Object.assign(this, init);
        } else {
            this.eventoPagtoRgrImputacaoId = null;
            this.dataInicioVigencia = "";
            this.dataFimVigencia = "";
            // this.regraImputacao = [];
            this.regraImputacao = null;
        }
    }
}
