<p-card header="Modelos de Conta Corrente">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-2">
            <label>Código</label>
            <input
                type="text"
                pInputText
                [(ngModel)]="regraCriacaoSearchRequest.regraCriacaoContaId"
            />
        </div>
        <div class="p-field p-col-12 p-md-4">
            <label>Descrição Resumida</label>
            <input
                type="text"
                pInputText
                [(ngModel)]="regraCriacaoSearchRequest.descricaoResumida"
                (ngModelChange)="
                    regraCriacaoSearchRequest.descricaoResumida =
                        $event.toUpperCase()
                "
            />
        </div>

        <div class="p-field p-col-12 p-md-6">
            <label>Descrição Completa</label>
            <input
                type="text"
                pInputText
                [(ngModel)]="regraCriacaoSearchRequest.descricaoCompleta"
                (ngModelChange)="
                    regraCriacaoSearchRequest.descricaoCompleta =
                        $event.toUpperCase()
                "
            />
        </div>

        <div class="p-field p-col-12 p-md-6">
            <label title="situacao">Situação</label>
            <p-dropdown
                optionLabel="chave"
                optionValue="chave"
                showClear="true"
                [autoDisplayFirst]="false"
                [options]="situacaoes"
                [(ngModel)]="regraCriacaoSearchRequest.situacao"
            ></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-3">
            <label title="dataInicioVigencia">Data da Criação</label>
            <p-calendar
                dateFormat="dd/mm/yy"
                [showIcon]="true"
                [(ngModel)]="regraCriacaoSearchRequest.dataCriacao"
            ></p-calendar>
        </div>
    </div>
    <div
        style="
            margin-top: 10px;
            margin-bottom: 10px;
            text-align: right !important;
        "
    >
        <button
            title="Limpar pesquisa"
            pButton
            type="button"
            icon="pi pi-trash"
            label="Limpar"
            (click)="clear()"
            class="p-mr-1 p-button-danger"
        ></button>
        <button
            pButton
            type="button"
            icon="pi pi-search"
            label="Pesquisar"
            (click)="pesquisar('resetPage')"
            class="p-mr-1 p-text-left"
        ></button>
        <button
            pButton
            type="button"
            icon="pi pi-plus-circle"
            label="Novo Modelo"
            (click)="novaRegra()"
            class="p-mr-1 p-text-left"
            [disabled]="!podeCadastrarModelosContaCorrente()"
        ></button>
    </div>
    <p-table [value]="regrasCriacao">
        <ng-template pTemplate="header">
            <tr>
                <th>Código</th>
                <th>Descrição Resumida</th>
                <th>Descrição Completa</th>
                <th>Situação</th>
                <th>Data da Criação</th>
                <th>Quantidade Contas Associadas</th>
                <th>Ação</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-regra>
            <tr>
                <td>{{ regra.regraCriacaoContaId }}</td>
                <td>{{ regra.descricaoResumida }}</td>
                <td>{{ regra.descricaoCompleta }}</td>
                <td>{{ regra.situacao }}</td>
                <td>{{ convertDateBr(regra.dataCriacao) }}</td>
                <td>{{ regra.qtdContasAssociadas }}</td>
                <td>
                    <button
                        pButton
                        pRipple
                        title="Editar"
                        type="button"
                        icon="pi pi-pencil"
                        class="p-button-rounded"
                        (click)="onEditar(regra)"
                        [disabled]="!podeEditarModelosContaCorrente()"
                    ></button>
                    <button
                        *ngIf="regra.qtdContasAssociadas == 0"
                        pButton
                        pRipple
                        title="Excluir"
                        type="button"
                        icon="pi pi-trash"
                        class="p-button-rounded p-button-danger"
                        (click)="excluir(regra)"
                        style="margin-left: 3px"
                        [disabled]="!podeExcluirModelosContaCorrente()"
                    ></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <p-paginator
        #paginator
        [rows]="10"
        [totalRecords]="pagination.totalElements"
        (onPageChange)="onPageChange($event)"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{first} - {last} de {totalRecords}"
    ></p-paginator>
</p-card>
