import * as EventoPagamentoContaExcecaoModalActions from './evento-pagamento-conta-excecao-modal-actions';

const eventoPagamentoContaExcecaoModalInitialstate = {
    displayMaximizable: false
};

// tslint:disable-next-line:max-line-length
export function eventoPagamentoContaExcecaoModalReducer(state = eventoPagamentoContaExcecaoModalInitialstate, action: EventoPagamentoContaExcecaoModalActions.SetDisplayMaximizable) {
    switch (action.type) {
        case EventoPagamentoContaExcecaoModalActions.SET_DISPLAY_MAXIMIZABLE:
            return {
                ...state,
                displayMaximizable: action.value
            };
        default:
            return state;
    }
}
