<p-dialog header="Pesquisar Base Legal" (onHide)="onHide()" [(visible)]="baseLegalSearchModalService.displayMaximizable" [modal]="true" [style]="{width: '60vw'}" [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12">
            <label>Descrição da Base Legal</label>
            <input type="text" pInputText autocomplete="nope" [(ngModel)]="baseLegalSearchRequest.descricao">
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label title="dataInicioVigencia">Início da Vigência</label>
            <p-calendar
                    appendTo="body"
                    dateFormat="dd/mm/yy"
                    [showIcon]="true"
                    [(ngModel)]="baseLegalSearchRequest.dataInicioVigencia"
                    (ngModelChange)="onSelectDataInicioVigencia($event)"
            ></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label title="dataFimVigencia">Fim da Vigência</label>
            <p-calendar
                    dateFormat="dd/mm/yy"
                    appendTo="body"
                    [showIcon]="true"
                    [(ngModel)]="baseLegalSearchRequest.dataFimVigencia"
                    (ngModelChange)="onSelectDataFimVigencia($event)"
            ></p-calendar>
        </div>
    </div>

    <p-table [value]="baseLegalSearchResponses">
        <ng-template pTemplate="header">
            <tr>
                <th>Código</th>
                <th>Descrição da Base Legal</th>
                <th>Tipo</th>
                <th>Início da Vigência</th>
                <th>Fim da Vigência</th>
                <th>Ações</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-bl>
            <tr>
                <td>{{bl?.baseLegalId}}</td>
                <td>{{bl?.tblDescBaseLegal}}</td>
                <td>{{bl?.tblTipoBase}}</td>
                <td>{{utilsService.convertToDateBr(bl?.tblVigenciaInicio)}}</td>
                <td>{{utilsService.convertToDateBr(bl?.tblVigenciaFinal)}}</td>
                <td>
                    <button pButton pRipple title="Selecionar" type="button" icon="pi pi-check" class="p-button-rounded p-button-warning" (click)="select(bl)"></button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td class="p-text-center" colspan="6">
                    Nenhum registro localizado
                </td>
            </tr>
        </ng-template>
    </p-table>
    <p-paginator [rows]="10" [totalRecords]="pagination.totalElements" (onPageChange)="onPageChange($event)"></p-paginator>

    <ng-template pTemplate="footer">
        <p-button icon="pi pi-times" (click)="baseLegalSearchModalService.displayMaximizable=false" label="Fechar"></p-button>
        <p-button icon="pi pi-search" (click)="search()" label="Pesquisar"></p-button>
    </ng-template>
</p-dialog>
