import * as ConfigContaSearchModalActions from './config-conta-search-modal-actions';

const configContaSearchModalInitialstate = {
    displayMaximizable: false
};

export function configContaSearchModalReducer2(state = configContaSearchModalInitialstate, action: ConfigContaSearchModalActions.SetDisplayMaximizable) {
    switch (action.type) {
        case ConfigContaSearchModalActions.SET_DISPLAY_MAXIMIZABLE:
            return {
                ...state,
                displayMaximizable: action.value
            };
        default:
            return state;
    }
}
