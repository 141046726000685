import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import { environment } from 'src/environments/environment';
import {Pageable} from "../../../shared/pagination/pageable";
import {RelatorioConta} from "../../../models/relatorio-conta";

@Injectable({
    providedIn: 'root'
})
export class RelatorioContaCorrenteService {
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(
        private http: HttpClient,
    ) {}

    listar(params, page, size): Observable<any> {
        return this.http.get<Pageable<any>>(
            `${environment.baseUrl}/relatorio-conta-corrente/relatorio`,
            {
                params: {
                    ...params,
                    page: page,
                    size: size,
                },
            }
        );
    }

    getOpcoesFiltros(): Observable<any> {
        return this.http.get<Pageable<any>>(
            `${environment.baseUrl}/relatorio-conta-corrente/opcoes-filtros`);
    }

    getXls(params: any): Observable<RelatorioConta> {
        return this.http.post<RelatorioConta>(`${environment.baseUrl}/relatorio-conta/geral/xls`, params);
    }

    getPdf(params: any): Observable<RelatorioConta> {
        return this.http.post<RelatorioConta>(`${environment.baseUrl}/relatorio-conta/geral/pdf`, params);
    }

}
