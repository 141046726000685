export class ConfigContaReceitaSearchRequest {

    situacao: string;
    dataInicioVigencia: string;
    dataFimVigencia: string;
    configContaId: string;

    constructor() {
        this.situacao = '';
        this.dataInicioVigencia = '';
        this.dataFimVigencia = '';
        this.configContaId = '';
    }
}
