<p-card header="Configuração de Evento de Benefício Fiscal">
    <div style="text-align: right">
        <button
            pButton
            type="button"
            class="p-mr-1"
            label="Novo Benefício"
            icon="pi pi-plus"
            (click)="novoBeneficio()"
        ></button>
    </div>
</p-card>
<p-card>
    <p-table
        columnResizeMode="expand"
        responsiveLayout="scroll"
        [value]="eventoBeneficioResponses"
        [globalFilterFields]="[
            'eb.eventoBeneficioId',
            'eb.descricao',
            'eb.eventos.tipoEvento.descricaoItemMenu',
            'eb.tributo.sigla',
            'eb.implantado',
            'eb.situacao',
            'eb.dataInicioVigencia',
            'eb.dataFimVigencia'
        ]"
    >
        <ng-template pTemplate="header">
            <tr>
                <th
                    style="min-width: 200px"
                    pSortableColumn="eventoBeneficioId"
                >
                    Código <p-sortIcon field="eventoBeneficioId"></p-sortIcon>
                </th>
                <th style="min-width: 200px" pSortableColumn="descricao">
                    Descrição
                    <p-sortIcon field="descricao"></p-sortIcon>
                </th>
                <th
                    style="min-width: 200px"
                    pSortableColumn="eventos.tipoEvento.descricaoItemMenu"
                >
                    Evento
                    <p-sortIcon
                        field="eventos.descricao"
                    ></p-sortIcon>
                </th>
                <th style="min-width: 200px" pSortableColumn="tributo.sigla">
                    Tributo <p-sortIcon field="tributo.sigla"></p-sortIcon>
                </th>
                <th style="min-width: 200px" pSortableColumn="implantado">
                    Implantado?
                    <p-sortIcon field="implantado"></p-sortIcon>
                </th>
                <th style="min-width: 200px" pSortableColumn="Situação">
                    Situação
                    <p-sortIcon field="Situação"></p-sortIcon>
                </th>
                <th
                    style="min-width: 200px"
                    pSortableColumn="dataInicioVigencia"
                >
                    Início da Vigência
                    <p-sortIcon field="dataInicioVigencia"></p-sortIcon>
                </th>
                <th style="min-width: 200px" pSortableColumn="dataFimVigencia">
                    Fim da Vigência
                    <p-sortIcon field="dataFimVigencia"></p-sortIcon>
                </th>
                <th style="min-width: 100px">Ações</th>
            </tr>
            <tr>
                <th style="min-width: 200px">
                    <p-columnFilter
                        type="text"
                        field="eventoBeneficioId"
                        matchMode="equals"
                    ></p-columnFilter>
                </th>
                <th style="min-width: 200px">
                    <p-columnFilter
                        type="text"
                        field="descricao"
                        matchMode="equals"
                    ></p-columnFilter>
                </th>
                <th>
                    <div class="p-d-flex p-jc-between p-ai-center">
<!--                        Evento-->
                        <p-columnFilter field="eventos" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                            <ng-template pTemplate="header">
                                <div class="p-px-3 p-pt-3 p-pb-0">
                                    <span class="p-text-bold">Selecionar Evento</span>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-multiSelect [ngModel]="value" [options]="eventos" placeholder="Sem filtro" (onChange)="filter($event.value)" optionLabel="descricao">
                                    <ng-template let-option pTemplate="item">
                                        <div class="p-multiselect-representative-option">
                                            <span class="p-ml-1">{{option.descricao}}</span>
                                        </div>
                                    </ng-template>
                                </p-multiSelect>
                            </ng-template>
                        </p-columnFilter>
                    </div>
                </th>
<!--                <th style="min-width: 200px">-->
<!--                    <p-columnFilter type="text" field="tributo.sigla" matchMode="equals">-->
<!--                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">-->
<!--                            <p-dropdown [ngModel]="sigla" [options]="tributoEnum" (onChange)="filter($event.value.sigla)" placeholder="selecione" optionLabel="sigla">-->
<!--                                <ng-template let-tributoEnum pTemplate="item">-->
<!--                                    <span [class]="'customer-badge status-' +tributoEnum.sigla">{{ tributoEnum.sigla }}</span>-->
<!--                                </ng-template>-->
<!--                            </p-dropdown>-->
<!--                        </ng-template>-->
<!--                    </p-columnFilter>-->
<!--                </th>-->

                <th>
                    <div class="p-d-flex p-jc-between p-ai-center">
                        <!--Tributo-->
                        <p-columnFilter field="tributo" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                            <ng-template pTemplate="header">
                                <div class="p-px-3 p-pt-3 p-pb-0">
                                    <span class="p-text-bold">Selecionar Tributo</span>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-multiSelect [ngModel]="value" [options]="tributoEnum" placeholder="Sem filtro" (onChange)="filter($event.value)" optionLabel="sigla">
                                    <ng-template let-option pTemplate="item">
                                        <div class="p-multiselect-representative-option">
                                            <span class="p-ml-1">{{option.sigla}}</span>
                                        </div>
                                    </ng-template>
                                </p-multiSelect>
                            </ng-template>
                        </p-columnFilter>
                    </div>
                </th>

                <th>
                    <p-columnFilter field="implantado" matchMode="equals" [showMenu]="false">
                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                            <p-dropdown [ngModel]="value" [options]="simNaos" (onChange)="filter($event.value)" placeholder="Todos" [showClear]="true">
                                <ng-template let-option pTemplate="item">
                                    <p-badge [value]="option.label" size="small" [severity]="option.value === 'SIM' ? 'success' : 'danger'"></p-badge>
                                </ng-template>
                            </p-dropdown>
                        </ng-template>
                    </p-columnFilter>
                </th>

                <th>
                    <p-columnFilter field="situacao" matchMode="equals" [showMenu]="false">
                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                            <p-dropdown [ngModel]="value" [options]="situacoes" (onChange)="filter($event.value)" placeholder="Todos" [showClear]="true">
                                <ng-template let-option pTemplate="item">
                                    <p-badge [value]="option.label" size="small" [severity]="option.value === 'ATIVA' ? 'success' : 'danger'"></p-badge>
                                </ng-template>
                            </p-dropdown>
                        </ng-template>
                    </p-columnFilter>
                </th>

                <th>
                    <p-columnFilter type="date" field="dataInicioVigencia" display="menu">
                        <ng-template pTemplate="filter" let-filter="filterCallback">
                            <p-calendar dateFormat="dd/mm/yy" (onSelect)="filter($event)"></p-calendar>
                        </ng-template>
                    </p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="date" field="dataFimVigencia" display="menu">
                        <ng-template pTemplate="filter" let-filter="filterCallback">
                            <p-calendar dateFormat="dd/mm/yy" (onSelect)="filter($event)"></p-calendar>
                        </ng-template>
                    </p-columnFilter>
                </th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-eb>
            <tr>
                <td>{{ eb.eventoBeneficioId }}</td>
                <td>{{ eb.descricao }}</td>
                <td>{{ eb.eventos?.descricao }}</td>
                <td>{{ eb.tributo?.sigla }}</td>
                <td>{{ eb.implantado }}</td>
                <td>{{ eb.situacao }}</td>
                <td>{{ eb.dataInicioVigencia | date: "dd/MM/yyyy" }}</td>
                <td>{{ eb.dataFimVigencia | date: "dd/MM/yyyy" }}</td>
                <td>
                    <button
                        pButton
                        pRipple
                        title="Editar"
                        type="button"
                        icon="pi pi-pencil"
                        class="p-button-rounded p-button botao-editar"
                        (click)="novoBeneficioEditar(eb.eventoBeneficioId)"
                    ></button>
                    <button
                        *ngIf="eb.implantado === 'SIM'"
                        pButton
                        pRipple
                        title="{{
                            eb.situacao === 'ATIVA' ? 'Desativar' : 'Ativar'
                        }}"
                        type="button"
                        icon="{{
                            eb.situacao === 'ATIVA'
                                ? 'pi pi-times'
                                : 'pi pi-check'
                        }}"
                        class="p-button-rounded p-button-secondary"
                        (click)="activateDeactivate(eb)"
                        style="margin-left: 3px"
                    ></button>
                    <button
                        *ngIf="eb.implantado === 'NAO'"
                        pButton
                        pRipple
                        title="Excluir"
                        type="button"
                        icon="pi pi-trash"
                        class="p-button-rounded p-button-danger"
                        (click)="excluirBeneficio(eb)"
                        style="margin-left: 3px"
                    ></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <p-paginator
        [rows]="10"
        [totalRecords]="pagination.totalElements"
        (onPageChange)="onPageChange($event)"
    ></p-paginator>
</p-card>

