import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Pageable } from "../../../shared/pagination/pageable";
import { Pagination } from "../../../shared/pagination/pagination";

@Injectable({
    providedIn: "root",
})
export class BaseLegalService {
    constructor(private http: HttpClient) {}

    searchBaseLegal(
        objParams,
        pagination: Pagination<any>
    ): Observable<Pageable<any>> {
        return this.http.get<Pageable<any>>(
            `${environment.baseUrl}/base-legal/search`,
            {
                params: {
                    ...objParams,
                    page: pagination.pageable.pageNumber,
                    size: pagination.size,
                },
            }
        );
    }

    getBaseLegal(baseLegalId): Observable<any> {
        return this.http.get<any>(
            `${environment.baseUrl}/base-legal/baselegal/${baseLegalId}`
        );
    }
}
