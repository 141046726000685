import {ConfigConta} from '../models/config-conta';
import {createAction, createReducer, on, props} from '@ngrx/store';

export interface IConfigContaSearchModalState {
    configContas: ConfigConta[];
}

export const configContaSearchModalInitialState: IConfigContaSearchModalState = {
    configContas: []
};

export const setConfigContas = createAction('[ConfigContas] Seta configContas', props<{ cc: ConfigConta[] }>());

export const configContaSearchModalReducer = createReducer(
    configContaSearchModalInitialState,
    on(setConfigContas, (state, {cc}) => {
        state = {
            ...state,
            configContas: cc
        };
        return state;
    })
);
