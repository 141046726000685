<div class="card">
    <p-toast></p-toast>
    <p-steps [model]="items" [readonly]="true"></p-steps>
</div>

<div class="stepsdemo-content">
    <p-card>
        <ng-template pTemplate="title">
            Base Legal
        </ng-template>

        <ng-template pTemplate="header">
            <div class="grid grid-nogutter justify-content-end" [style]="{padding: '10px'}">
                <p-button label="Anterior" (onClick)="prevPage()" icon="pi pi-angle-left"></p-button>
                <p-button class="float-btn-next" label="Próxima" (onClick)="nextPage()" icon="pi pi-angle-right" iconPos="right"></p-button>
            </div>
        </ng-template>

        <ng-template pTemplate="content">
            <div class="p-field p-col-12">
                <app-identificacao-conta [configConta]="configConta"></app-identificacao-conta>
            </div>
            <div class="p-field p-col-12 p-md-12" style="text-align: left;">
                <button
                        [disabled]="!configConta.configContaId ||
                        configContaBaseLegalService.configContaBaseLegal.configContaBaseLegalId != null"
                        pButton
                        (click)="showBasicDialog()"
                        icon="pi pi-search"
                        label="Buscar Base Legal">
                </button>
            </div>
            <p-fieldset legend="Base Legal Selecionada">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-12">
                        <label>Descrição da Base Legal</label>
                        <input [(ngModel)]="configContaBaseLegalService.configContaBaseLegal.baseLegal.tblDescBaseLegal"
                                #tblDescBaseLegal="ngModel"
                               type="text" pInputText [disabled]="configConta.configContaId">
                    </div>

                    <div class="p-field p-col-12 p-md-12">
                        <label>Capitulação Legal</label>
                        <textarea
                            rows="7"
                            cols="30"
                            pInputTextarea
                            [(ngModel)]="configContaBaseLegalService.configContaBaseLegal.baseLegal.tblCapitulacionLegal"
                            [disabled]="configConta.configContaId"
                        ></textarea>
                    </div>

                    <div class="p-field p-col-6">
                        <label>Início da Vigência</label>
                        <p-calendar
                            dateFormat="dd/mm/yy"
                            [disabled]="configConta.configContaId"
                            [showIcon]="true"
                            [(ngModel)]="configContaBaseLegalService.configContaBaseLegal.baseLegal.tblVigenciaInicio"
                        ></p-calendar>
                    </div>
                    <div class="p-field p-col-6">
                        <label>Fim da Vigência</label>
                        <p-calendar
                            dateFormat="dd/mm/yy"
                            [disabled]="configConta.configContaId"
                            [showIcon]="true"
                            [(ngModel)]="configContaBaseLegalService.configContaBaseLegal.baseLegal.tblVigenciaFinal"
                        ></p-calendar>
                    </div>
                </div>
            </p-fieldset>
            <div class="p-fluid p-formgrid p-grid" style="margin-top: 10px;">
                <div class="p-field p-col-4">
                    <label>Situação *</label>
                    <p-dropdown
                            [disabled]="!configConta?.configContaId || configConta.implantado === 'NAO' || !configContaBaseLegalService.configContaBaseLegal.configContaBaseLegalId"
                            required
                            #situacao="ngModel"
                            optionLabel="valor"
                            optionValue="chave"
                            inputId="dropdown"
                            showClear="true"
                            [autoDisplayFirst]="false"
                            [options]="situacaoCCBaseLegals"
                            [(ngModel)]="configContaBaseLegalService.configContaBaseLegal.situacao"
                    >
                    </p-dropdown>
                    <small *ngIf="(situacao.invalid && submitted)" class="p-error">Campo obrigatório</small>
                </div>
                <div class="p-field p-col-4">
                    <label>Início da Vigência *</label>
                    <p-calendar
                        [required]="submitted"
                        [disabled]="configConta.implantado === 'SIM' && configContaBaseLegalService.configContaBaseLegal.configContaBaseLegalId != null"
                        #dataInicioVigencia="ngModel"
                        dateFormat="dd/mm/yy"
                        [showIcon]="true"
                        [(ngModel)]="configContaBaseLegalService.configContaBaseLegal.dataInicioVigencia"
                        (onSelect)="onSelectDataInicioVigencia($event)"
                        (change)="convertDateBr($event)"
                    ></p-calendar>
                    <small *ngIf="(dataInicioVigencia.invalid && submitted)" class="p-error">Campo obrigatório</small>
                </div>
                <div class="p-field p-col-4">
                    <label>Fim da Vigência</label>
                    <p-calendar
                        dateFormat="dd/mm/yy"
                        [showIcon]="true"
                        [(ngModel)]="configContaBaseLegalService.configContaBaseLegal.dataFimVigencia"
                        (onSelect)="onSelectDataFimVigencia($event)"
                        [disabled]="!configContaBaseLegalService.configContaBaseLegal.dataInicioVigencia"
                    ></p-calendar>
                </div>
            </div>

            <div class="p-field p-col-12 p-md-12" style="margin-top: 10px; text-align: right;">
                <button
                    *ngIf="configContaBaseLegalService.configContaBaseLegal.configContaBaseLegalId == null"
                    pButton
                    type="button"
                    label="Adicionar Base Legal"
                    icon="pi pi-plus-circle"
                    (click)="addBaseLegal()"
                    [disabled]="!configConta.configContaId"
                ></button>
                <button
                    *ngIf="configContaBaseLegalService.configContaBaseLegal.configContaBaseLegalId != null"
                    pButton
                    type="button"
                    label="Atualizar Base Legal"
                    icon="pi pi-pencil"
                    class="p-mr-1"
                    (click)="addBaseLegal()"
                ></button>
                <button
                    *ngIf="configContaBaseLegalService.configContaBaseLegal.configContaBaseLegalId != null"
                    pButton
                    type="button"
                    label="Cancelar"
                    icon="pi pi-times"
                    class="p-button-secondary"
                    (click)="cancel()"
                ></button>
            </div>

            <p-table [value]="configContaBaseLegals">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Descrição Base Legal</th>
                        <th>Capitulação Legal</th>
                        <th>Tipo Base Legal</th>
                        <th>Situação</th>
                        <th>Inicio Vigência</th>
                        <th>Fim Vigência</th>
                        <th style="width: 150px;">Ação</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-configContaBaseLegal>
                    <tr>
                        <td>{{configContaBaseLegal.baseLegal?.tblDescBaseLegal}}</td>
                        <td>{{configContaBaseLegal.baseLegal?.tblCapitulacionLegal}}</td>
                        <td>{{configContaBaseLegal.baseLegal?.tblDescBaseLegal}}</td>
                        <td>{{configContaBaseLegal.situacao}}</td>
                        <td>{{utils.convertToDateBr(configContaBaseLegal.dataInicioVigencia)}}</td>
                        <td>{{utils.convertToDateBr(configContaBaseLegal.dataFimVigencia)}}</td>
                        <td>
                            <button pButton pRipple title="Selecionar" type="button" icon="pi pi-pencil" class="p-button-rounded p-mr-1" (click)="edit(configContaBaseLegal)"></button>
                            <button
                                *ngIf="configConta.implantado =='SIM'"
                                pButton
                                pRipple
                                title="{{ configContaBaseLegal?.situacao === 'ATIVA' ? 'Inativar' : 'Ativar' }}"
                                type="button"
                                icon="{{ configContaBaseLegal?.situacao === 'ATIVA' ? 'pi pi-times' : 'pi pi-check' }}"
                                class="p-button-rounded p-button-secondary"
                                (click)="ativarInativar(configContaBaseLegal)"
                            ></button>
                            <button 
                                *ngIf="configConta.implantado =='NAO'"
                                pButton
                                pRipple
                                title="Excluir"
                                type="button"
                                icon="pi pi-trash"
                                class="p-button-rounded p-button-danger"
                                style="margin-left: 3px"
                                (click)="delete(configContaBaseLegal)"
                            ></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td>
                            Nenhum registro localizado
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <p-paginator [rows]="10" [totalRecords]="pagination.totalElements" (onPageChange)="onPageChange($event)"></p-paginator>
      
        </ng-template>

        <ng-template pTemplate="footer">
            <div class="grid grid-nogutter justify-content-end">
                <p-button label="Anterior" (onClick)="prevPage()" icon="pi pi-angle-left"></p-button>
                <p-button class="float-btn-next" label="Próxima" (onClick)="nextPage()" icon="pi pi-angle-right" iconPos="right"></p-button>
            </div>
        </ng-template>
    </p-card>
</div>
<app-base-legal-search-modal (selectBaseLegal)="changeBaseLegal($event)"></app-base-legal-search-modal>
