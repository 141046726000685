import { NgModule } from '@angular/core';
import { CompartilhadoModule } from 'src/app/compartilhado/compartilhado.module';
import { EventoPagamentoListComponent } from './evento-pagamento-list/evento-pagamento-list.component';
import { EventoPagamentoCadastroComponent } from './evento-pagamento-cadastro/evento-pagamento-cadastro.component';
import { EventoPagamentoTransacaoFinanceiraModalComponent } from './evento-pagamento-transacao-financeira-modal/evento-pagamento-transacao-financeira-modal.component';
import { EventoPagamentoRegraImputacaoModalComponent } from './evento-pagamento-regra-imputacao-modal/evento-pagamento-regra-imputacao-modal.component';
import { EventoPagamentoRegraIdentificacaoModalComponent } from './evento-pagamento-regra-identificacao-modal/evento-pagamento-regra-identificacao-modal.component';
import { EventoPagamentoContaExcecaoModalComponent } from './evento-pagamento-conta-excecao-modal/evento-pagamento-conta-excecao-modal.component';
import { EventoPagamentoEventoCompensacaoModalComponent } from './evento-pagamento-evento-compensacao-modal/evento-pagamento-evento-compensacao-modal.component';
import { EventoPagamentoParametroConfiguracaoModalComponent } from './evento-pagamento-parametro-configuracao-modal/evento-pagamento-parametro-configuracao-modal.component';
import { DialogModule } from 'primeng/dialog';
import { FieldsetModule } from 'primeng/fieldset';
import {RippleModule} from 'primeng/ripple';
import {BadgeModule} from 'primeng/badge';



@NgModule({
  declarations: [
      EventoPagamentoListComponent,
      EventoPagamentoCadastroComponent,
    EventoPagamentoTransacaoFinanceiraModalComponent,
    EventoPagamentoRegraImputacaoModalComponent,
    EventoPagamentoRegraIdentificacaoModalComponent,
    EventoPagamentoContaExcecaoModalComponent,
    EventoPagamentoEventoCompensacaoModalComponent,
    EventoPagamentoParametroConfiguracaoModalComponent
  ],
    imports: [
        CompartilhadoModule,
        DialogModule,
        FieldsetModule,
        RippleModule,
        BadgeModule
    ],
  exports: [
      EventoPagamentoListComponent,
      EventoPagamentoCadastroComponent,
    EventoPagamentoTransacaoFinanceiraModalComponent,
    EventoPagamentoRegraImputacaoModalComponent,
    EventoPagamentoRegraIdentificacaoModalComponent,
    EventoPagamentoContaExcecaoModalComponent,
    EventoPagamentoEventoCompensacaoModalComponent,
    EventoPagamentoParametroConfiguracaoModalComponent
  ]
})
export class EventoPagamentoModule { }
