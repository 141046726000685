import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {EventoCompensacao} from '../../../models/evento-compensacao';
import * as ConfigContaSearchModalActions from '../../config-conta/config-conta-search-modal/config-conta-search-modal-actions';
import {Store} from '@ngrx/store';
import {Subject} from 'rxjs';
import {EventoCompensaImputacao} from '../../../models/evento-compensa-imputacao';
import {UtilsService} from '../../../shared/utils.service';
import Swal from 'sweetalert2';
import {EventoCompensacaoConta} from '../../../models/evento-compensacao-conta';
import {ConfigConta} from '../../../models/config-conta';
import {EnumService} from '../../../compartilhado/services/enum.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Message} from 'primeng/api';
import {EventoCompensacaoConfigService} from './evento-compensacao-config.service';
import {TransacaoSearchResponse} from '../../../responses/transacao-search-response';
import {OperacaoTransacaoEnum} from '../../../utils/enums/operacao-transacao-enum';
import {OperacaoCrud} from '../../../utils/enums/operacao-crud';
import {SimNaoEnum} from '../../../utils/enums/sim-nao.enum';
import {FormGroup} from '@angular/forms';
import {Location} from '@angular/common';
import { AlertsUtil } from 'src/app/utils/alerts/alerts.util';

@Component({
    selector: 'app-evento-compensacao-config',
    templateUrl: './evento-compensacao-config.component.html',
    styleUrls: ['./evento-compensacao-config.component.scss']
})
export class EventoCompensacaoConfigComponent implements OnInit {
    @ViewChild('transacaoDebito') inputTransacaoDebito;
    tiposCompensacao: Array<any>;
    situacoes: Array<any>;
    transacoesCredito: Array<TransacaoSearchResponse>;
    transacoesDebito: Array<TransacaoSearchResponse>;

    situacao: any = null;
    simNaoEnum: any[];
    whoCallModal = '';
    submitted = false;

    msgs: Message[];

    eventoCompensacao: EventoCompensacao;
    eventsSubject: Subject<any> = new Subject<any>();

    formGroupEventoCompensacao: FormGroup;

    blockedDocument = false;
    implantadoSim: SimNaoEnum;
    implantadoNao: SimNaoEnum;

    constructor(private http: HttpClient,
                private storeConfigContaSearchModal: Store<{ configContaSearchModal: { displayMaximizable: boolean }; }>,
                public utilsService: UtilsService,
                public enumService: EnumService,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private cd: ChangeDetectorRef,
                private service: EventoCompensacaoConfigService,
                private location: Location,
                private alertUtil: AlertsUtil
    ) {
        this.implantadoSim = SimNaoEnum.SIM;
        this.implantadoNao = SimNaoEnum.NAO;
    }

    ngOnInit(): void {
        this.getTiposCompensacao();
        this.getSituacoes();
        this.eventoCompensacao = new EventoCompensacao();
        this.eventoCompensacao.situacao = 'ATIVA';
        this.eventoCompensacao.implantado = 'NAO';
        this.getEnums();
        this.activatedRoute.params.subscribe(s => {
            const id = s.id || null;
            if (id) {
                this.getById(id);
            }
        });
    }

    getById(id) {
        this.blockedDocument = true;
        this.service.getById(id)
            .subscribe(response => {
                this.blockedDocument = false;
                response.dataInicioVigencia = this.utilsService.convertDateToEdit(response.dataInicioVigencia);
                response.dataFimVigencia = this.utilsService.convertDateToEdit(response.dataFimVigencia);
                if (response.eventoCompensacaoContas === null) {
                    response.eventoCompensacaoContas = [];
                }
                if (response.eventoCompensaImputacoes === null) {
                    response.eventoCompensaImputacoes = [];
                }
                this.eventoCompensacao = response;
                this.transacoesDebito = [this.eventoCompensacao.transacaoDebito];
                this.transacoesCredito = [this.eventoCompensacao.transacaoCredito];
            });
    }

    getTiposCompensacao() {
        this.http
            .get<Array<any>>(`${environment.baseUrl}/enums/name/TipoCompensacao`)
            .subscribe(response => {
                this.tiposCompensacao = response;
            });
    }

    getSituacoes() {
        this.http
            .get<Array<any>>(`${environment.baseUrl}/enums/name/Situacao`)
            .subscribe(response => {
                this.situacoes = response;
            });
    }

    onChangeTipoCompensacao(event) {
        if (event.value) {
            this.blockedDocument = true;
            this.service.getTransacoes(event.value, OperacaoTransacaoEnum.DEBITO)
                .subscribe(response => {
                    this.transacoesDebito = response;
                    this.blockedDocument = false;
                });

            this.blockedDocument = true;
            this.service.getTransacoes(event.value, OperacaoTransacaoEnum.CREDITO)
                .subscribe(response => {
                    this.blockedDocument = false;
                    this.transacoesCredito = response;
                });
        } else {
            this.transacoesCredito = [];
            this.transacoesDebito = [];
        }
    }

    outConfigConta(configContas: ConfigConta[]) {
        const eventoCompensacaoContas: EventoCompensacaoConta[] = [];

        configContas.forEach(r => {
            const eventoCompensacaoConta: EventoCompensacaoConta = new EventoCompensacaoConta();
            eventoCompensacaoConta.configConta = JSON.parse(JSON.stringify(r));
            eventoCompensacaoConta.origemDestino = this.whoCallModal === 'ADICIONAR_CONTAS_ORIGEM' ? 'ORIGEM' : 'DESTINO';
            eventoCompensacaoContas.push(eventoCompensacaoConta);
        });

        const duplicated = [];
        eventoCompensacaoContas.forEach(ecc => {
            const b = this.eventoCompensacao.eventoCompensacaoContas.some(r => {
                return ecc.configConta.configContaId === r.configConta.configContaId && ecc.origemDestino === r.origemDestino;
            });
            if (b) {
                duplicated.push(ecc.configConta.nomeCompleto);
            }
        });

        if (duplicated.length) {
            Swal.fire("Erro", `Contas já adicionadas: ${duplicated}`, "error");
        } else {
            this.eventoCompensacao.eventoCompensacaoContas.push(...eventoCompensacaoContas);
        }
        this.closeModal();
    }

    closeModal() {
        this.storeConfigContaSearchModal.dispatch(
            new ConfigContaSearchModalActions.SetDisplayMaximizable(false)
        );
    }

    showBasicDialog(whoCallModal) {
        this.whoCallModal = whoCallModal;
        this.storeConfigContaSearchModal.dispatch(
            new ConfigContaSearchModalActions.SetDisplayMaximizable(true)
        );
    }

    dropConta(cd: EventoCompensacaoConta, i) {
        this.confirmDelete(`Deseja excluir a conta de ${cd.origemDestino}: <b>${cd.configConta.configContaId} - ${cd.configConta.nomeCompleto}</b> ?`)
            .then(result => {
                if (result.value) {
                    if (cd.eventoCompensacaoContaId !== null) {
                        this.service
                            .deleteEventoCompensacaoConta(cd.eventoCompensacaoContaId)
                            .subscribe(() => {});
                    }
                    this.eventoCompensacao
                        .eventoCompensacaoContas
                        .splice(i, 1);
                }
            });
    }

    confirmDelete(msg = 'Confirma a exclusão?') {
        return Swal.fire({
            html: msg,
            icon: 'warning',
            title: "Atenção",
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
        });
    }

    dropEventoCompensacaoImputacao(eci: EventoCompensaImputacao, i) {
        this.confirmDelete(`Deseja excluir a associação da regra de imputação: <b>${eci.regraImputacao.descricao}</b> com o evento de compensação? `)
            .then(result => {
                if (result.value) {
                    if (eci.ideventoCompensaImputacao !== null) {
                        this.service
                            .deleteEventoCompensacaoImputacao(eci.ideventoCompensaImputacao)
                            .subscribe((r) => {})
                    }

                    this.eventoCompensacao
                        .eventoCompensaImputacoes
                        .splice(i, 1);

                    const eventoCompensacaoInputacaoLast: EventoCompensaImputacao =  this
                        .eventoCompensacao
                        .eventoCompensaImputacoes[0] || null;

                    if (eventoCompensacaoInputacaoLast != null) {
                        eventoCompensacaoInputacaoLast.dataFimVigencia = null;
                    }

                }
            });
    }

    editEventoCompensacaoImputacao(eci, i) {
        this.selectRegraInputacao(eci, OperacaoCrud.UPDATE, i);
    }

    selectRegraInputacao(eventoCompensaInputacao: EventoCompensaImputacao = null, operacaoCrud: OperacaoCrud = OperacaoCrud.CREATE, index: number = null) {
        if (this.eventoCompensacao.dataInicioVigencia === null) {
            Swal.fire('Atenção', 'Informe o início da vigência', 'warning').then(r => {
            });
        } else {
            this.eventsSubject.next({
                visible: true,
                operacaoCrud,
                index,
                inicioVigenciaEvento: this.eventoCompensacao.dataInicioVigencia,
                countRegrasInputacoes: this.eventoCompensacao.eventoCompensaImputacoes.length,
                eventoCompensacaoImpututacaoSelected: JSON.parse(JSON.stringify(eventoCompensaInputacao)),
                eventoCompensacaoInputacaoLast: this.eventoCompensacao
                    .eventoCompensaImputacoes[0] || null
            });
        }
    }

    getRegraInputacaoSelected(x) {
        const selected: EventoCompensaImputacao = x.selected;

        if (x.operacaoCrud === OperacaoCrud.CREATE) {
            const duplicated = this.eventoCompensacao.eventoCompensaImputacoes.some((r, i) => {
                return r.regraImputacao.id === selected.regraImputacao.id;
            });
            if (duplicated) {
                Swal.fire(
                    'Erro',
                    `Regra de Imputação <b>${selected.regraImputacao.descricao}</b> já associada ao evento de compensação para o período de vigência informado.`,
                    'error')
                    .then(r => {
                    });
            } else {
                if (this.eventoCompensacao.eventoCompensaImputacoes.length > 0) {
                    // const sizeArray = this.eventoCompensacao.eventoCompensaImputacoes.length;
                    this.eventoCompensacao
                        // .eventoCompensaImputacoes[sizeArray - 1]
                        .eventoCompensaImputacoes[0]
                        .dataFimVigencia = this.utilsService.subtractDays(selected.dataInicioVigencia, 1);
                }
                this.eventoCompensacao.eventoCompensaImputacoes.push(selected);
                this.eventoCompensacao
                    .eventoCompensaImputacoes
                    .sort((a, b) => {
                        if (a.dataFimVigencia === null) {
                            return -1;
                        } else if (b.dataFimVigencia === null) {
                            return 1;
                        }
                        return 0;
                    })
            }
        } else if (x.operacaoCrud === OperacaoCrud.UPDATE) {
            const duplicated = this.eventoCompensacao.eventoCompensaImputacoes.some((r, i) => {
                return r.regraImputacao.id === selected.regraImputacao.id && i !== x.index;
            });
            if (duplicated) {
                Swal.fire(
                    'Erro',
                    `Regra de Imputação <b>${selected.regraImputacao.descricao}</b> já associada ao evento de compensação para o período de vigência informado.`,
                    'error')
                    .then(r => {
                    });
            } else {
                if (this.eventoCompensacao.eventoCompensaImputacoes.length > 0) {
                    // const sizeArray = this.eventoCompensacao.eventoCompensaImputacoes.length;
                    this.eventoCompensacao
                        // .eventoCompensaImputacoes[sizeArray - 1]
                        .eventoCompensaImputacoes[0]
                        .dataFimVigencia = this.utilsService.subtractDays(selected.dataInicioVigencia, 1);
                }
                this.eventoCompensacao.eventoCompensaImputacoes[x.index] = selected;
                this.eventoCompensacao
                    .eventoCompensaImputacoes
                    .sort((a, b) => {
                        if (a.dataFimVigencia === null) {
                            return -1;
                        } else if (b.dataFimVigencia === null) {
                            return 1;
                        }
                        return 0;
                    })
            }
        }

    }

    salvar() {
        this.submitted = true;
        if(this.eventoCompensacao.transacaoDebito == null) {
            return
        }
        if(this.eventoCompensacao.transacaoCredito == null) {
            return
        }
        if (
            this.eventoCompensacao.descricao &&
            this.eventoCompensacao.tipoCompensacao &&
            this.eventoCompensacao.dataInicioVigencia) {

            const toSend: EventoCompensacao = JSON.parse(JSON.stringify(this.eventoCompensacao));

            if (toSend.descricao !== null)
                toSend.descricao = toSend.descricao.toUpperCase();

            toSend.dataInicioVigencia = this.utilsService.convertToSaveUTC(toSend.dataInicioVigencia);
            toSend.dataFimVigencia =    this.utilsService.convertToSaveUTC(toSend.dataFimVigencia);
            if (toSend.eventoCompensacaoId === null) {
                toSend.implantado = 'NAO';
            }

            this.blockedDocument = true;
            this.http.request('post', `${environment.baseUrl}/evento-compensacao`, {
                body: toSend
            }).subscribe(response => {
                this.blockedDocument = false;
                // this.eventoCompensacao = (response as EventoCompensacao);
                Swal
                    .fire('Sucesso', 'Salvo com sucesso', 'success')
                    .then(() => {
                        this.router
                            .navigate(['/evento-compensacao/evento-compensacao-list'])
                            .then(r => {
                                this.submitted = false;
                            });
                    });
            }, error => {
                this.blockedDocument = false;
                this.alertUtil.handleError(error);
            });
        }

    }

    scrollToTop() {
        (function smoothscroll() {
            const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
            if (currentScroll > 0) {
                window.requestAnimationFrame(smoothscroll);
                window.scrollTo(0, currentScroll - (currentScroll / 8));
            }
        })();
    }

    getEnums() {
        this.enumService.getByName('SimNaoEnum')
            .subscribe((Response) => {
                this.simNaoEnum = Response;
            });
    }

    voltar() {
        this.location.back();
    }

}
