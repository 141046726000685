<div class="card">
    <p-toast></p-toast>
    <p-steps [model]="items" [readonly]="true"></p-steps>
</div>

<div class="stepsdemo-content">
    <p-card>
        <ng-template pTemplate="title">
            Configuração da Conta
        </ng-template>

        <ng-template pTemplate="header">
            <div class="grid grid-nogutter justify-content-end" [style]="{padding: '10px'}">
                <button style="margin-right: 5px;" pButton type="button" label="Voltar" class="p-button-outlined p-mr-2" (click)="voltar()"></button>
                <!--                <button style="margin-right: 5px;" pButton type="button" label="Teste" class="p-button-outlined p-mr-2" (click)="teste()"></button>-->
                <p-button class="float-btn-next" label="Próxima" (onClick)="nextPage()" icon="pi pi-angle-right" iconPos="right"></p-button>
                <p-button (click)="salvar()" *ngIf="configConta.configContaId" class="float-btn-next margin-btn-right" label="Atualizar Conta" icon="fa fa-refresh" iconPos="left"></p-button>
            </div>
        </ng-template>

        <ng-template pTemplate="content">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-2">
                    <label>Código</label>
                    <input type="text" disabled [(ngModel)]="configConta.configContaId" pInputText>
                </div>
                <div class="p-field p-col-10">
                    <label>Nome Resumido*</label>
                    <input [disabled]="configConta.implantado === 'SIM'" #nomeResumido="ngModel" type="text" 
                    [(ngModel)]="configConta.nomeResumido" name="nomeResumido" required pInputText autocomplete="nope"
                    >
                    <small *ngIf="(nomeResumido.invalid && submitted)" class="p-error">Campo obrigatório</small>
                </div>

                <div class="p-field p-col-12">
                    <label>Nome Completo*</label>
                    <input  #nomeCompleto="ngModel" [disabled]="configConta.implantado === 'SIM'" type="text" 
                    [(ngModel)]="configConta.nomeCompleto" name="nomeCompleto" required pInputText autocomplete="nope"
                    >
                    <small *ngIf="(nomeCompleto.invalid && submitted)" class="p-error">Campo obrigatório</small>
                </div>

                <div class="p-field p-col-6">
                    <p-fieldset legend="Tipo de Conta">
                        <div class="p-fluid p-formgrid p-grid">
                            <div *ngFor="let tc of tiposConta" class="p-col-4">
                                <div class="p-field-radiobutton">
                                    <p-radioButton
                                            [name]="tc.chave"
                                            [inputId]="tc.chave"
                                            [value]="tc.chave"
                                            (onClick)="onChangeTipoConta(tc)"
                                            [(ngModel)]="tipoContaSelected"
                                            [disabled]="configConta.configContaId !== null && configConta.implantado === 'SIM'"
                                    ></p-radioButton>
                                    <label style="cursor: pointer" [for]="tc.chave">{{tc.valor}}</label>
                                </div>
                            </div>
                        </div>
                    </p-fieldset>
                </div>
                <div class="p-field p-col-6" [hidden]="tipoContaSelected == 'NAO_TRIBUTARIA' || tipoContaSelected == 'AMBAS'">
                    <p-fieldset legend="Tributo">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-col-12 p-md-12">
                                <p-dropdown
                                    #tributo="ngModel"
                                    optionLabel="sigla"
                                    showClear="true"
                                    [autoDisplayFirst]="false"
                                    [options]="tributoModels"
                                    [(ngModel)]="configConta.tributo"
                                    (onChange)="setOrigem($event)"
                                    [disabled]="configConta.configContaId !== null && configConta.implantado === 'SIM'"
                                    required
                                ></p-dropdown>
                                <small *ngIf="(tributo.invalid && submitted)" class="p-error">Campo obrigatório</small>
                            </div>
                        </div>
                    </p-fieldset>
                </div>
                <div class="p-field p-col-6" [hidden]="tipoContaSelected == 'TRIBUTARIA'">
                    <p-fieldset legend="Natureza da Conta">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-col-12 p-md-12">
                                <p-dropdown
                                    #naturezaContaCorrente="ngModel"
                                    optionLabel="descricao"
                                    showClear="true"
                                    [autoDisplayFirst]="false"
                                    [options]="naturezaContaCorrenteModels"
                                    [(ngModel)]="configConta.naturezaContaCorrente"
                                    (ngModelChange)="setOrigem($event)"
                                    [disabled]="configConta.configContaId !== null && configConta.implantado === 'SIM'"
                                    required
                                ></p-dropdown>
                                <small *ngIf="(naturezaContaCorrente.invalid && submitted)" class="p-error">Campo obrigatório</small>
                            </div>
                        </div>
                    </p-fieldset>
                </div>

                <div class="p-field p-col-12">
                    <p-fieldset legend="Tipo Cadastro">
                        <div class="p-col-12">
                                <div *ngFor="let tipoCadastro of tipoCadastroModels" class="p-field-checkbox">
                                    <p-checkbox
                                            name="tipoCadastro"
                                            [value]="tipoCadastro"
                                            [(ngModel)]="tipoCadastroModel"
                                            [disabled]="configConta.implantado === 'SIM'"
                                    ></p-checkbox>
                                    <label [for]="tipoCadastro.tipoCadastroId" style="cursor: pointer"> {{ tipoCadastro.descricao }} ({{tipoCadastro.tipoCodigoCadastro}}) </label>
                                </div>
                            <small *ngIf="validaTipocadastro" class="p-error">Campo obrigatório</small>
                        </div>
                    </p-fieldset>
                </div>

                <div class="p-field p-col-12">
                    <label>Origem da Informação*</label>
                    <p-dropdown
                        #origemInformacao="ngModel"
                        (click)="clickDisabledOrigem()"
                        [options]="origemInformacaoModels"
                        [(ngModel)]="configConta.origemInformacao"
                        optionLabel="nome"
                        [showClear]="true"
                        [autoDisplayFirst]="false"
                        name="origemInformacao"
                        [disabled]="origemInformacaoDisabled()"
                        required
                    >
                    </p-dropdown>
                    <small *ngIf="(origemInformacao.invalid && submitted)" class="p-error">{{labelValidacaoOrigem}}</small>
                </div>

                <div class="p-field p-col-2">
                    <label>Periodicidade do Lançamento*</label>
                    <p-dropdown
                        #periodicidade="ngModel"
                        [options]="periodicidadeModels"
                        [(ngModel)]="configConta.periodicidade"
                        optionLabel="descricao"
                        [showClear]="true"
                        [autoDisplayFirst]="false"
                        name="periodicidade"
                        required
                        [disabled]="configConta.implantado === 'SIM'"
                    ></p-dropdown>
                    <small *ngIf="(periodicidade.invalid && submitted)" class="p-error">Campo obrigatório</small>
                </div>
                <div class="p-field p-col-4">
                    <label>Período Referência(Mês/Ano) da*</label>
                    <p-dropdown
                        #tipoDataReferencia="ngModel"
                        [options]="tipoDataReferenciaModels"
                        [(ngModel)]="configConta.tipoDataReferencia"
                        optionLabel="descricao"
                        [showClear]="true"
                        [autoDisplayFirst]="false"
                        name="tipoDataReferencia"
                        [disabled]="configConta.implantado === 'SIM'"
                        required>
                    </p-dropdown>
                    <small *ngIf="(tipoDataReferencia.invalid && submitted)" class="p-error">Campo obrigatório</small>
                </div>
                <div class="p-field p-col-3">
                    <label>Situação*</label>
                    <p-dropdown
                        #situacao="ngModel"
                        optionLabel="valor"
                        optionValue="chave"
                        inputId="dropdown"
                        showClear="true"
                        [options]="situacoes"
                        [(ngModel)]="configConta.situacao"
                        [autoDisplayFirst]="false"
                        name="situacao"
                        [disabled]="configConta.implantado === 'NAO'"
                        required>
                    </p-dropdown>
                    <small *ngIf="(situacao.invalid && submitted)" class="p-error">Campo obrigatório</small>
                </div>
                <div class="p-field p-col-3">
                    <label>Implantada*</label>
                    <p-dropdown
                        #implantado="ngModel"
                        optionLabel="value"
                        optionValue="key"
                        inputId="dropdown"
                        showClear="true"
                        name="implantado"
                        required
                        [(ngModel)]="configConta.implantado"
                        [autoDisplayFirst]="false"
                        [options]="implantadoOptions"
                        [disabled]="true"
                    >
                    </p-dropdown>
                    <small *ngIf="(situacao.invalid && submitted)" class="p-error">Campo obrigatório</small>
                </div>
                <div class="p-field p-col-6">
                    <label>Início da Vigência*</label>
                    <p-calendar
                        #dataInicioVigencia="ngModel"
                        [showIcon]="true"
                        inputId="icon"
                        dateFormat="dd/mm/yy"
                        [(ngModel)]="configConta.dataInicioVigencia"
                        name="dataInicioVigencia"
                        [disabled]="configConta.configContaId"
                        (click)="alert('DATA')"
                        required>
                    </p-calendar>
                    <small *ngIf="(dataInicioVigencia.invalid && submitted)" class="p-error">Campo obrigatório</small>
                </div>
                <div class="p-field p-col-6">
                    <label>Fim da Vigência</label>
                    <p-calendar
                            [showIcon]="true"
                            inputId="icon"
                            dateFormat="dd/mm/yy"
                            [(ngModel)]="configConta.dataFimVigencia"
                            name="dataFimVigencia"
                            (click)="alert('DATA')"
                    >
                    </p-calendar>
                </div>
            </div>
        </ng-template>

        <ng-template pTemplate="footer">
            <div class="grid grid-nogutter justify-content-end">
                <button style="margin-right: 5px;" pButton type="button" label="Voltar" class="p-button-outlined p-mr-2" (click)="voltar()"></button>
                <p-button class="float-btn-next" label="Próxima" (onClick)="nextPage()" icon="pi pi-angle-right" iconPos="right"></p-button>
                <p-button (click)="salvar()" *ngIf="configConta.configContaId" class="float-btn-next margin-btn-right" label="Atualizar Conta" icon="fa fa-refresh" iconPos="left"></p-button>
            </div>
        </ng-template>
    </p-card>
</div>
