import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Pageable } from "src/app/shared/pagination/pageable";
import { EventoGerenciarConfigService } from "../../services/evento-gerenciar-config.service";

@Component({
    selector: "app-perfil-visualizacao",
    templateUrl: "./perfil-visualizacao.component.html",
    styleUrls: ["./perfil-visualizacao.component.scss"],
})
export class PerfilVisualizacaoComponent implements OnInit {
    @Input() form!: FormGroup;
    @Input() validarCampos: boolean;
    @Input() dadosCarregados: any;

    perfilVisualizacaoForm: FormGroup = new FormBuilder().group({
        perfis: [[]],
    });

    perfisOptions = [];
    perfilSelecionado = null;
    implantado: boolean | null;

    constructor(private egcService: EventoGerenciarConfigService) {}

    ngOnInit(): void {
        const perfilVisualizacaoForm = this.form.get(
            "perfilVisualizacaoForm"
        ).value;

        if (perfilVisualizacaoForm) {
            this.perfilVisualizacaoForm = perfilVisualizacaoForm;
        } else {
            this.form
                .get("perfilVisualizacaoForm")
                .setValue(this.perfilVisualizacaoForm);
        }
        this.egcService
            .getPerfis()
            .subscribe(
                (resp: Pageable<any>) => (this.perfisOptions = resp.content)
            );

        if (this.dadosCarregados?.perfis) {
            this.perfilVisualizacaoForm
                .get("perfis")
                .setValue(this.dadosCarregados?.perfis);
            this.dadosCarregados.perfis = null;
        }
        this.implantado = this.dadosCarregados?.implantado;
    }

    addPerfil() {
        const perfisSelecionados =
            this.perfilVisualizacaoForm.get("perfis").value;
        const existe = perfisSelecionados.find(
            (p) =>
                p.perfilVisualizacaoContaId ===
                this.perfilSelecionado.perfilVisualizacaoContaId
        );
        if (!existe) perfisSelecionados.push({ ...this.perfilSelecionado });
        this.perfilSelecionado = null;
        this.perfilVisualizacaoForm.get("perfis").setValue(perfisSelecionados);
    }

    removerPerfil(perfil) {
        const perfisSelecionados =
            this.perfilVisualizacaoForm.get("perfis").value;
        const index = perfisSelecionados.indexOf(perfil);
        perfisSelecionados.splice(index, 1);
        this.perfilVisualizacaoForm.get("perfis").setValue(perfisSelecionados);
    }

    aplicarTodos(event) {
        if (this.implantado) return;
        if (!this.todosCheckBox) {
            this.perfilVisualizacaoForm
                .get("perfis")
                .setValue([...this.perfisOptions]);
        } else {
            this.perfilVisualizacaoForm.get("perfis").setValue([]);
        }
    }

    get todosCheckBox(): boolean {
        const perfisSelecionados: any[] =
            this.perfilVisualizacaoForm.get("perfis").value;
        return this.perfisOptions.length === perfisSelecionados.length;
    }
}
