import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'modal-alerta-honorario',
  templateUrl: './modal-alerta-honorario.component.html',
  styleUrls: ['./modal-alerta-honorario.component.scss']
})
export class ModalAlertaHonorarioComponent implements OnInit {
  
  @Input() contaList: any;
  @Output() outEmitDare = new EventEmitter();

  visible: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  showDialog() {
    this.visible = true;
  }

  hiddenDialog(){
    this.visible = false;
  }

  emitirDare() {
    this.outEmitDare.emit();
  }

}
