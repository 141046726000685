<form [formGroup]="form" class="p-fluid ">
    <app-input-template [for-name]="forName" [name]="name" [form]="form">
        <p-inputNumber [formControlName]="forName" mode="decimal" [inputId]="forName" [useGrouping]="useGrouping"
            [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}" [placeholder]="placeholder ? name : ''"
            [title]="name" [maxlength]="18" (onBlur)="onBlur($event)" [min]="min" [max]="max"
            [maxFractionDigits]="maxFractionDigits" [minFractionDigits]="minFractionDigits" *ngIf="!money">
        </p-inputNumber>
        <p-inputNumber [formControlName]="forName" mode="currency" [inputId]="forName" [useGrouping]="useGrouping"
            [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}" [placeholder]="placeholder ? name : ''"
            [title]="name" currency="BRL" [min]="min" [max]="max" (onBlur)="onBlur($event)" *ngIf="money">
        </p-inputNumber>
    </app-input-template>
</form>