import { Component, OnInit } from "@angular/core";
import { AppMainComponent } from "./app.main.component";
import { MenuResetPageIndexService } from "./menu/menu-reset-page.service";
import {AcoesContaCorrenteHelper} from "./shared/acoes-helper/acoes-conta-corrente-helper";

@Component({
    selector: "app-menu",
    template: `
        <ul class="layout-menu">
            <li
                app-menuitem
                *ngFor="let item of model; let i = index"
                [item]="item"
                [index]="i"
                [root]="true"
            ></li>
        </ul>
    `,
})
export class AppMenuComponent implements OnInit {

    model: any[] = [];

    constructor(
        public app: AppMainComponent,
        private menuResetPageIndexService: MenuResetPageIndexService
    ) { }

    async ngOnInit() {
        this.menuMain();
    }

    menuMain() {
        this.adicionarMenuHome();
        this.adicionarMenuDashboard();
        this.adicionarMenuConfiguracoes();
        this.adicionarMenuContaCorrente();
    }

    adicionarMenuHome() {
        this.model.push(
            {
                label: "Home",
                icon: "pi pi-fw pi-home",
                routerLink: ["/"]
            }
        );
    }

    adicionarMenuDashboard() {
        if (!this.podeVisualizarDashboards()) {
            return;
        }

        let itens: any[] = [];

        if (this.podeVisualizarDashboardArrecadacaoEsperadaRealizada()) {
            itens.push(
                {
                    label: "Arrecadação Esperada X Realizada",
                    icon: "pi pi-fw pi-align-left",
                    routerLink: ["/dashboard/lancamento-por-imposto"]
                }
            );
        }

        if (this.podeVisualizarDashboardImpostoContasEmAtraso()) {
            itens.push(
                {
                    label: "Impostos/Contas em Atraso",
                    icon: "pi pi-fw pi-align-left",
                    routerLink: ["/dashboard/imposto-em-atraso"]
                }
            );
        }

        if (this.podeVisualizarDashboardImpostoContasPagas()) {
            itens.push(
                {
                    label: "Impostos/Contas Pagas",
                    icon: "pi pi-fw pi-align-left",
                    routerLink: ["/dashboard/imposto-conta-pagas"]
                }
            );
        }

        if (this.podeVisualizarDashboardEventosPorPeriodo()) {
            itens.push(
                {
                    label: "Eventos por Período",
                    icon: "pi pi-fw pi-align-left",
                    routerLink: ["/dashboard/evento-por-periodo"]
                }
            );
        }

        if (this.podeVisualizarDashboardRelatorioContaCorrente()) {
            itens.push(
                {
                    label: "Relatório de Conta Corrente" ,
                    icon: "pi pi-fw pi-align-left",
                    routerLink: ["relatorios/relatorio-por-periodo"]
                }
            );
        }

        this.model.push(
            {
                label: "Dashboard",
                icon: "pi pi-fw pi-chart-bar",
                items: itens
            }
        );
    }

    adicionarMenuConfiguracoes() {
        if (
            !(this.podeGerenciarContaCorrente() ||
            this.podeGerenciarTransacoes() ||
            this.podeGerenciarEstadoConta() ||
            this.podeGerenciarEventos() ||
            this.podeGerenciarRegraImputacao() ||
            this.podeGerenciarIntegracao())
        ) {
            return;
        }

        let itens: any[] = [];

        this.addSubMenuContaCorrente(itens);
        this.addSubMenuTransacoes(itens);
        this.addSubMenuEstadoConta(itens);
        this.addSubMenuEventos(itens);
        this.addSubMenuImputacao(itens);
        this.addSubMenuIntegracao(itens);

        this.model.push({
            label: "Configurações",
            icon: "pi pi-fw pi-cog",
            items: itens
        });
    }

    adicionarMenuContaCorrente(): void {
        if (
            !(this.podeAbrirContaCorrente() ||
            this.podeListarContaCorrente())
        ) {
            return;
        }

        let subItens: any[] = [];

        this.addSubMenuAbrirContaCorrente(subItens);
        this.addSubMenuListarContaCorrente(subItens);

        this.model.push({
                label: "Conta Corrente",
                icon: "pi pi-user",
                items: subItens
            }
        );
    }

    private addSubMenuContaCorrente(menu: any[] = []) {
        if (
            !(this.podeGerenciarModelos() &&
            this.podeGerenciarContaCorrente())
        ) {
            return;
        }

        let subItens: any[] = [];

        if (this.podeGerenciarModelos()) {
            subItens.push(
                {
                    label: "Gerenciar Modelos",
                    icon: "pi pi-fw pi-align-left",
                    routerLink: ["/regra-criacao/regra-criacao-list"]
                }
            );
        }

        if (this.podeGerenciarContaCorrente()) {
            subItens.push(
                {
                    label: "Gerenciar Contas",
                    icon: "pi pi-fw pi-align-left",
                    routerLink: ["/config-conta/config-conta-list"]
                }
            );
        }

        menu.push({
            label: "Contas Correntes",
            icon: "pi pi-fw pi-align-left",
            items: subItens
        });
    }

    private addSubMenuTransacoes(menu: any[]) {
        if (!this.podeGerenciarTransacoes()) {
            return;
        }

        menu.push(
            {
                label: "Transações",
                icon: "fa-solid fa-arrow-right-arrow-left",
                routerLink: ["/gerenciar-transacao-financeira/selecionar-evento"]
                // items: [
                //     {
                //         label: "Gerenciar Transações",
                //         icon: "pi pi-fw pi-align-left",
                //         routerLink: ["/gerenciar-transacao-financeira/selecionar-evento"],
                //     }
                // ],
            }
        );
    }

    private addSubMenuEstadoConta(itens: any[]) {
        if (!this.podeGerenciarEstadoConta()) {
            return;
        }

        itens.push({
            label: "Estado da Conta",
            icon: "pi pi-fw pi-align-left",
            routerLink: ["/estado-conta/estado-conta-list"]
            // items: [
            //     {
            //         label: "Gerenciar Modelo",
            //         icon: "pi pi-fw pi-align-left",
            //         routerLink: [
            //             "/estado-conta/estado-conta-list",
            //         ],
            //     },
            //     {
            //         label: "Gerenciar Eventos",
            //         icon: "pi pi-fw pi-align-left",
            //         routerLink: [
            //             "/evento-estado/evento-estado-list",
            //         ],
            //     },
        });
    }

    private addSubMenuEventos(itens: any[]) {
        if (!this.podeGerenciarEventos()) {
            return;
        }

        itens.push({
            label: "Eventos",
            icon: "pi pi-fw pi-align-left",
            routerLink: ["/evento-pesquisa-config"]
            // items: [
            //     {
            //         label: "Lançamento",
            //         icon: "pi pi-list",
            //         routerLink: [
            //             "/evento-lancamento/evento-lancamento-list",
            //         ],
            //     },
            //     {
            //         label: "Pagamento",
            //         icon: "pi pi-fw pi-align-left",
            //         routerLink: [
            //             "/evento-pagamento/evento-pagamento-list",
            //         ],
            //     },
            //     {
            //         label: "Compensação",
            //         icon: "pi pi-fw pi-align-left",
            //         routerLink: [
            //             "/evento-compensacao/evento-compensacao-list",
            //         ],
            //     },
            //     {
            //         label: "Beneficio Fiscal",
            //         icon: "pi pi-fw pi-align-left",
            //         routerLink: [
            //             "/evento-beneficio/evento-beneficio-list",
            //         ],
            //     }
            // ],
        });
    }

    private addSubMenuImputacao(itens: any[]) {
        if (!this.podeGerenciarRegraImputacao()) {
            return;
        }

        itens.push({
            label: "Regra de Imputação",
            icon: "pi pi-fw pi-align-left",
            routerLink: ["/config-regra-geral/regra-imputacao"]
            // items: [
            //     {
            //         label: "Regra de Imputação",
            //         icon: "pi pi-fw pi-align-left",
            //         routerLink: [
            //             "/config-regra-geral/regra-imputacao",
            //         ],
            //     },
            //     {
            //         label: "Regra de Seleção",
            //         icon: "pi pi-fw pi-sliders-v",
            //         routerLink: [
            //             "/config-regra-selecao/config-regra-selecao-list",
            //         ],
            //     },
            // ]
        });
    }

    private addSubMenuIntegracao(itens: any[]) {
        if (!this.podeGerenciarIntegracao()) {
            return;
        }

        let subItens: any[] = [];

        if (this.podeGerenciarOperadores()) {
            subItens.push(
                {
                    label: "Configuração de Operadores",
                    icon: "pi pi-fw pi-align-left",
                    routerLink: ["/config-operadores/gerenciar-operadores"]
                }
            );
        }

        if (this.podeGerenciarFluxos()) {
            subItens.push(
                {
                    label: "Configuração Fluxos",
                    icon: "pi pi-fw pi-align-left",
                    routerLink: ["/config-fluxos/gerenciar-fluxos"]
                }
            );
        }

        itens.push({
            label: "Integração",
            icon: "pi pi-fw pi-align-left",
            items: subItens
        });
    }

    private addSubMenuAbrirContaCorrente(subItens: any[]) {
        if (this.podeAbrirContaCorrente()) {
            subItens.push(
                {
                    label: "Abertura",
                    icon: "pi pi-file-o",
                    routerLink: ["/criacao-conta"],
                    command: (event) => {
                        this.menuResetPageIndexService.resetIndex();
                    }
                }
            );
        }
    }

    private addSubMenuListarContaCorrente(subItens: any[]) {
        if (this.podeListarContaCorrente()) {
            subItens.push(
                {
                    label: "Consulta",
                    icon: "pi pi-search",
                    routerLink: ["/consultar-conta-veiculo"],
                    command: (event) => {
                        this.menuResetPageIndexService.resetIndex();
                    }
                }
            );
        }
    }

    private podeVisualizarDashboards(): boolean {
        return this.podeVisualizarDashboardArrecadacaoEsperadaRealizada() ||
            this.podeVisualizarDashboardImpostoContasEmAtraso() ||
            this.podeVisualizarDashboardImpostoContasPagas() ||
            this.podeVisualizarDashboardEventosPorPeriodo() ||
            this.podeVisualizarDashboardRelatorioContaCorrente();
    }

    private podeVisualizarDashboardArrecadacaoEsperadaRealizada(): boolean {
        return AcoesContaCorrenteHelper.podeVisualizarDashboardArrecadacaoEsperadaAtualizada;
    }

    private podeVisualizarDashboardImpostoContasEmAtraso(): boolean {
        return AcoesContaCorrenteHelper.podeVisualizarDashboardImpostoContasEmAtraso;
    }

    private podeVisualizarDashboardImpostoContasPagas(): boolean {
        return AcoesContaCorrenteHelper.podeVisualizarDashboardImpostoContasPagas;
    }

    private podeVisualizarDashboardEventosPorPeriodo(): boolean {
        return AcoesContaCorrenteHelper.podeVisualizarDashboardEventosPorPeriodo;
    }

    private podeVisualizarDashboardRelatorioContaCorrente(): boolean {
        return AcoesContaCorrenteHelper.podeVisualizarDashboardRelatorioContaCorrente;
    }

    private podeGerenciarModelos(): boolean {
        return AcoesContaCorrenteHelper.podeCadastrarModelosContaCorrente ||
            AcoesContaCorrenteHelper.podeEditarModelosContaCorrente ||
            AcoesContaCorrenteHelper.podeExcluirModelosContaCorrente ||
            AcoesContaCorrenteHelper.podeListarModelosContaCorrente;
    }

    private podeAbrirContaCorrente(): boolean {
        return AcoesContaCorrenteHelper.podeAbrirContaCorrente;
    }

    private podeListarContaCorrente(): boolean {
        return AcoesContaCorrenteHelper.podeListarConsultasContaCorrente;
    }

    private podeGerenciarContaCorrente(): boolean {
        return AcoesContaCorrenteHelper.podeCadastrarContasCorrente ||
            AcoesContaCorrenteHelper.podeEditarContasCorrente ||
            AcoesContaCorrenteHelper.podeExcluirContasCorrente ||
            AcoesContaCorrenteHelper.podeListarContasCorrente;
    }

    private podeGerenciarTransacoes(): boolean {
        return AcoesContaCorrenteHelper.podeCadastrarTransacao ||
            AcoesContaCorrenteHelper.podeEditarTransacao ||
            AcoesContaCorrenteHelper.podeExcluirTransacao ||
            AcoesContaCorrenteHelper.podeListarTransacao;
    }

    private podeGerenciarEstadoConta(): boolean {
        return AcoesContaCorrenteHelper.podeCadastrarEstadoConta ||
            AcoesContaCorrenteHelper.podeEditarEstadoConta ||
            AcoesContaCorrenteHelper.podeExcluirEstadoConta ||
            AcoesContaCorrenteHelper.podeListarEstadoConta;
    }

    private podeGerenciarEventos(): boolean {
        return AcoesContaCorrenteHelper.podeCadastrarEventos ||
            AcoesContaCorrenteHelper.podeEditarEventos ||
            AcoesContaCorrenteHelper.podeExcluirEventos ||
            AcoesContaCorrenteHelper.podeListarEventos;
    }

    private podeGerenciarRegraImputacao(): boolean {
        return AcoesContaCorrenteHelper.podeCadastrarRegrasImputacao ||
            AcoesContaCorrenteHelper.podeEditarRegrasImputacao ||
            AcoesContaCorrenteHelper.podeAtivarInativarRegrasImputacao ||
            AcoesContaCorrenteHelper.podeListarRegrasImputacao;
    }

    private podeGerenciarIntegracao(): boolean {
        return this.podeGerenciarOperadores() ||
            this.podeGerenciarFluxos();
    }

    private podeGerenciarOperadores(): boolean {
        return AcoesContaCorrenteHelper.podeCadastrarOperadoresIntegracao ||
            AcoesContaCorrenteHelper.podeEditarOperadoresIntegracao ||
            AcoesContaCorrenteHelper.podeExcluirOperadoresIntegracao ||
            AcoesContaCorrenteHelper.podeListarOperadoresIntegracao;
    }

    private podeGerenciarFluxos(): boolean {
        return AcoesContaCorrenteHelper.podeCadastrarFluxosIntegracao ||
            AcoesContaCorrenteHelper.podeEditarFluxosIntegracao ||
            AcoesContaCorrenteHelper.podeExcluirFluxosIntegracao ||
            AcoesContaCorrenteHelper.podeListarFluxosIntegracao;
    }

}
