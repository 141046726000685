import {Injectable} from '@angular/core';
import {Pageable} from '../../../shared/pagination/pageable';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {RegraCriacaoSearchRequest} from '../../../request/RegraCriacaoSearchRequest';
import {Pagination} from '../../../shared/pagination/pagination';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RegraCriacaoListServiceService {
    blockedDocument = false;
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(
        private http: HttpClient,
    ) {}

    pesquisarRegrasCriacao(regraCriacaoSearchRequest: RegraCriacaoSearchRequest, pagination: Pagination<any>): Observable<Pageable<any>> {
        this.blockedDocument = true;
        return this.http
            .get<Pageable<any>>(`${environment.baseUrl}/regra-criacao-conta/search`, {
                params: {
                    ...regraCriacaoSearchRequest,
                    page: pagination.pageable.pageNumber,
                    size: pagination.size
                }
            });
    }

    getSituacoes(): Observable<Array<any>> {
      return this.http.get<Array<any>>(`${environment.baseUrl}/enums/regra-criacao-conta/situacao`);
    }

    deleteRegraCriacao(id: string): Observable<void> {
        return this.http.delete<void>(`${environment.baseUrl}/config-conta-regra-criacao/${id}`);
    }

    deleteModelo(id: number): Observable<void> {
        return this.http.delete<void>(`${environment.baseUrl}/regra-criacao-conta/${id}`);
    }

}
