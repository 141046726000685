import { ConfigRegraSelecaoSearchRequest } from "../../request/config-regra-selecao-search-request";
import { Pageable } from "../../shared/pagination/pageable";

import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Pagination } from "../../shared/pagination/pagination";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class RegraSelecaoService {
    httpOptions = {
        headers: new HttpHeaders({
            "Content-Type": "application/json",
        }),
    };

    constructor(private http: HttpClient) {}

    searchRegras(
        regraCriacaoSearchRequest: ConfigRegraSelecaoSearchRequest,
        pagination: Pagination<any>
    ): Observable<Pageable<any>> {
        return this.http.get<Pageable<any>>(
            `${environment.baseUrl}/regra/search`,
            {
                params: {
                    ...regraCriacaoSearchRequest,
                    page: pagination.pageable.pageNumber,
                    size: pagination.size,
                },
            }
        );
    }

    getSituacoes(): Observable<Array<any>> {
        return this.http.get<Array<any>>(
            `${environment.baseUrl}/enums/situacao`
        );
    }

    getCategorias(): Observable<Array<any>> {
        return this.http.get<Array<any>>(
            `${environment.baseUrl}/tipo-categoria`
        );
    }

    getCategoriasByIdCadastro(id: string): Observable<Array<any>> {
        return this.http.get<Array<any>>(
            `${environment.baseUrl}/tipo-categoria?tipoCadastroId=${id}`
        );
    }

    getTipoCadastro(): Observable<Array<any>> {
        return this.http.get<Array<any>>(
            `${environment.baseUrl}/tipo-cadastro`
        );
    }

    getAtributos(): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${environment.baseUrl}/atributo`);
    }

    listarValoresAtributos(id: number): Observable<Array<any>> {
        return this.http.get<Array<any>>(
            `${environment.baseUrl}/atributo/${id}/listar-valores`
        );
    }

    getAtributosByIdCategoria(id: string): Observable<Array<any>> {
        return this.http.get<Array<any>>(
            `${environment.baseUrl}/atributo?tipoCategoriaId=${id}`
        );
    }

    getTipoCadastroById(id: string): Observable<Array<any>> {
        return this.http.get<Array<any>>(
            `${environment.baseUrl}/tipo-cadastro?tipoCadastroId=${id}`
        );
    }

    mudaSituacao(id: string): Observable<void> {
        return this.http.put<void>(
            `${environment.baseUrl}/regra/${id}/ativa-inativa`,
            {}
        );
    }

    findById(id: number): Observable<any> {
        return this.http.get<void>(`${environment.baseUrl}/regra/${id}`);
    }

    save(regraSelecao: any): Observable<any> {
        return this.http.post<void>(
            `${environment.baseUrl}/regra`,
            regraSelecao,
            this.httpOptions
        );
    }

    //
}
