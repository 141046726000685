import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UtilsService} from 'src/app/shared/utils.service';
import {ConfigReceitaService} from './ConfigReceitaService';
import {ConfigContaReceitaSearchRequest} from '../../../request/config-conta-receita-search-request';
import {Pagination} from '../../../shared/pagination/pagination';
import * as moment from 'moment';
import swal from 'sweetalert2';
import {MenuItem, Message} from 'primeng/api';
import {Router} from '@angular/router';
import {ContaCorrenteService} from 'src/app/core/conta-corrente.service';
import {ConfigReceita} from 'src/app/models/config-receita';
import {
    ConfigContaUnidOrganizaTabViewComponent
} from '../config-conta-unid-organiza-tab-view/config-conta-unid-organiza-tab-view.component';
import {ConfigAssociarReceitaService} from './config-associar-receita.service';
import {Receita} from '../../../models/receita';
import Swal from 'sweetalert2';
import { AlertsUtil } from 'src/app/utils/alerts/alerts.util';

@Component({
    selector: 'app-config-associar-receita-tab-view',
    templateUrl: './config-associar-receita-tab-view.component.html',
    styleUrls: ['./config-associar-receita-tab-view.component.scss'],
})
export class ConfigAssociarReceitaTabViewComponent implements OnInit {

    @Input() configConta = null;
    @Output() outHabilitaSalvar = new EventEmitter();
    @ViewChild(ConfigContaUnidOrganizaTabViewComponent, {static: false})
    unidadeOrganizacional: ConfigContaUnidOrganizaTabViewComponent;
    isLoading = false;

    formGroupReceita: FormGroup;

    receitaSelected: Receita;

    dataAtual: string;

    submitted = false;

    habilitaCancelar: boolean;

    icone = 'pi pi-plus-circle';

    btnSalvarAtualizar = 'Adicionar Receita';

    status = [
        {value: 2, label: 'INATIVA'},
        {value: 1, label: 'ATIVA'}
    ];

    situacao = 'ATIVA';

    configReceitaConta = [];

    configContaReceitaSearchRequest: ConfigContaReceitaSearchRequest;

    pagination: Pagination<any>;

    isEdit = false;

    msgs1: Message[];

    primeiro = true;

    disableDataInicioVigencia = false;

    items: MenuItem[];
    configReceita: ConfigReceita;

    constructor(
        private formBuilder: FormBuilder,
        private utilService: UtilsService,
        private configReceitaService: ConfigReceitaService,
        private router: Router,
        private contaCorrenteService: ContaCorrenteService,
        private utils: UtilsService,
        public service: ConfigAssociarReceitaService,
        private alertsUtil: AlertsUtil
    ) {}

    ngOnInit(): void {

        const objContaSession = sessionStorage.getItem('objConta');
        if (objContaSession) {
            this.configConta = JSON.parse(objContaSession);
        }
        this.configContaReceitaSearchRequest = new ConfigContaReceitaSearchRequest();
        this.pagination = new Pagination<any>();
        this.configReceita = new ConfigReceita();
        this.receitaSelected = new Receita();
        this.items = this.contaCorrenteService.getEtapaConta();
        this.configReceita = this.contaCorrenteService.getConfigReceitaInformtation();
        this.iniciaForm('');
        this.getItemReceita();
        this.init();
    }

    onChangeDataInicioVigencia(event) {
        let data = this.utilService.convertDateToSave(event);
        let dataVigenciaConta = this.utilService.convertDateToSave(this.configConta.dataInicioVigencia);


        if (data < dataVigenciaConta) {
            swal.fire({
                title: 'A data não pode ser menor que a data da vigência da conta',
                icon: 'warning',
                showCancelButton: true,
                // confirmButtonText: 'SIM',
                // cancelButtonText: 'NÃO'
            }).then((result) => {
                this.receitaFormControl.dataInicioVigencia.setValue(null);
            });
        }
    }

    nextPage() {
        if (this.validaReceita()) {
            this.router.navigate(['/config-base-legal']);
        }
        this.submitted = true;
    }

    validaReceita() {

        if (this.formGroupReceita.valid || this.configConta.configContaId) {
            this.formGroupReceita.controls.situacao.setValue('ATIVA');
            this.configReceita.receita = this.receitaFormControl.receita.value;
            // this.configReceita.subreceita = this.receitaFormControl.subreceita.value;
            this.configReceita.situacao = this.receitaFormControl.situacao.value;
            this.configReceita.dataInicioVigencia = this.receitaFormControl.dataInicioVigencia.value;
            this.configReceita.dataFimVigencia = this.receitaFormControl.dataFimVigencia.value;

            const dtIni = this.utilService.convertToSaveUniversal(this.configReceita.dataInicioVigencia)
            const dtFim = this.utilService.convertToSaveUniversal(this.configReceita.dataFimVigencia)
            const diff = this.utilService.diffInDays(dtFim, dtIni);

            if (diff < 0) {
                Swal.fire("Erro", "A data final da vigência não pode ser menor que a data inicial da vigência", "error")
                    .then(null)
                return false
            }

            return true;
        }
        return false;
    }

    salvarContaAll() {
        this.submitted = true;
        if (this.validaReceita()) {
            this.isLoading = true;
            this.preparaDatas();
            this.contaCorrenteService.configContaBaseLegal = null;
            this.contaCorrenteService.configContaUnidOrganiza = null;

            this.contaCorrenteService.complete();
            setTimeout(() => {
                this.isLoading = false;
            }, 3000);
        }
    }

    preparaDatas() {
        this.contaCorrenteService.configConta = this.converteDataConfigConta();
        this.contaCorrenteService.configComposicaoConta =
            this.converteDataConfigComosicao();
        this.contaCorrenteService.configModelo =
            this.converteDataConfigModelo();
        this.contaCorrenteService.configReceita =
            this.converteDataConfigReceita();
    }

    converteDataConfigConta() {
        const data = JSON.parse(JSON.stringify(this.contaCorrenteService.configConta));
        data.dataInicioVigencia = this.utils.convertDateToSave(this.contaCorrenteService.configConta.dataInicioVigencia);
        data.dataFimVigencia = this.utils.convertDateToSave(this.contaCorrenteService.configConta.dataFimVigencia);
        return data;
    }

    converteDataConfigComosicao() {
        const data = JSON.parse(JSON.stringify(this.contaCorrenteService.configComposicaoConta));
        data.dataInicioVigencia = this.utils.convertDateToSave(this.contaCorrenteService.configComposicaoConta.dataInicioVigencia);
        data.dataFimVigencia = this.utils.convertDateToSave(this.contaCorrenteService.configComposicaoConta.dataFimVigencia);
        return data;
    }

    converteDataConfigModelo() {
        const data = JSON.parse(
            JSON.stringify(this.contaCorrenteService.configModelo)
        );
        data.dataInicioVigencia = this.utils.convertDateToSave(
            this.contaCorrenteService.configModelo.dataInicioVigencia
        );
        return data;
    }

    converteDataConfigReceita() {
        const data = JSON.parse(
            JSON.stringify(this.contaCorrenteService.configReceita)
        );
        data.dataInicioVigencia = this.utils.convertDateToSave(
            this.contaCorrenteService.configReceita.dataInicioVigencia
        );
        data.dataFimVigencia = this.utils.convertDateToSave(
            this.contaCorrenteService.configReceita.dataFimVigencia
        );        
        return data;
    }

    prevPage() {
        this.router.navigate(['/config-modelo']);
    }

    init() {
        this.service.receitas = this.service.receitas.map(
            (r: any) => {
                return {
                    ...r,
                    displayLabel: `${r.codReceita} - ${r.nomeReceita}`,
                };
            }
        );
        let aux = 0;
        if (this.configConta.tributo != null) {
            aux = this.configConta.tributo.tributoId;
        }
    }

    convertDateChild() {
        this.configConta.dataInicioVigencia = this.convertDateBr(this.configConta.dataInicioVigencia);
    }

    private getCodTipoConta() {
        if (this.configConta.tipoConta === 'TRIBUTARIA') {
            return 1;
        } else {
            return 0;
        }
    }

    iniciaForm(obj: any) {
        // this.formGroupReceita = this.formBuilder.group({
        //     configContaReceitaId: [obj.configContaReceitaId ? obj.configContaReceitaId : undefined],
        //     configConta: [obj.configConta ? obj.configConta : undefined],
        //     configContaId: [obj.configContaId ? obj.configContaId : undefined],
        //     receita: [obj.receita ? obj.receita : undefined, [Validators.required]],
        //     // subreceita: [obj.subreceita ? obj.subreceita : undefined],
        //     situacao: [{value: 1, label: 'ATIVA'}, [Validators.required]],
        //     dataInicioVigencia: [obj ? obj.dataInicioVigencia : undefined, [Validators.required]],
        //     dataFimVigencia: [obj ? obj.dataFimVigencia : undefined],
        // });
        this.formGroupReceita = this.formBuilder.group({
            configContaReceitaId: [null],
            configConta: [null],
            configContaId: [null],
            receita: [null, [Validators.required]],
            situacao: [{value: 1, label: 'ATIVA'}, [Validators.required]],
            dataInicioVigencia: [null, [Validators.required]],
            dataFimVigencia: [null],
        });
    }

    get receitaFormControl() {
        return this.formGroupReceita.controls;
    }

    onSubmit() {
        this.submitted = true;
        let codigoReceita = this.receitaFormControl.receita.value?.codReceita
        this.formGroupReceita.controls.configContaId.setValue(this.configConta.configContaId);
        /**reset value combo receita */
        if(codigoReceita == 0 || codigoReceita == undefined ){
            this.receitaFormControl.receita.setValue(null);
        }
        if (this.formGroupReceita.valid) {
            if (this.formGroupReceita.value.receita.dataFimVigencia != null && (this.formGroupReceita.value.receita.dataFimVigencia > Date.now())) {
                swal.fire({
                    title: 'Receita selecionada não está vigente. Confirma associação com a conta corrente?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'SIM',
                    cancelButtonText: 'NÃO'
                }).then((result) => {
                    if (result.value) {
                        this.adicionarReceita();
                        this.formGroupReceita.controls.receita.setErrors(null);
                    }
                });
            } else {
                this.adicionarReceita();
                // this.formGroupReceita.controls.receita.setErrors(null);
            }
        }
    }


    adicionarReceita() {
        this.formGroupReceita.value.dataFimVigencia = this.utilService.convertDateToSave(
            this.formGroupReceita.value.dataFimVigencia
        );

        if (this.formGroupReceita.value.dataFimVigencia === 'Invalid date') {
            this.formGroupReceita.value.dataFimVigencia = null;
        }

        this.formGroupReceita.value.dataInicioVigencia = this.utilService.convertDateToSave(
            this.formGroupReceita.value.dataInicioVigencia
        );
        this.formGroupReceita.value.situacao = this.formGroupReceita.value.situacao.label;

        this.configReceitaService.postContaReceita(this.formGroupReceita.value).subscribe(result => {
            swal.fire('Sucesso', 'Salvo com sucesso', 'success').then(
                () => {
                    this.getItemReceita();
                    this.formGroupReceita.reset();
                    this.receitaFormControl.situacao.setValue({value: 1, label: 'ATIVA'})
                    this.receitaFormControl.dataInicioVigencia.setValue(null);
                    this.primeiro = true;
                    this.submitted = false;
                    /**botão label dinamico atualizar salvar */
                    this.isEdit = false;
                    this.btnSalvarAtualizar = 'Adicionar Receita';
                }
            );
            this.outHabilitaSalvar.emit('CONFIG_RECEITA');
        }, (error) => {
            if (error.status === 412) {
                swal.fire({
                    title: 'Atenção',
                    text: `${error.error.message}`,
                    icon: 'warning',
                });
            } else if (error.status === 400) {
                const errors = error.error.errors;
                this.msgs1 = [];
                errors.forEach((e: { message: any }) => {
                    swal.fire(
                        'Erro de Validação',
                        e.message,
                        'error'
                    ).then((r) => {
                    });
                });
            }
        });
        this.primeiro = false;
    }

    getItemReceita() {
       if(this.configConta?.configContaId != null) {
        const data = JSON.parse(JSON.stringify(this.configContaReceitaSearchRequest));
        data.configContaId = this.configConta?.configContaId;
        this.configReceitaService
            .configReceitaService(data, this.pagination)
            .subscribe((response) => {
                this.configReceitaConta = response.content;
                this.pagination = response;
            },
            (erro) => {
                this.alertsUtil.handleError(erro);
            });
        }
    }

    convertDateBr(data) {
        return data == null ? '' : moment(data).format('DD/MM/YYYY');
    }

    onPageChange(e) {
        this.pagination.pageable.pageNumber = e.page;
        this.getItemReceita();
    }

    removeConfirm(id: string, configContaReceita: any) {
        swal.fire({
            title: 'Confirmação',
            html: `Deseja ${configContaReceita.situacao == 'ATIVA' ? 'inativar' : 'ativar'} a receita <b>${configContaReceita?.receita?.codReceita} - ${configContaReceita?.receita?.nomeReceita}</b> ?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                this.ativarInativarItemCalculo(id);
            }
        });
    }

    removeConfigContaReceitaConfirm(id: string, configContaReceita: any) {
        let msgConfirm;
        if(configContaReceita.configContaReceitaId && this.configConta.implantado =='NAO'){
            msgConfirm = 'excluir';
        } 
       
        swal.fire({
            title: 'Confirmação',
            html: `Deseja ${msgConfirm} a receita <b>${configContaReceita?.receita?.codReceita} - ${configContaReceita?.receita?.nomeReceita}</b> ?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                this.remove(id);
            }
        });
    }

    ativarInativarItemCalculo(id: string) {
        this.configReceitaService
            .ativaInativa(id)
            .subscribe((resp) => {
                swal.fire('Sucesso', 'Atualizado com sucesso', 'success').then(
                    () => {
                        this.getItemReceita();
                        this.submitted = false;                        
                    }
                );
            });
    }

    remove(id: string) {
        this.configReceitaService
            .remove(id)
            .subscribe((resp) => {
                swal.fire('Sucesso', 'Removido com sucesso', 'success').then(
                    () => {
                        this.getItemReceita();
                        this.submitted = false;
                    }
                );
            },erro =>{
                this.alertsUtil.handleError(erro)
            });
    }

    editarItemReceita(obj: any) {
        this.isEdit = true;
        this.icone = 'pi pi-pencil';
        this.btnSalvarAtualizar = 'Atualizar Receita';

        this.receitaFormControl.dataInicioVigencia.setValue(
            this.convertDateBr(obj.dataInicioVigencia)
        );
        this.receitaFormControl.dataFimVigencia.setValue(
            this.convertDateBr(obj.dataFimVigencia)
        );

        if (moment(this.receitaFormControl.dataInicioVigencia.value, 'DD/MM/YYYY') < moment(this.configConta.dataInicioVigencia, 'DD/MM/YYYY')) {
            this.disableDataInicioVigencia = true;
        }

        this.receitaFormControl.situacao.setValue(
            {value: obj.situacao === 'ATIVA' ? 2 : 1, label: obj.situacao}
        );

        this.receitaFormControl.receita.setValue(
            {...obj.receita,...{displayLabel: `${obj.receita.codReceita} - ${obj.receita.nomeReceita}`}}
        );
        this.receitaSelected = this.receitaFormControl.receita.value;
        this.receitaFormControl.configContaReceitaId.setValue(
            obj.configContaReceitaId
        );
    }

    cancelarEdicao() {
        this.isEdit = false;
        this.icone = 'pi pi-plus-circle';
        this.btnSalvarAtualizar = 'Adicionar Receita';
        this.formGroupReceita.reset();
        this.formGroupReceita.get('situacao').setValue({value: 1, label: 'ATIVA'});
        this.disableDataInicioVigencia = false;
    }

}
