<div class="card">
    <p-toast></p-toast>
    <p-steps [model]="items" [readonly]="true"></p-steps>
</div>

<div class="stepsdemo-content">
    <p-card>
        <ng-template pTemplate="title">
            Composição da Conta
        </ng-template>

        <ng-template pTemplate="header">
            <div class="grid grid-nogutter justify-content-end" [style]="{padding: '10px'}">
                <p-button label="Anterior" (onClick)="prevPage()" icon="pi pi-angle-left"></p-button>
                <p-button class="float-btn-next" label="Próxima" (onClick)="nextPage()" icon="pi pi-angle-right" iconPos="right"></p-button>
            </div>
        </ng-template>

        <ng-template pTemplate="content">
            <div class="stepsdemo-content">
                <p-card>
                    <ng-template pTemplate="subtitle">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12">
                                <app-identificacao-conta [configConta]="configConta"></app-identificacao-conta>
                            </div>

                            <div class="p-field p-col-8">
                                <label>Item de Cálculo*</label>
                                <input #itemCalculoNome="ngModel" [required]="submitted" type="text" pInputText
                                        [(ngModel)]="objComposicao.itemCalculoNome"
                                        maxlength="150"
                                        [disabled]="!isReaderFieldSituacao()"/>
                                <small *ngIf="itemCalculoNome.invalid && submitted" class="p-error">Campo obrigatório</small>
                            </div>
                            <div class="p-field p-col-2">
                                <label>Sigla*</label>
                                <input  #itemCalculoSigla="ngModel" [required]="submitted" type="text" name="itemCalculoSigla"  pInputText autocomplete="nope"
                                    [(ngModel)]="objComposicao.itemCalculoSigla" 
                                    maxlength="20"
                                    [disabled]="!isReaderFieldSituacao()"/>
                                <small *ngIf="itemCalculoSigla.invalid && submitted" class="p-error">Campo obrigatório</small>
                            </div>

                            <div class="p-field p-col-2">
                                <label>Natureza*</label>
                                <p-dropdown #naturezaItemCalculoId="ngModel" optionLabel="descricao" optionValue="naturezaItemCalculoId" [required]="submitted" showClear="true"
                                    [autoDisplayFirst]="false" [options]="naturezaItem" [(ngModel)]="objComposicao.naturezaItemCalculoId"
                                    [disabled]="!isReaderFieldSituacao()">
                                </p-dropdown>
                                <small *ngIf="naturezaItemCalculoId.invalid && submitted" class="p-error">Campo obrigatório</small>
                            </div>
                            <div class="p-field p-col-12">
                                <label>Comentário</label>
                                <textarea id="comentario" rows="4" cols="30" pInputTextarea [(ngModel)]="objComposicao.comentario" maxlength="2500"
                                [disabled]="!isReaderFieldSituacao()"></textarea>
                            </div>
                        </div>
                        <div>
                            <app-condition
                            #condition
                            [regraCalculo]="regraCalculo"
                            [groupArr]="groupArr"
                            (resposta)="receberResposta($event)"
                            (erroSqlOutput)="erroSqlOutput($event)"
                            >
                            </app-condition>
                        </div>


                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-2">
                                <label>Situação*</label>
                                <p-dropdown #situacao="ngModel" optionLabel="valor" optionValue="chave" inputId="dropdown" showClear="true" [options]="situacaoOptions"
                                    [(ngModel)]="objComposicao.situacao" name="situacao" required
                                    [disabled]="!configConta?.configContaId || configConta.implantado === 'NAO' || !habilitaCancelar">
                                </p-dropdown>
                                <small *ngIf="(situacao.invalid && submitted)" class="p-error">Campo obrigatório</small>
                            </div>
                            <div class="p-field p-col-2">
                                <label>Início da Vigência*</label>
                                <p-calendar
                                    #dataInicioVigencia="ngModel"
                                    [showIcon]="true"
                                    inputId="icon"
                                    dateFormat="dd/mm/yy"
                                    name="dataInicioVigencia"
                                    [required]="submitted"
                                    [(ngModel)]="objComposicao.dataInicioVigencia"
                                    (ngModelChange)="onChangeDataInicioVigencia($event)"
                                    [disabled]="configConta.implantado === 'SIM' && habilitaCancelar"
                                ></p-calendar>
                                <small *ngIf="dataInicioVigencia.invalid && submitted" class="p-error">Campo obrigatório</small>
                            </div>
                            <div class="p-field p-col-2">
                                <label>Fim da Vigência</label>
                                <p-calendar [showIcon]="true" inputId="icon" dateFormat="dd/mm/yy" name="dataFimVigencia"
                                    [(ngModel)]="objComposicao.dataFimVigencia">
                                </p-calendar>
                            </div>
                        </div>
                        <div class="p-field p-col-12 p-md-12" style="margin-top: 10px; text-align: right">
                            <button [disabled]="!configConta.configContaId" pButton type="button" [icon]="this.icone" class="p-mr-1" [label]="btnSalvarAtualizar"
                                (click)="salvar()"></button>
                            <button *ngIf="habilitaCancelar" pButton type="button" label="Cancelar" icon="pi pi-times"
                                class="p-button-secondary" (click)="cancelarEdicao()"></button>
                        </div>
                        <div class="p-field p-col-12">
                            <p-table [value]="configComposicaoConta">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>Item de Cálculo*</th>
                                        <th>Sigla</th>
                                        <th>Natureza*</th>
                                        <th>Situação</th>
                                        <th>Início Vigência</th>
                                        <th>Fim Vigência</th>
                                        <th>Ações</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-configComposicaoConta>
                                    <tr>
                                        <td>{{configComposicaoConta.itemCalculoNome}}</td>
                                        <td>{{configComposicaoConta.itemCalculoSigla}}</td>
                                        <td>{{configComposicaoConta.naturezaItemCalculo.descricao}}</td>
                                        <td>{{configComposicaoConta.situacao}}</td>
                                        <td>{{convertDateBr(configComposicaoConta.dataInicioVigencia)}}</td>
                                        <td>{{convertDateBr(configComposicaoConta.dataFimVigencia)}}</td>
                                        <td>
                                            <span>
                                                <button pButton pRipple title="Editar" type="button" icon="pi pi-pencil"
                                                    class="p-button-rounded"
                                                    (click)="editarItemCaluculo(configComposicaoConta)"></button>
                                            </span>
                                            <button *ngIf="configConta.implantado === 'SIM' && configComposicaoConta.situacao === 'INATIVA'" pButton pRipple title="Ativar"
                                                type="button" icon="pi pi-check" class="p-button-rounded p-button-secondary"
                                                (click)="ativarInativarItemCalculo(configComposicaoConta)"></button>
                                            <button *ngIf="configConta.implantado === 'SIM' && configComposicaoConta.situacao === 'ATIVA'" pButton pRipple title="Inativar"
                                                    type="button" icon="pi pi-times" class="p-button-rounded p-button-secondary"
                                                    (click)="ativarInativarItemCalculo(configComposicaoConta)"></button>
                                            <button *ngIf="configConta.implantado !== 'SIM'" pButton pRipple title="Excluir"
                                                    type="button" icon="pi pi-trash" class="p-button-rounded p-button-danger"
                                                    (click)="ativarInativarItemCalculo(configComposicaoConta)"></button>
                                                </td>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="12" style="text-align: center;">
                                            Nenhum registro localizado
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                            <p-paginator [rows]="10" [totalRecords]="pagination.totalElements" (onPageChange)="onPageChange($event)">
                            </p-paginator>
                        </div>
                    </ng-template>
                </p-card>
            </div>
        </ng-template>

        <ng-template pTemplate="footer">
            <div class="grid grid-nogutter justify-content-end">
                <p-button label="Anterior" (onClick)="prevPage()" icon="pi pi-angle-left"></p-button>
                <p-button class="float-btn-next" label="Próxima" (onClick)="nextPage()" icon="pi pi-angle-right" iconPos="right"></p-button>
            </div>
        </ng-template>
    </p-card>
</div>
