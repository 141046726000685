<p-dialog
    header="Contas Associadas"
    [(visible)]="visible"
    [modal]="true"
    [style]="{ width: '90vw' }"
    [maximizable]="true"
    [baseZIndex]="10000"
    [draggable]="false"
    [resizable]="false"
>
    <div class="p-fluid p-formgrid p-grid">
        <div *ngFor="let tc of contasView" style="padding: 3px;">
            <div class="p-field-radiobutton">
                <p-radioButton 
                [name]="tc.chave"
                [inputId]="tc.chave"
                [value]="tc.valor"
                [(ngModel)]="tipoContaView"
                ></p-radioButton>
                <label style="cursor: pointer" [for]="tc.chave">{{tc.chave}}</label>
            </div>
        </div>
    </div>
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-2">
            <label>Código do Cadastro</label>
            <input
            disabled
            maxlength="20"
            showClear="true"
            pInputText
            [value]="objTpCadastro?.tpCadastroValue"
        />
        </div>
        <div class="p-field p-col-12 p-md-2">
            <label>Conta</label>
                <p-multiSelect
                        [options]="contaList"
                        optionLabel="nomeCompleto"
                        optionValue="configContaId"
                        placeholder="Selecione"
                        display="chip"
                        resetFilterOnHide="true"
                        showClear="true"
                        [(ngModel)]="contaValue"
                ></p-multiSelect>
        </div>
        <div class="p-field p-col-12 p-md-2">
            <label>Número Conta <span *ngIf="contaValue">*</span></label>
                <input
                    [disabled]="!contaValue"
                    maxlength="20"
                    placeholder="Informe o número"
                    showClear="true"
                    [(ngModel)]="numeroContaValue"
                    pInputText
                />
        </div>
        <div class="p-field p-col-12 p-md-2">
            <label>Tipo de Documento</label>
                <p-dropdown
                    [options]="tipoDocumentoList"
                    optionLabel="descricao"
                    optionValue="tipoNumeroDocumentoId"
                    placeholder="Selecione"
                    showClear="true"
                    resetFilterOnHide="true"
                    [autoDisplayFirst]="true"
                    [(ngModel)]="tipoNumeroDocumentoValue"
                    (onChange)="tipoDocumentoChange()"
                ></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-2">
            <label>Número Documento <span *ngIf="tipoNumeroDocumentoValue">*</span></label>
                <input
                    [disabled]="!tipoNumeroDocumentoValue"
                    maxlength="20"
                    placeholder="Informe o número"
                    showClear="true"
                    [(ngModel)]="numeroDocumentoValue"
                    pInputText
                />
        </div>
        <div class="p-field p-col-12 p-md-2">
            <label title="dataInicio">Período de Referência Inicial *</label>
            <p-calendar
                view="month"
                dateFormat="mm/yy"
                [showIcon]="true"
                placeholder="Informe periodo inicial"
                [(ngModel)]="dataInicialReferenciaValue"
            ></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-2">
            <label >Período de Referência Final *</label>
            <p-calendar
                view="month"
                appendTo="body"
                dateFormat="mm/yy"
                [showIcon]="true"
                placeholder="Informe o periodo final"
                [(ngModel)]="dataFinalReferenciaValue"
            >
            </p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-2 top-btn-line">
            <button
            pButton
            type="button"
            icon="pi pi-search"
            label="Pesquisar"
            class="p-mr-1 p-text-left"
            [disabled]="!dataInicialReferenciaValue || !dataFinalReferenciaValue"
            (click)="filtroConta()"
            ></button>
        </div>
        <div class="p-field p-col-12 p-md-2 top-btn-line">
            <button
            pButton
            type="button"
            icon="pi pi-trash"
            label="Limpar"
            class="p-mr-1 p-button-danger"
            (click)="limparFiltros()"
            ></button>
        </div>
    </div>

    <p-table
        [value]="pagination.content"
        [(selection)]="contasSelecionadas"
        [scrollable]="true"
    >
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 2rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th pSortableColumn="configContaId">
                    Conta <p-sortIcon field="configContaId"></p-sortIcon>
                </th>
                <th pSortableColumn="tipoCadastroId">
                    Tipo Cadastro <p-sortIcon field="tipoCadastroId"></p-sortIcon>
                </th>
                <th pSortableColumn="codigoCadastro">
                    Código Cadastro
                    <p-sortIcon field="codigoCadastro"></p-sortIcon>
                </th>
                <th pSortableColumn="nomeResponsavel">
                    Contribuinte
                    <p-sortIcon field="nomeResponsavel"></p-sortIcon>
                </th>
                <th pSortableColumn="numeroDocumento">
                    Número Documento
                    <p-sortIcon field="numeroDocumento"></p-sortIcon>
                </th>
                <th pSortableColumn="periodoReferencia">
                    Período Referência
                    <p-sortIcon field="periodoReferencia"></p-sortIcon>
                </th>
                <th pSortableColumn="parcela">
                    Cota/Pacela
                    <p-sortIcon field="parcela"></p-sortIcon>
                </th>
                <th pSortableColumn="dataVencimento">
                    Vencimento
                    <p-sortIcon field="dataVencimento"></p-sortIcon>
                </th>
                <th pSortableColumn="estadoContaDesc">
                    Estado da Conta
                    <p-sortIcon field="estadoContaDesc"></p-sortIcon>
                </th>
                <th pSortableColumn="saldoContaDesc">
                    Saldo da Conta
                    <p-sortIcon field="saldoContaDesc"></p-sortIcon>
                </th>
                <th pSortableColumn="situacaoCreditoDesc">
                    Situação Crédito
                    <p-sortIcon field="situacaoCreditoDesc"></p-sortIcon>
                </th>
                <th pSortableColumn="situacaoCobrancaDesc">
                    Situação Cobrança
                    <p-sortIcon field="situacaoCobrancaDesc"></p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr class="selecao">
                <td style="width: 2rem">
                    <p-tableCheckbox [value]="item"></p-tableCheckbox>
                </td>
                <td>{{ item?.configContaId }}</td>
                <td>{{ item?.tipoCadastroId }}</td>
                <td>{{ item?.codigoCadastro }}</td>
                <td>{{ item?.nomeResponsavel }}</td>
                <td>{{ item?.numeroDocumento }}</td>
                <td>{{ item?.periodoReferencia }}</td>
                <td>{{ item?.contaCorrenteCotaUnicaId ? item?.contaCorrenteCotaUnicaId : '-' }}/{{ item?.parcela }}</td>
                <td>{{ item?.dataVencimento | date:'dd/MM/yyyy' }}</td>
                <td>{{ item?.estadoContaDesc }}</td>
                <td>{{ item?.saldoContaDesc }}</td>
                <td>{{ item?.situacaoCreditoDesc }}</td>
                <td>{{ item?.situacaoCobrancaDesc }}</td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td class="p-text-center">
                    <div style="margin-left: 46%">
                        Nenhum registro encontrado
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <p-paginator
        [rows]="10"
        [rowsPerPageOptions]="[10, 50, 100]"
        [totalRecords]="pagination.totalElements"
        (onPageChange)="onPageChange($event)"
    ></p-paginator>

    <div class="p-mt-3" style="text-align: right">
        <button
            pButton
            label="Cancelar"
            icon="pi pi-close-circle"
            class="p-button-outlined p-mr-2"
            (click)="close()"
        ></button>
        <button
            pButton
            class="float-btn-next"
            label="Adicionar"
            icon="pi pi-plus-circle"
            (click)="adicionaConta()"
        ></button>
    </div>
</p-dialog>

