<div class="p-grid">
    <div class="p-col-12" *ngIf="implantado">
        <p-messages severity="info">
            <ng-template pTemplate>
                <div class="ml-2">
                    Não é permitido editar as transações um evento implantado.
                </div>
            </ng-template>
        </p-messages>
    </div>
    <div class="p-col-12" *ngIf="pulaEtapa">
        <p-messages severity="info">
            <ng-template pTemplate>
                <div class="ml-2">
                    Não existe(m) transação(ões) para Evento de {{evento.descricao}}.
                </div>
            </ng-template>
        </p-messages>
    </div>
    <div class="p-col-12">
        <form [formGroup]="transacoesForm">
            <div class="p-grid">
                <div class="p-col-9 p-fluid">
                    <label>Tipo da Imputação da Transação*</label>
                    <p-dropdown
                        [options]="tipoImputacaoList"
                        formControlName="tipoImputacao"
                        optionValue="chave"
                        optionLabel="valor"
                        [showClear]="true"
                        placeholder="Selecione uma opção"
                    ></p-dropdown>
                    <p
                        class="erro-validacao"
                        *ngIf="
                            (transacoesForm.controls.tipoImputacao.touched ||
                                validarCampos) &&
                            transacoesForm.controls.tipoImputacao.errors
                                ?.required
                        "
                    >
                        Campo obrigatório
                    </p>
                </div>
                <div
                    class="p-col-3 p-fluid"
                    *ngIf="
                        transacoesForm.value.tipoImputacao === 'VARIAVEL_LINEAR'
                    "
                >
                    <label>Regra de Imputação*</label>
                    <p-dropdown
                        [options]="regraImputacaoList"
                        formControlName="idRegraImputacao"
                        optionValue="id"
                        optionLabel="descricao"
                        [showClear]="true"
                        placeholder="Selecione uma opção"
                    ></p-dropdown>
                    <p
                        class="erro-validacao"
                        *ngIf="
                            (transacoesForm.controls.idRegraImputacao.touched ||
                                validarCampos) &&
                            transacoesForm.controls.idRegraImputacao.errors
                                ?.required
                        "
                    >
                        Campo obrigatório
                    </p>
                </div>
            </div>
        </form>
    </div>
    <div class="p-col-11 p-fluid">
        <label>Transações*</label>
        <p-dropdown
            [options]="transacoesList"
            [(ngModel)]="transacaoSelecionada"
            optionValue="transacaoId"
            optionLabel="nomeCompleto"
            [showClear]="true"
            placeholder="Selecione uma opção"
        ></p-dropdown>
    </div>
    <div class="p-col-1 p-fluid">
        <label><br /></label>
        <button
            pButton
            pRipple
            type="button"
            icon="pi pi-plus"
            class="p-button-rounded"
            (click)="adicionarTransacao()"
            [disabled]="!transacaoSelecionada || implantado"
        ></button>
    </div>
    <div class="p-col-12">
        <h6>Lista de Transações</h6>
        <p-table [value]="listaTranscoes">
            <ng-template pTemplate="header">
                <tr>
                    <th>Nome</th>
                    <th>Código</th>
                    <th>Tipo de Operação</th>
                    <th>Tipo de Processamento</th>
                    <th *ngIf="!implantado">Ação</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-transacao>
                <tr>
                    <td>
                        {{ transacao.nomeCompleto || "-" }}
                    </td>
                    <td>
                        {{ transacao.transacaoId || "-" }}
                    </td>
                    <td>
                        {{ transacao.operacaoDescricao || "-" }}
                    </td>
                    <td>
                        {{ transacao.tipoTransacaoDescricao || "-" }}
                    </td>
                    <td *ngIf="!implantado">
                        <button
                            pButton
                            pRipple
                            type="button"
                            icon="pi pi-trash"
                            class="p-button-rounded p-button-danger"
                            (click)="excluirTransacao(transacao.transacaoId)"
                        ></button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td class="p-text-center" colspan="10">-</td>
                </tr>
            </ng-template>
        </p-table>
        <p
            class="erro-validacao"
            *ngIf="
                (transacoesForm.controls.listaTransacoes.touched ||
                    validarCampos) &&
                transacoesForm.controls.listaTransacoes.errors?.required
            "
        >
            Campo obrigatório
        </p>
    </div>
</div>
