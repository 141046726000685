import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EventoEstado } from 'src/app/models/estado-conta';
import { environment } from 'src/environments/environment';
import { Pageable } from '../../shared/pagination/pageable';
import { Pagination } from '../../shared/pagination/pagination';

@Injectable({
  providedIn: 'root'
})
export class EventosEstadoService {

  constructor(private http: HttpClient) { }

  getEventoEstado(objParams, pagination: Pagination<any>): Observable<Pageable<any>> {
    const params = new HttpParams({ fromObject: objParams })
    return this.http.get<Pageable<any>>(`${environment.baseUrl}/evento-estado/search`, {
      params: {
        ...objParams,
        page: pagination.pageable.pageNumber,
        size: pagination.size
      }
    });
  }

  putAtivaInativa(eventoEstadoId: any): Observable<void> {
    return this.http.put<void>(`${environment.baseUrl}/evento-estado/${eventoEstadoId}/ativa-inativa`, {});
  }

  putAtivaInativaPerfil(eventoEstadoId: any): Observable<void> {
    return this.http.put<void>(`${environment.baseUrl}/evento-estado-perfil/${eventoEstadoId}/ativa-inativa`, {});
  }

  getPerfisByEventoEstadoId(eventoEstadoId): Observable<any[]> {
    return this.http.get<any[]>(`${environment.baseUrl}/evento-estado-perfil/by-evento-estado/${eventoEstadoId}`);
  }

  getEventoEstadoById(id): Observable<EventoEstado> {
    return this.http.get<EventoEstado>(`${environment.baseUrl}/evento-estado/${id}`);
  }
  
  listByPerfilId(eventoEstadoId): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/evento-estado-perfil/by-evento-estado/${eventoEstadoId}`);
  }

  getEstadoAntesId(eventoEstadoId): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/evento-estado-antes/evento-estado/${eventoEstadoId}`);
  }

  getParamInteresseId(eventoEstadoId): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/param-interesse/evento-estado/${eventoEstadoId}`);
  }

  deletePerfil(eventoEstadoPerfilId): Observable<void> {
    return this.http.delete<void>(
        `${environment.baseUrl}/evento-estado-perfil/${eventoEstadoPerfilId}`);
  }

  deleteEventoEstado(eventoEstadoId: any): Observable<void> {
    return this.http.delete<void>(`${environment.baseUrl}/evento-estado/${eventoEstadoId}`);
  }

  deleteEventoEstadoAntes(eventoEstadoAntesId: any): Observable<void> {
    return this.http.delete<void>(`${environment.baseUrl}/evento-estado-antes/${eventoEstadoAntesId}`);
  }

  deleteParamInteresse(paramInteresseId: any): Observable<void> {
    return this.http.delete<void>(`${environment.baseUrl}/param-interesse/${paramInteresseId}`);
  }

}
