export class FiltroConta {

    tipoDocumentoContribuinte: string = undefined;
    numeroDocumentoContribuinte: string = undefined;
    configConta: { id: string, descricao: string } = { id: undefined, descricao: undefined };
    estadoConta: { id: string, descricao: string } = { id: undefined, descricao: undefined };
    situacaoCreditoConta: { id: string, descricao: string } = { id: undefined, descricao: undefined };
    saldoConta: { id: string, descricao: string } = { id: undefined, descricao: undefined };
    configEvento: { id: string, descricao: string } = { id: undefined, descricao: undefined };
    situacaoCobranca: { id: string, descricao: string } = { id: undefined, descricao: undefined };
    dataReferenciaInicial: Date = undefined;
    dataReferenciaFinal: Date = undefined;
    page: number = undefined;
    size: number = undefined;

}
