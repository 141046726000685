import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TransacaoService } from "../../transacao-financeira/services/transacao.service";
import swal from "sweetalert2";
import { UtilsService } from "src/app/shared/utils.service";
import { PerfisAcessoService } from "../../transacao-financeira/services/perfis-acesso.service";
import * as moment from "moment";
import { EnumsService } from "src/app/core/enums.service";
import { EstadoConta, EstadoContaDepois, EstadoEventoSaveRequest, EventoEstado, EventoEstadoId } from "src/app/models/estado-conta";
import { EstadoContaService } from "src/app/component/estado-conta/estado-conta.service";
import { EventosSearchRequest } from "src/app/request/eventos-search-request";
import { AlertsUtil } from "src/app/utils/alerts/alerts.util";
import { EventosEstadoService } from "../evento-estado.service";
import { ThisReceiver } from "@angular/compiler";

@Component({
    selector: "app-config-evento-estado",
    templateUrl: "./config-evento-estado.component.html",
    styleUrls: ["./config-evento-estado.component.scss"],
})
export class ConfigEventoEstadoComponent implements OnInit {
    // eventoEstadoId: any = 1;
    perfisList: any[] = [];
    estadoAntesList: any[] = [];
    parametroInteresseList: any[] = [];
    estadosComboList: any[];
    perfisComboList: any[];
    eventosComboList: any[];
    atributoComboList: any[];
    situacaoComboList: any[];
    estadoComboAntesList: any[];
    estadoComboDepoisList: any[];
    processamentoComboList: any[];
    estadoForm: FormGroup;
    eventosSearchRequest: EventosSearchRequest;
    estadoEventoRequest: EstadoEventoSaveRequest;
    estadoContaId: EstadoConta;
    eventoEstadoId: any;
    estadoEvento: EventoEstado;
    eventoEstadoAny: any;
    deleteParamsInteresse: boolean;
    selectedContaDepois: number;

    constructor(
        private activatedRouter: ActivatedRoute,
        private perfisAcessoService: PerfisAcessoService,
        private estadoContaService: EstadoContaService,
        private eventosEstadoService: EventosEstadoService,
        public transacaoService: TransacaoService,
        private enumsService: EnumsService,
        private fb: FormBuilder,
        private util: UtilsService,
        private router: Router,
        private alertsUtil: AlertsUtil

    ) {}

    get estadoControls() {
        return this.estadoForm ? this.estadoForm.controls : null;
    }

    ngOnInit(): void {
        this.getEnums();

        this.estadoContaId = new EstadoConta();
        this.eventoEstadoId = new EventoEstadoId();
        this.eventosSearchRequest = new EventosSearchRequest();
        this.estadoEventoRequest = new EstadoEventoSaveRequest();

        this.estadoEventoRequest.eventoEstado = new EventoEstado();
        // this.estadoEventoRequest.eventoEstado.eventos = new Eventos();
        this.estadoEventoRequest.eventoEstado.estadoConta = new EstadoContaDepois();
        // this.estadoEventoRequest.paramInteresse = new ParamInteresse();

        this.activatedRouter.params.subscribe((objParametro) => {
            if (objParametro.id) {
                let eventoEstadoId = objParametro.id;
                this.eventoEstadoId = parseInt(eventoEstadoId);
                this.getEventoEstadoById(this.eventoEstadoId);
                this.listByPerfilId(this.eventoEstadoId);
                this.getEstadoAntesId(this.eventoEstadoId);
                this.getParamInteresseId(this.eventoEstadoId);
            } else {
                this.eventoEstadoId = null;
                this.criarFormulario(this.criaEstadoEmbranco());
            this.estadoControls.dataCadastro.setValue(new Date());
            }
        });
    }

    getEventoEstadoById(id){
        this.eventosEstadoService.getEventoEstadoById(id).subscribe(
            (resp: EventoEstado) => {
                this.estadoEvento = resp
                this.selectedContaDepois = this.estadoEvento.estadoConta.estadoContaId;
                this.criarFormulario(resp);
            }
        )
    }


    ativaInativaPerfil(perfil){
        let eventoEstadoPerfilId = perfil.eventoEstadoPerfilId;
        let ativaInativa = perfil.situacao === 'ATIVA' ? 'Desativar' : 'Ativar' 
        swal.fire({
            title: "Aviso",
            text: `Deseja ${ativaInativa} a conta ${eventoEstadoPerfilId}.`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "SIM",
            cancelButtonText: "NÃO"
        }).then((result) => {
            if (result.isConfirmed) {
                this.putAtivaInativaPerfil(eventoEstadoPerfilId);
                setTimeout(() => {
                    this.listByPerfilId(this.eventoEstadoId, perfil);
                }, 800);
            }
        }); 
    }

    putAtivaInativaPerfil(eventoEstadoPerfilId){
        this.eventosEstadoService.putAtivaInativaPerfil(eventoEstadoPerfilId).subscribe(
            (resp ) => {
                swal.fire("Sucesso", "Atualizado com sucesso", "success").then(
                    () => {

                    }
                );
            }
        )
    }

    submit(): void {
        this.estadoForm.markAllAsTouched();
        if (this.estadoForm.invalid) {
            return;
        }
        this.salvar(this.prepareObjSave());
    }

    prepareObjSave(){

        /**objSave */
        this.estadoEventoRequest.eventoEstado.eventoEstadoId = this.eventoEstadoId ? this.eventoEstadoId : 0;
        this.estadoEventoRequest.eventoEstado.estadoConta.estadoContaId = this.estadoEvento ? this.estadoEvento.estadoConta.estadoContaId : 1;
       
        /**Tornar dinamico */
        // this.estadoEventoRequest.eventoEstado.eventos.eventosId = 1;
        this.estadoEventoRequest.eventoEstado.descricao = this.estadoControls?.descricao.value;
        this.estadoEventoRequest.eventoEstado.estadoConta.estadoContaId = this.estadoControls?.estadoDepois.value;
        this.estadoEventoRequest.eventoEstado.tipoEvento = this.estadoControls.tipoEvento.value;
        this.estadoEventoRequest.eventoEstado.tipoProcessamento = this.estadoControls.tipoProcessamento.value;
        this.estadoEventoRequest.eventoEstado.situacao = this.estadoControls.situacao.value;
        this.estadoEventoRequest.eventoEstado.interrompePrazo = this.estadoControls.interrompePrazo.value;
        this.estadoEventoRequest.eventoEstado.dataInicioVigencia = this.estadoControls.dataInicioVigencia.value;
        this.estadoEventoRequest.eventoEstado.dataFimVigencia = this.estadoControls.dataFimVigencia.value;
        this.estadoEventoRequest.eventoEstado.dataCadastro = this.estadoControls.dataCadastro.value;
        
        const objAntesSave = [];
        const objEstadoPerflSave = [];
        const objParametroInteresseSave = [];

        /**objAntesSave */
        let estadoConta = new EstadoConta();
        let eventoEstadoAntesId = 0
        
        this.estadoAntesList.forEach((list) => {
            // eventoEstado.eventoEstadoId = list.eventoEstadoId;
            estadoConta.estadoContaId = this.estadoControls?.estadoAntes.value?.estadoContaId;

            const objAux = {
                estadoConta: {estadoContaId: list.estadoContaId ? list.estadoContaId : list.estadoConta.estadoContaId},
                eventoEstado: {eventoEstadoId: this.eventoEstadoId ? this.eventoEstadoId : 0},
                eventoEstadoAntesId: list.eventoEstadoAntesId ? list?.eventoEstadoAntesId : eventoEstadoAntesId,
            }
            objAntesSave.push(objAux)
        });
        this.estadoEventoRequest.eventoEstadoAntes = objAntesSave;
        
        /**objEstadoPerfilSave */
        this.perfisList.forEach((list) => {
            let eventoEstadoPerfilId = list.eventoEstadoPerfilId ? list.eventoEstadoPerfilId : 0;
            const objAux = {
                codigoPerfil: list.codigoPerfil,
                dataFimAssociacao: list.dataFimAssociacao,
                dataInicioAssociacao: list.dataInicioAssociacao,
                eventoEstado: {eventoEstadoId: this.eventoEstadoId ? this.eventoEstadoId : 0},
                eventoEstadoPerfilId: eventoEstadoPerfilId,
                situacao: list.situacao
            }
            objEstadoPerflSave.push(objAux)
        });
        this.estadoEventoRequest.eventoEstadoPerfil = objEstadoPerflSave;

        /**objParametroInteresseSave */
        this.parametroInteresseList.forEach(list => {
            // atributo.atributoId = list.atributoId
            const objAux = {
                atributo: {atributoId: list.atributo?.atributoId ? list.atributo?.atributoId : list?.atributoId}, 
                eventoEstado: {eventoEstadoId: this.eventoEstadoId ? this.eventoEstadoId : 0 },
                obrigatorio: list.obrigatorio,
                paramInteresseId: list.paramInteresseId ? list.paramInteresseId : 0
            }
            objParametroInteresseSave.push(objAux);
        });
        this.estadoEventoRequest.paramInteresse = objParametroInteresseSave;
        return this.estadoEventoRequest;
    }

    criarFormulario(estados: any): void {
        this.estadoForm = this.fb.group({
            tipoEvento: [estados?.tipoEvento, Validators.required],
            // descricao: [estados.descricao,Validators.required, Validators.maxLength(50)],
            descricao: [estados.descricao,[Validators.required, Validators.maxLength(50)]],
            tipoProcessamento: [estados?.tipoProcessamento, Validators.required],
            situacao: [estados?.situacao],
            dataInicioVigencia: [estados?.dataInicioVigencia ? new Date(estados?.dataInicioVigencia) : null, Validators.required],
            dataFimVigencia: [estados?.dataFimVigencia ? new Date(estados?.dataFimVigencia) : null],
            dataCadastro: [estados?.dataCadastro ? new Date(estados?.dataCadastro) : null],
            interrompePrazo: [estados?.interrompePrazo],
            estadoDepois: [estados?.estadoConta?.estadoContaId, Validators.required],
            estadoAntes: [null],
            perfilVisualizacao: [null],
            atributo: [null],
            obrigatorio: [null],

        });
    }

    private criaEstadoEmbranco(): EventoEstado {  
        return {
            tipoEvento: null,
            dataInicioVigencia: null,
            dataFimVigencia: null,
            situacao: "ATIVA",
            interrompePrazo: 'NAO',
            tipoProcessamento: null,
        } as EventoEstado;
    }

    salvar(estadoEventoRequest: EstadoEventoSaveRequest) {
        const data = JSON.parse(JSON.stringify(estadoEventoRequest));
        data.eventoEstado.dataInicioVigencia = (data.eventoEstado.dataInicioVigencia ? this.util.convertDateUsToSave(data.eventoEstado.dataInicioVigencia): '');
        data.eventoEstado.dataFimVigencia = (data.eventoEstado.dataFimVigencia ? this.util.convertDateUsToSave(data.eventoEstado.dataFimVigencia): '');
        data.eventoEstado.dataCadastro = (data.eventoEstado.dataCadastro ? this.util.convertDateUsToSave(data.eventoEstado.dataCadastro): '');
        data.eventoEstado.descricao = data.eventoEstado.descricao.toUpperCase();
        if(this.requiredEstadoAntes(estadoEventoRequest)){
            return;
        } 
        this.estadoContaService.postEventoEstado(data).subscribe(
            (resp: any) => {
                swal.fire("Sucesso", `O Evendo com Cod. ${resp.eventoEstadoId} foi salvo com sucesso`, "success").then(
                    () => {
                        this.router.navigateByUrl('/evento-estado/evento-estado-list');
                    }
                );
            }, (error) => {
                this.alertsUtil.handleError(error);
            }
        )
    }

    requiredEstadoAntes(evento: EstadoEventoSaveRequest): boolean {
       if(evento.eventoEstado.tipoEvento === 'MUDANCA_DE_ESTADO' && evento.eventoEstadoAntes.length === 0) {
            swal.fire(
                "Atenção",
                "É obrigatório adicionar ao menos um Estado Antes!",
                "warning"
            ).then((r) => {});
        return true;
       }
       return false;
    }

    async getEnums() {
      let nameSituacao = 'Situacao'
      let nameTipoProcessamento = 'TipoProcessamento'
      let nameTipoEvento = 'TipoEvento'

        const objParamsEventoEstado = {
            situacao: "ATIVA",
        };
        /**Verificar id Evento Manual */
        const objParamsAtributo = {
            tipoCategoriaTipoUso: 'EVENTO_DE_ESTADO_MANUAL',
        };
        this.atributoComboList = await this.estadoContaService
            .getAtributos(objParamsAtributo)
            .toPromise();
        this.perfisComboList = await this.perfisAcessoService
            .getPerfis()
            .toPromise();
        this.situacaoComboList = await this.enumsService
            .getEnumNome(nameSituacao)
            .toPromise();
        this.processamentoComboList = await this.enumsService
            .getEnumNome(nameTipoProcessamento)
            .toPromise();
        this.processamentoComboList = await this.enumsService
            .getEnumNome(nameTipoProcessamento)
            .toPromise();
        this.eventosComboList = await this.enumsService
            .getEnumNome(nameTipoEvento)
            .toPromise();
        this.situacaoComboList = this.situacaoComboList.reverse();
        this.estadosComboList = await this.estadoContaService
            .getEstadoConta(objParamsEventoEstado)
            .toPromise();
        this.estadoComboAntesList = this.estadosComboList;
        this.estadoComboDepoisList = this.estadosComboList;
        this.estadoControls?.atributo?.setValue(this.atributoComboList.length > 0 ? this.atributoComboList[0]?.atributoId : null);
        return new Promise((resolve) => {
            resolve("");
        });
    }

    eventosRequest() {
        const data = JSON.parse(JSON.stringify(this.eventosSearchRequest));
        data.tipoEventoDescricao = "Benefício Fiscal";
        return data;
    }

    onSelectDataMenor(event?) {
        let dataInicio = this.util.convertDateToSave(
            this.estadoControls.dataInicioVigencia.value
        );
        let dataFim = this.util.convertDateToSave(
            this.estadoControls.dataFimVigencia.value
        );

        if (dataFim < dataInicio) {
            swal.fire(
                "Erro Vigência",
                "A Data Fim não pode ser inferior a Data Inicio",
                "info"
            ).then((r) => {
                this.estadoControls.dataFimVigencia.setValue(null);
            });
        }
        if (dataInicio) {
            this.calculaInterValoDatas(dataInicio);
        }
    }

    calculaInterValoDatas(dataInicio) {
        let dataAtual: any = this.util.convertDateToSave(new Date());

        const now = new Date(); // Data de hoje
        const past = new Date(dataInicio); // Outra data no passado
        const diff = Math.abs(now.getTime() - past.getTime()); // Subtrai uma data pela outra
        const days = Math.ceil(diff / (1000 * 60 * 60 * 24)); // Divide o total pelo total de milisegundos correspondentes a 1 dia. (1000 milisegundos = 1 segundo).

        if (days >= 365) {
            swal.fire(
                "Aviso",
                "A data de Inicio não pode ser inferior a 1 Ano",
                "info"
            ).then((r) => {
                this.estadoControls.dataFimVigencia.setValue(null);
            });
        }

        if (dataInicio > dataAtual && days > 30) {
            swal.fire(
                "Aviso",
                "A data de Inicio não pode ser superior a 1 Mês",
                "info"
            ).then((r) => {
                this.estadoControls.dataInicioVigencia.setValue(null);
            });
        }
    }

    adicionaEstadoAntes() {
        let estadoAntes = this.estadoControls.estadoAntes.value.estadoContaId;
        if (
            this.estadoAntesList.find((aux) => aux.estadoContaId == estadoAntes ||
                 aux.estadoConta?.estadoContaId == estadoAntes)
            ) {
            swal.fire({
                icon: "warning",
                title: "Atenção",
                text: `${this.estadoControls.estadoAntes.value.descricao}, já existe na lista!`,
                showConfirmButton: true,
            });
            return;
            
          } else if(this.estadoControls.estadoAntes.value.descricao === this.estadoControls.estadoDepois.value.descricao){
       
            return;
        }
        this.estadoAntesList = [
            ...this.estadoAntesList,
            {
                descricao: this.estadoControls.estadoAntes.value.descricao,
                eventoContaId: this.eventoEstadoId ? this.eventoEstadoId : 0,
                eventoEstadoAntesId: null,
                estadoContaId: this.estadoControls.estadoAntes.value.estadoContaId
            },
        ];
    }
    
    estadohange(ev){
        if(this.estadoControls.estadoAntes.value?.estadoContaId == this.estadoControls?.estadoDepois.value){
            swal.fire({
                icon: "warning",
                title: "Atenção",
                text: `Nenhum "Estado Antes" não pode ser igual ao "Estado Depois"`,
                showConfirmButton: true,
              });

              if(ev.estadoContaId){
                  this.estadoControls?.estadoDepois.setValue(null);
                } else {
                  this.estadoControls?.estadoAntes.setValue(null);
              }
        }
    }

    removerEstaoAntes(i, item) {
        let id = item?.eventoEstadoAntesId;
        if(id){
            swal.fire({
                title: "Aviso",
                text: `Deseja excluir o evento estado antes ${item.estadoConta.descricao}.`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "SIM",
                cancelButtonText: "NÃO"
            }).then((result) => {
                if (result.isConfirmed) {
                    this.eventosEstadoService.deleteEventoEstadoAntes(id).subscribe(
                        resp => {
                            swal.fire("Sucesso", "Removido com sucesso", "success").then(
                                () => {
                                    this.estadoAntesList.splice(i, 1);
                                }
                            );
                        }
                    ), erro =>{
                        this.alertsUtil.handleError(erro)
                    }
                }
            }); 
        } else {
            this.estadoAntesList.splice(i, 1);
        }
    }

    adicionaPerfilVizualizacao() {
        let codigoPerfil =
            this.estadoControls.perfilVisualizacao.value.codigoPerfil;
        if (this.perfisList.find((aux) => aux?.codigoPerfil == codigoPerfil || aux.configEvento?.codigoPerfil == codigoPerfil)) {
            swal.fire({
                icon: "warning",
                title: "Atenção",
                text: `O perfil com código ${codigoPerfil}, já existe na lista!`,
                showConfirmButton: true,
            });
            return;
        }
        this.perfisList = [
            ...this.perfisList,
            {
                codigoPerfil: codigoPerfil,
                descricao:
                    this.estadoControls.perfilVisualizacao.value.descricao,
                situacao: this.estadoControls.situacao.value,
                dataInicioAssociacao: moment(new Date(), "YYYY-MM-DD").format(
                    "YYYY-MM-DD"
                ),
                dataFimAssociacao: this.util.convertDateUsToSave(this.estadoControls.dataFimVigencia.value),
            },
        ];
        
    }

    removerPerfilVizualizacao(i, item) {
        let id = item?.eventoEstadoPerfilId;
        if(id){
            swal.fire({
                title: "Aviso",
                text: `Deseja excluir o perfil com código ${item.codigoPerfil}.`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "SIM",
                cancelButtonText: "NÃO"
            }).then((result) => {
                if (result.isConfirmed) {
                    this.eventosEstadoService.deletePerfil(id).subscribe(
                        resp => {
                            swal.fire("Sucesso", "Removido com sucesso", "success").then(
                                () => {
                                    this.perfisList.splice(i, 1);
                                }
                            );
                        }
                    ), erro =>{
                        this.alertsUtil.handleError(erro)
                    }
                }
            }); 
        } else {
            this.perfisList.splice(i, 1);
        }
    }

    adicionaParametroInteresse() {
      let atributoId =
        this.estadoControls.atributo.value.atributoId
        if (
            this.parametroInteresseList.find((aux) => aux.atributoId == atributoId)
        ) {
            swal.fire({
                icon: "warning",
                title: "Atenção",
                text: `O atributo com código ${atributoId}, já existe na lista!`,
                showConfirmButton: true,
            });
            return;
        }

        this.parametroInteresseList = [
            ...this.parametroInteresseList,
            {
                descricao: this.estadoControls.atributo.value.descricao,
                atributoId: this.estadoControls.atributo.value.atributoId,
                obrigatorio: this.estadoControls.obrigatorio.value,
                datatype: this.estadoControls.atributo.value.datatype,
            },
        ];
    }

    removerParametroInteresse(i, item) {
        let id = item?.paramInteresseId;
        if(id){
            swal.fire({
                title: "Aviso",
                text: `Deseja excluir o parâmetro de interesse ${item.atributo.descricao}.`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "SIM",
                cancelButtonText: "NÃO"
            }).then((result) => {
                if (result.isConfirmed) {
                    this.eventosEstadoService.deleteParamInteresse(id).subscribe(
                        resp => {
                            swal.fire("Sucesso", "Removido com sucesso", "success").then(
                                () => {
                                    this.parametroInteresseList.splice(i, 1);
                                }
                            );
                        }
                    ), erro =>{
                        this.alertsUtil.handleError(erro)
                    }
                }
            }); 
        } else {
            this.parametroInteresseList.splice(i, 1);
        }
    }

    listByPerfilId(eventoEstadoId, perfil?){
        this.eventosEstadoService.listByPerfilId(eventoEstadoId).subscribe(
            resp => {
                this.perfisList = resp;
                this.perfisList.forEach((elem, index) => {
                    if(elem.eventoEstadoPerfilId == perfil?.eventoEstadoPerfilId){
                    if(perfil.situacao == 'ATIVA'){
                        this.perfisList[index].dataFimAssociacao =  moment(new Date(), "YYYY-MM-DD").format(
                            "YYYY-MM-DD"
                        );
                    } else if(perfil.situacao == 'INATIVA') {
                        this.perfisList[index].dataFimAssociacao = null;
                    }
                    }
                });
            }
        )
    }
   

    getEstadoAntesId(eventoEstadoId){
        this.eventosEstadoService.getEstadoAntesId(eventoEstadoId).subscribe(
            resp => {
             this.estadoAntesList = resp;
            }
        )
    }

    getParamInteresseId(eventoEstadoId){
        this.eventosEstadoService.getParamInteresseId(eventoEstadoId).subscribe(
            resp => {
                this.parametroInteresseList = resp;
            }
        )
    }

    eventoDeEstadoChange(event) {
        if(event.value === 'CRIACAO_DE_CONTA' && this.estadoAntesList.length > 0){
            this.alertsUtil.confirm(`Deseja alterar o campo Tipo Evento de Estado? 
            Os dados informados no campo Estado Antes serão perdidos ao salvar`).then((result) => {
                if (!result.isConfirmed) {
                    this.estadoControls.tipoEvento.setValue('MUDANCA_DE_ESTADO');
                }
            }); 
        } 
    }

    tipoProcessamentoChange(event) {
        if(event.value === 'AUTOMATICO' && this.parametroInteresseList.length > 0){
            this.alertsUtil.confirm(`Deseja alterar o campo Tipo Processamento?
             Os parâmetros de interesse informados serão perdidos ao salvar`).then((result) => {
                if (!result.isConfirmed) {
                    this.estadoControls.tipoProcessamento.setValue('MANUAL');
                }
            }); 
        } 
    }

    voltar(){
        this.router.navigateByUrl('/evento-estado/evento-estado-list')
    }
}
