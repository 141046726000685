export class BaseLegal {
    baseLegalId: string;
    tblCodBaseLegal: string;
    tblTipoBase: string;
    tblDescBaseLegal: string;
    tblCapitulacionLegal: string;
    tblValorMulta: string;
    tblTipBaseCalc: string;
    tblVigenciaInicio: string;
    tblVigenciaFinal: string;
    tblHistPenal: string;
    tblEnqLegal: string;
    tblResLegal: string;
    tblStatus: string;
    tblMultaOperacao: string;
    tblRffp: string;


    constructor(init?: Partial<BaseLegal>) {
        if (init) {
            Object.assign(this, init);
        } else {
            this.baseLegalId = null;
            this.tblCodBaseLegal = null;
            this.tblTipoBase = null;
            this.tblDescBaseLegal = null;
            this.tblCapitulacionLegal = null;
            this.tblValorMulta = null;
            this.tblTipBaseCalc = null;
            this.tblVigenciaInicio = null;
            this.tblVigenciaFinal = null;
            this.tblHistPenal = null;
            this.tblEnqLegal = null;
            this.tblResLegal = null;
            this.tblStatus = null;
            this.tblMultaOperacao = null;
            this.tblRffp = null;
        }
    }
}
