import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MenuItem } from "primeng/api";
import Swal from "sweetalert2";
import { configuracaoFormType } from "./passos/configuracao/configuracao.component";
import { eventosFormType } from "./passos/eventos/eventos.component";
import { EventoGerenciarConfigService } from "./services/evento-gerenciar-config.service";

enum Passos {
    EVENTO = 0,
    CONFIGURACAO = 1,
    CONTAS = 2,
    TRANSACOES = 3,
    BASE_LEGAL = 4,
}

@Component({
    selector: "app-evento-gerenciar-config",
    templateUrl: "./evento-gerenciar-config.component.html",
    styleUrls: ["./evento-gerenciar-config.component.scss"],
})
export class EventoGerenciarConfigComponent implements OnInit, OnDestroy {
    passos: MenuItem[] = [
        { label: "Eventos" },
        { label: "Configuração" },
        { label: "Contas" },
        { label: "Transações" },
        { label: "Base Legal" },
    ];
    passoAtivo: Passos = null;
    validarCampos: boolean = false;

    dadosCarregados: any = null;
    edicaoCategoria: boolean = false;
    edicaoConfiguracao: boolean = false;
    configuracaoEventosId: number;
    habilitaSalvarNovasContasImplementado = false;

    form = new FormBuilder().group({
        eventosForm: [null as FormGroup, Validators.required],
        configuracaoForm: [null as FormGroup, Validators.required],
        contasForm: [null as FormGroup, Validators.required],
        transacoesForm: [null as FormGroup, Validators.required],
        baseLegalForm: [null as FormGroup, Validators.required],
    });

    passoFormMap: Map<Passos, string>;
    implantado: boolean | null;

    constructor(
        private eventoGerenciarConfigService: EventoGerenciarConfigService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private egc: EventoGerenciarConfigService
    ) {}

    async ngOnInit() {
        this.passoFormMap = new Map();
        this.passoFormMap.set(Passos.EVENTO, "eventosForm");
        this.passoFormMap.set(Passos.CONFIGURACAO, "configuracaoForm");
        this.passoFormMap.set(Passos.CONTAS, "contasForm");
        this.passoFormMap.set(Passos.TRANSACOES, "transacoesForm");

        const params = this.activatedRoute.snapshot.paramMap;
        const passoConta = params.get("conta") === "true";
        const eventosId = params.get("eventosId");
        if (eventosId) {
            this.egc.carregarPermissoesEvento(Number(eventosId));
            this.edicaoCategoria = true;
        }

        this.configuracaoEventosId = Number(
            params.get("configuracaoEventosId")
        );

        if (!!this.configuracaoEventosId) {
            this.edicaoConfiguracao = true;
            this.dadosCarregados = await this.eventoGerenciarConfigService
                .detalharConfiguracao(this.configuracaoEventosId)
                .toPromise();

            const eventosForm = eventosFormType;
            eventosForm.patchValue(this.dadosCarregados.eventos);

            this.form.get("eventosForm").setValue(eventosForm);
            this.implantado = this.dadosCarregados?.implantado;

            if (passoConta) {
                const configuracaoForm = configuracaoFormType;
                configuracaoForm.patchValue(this.dadosCarregados.configuracao);
                this.form.get("configuracaoForm").setValue(configuracaoForm);
                this.passoAtivo = Passos.CONTAS;
                return;
            }

            this.passoAtivo = Passos.CONFIGURACAO;
            return;
        }

        this.passoAtivo = Passos.EVENTO;
    }

    ngOnDestroy(): void {
        eventosFormType.reset();
        configuracaoFormType.reset({
            listaBloqueio: [],
            mudaEstadoConta: false,
            mudaSituacaoCobranca: false,
        });
        configuracaoFormType.enable();
    }

    salvar() {
        if (this.passoAtivo !== Passos.BASE_LEGAL) return;
        if (!this.form.get("baseLegalForm").value.valid) {
            this.validarCampos = true;
            return;
        }

        const requisicao = {
            eventos: this.form.get("eventosForm").value?.value,
            configuracao: this.form
                .get("configuracaoForm")
                .value?.getRawValue(), //evento de correcao de pagamento pode ter alguns campos desativados
            contas: this.mapearContasSaveRequest(),
            transacoes: this.form.get("transacoesForm").value?.value,
            idBaseLegal: this.form
                .get("baseLegalForm")
                .value?.get("idBaseLegal").value,
        };
        if (this.edicaoConfiguracao) {
            this.eventoGerenciarConfigService
                .atualizarConfiguracao(this.configuracaoEventosId, requisicao)
                .subscribe((res) => {
                    Swal.fire("Configuração de evento atualizada com sucesso!");
                    this.router.navigate(["/evento-pesquisa-config"]);
                    this.eventoGerenciarConfigService.setContaModificadaPosImplantado(false);
                });
        } else {
            this.eventoGerenciarConfigService
                .salvarConfiguracao(requisicao)
                .subscribe((res) => {
                    Swal.fire("Configuração de evento salva com sucesso!");
                    this.router.navigate(["/evento-pesquisa-config"]);
                    this.eventoGerenciarConfigService.setContaModificadaPosImplantado(false);
                });
        }
    }

    avancarPasso() {
        const formName: string = this.passoFormMap.get(this.passoAtivo);
        if (this.implantado || this.form.get(formName).value.valid) {
            this.validarCampos = false;
            if(this.implantado && this.passoAtivo == Passos.CONTAS){
                this.habilitaSalvarNovasContasImplementado = this.egc.teveContasModificadasPosImplantado;
            }
            this.passoAtivo++;
        } else {
            this.validarCampos = true;
        }
    }



    voltarPasso() {
        if (this.passoAtivo === Passos.EVENTO) {
            history.back();
        } else if (
            this.passoAtivo === Passos.CONFIGURACAO &&
            this.edicaoConfiguracao
        ) {
            history.back();
        } else {
            this.passoAtivo--;
        }
    }

    get tipoPasso(): typeof Passos {
        return Passos;
    }

    private mapearContasSaveRequest() {
        const form = this.form.get("contasForm").value;
        const values = form?.value?.contasSelecionadas;
        return values?.map((a) => ({
            eventoContaId: a.eventoContaId,
            idConfigConta: a.conta.configContaId,
            criterioBuscaContaCorrente: a.criterioBuscaContaCorrente,
            inicioVigencia: a.inicioVigencia,
            fimVigencia: a.fimVigencia,
            idConfigContaCriacao: a.contaPosEvento
                ? a.contaPosEvento.configContaId
                : null,
        }));
    }
}
