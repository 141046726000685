import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { MenuResetPageIndexService } from 'src/app/menu/menu-reset-page.service';

@Component({
  selector: 'app-consulta-conta-veiculo',
  templateUrl: './consulta-conta-veiculo.component.html',
  styleUrls: ['./consulta-conta-veiculo.component.scss']
})
export class ConsultaContaVeiculoComponent implements OnInit {

  items: MenuItem[];
  activeIndex: number = 0;
  nextPage: boolean = true;

  constructor(private menuResetPageIndexService: MenuResetPageIndexService) { }

  ngOnInit(): void {
    localStorage.removeItem('filtrosCCAnterior');
    this.items = [
      {
        label: "Selecionar Contribuinte"
      },
      {
        label: "Selecionar Conta"
      },
      {
        label: "Detalhamento da Conta"
      }
    ],
    this.resetIndex();
  }

  resetIndex() {
    this.menuResetPageIndexService.emitirValorIndex.subscribe((data => {
      this.activeIndex = data;
    }))
  }

  outEtapas(eventIndexPage) {
    this.activeIndex = eventIndexPage;
  }

}
