<p-card header="Modelos de Estado da Conta">
    <div style="text-align: right">
        <button
            pButton
            type="button"
            icon="pi pi-plus-circle"
            label="Novo Estado"
            class="p-mr-1 p-text-left botao-adicionar"
            (click)="novoEvento()"
            [disabled]="!podeCadastrarEstadoConta()"
        ></button>
    </div>
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-2">
            <label>Código</label>
            <input type="number" [(ngModel)]="estadoContaId" pInputText />
        </div>
        <div class="p-field p-col-10">
            <label>Descrição *</label>
            <input
                maxlength="100"
                [(ngModel)]="descricao"
                type="text"
                pInputText
            />
        </div>
        <!-- <div class="p-field p-col-4">
            <label>Situação</label>
            <p-dropdown
                [(ngModel)]="situacao"
                [readonly]="false"
                inputId="dropdown"
                name="situacao"
                [options]="situacaoEnum"
                [optionLabel]="'valor'"
                [optionValue]="'chave'"
                [autoDisplayFirst]="false"
                showClear="true"
                >
            </p-dropdown>
        </div> -->
    </div>
    <div
        style="
            margin-top: 10px;
            margin-bottom: 10px;
            text-align: right !important;
        "
    >
        <p-button
            (click)="pesquisaFiltro()"
            label="Pesquisar"
            icon="pi pi-search"
            iconPos="right"
            class="p-mr-2"
        ></p-button>
        <button
            title="Limpar pesquisa"
            pButton
            type="button"
            icon="pi pi-trash"
            label="Limpar"
            (click)="limparFiltro()"
            class="p-mr-1 p-button-danger"
        ></button>
    </div>
</p-card>
<p-card>
    <p-table [value]="estadoList">
        <ng-template pTemplate="header">
            <tr>
                <th>Código</th>
                <th>Descrição</th>
                <th>Situação</th>
                <th>Ações</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-estado>
            <tr>
                <td>{{ estado.estadoContaId }}</td>
                <td>{{ estado.descricao }}</td>
                <td>{{ estado.situacao }}</td>
                <td>
                    <button
                        pButton
                        pRipple
                        title="Editar"
                        type="button"
                        icon="pi pi-pencil"
                        class="p-button-rounded p-button botao-editar"
                        (click)="editarEvento(estado.estadoContaId)"
                        [disabled]="!podeEditarEstadoConta()"
                    ></button>
                    <button
                        pButton
                        pRipple
                        type="button"
                        icon="pi pi-trash"
                        class="p-button-rounded p-button-danger"
                        (click)="delete(estado)"
                        style="margin-left: 3px"
                        [disabled]="!podeExcluirEstadoConta()"
                    ></button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="12" style="text-align: center">
                    Nenhum registro localizado
                </td>
            </tr>
        </ng-template>
    </p-table>
    <p-paginator
        [first]="pagination.pageable.offset"
        [rows]="10"
        [totalRecords]="pagination.totalElements"
        (onPageChange)="onPageChange($event)"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{first} - {last} de {totalRecords}"
    ></p-paginator>
</p-card>
