import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {Store} from '@ngrx/store';
import {ConfigContaRegraCriacao} from '../../models/config-conta-regra-criacao';
import {RegraCriacaoConta} from '../../models/regra-criacao-conta';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Message} from 'primeng/api';
import * as moment from 'moment';
import swal from 'sweetalert2';
import * as RegraCriacaoViewActions from '../regra-criacao/regra-criacao-view/regra-criacao-view.actions';
import * as ConfigContaSearchModalActions from '../config-conta/config-conta-search-modal/config-conta-search-modal-actions';
import {UtilsService} from '../../shared/utils.service';
import {ConfigConta} from '../../models/config-conta';
import Swal from 'sweetalert2';
import { Pagination } from 'src/app/shared/pagination/pagination';
import { ConfigContaRegraCriacaoSearchRequest } from 'src/app/request/config-conta-regra-criacao-search-request';
import { AssociarModeloContaService } from '../configurar-conta-corrente/associar-modelo-conta-corrente/associar-modelo-conta-corrente.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-contas-correntes-tab-view',
    templateUrl: './contas-correntes-tab-view.component.html',
    styleUrls: ['./contas-correntes-tab-view.component.scss']
})
export class ContasCorrentesTabViewComponent implements OnInit {

    @Input() situacoesInput: any[];

    @Input() regraCriacaoConta: RegraCriacaoConta;

    @Output() temContaAssociadaEvent = new EventEmitter();

    temContaAssociada: boolean;

    msgs1: Message[];

    blockedDocument = false;

    configContaRegraCriacao: ConfigContaRegraCriacao;

    configContaRegraCriacoes: ConfigContaRegraCriacao[];

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    pagination: Pagination<any>;

    searchRequest: ConfigContaRegraCriacaoSearchRequest;
    contaModelo: any;


    constructor(
        public utilsService: UtilsService,
        private http: HttpClient,
        private storeRegraCriacaoView: Store<{ regraCriacaoView: { configContaRegraCriacao: ConfigContaRegraCriacao } }>,
        private storeConfigContaSearchModal: Store<{ configContaSearchModal: { displayMaximizable: boolean } }>,
        private associarModeloContaService: AssociarModeloContaService,
        private utilService: UtilsService,
        private router: Router

    ) {
        this.contaModelo = this.router.getCurrentNavigation();
    }

    ngOnInit(): void {
        this.searchRequest = new ConfigContaRegraCriacaoSearchRequest();
        this.pagination = new Pagination<any>();
        this.storeRegraCriacaoView
            .select('regraCriacaoView')
            .subscribe(data => this.configContaRegraCriacao = JSON.parse(JSON.stringify(data.configContaRegraCriacao)));
            this.getConfigContaRegraSituacoes();
        }

    onSelectDataInicioVigencia(event) {
        this.configContaRegraCriacao.dataInicioVigencia = moment(event).format('DD/MM/YYYY') || null;
        this.updateState();
    }

    onChangeDataInicioVigencia(event) {
        this.configContaRegraCriacao.dataInicioVigencia = event.target.value || null;
        this.updateState();
    }

    onSelectDataFimVigencia(event) {
        this.configContaRegraCriacao.dataFimVigencia = moment(event).format('DD/MM/YYYY') || null;
        this.updateState();
    }

    onChangeDataFimVigencia(event) {
        this.configContaRegraCriacao.dataFimVigencia = event.target.value || null;
        this.updateState();
    }

    onChangeSituacao(event) {
        if(event.value == null) {
            this.searchRequest = new ConfigContaRegraCriacaoSearchRequest();
        }
        this.updateState();
    }

    updateState() {
        this.storeRegraCriacaoView.dispatch(new RegraCriacaoViewActions.SetConfigContaRegraCriacao(this.configContaRegraCriacao));
    }

    save() {
        const data = this.prepareToSend();
        this.blockedDocument = true;
        this.msgs1 = [];
        this.http
            .post<RegraCriacaoConta>(
                `${environment.baseUrl}/config-conta-regra-criacao`,
                JSON.stringify(data),
                this.httpOptions
            )
            .subscribe(response => {
                this.blockedDocument = false;
                this.configContaRegraCriacao = new ConfigContaRegraCriacao();
                this.configContaRegraCriacao.configConta = new ConfigConta();
                this.updateState();
                swal.fire('Sucesso', 'Salvo com sucesso', 'info').then(r => this.getConfigContaRegraSituacoes());
            }, error => {
                this.blockedDocument = false;
                if (error.status === 500) {
                    swal.fire(error.statusText, 'Ocorreu um erro interno', 'error').then(r => {});
                } else if (error.status === 412) {
                    swal.fire('Erro de Validação', error.error.message, 'error').then(r => {});
                } else if (error.status === 400) {
                    const errors = error.error.errors;
                    this.msgs1 = [];
                    if (errors) {
                        errors.forEach(e => {
                            this.msgs1.push({severity: 'error', summary: 'Erro', detail: e.message});
                        });
                    } else {
                        this.msgs1.push({severity: 'error', summary: 'Erro', detail: error.error.message});
                    }
                    this.scrollToTop();
                }
            });
    }

    cancel() {
        this.configContaRegraCriacao = new ConfigContaRegraCriacao();
        this.configContaRegraCriacao.configConta = new ConfigConta();
        this.updateState();
    }

    limpar() {
        this.searchRequest = new ConfigContaRegraCriacaoSearchRequest;
        this.getConfigContaRegraSituacoes();
    }

    onChangeDisplayMaximizable(event) {
    }

    pesquisar() {
        this.storeConfigContaSearchModal.dispatch(new ConfigContaSearchModalActions.SetDisplayMaximizable(true));
    }

    getConfigContaRegraSituacoes() {
        this.pagination = new Pagination<any>();

        const data = JSON.parse(JSON.stringify(this.searchRequest));
        data.regraCriacaoContaId = this.regraCriacaoConta.regraCriacaoContaId;
        if(data.regraCriacaoContaId == null) {
            data.regraCriacaoContaId = "";
        }
        if (data.dataInicioVigencia == null) {
            data.dataInicioVigencia = "";
        } else if (data.dataInicioVigencia) {
            data.dataInicioVigencia = this.utilService.convertToDateBr(
                this.searchRequest.dataInicioVigencia
            );
        }
        if (data.dataFimVigencia == null) {
            data.dataFimVigencia = "";
        } else if (data.dataFimVigencia) {
            data.dataFimVigencia = this.utilService.convertToDateBr(
                this.searchRequest.dataFimVigencia
            );
        }
        
        this.associarModeloContaService
            .pesquisaRegraCriacao(data, this.pagination)
            .subscribe((response: any) => {
                this.configContaRegraCriacoes = response.content;
                this.pagination = response;
                this.temContaAssociadaEvent.emit([response, this.contaModelo]);
            });
    }

    getConfigContaRegraSituacoesOld() {

        this.blockedDocument = true;
        this.msgs1 = [];
        this.http
            .get<Array<any>>(`${environment.baseUrl}/config-conta-regra-criacao/get-by-regra-criacao-conta/${this.regraCriacaoConta.regraCriacaoContaId}`)
            .subscribe(response => {
                this.blockedDocument = false;
                this.configContaRegraCriacoes = response;
            }, error => {
                this.blockedDocument = false;
                if (error.status === 500 || error.status === 404) {
                    swal.fire(error.statusText, 'Ocorreu um erro interno', 'error').then(r => {});
                } else if (error.status === 412) {
                    swal.fire('Erro de Validação', error.error.message, 'error').then(r => {});
                }
                else if (error.status === 400) {
                    const errors = error.error.errors;
                    this.msgs1 = [];
                    errors.forEach(e => {
                        this.msgs1.push({severity: 'error', summary: 'Erro', detail: e.message});
                    });
                    this.scrollToTop();
                }
            });
    }

    prepareToSend() {
        this.configContaRegraCriacao.regraCriacaoConta = JSON.parse(JSON.stringify(this.regraCriacaoConta));
        this.updateState();
        const data = JSON.parse(JSON.stringify(this.configContaRegraCriacao));
        data.configContaId = data.configConta.configContaId === null ? "" : data.configConta.configContaId;
        delete data.configConta;
        data.regraCriacaoContaId = data.regraCriacaoConta.regraCriacaoContaId;
        delete data.regraCriacaoConta;

        data.dataInicioVigencia = data.dataInicioVigencia == null ?
            null : moment(data.dataInicioVigencia, 'DD/MM/YYYY').format('YYYY-MM-DD');
        data.dataFimVigencia = data.dataFimVigencia == null ?
            null : moment(data.dataFimVigencia, 'DD/MM/YYYY').format('YYYY-MM-DD');

        return data;
    }

    scrollToTop() {
        (function smoothscroll() {
            const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
            if (currentScroll > 0) {
                window.requestAnimationFrame(smoothscroll);
                window.scrollTo(0, currentScroll - (currentScroll / 8));
            }
        })();
    }

    editar(configContaRegraCriacao) {
        this.configContaRegraCriacao = JSON.parse(JSON.stringify(configContaRegraCriacao));
        this.configContaRegraCriacao.dataInicioVigencia = this.configContaRegraCriacao.dataInicioVigencia == null ?
            null : moment(this.configContaRegraCriacao.dataInicioVigencia).format('DD/MM/YYYY');
        this.configContaRegraCriacao.dataFimVigencia = this.configContaRegraCriacao.dataFimVigencia == null ?
            null : moment(this.configContaRegraCriacao.dataFimVigencia).format('DD/MM/YYYY');
        this.updateState();
    }


    abrirAlertDeConfirmacao(configContaRegraCriacao) {
        Swal.fire({
            title: 'Deseja desativar a conta corrente do modelo?',
            showDenyButton: true,
            confirmButtonText: 'Sim',
            denyButtonText: `Não`,
        }).then((result) => {
            if (result.isConfirmed) {
                this.remove(configContaRegraCriacao);
            }
        });
    }

    remove(configContaRegraCriacao) {
        this.http
            .delete<Array<any>>(`${environment.baseUrl}/config-conta-regra-criacao/${configContaRegraCriacao.configContaRegraCriacaoId}`)
            .subscribe(response => {
                this.blockedDocument = false;
                Swal.fire('Sucesso', 'Desativado com sucesso', 'success').then(r => this.getConfigContaRegraSituacoes());

            }, error => {
                this.blockedDocument = false;
                if (error.status === 500 || error.status === 404) {
                    swal.fire(error.statusText, 'Ocorreu um erro interno', 'error').then(r => {});
                } else if (error.status === 412) {
                    swal.fire('Erro de Validação', error.error.message, 'error').then(r => {});
                }
                else if (error.status === 400) {
                    const errors = error.error.errors;
                    this.msgs1 = [];
                    errors.forEach(e => {
                        this.msgs1.push({severity: 'error', summary: 'Erro', detail: e.message});
                    });
                    this.scrollToTop();
                }
            });
    }

    onPageChange(e) {
        this.pagination.pageable.pageNumber = e.page;
        this.getConfigContaRegraSituacoes();
    }

}
