import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { BaseResourceService } from '../components/base-resource-service/base-resource-service';

@Injectable({
  providedIn: 'root'
})
export class EnumService extends BaseResourceService {

  constructor(
    protected injector: Injector
  ) {
    super(`${environment.baseUrl}/enums`, injector);
  }

  getByName(name: string): Observable<any[]> {
    return this.getUtilList(`${this.apiPath}/name/${name}`);
  }
}
