import {Injectable} from '@angular/core';
import {ConfigContaBaseLegal} from '../../../models/config-conta-base-legal';
import {BaseLegal} from '../../../models/base-legal';
import {ConfigConta} from '../../../models/config-conta';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Pagination } from 'src/app/shared/pagination/pagination';
import { Pageable } from 'src/app/shared/pagination/pageable';

@Injectable({
    providedIn: 'root'
})
export class ConfigContaBaseLegalTabViewService {
    configContaBaseLegal: ConfigContaBaseLegal;

    constructor(
        private http: HttpClient,
    )
    {
        this.configContaBaseLegal = new ConfigContaBaseLegal();
        this.configContaBaseLegal.baseLegal = new BaseLegal();
        this.configContaBaseLegal.configConta = new ConfigConta();
        this.configContaBaseLegal.situacao = 'ATIVA';
    }

    getSituacoes(): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${environment.baseUrl}/enums/config-conta/situacao`);
    }

    getConfigContaBaseLegal(objParams, pagination: Pagination<any>): Observable<Pageable<any>> {
        return this.http.get<Pageable<any>>(`${environment.baseUrl}/config-conta-base-legal/search`, {
          params: {
            ...objParams,
            page: pagination.pageable.pageNumber,
            size: pagination.size
          }
        });
      }

    ativaInativaBaseLegal (id: number): Observable<void> {
        return this.http.put<void>(`${environment.baseUrl}/config-conta-base-legal/${id}/ativa-inativa`, {});
    }

    deleteBaseLegal(id: number): Observable<void> {
        return this.http.delete<void>(
            `${environment.baseUrl}/config-conta-base-legal/${id}`);
    }
}
