import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { ConfigContaUnidOrganizaTabViewService } from '../component/configurar-conta-corrente/config-conta-unid-organiza-tab-view/config-conta-unid-organiza-tab-view.service';
import { ConfigurarContaCorrenteService } from '../component/configurar-conta-corrente/configurar-conta-corrente.service';
import { ConfigComposicaoConta } from '../models/config-composicao-conta';
import { ConfigConta } from '../models/config-conta';
import { ConfigContaBaseLegal } from '../models/config-conta-base-legal';
import { ConfigContaSaveAllRequest } from '../models/config-conta-save-all-request';
import { ConfigContaUnidOrganiza } from '../models/config-conta-unid-organiza';
import { ConfigModelo } from '../models/config-modelo';
import { ConfigReceita } from '../models/config-receita';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { ConfigContaTipoCadastro } from '../models/config-conta-tipo-cadastro';
import {UtilsService} from '../shared/utils.service';
import { TipoCadastro } from '../models/tipo-cadastro';
@Injectable({
    providedIn: "root",
})
export class ContaCorrenteService {
    constructor(
        public service: ConfigContaUnidOrganizaTabViewService,
        private configurarContaCorrenteService: ConfigurarContaCorrenteService,
        private route: Router,
        private utils: UtilsService,
    ) {}

    configContaSaveAllRequest = new ConfigContaSaveAllRequest();
    configConta = new ConfigConta();
    configComposicaoConta = new ConfigComposicaoConta();
    configModelo = new ConfigModelo();
    configReceita = new ConfigReceita();
    configContaBaseLegal = new ConfigContaBaseLegal();
    configContaUnidOrganiza = new ConfigContaUnidOrganiza();
    // tipoCadastroSaveRequest = new ConfigContaTipoCadastro();
    tipoCadastro = new TipoCadastro();
    condition: any;
    validaAba = false;

    etapaContaItems: MenuItem[];

    private contaComplete = new Subject<any>();
    contaComplete$ = this.contaComplete.asObservable();

    getEtapaConta() {
        return (this.etapaContaItems = [
            {
                label: "Conta",
                routerLink: "config-conta-corrente",
            },
            {
                label: "Composicao",
                routerLink: "config-composicao",
            },
            {
                label: "Modelo",
                routerLink: "config-modelo",
            },
            {
                label: "Receita",
                routerLink: "config-receita",
            },
            {
                label: "Base Legal",
                routerLink: "config-base-legal",
            },
            {
                label: "Unid. Organizacional",
                routerLink: "unid-organiza",
            },
            {
                label: "Perfil de Visualização",
                routerLink: "perfil-visualizacao",
            },
        ]);
    }

    getContaInformtation() {
        return this.configConta;
    }

    getConfigComposcaoInformtation() {
        return this.configComposicaoConta;
    }

    getConfigModeloInformtation() {
        return this.configModelo;
    }

    getConfigReceitaInformtation() {
        return this.configReceita;
    }

    getConfigBaseLegalInformtation() {
        return this.configContaBaseLegal;
    }

    getConfigUnidOraganizalInformtation() {
        return this.configContaUnidOrganiza;
    }

    getTipoCadastroSaveRequest() {
        return this.tipoCadastro;
    }

    setTicketInformation(configConta) {
        this.configConta = configConta;
    }

    setConfigComposcaoInformtation(configComposicaoConta) {
        this.configComposicaoConta = configComposicaoConta;
    }

    setConfigTipoCadastroInformtation(configComposicaoConta) {
        this.tipoCadastro = configComposicaoConta;
    }

    setConfigModeloInformtation(configModelo) {
        this.configModelo = configModelo;
    }

    setConfigReceitaInformtation(configReceita) {
        this.configReceita = configReceita;
    }

    setConfigBaseLegalInformtation(configReceita) {
        this.configReceita = configReceita;
    }

    getValdiaAba() {
        return this.validaAba;
    }

    complete() {

        // let objTpCadastroSave: any;
        // this.configContaSaveAllRequest.tipoCadastroSaveRequests.forEach(element => {

        //             objTpCadastroSave.pus(
        //                 {
        //                     configContaId: 0,
        //                     configContaTipoCadastroId: 0,
        //                     tipoCadastroId: element.tipoCadastroId
        //                 }
        //             )
        //         });

        this.configContaSaveAllRequest.configContaSaveRequest =
            this.removeParametro();

        this.configContaSaveAllRequest.itemCalculoSaveRequest =
            this.configComposicaoConta;

        this.configContaSaveAllRequest.regraCriacaoSaveRequest =
            this.configModelo;

        this.configContaSaveAllRequest.receitaSaveRequest = this.configReceita;

        this.configContaSaveAllRequest.baseLegalSaveRequest =
            this.configContaBaseLegal;

        this.configContaSaveAllRequest.unidOrganizaSaveRequest =
            this.configContaUnidOrganiza;

        this.configContaSaveAllRequest.tipoCadastroSaveRequests =
            this.tipoCadastro;

        this.configurarContaCorrenteService
            .postContaSaveAll(this.configContaSaveAllRequest)
            .subscribe(
                (res) => {
                    this.configConta = res;
                    swal.fire("Sucesso", `Conta ${res.configContaId} salva com sucesso.`, "success").then(
                        (r) => {
                            this.getConfigContaId(this.configConta.configContaId);
                        }
                    );
                },
                (error) => {
                    if (error.status === 500) {
                        swal.fire(
                            "Erro ao tentar salvar a conta",
                            "Ocorreu um erro interno",
                            "error"
                        ).then((r) => {
                            this.route
                                .navigate(["/config-conta/config-conta-list",])
                                .then(() => {});
                        });
                    } else if (error.status === 412) {
                        swal.fire({
                            title: "Atenção",
                            text: `${error.error.message}`,
                            icon: "warning",
                        }).then((r) => {
                            // this.route.navigate(['/config-conta/config-conta-list'])
                        });
                    } else {
                        swal.fire(
                            error.statusText,
                            "Ocorreu um erro interno",
                            "error"
                        ).then((r) => {
                            // this.route.navigate(['/config-conta/config-conta-list'])
                        });
                    }
                }
            );
    }

    removeParametro(){
        let {configContaTipoCadastros, ...objNovo} = this.configConta;
        return objNovo;
      }

    getConfigContaId(configContaId) {
        this.configurarContaCorrenteService
            .getConfigContaId(configContaId)
            .subscribe(
                (configConta) => {
                    let configContaSaveAll;
                    this.configConta = configConta;
                    configContaSaveAll = this.configConta;
                     sessionStorage.clear();
                            this.configComposicaoConta = new ConfigComposicaoConta();
                            this.configModelo = new ConfigModelo();
                            this.configReceita = new ConfigReceita();
                            this.configContaBaseLegal = new ConfigContaBaseLegal();
                            this.condition = null;
                            this.route.navigate(["/config-conta-corrente"], {state: { configContaSaveAll}});
                    // swal.fire("Sucesso", "Salvo com sucesso", "success").then(
                    //     (r) => {
                    //         sessionStorage.clear();
                    //         this.configComposicaoConta = new ConfigComposicaoConta();
                    //         this.configModelo = new ConfigModelo();
                    //         this.configReceita = new ConfigReceita();
                    //         this.configContaBaseLegal = new ConfigContaBaseLegal();
                    //         this.condition = null;
                    //         this.route.navigate(["/config-conta-corrente"], {
                    //             state: { configContaSaveAll, configConta: "/config-conta-corrente" },
                    //         });
                    //     }
                    // );
                },
                (erro) => {
                    swal.fire({
                        title: "Atenção",
                        text: `${erro.error.message}`,
                        icon: "warning",
                    });
                }
            );
    }

    clear() {
        this.configContaSaveAllRequest = new ConfigContaSaveAllRequest();
        this.configConta = new ConfigConta();
        this.configComposicaoConta = new ConfigComposicaoConta();
        this.configModelo = new ConfigModelo();
        this.configReceita = new ConfigReceita();
        this.configContaBaseLegal = new ConfigContaBaseLegal();
        this.configContaUnidOrganiza = new ConfigContaUnidOrganiza();
        // this.tipoCadastroSaveRequest = new ConfigContaTipoCadastro
        this.condition = null;
    }
}
