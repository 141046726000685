import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

/**Reseta index componentes que utilizam time line e em pages dentro de um único componente */
export class MenuResetPageIndexService {

    emitirValorIndex = new EventEmitter<number>();

    constructor() {}

    resetIndex() {
        this.emitirValorIndex.emit(0);
    }

}
