import { Component, OnInit } from "@angular/core";
import { EnumsService } from "src/app/core/enums.service";
import { Pagination } from "src/app/shared/pagination/pagination";
import { AlertsUtil } from "src/app/utils/alerts/alerts.util";
import { RemoveParamsUtil } from "src/app/utils/paramsRemove/paramsRemove.util";
import { EventoPesquisaConfigService } from "./evento-pesquisa-config.service";

@Component({
    selector: "app-evento-pesquisa-config",
    templateUrl: "./evento-pesquisa-config.component.html",
    styleUrls: ["./evento-pesquisa-config.component.scss"],
})
export class EventoPesquisaConfigComponent implements OnInit {
    pagination: Pagination<any>;

    tipoEventoList: any = [];
    tipoEvento: any;
    tipoPesquisaEvento = "CATEGORIA";
    tipoPesquisaList = [
        {
            chave: "CATEGORIA",
            valor: "Categoria",
        },
        {
            chave: "EVENTO",
            valor: "Evento",
        },
        {
            chave: "CONTA",
            valor: "Conta",
        },
    ];
    operacaoEnum: any = [];
    blockedDocument: boolean;
    operacaoList: any = [];
    descricaoEvento: any;
    eventosConfig: any;
    descricaoCategoria: any;
    /**Evento */
    paginationEvento: Pagination<any>;
    tipoProcessamentoList: any = [];
    tipoProcessamentoValue: any;
    implantadoValue: any = "NAO";
    eventoDescricaoValue: any;
    codEvento: any;
    /**Conta */
    conta: any;
    contaDescricao: any;
    tributoValue: any;
    tipoContaValue: any;
    tipoContaList: any[];
    tributoList: any[];
    honorariosList: any = [];
    honorariosValue: any;
    paginationConta: Pagination<any>;

    constructor(
        private alertUtil: AlertsUtil,
        private removeParamsUtil: RemoveParamsUtil,
        private eventoPesquisaConfigService: EventoPesquisaConfigService,
        private enumsService: EnumsService
    ) {}

    ngOnInit(): void {
        this.pagination = new Pagination<any>({
            pageable: { pageNumber: 0 },
            size: 20,
        });
        this.paginationEvento = new Pagination<any>({
            pageable: { pageNumber: 0 },
            size: 20,
        });
        this.paginationConta = new Pagination<any>({
            pageable: { pageNumber: 0 },
            size: 20,
        });
        this.getEnums();
    }

    filtroEventosConfig() {
        switch (this.tipoPesquisaEvento) {
            case "CATEGORIA":
                this.getEventos();
                break;
            case "EVENTO":
                this.getConfigEventos();
                break;
            case "CONTA":
                this.getConfigEventoConta();
                break;
            default:
                this.alertUtil.warning(`Erro ao selecionar o tipo de evento`);
                break;
        }
    }

    /**Filtro Categoria */
    getEventos() {
        this.blockedDocument = true;
        const obj: any = {
            operacao: this.operacaoEnum,
            tipoEventoId: this.tipoEvento,
            descricaoCategoria: this.descricaoCategoria,
            descricaoCompleta: this.descricaoEvento,
        };
        let objParams = this.removeParamsUtil.removeParametroNaoUtilizado(obj);
        this.eventoPesquisaConfigService
            .getEventos(objParams, this.pagination)
            .subscribe(
                (resp: any) => {
                    this.pagination = resp;
                    this.blockedDocument = false;
                },
                (error) => {
                    this.blockedDocument = false;
                    this.alertUtil.handleError(error);
                }
            );
    }

    /**Filtro Evento */
    getConfigEventos() {
        this.blockedDocument = true;

        const obj: any = {
            eventosId: this.codEvento,
            eventoDescricao: this.eventoDescricaoValue,
            tipoProcessamento: this.tipoProcessamentoValue,
            implantado: this.implantadoValue,
            lancaHonorario: this.honorariosValue,
        };
        let objParams = this.removeParamsUtil.removeParametroNaoUtilizado(obj);
        this.eventoPesquisaConfigService
            .getConfigEventos(objParams, this.paginationEvento)
            .subscribe(
                (resp) => {
                    this.paginationEvento = resp;
                    this.blockedDocument = false;
                },
                (error) => {
                    this.blockedDocument = false;
                    this.alertUtil.handleError(error);
                }
            );
    }

    /**Filtro Conta */
    getConfigEventoConta() {
        this.blockedDocument = true;
        const obj: any = {
            codConta: this.conta,
            configContaNomeCompleto: this.contaDescricao,
            configContaTributoId: this.tributoValue,
            tipoConta: this.tipoContaValue,
        };
        let objParams = this.removeParamsUtil.removeParametroNaoUtilizado(obj);
        this.eventoPesquisaConfigService
            .getConfigConfiguracaoConta(objParams, this.paginationConta)
            .subscribe(
                (resp) => {
                    this.paginationConta = resp;
                    this.blockedDocument = false;
                },
                (error) => {
                    this.blockedDocument = false;
                    this.alertUtil.handleError(error);
                }
            );
    }

    /**BUSCA TIPO DE PESQUISA CATEGORIA */
    getConfigEventosByTipoEventoId(evento: any, index) {
        const objParams = {
            eventosId: evento?.eventosId,
        };
        this.eventoPesquisaConfigService
            .getConfigEventosByTipoEventoId(objParams, this.pagination)
            .subscribe(
                (resp) => {
                    this.pagination.content[index] = {
                        ...this.pagination.content[index],
                        ...{ eventosConfig: resp.content },
                    };
                },
                (error) => {
                    this.alertUtil.handleError(error);
                }
            );
    }

    /**BUSCA TIPO DE PESQUISA EVENTO */
    getConfigByConfiguracaoEventosId(evento: any, index) {
        const objParams = {
            configuracaoEventosId: evento?.configuracaoEventosId,
        };

        this.eventoPesquisaConfigService
            .getConfigByConfiguracaoEventosId(objParams, this.pagination)
            .subscribe(
                (resp) => {
                    this.paginationEvento.content[index] = {
                        ...this.paginationEvento.content[index],
                        ...{ configEventos: resp.content },
                    };
                },
                (error) => {
                    this.alertUtil.handleError(error);
                }
            );
    }

    async getEnums() {
        let nameTipoProcessamento = "TipoProcessamento";
        let nameTipoConta = "TipoContaConfigConta";
        this.operacaoList = await this.eventoPesquisaConfigService
            .getOperacao()
            .toPromise();
        this.tipoEventoList = await this.eventoPesquisaConfigService
            .getTipoEvento()
            .toPromise();
        this.tributoList = await this.eventoPesquisaConfigService
            .getTributo()
            .toPromise();
        this.tipoContaList = await this.enumsService
            .getEnumNome(nameTipoConta)
            .toPromise();
        this.tipoProcessamentoList = await this.enumsService
            .getEnumNome(nameTipoProcessamento)
            .toPromise();

        this.honorariosList = await this.enumsService.getSimNao().toPromise();

        return new Promise((resolve) => {
            resolve("");
        });
    }

    onPageChange(e) {
        this.pagination.pageable.pageNumber = e.page;
        this.pagination.size = e.rows;
        this.getEventos();
    }

    onPageChangeEvento(e) {
        this.paginationEvento.pageable.pageNumber = e.page;
        this.paginationEvento.size = e.rows;
        this.getConfigEventos();
    }

    onPageChangeConta(e) {
        this.paginationConta.pageable.pageNumber = e.page;
        this.paginationConta.size = e.rows;
        this.getConfigEventoConta();
    }

    limpar() {
        switch (this.tipoPesquisaEvento) {
            case "CATEGORIA":
                this.pagination = new Pagination<any>();
                this.pagination.pageable.pageNumber = 0;
                this.operacaoEnum = null;
                this.tipoEvento = null;
                this.descricaoCategoria = null;
                this.descricaoEvento = null;
                break;
            case "EVENTO":
                this.paginationEvento = new Pagination<any>();
                this.paginationEvento.pageable.pageNumber = 0;
                this.eventoDescricaoValue = null;
                this.tipoProcessamentoValue = null;
                this.honorariosValue = null;
                this.implantadoValue = "NAO";
                break;
            case "CONTA":
                this.paginationConta = new Pagination<any>();
                this.paginationConta.pageable.pageNumber = 0;
                this.tributoValue = null;
                this.tipoContaValue = null;
                this.conta = null;
                this.contaDescricao = null;
                break;
            default:
                break;
        }
    }

    converteValorOperacoes(valor) {
        return (valor = this.operacaoList.find(
            (aux) => aux.chave == valor
        ).valor);
    }
}
