import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Pagination } from 'src/app/shared/pagination/pagination';
import { SelecionarContribInternaService } from 'src/app/compartilhado/services/selecionar-contrib-interna/selecionar-contrib-interna.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-selecionar-veiculo-list',
  templateUrl: './selecionar-veiculo-list.component.html',
  styleUrls: ['./selecionar-veiculo-list.component.scss']
})
export class SelecionarVeiculoListComponent implements OnInit {

  @Input() veiculosListSearch;
  @Input() pagination: Pagination<any>;

  @Output() changePageEvent: EventEmitter<number> = new EventEmitter<number>();

  public detailsVeiculo:any;

  constructor(public selecionarContribInternaService: SelecionarContribInternaService, private router: Router)  {}

  ngOnInit(): void {
    this.onPageChange({ page: 0 });
  }

  searchDetails(veiculo) {
    this.selecionarContribInternaService.searchVeiculoDetails(veiculo.idRenavam).subscribe((response) => {
      this.detailsVeiculo = response;
    }, (error) => {

    });
  }

  onPageChange(e) {
    this.pagination.pageable.pageNumber = e.page;
    this.changePageEvent.emit(e.page);  
  }

  goToExtratoContaCorrente(veiculoSelecionado: any){
    localStorage.clear();
    localStorage.setItem('contribuinteSelecionado', JSON.stringify(veiculoSelecionado));
   
    this.router.navigateByUrl(`/extrato-conta-corrente/`);
  }
}
