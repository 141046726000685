import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { BaseLegal } from '../../../models/base-legal';
import { BaseLegalSearchRequest } from '../../../request/base-legal-search-request';
import { Pagination } from '../../../shared/pagination/pagination';
import { UtilsService } from '../../../shared/utils.service';
import {
    ConfigContaBaseLegalTabViewService
} from '../../configurar-conta-corrente/config-conta-base-legal-tab-view/config-conta-base-legal-tab-view.service';
import { TransacaoService } from '../../transacao-financeira/services/transacao.service';
import { BaseLegalSearchModalService } from './base-legal-search-modal.service';
import { BaseLegalService } from './base-legal.service';

@Component({
    selector: 'app-base-legal-search-modal',
    templateUrl: './base-legal-search-modal.component.html',
    styleUrls: ['./base-legal-search-modal.component.scss']
})
export class BaseLegalSearchModalComponent implements OnInit {

    blockedDocument = false;

    baseLegalSearchRequest: BaseLegalSearchRequest;

    baseLegalSearchResponses: BaseLegal[];

    pagination: Pagination<any>;

    @Output() selectBaseLegal = new EventEmitter<string>();

    constructor(
        private storeBaseLegalSearchModal: Store<{ baseLegalSearchModal: { displayMaximizable: boolean } }>,
        private http: HttpClient,
        public utilsService: UtilsService,
        public configContaBaseLegalService: ConfigContaBaseLegalTabViewService,
        public transacaoService: TransacaoService,
        public baseLegalSearchModalService: BaseLegalSearchModalService,
        public baseLegalService: BaseLegalService
    ) {
    }

    ngOnInit(): void {
        this.pagination = new Pagination<any>();
        this.baseLegalSearchRequest = new BaseLegalSearchRequest();
    }

    search() {
        this.blockedDocument = true;
        this.baseLegalService.searchBaseLegal(
                 this.baseLegalSearchRequest,
                 this.pagination
        ).subscribe(response => {
            this.baseLegalSearchResponses = response.content;
            this.blockedDocument = false;
            this.pagination = response;
        }, error => {
            this.blockedDocument = false;
        });    
    }

    select(bl) {
        this.configContaBaseLegalService.configContaBaseLegal.baseLegal = bl;
        this.transacaoService.transacao.baseLegal = bl;
        this.baseLegalSearchModalService.displayMaximizable = false;
        this.selectBaseLegal.emit(bl);
    }

    onHide() {
        this.baseLegalSearchResponses = [];
    }

    onPageChange(e) {
        this.pagination.pageable.pageNumber = e.page;
        this.search();
    }

    onSelectDataInicioVigencia(event) {
        if (event) {
            this.baseLegalSearchRequest.dataInicioVigencia = moment(event).format('DD/MM/YYYY');
        } else {
            this.baseLegalSearchRequest.dataInicioVigencia = '';
        }
    }

    onSelectDataFimVigencia(event) {
        if (event) {
            this.baseLegalSearchRequest.dataFimVigencia = moment(event).format('DD/MM/YYYY');
        } else {
            this.baseLegalSearchRequest.dataFimVigencia = '';
        }
    }
}
