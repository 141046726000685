import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ConfigContaRegraCriacaoSaveRequest } from "src/app/request/config-conta-regra-criacao-save-request";
import { Pagination } from "src/app/shared/pagination/pagination";
import { Pageable } from "src/app/shared/pagination/pageable";
import { ConfigContaRegraCriacaoSearchRequest } from "src/app/request/config-conta-regra-criacao-search-request";
import {TransacaoSearchRequest} from '../../../request/TransacaoSearchRequest';

@Injectable({
    providedIn: "root",
})
export class AssociarModeloContaService {
    constructor(private http: HttpClient) {}

    pesquisaRegraCriacao(searchRequest: ConfigContaRegraCriacaoSearchRequest,
         pagination: Pagination<any>): Observable<Pageable<any>> {
        return this.http.get<Pageable<any>>(
            `${environment.baseUrl}/config-conta-regra-criacao/search`, {
                params: {
                    ...searchRequest,
                    page: pagination.pageable.pageNumber,
                    size: pagination.size
                }
            }
        );
    }
  
    postConfigContaRegraCriacao(request: ConfigContaRegraCriacaoSaveRequest): Observable<any> {
        return this.http.post<ConfigContaRegraCriacaoSaveRequest>(
            `${environment.baseUrl}/config-conta-regra-criacao`, request
        );
    }

    getReceitas(tributaria: any, vinculoConta: any, codTributo: any): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${environment.baseUrl}/receita/list-all-nopage`,{
            params: {
                tributaria,
                vinculoConta,
                codTributo
            }
        });
    }
    

    // ativaInativaItemCalculo(id: string): Observable<void>{
    //     return this.http.put<void>(`${environment.baseUrl}/config-conta-item-calculo/${id}/ativa-inativa`,{});
    // }

}