<!-- <p-toast></p-toast> -->
<div class="card">
    <p-steps [model]="items" [(activeIndex)]="activeIndex" [readonly]="nextPage" class="time-line"></p-steps>
</div>

<app-consulta-config-conta 
*ngIf="activeIndex == 0"
(outEtapas)="outEtapas($event)"
></app-consulta-config-conta>

<app-contas-associadas
*ngIf="activeIndex == 2"
(outEtapas)="outEtapas($event)"
>
</app-contas-associadas>

<app-registrar-memoria-calculo
*ngIf="activeIndex == 1 || activeIndex == 3"
(outEtapas)="outEtapas($event)"
[hiddenItensConfirm]="activeIndex"
></app-registrar-memoria-calculo>