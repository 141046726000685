export class ImpostoAtrasadoSearchRequest {
    atraso: number;
    impostoId: number[];
    contaId: number[];
    dataInicialReferenciaValue: any | null;
    dataFinalReferenciaValue: any | null;

    constructor() {
        this.atraso = 30;
        this.impostoId = [];
        this.contaId = [];
        this.dataInicialReferenciaValue = null;
        this.dataFinalReferenciaValue = null;
    }
}
