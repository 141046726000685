export class EventosSearchRequest {
    eventosId: string;
    descricao: string;
    tipoEventoId: string;
    tipoEventoDescricao: string;

    constructor(init?: Partial<EventosSearchRequest>) {
        if (init) {
            Object.assign(this, init);
        } else {
            this.eventosId = '';
            this.descricao = '';
            this.tipoEventoId = '';
            this.tipoEventoDescricao = '';
        }
    }
}
