import {Component, OnInit, ViewChild} from '@angular/core';
import {Pagination} from '../../../shared/pagination/pagination';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {Pageable} from '../../../shared/pagination/pageable';
import * as moment from 'moment';
import {ConfigContaSearchRequest} from '../../../request/config-conta-search-request';
import {map} from 'rxjs/operators';
import {ConfigContaRegraCriacao} from '../../../models/config-conta-regra-criacao';
import {Store} from '@ngrx/store';
import {IConfigContaSearchModalState, setConfigContas} from '../../../store/config.conta.search.modal.state';
import {ConfigConta} from '../../../models/config-conta';
import * as RegraCriacaoViewActions from '../../regra-criacao/regra-criacao-view/regra-criacao-view.actions';
import { Paginator } from 'primeng/paginator';
import { ContaCorrenteService } from 'src/app/core/conta-corrente.service';
import { ConfigComposicaoConta } from 'src/app/models/config-composicao-conta';
import { UtilsService } from 'src/app/shared/utils.service';
import { AlertsUtil } from 'src/app/utils/alerts/alerts.util';
import {AcoesContaCorrenteHelper} from "../../../shared/acoes-helper/acoes-conta-corrente-helper";
@Component({
    selector: 'app-config-conta-list',
    templateUrl: './config-conta-list.component.html',
    styleUrls: ['./config-conta-list.component.scss']
})
export class ConfigContaListComponent implements OnInit {
    displayMaximizable: boolean;
    tiposConta: any[];
    tributos: any[];
    naturezaContaCorrentes: any[];
    origemInformacaos: any[];
    configContaSearchRequest: ConfigContaSearchRequest;
    configContaSearchResponses$ = this.store.select('app').pipe(
        map(e => e.configContas)
    );
    configContaRegraCriacao: ConfigContaRegraCriacao;
    tipoConta: any;
    tributo: any;
    pagination: Pagination<any>;

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };
    blockedDocument = false;
    @ViewChild('paginator', { static: true }) paginator: Paginator;
    optionsSituacao = [
        {label: 'Todos', value: ''},
        {label: 'Inativa', value: 'INATIVA'},
        {label: 'Ativa', value: 'ATIVA'},
    ]

    constructor(
        private http: HttpClient,
        private store: Store<{ app: IConfigContaSearchModalState }>,
        private storeRegraCriacaoView: Store<{ regraCriacaoView: { configContaRegraCriacao: ConfigContaRegraCriacao } }>,
        private storeConfigContaSearchModal: Store<{ configContaSearchModal: { displayMaximizable: boolean } }>,
        private router: Router,
        private contaCorrenteService: ContaCorrenteService,
        private utilService: UtilsService,
        private alertUtil: AlertsUtil,

    ) {
        this.configContaSearchRequest = new ConfigContaSearchRequest();

    }

    ngOnInit(): void {
        if (!this.podeListarContasCorrente()) {
            this.router.navigate(['/']);
        }

        this.contaCorrenteService.configComposicaoConta = new ConfigComposicaoConta()

        this.pagination = new Pagination<any>();
        this.search();
        this.storeRegraCriacaoView
            .select('regraCriacaoView')
            .subscribe(data => this.configContaRegraCriacao = JSON.parse(JSON.stringify(data.configContaRegraCriacao)));

        this.storeConfigContaSearchModal
            .select('configContaSearchModal')
            .subscribe(data => this.displayMaximizable = data.displayMaximizable);

        this.http
            .get<Array<any>>(`${environment.baseUrl}/enums/config-conta/tipo-conta`)
            .subscribe(response => {
                this.tiposConta = response;
            });

        this.http
            .get<Array<any>>(`${environment.baseUrl}/tributo`)
            .subscribe(response => {
                this.tributos = response;
            });
        this.http
            .get<Array<any>>(`${environment.baseUrl}/natureza-conta-corrente`)
            .subscribe(response => {
                this.naturezaContaCorrentes = response;
            });
        this.http
            .get<Array<any>>(`${environment.baseUrl}/origem-informacao`)
            .subscribe(response => {
                this.origemInformacaos = response;
            });
    }

    showMaximizableDialog() {
        this.displayMaximizable = true;
    }

    search(resetPage?) {
        this.converteData();
        // if(resetPage){
        //     this.pagination.pageable.pageNumber = 0;
        //     setTimeout(() => {
        //         this.paginator.changePage(0);
        //     }, 600);
        // }
        this.blockedDocument = true;
        this.http
            .get<Pageable<any>>(`${environment.baseUrl}/config-conta/search`, {
                params: {
                    ...this.configContaSearchRequest,
                    size: this.pagination.size,
                    page: this.pagination.pageable.pageNumber,
                }
            })
            .subscribe(response => {
                const ccm: ConfigConta[] = response.content;
                this.pagination = response;
                this.store.dispatch(setConfigContas({cc: ccm}));
                this.blockedDocument = false;
            }, erro => {
                this.alertUtil.handleError(erro);
                this.blockedDocument = false;
                this.reseteData()
            });
    }

    reseteData(){
        if(this.configContaSearchRequest.dataInicioVigencia) {
            this.configContaSearchRequest.dataInicioVigencia =
            this.utilService.convertDateToEdit(this.configContaSearchRequest.dataInicioVigencia);
        }

        if(this.configContaSearchRequest.dataFimVigencia) {
            this.configContaSearchRequest.dataFimVigencia =
            this.utilService.convertDateToEdit(this.configContaSearchRequest.dataFimVigencia);
        }
    }

    converteData() {
        if(this.configContaSearchRequest.dataInicioVigencia) {
            this.configContaSearchRequest.dataInicioVigencia =
            this.utilService.convertDateToSave(this.configContaSearchRequest.dataInicioVigencia);
        }

        if(this.configContaSearchRequest.dataFimVigencia) {
            this.configContaSearchRequest.dataFimVigencia =
            this.utilService.convertDateToSave(this.configContaSearchRequest.dataFimVigencia);
        }
    }

    onPageChange(e) {
        this.pagination.pageable.pageNumber = e.page;
        this.search();
    }

    nova() {
        this.resetStorageContaCorrente();
        this.router.navigate(['/config-conta-corrente']);
    }

    onSelectDataInicioVigencia(event) {
        this.configContaSearchRequest.dataInicioVigencia = moment(event).format('DD/MM/YYYY');
    }
    onChangeDataInicioVigencia(event) {
        this.configContaSearchRequest.dataInicioVigencia = event.target.value || '';
    }

    onSelectDataFimVigencia(event) {
        this.configContaSearchRequest.dataFimVigencia = moment(event).format('DD/MM/YYYY');
    }
    onChangeDataFimVigencia(event) {
        this.configContaRegraCriacao.dataFimVigencia = event.target.value || '';
    }

    convertDateBr(data) {
        return data == null ? '' : moment(data).format('DD/MM/YYYY');
    }

    select(cc) {
        this.configContaRegraCriacao.configConta = JSON.parse(JSON.stringify(cc));
        this.storeRegraCriacaoView.dispatch(new RegraCriacaoViewActions.SetConfigContaRegraCriacao(this.configContaRegraCriacao));
        this.displayMaximizable = false;
    }

    onEditar(configConta: ConfigConta) {
        this.resetStorageContaCorrente();
        this.router.navigate(['/config-conta-corrente'], { state: { configConta } });
    }

    onChangeOrigemInformacao(event) {
        if (event.value === null) {
            this.configContaSearchRequest.origemInformacaoId = '';
        }
    }
    onChangeTributo(event) {
        if (event.value === null) {
            this.configContaSearchRequest.tributoId = '';
        }
    }
    onChangeNaturezaContaCorrente(event) {
        if (event.value === null) {
            this.configContaSearchRequest.naturezaContaCorrenteId = '';
        }
    }

    /**reset storage */
    resetStorageContaCorrente(){
        this.contaCorrenteService.configComposicaoConta = new ConfigComposicaoConta();
        sessionStorage.clear();
    }

    podeCadastrarContasCorrente(): boolean {
        return AcoesContaCorrenteHelper.podeCadastrarContasCorrente;
    }

    podeEditarContasCorrente(): boolean {
        return AcoesContaCorrenteHelper.podeEditarContasCorrente;
    }

    podeExcluirContasCorrente(): boolean {
        return AcoesContaCorrenteHelper.podeExcluirContasCorrente;
    }

    podeListarContasCorrente(): boolean {
        return AcoesContaCorrenteHelper.podeListarContasCorrente;
    }

}
