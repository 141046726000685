<!-- COMPONENTE NÃO UTILIZADO - ANTIGO ABA CONTA CORRENTE -->
<p-messages [(value)]="msgs1" [enableService]="false"></p-messages>
<p-tabView (onChange)="handleChange($event)">
    <p-tabPanel header="Conta Corrente" title="ContaCorrente">
        <app-config-conta-corrente-tab-view (outConfigConta)="outConfigConta($event)">
        </app-config-conta-corrente-tab-view>
    </p-tabPanel>

    <p-tabPanel header="Composição da Conta" [disabled]="!this.contaCorrenteService.configConta.configContaId == null" title="ComposicaoConta">
        <app-config-composicao-conta-tab-view (outHabilitaSalvar)="habilitaSalvar($event)">
        </app-config-composicao-conta-tab-view>
    </p-tabPanel>

    <p-tabPanel header="Modelo" [disabled]="!this.contaCorrenteService.configConta.configContaId == null" title="ComposicaoConta">
        <app-associar-modelo-conta-corrente (outHabilitaSalvar)="habilitaSalvar($event)" [configConta]="this.contaCorrenteService.configConta">
        </app-associar-modelo-conta-corrente>
    </p-tabPanel>

    <p-tabPanel header="Receita" [disabled]="!this.contaCorrenteService.configConta.configContaId == null" title="Receita">
        <app-config-associar-receita-tab-view (outHabilitaSalvar)="habilitaSalvar($event)" [configConta]="this.contaCorrenteService.configConta">
        </app-config-associar-receita-tab-view>
    </p-tabPanel>

    <p-tabPanel header="Base Legal" [disabled]="!this.contaCorrenteService.configConta.configContaId == null" title="BaseLegal">
        <app-config-conta-base-legal-tab-view [configConta]="this.contaCorrenteService.configConta"></app-config-conta-base-legal-tab-view>
    </p-tabPanel>

    <p-tabPanel header="Unidade Organizacional" [disabled]="!this.contaCorrenteService.configConta.configContaId == null" title="UnidadeOrg">
        <app-config-conta-unid-organiza-tab-view [configConta]="this.contaCorrenteService.configConta"></app-config-conta-unid-organiza-tab-view>
    </p-tabPanel>

    <div style="margin-top: 10px; text-align: right;">
        <button style="margin-right: 5px;" pButton type="button" label="Voltar" class="p-button-outlined p-mr-2"
            (click)="voltar()"></button>
        <p-button *ngIf="btnSalvarReceita" [label]="salvarEditarConta" (click)="alertSaveConta()"></p-button>
        <p-button *ngIf="btnProximo" [label]="labelBtnProximo" (click)="saveProximo()"></p-button>
    </div>

</p-tabView>
