import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RegraCriacaoConta} from 'src/app/models/regra-criacao-conta';
import {ConfigContaRegraCriacaoSaveRequest} from 'src/app/request/config-conta-regra-criacao-save-request';
import {RegraCriacaoSearchRequest} from 'src/app/request/RegraCriacaoSearchRequest';
import {Pagination} from 'src/app/shared/pagination/pagination';
import {UtilsService} from 'src/app/shared/utils.service';
import {RegraCriacaoListServiceService} from '../../regra-criacao/regra-criacao-list/regra-criacao-list-service.service';
import {AssociarModeloContaService} from './associar-modelo-conta-corrente.service';

import {MenuItem, Message} from 'primeng/api';
import swal from 'sweetalert2';
import Swal from 'sweetalert2';
import {ConfigContaRegraCriacaoSearchRequest} from 'src/app/request/config-conta-regra-criacao-search-request';
import {ConfigContaRegraCriacao} from 'src/app/models/config-conta-regra-criacao';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ContaCorrenteService} from 'src/app/core/conta-corrente.service';
import {ConfigModelo} from 'src/app/models/config-modelo';
import {ConfigAssociarReceitaService} from '../config-associar-receita-tab-view/config-associar-receita.service';
import { RemoveParamsUtil } from 'src/app/utils/paramsRemove/paramsRemove.util';

@Component({
    selector: "app-associar-modelo-conta-corrente",
    templateUrl: "./associar-modelo-conta-corrente.component.html",
    styleUrls: ["./associar-modelo-conta-corrente.component.scss"],
})
export class AssociarModeloContaCorrenteComponent implements OnInit {
    formGroupModelo: FormGroup;
    msgs1: Message[];
    regraCriacaoSearchRequest: RegraCriacaoSearchRequest;
    regraCriacaoConta: RegraCriacaoConta[] = [];
    configContaRegraCriacaoSaveRequest =
        new ConfigContaRegraCriacaoSaveRequest();
    configContaRegraCriacao: ConfigContaRegraCriacao[] = [];
    searchRequest: ConfigContaRegraCriacaoSearchRequest;
    pagination: Pagination<any>;
    regraCriacaoContaView: any = [];

    @Input() configConta;
    // configConta: any;

    @Output() outHabilitaSalvar = new EventEmitter();

    habilitaCancelar: boolean;
    icone = "pi pi-plus-circle";
    btnSalvarAtualizar: string = "Adicionar Modelo";
    submitted = false;
    dataAtual: any;
    items: MenuItem[];
    configModelo: ConfigModelo

    constructor(
        private regraCriacaoService: RegraCriacaoListServiceService,
        private utilService: UtilsService,
        private associarModeloContaService: AssociarModeloContaService,
        private formBuilder: FormBuilder,
        private router: Router,
        private contaCorrenteService: ContaCorrenteService,
        private utils: UtilsService,
        private configAssociarReceitaService: ConfigAssociarReceitaService,
        private removeParamsUtil: RemoveParamsUtil,

    ) {
        this.treatNextPage();
    }

    treatNextPage(): void {
        let redirect = this.router
            .getCurrentNavigation().extras?.state?.nextPageUntil;

        if(redirect) {
            if(this.router.url !== redirect) {
                this.ngOnInit();
                this.nextPage();
            }
        }
    };

    ngOnInit(): void {
        const objContaSession = sessionStorage.getItem('objConta');
        if (objContaSession) {
            this.configConta = JSON.parse(objContaSession);
        }
        this.regraCriacaoSearchRequest = new RegraCriacaoSearchRequest();
        this.searchRequest = new ConfigContaRegraCriacaoSearchRequest();
        this.pagination = new Pagination<any>();
        this.items = this.contaCorrenteService.getEtapaConta();

        const configModeloStorage = sessionStorage.getItem("objModelo");
        this.configModelo = configModeloStorage ? JSON.parse(configModeloStorage) : this.contaCorrenteService.getConfigModeloInformtation();

        this.formInit(this.configModelo);

        if(this.configConta.configContaId !== null){
            this.init();
        }
        this.getModelo();
        this.converteData();
        if(!this.configConta.configContaId){
            this.modeloFormControl.dataInicioVigencia.setValue(this.convertDateBr(this.configConta?.dataInicioVigencia));
        } else {
            this.modeloFormControl.dataInicioVigencia.setValue(null);
        }
    }

    converteData(){
        if(this.configModelo?.dataInicioVigencia){
            this.configModelo.dataInicioVigencia = this.utilService.convertToDateBr(this.configModelo.dataInicioVigencia);
        }
        if(this.configModelo?.dataFimVigencia){
            this.configModelo.dataFimVigencia = this.utilService.convertToDateBr(this.configModelo.dataFimVigencia);
        }
    }

    getAutomaticNextPage() {
        let redirect = this.router
            .getCurrentNavigation()?.extras?.state?.nextPageUntil;

        if(redirect) {
            if(this.router.url !== redirect) {
                return redirect;
            }
        }

        return null;
    }

    nextPage() {
        const tipoConta = this.configConta?.tipoConta;
        const tributo = this.configConta?.tributo;

        let tributaria: any = "";
        let vinculoConta: any = "";
        let codTributo: any = "";

        if (tipoConta === "TRIBUTARIA") {
            tributaria = 1;
            vinculoConta = 1;
            codTributo = this.configConta?.tributo?.codTributo;
        } else if (tipoConta === "NAO_TRIBUTARIA") {
            tributaria = 0;
            vinculoConta = 0;
        }
        let automaticNextPage = this.getAutomaticNextPage();
        this.associarModeloContaService.getReceitas(tributaria, vinculoConta, codTributo)
            .subscribe(r => {
                this.configAssociarReceitaService.receitas = r;
                if (r.length > 0) {
                    if(this.formGroupModelo.valid || this.configConta.configContaId){
                        this.configModelo.regraCriacaoContaId = this.modeloFormControl.regraCriacaoContaId.value
                        this.configModelo.dataInicioVigencia = this.modeloFormControl.dataInicioVigencia.value
                        this.configModelo.situacao = this.modeloFormControl.situacao.value

                        sessionStorage.setItem("objModelo", JSON.stringify(this.configModelo))

                        this.router
                            .navigate(['/config-receita'], {
                                state: { nextPageUntil: automaticNextPage },
                            })
                            .then(() => {});

                    }
                    this.submitted = true;
                } else {
                    let msg = "";
                    if (tipoConta === "TRIBUTARIA")
                        msg = `Não há receitas cadastradas para o Tributo <b>${tributo?.codTributo} - ${tributo?.sigla}</b>`
                    else if (tipoConta === "NAO_TRIBUTARIA")
                        msg = `Não há receitas não tributárias cadastradas`
                    Swal.fire(
                        "Atenção!",
                        msg,
                        "warning")
                        .then(() => {});
                }
            });


    }

    prevPage() {
        this.contaCorrenteService.configModelo = this.formGroupModelo.value
        this.router.navigate(['/config-composicao']);
    }


    init() {
        this.getConfigContaRegraCriacao();
        this.verificaDataMaiorQueAtual();
    }


    formInit(configModelo: ConfigModelo): void {
        this.configModelo.dataFimVigencia = '';
        this.formGroupModelo = this.formBuilder.group({
            regraCriacaoContaId: [configModelo ? configModelo?.regraCriacaoContaId : null , [Validators.required]],
            dataInicioVigencia: [configModelo ? configModelo?.dataInicioVigencia: null, [Validators.required]],
            configContaId: [configModelo ? configModelo?.configContaId : null],
            configContaRegraCriacaoId: [configModelo ? configModelo?.configContaRegraCriacaoId : null],
            dataFimVigencia: [configModelo.dataFimVigencia ? configModelo?.dataFimVigencia : ''],
            situacao: ['ATIVA'],
        });
    }

    getConfigContaRegraCriacao() {
        const data = JSON.parse(JSON.stringify(this.searchRequest));
        data.configContaId =
            this.configConta.configContaId === null
                ? ""
                : this.configConta.configContaId;
        this.associarModeloContaService
            .pesquisaRegraCriacao(data, this.pagination)
            .subscribe((response) => {
                this.configContaRegraCriacao = response.content;
                this.pagination = response;
                this.iniciaData(this.configContaRegraCriacao);
            });
    }

    iniciaData(configContaRegraCriacao) {
        if (configContaRegraCriacao.length == 0) {
            this.modeloFormControl.dataInicioVigencia.setValue(
                this.configConta.dataInicioVigencia
            );
        }
    }

    getModelo() {
        const tipoConta = this.configConta?.tipoConta
        const data: RegraCriacaoSearchRequest = JSON.parse(JSON.stringify(this.regraCriacaoSearchRequest));
        data.situacao = "ATIVA"
        if (tipoConta === 'NAO_TRIBUTARIA') {
            data.tributoIdIsNull = true;
        } else if (tipoConta === 'TRIBUTARIA') {
            data.tributoId = this.configConta?.tributo?.tributoId;
        }

        let objParams = this.removeParamsUtil.removeParametroNaoUtilizado(data);

        let page = this.pagination;
        page.size = 1000;

        this.regraCriacaoService
            .pesquisarRegrasCriacao(objParams, page)
            .subscribe((response) => {
                this.regraCriacaoConta = response.content;
                this.regraCriacaoConta = this.regraCriacaoConta.map(
                    (regraCriacaoContaId: any) => {
                        return {
                            ...regraCriacaoContaId,
                            displayLabel: `${regraCriacaoContaId.regraCriacaoContaId} - ${regraCriacaoContaId.descricaoCompleta}`,
                        };
                    }
                );

            });
    }

    onSubmit() {
        this.submitted = true;
        if (this.formGroupModelo.valid) {
            this.adicionarModelo();
        }
    }

    get modeloFormControl() {
        return this.formGroupModelo.controls;
    }

    adicionarModelo() {
        const data = this.prepareToSend();

        this.associarModeloContaService
            .postConfigContaRegraCriacao(data)
            .subscribe(
                (resp) => {
                    swal.fire("Sucesso", "Salvo com sucesso", "success")
                        .then(() => {this.getConfigContaRegraCriacao();});
                    this.reset();
                    this.outHabilitaSalvar.emit('CONFIG_MODELO');
                },
                (error) => {
                    if (error.status === 500) {
                        swal.fire(
                            error.statusText,
                            "Ocorreu um erro interno",
                            "error"
                        );
                    } else if (error.status === 412) {
                        swal.fire({
                            title: "Atenção",
                            text: `${error.error.message}`,
                            icon: "warning",
                        });
                    } else if (error.status === 400) {
                        const errors = error.error.errors;
                        this.msgs1 = [];
                        errors.forEach((e: { message: any }) => {
                            this.msgs1.push({
                                severity: "error",
                                summary: "Erro",
                                detail: e.message,
                            });
                        });
                    }
                }
            );
    }

    editar(request: any) {
        this.habilitaCancelar = true;
        this.icone = "pi pi-pencil";
        this.btnSalvarAtualizar = "Atualizar Modelo";

        this.modeloFormControl.configContaRegraCriacaoId.setValue(
            request.configContaRegraCriacaoId
        );
        this.modeloFormControl.regraCriacaoContaId.setValue(
            request.regraCriacaoConta.regraCriacaoContaId
        );
        this.modeloFormControl.dataInicioVigencia.setValue(
            this.convertDateBr(request.dataInicioVigencia)
        );
    }

    verificaDataMaiorQueAtual(){
        this.dataAtual = this.utilService.convertDateToSave(new Date());
    }

    prepareToSend() {
        this.modeloFormControl.configContaId.setValue(
            this.configConta.configContaId
        );
        const data = JSON.parse(JSON.stringify(this.formGroupModelo.value));
        data.dataInicioVigencia = this.utilService.convertDateToSave(
            this.modeloFormControl.dataInicioVigencia.value
        );

        return data;
    }

    onPageChange(e) {
        this.pagination.pageable.pageNumber = e.page;
    }

    remove(configContaRegraCriacao) {
        swal.fire({
            text: `Deseja excluir a associação do modelo ${configContaRegraCriacao.configContaRegraCriacaoId}
             - ${configContaRegraCriacao.regraCriacaoConta.descricaoResumida} com a conta ${configContaRegraCriacao.configConta.configContaId} -
             ${configContaRegraCriacao.configConta.nomeResumido}?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
        }).then((result) => {
            if (result.value) {
                this.regraCriacaoService
                    .deleteRegraCriacao(
                        configContaRegraCriacao.configContaRegraCriacaoId
                    )
                    .subscribe(
                        (response) => {
                            swal.fire(
                                "Sucesso",
                                "Removido com sucesso",
                                "success"
                            ).then((r) => this.getConfigContaRegraCriacao());
                        },
                        (error) => {
                            if (error.status === 500 || error.status === 404) {
                                swal.fire(
                                    error.statusText,
                                    "Ocorreu um erro interno",
                                    "error"
                                ).then((r) => {});
                            } else if (error.status === 412) {
                                swal.fire(
                                    "Erro de Validação",
                                    error.error.message,
                                    "error"
                                ).then((r) => {});
                            } else if (error.status === 400) {
                                const errors = error.error.errors;
                                this.msgs1 = [];
                                errors.forEach((e) => {
                                    this.msgs1.push({
                                        severity: "error",
                                        summary: "Erro",
                                        detail: e.message,
                                    });
                                });
                                this.scrollToTop();
                            }
                        }
                    );
            } else {
                result.dismiss === swal.DismissReason.cancel;
            }
        });
    }

    scrollToTop() {
        (function smoothscroll() {
            const currentScroll =
                document.documentElement.scrollTop || document.body.scrollTop;
            if (currentScroll > 0) {
                window.requestAnimationFrame(smoothscroll);
                window.scrollTo(0, currentScroll - currentScroll / 8);
            }
        })();
    }

    convertDateBr(data) {
        return this.utilService.convertToDateBr(data);
    }

    reset() {
        this.submitted = false;
        this.habilitaCancelar = false;
        this.icone = "pi pi-plus-circle";
        this.btnSalvarAtualizar = "Adicionar Modelo";
        this.configContaRegraCriacaoSaveRequest =
            new ConfigContaRegraCriacaoSaveRequest();
        this.formGroupModelo.reset();
        this.modeloFormControl.situacao.setValue("ATIVA")
    }
}
