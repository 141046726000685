import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Pagination } from 'src/app/shared/pagination/pagination';
import { AlertsUtil } from 'src/app/utils/alerts/alerts.util';
import { NaturezaItemCalculoEnum } from 'src/app/utils/enums/naturezaItemCalculo.enum';
import { ExtratoContaCorrenteService } from '../../service/extrato-conta-corrente.service';

@Component({
    selector: "ccp-visualiza-extrato-veiculo",
    templateUrl: "./visualiza-extrato-veiculo.component.html",
    styleUrls: ["./visualiza-extrato-veiculo.component.scss"],
})
export class VisualizaExtratoVeiculoComponent implements OnInit {
    
    @Input() listaVeiculos;
    @Input() renavam;
    @Input() cpfOuCnpj;
    
    pagination: Pagination<any>;
    items: MenuItem[];
    conta: any[] = [];
    showTransacoesAndEventos: boolean;
    showExtratoVeiculos: boolean;
    tributos: any[] = [];
    contaCorrente: any[];
    contaExtratoVeiculo: any;
    objFilterExtatoContaCorrente: any;

    constructor(
        private extratoContaService: ExtratoContaCorrenteService,
        private alertUtil: AlertsUtil
    ) {}

    ngOnInit(): void {
        this.pagination = new Pagination<any>();
    }

    /**emit filtro componente visualiza extrato */
    pesquisaEmit(filter){
        this.resetConsulta();
        /**Filtro */
        this.objFilterExtatoContaCorrente = filter;
        this.getIpva();
    }

    resetConsulta(){
        this.items = [];
        this.tributos = [];
        this.contaExtratoVeiculo = [];
        this.conta = [];
    }

    getIpva() {
        const dadosVeiculoContribuinte = [];
        const objParams = {
            cpfCnpjRenavam: this.cpfOuCnpj,
            renavam: this.renavam,
        };
        this.extratoContaService.getVeiculoSearch(objParams).subscribe(
            (resp: any) => {
                resp.content.forEach((dadosIpva) => {
                    dadosVeiculoContribuinte.push({
                        label: `Renavam: ${dadosIpva.renavam} - Chassi: ${dadosIpva.chassi} - Proprietário: ${dadosIpva.nome}`,
                        icon: "pi pi-pw pi-file",
                        items: [],
                        command: () => {
                            if(this.tributos.length === 0){
                                this.getTributo(dadosIpva.renavam);
                            }
                        },
                    });
                });
                this.items = dadosVeiculoContribuinte;
            },
            (erro) => {
                this.alertUtil.handleError(erro);
            }
        );
    }

    getTributo(codigoCadastro) {
        this.tributos = [];
        const obj = {
            sigla: this.objFilterExtatoContaCorrente.tributo[0].sigla,
        };
            this.extratoContaService.getTributoByVeiculo(obj).subscribe(
                (resp) => {
                    this.tributos = resp;
                    this.getConta(this.tributos, codigoCadastro);
                },
                (erro) => {
                    this.alertUtil.handleError(erro);
                }
            );
    }

    getConta(tributosResp, codigoCadastro) {
        this.contaCorrente = [];
        const objFilter = {
            configContaId: this.objFilterExtatoContaCorrente.conta[0].configContaId,
            situacao: "ATIVA",
            tipoCodigoCadastro: "RENAVAM",
            tipoConta: this.objFilterExtatoContaCorrente.tipoConta,
            tributoId: tributosResp[0].tributoId,
        };
        this.extratoContaService.getConfigConta(objFilter).subscribe(
            (resp) => {
                this.contaCorrente = resp;
                this.items[0].items.splice(1, 0, {
                    label: `Tributo: ${tributosResp[0].sigla}`,
                    icon: "pi pi-pw pi-file",
                    command: () => {
                        this.getContaCorrente(
                            this.contaCorrente,
                            codigoCadastro
                        );
                    },
                });
            },
            (erro) => {
                this.alertUtil.handleError(erro);
            }
        );
    }

    getContaCorrente(contaCorrente?, numRenavam?) {
        let novoArray = [];
        contaCorrente.forEach((contaCorrente) => {
            const obj = {
                //1285 - cONTA: ipva - notificaçâo lançamento
                /**Dados temporarios:  1285 - cONTA: ipva - notificaçâo lançamento, codigoCadastro: "933925301"*/
                /**Pegar valor do CPF usuário CCP-233 */
                codigoCadastro: this.objFilterExtatoContaCorrente.renavam || this.objFilterExtatoContaCorrente.inscEstadual,
                configContaId: contaCorrente.configContaId,
                periodoReferenciaFinal: this.objFilterExtatoContaCorrente.dataFim,
                periodoReferenciaInicial: this.objFilterExtatoContaCorrente.dataInicio,
                estadoContaId: this.objFilterExtatoContaCorrente.estadoConta[0]?.estadoContaId
            };
            this.extratoContaService.getContaCorrenteAll(obj).subscribe(
                (resp) => {
                    this.conta = resp;
                    let objTemp = { contas: this.conta };
                    const calculaTotal = this.calculaTotal(this.conta);
                    novoArray.push({
                        ...contaCorrente,
                        ...objTemp,
                        ...calculaTotal,
                    });
                    this.contaExtratoVeiculo = novoArray;
                    this.showExtratoVeiculos = true;
                },
                (erro) => {
                    this.alertUtil.handleError(erro);
                }
            );
        });
    }

    verificaNaturezaItemCalculoPrincipal(financeiro) {
        const principal = financeiro.find(
            (aux) =>
                aux.naturezaItemCalculo.naturezaItemCalculoId ==
                NaturezaItemCalculoEnum.PRINCIPAL
        );
        return principal.saldoAtualizado;
    }

    verificaNaturezaItemCalculoJuros(financeiro) {
        const juros = financeiro.find(
            (aux) =>
                aux.naturezaItemCalculo.naturezaItemCalculoId ==
                NaturezaItemCalculoEnum.JUROS_MORA
        );
        return juros.saldoAtualizado;
    }

    verificaNaturezaItemCalculoMulta(financeiro) {
        const multa = financeiro.find(
            (aux) =>
                aux.naturezaItemCalculo.naturezaItemCalculoId ==
                NaturezaItemCalculoEnum.MULTA_MORA
        );
        return multa.saldoAtualizado;
    }

    calculaTotal(conta) {
        let financeiros = [];
        let total: number = 0;
        let principal: number = 0;
        let juros: number = 0;
        let multa: number = 0;

        conta.forEach((contaItems) => {
            total = total + contaItems.saldoTotalAtualizado;
            financeiros = contaItems.contaCorrenteFinanceiros;
            financeiros.forEach((naturezaItems) => {
                switch (
                    naturezaItems.naturezaItemCalculo.naturezaItemCalculoId
                ) {
                    case NaturezaItemCalculoEnum.JUROS_MORA:
                        juros = juros + naturezaItems.saldoAtualizado;
                        break;
                    case NaturezaItemCalculoEnum.PRINCIPAL:
                        principal = principal + naturezaItems.saldoAtualizado;
                        break;
                    case NaturezaItemCalculoEnum.MULTA_MORA:
                        principal = principal + naturezaItems.saldoAtualizado;
                        break;
                    default:
                        break;
                }
            });
        });
        return {
            total: total,
            juros: juros,
            principal: principal,
            multa: multa,
        };
    }

    detalheTransacaoAndEventos() {
        // this.showTransacoesAndEventos = true
    }

    onPageChangeVeiculos(e) {
        this.pagination.pageable.pageNumber = e.page;
        //pesquisa conta
    }

    onPageChangeTransacao(e) {
        this.pagination.pageable.pageNumber = e.page;
        //pesquisa transacoes
    }

    onPageChangeEvento(e) {
        this.pagination.pageable.pageNumber = e.page;
        //pesquisa eventos
    }

    substringAno(ano) {
        return ano.toString().substring(0, 4);
    }
}
