import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { LazyLoadEvent } from "primeng/api";
import { ModalEventoContaCorrenteComponent } from "src/app/modal/modal-evento-conta-corrente/modal-evento-conta-corrente.component";
import { Pagination } from "src/app/shared/pagination/pagination";
import { AlertsUtil } from "src/app/utils/alerts/alerts.util";
import { ConsultaContaCorrenteService } from "../../services/consulta-conta-corrente.service";

@Component({
    selector: "ccp-detalhe-eventos-transacoes",
    templateUrl: "./detalhe-eventos-transacoes.component.html",
    styleUrls: ["./detalhe-eventos-transacoes.component.scss"],
})
export class DetalheEventosTransacoesComponent implements OnInit {
    @Input() conta;
    @ViewChild(ModalEventoContaCorrenteComponent, { static: false })
    modalEventoConta: ModalEventoContaCorrenteComponent;

    contaCorrenteId: number = null;
    transacaoList: any[] = [];
    eventosList: Pagination<any>;
    contaCorrenteEventoIdSelecionada: number = null;

    constructor(
        private consultaContaCorrenteService: ConsultaContaCorrenteService,
        private alertUtil: AlertsUtil
    ) {}

    ngOnInit(): void {
        this.conta = JSON.parse(localStorage.getItem("conta"));
        this.contaCorrenteId = this.conta?.contaCorrenteId;
        this.getDetalheEventos();
        this.getDetalheTransacoes();
    }

    getDetalheEventos(page = 0, descricaoEvento = null) {
        this.consultaContaCorrenteService
            .getDetalheEventos(this.contaCorrenteId, page, descricaoEvento)
            .subscribe((response: any) => {
                this.eventosList = response;
            });
    }

    paginar(event: LazyLoadEvent) {
        const page = event.first / event.rows;
        const descricaoEvento = event.filters?.global?.value;
        this.getDetalheEventos(page, descricaoEvento);
    }

    /**cada clique de evento, exibe as transações por evento */
    getDetalheTransacoes() {
        this.consultaContaCorrenteService
            .getTransacoesAgrupadaPorEventoData(this.contaCorrenteId)
            .subscribe(
                (resp: any) => {
                    this.transacaoList = resp;
                },
                (error) => {
                    this.alertUtil.handleError(error);
                }
            );
    }

    buscaTransacoesVinculoEvento(contaCorrenteEventoId) {
        if (contaCorrenteEventoId === this.contaCorrenteEventoIdSelecionada) {
            this.contaCorrenteEventoIdSelecionada = null;
        } else {
            this.contaCorrenteEventoIdSelecionada = contaCorrenteEventoId;
        }
    }

    vinculoEvento(contaCorrenteEventoId) {
        return contaCorrenteEventoId === this.contaCorrenteEventoIdSelecionada;
    }

    openModalEvento(evento) {
        this.modalEventoConta.showDialog(evento);
    }
}
