import { ConfigTransacao } from "./config-transacao";

export class EventoPagamentoTransacaos {
    eventoPagamentoTransacaoId: number
    dataInicioVigencia: string;
    dataFimVigencia: string;
    // transacao: ConfigTransacao[];
    transacao: ConfigTransacao;

    constructor(init?: Partial<EventoPagamentoTransacaos>) {
        if (init) {
            Object.assign(this, init);
        } else {
            this.dataInicioVigencia = "";
            this.dataFimVigencia = "";
            // this.transacao = [];
            this.transacao = null;
            this.eventoPagamentoTransacaoId = null;
        }

    }
}
