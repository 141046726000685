<p-card>
    <h2 class="titulo font-raleway font-semibold text-[#2E3468] text-2xl leading-[35px] mb-6 text-left flex items-center">
        <i class="fas fa-arrow-left text-red-600 mr-2" style="color: #EB2939;"></i>
        Relatório de Conta Corrente
    </h2>
</p-card>

<br>

<p-card class="second-card">
    <div class="relatorio-container p-6">
        <h2 class="sub-titulo text-xl font-semibold p-text-left mb-6">Filtros do Relatório</h2>

        <div class="p-fluid p-formgrid p-grid">
            <div class="filtros-relatorio p-field p-col-6 p-md-3">
                <p-dropdown
                    placeholder="Selecione o Contribuinte"
                    class="w-full"
                    [options]="contribuinteList"
                    [(ngModel)]="filtro.tipoDocumentoContribuinte"
                    showClear="true" (onChange)="contribuinteChange()"
                    (onChange)="paramContribuinte()"
                    [filter]="true"
                ></p-dropdown>
            </div>

            <div class="filtros-relatorio p-field p-col-6 p-md-3" *ngIf="!isRenavamOuInscricao()">
                <p-inputMask
                    [disabled]="!filtro.tipoDocumentoContribuinte"
                    [mask]="maskCustom"
                    [maxlength]="maximoCaracteres"
                    placeholder="Número de Documento Contribuinte"
                    type="text"
                    [(ngModel)]="filtro.numeroDocumentoContribuinte"
                ></p-inputMask>
            </div>

            <div class="filtros-relatorio p-field p-col-6 p-md-3" *ngIf="isRenavamOuInscricao()">
                <p-inputNumber
                    [disabled]="!filtro.tipoDocumentoContribuinte"
                    [maxlength]="maximoCaracteres"
                    placeholder="Número de Documento Contribuinte"
                    type="text"
                    [(ngModel)]="filtro.numeroDocumentoContribuinte"
                    [useGrouping]="false"
                ></p-inputNumber>
            </div>

            <div class="filtros-relatorio p-field p-col-6 p-md-3">
                <p-dropdown
                    placeholder="Conta"
                    class="w-full"
                    [options]="contaList"
                    [(ngModel)]="filtro.configConta"
                    optionLabel="descricao"
                    showClear="true"
                    [filter]="true"
                ></p-dropdown>
            </div>

            <div class="filtros-relatorio p-field p-col-6 p-md-3">
                <p-dropdown
                    placeholder="Estado da Conta"
                    class="w-full"
                    [options]="estadoContaList"
                    [(ngModel)]="filtro.estadoConta"
                    optionLabel="descricao"
                    showClear="true"
                    [filter]="true"
                ></p-dropdown>
            </div>

            <div class="filtros-relatorio p-field p-col-6 p-md-3">
                <p-dropdown
                    placeholder="Situação do Crédito"
                    class="w-full"
                    [options]="situacaoCreditoList"
                    [(ngModel)]="filtro.situacaoCreditoConta"
                    optionLabel="descricao"
                    showClear="true"
                    [filter]="true"
                ></p-dropdown>
            </div>

            <div class="filtros-relatorio p-field p-col-6 p-md-3">
                <p-dropdown
                    placeholder="Saldo da Conta"
                    class="w-full"
                    [options]="saldoContaList"
                    [(ngModel)]="filtro.saldoConta"
                    optionLabel="descricao"
                    showClear="true"
                    [filter]="true"
                ></p-dropdown>
            </div>

            <div class="filtros-relatorio p-field p-col-6 p-md-3">
                <p-dropdown
                    placeholder="Evento"
                    class="w-full"
                    [options]="eventoList"
                    [(ngModel)]="filtro.configEvento"
                    optionLabel="descricao"
                    showClear="true"
                    [filter]="true"
                ></p-dropdown>
            </div>

            <div class="filtros-relatorio p-field p-col-6 p-md-3">
                <p-dropdown
                    placeholder="Situação da Conta"
                    class="w-full"
                    [options]="situacaoContaList"
                    [(ngModel)]="filtro.situacaoCobranca"
                    optionLabel="descricao"
                    showClear="true"
                    [filter]="true"
                ></p-dropdown>
            </div>

            <div class="filtros-relatorio p-field p-col-6 p-md-3">
                <p-calendar
                    placeholder="Data de Referência - Inicial *"
                    class="w-full"
                    [(ngModel)]="filtro.dataReferenciaInicial"
                    [showIcon]="true"
                ></p-calendar>
            </div>

            <div class="filtros-relatorio p-field p-col-6 p-md-3">
                <p-calendar
                    placeholder="Data de Referência - Final *"
                    class="w-full"
                    [(ngModel)]="filtro.dataReferenciaFinal"
                    [disabled]="!filtro.dataReferenciaInicial"
                    [showIcon]="true"
                ></p-calendar>
            </div>
        </div>

        <div class="button-container">
            <button type="button" class="button button-secondary" (click)="limparFiltros()">
                Limpar
            </button>
            <button type="button" class="button button-primary" (click)="pesquisar()">
                Pesquisar
            </button>
        </div>

        <div class="tabela-relatorio overflow-x-auto">
            <p-table [value]="contas" [(selection)]="contasSelecionadas">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="color: #2E3468; background-color: #EDEDED; font-size: 10px">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th style="color: #2E3468; background-color: #EDEDED; font-size: 10px" *ngIf="exibirColunaConta()">Conta</th>
                        <th style="color: #2E3468; background-color: #EDEDED; font-size: 10px" *ngIf="exibirColunaEstado()">Estado da Conta</th>
                        <th style="color: #2E3468; background-color: #EDEDED; font-size: 10px" *ngIf="exibirColunaSituacaoCredito()">Situação do Crédito</th>
                        <th style="color: #2E3468; background-color: #EDEDED; font-size: 10px">Imposto</th>
                        <th style="color: #2E3468; background-color: #EDEDED; font-size: 10px">Receita</th>
                        <th style="color: #2E3468; background-color: #EDEDED; font-size: 10px" *ngIf="exibirColunaEvento()">Evento</th>
                        <th style="color: #2E3468; background-color: #EDEDED; font-size: 10px">Período de Referência</th>
                        <th style="color: #2E3468; background-color: #EDEDED; font-size: 10px">Valor</th>
                        <th style="color: #2E3468; background-color: #EDEDED; font-size: 10px">Multa</th>
                        <th style="color: #2E3468; background-color: #EDEDED; font-size: 10px">Juros</th>
                        <th style="color: #2E3468; background-color: #EDEDED; font-size: 10px">Total</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-relatorio>
                    <tr>
                        <td>
                            <p-tableCheckbox [value]="relatorio"></p-tableCheckbox>
                        </td>
                        <td style="font-size: 10px; text-align: left" *ngIf="exibirColunaConta()">{{ relatorio.configContaId + ' - ' + relatorio.configContaNomeCompleto }}</td>
                        <td style="font-size: 10px; text-align: left" *ngIf="exibirColunaEstado()">{{ relatorio.estado }}</td>
                        <td style="font-size: 10px; text-align: left" *ngIf="exibirColunaSituacaoCredito()">{{ relatorio.situacao }}</td>
                        <td style="font-size: 10px; text-align: left">{{ relatorio.imposto }}</td>
                        <td style="font-size: 10px; text-align: left">{{ relatorio.receita }}</td>
                        <td style="font-size: 10px; text-align: left" *ngIf="exibirColunaEvento()">{{ relatorio.evento }}</td>
                        <td style="font-size: 10px; text-align: left">{{ relatorio.periodoReferencia | date : "dd/MM/yyyy" }}</td>
                        <td style="font-size: 10px; text-align: end">{{ relatorio.valorPrincipal | currency:'BRL' }}</td>
                        <td style="font-size: 10px; text-align: end">{{ relatorio.valorMultaMora | currency:'BRL' }}</td>
                        <td style="font-size: 10px; text-align: end">{{ relatorio.valorJurosMora | currency:'BRL' }}</td>
                        <td style="font-size: 10px; text-align: end">{{ relatorio.valorTotal | currency:'BRL' }}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="footer">
                    <tr>
                        <td style="color: #2E3468; background-color: #EDEDED; font-size: 10px;" [colSpan]="getNumColunas()" class="text-right font-bold">
                            Total:
                        </td>
                        <td style="color: #2E3468; background-color: #EDEDED; font-size: 10px; text-align: end"
                            class="text-right font-bold">{{ totalValor | currency:'BRL' }}
                        </td>
                        <td style="color: #2E3468; background-color: #EDEDED; font-size: 10px; text-align: end"
                            class="text-right font-bold">{{ totalMulta | currency:'BRL' }}
                        </td>
                        <td style="color: #2E3468; background-color: #EDEDED; font-size: 10px; text-align: end"
                            class="text-right font-bold">{{ totalJuros | currency:'BRL' }}
                        </td>
                        <td style="color: #2E3468; background-color: #EDEDED; font-size: 10px; text-align: end"
                            class="text-right font-bold">{{ totalTotal | currency:'BRL' }}
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td class="p-text-center" colspan="12">
                            Nenhum registro encontrado
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>

        <div class="p-fluid p-formgrid p-grid">
            <div class="paginacao-esquerda p-field p-col-12 p-md-6">
                <span>Exibir</span>
                <p-dropdown [options]="rodapeModel.opcoesExibicao" [(ngModel)]="rodapeModel.itensPorPagina"
                            class="w-16 no-border" (onChange)="alterarItensPorPagina()"
                            [disabled]="pesquisaNaoRealizada()"></p-dropdown>
                <div class="linha-separacao"></div>
                <span>{{ rodapeModel.inicioItem }}-{{ rodapeModel.fimItem }} de {{ rodapeModel.totalRecords }}
                    ítens</span>
            </div>

            <div class="paginacao-direita p-field p-col-12 p-md-6">
                <span>Página</span>
                <p-dropdown [options]="rodapeModel.opcoesPagina" [(ngModel)]="rodapeModel.paginaAtual" (onChange)="carregarDados()"
                            class="w-16 no-border" [disabled]="pesquisaNaoRealizada()"></p-dropdown>
                <div class="linha-separacao"></div>
                <button pButton type="button" icon="pi pi-chevron-left" class="button-paginacao"
                        (click)="prevPage()" [disabled]="notHasPrevPage() || pesquisaNaoRealizada()"></button>
                <button pButton type="button" icon="pi pi-chevron-right" class="button-paginacao"
                        (click)="nextPage()" [disabled]="notHasNextPage() || pesquisaNaoRealizada()"></button>
            </div>
        </div>

        <div class="exportacao-titulo flex justify-end mt-6 button-container">
            <span>Escolha o Tipo de Arquivo para Baixar:</span>
        </div>
        <div class="exportacao flex justify-end mt-6 button-container">
            <div>
                <button
                    class="file-button"
                    [style]="{cursor: pesquisaNaoRealizada() ? 'default' : 'pointer' }"
                    (click)="exportarExcel()"
                    [disabled]="pesquisaNaoRealizada()"
                >
                    <img src="assets/layout/images/xls-icon.png" alt="Ícone XLS">
                </button>
                <button
                    class="file-button"
                    [style]="{cursor: pesquisaNaoRealizada() ? 'default' : 'pointer' }"
                    (click)="exportarPdf()"
                    [disabled]="pesquisaNaoRealizada()"
                >
                    <img src="assets/layout/images/pdf-icon.png" alt="Ícone PDF">
                </button>
            </div>
        </div>
    </div>
</p-card>
