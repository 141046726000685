<p-card header="Configuração de Evento de Compensação">
    <div
        style="
            margin-right: 8px;
            margin-top: 10px;
            margin-bottom: 10px;
            text-align: right !important;
        "
    >
        <button
            (click)="novaTransacao()"
            pButton
            type="button"
            icon="pi pi-plus-circle"
            label="Novo Evento"
            class="p-mr-1 p-text-left botao-adicionar"
        ></button>
    </div>
    <p-table [value]="pagination?.content || []">
        <ng-template pTemplate="header">
            <tr>
                <th>Código</th>
                <th>Descrição do Evento</th>
                <th>Implantado?</th>
                <th>Situação</th>
                <th>Início Vigência</th>
                <th>Fim Vigência</th>
                <th style="width: 100px;">Ações</th>
            </tr>
            <tr>
                <th><p-columnFilter type="text" field="eventoCompensacaoId"></p-columnFilter></th>
                <th><p-columnFilter type="text" field="descricao"></p-columnFilter></th>
                <th>
                    <p-columnFilter field="implantado" matchMode="equals" [showMenu]="false">
                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                            <p-dropdown [ngModel]="value" [options]="simNaos" (onChange)="filter($event.value)" placeholder="Todos" [showClear]="true">
                                <ng-template let-option pTemplate="item">
                                    <p-badge [value]="option.label" size="small" [severity]="option.value === 'SIM' ? 'success' : 'danger'"></p-badge>
                                </ng-template>
                            </p-dropdown>
                        </ng-template>
                    </p-columnFilter>
                </th>
                <th>
                    <p-columnFilter field="situacao" matchMode="equals" [showMenu]="false">
                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                            <p-dropdown [ngModel]="value" [options]="situacoes" (onChange)="filter($event.value)" placeholder="Todos" [showClear]="true">
                                <ng-template let-option pTemplate="item">
                                    <p-badge [value]="option.label" size="small" [severity]="option.value === 'ATIVA' ? 'success' : 'danger'"></p-badge>
                                </ng-template>
                            </p-dropdown>
                        </ng-template>
                    </p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="date" field="dataInicioVigencia" display="menu">
                        <ng-template pTemplate="filter" let-filter="filterCallback">
                            <p-calendar [readonlyInput]="true" dateFormat="dd/mm/yy" (onSelect)="filter($event)"></p-calendar>
                        </ng-template>
                    </p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="date" field="dataFimVigencia" display="menu">
                        <ng-template pTemplate="filter" let-filter="filterCallback">
                            <p-calendar [readonlyInput]="true" dateFormat="dd/mm/yy" (onSelect)="filter($event)"></p-calendar>
                        </ng-template>
                    </p-columnFilter>
                </th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-pagamento>
            <tr>
                <td>{{ pagamento.eventoCompensacaoId }}</td>
                <td>{{ pagamento.descricao }}</td>
                <td>{{ pagamento.implantado }}</td>
                <td>{{ pagamento.situacao }}</td>
                <td>{{ convertDateBr(pagamento.dataInicioVigencia) }}</td>
                <td>{{ convertDateBr(pagamento.dataFimVigencia) }}</td>
                <td>
                    <a pButton pRipple title="Editar" type="button" icon="pi pi-pencil"
                       class="p-button-rounded" [routerLink]="['/evento-compensacao/config', pagamento.eventoCompensacaoId]"
                       routerLinkActive="router-link-active">
                    </a>
<!--                    <button-->
<!--                        pButton-->
<!--                        pRipple-->
<!--                        title="Editar"-->
<!--                        type="button"-->
<!--                        icon="pi pi-pencil"-->
<!--                        class="p-button-rounded"-->
<!--                        (click)="onEditar(pagamento)"-->
<!--                    ></button>-->
                    <button
                        *ngIf="podeAtivar(pagamento)"
                        pButton
                        pRipple
                        title="{{ tipoBotao }}"
                        type="button"
                        icon="pi pi-{{ iconeBotao }}"
                        class="p-button-rounded p-button-{{ corBotao }}"
                        style="margin-left: 3px"
                        (click)="ativaInativaPagamento(pagamento)"
                    ></button>
                    <button
                        *ngIf="podeDeletar(pagamento)"
                        pButton
                        pRipple
                        title="{{ tipoBotao }}"
                        type="button"
                        icon="pi pi-{{ iconeBotao }}"
                        class="p-button-rounded p-button-{{ corBotao }}"
                        style="margin-left: 3px"
                        (click)="ativaInativaPagamento(pagamento)"
                    ></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <p-paginator
        [rows]="10"
        [totalRecords]="pagination?.totalElements"
        (onPageChange)="onPageChange($event)"
    ></p-paginator>
</p-card>

