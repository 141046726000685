import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MenuItem } from "primeng/api";
import { Pagination } from "src/app/shared/pagination/pagination";
import { ConsultaContribuinteService } from "../../../services/consulta-conta-debitos-contribuinte.service";
import { AlertsUtil } from "src/app/utils/alerts/alerts.util";
import { ModalExtratoContaCorrenteComponent } from "src/app/modal/modal-extrato-conta-corrente/modal-extrato-conta-corrente.component";
import { ModalAlertaHonorarioComponent } from "src/app/modal/modal-alerta-honorario/modal-alerta-honorario.component";
import { ConsultaContaCorrenteService } from "../../../services/consulta-conta-corrente.service";
import { ArrecadaCaoDareService } from "../../../services/arrecadacao-dare.service";
import { UtilsService } from "src/app/shared/utils.service";

@Component({
    selector: "ccp-consulta-debito-contribuinte",
    templateUrl: "./consulta-debito-contribuinte.component.html",
    styleUrls: ["./consulta-debito-contribuinte.component.scss"],
})
export class ConsultaDebitoContribuinteComponent implements OnInit {
    
    @ViewChild(ModalExtratoContaCorrenteComponent, { static: false })
    modalExtratoConta: ModalExtratoContaCorrenteComponent;

    @ViewChild(ModalAlertaHonorarioComponent, { static: false })
    modalAlertaPossuiHonorario: ModalAlertaHonorarioComponent;

    @Input() abaSelecionada: any;

    items: MenuItem[];
    activeIndex: number = 1;
    pagination: Pagination<any>;
    tituloPage = "Débitos do Contribuinte";

    debitosList: any;
    detalheContribuinte: any = [];
    blockedDocument = false;
    contaJuros: any;
    valueContribuinte: any;
    siglasAll: any = 'IPVA,ITCD,ICMS';
    rowIndexConta: any;
    cpfCnpjContribuinte: any;
    contaList: any;
    objParamsConta: { idsContas: any; dataVencimento: any; informacoesComplementares: any; };

    constructor(
      private router: Router,
        private activatedRoute: ActivatedRoute,
        private alertsUtil: AlertsUtil,
        private utilService: UtilsService,
        private consultaContribuinteService: ConsultaContribuinteService,
        private consultaContaCorrenteService: ConsultaContaCorrenteService,
        private arrecadaCaoDareService: ArrecadaCaoDareService
    ) {}

    ngOnInit(): void {
        this.valueContribuinte = JSON.parse(localStorage.getItem('contribuinte'));
        
        this.pagination = new Pagination<any>();
       
        this.activatedRoute.params.subscribe((eventoId) => {
          
          this.cpfCnpjContribuinte = eventoId.cpfCnpj;
          
          setTimeout(() => {
            this.getContribuinteView(this.cpfCnpjContribuinte);
          }, 500);
          
        });

        this.items = [
            {
              label: "Selecionar Contribuinte"
            },
            {
              label: "Débitos do Contribuinte"
            }
          ]
    }

    getContribuinteView(cpfCnpj) {
        this.blockedDocument = true;
        // const objEvento = {
        //     cpfCnpjResponsavel: cpfCnpj,
        //     siglaTributo: this.siglasAll
        // };
      /**Endpoint Retornando todas view temporária para OS10 dia 20-12-2023*/
          if(cpfCnpj != 'undefined') {
          this.consultaContaCorrenteService.getAllByCpfCnpj(cpfCnpj).subscribe(
            // this.consultaContribuinteService.getContribuinteView(objEvento, this.pagination).subscribe(
              (response: any) => {
                this.blockedDocument = false;
                this.debitosList = response;
                this.pagination = response;
              },
              (error) => {
                this.blockedDocument = false;
                this.alertsUtil.handleError(error);
              }
              );
          } else {
            this.alertsUtil.warning(`Não foi possível verificar o CPF ou CNPJ do Contribuiente, faça a pesquisa do Contruibuinte novamente.`);
            this.router.navigateByUrl('consultar-conta-veiculo');
          }
    }

    onPageChange(e) {
        this.pagination.pageable.pageNumber = e.page;
        this.pagination.size = e.rows;
        this.getContribuinteView(this.cpfCnpjContribuinte);
    }

    somaJuros(vl) {
        let total = 0;
        for (var i = 0; i < vl?.length; i++) {
            total += vl.valorLancado[i];
        }
        return total;
    }

    maior(ev){
        if(ev < 0){
          return true;
        }
        return false;
      }

      openModal(dadosContribuinte, contaCorrenteContribuinte) {
        this.abaSelecionada = contaCorrenteContribuinte.sigla;
        if(contaCorrenteContribuinte.sigla == 'IPVA'||
          contaCorrenteContribuinte.sigla == 'ITCD' || 
          contaCorrenteContribuinte.sigla == 'ICMS') {
          this.abaSelecionada = contaCorrenteContribuinte.sigla;
        } else {
          this.abaSelecionada = 'SEM_SIGLA'
        }
        this.modalExtratoConta.showDialog(dadosContribuinte, contaCorrenteContribuinte)
      }

      openModalAlertaHonorario() {
        this.modalAlertaPossuiHonorario.showDialog()
      }

      converteMesAno(data){
        let ano =  data?.toString().substring(0, 4);
        let mes = data?.toString().substring(4, 6);
        return `${mes}/${ano}`
      }

      expandeEnvento(evento){
        /**Index para o componente tabela juros */
        this.rowIndexConta = evento
      }

      /**Verifica se se a conta que originou a ação de emissão de DARE possui conta de honorários vinculada a ela */
      getContas(conta){
        /**há honorários vinculados  */
        this.blockedDocument = true;
        this.consultaContaCorrenteService.getContaVinculadaDare(conta.contaCorrenteId).subscribe((response: any) => {
          this.blockedDocument = false;
            if(response.length > 0){
              this.contaList = response
              this.openModalAlertaHonorario();
              this.prepareIdsContas(conta, this.contaList);
            } else {
              this.alertsUtil.warning('A conta não possui honorários vinculados');
            }
        },
        error => {
          this.alertsUtil.handleError(error);
          this.blockedDocument = false;
        });
      }

      prepareIdsContas(conta, contaList){
        let idsContas: any[] = [];
        contaList.forEach(element => {
          idsContas.push(element.contaCorrenteId);
        });

        this.objParamsConta = {
          idsContas: idsContas,
          dataVencimento: conta.dataVencimento,
          informacoesComplementares: null
        }
      }

      postEmitirDare(){
        this.blockedDocument = true;
        this.arrecadaCaoDareService.postArrecadacaoDare( this.objParamsConta).subscribe(
          data => {
            this.blockedDocument = false;
            this.alertsUtil.saveSuccess(`Dare emitido com sucesso.`);
            setTimeout(() => {
              this.emitePdfDare(data.base64);
            }, 600);
          }, e => {
            this.blockedDocument = false;
            this.alertsUtil.handleError(e);
          }
          )
          this.modalAlertaPossuiHonorario.hiddenDialog();
      }

      emitePdfDare(dataResponse) {
        this.utilService.geraPdfStringBase64(dataResponse);
      }
}
