import {Injectable} from '@angular/core';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class UtilsService {
    constructor() {
    }

    convertToDateBr(dataUs) {
        return dataUs == null || dataUs == '' ? '' : moment(dataUs).format('DD/MM/YYYY');
    }

    convertDateToEdit(date = null) {
        return date == null || date == '' ? null : moment(date).format('DD/MM/YYYY');
    }

    convertDateToSave(date = null) {
        return date == null || date == '' ? null : moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }

    convertToSaveUTC(date = null) {
        if (date === null) {
            return null;
        } else {
            let result = moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
            if (moment(result).isValid() === false) {
                result = moment(date).utc().format("YYYY-MM-DD");
            }
            return result;
        }
    }

    convertToSaveAnoMes(date){
        let mes = date == null || date == '' ? null : moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD').toString().substring(5, 7);
        let ano = date == null || date == '' ? null : moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD').toString().substring(0, 4);
        return `${ano}${mes}`;
    }

    convertToSaveAno(date){
        let ano = date == null || date == '' ? null : moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD').toString().substring(0, 4);
        return `${ano}`;
    }

    convertToSaveUniversal(date = null) {
        if (date === null || date === undefined) {
            return null
        } else {
            if (typeof date === 'object') {
                return moment(date).utc().format("YYYY-MM-DD")
            } else {
                let array = date.split("/")
                let separador = "/"
                let originalPattern = ""
                if (array.length === 1) {
                    array = date.split("-")
                    separador = "-"
                }
                const lengthIndex0 = array[0].length
                const lengthIndex2 = array[2].length

                if (lengthIndex0 === 4) {
                    originalPattern = `YYYY${separador}MM${separador}DD`
                } else if (lengthIndex2 === 4) {
                    originalPattern = `DD${separador}MM${separador}YYYY`
                }
                return moment(date, originalPattern).format("YYYY-MM-DD")
            }
        }
    }

    convertToEditBrUniversal(date = null) {
        if (date === null || date === undefined) {
            return null
        } else {
            if (typeof date === 'object') {
                return moment(date).utc().format("DD/MM/YYYY")
            } else {
                let array = date.split("/")
                let separador = "/"
                let originalPattern = ""
                if (array.length === 1) {
                    array = date.split("-")
                    separador = "-"
                }
                const lengthIndex0 = array[0].length
                const lengthIndex2 = array[2].length

                if (lengthIndex0 === 4) {
                    originalPattern = `YYYY${separador}MM${separador}DD`
                } else if (lengthIndex2 === 4) {
                    originalPattern = `DD${separador}MM${separador}YYYY`
                }
                return moment(date, originalPattern).format("DD/MM/YYYY")
            }
        }
    }


    convertDateUsToSave(date = null) {
        return date == null || date == '' ? null : moment(date).format('YYYY-MM-DD');
    }

    convertDateTime(date = null, fromPattern = 'DD/MM/YYYY', toPattern = 'YYYY-MM-DD') {
        return date == null || date == '' ? null : moment(date, fromPattern).format(toPattern);
    }

    diffInDays(lastDate = null, iniDate = null) {
        return moment(lastDate).diff(iniDate, "days");
    }

    subtractDays(data, qtdDias = 1, format = "YYYY-MM-DD") {
        return moment(data).subtract(qtdDias, "days").format(format);
    }

    addDays(data, qtdDias = 1, format = "YYYY-MM-DD") {
        return moment(data).add(qtdDias, "days").format(format);
    }

    geraPdfStringBase64(stringBase64) {
        // Decodificar a string base64 para um array de bytes
        const bytes = atob(stringBase64);
        const byteNumbers = new Array(bytes.length);
        for (let i = 0; i < bytes.length; i++) {
          byteNumbers[i] = bytes.charCodeAt(i);
        }

        // Criar um array de bytes usando o TypedArray
        const byteArray = new Uint8Array(byteNumbers);

        // Criar um Blob a partir do array de bytes
        const blob = new Blob([byteArray], { type: 'application/pdf' });

        // Criar uma URL para o Blob
        const url = URL.createObjectURL(blob);

        // Abrir o PDF no navegador
        window.open(url, '_blank');
    }

    getCurrentDateTimeString(): string {
        const now = new Date();

        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');

        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');

        return `${day}-${month}-${year}_${hours}-${minutes}-${seconds}`;
    }

    baixarPdfFromBase64(arquivo: string, fileName: string) {
        this.baixarArquivoBase64(arquivo, fileName, 'application/pdf', '.pdf');
    }

    baixarXlsFromBase64(arquivo: string, fileName: string) {
        this.baixarArquivoBase64(arquivo, fileName, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', '.xlsx');
    }

    private baixarArquivoBase64(arquivo: string, fileName: string, mimeType: string, extensao: string) {
        if (arquivo) {
            const nomeArquivo = fileName.toUpperCase() + '_' + this.getCurrentDateTimeString() + extensao;
            const b64Data = arquivo.replace(/\s/g, ''); // Remove espaços em branco, se houver
            const source = `data:${mimeType};base64,${b64Data}`;

            const link = document.createElement("a");
            link.href = source;
            link.download = nomeArquivo;
            link.click();
        } else {
            console.error("Arquivo base64 não fornecido.");
            alert("Não foi possível baixar. Tente novamente...");
        }
    }

}
