<div class="route-bar">
    <div class="route-bar-breadcrumb">
        <li>
            <a routerLink="">
                <i class="pi pi-home"></i>
            </a>
        </li>
        <li>&nbsp;/&nbsp;</li>
        <ng-template ngFor let-item let-last="last" [ngForOf]="items">
            <li>
                <a [routerLink]="item.routerLink" *ngIf="item.routerLink"> {{item.label}} </a>
                <ng-container *ngIf="!item.routerLink"> {{item.label}} </ng-container>
            </li>
            <li *ngIf="!last">/</li>
        </ng-template>
    </div>

    <ul class="route-bar-menu">
        <li>
            <a href="#">
                <i class="pi pi-eye"></i>
                <span>Overview</span>
            </a>
        </li>
        <li>
            <a href="#">
                <i class="pi pi-briefcase"></i>
                <span>Demos</span>
            </a>
        </li>
        <li>
            <a href="#">
                <i class="pi pi-file"></i>
                <span>Documentation</span>
            </a>
        </li>
        <li>
            <a href="#">
                <i class="pi pi-envelope"></i>
                <span>Support</span>
            </a>
        </li>
        <li>
            <a href="#">
                <i class="pi pi-user"></i>
                <span>Profile</span>
            </a>
        </li>
    </ul>
</div>
