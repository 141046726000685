import { TipoCadastro } from './tipo-cadastro';
import {RegraVencimento} from './regra-vencimento';
import {TipoContagem} from './tipo-contagem';
import {TipoDataReferencia} from './tipo-data-referencia';

export class TipoCategoria {
    tipoCategoriaId: string;
    descricao: string;
    tipoCadastro: TipoCadastro;

    constructor(init?: Partial<TipoCategoria>) {
        if (init) {
            Object.assign(this, init);
        } else {
            this.tipoCategoriaId = null;
            this.descricao = null;
            this.tipoCadastro = null;
        }
    }
}
