import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import * as moment from 'moment';
import {RegraCriacaoConta} from '../../../models/regra-criacao-conta';
import {RegraVencimento} from '../../../models/regra-vencimento';
import {environment} from '../../../../environments/environment';
import swal from 'sweetalert2';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {Message, MessageService, PrimeNGConfig} from 'primeng/api';
import {RegraCriacaoViewService} from './regra-criacao-view.service';
import { Tributo } from 'src/app/models/tributo';
import { RemoveParamsUtil } from 'src/app/utils/paramsRemove/paramsRemove.util';
import { ContasCorrentesTabViewComponent } from '../../contas-correntes-tab-view/contas-correntes-tab-view.component';

@Component({
    selector: 'app-regra-criacao-view',
    templateUrl: './regra-criacao-view.component.html',
    styleUrls: ['./regra-criacao-view.component.scss'],
    providers: [MessageService, RegraCriacaoViewService]
})
export class RegraCriacaoViewComponent implements OnInit {
    msgs1: Message[];
    regraCriacaoConta: RegraCriacaoConta;
    situacaoes: any[];
    situacao: any;
    referencias: any[];
    referenciaRp: any;
    contagensEm: any[];
    aContarDas: any[];
    tributos: any[];
    vencimentosEmDiaUtil: any[];
    criteriosUtilizacaoCredito: any[];
    qtdRetroativoLabel = '.';
    qtdPrescricaoLabel = '.';
    tipoContagemRetroativoLabel = 'Contagem em';
    tipoContagemPrescricaoLabel = 'Contagem em';
    blockedDocument = false;
    temContaAssociada: boolean;
    podeEditar: boolean = false;

    regraVencimentos: RegraVencimento[];
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };
    podeEditarTributo: boolean;
    podeEditarSituacao: boolean;
    camposLancamentoAndPrescricao: boolean;

    @ViewChild(ContasCorrentesTabViewComponent, { static: false })
    contaTabViewComponent: ContasCorrentesTabViewComponent;

    constructor(
        private http: HttpClient,
        private router: Router,
        private location: Location,
        private removeParams: RemoveParamsUtil,
        private messageService: MessageService,
        private primengConfig: PrimeNGConfig,
        private regraCriacaoViewService: RegraCriacaoViewService
    ) {
        this.initRegraCriacaoConta();
        this.podeEditar = false;

        if (this.router.getCurrentNavigation().extras?.state?.regraCriacaoConta !== undefined) {
            let regraCriacaoConta = JSON.parse(JSON.stringify(this.router.getCurrentNavigation().extras.state.regraCriacaoConta));

            this.regraCriacaoViewService.loadRegra(regraCriacaoConta.regraCriacaoContaId).subscribe(r => {
                this.regraCriacaoConta = r;
                if(!this.regraCriacaoConta.regraVencimentoPrimeiraParcela) {
                    this.regraCriacaoConta.regraVencimentoPrimeiraParcela = new RegraVencimento();
                }
                if(!this.regraCriacaoConta.regraVencimentoDemaisParcela) {
                    this.regraCriacaoConta.regraVencimentoDemaisParcela = new RegraVencimento();
                }
                if(this.regraCriacaoConta.tributo && this.regraCriacaoConta.tributo.tributoId) {
                    this.regraCriacaoConta.tributoId = this.regraCriacaoConta.tributo.tributoId;
                }
                this.regraCriacaoConta.dataCriacao = this.regraCriacaoConta.dataCriacao == null ? null : moment(this.regraCriacaoConta.dataCriacao).format('DD/MM/YYYY');
            });
        }
    }

    initRegraCriacaoConta(): void {
        this.regraCriacaoConta = new RegraCriacaoConta();
        this.regraCriacaoConta.regraVencimento = new RegraVencimento();
        this.regraCriacaoConta.regraVencimentoPrimeiraParcela = new RegraVencimento();
        this.regraCriacaoConta.regraVencimentoDemaisParcela = new RegraVencimento();
        this.regraCriacaoConta.tributo = new Tributo();

        this.regraCriacaoConta.permiteDuplicidadeLancamento = '0';
        this.regraCriacaoConta.permiteUtilizarCreditoAuto = '0';
        this.regraCriacaoConta.permiteBloqueioCertidao = '1';
        this.regraCriacaoConta.permiteEmissaoDare = '1';
        this.regraCriacaoConta.permiteDareValorMenorSaldo = '0';
        this.regraCriacaoConta.permiteLancamentoRetroativo = '0';
        this.regraCriacaoConta.permitePrescricao = '0';
        this.regraCriacaoConta.permiteCotaUnica = '0';
        this.regraCriacaoConta.permiteParcelar = '0';

        if (!this.regraCriacaoConta.regraCriacaoContaId) {
            this.regraCriacaoConta.situacao = 'ATIVA';
        }
    }


    ngOnInit(): void {
        this.msgs1 = [];
        this.primengConfig.ripple = true;
        this.regraCriacaoViewService.getSituacoes().subscribe(r => {
            this.situacaoes = r;
        });
        this.regraCriacaoViewService.getReferencias().subscribe(response => {
            this.referencias = response;
        });
        this.regraCriacaoViewService.getCriteriosUtilizacaoCredito().subscribe(response => {
            this.criteriosUtilizacaoCredito = response;
        });
        this.regraCriacaoViewService.getContagensEm().subscribe(response => {
            this.contagensEm = response;
        });
        this.regraCriacaoViewService.getAContarDas().subscribe(response => {
            this.aContarDas = response;
        });
        this.regraCriacaoViewService.getRegraVencimentos().subscribe(response => {
       
            this.regraVencimentos = response
            
            this.regraVencimentos.forEach(element => {
                this.removeAtributoFormula(element);
            });
            
        });
        this.regraCriacaoViewService.getVencimentosEmDiaUtil().subscribe(response => {
            this.vencimentosEmDiaUtil = response;
            if (this.regraCriacaoConta.regraCriacaoContaId === null) {
                this.regraCriacaoConta.vencimentoEmDiaUtil = 'E_INDIFERENTE';
            }
        });
        this.regraCriacaoViewService.getTributos().subscribe(response => {
            this.tributos = response;
        });
        this.regraCriacaoConta.dataCriacao = new Date().toLocaleDateString();

    }

    handleChange(evento){
        /**Evento abas Modelo = 0, Conta = 1 */
        if(evento.index === 1){
            this.contaTabViewComponent.getConfigContaRegraSituacoes();
        }
    }

    removeAtributoFormula(params: any) {
            delete params.formulaAno;
            delete params.formulaDia;
            delete params.formulaMes;
            delete params.formulaQtdAnos;
            delete params.formulaQtdDias;
            delete params.formulaQtdMeses;
        return params;
    }

    create(): void {
        if(this.handleValidated()) {
            swal.fire('Atenção', "Por favor, preencha os campos obrigatórios.", 'warning').then(r => {});
            return;
        }
        this.regraCriacaoConta.tributo = new Tributo();
        const data = JSON.parse(JSON.stringify(this.regraCriacaoConta));
        data.dataCriacao = data.dataCriacao == null ? null : moment(this.regraCriacaoConta.dataCriacao, 'DD/MM/YYYY').format('YYYY-MM-DD');
        if(data.tributoId) {
            data.tributo.tributoId = data.tributoId;
        } else {
            data.tributo = null;
        }

        if (!data.regraVencimento || !data.regraVencimento.regraVencimentoId) {
            data.regraVencimento = null;
        }
        this.blockedDocument = true;
        this.msgs1 = [];
        this.http
            .post<RegraCriacaoConta>(
                `${environment.baseUrl}/regra-criacao-conta`,
                JSON.stringify(data),
                this.httpOptions
            )
            .subscribe(response => {
                this.blockedDocument = false;
                this.regraCriacaoConta = JSON.parse(JSON.stringify(response));
                this.regraCriacaoConta.dataCriacao = this.regraCriacaoConta.dataCriacao == null ? null : moment(this.regraCriacaoConta.dataCriacao).format('DD/MM/YYYY');

                swal.fire('Sucesso', `Modelo ${response.regraCriacaoContaId} salvo com sucesso`, 'success').then(r => {
                    this.router.navigateByUrl('/regra-criacao/regra-criacao-list')
                });
            }, error => {
                this.blockedDocument = false;
                if (error.status === 500) {
                    swal.fire(error.statusText, 'Ocorreu um erro interno', 'error').then(r => {});
                } else if (error.status === 412) {
                    swal.fire('Erro de Validação', error.error.message, 'error').then(r => {});
                } else if (error.status === 400) {
                    const errors = error.error.errors;
                    this.msgs1 = [];
                    errors.forEach(e => {
                        this.msgs1.push({severity: 'error', summary: 'Erro', detail: e.message});
                    });
                    this.scrollToTop();
                }
            });
    }

    onChangeRegraVencimento(event) {
        this.regraCriacaoConta.diaVencimento = null;
        this.regraCriacaoConta.mesVencimento = null;
        this.regraCriacaoConta.anoVencimento = null;
        this.regraCriacaoConta.qtdDiasVencimento = null;
        this.regraCriacaoConta.tipoContagemVencimento = null;
    }

    onChangeRegraVencimentoPrimeira(event) {
        this.regraCriacaoConta.diaVencPrimeiraParcela = null;
        this.regraCriacaoConta.mesVencPrimeiraParcela = null;
        this.regraCriacaoConta.anoVencPrimeiraParcela = null;
        this.regraCriacaoConta.qtdDiasPrimeiraParcela = null;
        this.regraCriacaoConta.qtdMesesPrimeiraParcela = null;
        this.regraCriacaoConta.tipoContagemVencPrimeiraParcela = null;
    }

    onChangeRegraVencimentoDemais(event) {
        this.regraCriacaoConta.diaVencDemaisParcela = null;
        this.regraCriacaoConta.mesVencDemaisParcela = null;
        this.regraCriacaoConta.anoVencDemaisParcela = null;
        this.regraCriacaoConta.qtdDiasDemaisParcela = null;
        this.regraCriacaoConta.qtdMesesDemaisParcela = null;
        this.regraCriacaoConta.tipoContagemVencDemaisParcela = null;
    }

    onChangeSituacao(event) {
    }

    onChangePermiteLancamentoRetroativo(event) {
        if (event.checked === '0') {
            this.regraCriacaoConta.referenLancamentoRetroativo = null;
            this.regraCriacaoConta.qtdRetroativo = null;
            this.regraCriacaoConta.tipoContagemRetroativo = null;
            this.regraCriacaoConta.tipoDataReferenciaRetroativo = null;
        }
    }

    onChangePermitePrescricao(event) {
        if (event.checked === '0') {
            this.regraCriacaoConta.referenciaPrescricao = null;
            this.regraCriacaoConta.qtdPrescricao = null;
            this.regraCriacaoConta.tipoContagemPrescricao = null;
            this.regraCriacaoConta.tipoDataReferenciaPrescricao = null;
        }
    }

    onChangeReferenLancamentoRetroativo(event) {
        this.regraCriacaoConta.tipoContagemRetroativo = null;
        if(event.value == null) {
            this.regraCriacaoConta.qtdRetroativo = null;
        }
        switch (event.value) {
            case 'DIAS':
                this.qtdRetroativoLabel = 'Dias *';
                this.tipoContagemRetroativoLabel = this.tipoContagemRetroativoLabel.concat(' *');
                break;
            case 'MESES':
                this.qtdRetroativoLabel = 'Meses *';
                this.tipoContagemRetroativoLabel = this.tipoContagemRetroativoLabel.replace(' *', '');
                break;
            case 'ANOS':
                this.qtdRetroativoLabel = 'Anos *';
                this.tipoContagemRetroativoLabel = this.tipoContagemRetroativoLabel.replace(' *', '');
                break;
            default:
                this.qtdRetroativoLabel = '.';
                this.tipoContagemRetroativoLabel = this.tipoContagemRetroativoLabel.replace(' *', '');
        }
    }

    OnChangePermiteUtilizarCreditoAuto(event) {
        if (event.checked === '0') {
            this.regraCriacaoConta.criterioUtilizacaoCredito = null;
        }
    }

    onChangeReferenciaPrescricao(event) {
        this.regraCriacaoConta.tipoContagemPrescricao = null;
        if(event.value == null) {
            this.regraCriacaoConta.qtdPrescricao = null;
        }
        switch (event.value) {
            case 'DIAS':
                this.qtdPrescricaoLabel = 'Dias *';
                this.tipoContagemPrescricaoLabel = this.tipoContagemPrescricaoLabel.concat(' *');
                break;
            case 'MESES':
                this.qtdPrescricaoLabel = 'Meses *';
                this.tipoContagemPrescricaoLabel = this.tipoContagemPrescricaoLabel.replace(' *', '');
                break;
            case 'ANOS':
                this.qtdPrescricaoLabel = 'Anos *';
                this.tipoContagemPrescricaoLabel = this.tipoContagemPrescricaoLabel.replace(' *', '');
                break;
            default:
                this.qtdPrescricaoLabel = '.';
                this.tipoContagemPrescricaoLabel = this.tipoContagemPrescricaoLabel.replace(' *', '');
        }
    }

    OnChangePermiteInformarQuantidadeParcelas(event) {
        if (event.checked === '0') {
        }
    }

    OnChangePermiteCriacaoCotaUnica(event) {
        if (event.checked === '1') {
            this.regraCriacaoConta.permiteParcelar = '1';
        }
    }

    voltar() {
        this.location.back();
    }

    scrollToTop() {
        (function smoothscroll() {
            const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
            if (currentScroll > 0) {
                window.requestAnimationFrame(smoothscroll);
                window.scrollTo(0, currentScroll - (currentScroll / 8));
            }
        })();
    }

    setTemContaAssociadaEvent(eventConfigConta) {
        if(eventConfigConta[0].numberOfElements == 0) {
            swal.fire({
                title: "Atenção",
                text: `Nenhuma conta corrente associada ao modelo foi localizada pelos filtros informados.`,
                icon: "warning",
            });
            this.temContaAssociada = false;
        } else {
            this.temContaAssociada = true;
        }

        if(!this.temContaAssociada || !this.regraCriacaoConta?.regraCriacaoContaId) {
            this.podeEditar = true;
            
        }

        if(eventConfigConta[1]?.extras?.state?.regraCriacaoConta.qtdContasAssociadas == 0){
            this.podeEditar = true;
        }

        if(eventConfigConta.numberOfElements > 0){
            let estaImplantado;
            estaImplantado = this.verificaImplantado(eventConfigConta.content);
            /**há contas associadas e nenhuma está implantada = 0 - Não */
            if(this.temContaAssociada && !estaImplantado){
                this.podeEditarTributo = true;
                this.podeEditarSituacao = false;
                
                this.podeEditar = true;
                /**há pelo menos uma conta associada com o campo Implantado = 1 - SIM */
            } else if(this.temContaAssociada && estaImplantado && this.regraCriacaoConta?.regraCriacaoContaId){
                this.podeEditarTributo = false;
                this.podeEditarSituacao = false;
                this.camposLancamentoAndPrescricao = true;
            }

            if(!this.regraCriacaoConta?.regraCriacaoContaId){
                this.podeEditarSituacao = true;
            }
        }
    }

    /**Verifica se há alguma conta implantada para validacao dos campos*/
    verificaImplantado(regraCriacaoContaList) {
        const contas = regraCriacaoContaList;
        if(contas.find(aux => aux.configConta?.implantado === 'SIM')){
            return true;
        }
        return false;
    }

    handleValidated(): boolean {
        if(this.regraCriacaoConta.regraVencimento.regraUtilizaDia == "1") {
            if(this.regraCriacaoConta.diaVencimento == null) {
                return true;
            }
        }
        if(this.regraCriacaoConta.regraVencimento.regraUtilizaMes == "1") {
            if(this.regraCriacaoConta.mesVencimento == null) {
                return true;
            }
        }
        if(this.regraCriacaoConta.regraVencimento.regraUtilizaAno == "1") {
            if(this.regraCriacaoConta.anoVencimento == null) {
                return true;
            }
        }
        if(this.regraCriacaoConta.regraVencimento.regraUtilizaQtddias == "1") {
            if(this.regraCriacaoConta.qtdDiasVencimento == null) {
                return true;
            }
            if(this.regraCriacaoConta.tipoContagemVencimento == null) {
                return true;
            } 
        }
        if(this.regraCriacaoConta.regraVencimento.regraUtilizaQtdmeses == "1") {
             if(this.regraCriacaoConta.qtdMesesVencimento == null) {
                 return true;
             }
        }
        if(this.regraCriacaoConta.regraVencimentoPrimeiraParcela.regraUtilizaDia == "1") {
            if(this.regraCriacaoConta.diaVencPrimeiraParcela == null) {
                return true;
            }
       } 
       if(this.regraCriacaoConta.regraVencimentoPrimeiraParcela.regraUtilizaMes == "1") {
            if(this.regraCriacaoConta.mesVencPrimeiraParcela == null) {
                return true;
            }
       }
       if(this.regraCriacaoConta.regraVencimentoPrimeiraParcela.regraUtilizaAno == "1") {
            if(this.regraCriacaoConta.anoVencPrimeiraParcela == null) {
                return true;
            }
       }
       if(this.regraCriacaoConta.regraVencimentoPrimeiraParcela.regraUtilizaQtddias == "1") {
            if(this.regraCriacaoConta.qtdDiasPrimeiraParcela == null) {
                return true;
            }
            if(this.regraCriacaoConta.tipoContagemVencPrimeiraParcela == null) {
                return true;
            }
       }
       if(this.regraCriacaoConta.regraVencimentoPrimeiraParcela.regraUtilizaQtdmeses == "1") {
            if(this.regraCriacaoConta.qtdMesesPrimeiraParcela == null) {
                return true;
            }
       }
       if(this.regraCriacaoConta.regraVencimentoDemaisParcela.regraUtilizaDia == "1") {
            if(this.regraCriacaoConta.diaVencDemaisParcela == null) {
                return true;
            }
       }
       if(this.regraCriacaoConta.regraVencimentoDemaisParcela.regraUtilizaMes == "1") {
            if(this.regraCriacaoConta.mesVencDemaisParcela == null) {
                return true;
            }
       }
       if(this.regraCriacaoConta.regraVencimentoDemaisParcela.regraUtilizaAno == "1") {
            if(this.regraCriacaoConta.anoVencDemaisParcela == null) {
                return true;
            }
       }
       if(this.regraCriacaoConta.regraVencimentoDemaisParcela.regraUtilizaQtddias == "1") {
            if(this.regraCriacaoConta.qtdDiasDemaisParcela == null) {
                return true;
            }
            if(this.regraCriacaoConta.tipoContagemVencDemaisParcela == null) {
                return true;
            }
       }
       if(this.regraCriacaoConta.regraVencimentoDemaisParcela.regraUtilizaQtdmeses == "1") {
            if(this.regraCriacaoConta.qtdMesesDemaisParcela == null) {
                return true;
            }
       }
       return false;
    }

}
