import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { MenuResetPageIndexService } from 'src/app/menu/menu-reset-page.service';

@Component({
  selector: 'app-criacao-conta-corrente',
  templateUrl: './criacao-conta-corrente.component.html',
  styleUrls: ['./criacao-conta-corrente.component.scss']
})
export class CriacaoContaCorrenteComponent implements OnInit {

  items: MenuItem[];
  activeIndex: number = 0;
  nextPage: boolean = true;

  constructor(private menuResetPageIndexService: MenuResetPageIndexService) { }

  ngOnInit(): void {
    this.initLabelTimeLine();
    this.resetIndex();
  }

  resetIndex() {
    this.menuResetPageIndexService.emitirValorIndex.subscribe((data => {
      this.activeIndex = data;
    }))
  }

  initLabelTimeLine(){
    this.items = [
      {
        label: "Configuração da Conta"
      },
      {
        label: "Memória de Cálculo"
      },
      {
        label: "Contas Associadas"
      },
      {
        label: "Confirmação"
      }
    ]
  }

  outEtapas(eventIndexPage?: any){
    this.activeIndex = eventIndexPage;
  }

}
