import {Injectable} from '@angular/core';
import {Pageable} from '../../../shared/pagination/pageable';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Pagination} from '../../../shared/pagination/pagination';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PerfisAcessoService {

  httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient) { }

  getPerfis(): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${environment.baseUrl}/keycloak/roles`);
  }

  getPerfisByTransacao(transacaoId): Observable<any[]> {
    return this.http.get<any[]>(`${environment.baseUrl}/transacao-perfil/by-transacao/${transacaoId}`);
  }

  savePerfil(perfil): Observable<any[]> {

    return this.http.post<any[]>(`${environment.baseUrl}/transacao-perfil/save-all`, JSON.stringify(perfil), this.httpOptions);
  }
  
  putAtivaInativaTransacaoPerfil(transacaoPerfilId):  Observable<any>{
    return this.http.put<any>(`${environment.baseUrl}/transacao-perfil/${transacaoPerfilId}/ativa-inativa`, JSON.stringify({}), this.httpOptions);
  }

}
