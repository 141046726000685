
<p-card header="Contas Correntes">
    <p-fieldset legend="Tipo de Conta">
        <div class="p-fluid p-formgrid p-grid">
            <div *ngFor="let tc of tiposConta" class="p-col-12 p-md-4">
                <div class="p-field-radiobutton">
                    <p-radioButton [name]="tc.chave" [inputId]="tc.chave" [value]="tc.chave" [(ngModel)]="configContaSearchRequest.tipoConta"></p-radioButton>
                    <label style="cursor: pointer" [for]="tc.chave">{{tc.valor}}</label>
                </div>
            </div>
        </div>
    </p-fieldset>
    <div style="margin-top: 15px;" class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-12" *ngIf="configContaSearchRequest.tipoConta == 'TRIBUTARIA'">
            <label>Tributo</label>
            <p-dropdown
                    optionLabel="sigla"
                    optionValue="tributoId"
                    showClear="true"
                    [autoDisplayFirst]="false"
                    [options]="tributos"
                    [(ngModel)]="configContaSearchRequest.tributoId"
                    (onChange)="onChangeTributo($event)"
            ></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-12" *ngIf="configContaSearchRequest.tipoConta == 'NAO_TRIBUTARIA'">
            <label>Natureza da Conta</label>
            <p-dropdown
                    optionLabel="descricao"
                    optionValue="naturezaContaCorrenteId"
                    showClear="true"
                    [autoDisplayFirst]="false"
                    [options]="naturezaContaCorrentes"
                    [(ngModel)]="configContaSearchRequest.naturezaContaCorrenteId"
                    (onChange)="onChangeNaturezaContaCorrente($event)"
            ></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-2">
            <label title="regraCriacaoContaId">Código</label>
            <input
            type="text"
            pInputText
            [(ngModel)]="configContaSearchRequest.configContaId"
            pKeyFilter="num">
        </div>
        <div class="p-field p-col-12 p-md-10">
            <label>Nome Completo</label>
            <input
            type="text"
            pInputText
            autocomplete="nope"
            [(ngModel)]="configContaSearchRequest.nomeCompleto"
            >
        </div>

        <div class="p-field p-col-12 p-md-6">
            <label>Nome Resumido</label>
            <input
            type="text"
            pInputText
            autocomplete="nope"
            [(ngModel)]="configContaSearchRequest.nomeResumido"
            >
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label>Origem da Informação</label>
            <p-dropdown
                    optionLabel="nome"
                    optionValue="origemInformacaoId"
                    showClear="true"
                    [autoDisplayFirst]="false"
                    [options]="origemInformacaos"
                    [(ngModel)]="configContaSearchRequest.origemInformacaoId"
                    (onChange)="onChangeOrigemInformacao($event)"
            ></p-dropdown>
        </div>

        <div class="p-field p-col-12 p-md-6">
            <label title="dataInicioVigencia">Início da Vigência</label>
            <p-calendar
                    dateFormat="dd/mm/yy"
                    [showIcon]="true"
                    [(ngModel)]="configContaSearchRequest.dataInicioVigencia"
                    (onSelect)="onSelectDataInicioVigencia($event)"
                    (change)="onChangeDataInicioVigencia($event)"
            ></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label title="dataFimVigencia">Fim da Vigência</label>
            <p-calendar
                    dateFormat="dd/mm/yy"
                    [showIcon]="true"
                    [(ngModel)]="configContaSearchRequest.dataFimVigencia"
                    (onSelect)="onSelectDataFimVigencia($event)"
                    (change)="onChangeDataFimVigencia($event)"
            ></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label>Situação</label>
            <p-dropdown
                    optionLabel="nome"
                    optionValue="origemInformacaoId"
                    [autoDisplayFirst]="false"
                    [options]="optionsSituacao"
                    optionLabel="label"
                    optionValue="value"
                    [(ngModel)]="configContaSearchRequest.situacao"
            ></p-dropdown>
        </div>
    </div>
    <div style="margin-top: 10px; margin-bottom: 10px; text-align: right !important;">
        <button pButton type="button"  class="p-mr-1" (click)="search('resetPage')" label="Pesquisar" icon="pi pi-search"></button>
        <button pButton type="button" label="Nova Conta" class="p-button-success" (click)="nova()"></button>
    </div>

    <p-table [value]="(configContaSearchResponses$ | async) || []">
        <ng-template pTemplate="header">
            <tr>
                <th>Código</th>
                <th>Nome Resumido</th>
                <th>Origem Informação</th>
                <th>Situação</th>
                <th>Início Vigência</th>
                <th>Fim Vigência</th>
                <th>Ações</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-cc>
            <tr>
                <td>{{cc.configContaId}}</td>
                <td>{{cc.nomeResumido}}</td>
                <td>{{cc.origemInformacao.sigla}}</td>
                <td>{{cc.situacao}}</td>
                <td>{{convertDateBr(cc.dataInicioVigencia)}}</td>
                <td>{{convertDateBr(cc.dataFimVigencia)}}</td>
                <td>
                    <button pButton pRipple title="Editar" type="button" icon="pi pi-pencil" class="p-button-rounded" (click)="onEditar(cc)"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <p-paginator #paginator
    [showCurrentPageReport]="true"
    currentPageReportTemplate="{first} - {last} de {totalRecords}"
    [rows]="10" [totalRecords]="pagination.totalElements" (onPageChange)="onPageChange($event)"></p-paginator>
</p-card>

