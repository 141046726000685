<div class="card">
    <p-steps [model]="items" [(activeIndex)]="activeIndex" [readonly]="nextPage" class="time-line"></p-steps>
</div>

<ccp-pesquisa-contribuinte
    *ngIf="activeIndex == 0"
    (outEtapaVeiculo)="outEtapas($event)"
></ccp-pesquisa-contribuinte>

<ccp-selecao-conta
    *ngIf="activeIndex == 1"
    (outEtapaSelecaoConta)="outEtapas($event)">
</ccp-selecao-conta>

<ccp-detalhamento-conta
    *ngIf="activeIndex == 2"
    (outEtapaSelecaoConta)="outEtapas($event)">
</ccp-detalhamento-conta>