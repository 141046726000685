import {Eventos} from './estado-conta';
import {EventoCompensacaoConta} from './evento-compensacao-conta';
import {EventoCompensaImputacao} from './evento-compensa-imputacao';
import {TransacaoSearchResponse} from '../responses/transacao-search-response';

export class EventoCompensacao {
    eventoCompensacaoId: number = null;
    evento: Eventos = null;
    transacaoDebito: TransacaoSearchResponse = null;
    transacaoCredito: TransacaoSearchResponse = null;
    descricao: string = null;
    tipoCompensacao: string = null;
    implantado: string = null;
    dataInicioVigencia: string = null;
    dataFimVigencia: string = null;
    situacao: string = null;
    eventoCompensacaoContas: EventoCompensacaoConta[] = [];
    // eventoCompensacaoContasOrigem: EventoCompensacaoConta[] = [];
    // eventoCompensacaoContasDestino: EventoCompensacaoConta[] = [];
    eventoCompensaImputacoes: EventoCompensaImputacao[] = [];
    // List<EventoPagtoCompensacao> eventoPagtoCompensacoes;
    constructor(init?: Partial<EventoCompensacao>) {
        if (init) {
            Object.assign(this, init);
        }
    }
}
