import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import * as moment from 'moment/moment';
import {EventoCompensaImputacao} from '../../../models/evento-compensa-imputacao';
import {RegraImputacao} from '../../../models/regra-imputacao';
import {UtilsService} from '../../../shared/utils.service';
import Swal from 'sweetalert2';
import {Moment} from 'moment/moment';
import {OperacaoCrud} from '../../../utils/enums/operacao-crud';

@Component({
    selector: 'app-regra-inputacao-select-modal',
    templateUrl: './regra-inputacao-select-modal.component.html',
    styleUrls: ['./regra-inputacao-select-modal.component.scss']
})
export class RegraInputacaoSelectModalComponent implements OnInit, OnDestroy {
    displayMaximizable = false;
    @Input() visible = false;
    @Output() onSelected = new EventEmitter<any>();

    private eventsSubscription: Subscription;

    receivedParams: {
        visible: boolean,
        operacaoCrud: OperacaoCrud,
        index: number,
        inicioVigenciaEvento: string,
        countRegrasInputacoes: number,
        eventoCompensacaoImpututacaoSelected: EventoCompensaImputacao,
        eventoCompensacaoInputacaoLast: EventoCompensaImputacao
    };

    OPERACAO_CRUD_CREATE: OperacaoCrud.CREATE;
    OPERACAO_CRUD_UPDATE: OperacaoCrud.UPDATE;

    @Input() events: Observable<any>;
    regrasInputacoes: RegraImputacao[];

    eventoCompensaImputacao: EventoCompensaImputacao;


    constructor(
        private utilsService: UtilsService,
        private http: HttpClient
    ) {
    }

    ngOnInit(): void {
        this.receivedParams = {
            visible: null,
            operacaoCrud: null,
            index: null,
            inicioVigenciaEvento: null,
            countRegrasInputacoes: null,
            eventoCompensacaoImpututacaoSelected: null,
            eventoCompensacaoInputacaoLast: null
        };

        this.eventsSubscription = this.events.subscribe((x) => {
            this.receivedParams = x;
            this.visible = x.visible;
            if (x.countRegrasInputacoes === 0) {
                this.eventoCompensaImputacao.dataInicioVigencia = x.inicioVigenciaEvento;
            }
            if (x.eventoCompensacaoImpututacaoSelected != null) {
                x.eventoCompensacaoImpututacaoSelected.dataInicioVigencia = this.utilsService
                    .convertDateToEdit(x.eventoCompensacaoImpututacaoSelected.dataInicioVigencia);
                this.eventoCompensaImputacao = x.eventoCompensacaoImpututacaoSelected;
            }
        });
        this.getRegrasInputacoes();

        this.eventoCompensaImputacao = new EventoCompensaImputacao();
    }

    ngOnDestroy(): void {
        this.eventsSubscription.unsubscribe();
    }

    getRegrasInputacoes() {
        this.http
            .get<Array<any>>(`${environment.baseUrl}/regra-imputacao/search-nopage`, {
                params: {
                    situacao: 'ATIVA',
                }
            })
            .subscribe(response => {
                this.regrasInputacoes = response;
            });
    }

    confirmar() {
        const inicioVigenciaEvento = this.utilsService.convertDateTime(this.receivedParams.inicioVigenciaEvento);
        const inicioVigenciaRegra = this.utilsService.convertDateTime(this.eventoCompensaImputacao.dataInicioVigencia);
        const inicioVigenciaLast = this.utilsService.convertDateUsToSave(this.receivedParams.eventoCompensacaoInputacaoLast?.dataInicioVigencia);
        const diffDatas = this.utilsService.diffInDays(inicioVigenciaRegra, inicioVigenciaEvento);
        const diffAnterior = this.utilsService.diffInDays(inicioVigenciaRegra, inicioVigenciaLast);

        if (this.eventoCompensaImputacao.regraImputacao == null) {
            Swal.fire('Erro', 'Selecione uma regra de imputação', 'error').then();
        } else if (this.eventoCompensaImputacao.dataInicioVigencia === '' || this.eventoCompensaImputacao.dataInicioVigencia === null) {
            Swal.fire('Erro', 'Informe a data de início da vigência', 'error').then();
        } else if (this.receivedParams.countRegrasInputacoes === 0 && diffDatas !== 0) {
            Swal.fire('Erro',
                `O início de vigência da regra de imputação selecionada deve ser igual ao início de vigência do evento de compensação: <b>${this.utilsService.convertToDateBr(inicioVigenciaEvento)}</b>`,
                'error')
                .then();
        } else if (this.receivedParams.countRegrasInputacoes > 0 && inicioVigenciaLast !== null && diffAnterior <= 0) {
            Swal.fire('Erro', `Início da vigência informado deve ser maior que o início da vigência da regra de imputação anterior: <b>${this.utilsService.convertToDateBr(inicioVigenciaLast)}</b>`, 'error').then();
        } else {
            const selected = JSON.parse(JSON.stringify(this.eventoCompensaImputacao));
            selected.dataInicioVigencia = inicioVigenciaRegra;
            this.onSelected.emit({
                selected,
                operacaoCrud: this.receivedParams.operacaoCrud,
                index: this.receivedParams.index
            });
            this.visible = false;
        }
    }

    onHide() {
        this.eventoCompensaImputacao = new EventoCompensaImputacao();
        this.receivedParams = {
            visible: null,
            operacaoCrud: null,
            index: null,
            inicioVigenciaEvento: null,
            countRegrasInputacoes: null,
            eventoCompensacaoImpututacaoSelected: null,
            eventoCompensacaoInputacaoLast: null
        };
    }

    onSelectDataInicioVigencia(event) {
        if (event) {
            this.eventoCompensaImputacao.dataInicioVigencia = moment(event).format('DD/MM/YYYY');
        } else {
            this.eventoCompensaImputacao.dataInicioVigencia = '';
        }
    }

}
