import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {

  @ContentChild(TemplateRef) templateRef: TemplateRef<any>;
  @Input() resources: any = {};
  @Input('filter-form') filterForm: FormGroup | null = null;

  @Output('paginate') paginate: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    // This is intentional
  }

  get isVazio(): boolean {
    return !this.resources.totalElements || 0 == this.resources.totalElements;
  }

  ngOnInit(): void {
    // This is intentional
  }

  onPaginate(event?: any): void {
    this.paginate.emit(event);
  }
}
