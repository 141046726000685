export class RodapedModel {
    itensPorPagina: number = 10;
    paginaAtual: number = 1;
    totalPages: number = 0;
    inicioItem: number = 0;
    fimItem: number = 0;
    totalRecords: number = 0;

    opcoesExibicao: any[] = [
        { label: '10', value: 10 },
        { label: '20', value: 20 },
        { label: '50', value: 50 }
    ];

    opcoesPagina: any[] = [
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 },
        { label: '4', value: 4 },
        { label: '5', value: 5 }
    ];
}
