<p-dialog header="Pesquisar Tipo de Conta"
          (onHide)="onHide()"
          [(visible)]="displayMaximizable"
          [modal]="true"
          [style]="{width: '60vw'}"
          [maximizable]="true"
          [baseZIndex]="10000"
          [draggable]="false"
          [resizable]="false"
          (onShow)="onShow()"
>
    <p-fieldset legend="Tipo de Conta">
        <div class="p-fluid p-formgrid p-grid">
            <div *ngFor="let tipo of tiposConta" class="p-col-12 p-md-4">
                <div class="p-field-radiobutton">
                    <p-radioButton [name]="tipo.chave" [inputId]="tipo.chave" [value]="tipo.chave" [(ngModel)]="configContaSearchRequest.tipoConta">
                    </p-radioButton>
                    <label style="cursor: pointer" [for]="tipo.chave">{{tipo.valor}}</label>
                </div>
            </div>
        </div>
    </p-fieldset>
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-12" *ngIf="configContaSearchRequest.tipoConta == 'TRIBUTARIA'">
            <label>Tributo</label>
            <p-dropdown
                optionLabel="sigla"
                optionValue="tributoId"
                showClear="true"
                [autoDisplayFirst]="false"
                [options]="tributos"
                [(ngModel)]="configContaSearchRequest.tributoId"
                (onClear)="clearTributo()"
            ></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-12" *ngIf="configContaSearchRequest.tipoConta == 'NAO_TRIBUTARIA'">
            <label>Natureza da Conta</label>
            <p-dropdown
                optionLabel="descricao"
                optionValue="naturezaContaCorrenteId"
                showClear="true"
                [autoDisplayFirst]="false"
                [options]="naturezaContaCorrentes"
                [(ngModel)]="configContaSearchRequest.naturezaContaCorrenteId"
            ></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-2">
            <label title="regraCriacaoContaId">Código</label>
            <input type="text" pInputText [(ngModel)]="configContaSearchRequest.configContaId">
        </div>
        <div class="p-field p-col-12 p-md-10">
            <label>Nome Completo</label>
            <input type="text" pInputText autocomplete="nope" [(ngModel)]="configContaSearchRequest.nomeCompleto">
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label>Nome Resumido</label>
            <input type="text" pInputText autocomplete="nope" [(ngModel)]="configContaSearchRequest.nomeResumido">
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label>Origem da Informação</label>
            <p-dropdown
                optionLabel="nome"
                optionValue="origemInformacaoId"
                showClear="true"
                [autoDisplayFirst]="false"
                [options]="origemInformacaos"
                [(ngModel)]="configContaSearchRequest.origemInformacaoId"
                (onClear)="clearOrigem()"
            ></p-dropdown>
        </div>

        <div class="p-field p-col-12 p-md-6">
            <label title="dataInicioVigencia">Início da Vigência</label>
            <p-calendar
                dateFormat="dd/mm/yy"
                appendTo="body"
                [showIcon]="true"
                [(ngModel)]="dataInicioVigencia"
                [maxDate]="dataFimVigencia"
                (ngModelChange)="onSelectDataInicioVigencia($event)"
            ></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label title="dataFimVigencia">Fim da Vigência</label>
            <p-calendar
                dateFormat="dd/mm/yy"
                appendTo="body"
                [showIcon]="true"
                [(ngModel)]="dataFimVigencia"
                [minDate]="dataInicioVigencia"
                (ngModelChange)="onSelectDataFimVigencia($event)"
            ></p-calendar>
        </div>
    </div>
    <p-table [value]="(configContaSearchResponses$ | async) || []">
        <ng-template pTemplate="header">
            <tr>
                <th>Código</th>
                <th>Nome Resumido</th>
                <th>Origem Informação</th>
                <th>Situação</th>
                <th>Início Vigência</th>
                <th>Fim Vigência</th>
                <th>
                    <div class="field-checkbox" *ngIf="multipleSelection; else acoes" title="Selecionar todos">
                        <p-checkbox (onChange)="onSelectAll($event)" [(ngModel)]="selectedAll" [binary]="true" inputId="binary"></p-checkbox>
                    </div>
                    <ng-template #acoes>
                        Ações
                    </ng-template>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-cc>
            <tr>
                <td>{{cc.configContaId}}</td>
                <td>{{cc.nomeResumido}}</td>
                <td>{{cc.origemInformacao.sigla}}</td>
                <td>{{cc.situacao}}</td>
                <td>{{convertDateBr(cc.dataInicioVigencia)}}</td>
                <td>{{convertDateBr(cc.dataFimVigencia)}}</td>
                <td *ngIf="!multipleSelection">
                    <button pButton pRipple title="Selecionar" type="button" icon="pi pi-check" class="p-button-rounded p-button-warning" (click)="select(cc)"></button>
                </td>
                <td *ngIf="multipleSelection">
                    <div class="field-checkbox" *ngIf="multipleSelection">
                        <p-checkbox [(ngModel)]="selectedConfigContas" [value]="cc"></p-checkbox>
                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr >
                <td colspan="7" class="p-text-center">
                    Nenhum registro localizado
                </td>
            </tr>
        </ng-template>
    </p-table>
    <p-paginator [rows]="5" [totalRecords]="pagination.totalElements" (onPageChange)="onPageChange($event)"></p-paginator>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-times" (click)="displayMaximizable=false" label="Fechar" [style]="{'float': 'left'}"></p-button>
        <p-button icon="pi pi-search" (click)="search()" label="Pesquisar"></p-button>
        <p-button *ngIf="multipleSelection" icon="pi pi-check-circle" (click)="confirmarSelecionados()" label="Confirmar"></p-button>
        <button (click)="onClearFilter()" pButton pRipple type="button" icon="pi pi-filter-slash" class="p-button-danger" label="Limpar"></button>
    </ng-template>
</p-dialog>
