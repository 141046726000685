

export class ValorSimularHistorico {
    valorLancadoPrincipal: number;
      valorLancadoMulta: number;
      valorLancadoJuros: number;
      valorLancadoMultaInfra: number;
      valorTotalLancado: number;
      valorAtualizadoPrincipal: number;
      valorAtualizadoMulta: number;
      valorAtualizadoJuros: number;
      valorAtualizadoMultaInfra: number;
      saldoTotalAtualizado: number;
      valorSimuladoPrincipal: number;
      valorSimuladoMulta: number;
      valorSimuladoJuros: number;
      valorSimuladoMultaInfra: number;
      saldoTotalSimulado: number;

    constructor(init?: Partial<ValorSimularHistorico>) {
        if (init) {
            Object.assign(this, init);
        } else {
            this.valorLancadoPrincipal = null;
            this.valorLancadoMulta = null;
            this.valorLancadoJuros = null;
            this.valorLancadoMultaInfra = null;
            this.valorTotalLancado = null;
            this.valorAtualizadoPrincipal = null;
            this.valorAtualizadoMulta = null;
            this.valorAtualizadoJuros = null;
            this.valorAtualizadoMultaInfra = null;
            this.saldoTotalAtualizado = null;
            this.valorSimuladoPrincipal = null;
            this.valorSimuladoMulta = null;
            this.valorSimuladoJuros = null;
            this.valorSimuladoMultaInfra = null;
            this.saldoTotalSimulado = null;
        }
    }
}
