<p-card>
    <div class="grid grid-nogutter justify-content-end margin-bt">
        <button
            pButton
            label="Voltar"
            icon="pi pi-angle-left"
            class="p-button-outlined p-mr-2"
            (click)="irSelecionarConta()"
        ></button>
    </div>

    <div class="p-grid" [style]="{ 'margin-top': '20px' }">
        <div class="p-md-8 p-sm-6">
            <p-fieldset legend="Conta Corrente">
                <!-- CONTA -->
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-md-3 p-sm-6">
                        <strong>Nº Conta Corrente</strong>
                        <p>{{ detalheConta?.contaCorrenteId }}</p>
                    </div>
                    <div class="p-field p-md-6 p-sm-6">
                        <strong>Conta Corrente</strong>
                        <p>
                            {{
                                detalheConta?.configContaId +
                                    " - " +
                                    detalheConta?.configContaNomeCompleto
                            }}
                        </p>
                    </div>
                    <div class="p-field p-md-3 p-sm-6">
                        <strong>Período de Referência</strong>
                        <p>
                            {{
                                detalheConta?.periodoReferencia
                                    ? converteMesAno(
                                          detalheConta?.periodoReferencia
                                      )
                                    : "-"
                            }}
                        </p>
                    </div>
                    <div class="p-field p-md-3 p-sm-6">
                        <strong>Data de Vencimento</strong>
                        <p>
                            {{
                                detalheConta?.dataVencimento
                                    | date : "dd/MM/yyyy"
                            }}
                        </p>
                    </div>
                    <div class="p-field p-md-3 p-sm-6">
                        <strong>Data da criação</strong>
                        <p>
                            {{
                                detalheConta?.dataCriacao | date : "dd/MM/yyyy"
                            }}
                        </p>
                    </div>
                    <div class="p-field p-md-3 p-sm-6">
                        <strong>Tipo de Documento</strong>
                        <p>
                            {{
                                detalheConta?.tipoNumeroDocumentoDesc
                                    ? detalheConta?.tipoNumeroDocumentoDesc
                                    : "-"
                            }}
                        </p>
                    </div>
                    <div class="p-field p-md-3 p-sm-6">
                        <strong>Número do Documento</strong>
                        <p>
                            {{
                                detalheConta?.numeroDocumento
                                    ? detalheConta?.numeroDocumento
                                    : "-"
                            }}
                        </p>
                    </div>
                    <div class="p-field p-md-3 p-sm-6">
                        <strong>Data de atualização</strong>
                        <p>
                            {{
                                detalheConta?.dataAtualizacao
                                    | date : "dd/MM/yyyy"
                            }}
                        </p>
                    </div>
                    <div
                        class="p-field p-md-3 p-sm-6"
                        *ngIf="detalheConta?.cotaUnica === 'SIM'"
                    >
                        <strong>Cota Única</strong>
                        <p>
                            {{
                                detalheConta?.cotaUnica
                                    ? detalheConta?.cotaUnica
                                    : "-"
                            }}
                        </p>
                    </div>
                    <div
                        class="p-field p-md-3 p-sm-6"
                        *ngIf="detalheConta?.parcela"
                    >
                        <strong>Número da Cota/Parcela</strong>
                        <p>
                            {{
                                detalheConta?.parcela
                                    ? detalheConta?.parcela
                                    : "-"
                            }}
                        </p>
                    </div>
                    <div
                        class="p-field p-md-3 p-sm-6"
                        *ngIf="detalheConta?.contaCorrenteCotaUnicaId"
                    >
                        <strong>Nº da Conta (Cota Única)</strong>
                        <p>{{ detalheConta?.contaCorrenteCotaUnicaId }}</p>
                    </div>
                    <div class="p-field p-md-3 p-sm-6">
                        <strong>Saldo da Conta</strong>
                        <p [style]="{ 'background-color': '#ffcbdb' }">
                            {{
                                detalheConta?.saldoContaDesc
                                    ? detalheConta?.saldoContaDesc
                                    : "-"
                            }}
                        </p>
                    </div>
                    <div class="p-field p-md-3 p-sm-6">
                        <strong>Situação do Crédito</strong>
                        <p>
                            {{
                                detalheConta?.situacaoCreditoDesc
                                    ? detalheConta?.situacaoCreditoDesc
                                    : "-"
                            }}
                        </p>
                    </div>
                    <div class="p-field p-md-3 p-sm-6">
                        <strong>Situação da Cobrança</strong>
                        <p>
                            {{
                                detalheConta?.situacaoCobrancaDesc
                                    ? detalheConta?.situacaoCobrancaDesc
                                    : "-"
                            }}
                        </p>
                    </div>
                    <div class="p-field p-md-3 p-sm-6">
                        <strong>Estado da Conta</strong>
                        <p>{{ detalheConta?.estadoContaDesc }}</p>
                    </div>
                    <!-- Informações somente na os10. Aplicar o caracter “-" Inicio-->
                    <div class="p-field p-md-3 p-sm-6">
                        <strong>DARE Bloqueado</strong>
                        <p>
                            {{
                                detalheContaAnexo?.bloqueiaEmissaoDare
                                    ? detalheContaAnexo?.bloqueiaEmissaoDare
                                    : "-"
                            }}
                        </p>
                    </div>
                    <div
                        *ngIf="detalheContaAnexo?.bloqueiaEmissaoDare != 'Sim'"
                        class="p-field p-md-3 p-sm-6"
                    >
                        <strong>Código de receita</strong>
                        <p>
                            {{
                                detalheContaAnexo?.codReceita
                                    ? detalheContaAnexo?.codReceita
                                    : "-"
                            }}
                            - {{ detalheContaAnexo?.nomeReceita }}
                        </p>
                    </div>
                    <!-- Informações somente na os10. Aplicar o caracter “-" Fim-->
                    <div class="p-field p-md-3 p-sm-6">
                        <strong>Anexo</strong>
                        <p
                            (click)="downloadFile()"
                            [ngClass]="{ 'cursor-pointer': downloadAnexo }"
                        >
                            {{ downloadAnexo ? textoDocDownload : " - " }}
                            <i
                                *ngIf="downloadAnexo"
                                class="fa-solid fa-download"
                            ></i>
                        </p>
                    </div>
                </div>
            </p-fieldset>
        </div>
        <!-- POSIÇÃO SALDOS -->
        <div class="p-md-4 p-sm-6">
            <p-fieldset legend="Posição de Saldos">
                <p-table
                    [value]="detalheContaPosicaoSaldo"
                    styleClass="p-datatable-gridlines"
                >
                    <ng-template pTemplate="header">
                        <tr>
                            <th>VALOR INICIAL</th>
                            <th class="text-right">
                                {{
                                    detalheConta?.dataBaseLancamento
                                        | date : "dd/MM/yyyy"
                                }}
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-c>
                        <tr>
                            <td>Saldo da Conta</td>
                            <td
                                [ngClass]="{
                                    'saldo-negativo': maior(
                                        c?.valorTotalLancado
                                    ),
                                    'text-right': true
                                }"
                            >
                                {{
                                    c?.valorTotalLancado | currency : "BRL" : ""
                                }}
                            </td>
                        </tr>
                        <tr
                            *ngFor="
                                let c of detalheContaAnexo?.contaCorrenteFinanceiros
                            "
                        >
                            <td>{{ c?.naturezaItemCalculo?.descricao }}</td>
                            <td
                                [ngClass]="{
                                    'saldo-negativo': maior(c?.valorLancado),
                                    'text-right': true
                                }"
                            >
                                {{ c?.valorLancado | currency : "BRL" : "" }}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <p-table
                    [value]="detalheContaPosicaoSaldo"
                    styleClass="p-datatable-gridlines"
                    [style]="{ 'margin-top': '20px' }"
                >
                    <ng-template pTemplate="header">
                        <tr>
                            <th>SALDO ATUAL</th>
                            <th class="text-right">
                                {{
                                    detalheConta?.dataAtualizacao
                                        | date : "dd/MM/yyyy"
                                }}
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-c>
                        <tr>
                            <td>Saldo da Conta</td>
                            <td
                                [ngClass]="{
                                    'saldo-negativo': maior(
                                        c?.saldoTotalAtualizado
                                    ),
                                    'text-right': true
                                }"
                            >
                                {{
                                    c?.saldoTotalAtualizado
                                        | currency : "BRL" : ""
                                }}
                            </td>
                        </tr>
                        <tr
                            *ngFor="
                                let c of detalheContaAnexo?.contaCorrenteFinanceiros
                            "
                        >
                            <td>{{ c?.naturezaItemCalculo?.descricao }}</td>
                            <td
                                [ngClass]="{
                                    'saldo-negativo': maior(c?.saldoAtualizado),
                                    'text-right': true
                                }"
                            >
                                {{ c?.saldoAtualizado | currency : "BRL" : "" }}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-fieldset>
        </div>
    </div>
</p-card>
<!-- DETALHAMENTO -->
<p-card [style]="{ 'margin-top': '20px' }">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12">
            <p-fieldset legend="Detalhamento">
                <!-- DETALHE IPVA -->
                <div
                    *ngIf="valorTabPanel === 'IPVA'"
                    class="p-fluid p-formgrid p-grid"
                >
                    <div class="p-field p-md-3 p-sm-6">
                        <strong>Proprietário</strong>
                        <p>
                            {{ insereMascara(contribuinte?.baseCnpj) }} -
                            {{ contribuinte?.nome }}
                        </p>
                    </div>
                    <div class="p-field p-md-3 p-sm-6">
                        <strong>Renavam</strong>
                        <p>{{ detalheContribuinte?.renavam }}</p>
                    </div>
                    <div class="p-field p-md-3 p-sm-6">
                        <strong>Chassi</strong>
                        <p>{{ detalheContribuinte?.chassi }}</p>
                    </div>
                    <div class="p-field p-md-3 p-sm-6">
                        <strong>Placa</strong>
                        <p>
                            {{
                                detalheContribuinte?.placa
                                    ? detalheContribuinte?.placa
                                    : "-"
                            }}
                        </p>
                    </div>
                    <div class="p-field p-md-3 p-sm-6">
                        <strong>Cor</strong>
                        <p>
                            {{
                                detalheContribuinte?.cor
                                    ? detalheContribuinte?.cor
                                    : "-"
                            }}
                        </p>
                    </div>
                    <div class="p-field p-md-3 p-sm-6">
                        <strong>Ano Modelo</strong>
                        <p>
                            {{
                                detalheContribuinte?.anoModelo
                                    ? detalheContribuinte?.anoModelo
                                    : "-"
                            }}
                        </p>
                    </div>
                    <div class="p-field p-md-3 p-sm-6">
                        <strong>Ano Fabricação</strong>
                        <p>
                            {{
                                detalheContribuinte?.anoFabricacao
                                    ? detalheContribuinte?.anoFabricacao
                                    : "-"
                            }}
                        </p>
                    </div>
                    <div class="p-field p-md-3 p-sm-6">
                        <strong>Marca/Modelo</strong>
                        <p>
                            {{
                                detalheContribuinte?.marcaModelo
                                    ? detalheContribuinte?.marcaModelo
                                    : "-"
                            }}
                        </p>
                    </div>
                    <div class="p-field p-md-3 p-sm-6">
                        <strong>Combustível</strong>
                        <p>
                            {{
                                detalheContribuinte?.codCombustivel
                                    ? detalheContribuinte?.codCombustivel
                                    : "-"
                            }}
                            -
                            {{
                                detalheContribuinte?.combustivel
                                    ? detalheContribuinte?.combustivel
                                    : "-"
                            }}
                        </p>
                    </div>
                    <div class="p-field p-md-3 p-sm-6">
                        <strong>Categoria</strong>
                        <p>
                            {{
                                detalheContribuinte?.categoriaVeiculo
                                    ? detalheContribuinte?.categoriaVeiculo
                                    : "-"
                            }}
                            -
                            {{
                                detalheContribuinte?.categoriaVeiculoDesc
                                    ? detalheContribuinte?.categoriaVeiculoDesc
                                    : "-"
                            }}
                        </p>
                    </div>
                    <div class="p-field p-md-3 p-sm-6">
                        <strong>Cilindrada</strong>
                        <p>
                            {{
                                detalheContribuinte?.cilindrada
                                    ? detalheContribuinte?.cilindrada
                                    : "-"
                            }}
                        </p>
                    </div>
                    <div class="p-field p-md-3 p-sm-6">
                        <strong>Potência</strong>
                        <p>
                            {{
                                detalheContribuinte?.potencia
                                    ? detalheContribuinte?.potencia
                                    : "-"
                            }}
                        </p>
                    </div>
                </div>
                <!-- DETALHE ITCD -->
                <div
                    *ngIf="valorTabPanel === 'ITCD'"
                    class="p-fluid p-formgrid p-grid"
                >
                    <div class="p-field p-md-3 p-sm-6">
                        <strong>Responsável</strong>
                        <p>
                            {{
                                detalheContaItcd?.cpfCnpj
                                    ? detalheContaItcd?.cpfCnpj
                                    : "-"
                            }}
                            -
                            {{
                                detalheContaItcd?.nomeResponsavel
                                    ? detalheContaItcd?.nomeResponsavel
                                    : "-"
                            }}
                        </p>
                    </div>
                    <div class="p-field p-md-3 p-sm-6">
                        <strong>Número da Declaração</strong>
                        <p>
                            {{
                                detalheContaItcd?.tdiNumDecl
                                    ? detalheContaItcd?.tdiNumDecl
                                    : "-"
                            }}
                        </p>
                    </div>
                    <div class="p-field p-md-3 p-sm-6">
                        <strong>Tipo de Declaração</strong>
                        <p>
                            {{
                                detalheContaItcd?.codTipoDesc
                                    ? detalheContaItcd?.codTipoDesc
                                    : "-"
                            }}
                        </p>
                    </div>
                    <div class="p-field p-md-3 p-sm-6">
                        <strong>Tipo de Transmissão</strong>
                        <p>
                            {{
                                detalheContaItcd?.tipoTransmissaoDesc
                                    ? detalheContaItcd?.tipoTransmissaoDesc
                                    : "-"
                            }}
                        </p>
                    </div>
                </div>
                <!-- DETALHE COMPONENT EVENTO E TRANSAÇÕES & DETALHE ICMS-->

                <ccp-detalhe-eventos-transacoes></ccp-detalhe-eventos-transacoes>
            </p-fieldset>
        </div>
    </div>
    <div class="grid grid-nogutter justify-content-end margin-bt">
        <button
            pButton
            label="Voltar"
            icon="pi pi-angle-left"
            class="p-button-outlined p-mr-2"
            (click)="irSelecionarConta()"
        ></button>
    </div>
</p-card>
