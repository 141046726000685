import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { EnumsService } from 'src/app/core/enums.service';
import { EventoLancamento } from 'src/app/models/evento-lancamento';
import { EventoLancamentoService } from '../evento-lancamento.service';
import * as ConfigContaSearchModalActions from '../../config-conta/config-conta-search-modal/config-conta-search-modal-actions';
import { UtilsService } from 'src/app/shared/utils.service';
import { AlertsUtil } from 'src/app/utils/alerts/alerts.util';
import { EventosSearchRequest } from 'src/app/request/eventos-search-request';
@Component({
    selector: "app-config-evento-lancamento",
    templateUrl: "./config-evento-lancamento.component.html",
    styleUrls: ["./config-evento-lancamento.component.scss"],
})
export class ConfigEventoLancamentoComponent implements OnInit {
    eventoLancamento: EventoLancamento = {
        dataFimVigencia: "",
        dataInicioVigencia: "",
        descricao: "",
        eventoEstado: { eventoEstadoId: 0 },
        eventoLancamentoContas: [],
        eventoLancamentoId: 0,
        eventos: { eventosId: 0 },
        situacao: "ATIVA",
        transacao: { transacaoId: 0 },
    };
    eventoLancamentoId = null;
    situacaoComboList: any[];
    contasAssociadasList: any[] = [];
    eventoEstadoComboList: any[];
    transacaoComboList: any[];
    eventosList: any[];
    eventoEstadoCriacaoComboList: any[];
    transacaoLancametoComboList: any[];
    eventoLancamentoForm: FormGroup;
    eventoLancamentoRequest: any;
    eventosSearchRequest: EventosSearchRequest;
    implantadoEnum: any[];
    somenteLetrasEspaco: RegExp = /[a-zA-Z\u00C0-\u00FF ]+/i;
    somenteLetras: RegExp = /([aA-zZ]+)/;

    constructor(
        private fb: FormBuilder,
        private util: UtilsService,
        private router: Router,
        private alertUtil: AlertsUtil,
        private enumsService: EnumsService,
        private activatedRoute: ActivatedRoute,
        private eventoLancamentoService: EventoLancamentoService,
        private storeConfigContaSearchModal: Store<{
            configContaSearchModal: { displayMaximizable: boolean };
        }>
    ) {}

    ngOnInit(): void {
        this.eventosSearchRequest = new EventosSearchRequest();
        this.getCombos();
        // this.criaForm();
        this.activatedRoute.params.subscribe((objParam) => {
            this.eventoLancamentoId = objParam.id;
            if (objParam.id) {
                this.getEventoLancamento();
                this.getByEventoLancamentoContaId(this.eventoLancamentoId);
            } else {
                this.eventoLancamentoId = null;
                this.criaForm(this.criaFortmEmbranco());
            }
        });
        this.eventoLancamento.eventoEstado = { eventoEstadoId: 1 };
        this.closeModal();
    }

    get f() {
        return this.eventoLancamentoForm.controls;
    }

    criaForm(obj) {
        this.eventoLancamentoForm = this.fb.group({
            eventoLancamentoId: [obj.eventoLancamentoId],
            descricao: [
                obj.descricao,
                [Validators.required, Validators.maxLength(150)],
            ],
            situacao: [obj.situacao],
            dataInicioVigencia: [
                obj.dataInicioVigencia
                    ? new Date(obj.dataInicioVigencia)
                    : null,
                Validators.required,
            ],
            dataFimVigencia: [
                obj.dataFimVigencia ? new Date(obj.dataFimVigencia) : null,
            ],
            eventoEstado: [
                obj.eventoEstado?.eventoEstadoId,
                Validators.required,
            ],
            transacao: [obj.transacao?.transacaoId, Validators.required],
            eventos: [1],
            eventoLancamentoContas: [],
            dataInicioVigenciacontaAssociada: [],
            implantado: [obj.implantado ? obj.implantado : 'NAO']
        });
    }
    
    private criaFortmEmbranco(): any {
        return {
            eventoLancamentoId: 0,
            descricao: null,
            situacao: "ATIVA",
            dataInicioVigencia: null,
            dataFimVigencia: null,
            eventoEstadoId: null,
            transacao: null,
            eventos: 1,
            eventoLancamentoContas: null,
            implantado: 'NAO'
        } as any;
    }

    getCombos() {
        this.getEnums();
        /**Eventos de Estado de Criação de Conta* */
        const eventoEstadoParams = {
            dataFimVigenciaIsNull:true,
            implantado:'SIM',
            situacao:'ATIVA',
            tipoEvento:'CRIACAO_DE_CONTA',
            tipoProcessamento: 'AUTOMATICO'
        };
        this.eventoLancamentoService
            .getEventoEstado(eventoEstadoParams)
            .subscribe((resp) => {
                this.eventoEstadoComboList = resp;
            });
        /**Transação de Lançamento */
        const transacaoParams = {
            situacao: "ATIVA",
            eventosId:1,
            geraTransacaoInicial: 'SIM'
        };
        this.eventoLancamentoService
            .getTransacaoLancamento(transacaoParams)
            .subscribe((resp) => {
                this.transacaoComboList = resp;
            });
    }

    async getEnums() {
        let nameSituacao = "Situacao";
        let nameImplantado = "SimNaoEnum";
        this.situacaoComboList = await this.enumsService
            .getEnumNome(nameSituacao)
            .toPromise();
        this.implantadoEnum = await this.enumsService
        .getEnumNome(nameImplantado)
        .toPromise();
        // this.eventosList = await this.eventoLancamentoService
        // .getTpoEvento(this.eventosRequest())
        // .toPromise();
        this.situacaoComboList = this.situacaoComboList.reverse();
        return new Promise((resolve) => {
            resolve("");
        });
    }

    eventosRequest() {
        const data = JSON.parse(JSON.stringify(this.eventosSearchRequest));
        // data.tipoEventoId = '3'
        // data.tipoEventoDescricao = 'Eventos de Lançamento'
        return data; 
    }

    getEventoLancamento() {
        const objParams = {
            eventoLancamentoId: this.eventoLancamentoId,
        };
        this.eventoLancamentoService
            .getEventoLancamentoById(objParams)
            .subscribe((resp) => {
                this.eventoLancamentoRequest = resp.content[0];
                this.criaForm(this.eventoLancamentoRequest);
            });
    }

    /**Lista contas associadas */
    getByEventoLancamentoContaId(eventoLancamentoId) {
        this.contasAssociadasList = [];
        this.eventoLancamentoService
        .getByEventoLancamentoContaId(eventoLancamentoId)
        .subscribe((resp) => {
            /**Popula lista contas associadas */
            resp.forEach(element => {
                let dataFimVigencia = element.dataFimVigencia != null ? new Date(element.dataFimVigencia) : '';
                this.contasAssociadasList = [
                    ...this.contasAssociadasList,
                    {
                        configContaId: element.configConta.configContaId,
                        nomeDaConta: element.configConta.nomeCompleto,
                        situacao: element.situacao,
                        inicioVigenciaAssociacao: new Date(element.dataInicioVigencia),
                        fimVigenciaAssociacao: dataFimVigencia,
                        eventoLancamentoContaId: element.eventoLancamentoContaId
                    },
                ];
            });
           
        });
    }

    ativaInativaConta(conta){
        this.alertUtil.confirm(
            `Deseja ${conta.situacao === 'ATIVA' ? 'inativar' : 'ativar'} a associação da Conta
             ${conta.configContaId} - ${conta.nomeDaConta} com o evento de lançamento?`).then((r) =>{
                if(r.isConfirmed){
                    this.eventoLancamentoService.putAtivaInativaContaAssociada(conta.eventoLancamentoContaId).subscribe(
                        resp => {
                            this.alertUtil.saveSuccess(`Conta ${conta.situacao === 'ATIVA' ? 'Inativada' : 'Ativada'} com sucesso`)
                            .then((r) => {
                                this.getByEventoLancamentoContaId(this.eventoLancamentoId);
                            });
                        }
                    )
                }
             });
    }

    prepareSave() {
        this.eventoLancamento.dataInicioVigencia = this.f.dataInicioVigencia
            .value
            ? this.util.convertDateToSave(this.f.dataInicioVigencia.value)
            : "";
        this.eventoLancamento.dataFimVigencia = this.f.dataFimVigencia.value
            ? this.util.convertDateToSave(this.f.dataFimVigencia.value)
            : "";
        this.eventoLancamento.descricao = this.f.descricao.value.toUpperCase();
        this.eventoLancamento.eventoEstado = {
            eventoEstadoId: this.f.eventoEstado.value,
        };

        this.eventoLancamento.eventoLancamentoId =
            this.f.eventoLancamentoId.value;
        this.eventoLancamento.situacao = this.f.situacao.value;
        this.eventoLancamento.transacao = {
            transacaoId: this.f.transacao.value,
        };
        this.eventoLancamento.eventos = { eventosId: this.f.eventos.value };
        this.prepareEventoLancamento();
        return this.eventoLancamento;
    }

    save(): void {
        this.eventoLancamentoForm.markAllAsTouched();
        if (this.eventoLancamentoForm.invalid) {
            return;
        }
        this.saveEventoLancamento(this.prepareSave());
    }

    saveEventoLancamento(eventoLancamento: EventoLancamento) {
        let dataInicio = this.util.convertDateToSave(this.f.dataInicioVigencia.value);
        let dataFim = this.util.convertDateToSave(this.f.dataFimVigencia.value);
        let eventoConta = this.contasAssociadasList.find(ec => 
            !(this.util.convertDateToSave(ec.inicioVigenciaAssociacao) >= dataInicio && this.util.convertDateToSave(ec.fimVigenciaAssociacao) <= dataFim)
        );

        if(eventoConta) {
            this.alertUtil.warning(`O período de vigência da conta ${eventoConta.configContaId} ${eventoConta.nomeDaConta} está fora do período de vigência do evento de lançamento.`);
            return;     
        }

        if (this.eventoLancamento.eventoLancamentoContas.length < 1) {
            this.alertUtil.warning(
                `No mínimo uma conta deve ser associada ao evento de lançamento`
            );
            return;
        }
        if(eventoLancamento.eventoLancamentoContas.some((ec) => ec.dataInicioVigencia === '')){
            return;
        }
        const data = JSON.parse(JSON.stringify(eventoLancamento));
        this.eventoLancamentoService.postEventoLancamento(data).subscribe(
            (resp) => {
                this.alertUtil.saveSuccess(`Evento de lançamento ${resp.eventoLancamentoId} foi salvo com sucesso`)
                .then((r) => {
                    this.router.navigateByUrl("/evento-lancamento/evento-lancamento-list");
                });
            },
            (erro) => {
                this.alertUtil.handleError(erro);
            }
        );
    }

    /**Evento modal*/
    outConfigConta(conta) {
        this.addContasAssociadas(conta);
        this.closeModal();
    }

    /**Open modal*/
    showModalConta() {
        this.storeConfigContaSearchModal.dispatch(new ConfigContaSearchModalActions.SetDisplayMaximizable(true));
    }

    /**Close Modal */
    closeModal() {
        this.storeConfigContaSearchModal.dispatch(
            new ConfigContaSearchModalActions.SetDisplayMaximizable(false)
        );
    }

    addContasAssociadas(conta) {
        if(this.contasAssociadasList.find((c) => c.configContaId === conta.configContaId)){
            this.alertUtil.warning(
                `Conta ${conta.configContaId} ${conta.nomeResumido} já foi adicionada.`
            );
            return;
        }
        this.contasAssociadasList = [
            ...this.contasAssociadasList,
            {
                configContaId: conta.configContaId,
                nomeDaConta: conta.nomeCompleto,
                situacao: "Ativa",
                inicioVigenciaAssociacao: this.f.dataInicioVigencia.value != null ? this.f.dataInicioVigencia.value : new Date(),
                fimVigenciaAssociacao: null
            },
        ];
    }

    prepareEventoLancamento() {
        this.eventoLancamento.eventoLancamentoContas = [];
        this.contasAssociadasList.forEach((element, index) => {
            const objLancamentoContas: any = {
                configConta: { configContaId: element.configContaId },
                dataFimVigencia: element.fimVigenciaAssociacao
                    ? this.util.convertDateTime(new Date(element.fimVigenciaAssociacao))
                    : "",
                dataInicioVigencia: element.inicioVigenciaAssociacao
                    ? this.util.convertDateTime(new Date(element.inicioVigenciaAssociacao))
                    : "",
                eventoLancamento: {
                    eventoLancamentoId: this.f.eventoLancamentoId.value,
                },
                eventoLancamentoContaId: element.eventoLancamentoContaId
                    ? element.eventoLancamentoContaId
                    : 0,
                situacao: element.situacao.toUpperCase(),
            };
            this.eventoLancamento.eventoLancamentoContas.push(objLancamentoContas);
        });
    }

    removerConta(i, item) {
        let id = '';
        let nomeConta = '';

        id = item.eventoLancamentoContaId;
        nomeConta = item.nomeDaConta;

        if(id){
            this.alertUtil.confirm(`Deseja excluir a associação da conta ${id} - ${nomeConta} com o evento de lançamento?`).then((result) => {
                if (result.isConfirmed) {
                    this.eventoLancamentoService.deleteContaAssociada(id).subscribe(
                        resp => {
                            this.alertUtil.saveSuccess("Removido com sucesso").then(
                                () => {
                                    this.contasAssociadasList.splice(i, 1);
                                }
                            );
                        }
                    ), erro =>{
                        this.alertUtil.handleError(erro)
                    }
                }
            }); 
        } else {
            this.contasAssociadasList.splice(i, 1);
        }
    }

    onSelectDataMenorVigencia(event: any) {
        let dataInicio = this.util.convertDateToSave(
            this.f.dataInicioVigencia.value
        );
        let dataFim = this.util.convertDateToSave(this.f.dataFimVigencia.value);

        if (dataFim < dataInicio) {
            this.alertUtil.warning(
                "Fim da Vigência deve ser maior ou igual ao Início da Vigência"
            );
            this.f.dataFimVigencia.setValue(null);
        }
    }

    onSelectDataMenorAssociacao(event: any, index: any) {
        let dataInicio = this.util.convertDateToSave(
            event.inicioVigenciaAssociacao
        );
        let dataFim = this.util.convertDateToSave(
            event.fimVigenciaAssociacao
        );

        if (dataFim < dataInicio) {
            this.alertUtil.warning(
                "Fim da associação deve ser maior ou igual ao Início da Associação"
                ).then((r) => {
                    this.contasAssociadasList[index].fimVigenciaAssociacao = null;
                })
        }
    }
}
