<p-dialog
    header="Regra de Imputação"
    (onShow)="onShow()"
    (onHide)="onHide()"
    [(visible)]="eventoPagamentoRegraImputacaoModalService.displayMaximizable"
    [modal]="true"
    [style]="{ width: '60vw', height: '20vw' }"
    [maximizable]="true"
    [baseZIndex]="10000"
    [draggable]="true"
    [resizable]="false"
>
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-6">
            <label title="Regra de Imputação">Regra de Imputação*</label>
            <p-dropdown
                optionLabel="descricao"
                showClear="true"
                [autoDisplayFirst]="false"
                [options]="regrasImputacao"
                [(ngModel)]="this.regraImputacao.regraImputacao"
                appendTo="body"
                [disabled]="this.regraImputacaoEdit?.eventoPagtoRgrImputacaoId !== null"
            ></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-4">
            <label title="dataInicioVigencia">Início da Vigência</label>
            <p-calendar
                appendTo="body"
                dateFormat="dd/mm/yy"
                [showIcon]="true"
                [(ngModel)]="dateStart"
                (ngModelChange)="onSelectDataInicioVigencia($event)"
            ></p-calendar>
        </div>
    </div>

    <ng-template pTemplate="footer">
        <p-button
            icon="pi pi-times"
            (click)="
                eventoPagamentoRegraImputacaoModalService.displayMaximizable = false
            "
            label="Fechar"
        ></p-button>
        <p-button
            [disabled]="validaConfirmar()"
            icon="pi pi-save"
            (click)="confirmar()"
            label="Confirmar"
        ></p-button>
    </ng-template>
</p-dialog>


