import { ParamConfigEvento } from "./../../models/param-config-evento";
import { TiposParamsConfigEventos } from "./../../models/tipos-params-config-evento";
import { ParamConfigEventoService } from "./../../services/param-config-evento.service";
import { map, take } from "rxjs/operators";
import { Component, Input, OnInit } from "@angular/core";
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    Validators,
} from "@angular/forms";
import { EnumService } from "src/app/compartilhado/services/enum.service";
import { TransacaoService } from "src/app/component/transacao-financeira/services/transacao.service";
import Swal from "sweetalert2";
import { EventoGerenciarConfigService } from "../../services/evento-gerenciar-config.service";

@Component({
    selector: "app-transacoes",
    templateUrl: "./transacoes.component.html",
    styleUrls: ["./transacoes.component.scss"],
})
export class TransacoesComponent implements OnInit {
    @Input() form!: FormGroup;
    @Input() validarCampos: boolean;
    @Input() dadosCarregados: any;

    transacoesForm: FormGroup = new FormBuilder().group({
        tipoImputacao: [
            null,
            [Validators.required, idRegraImputacaoRequiredValidator],
        ],
        idRegraImputacao: [null],
        listaTransacoes: [[], Validators.required],
    });

    evento: any;
    transacaoSelecionada: number;
    pulaEtapa = false;
    transacoesList: any[];
    tipoImputacaoList: any[];
    regraImputacaoList: any[];
    implantado: boolean | null;
    private permissoes: ParamConfigEvento[] = [];

    constructor(
        private transacaoService: TransacaoService,
        private enumService: EnumService,
        private eventoGerenciarConfigService: EventoGerenciarConfigService
    ) {}

    ngOnInit() {
        const transacoesForm = this.form.get("transacoesForm").value;
        this.eventoGerenciarConfigService.onPermissoesCarregadas();
        if (transacoesForm) {
            this.transacoesForm = transacoesForm;
        } else {
            this.form.get("transacoesForm").setValue(this.transacoesForm);
        }

        this.evento = this.form
            ?.getRawValue()
            ?.eventosForm?.getRawValue()?.evento;

        this.eventoGerenciarConfigService
            .onPermissoesCarregadas()
            .pipe(take(1))
            .subscribe((permissions) => {
                this.permissoes = permissions;
                const params = permissions.map((p) => p.parametro);


                if (params.includes(
                    TiposParamsConfigEventos.P4_PULA_ETAPA
                )) {
                    this.pulaEtapa = true;
                    this.transacoesForm.get('listaTransacoes').disable();
                    this.transacoesForm.get('tipoImputacao').disable();
                    return; //transacoes nao devem ser informadas
                }

                this.transacaoService
                    .getTransacaoByIdEventos(this.evento.eventosId)
                    .subscribe((res) => {
                        if (
                            params.includes(
                                TiposParamsConfigEventos.P4_CREDITO_APENAS
                            )
                        ) {
                            this.transacoesList = res.filter(
                                (t) => t.operacao == "CREDITO"
                            );
                        } else if (
                            params.includes(
                                TiposParamsConfigEventos.P4_DEBITO_APENAS
                            )
                        ) {
                            this.transacoesList = res.filter(
                                (t) => t.operacao == "DEBITO"
                            );
                        } else {
                            this.transacoesList = res;
                        }
                    });
            });

        this.enumService
            .getByName("TipoImputacao")
            .subscribe((res) => (this.tipoImputacaoList = res));

        this.eventoGerenciarConfigService
            .getAllRegraImputacao()
            .subscribe((res) => (this.regraImputacaoList = res));

        if (this.dadosCarregados?.transacoes) {
            this.transacoesForm.patchValue(this.dadosCarregados.transacoes);
            this.dadosCarregados.transacoes = null;
        }
        if (this.dadosCarregados?.implantado) {
            this.implantado = this.dadosCarregados?.implantado;
            this.transacoesForm.disable();
        }
    }

    adicionarTransacao() {
        const listaTransacoes: number[] =
            this.transacoesForm.get("listaTransacoes").value;

        const existeTransacao: boolean = !!listaTransacoes.find(
            (t) => t === this.transacaoSelecionada
        );

        if (existeTransacao) {
            this.transacaoSelecionada = null;
            return;
        }

        if (!this.podeAdicionarTransacao()) {
            Swal.fire("Limite máximo de transações para evento");
            this.transacaoSelecionada = null;
            return;
        }
        listaTransacoes.push(this.transacaoSelecionada);
        this.transacaoSelecionada = null;
        this.transacoesForm.get("listaTransacoes").setValue(listaTransacoes);
    }

    podeAdicionarTransacao(): boolean {
        const param = this.permissoes.find(
            (p) => p.parametro == TiposParamsConfigEventos.P4_QNT_TRANSACAO
        );
        if (!param) return true;
        console.log({ param });
        const listaTransacoes: number[] =
            this.transacoesForm.get("listaTransacoes").value;
        return listaTransacoes.length < param.valorQnt;
    }

    excluirTransacao(transacaoId: number) {
        const listaTransacoes: number[] =
            this.transacoesForm.get("listaTransacoes").value;

        const indexExcluir = listaTransacoes.findIndex(
            (id) => id === transacaoId
        );
        listaTransacoes.splice(indexExcluir, 1);
        this.transacoesForm.get("listaTransacoes").setValue(listaTransacoes);
    }

    get listaTranscoes() {
        const listaTransacoes: number[] =
            this.transacoesForm.get("listaTransacoes").value;
        if (!this.transacoesList || this.transacoesList?.length === 0)
            return [];

        return listaTransacoes?.map((transcaoId) =>
            this.transacoesList?.find(
                (transcao) => transcao.transacaoId === transcaoId
            )
        );
    }
}

function idRegraImputacaoRequiredValidator(formControl: AbstractControl) {
    if (!formControl.parent) {
        return null;
    }

    if (formControl.value === "VARIAVEL_LINEAR") {
        formControl.parent
            .get("idRegraImputacao")
            .setValidators(Validators.required);
    } else {
        formControl.parent.get("idRegraImputacao").clearValidators();
        formControl.parent.get("idRegraImputacao").setValue(null);
    }

    return null;
}
