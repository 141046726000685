<p-card header="Contas Correntes">
    <div class="p-fluid p-formgrid p-grid">

        <p-fieldset legend="Tipo de Conta" class="p-field-radiobutton">
            <div class="p-fluid p-formgrid">
                <div *ngFor="let tc of tiposCadastro" class="p-col-3 p-md-4">
                    <div class="p-field-radiobutton">
                        <p-radioButton [name]="tc.chave" [inputId]="tc.id" [value]="tc.chave"
                            [(ngModel)]="tipoContaSelected"></p-radioButton>
                        <label style="cursor: pointer" [for]="tc.chave">{{tc.valor}}
                        </label>
                    </div>
                </div>
            </div>
        </p-fieldset>

        <div class="p-col-8" *ngIf="tipoContaSelected == 'FISICA' || tipoContaSelected == 'JURIDICA'">
            <div class="p-col-12">
                <div class="p-field p-col-4">
                    <label>CPF/CNPJ</label>
                    <input maxlength="18" [(ngModel)]="contribuinte.cpfCnpj" pInputText
                        (keyup)="formatDocument($event)" />
                </div>
                <div class="p-field p-col-4">
                    <label>Inscrição Estadual</label>
                    <input
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        type="number" maxlength="9" [(ngModel)]="contribuinte.ie" pInputText />
                </div>
                <div class="p-field p-col-10">
                    <label>Nome/Razão Social</label>
                    <input maxlength="100" type="text" [(ngModel)]="contribuinte.nome" pInputText />
                </div>

            </div>
        </div>

        <div class="p-col-8" *ngIf="tipoContaSelected == 'VEICULO'">
            <div class="p-col-12">
                <div class="p-field p-col-4">
                    <label>Chassi</label>
                    <input type="text" maxlength="17" [(ngModel)]="veiculo.chassi" pInputText />
                </div>
                <div class="p-field p-col-4">
                    <label>Renavan</label>
                    <input
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        type="number" maxlength="11" [(ngModel)]="veiculo.renavam" pInputText />
                </div>
                <div class="p-field p-col-4">
                    <label>Placa</label>
                    <input type="text" maxlength="7" [(ngModel)]="veiculo.placa" pInputText />
                </div>

            </div>
        </div>
    </div>

    <div *ngIf="tipoContaSelected == 'VEICULO'  else elseContrib" class="flex justify-content-around flex-wrap ">
        <div style="margin-bottom: 20px">
            <p-button icon="pi pi-search" label="Buscar" class="p-text-center botao-limpar" (click)="searchVeiculo()">
            </p-button>
            <p-button (click)="limpar()" type="button" icon="pi pi-filter-slash" class="p-button-danger ml-3"
                label="Limpar"></p-button>
        </div>

        <app-selecionar-veiculo-list [veiculosListSearch]="veiculosListSearch" [pagination]="pagination"
            (changePageEvent)="onPageChange($event)"></app-selecionar-veiculo-list>
    </div>

    <ng-template #elseContrib class="flex justify-content-around flex-wrap ">
        <div style="margin-bottom: 20px">
            <p-button icon="pi pi-search" label="Buscar" class="p-text-center botao-limpar" (click)="search()">
            </p-button>

            <p-button (click)="limpar()" type="button" icon="pi pi-filter-slash" class="p-button-danger" label="Limpar">
            </p-button>
        </div>
        <app-selecionar-contrib-interna-list [contribuinteListSearch]="contribuinteListSearch" [pagination]="pagination"
            (changePageEvent)="onPageChange($event)">
        </app-selecionar-contrib-interna-list>
    </ng-template>
</p-card>