<p-card>
    <div class="grid grid-nogutter justify-content-end margin-bt">
        <button
            pButton
            label="Voltar"
            icon="pi pi-angle-left"
            class="p-button-outlined p-mr-2"
            routerLink="/"
        ></button>
        <!-- <button
        pButton 
        class="float-btn-next"
        label="Próxima"
        icon="pi pi-angle-right"
        iconPos="right"
        ></button> -->
    </div>
    <ng-template pTemplate="title">
        {{ title }}
    </ng-template>
    <ng-template pTemplate="content">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-3 p-lg-3">
                <label style="white-space: nowrap"
                    >Identificação do Contribuinte *</label
                >
                <p-dropdown
                    placeholder="Selecione o Contribuinte"
                    optionLabel="label"
                    showClear="true"
                    [autoDisplayFirst]="false"
                    [options]="contribuinteList"
                    [(ngModel)]="contribuinteValue"
                    (onChange)="paramContribuinte($event)"
                    [disabled]="loginContribuinte"
                ></p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-2 p-lg-2">
                <label for="">&nbsp;</label>
                <p-inputMask
                    [disabled]="loginContribuinte || !contribuinteValue"
                    *ngIf="valueMask && !valueNumber"
                    [mask]="maskCustom"
                    [placeholder]="placeholder"
                    type="text"
                    [(ngModel)]="valueIdentificacao"
                    (keydown.enter)="pesquisar()"
                ></p-inputMask>
                <!-- <p-inputMask *ngIf="valueMask && valueNumber"  [mask]="maskCustom" [placeholder]="placeholder" type="text" [(ngModel)]="valueIdentificacao" (keydown.enter)="pesquisar()"></p-inputMask> -->
                <input
                    [disabled]="!contribuinteValue"
                    *ngIf="!valueMask"
                    [maxlength]="max"
                    [minlength]="3"
                    [placeholder]="placeholder"
                    type="text"
                    pInputText=""
                    [(ngModel)]="valueIdentificacao"
                    (keydown.enter)="pesquisar()"
                />
                <small
                    style="margin-left: 2px"
                    class="p-error"
                    *ngIf="
                        contribuinteValue?.value == 'nome' &&
                        valueIdentificacao?.length < 3
                    "
                >
                    Informe ao menos mais
                    {{ 3 - valueIdentificacao?.length }} caractere(s)
                </small>
            </div>
            <div class="p-field p-col-12 p-md-2 p-lg-2 top-btn-line">
                <button
                    pButton
                    type="button"
                    icon="pi pi-search"
                    label="Pesquisar"
                    class="p-mr-1 p-text-left"
                    (click)="pesquisar()"
                    [disabled]="
                        valueIdentificacao?.length < 3 ||
                        !contribuinteValue ||
                        loginContribuinte
                    "
                ></button>
            </div>
            <div class="p-field p-col-12 p-md-2 p-lg-2 top-btn-line">
                <button
                    [disabled]="loginContribuinte"
                    pButton
                    type="button"
                    icon="pi pi-trash"
                    label="Limpar"
                    class="p-mr-1 p-button-danger"
                    (click)="limparPesquisa()"
                ></button>
            </div>
        </div>
    </ng-template>
</p-card>

<p-card [style]="{ 'margin-top': '10px' }">
    <ng-template pTemplate="title">
        {{ titleSelecionarContribuinte }}
    </ng-template>

    <p-table #dt1 [value]="ipvaList" [globalFilterFields]="['cpfcnpj', 'nome']">
        <ng-template pTemplate="caption">
            <div class="p-d-flex">
                <span class="p-input-icon-left p-ml-auto">
                    <i class="pi pi-search"></i>
                    <input
                        pInputText
                        type="text"
                        (input)="
                            dt1.filterGlobal($event.target.value, 'contains')
                        "
                        placeholder="Pesquisar na página"
                    />
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="cpfcnpj">
                    CPF/CNPJ <p-sortIcon field="cpfcnpj"></p-sortIcon>
                </th>
                <th pSortableColumn="nome">
                    Nome/Nome Empresarial <p-sortIcon field="nome"></p-sortIcon>
                </th>
                <th>Ações</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-contribuinte>
            <tr>
                <td>{{ this.inserirMascara(contribuinte.baseCnpj) }}</td>
                <td>{{ contribuinte.nome }}</td>
                <td>
                    <i
                        (click)="consultaDebitoContribuinte(contribuinte)"
                        pTooltip="Consultar Débitos"
                        tooltipPosition="top"
                        class="selecao fas fa-search"
                        aria-hidden="true"
                        style="font-size: large"
                    ></i>
                    <i
                        (click)="irSelecaoConta(contribuinte)"
                        disabled
                        pTooltip="Detalhar Conta"
                        tooltipPosition="top"
                        class="selecao fa fa-list"
                        style="font-size: large; margin-left: 8px"
                    ></i>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td class="p-text-center" colspan="6">
                    Nenhum registro encontrado
                </td>
            </tr>
        </ng-template>
    </p-table>
    <p-paginator
        [rows]="20"
        [rowsPerPageOptions]="[20, 50, 100]"
        [totalRecords]="pagination.totalElements"
        (onPageChange)="onPageChange($event)"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{first} - {last} de {totalRecords}"
    ></p-paginator>
    <!-- <div class="grid grid-nogutter justify-content-end margin-bt">
        <button 
        pButton 
        label="Voltar" 
        icon="pi pi-angle-left" 
        class="p-button-outlined p-mr-2"
        routerLink="/"
        ></button>
        <button
        pButton 
        class="float-btn-next"
        label="Próxima"
        icon="pi pi-angle-right"
        iconPos="right"
        disabled
        ></button>
    </div> -->
</p-card>
