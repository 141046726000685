import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IMaskModule } from 'angular-imask';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ImageModule } from 'primeng/image';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { ComboComponent } from './components/combo/combo.component';
import { FormFieldErrorComponent } from './components/form-field-error/form-field-error.component';
import { InputAreaComponent } from './components/input-area/input-area.component';
import { InputDateComponent } from './components/input-date/input-date.component';
import { InputLabelComponent } from './components/input-label/input-label.component';
import { InputNumberComponent } from './components/input-number/input-number.component';
import { InputTemplateComponent } from './components/input-template/input-template.component';
import { InputComponent } from './components/input/input.component';
import { TableComponent } from './components/table/table.component';
import { ValueOrTracePipe } from './pipes/value-or-trace.pipe';

registerLocaleData(localePt, 'pt');

@NgModule({
  declarations: [
    TableComponent,
    ValueOrTracePipe,
    InputComponent,
    InputTemplateComponent,
    FormFieldErrorComponent,
    ComboComponent,
    InputDateComponent,
    ValueOrTracePipe,
    InputAreaComponent,
    InputNumberComponent,
    InputLabelComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    CardModule,
    PanelModule,
    PaginatorModule,
    DropdownModule,
    CalendarModule,
    InputMaskModule,
    InputNumberModule,
    InputTextareaModule,
    InputTextModule,
    ButtonModule,
    ConfirmDialogModule,
    DynamicDialogModule,
    IMaskModule,
    TooltipModule,
    TableModule,
    CheckboxModule,
    ConfirmPopupModule,
    ImageModule,
    BlockUIModule
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    CardModule,
    PanelModule,
    PaginatorModule,
    DropdownModule,
    CalendarModule,
    InputMaskModule,
    InputNumberModule,
    InputTextareaModule,
    InputTextModule,
    ButtonModule,
    ConfirmDialogModule,
    DynamicDialogModule,
    IMaskModule,
    TooltipModule,
    TableModule,
    CheckboxModule,
    ConfirmPopupModule,
    ImageModule,
    BlockUIModule,

    //Components
    TableComponent,
    InputComponent,
    InputTemplateComponent,
    FormFieldErrorComponent,
    ComboComponent,
    InputDateComponent,
    ValueOrTracePipe,
    InputAreaComponent,
    InputNumberComponent,
    InputLabelComponent,

    //Pipes
    ValueOrTracePipe
  ]
})
export class CompartilhadoModule { }
