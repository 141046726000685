<div class="card">
    <p-toast></p-toast>
    <p-steps [model]="items" [readonly]="true"></p-steps>
</div>

<div class="stepsdemo-content">
    <p-card>
        <ng-template pTemplate="title"> Perfil de Visualização </ng-template>

        <ng-template pTemplate="header">
            <div
                class="grid grid-nogutter justify-content-end"
                [style]="{ padding: '10px' }"
            >
                <p-button
                    label="Anterior"
                    (onClick)="prevPage()"
                    icon="pi pi-angle-left"
                ></p-button>
            </div>
        </ng-template>

        <ng-template pTemplate="content">
            <div class="p-field p-col-12">
                <app-identificacao-conta
                    [configConta]="configConta"
                ></app-identificacao-conta>
            </div>
            <div class="p-field p-col-12">
                <p-fieldset
                    title="Perfis Associados"
                    legend="Perfis Associados"
                >
                    <div class="p-fluid p-formgrid p-grid p-mb-5">
                        <div class="p-field p-col-12 p-md-6">
                            <label>Perfil *</label>
                            <p-dropdown
                                [optionLabel]="getConcatenatedLabelPerfil"
                                [optionValue]="codigoPerfil"
                                showClear="true"
                                [autoDisplayFirst]="false"
                                [options]="perfilList"
                                [(ngModel)]="codigoPerfilModel"
                            ></p-dropdown>
                            <p
                                    class="erro-validacao"
                                    *ngIf="
                                    !codigoPerfilModel && submitted
                                    "
                                >
                                    Campo obrigatório
                                </p>
                        </div>
                    </div>

                    <p-fieldset
                        title="Situação e Vigência da Associação"
                        legend="Situação e Vigência da Associação"
                    >
                        <div class="p-fluid p-formgrid p-grid p-mb-5">
                            <div class="p-field p-col-12 p-md-2">
                                <label>Situação *</label>
                                <p-dropdown
                                    [disabled]="!configConta?.configContaId || 
                                    configConta.implantado === 'NAO' || 
                                    !perfilService.perfilVisualizacaoConta.perfilVisualizacaoContaId"
                                    optionLabel="valor"
                                    optionValue="chave"
                                    showClear="true"
                                    [autoDisplayFirst]="false"
                                    [options]="situacoes"
                                    [(ngModel)]="
                                        perfilService.perfilVisualizacaoConta
                                            .situacao
                                    "
                                ></p-dropdown>
                            </div>
                            <div class="p-field p-col-12 p-md-3">
                                <label title="dataInicio">Início da Vigência *</label>
                                <p-calendar
                                    #dataInicioVigencia="ngModel"
                                    dateFormat="dd/mm/yy"
                                    [showIcon]="true"
                                    placeholder="Informe periodo inicial"
                                    [(ngModel)]="
                                        perfilService.perfilVisualizacaoConta
                                            .dataInicioVigencia
                                    "
                                    [required]="submitted"
                                ></p-calendar>
                                <p
                                    class="erro-validacao"
                                    *ngIf="
                                        dataInicioVigencia.invalid && submitted
                                    "
                                >
                                    Campo obrigatório
                                </p>
                            </div>

                            <div class="p-field p-col-12 p-md-3">
                                <label title="dataFim">Fim da Vigência </label>
                                <p-calendar
                                    dateFormat="dd/mm/yy"
                                    [minDate]="perfilService.perfilVisualizacaoConta
                                    .dataInicioVigencia"
                                    [showIcon]="true"
                                    placeholder="Informe periodo inicial"
                                    [(ngModel)]="perfilService.perfilVisualizacaoConta
                                    .dataFimVigencia"
                                ></p-calendar>
                            </div>
                        </div>

                        <div
                            class="p-field p-col-12 p-md-12"
                            style="
                                margin-top: 10px;
                                margin-bottom: 10px;
                                text-align: right;
                            "
                        >
                            <button
                                *ngIf="
                                    perfilService.perfilVisualizacaoConta
                                        .perfilVisualizacaoContaId != null
                                "
                                pButton
                                type="button"
                                label="Cancelar"
                                icon="pi pi-times"
                                class="p-button-secondary p-mr-1"
                                (click)="cancelar()"
                            ></button>

                            <button
                                pButton
                                type="button"
                                [icon]="btnSaveIcone"
                                [label]="btnSaveLabel"
                                (click)="adicionaPerfis()"
                            ></button>
                        </div>

                        <p-table [value]="pagination.content">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Código</th>
                                    <th>Perfil de Visualização</th>
                                    <th>Situação</th>
                                    <th>Início da Vigência</th>
                                    <th>Fim da Vigência</th>
                                    <th>Ações</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-perfil>
                                <tr>
                                    <td>
                                        {{ perfil.perfilVisualizacaoContaId }}
                                    </td>
                                    <td>{{ perfil.codigoPerfil }}</td>
                                    <td>{{ perfil.situacao }}</td>
                                    <td>
                                        {{
                                            perfil.dataInicioVigencia
                                                | date : "dd/MM/yyyy"
                                        }}
                                    </td>
                                    <td>
                                        {{
                                            perfil.dateFimVigencia
                                                | date : "dd/MM/yyyy"
                                        }}
                                    </td>
                                    <td>
                                        <button
                                            pButton
                                            pRipple
                                            title="Editar"
                                            type="button"
                                            icon="pi pi-pencil"
                                            class="p-button-rounded p-mr-1"
                                            (click)="editar(perfil)"
                                        ></button>
                                        <button
                                            *ngIf="configConta.implantado =='SIM'"
                                            pButton
                                            pRipple
                                            title="{{
                                                perfil.situacao === 'ATIVA'
                                                    ? 'Inativar'
                                                    : 'Ativar'
                                            }}"
                                            type="button"
                                            icon="{{
                                                perfil.situacao === 'ATIVA'
                                                    ? 'pi pi-times'
                                                    : 'pi pi-check'
                                            }}"
                                            class="p-button-rounded p-button-secondary"
                                            (click)="ativaInativar(perfil)"
                                        ></button>
                                        <button
                                            *ngIf="
                                                configConta.implantado == 'NAO'
                                            "
                                            pButton
                                            pRipple
                                            title="Excluir"
                                            type="button"
                                            icon="pi pi-trash"
                                            class="p-button-rounded p-button-danger"
                                            style="margin-left: 3px"
                                            (click)="delete(perfil)"
                                        ></button>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td class="p-text-center" colspan="10">
                                        Nenhum registro encontrado
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>

                  
                        <p-paginator
                            [rows]="10"
                            [totalRecords]="pagination.totalElements"
                            (onPageChange)="onChangePerfil($event)"
                        ></p-paginator>
                    </p-fieldset>
                </p-fieldset>
            </div>
        </ng-template>

        <ng-template pTemplate="footer">
            <div class="grid grid-nogutter justify-content-end">
                <p-button
                    label="Anterior"
                    (onClick)="prevPage()"
                    icon="pi pi-angle-left"
                ></p-button>
            </div>
        </ng-template>
    </p-card>
</div>
