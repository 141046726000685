import {HttpClient, HttpHeaders} from '@angular/common/http';
import {MenuItem, Message} from 'primeng/api';

import {Component, EventEmitter, OnInit, Output, ViewChild,} from '@angular/core';
import {Router} from '@angular/router';
import {ConfigConta} from 'src/app/models/config-conta';
import {ConfigurarContaCorrenteService} from '../configurar-conta-corrente.service';
import swal from 'sweetalert2';
import {UnidadeOrganizacional} from 'src/app/models/unidade-organizacional';
import {ConfigContaUnidOrganiza} from 'src/app/models/config-conta-unid-organiza';
import {Location} from '@angular/common';
import {ConfigContaTipoCadastro} from 'src/app/models/config-conta-tipo-cadastro';
import {environment} from '../../../../environments/environment';
import {ConfigComposicaoContaComponent} from '../config-composicao-conta/config-composicao-conta.component';
import {ContaCorrenteService} from 'src/app/core/conta-corrente.service';
import {UtilsService} from 'src/app/shared/utils.service';
import {ConfigComposicaoConta} from 'src/app/models/config-composicao-conta';
import {TipoCadastro} from '../../../models/tipo-cadastro';
import {TipoCadastroEnum} from '../../../utils/enums/tipo-cadastro-enum';
import {TipoContaEnum} from '../../../utils/enums/tipo-conta-enum';
import {ConfigReceita} from '../../../models/config-receita';
import {map, pairwise, scan, startWith} from 'rxjs/operators';
import {BehaviorSubject, ReplaySubject, Subject} from 'rxjs';
import {ConfigModelo} from '../../../models/config-modelo';
import {ConfigContaBaseLegal} from '../../../models/config-conta-base-legal';


@Component({
    selector: "app-config-conta-corrente-tab-view",
    templateUrl: "./config-conta-corrente-tab-view.component.html",
    styleUrls: ["./config-conta-corrente-tab-view.component.scss"],
})
export class ConfigContaCorrenteTabViewComponent implements OnInit {
    @Output() public configContaEmit = new EventEmitter<void>();
    @ViewChild(ConfigComposicaoContaComponent, { static: false })
    @Output()
    outConfigConta = new EventEmitter();

    configComposicaoContaComponent: ConfigComposicaoContaComponent;

    msgs1: Message[];

    configConta: ConfigConta;
    tipoContaSelected: string = "TRIBUTARIA";
    origemInformacaoModels: any[];
    tributoModels: any[];
    periodicidadeModels: any[];
    tipoDataReferenciaModels: any[];
    tipoCadastroModels: TipoCadastro[] = [];
    tipoCadastroModel: any;
    naturezaContaCorrenteModels: any[];
    situacoes: any[];
    tiposConta: any[];
    implantadoOptions = [
        { key: "SIM", value: "Sim" },
        { key: "NAO", value: "Não" },
    ];

    tipoCadastrosSelecteds: any[] = [];
    tipoCadastroSelected: TipoCadastro[] = [];
    configContaTipoCadastrosList: any[] = [];

    httpOptions = {
        headers: new HttpHeaders({
            "Content-Type": "application/json",
        }),
    };
    origemId = 1;
    origemDisabled = true;
    labelOrigem = false;

    unidadeOrganizacionalModel: UnidadeOrganizacional;
    configContaUnidOrganizaModel: ConfigContaUnidOrganiza;
    configContaUnidOrganizaEdit: ConfigContaUnidOrganiza;
    configContaUnidOrganizas: ConfigContaUnidOrganiza[];
    editConfigUnid: boolean;
    blockedDocument = false;

    submitted: boolean = false;

    items: MenuItem[];
    tipoCadastroSaveRequest: ConfigContaTipoCadastro;
    novaConta: boolean;
    campoOrigem: boolean;
    validaTipocadastro: boolean = false;
    labelValidacaoOrigem: string =
        "Campo obrigatório (Escolha um valor para o campo Tributo)";
    tipoContaEnum = TipoContaEnum;

    constructor(
        private http: HttpClient,
        private configurarContaCorrenteService: ConfigurarContaCorrenteService,
        private router: Router,
        private location: Location,
        private contaCorrenteService: ContaCorrenteService,
        private utilService: UtilsService
    ) {
        this.configConta = new ConfigConta();
        const extras = this.router.getCurrentNavigation().extras;
        const configContaStorage = sessionStorage.getItem("objConta");

        /**Criação conta e retorna para edição */
        if (extras.state?.configConta || extras.state?.configContaSaveAll) {
            this.carregaObjState();
        } else if (configContaStorage !== null &&
            this.carregaObjStateNovaConta() !== "NOVA") {
            this.configConta = JSON.parse(configContaStorage) as ConfigConta;
            this.tipoCadastroModel = this.configConta.configContaTipoCadastros;
            this.converteData();
        } else {
            this.novaConta = true;
            this.initConfigConta();
        }

        if (this.configConta.tipoConta != null) {
            this.tipoContaSelected = this.configConta.tipoConta;
        }

        this.editConfigUnid = false;
        this.unidadeOrganizacionalModel = new UnidadeOrganizacional();
        this.configContaUnidOrganizaModel = new ConfigContaUnidOrganiza();
        this.configContaUnidOrganizaModel.situacao = "1";
        this.configContaUnidOrganizaEdit = new ConfigContaUnidOrganiza();
        this.tipoCadastroSaveRequest = new ConfigContaTipoCadastro();

        this.replaySub$.subscribe((val) => {
            if (this.tipoContaSelected != val.prev) {
                this.tipoContaSelected = val.prev;
            } else if (val.prev === val.active || val.active === undefined) {
                const PATTERN = val.prev;
                this.tipoContaSelected = this.tiposConta.filter(
                    (str) => str.chave != PATTERN
                )[0].chave;
            } else {
                this.tipoContaSelected = val.prev;
            }
        });
    }

    treatNextPage(): void {
        let redirect =
            this.router.getCurrentNavigation().extras?.state?.nextPageUntil;
        if (redirect) {
            this.nextPage();
        }
    }

    ngOnInit(): void {
        this.msgs1 = [];

        this.configurarContaCorrenteService
            .getTiposConta()
            .subscribe((response) => {
                this.tiposConta = response;
            });

        this.configurarContaCorrenteService
            .getTiposDatasReferencias()
            .subscribe((response) => {
                this.tipoDataReferenciaModels = response;
            });

        this.configurarContaCorrenteService
            .getPeriodicidades()
            .subscribe((response) => {
                this.periodicidadeModels = response;
            });

        this.configurarContaCorrenteService
            .getTributos()
            .subscribe((response) => {
                this.tributoModels = response;
            });

        this.configurarContaCorrenteService
            .getTiposCadastro()
            .subscribe((response) => {
                this.tipoCadastroModels = response;
            });

        this.configurarContaCorrenteService
            .getNaturezasContaCorrente()
            .subscribe((response) => {
                this.naturezaContaCorrenteModels = response;
            });

        this.items = this.contaCorrenteService.getEtapaConta();
        // this.configContaTipoCadastrosList = this.configConta.configContaTipoCadastros;
        // this.configContaTipoCadastrosList?.forEach((r) => {
        //     this.tipoCadastrosSelecteds.push(r.tipoCadastro);
        // });

        this.getEnum();
        let id;

        if (this.configConta.tributo?.tributoId) {
            id = this.configConta.tributo.tributoId;
            this.setOrigens(id);
        } else if (this.configConta.naturezaContaCorrente?.descricao) {
            id = this.configConta.naturezaContaCorrente.naturezaContaCorrenteId;
            this.origemId = 2;
            this.setOrigens(id);
        }

        this.contaCorrenteService.configConta = this.configConta;
    }

    converteData() {
        if (this.configConta?.dataInicioVigencia) {
            this.configConta.dataInicioVigencia =
                this.utilService.convertDateToEdit(
                    this.configConta.dataInicioVigencia
                );
        }
        if (this.configConta?.dataFimVigencia) {
            this.configConta.dataFimVigencia = this.utilService.convertDateToEdit(
                this.configConta.dataFimVigencia
            );
        }
    }

    getEnum() {
        this.configurarContaCorrenteService
            .getSituacoes()
            .subscribe((response) => {
                this.situacoes = response;
                this.configConta.situacao = this.situacoes[1].chave;
            });
    }

    carregaObjState() {
        let objStateConfigConta: any;
        let stateSave =
            this.router.getCurrentNavigation().extras?.state.configConta;

        let stateSaveAll =
            this.router.getCurrentNavigation().extras?.state.configContaSaveAll;

        if (stateSave !== undefined) {
            objStateConfigConta = stateSave;
        } else {
            objStateConfigConta = stateSaveAll;
        }

        this.configConta = JSON.parse(JSON.stringify(objStateConfigConta));

        setTimeout(() => {
            if (this.configConta?.tipoConta === "NAO_TRIBUTARIA") {
                this.tipoContaSelected = "NAO_TRIBUTARIA";
            }

            if (stateSaveAll) {
                this.configConta = stateSaveAll;
            }
            this.tipoCadastroModel = [];

            this.configConta.configContaTipoCadastros?.forEach((element) => {
                this.tipoCadastroModel.push(element.tipoCadastro);
            });
        }, 500);

        setTimeout(() => {
            this.convertDataEdit();
        }, 500);

    }

    carregaObjStateNovaConta() {
        return this.router.getCurrentNavigation().extras?.state?.novaConta;
    }

    convertDataEdit() {
            this.configConta.dataInicioVigencia =
                this.configurarContaCorrenteService.convertDateToEdit(
                    this.configConta.dataInicioVigencia
                );
        this.configConta.dataFimVigencia =
            this.configurarContaCorrenteService.convertDateToEdit(
                this.configConta.dataFimVigencia
            );
    }

    getAutomaticNextPage() {
        let redirect =
            this.router.getCurrentNavigation()?.extras?.state?.nextPageUntil;

        if (redirect) {
            if (this.router.url !== redirect) {
                return redirect;
            }
        }

        return null;
    }

    nextPage() {
        this.validaTipocadastro = !this.validaTipocadastro;

        if (this.tipoCadastroModel[0]?.descricao) {
            this.validaTipocadastro = false;
        }
        if (
            this.configConta.nomeResumido &&
            this.configConta.nomeCompleto &&
            this.configConta.periodicidade &&
            this.configConta.tipoDataReferencia &&
            this.configConta.situacao &&
            this.configConta.origemInformacao &&
            this.configConta.dataInicioVigencia &&
            this.tipoCadastroModel
        ) {
            const dtIni = this.utilService.convertToSaveUniversal(
                this.configConta.dataInicioVigencia
            );
            const dtFim = this.utilService.convertToSaveUniversal(
                this.configConta.dataFimVigencia
            );
            const diff = this.utilService.diffInDays(dtFim, dtIni);

            if (diff < 0) {
                swal.fire(
                    "Erro",
                    "A data final da vigência não pode ser menor que a data inicial da vigência",
                    "error"
                ).then(null);
            } else {
                //    const tipoCadastroSaveRequest: any = []
                //     this.tipoCadastroModel.forEach(element => {
                //         tipoCadastroSaveRequest.push({
                //             configContaId: 0,
                //             configContaTipoCadastroId: 0,
                //             tipoCadastroId: element.tipoCadastroId
                //         })
                //     });
                // this.configConta.configContaTipoCadastros = this.tipoCadastrosSelecteds;
                // this.configConta.tipoCadastro = this.tipoCadastroSelected
                // this.configConta.configContaTipoCadastros = this.tipoCadastroModel

                sessionStorage.setItem(
                    "objConta",
                    JSON.stringify(this.converteDataSave())
                );
                this.contaCorrenteService.configConta = this.configConta;
                this.contaCorrenteService.tipoCadastro =
                    this.converteTipoCadastroSave();
                // this.selectTipoCadastro();
                let automaticNextPage = this.getAutomaticNextPage();
                this.router
                    .navigate(["/config-composicao"], {
                        state: { nextPageUntil: automaticNextPage },
                    })
                    .then(null);
            }
        }
        this.submitted = true;

    }

    /**ObjTipo Cadastro Salvar */
    converteTipoCadastroSave() {
        const tipoCadastroSaveRequest: any = [];
        this.tipoCadastroModel.forEach((element) => {
            tipoCadastroSaveRequest.push({
                configContaId: 0,
                configContaTipoCadastroId: 0,
                tipoCadastroId: element.tipoCadastroId,
            });
        });
        return tipoCadastroSaveRequest;
    }

    /**ObjTipo Cadastro Edit */
    converteTipoCadastroEdit() {
        const tipoCadastroSaveRequestEdit: any = [];
        this.tipoCadastroModel.forEach((element) => {
            tipoCadastroSaveRequestEdit.push({
                configConta: {
                    configContaId: this.configConta.configContaId,
                },
                configContaTipoCadastroId:
                    this.verificaSeExisteConfigContaTipoCadastroId(element.tipoCadastroId),
                tipoCadastro: {
                    tipoCadastroId: element.tipoCadastroId,
                },
            });
        });
        return tipoCadastroSaveRequestEdit;
    }

    verificaSeExisteConfigContaTipoCadastroId(tipoCadastroId) {

        let valueObj: any = null;
        this.configConta.configContaTipoCadastros.forEach(element => {
            if(element.tipoCadastro.tipoCadastroId == tipoCadastroId){
                valueObj = element.configContaTipoCadastroId;
            }
        })
        return valueObj;
    }

    converteDataSave() {
        const data = JSON.parse(JSON.stringify(this.configConta));
        data.configContaTipoCadastros = this.tipoCadastroModel;
        data.tipoConta = this.tipoContaSelected;
        data.dataInicioVigencia =
            this.configurarContaCorrenteService.convertDateToSave(
                this.configConta.dataInicioVigencia
            );
        data.dataFimVigencia =
            this.configurarContaCorrenteService.convertDateToSave(
                this.configConta.dataFimVigencia
            );
        // if (this.configConta.configContaId) {
        //     data.dataInicioVigencia = this.configurarContaCorrenteService.convertDateToEdit(this.configConta.dataInicioVigencia);
        //     data.dataFimVigencia = this.configurarContaCorrenteService.convertDateToEdit(this.configConta.dataFimVigencia);
        // } else {
        //     data.dataInicioVigencia = this.configurarContaCorrenteService.convertDateToSave(this.configConta.dataInicioVigencia);
        //     data.dataFimVigencia = this.configurarContaCorrenteService.convertDateToSave(this.configConta.dataFimVigencia);
        // }

        return data;
    }

    onTipoCadastro(ev) {
        this.validaTipocadastro = !this.tipoCadastroModel;
    }

    initConfigConta(): void {
        this.configConta = new ConfigConta();
        this.configConta.tipoConta =
            this.tipoContaEnum[TipoContaEnum.TRIBUTARIA];
        this.configConta.implantado = "NAO";
        // this.configConta.tipoCadastro = new TipoCadastro();
        // this.tipoCadastroSelected.tipoCadastroId = TipoCadastroEnum.CONTRIBUINTE;
        this.tipoCadastrosSelecteds = [];
        sessionStorage.clear();
        this.contaCorrenteService.configConta = new ConfigConta();

        // this.configConta.dataInicioVigencia = "2022-11-02"

        // this.configConta = this.contaCorrenteService.getContaInformtation();
        if (this.configConta.dataInicioVigencia?.length != 10) {
            this.convertDateChild();
        }
    }

    convertDateChild() {
        this.configConta.dataInicioVigencia = this.convertDateBr(
            this.configConta?.dataInicioVigencia
        );
    }

    convertDateBr(data) {
        return this.utilService.convertToDateBr(data);
    }

    onSelectDataFimVigencia(event: any) {
        if (
            this.configConta.dataFimVigencia <
            this.configConta.dataInicioVigencia
        ) {
            swal.fire(
                "Erro Vigência",
                "A Data Fim não pode ser anterior a Data Inicio",
                "info"
            ).then((r) => {});
        }
    }

    ListarCCUnidOrganiza(configContaId: number) {
        this.http
            .get<Array<any>>(
                `${environment.baseUrl}/config-conta-unid-organiza/${configContaId}`
            )
            .subscribe((response) => {
                this.configContaUnidOrganizas = response;
            });
    }

    voltar() {
        this.router
            .navigate(["/config-conta/config-conta-list"])
            .then(() => {});
    }

    confirmDialog(msg = "Confirma a exclusão?") {
        return swal.fire({
            html: msg,
            icon: "warning",
            title: "Atenção",
            showCancelButton: true,
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
        });
    }

    replaySubSource = new ReplaySubject<any>(2);
    replaySub$ = this.replaySubSource.pipe(
        scan((acc, curr) => {
            acc.push(curr);
            return acc.slice(-2);
        }, []),
        map(([prev, active]) => ({ prev: prev, active: active }))
    );
    private active = new BehaviorSubject<any>(null); // <-- default value required
    prev: any;

    set(p: any): void {
        this.prev = this.active.value;
        this.active.next(p);
    }

    onChangeTipoConta(event) {
        const ccc = this.contaCorrenteService.configComposicaoConta; 
 
        if (
            ccc.itemCalculoNome !== null ||
            ccc.itemCalculoSigla !== null ||
            ccc.comentario !== null ||
            ccc.dataInicioVigencia !== null
        ) {
            this.confirmDialog(
                `Deseja realmente alterar o TIPO DE CONTA? Caso afirmativo, os dados das etapas Composição e Receita serão perdidos`
            ).then((result) => {
                if (result.value) {
                    this.tipoContaChanged(event);
                } else {
                    this.replaySubSource.next(this.tipoContaSelected);
                }
            });
        } else {
            this.tipoContaChanged(event);
        }
    }

    tipoContaChanged(event?) {
        this.contaCorrenteService.configComposicaoConta =
            new ConfigComposicaoConta();
        this.contaCorrenteService.configReceita = new ConfigReceita();
        this.configConta.origemInformacao = null;
        this.origemInformacaoModels = [];
        this.configConta.tributo = null;
        this.configConta.naturezaContaCorrente = null;
        this.configConta.tipoConta = event.chave;

        sessionStorage.removeItem("objComposicao");
        sessionStorage.removeItem("objReceita");
        sessionStorage.removeItem("objModelo");

        if (event.chave == "NAO_TRIBUTARIA"|| event.chave == "AMBAS") {
            this.getOrigemInformacaoByTipoOrigem(event.chave);
        }
    }

    subSource = new Subject<any>();
    sub$ = this.subSource.pipe(
        startWith(null),
        pairwise(),
        map(([prev, active]) => ({ prev: prev, active: active }))
    );

    changeTipoConta(event) {
        if (event.id == 1) {
            this.campoOrigem = true;
            this.labelOrigem = false;
            this.labelValidacaoOrigem =
                "Campo obrigatório (Escolha um valor para o campo Tributo)";
        } else if (event.id == 2) {
            this.labelValidacaoOrigem =
                "Campo obrigatório (Escolha um valor para o campo Natureza da Conta)";
            this.labelOrigem = true;
            this.campoOrigem = true;
        }
        this.configConta.tributo = null;
        this.configConta.naturezaContaCorrente = null;
        this.origemInformacaoModels = null;
        this.origemDisabled = true;
        this.configConta.origemInformacao = null;
        if (this.configConta.tipoConta === "NAO_TRIBUTARIA") {
            this.origemId = 2;
            this.origemDisabled = false;
            this.setOrigens(0);
        } else {
            this.origemId = 1;
        }
    }

    setOrigem(event) {
        const origemInformacaoIdAux = this.getOrigemInformacaoId(event);
        const origemInforNaoTributaria = 0;
        if (origemInformacaoIdAux) {
            this.origemDisabled = false;
            this.labelOrigem = false;
            this.setOrigens(origemInformacaoIdAux);
        } else if (origemInforNaoTributaria) {
            this.setOrigens(origemInforNaoTributaria);
        }
    }

    alert(value) {
        let msgAtributo;
        switch (value) {
            case "TRIBUTO":
                msgAtributo = "tributo";
                break;
            case "DATA":
                msgAtributo = "data";
                break;
            case "ORIGEM_INFOR":
                msgAtributo = "origem da informação";
                break;
        }
        if (
            this.contaCorrenteService.configComposicaoConta.itemCalculoNome ||
            this.contaCorrenteService.configComposicaoConta.itemCalculoSigla ||
            this.contaCorrenteService.configComposicaoConta
                .naturezaItemCalculoId
        ) {
            swal.fire({
                title: "Aviso",
                text: `Deseja realmente alterar o campo ${msgAtributo}? Caso afirmativo, os dados de todas as etapas seguintes serão perdidos.`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "SIM",
                cancelButtonText: "NÃO",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.contaCorrenteService.clear();
                    sessionStorage.removeItem("objComposicao");
                    sessionStorage.removeItem("objReceita");
                    sessionStorage.removeItem("objModelo");
                }
            });
        }
    }

    getOrigemInformacaoByTipoOrigem(tipoOrigem) {
        this.origemInformacaoModels = [];
        this.configurarContaCorrenteService
            .getOrigemInformacaoByTipoOrigem(tipoOrigem)
            .subscribe((response) => {
                this.origemInformacaoModels = response;
            });
    }

    setOrigens(origemInformacaoIdAux) {
        this.configurarContaCorrenteService
        .getOrigemInformacaos(this.origemId, origemInformacaoIdAux)
        .subscribe((response) => {
            this.origemInformacaoModels = response;
        });
        if(this.configConta.tipoConta === "AMBAS") {
            this.getOrigemInformacaoByTipoOrigem(this.configConta.tipoConta)
        }
    }

    getOrigemInformacaoId(obj) {
        if (obj?.value?.tributoId) {
            this.labelOrigem = true;
            return obj.value.tributoId;
        } else {
            return null;
        }
    }

    clickDisabledOrigem() {
        if (
            !this.configConta.origemInformacao &&
            this.configConta.tipoConta == "TRIBUTARIA"
        ) {
            this.labelOrigem = true;
        } else if (this.configConta.origemInformacao) {
            this.labelOrigem = false;
        }
    }

    salvar(): void {
        const data = JSON.parse(JSON.stringify(this.configConta));
        data.configContaTipoCadastros = this.converteTipoCadastroEdit();
        data.dataInicioVigencia =
            this.configurarContaCorrenteService.convertDateToSave(
                this.configConta.dataInicioVigencia
            );
        data.dataFimVigencia =
            this.configurarContaCorrenteService.convertDateToSave(
                this.configConta.dataFimVigencia
            );
        this.blockedDocument = true;
        this.msgs1 = [];

        this.http
            .post<ConfigConta>(
                `${environment.baseUrl}/config-conta`,
                JSON.stringify(data),
                this.httpOptions
            )
            .subscribe(
                (response) => {
                    this.blockedDocument = false;
                    this.configConta.configContaId = response.configContaId;

                    // this.outConfigConta.emit(this.configConta);
                    swal.fire(
                        "Sucesso",
                        "Atualizado com sucesso",
                        "success"
                    ).then((r) => {
                        this.blockedDocument = false;
                        this.getConsultarConta(this.configConta.configContaId);
                    });
                },
                (error) => {
                    this.blockedDocument = false;
                    if (error.status === 500) {
                        swal.fire(
                            error.statusText,
                            "Ocorreu um erro interno",
                            "error"
                        ).then((r) => {});
                    } else if (error.status === 412) {
                        swal.fire(
                            "Erro de Validação",
                            error.error.message,
                            "error"
                        ).then((r) => {});
                    } else if (error.status === 400) {
                        const errors = error.error.errors;
                        this.msgs1 = [];
                        errors.forEach((e: { message: any }) => {
                            this.msgs1.push({
                                severity: "error",
                                summary: "Erro",
                                detail: e.message,
                            });
                        });
                    }
                }
            );
    }

    getConsultarConta(configContaId) {

        // this.contaCorrenteService.getConfigContaId(configContaId);

        this.configurarContaCorrenteService.getConsultarConta(configContaId).subscribe(
            (response) => {
                this.tipoCadastroModel = [];
                /**Reseta values tipo cadastro */
                this.configConta = response;

                this.configConta.configContaTipoCadastros?.forEach((element) => {
                    this.tipoCadastroModel.push(element.tipoCadastro);
                });

                if(this.configConta.dataInicioVigencia){
                    this.configConta.dataInicioVigencia = this.convertDateBr(
                        this.configConta?.dataInicioVigencia
                    );
                }

                if(this.configConta.dataFimVigencia){
                    this.configConta.dataFimVigencia = this.convertDateBr(
                        this.configConta?.dataFimVigencia
                    );
                }

            }
        );
    }

    saveConfigContaTipoCadastro(configContaId): void {
        const data = [];
        // this.tipoCadastrosSelecteds.forEach((r) => {
        //     data.push({
        //         configContaTipoCadastroId: null,
        //         configContaId,
        //         tipoCadastroId: r.tipoCadastroId,
        //     });
        // });

        this.blockedDocument = true;
        this.http
            .post<ConfigContaTipoCadastro>(
                `${environment.baseUrl}/config-conta-tipo-cadastro/save-all`,
                JSON.stringify(data),
                this.httpOptions
            )
            .subscribe(
                (response) => {
                    this.blockedDocument = false;
                },
                (error) => {
                    this.blockedDocument = false;
                }
            );
    }

    onChangeInicioVigencia(event) {
        this.confirmDialog(
            "Deseja realmente alterar o Início da Vigência? Caso afirmativo, os dados de todas as etapas seguintes serão perdidos"
        ).then((result) => {
            if (result.value) {
                this.contaCorrenteService.configComposicaoConta =
                    new ConfigComposicaoConta();
                this.contaCorrenteService.configReceita = new ConfigReceita();
                this.contaCorrenteService.configModelo = new ConfigModelo();
                this.contaCorrenteService.configContaBaseLegal =
                    new ConfigContaBaseLegal();
                this.contaCorrenteService.configContaUnidOrganiza =
                    new ConfigContaUnidOrganiza();
            }
        });
    }

    // selectTipoCadastro(configContaId?) {
    //     this.tipoCadastrosSelecteds = this.configConta.configContaTipoCadastros;
    //     const data = [];
    //     this.tipoCadastrosSelecteds.forEach((r) => {
    //         data.push({
    //             configContaTipoCadastroId: null,
    //             configContaId: null,
    //             tipoCadastroId: r.tipoCadastroId,
    //         });
    //     });
    //     this.contaCorrenteService
    //         .configContaSaveAllRequest
    //         .tipoCadastroSaveRequests = data;
    //     return data;
    // }

    origemInformacaoDisabled(): boolean {
        const tipoConta = this.tipoContaSelected;
        if (this.configConta.implantado == "SIM") {
            return true;
        } else if (
            tipoConta === this.tipoContaEnum[this.tipoContaEnum.NAO_TRIBUTARIA] || tipoConta === this.tipoContaEnum[this.tipoContaEnum.AMBAS]
        ) {
            return false;
        } else if (
            tipoConta === this.tipoContaEnum[this.tipoContaEnum.TRIBUTARIA] &&
            this.configConta.tributo !== null
        ) {
            return false;
        }
        return true;
    }

    ngOnDestroy(): void {
        // this.replaySubSource.next();
        // this.replaySubSource.complete();
    }
}
