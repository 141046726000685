import {Component, Input, OnInit} from '@angular/core';
import {ConfigContaBaseLegal} from '../../../models/config-conta-base-legal';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ConfigConta} from '../../../models/config-conta';
import {ConfigContaBaseLegalTabViewService} from './config-conta-base-legal-tab-view.service';
import {BaseLegalSearchModalService} from '../../base-legal/base-legal-search-modal/base-legal-search-modal.service';
import {UtilsService} from '../../../shared/utils.service';
import {BaseLegal} from '../../../models/base-legal';
import {environment} from '../../../../environments/environment';
import {MenuItem, Message} from 'primeng/api';
import swal from 'sweetalert2';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { ContaCorrenteService } from 'src/app/core/conta-corrente.service';
import { Pagination } from 'src/app/shared/pagination/pagination';
import { AlertsUtil } from 'src/app/utils/alerts/alerts.util';

@Component({
    selector: 'app-config-conta-base-legal-tab-view',
    templateUrl: './config-conta-base-legal-tab-view.component.html',
    styleUrls: ['./config-conta-base-legal-tab-view.component.scss'],
    providers: [ConfigContaBaseLegalTabViewService, BaseLegalSearchModalService]
})
export class ConfigContaBaseLegalTabViewComponent implements OnInit {
    msgs1: Message[];
    @Input() configConta;
    blockedDocument = false;
    pagination: Pagination<any>;

    situacaoCCBaseLegals: any[];
    configContaBaseLegals: ConfigContaBaseLegal[];
    editConfigContaBL: boolean;
    configContaBaseLegalModelEdit: ConfigContaBaseLegal;
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };
    items: MenuItem[];
    objBaseLegal: any;
    submitted: boolean;
    dtInicioConverte: string;
    dtiniteste: string;

    constructor(
        private http: HttpClient,
        public configContaBaseLegalService: ConfigContaBaseLegalTabViewService,
        public baseLegalSearchModalService: BaseLegalSearchModalService,
        public utils: UtilsService,
        private router: Router,
        private contaCorrenteService: ContaCorrenteService,
        private utilService: UtilsService,
        private alertsUtil: AlertsUtil
    ) {
        this.configContaBaseLegalModelEdit = new ConfigContaBaseLegal();
        this.editConfigContaBL = false;

    }

    ngOnInit(): void {
        this.pagination = new Pagination<any>();
        this.configContaBaseLegalService.getSituacoes().subscribe(r => {
            this.situacaoCCBaseLegals = r;
        });
        this.items = this.contaCorrenteService.getEtapaConta();
        this.configConta = this.contaCorrenteService.getContaInformtation();
        if (this.configConta.configContaId) {
            this.pesquisar(this.configConta.configContaId);
        }
        if(this.contaCorrenteService.getValdiaAba()){
            this.populaCampos();
        }
        const objContaSession = sessionStorage.getItem('objConta');
        if (objContaSession) {
            const objConta = JSON.parse(objContaSession);
            this.configConta = objConta;
        }
    }

    onPageChange(e) {
        this.pagination.pageable.pageNumber = e.page;
        this.pesquisar(this.configConta.configContaId);
    }

    pesquisar(configContaId: number) {
        let params = { configContaId: configContaId};
        this.blockedDocument = true;
        this.configContaBaseLegalService.getConfigContaBaseLegal(params, this.pagination).subscribe(
            (resp: any) => {
                this.blockedDocument = false;
                this.pagination = resp;
                this.configContaBaseLegals = resp.content;
            },
            (error) => {
                this.alertsUtil.handleError(error);
            }
        );
    }

    nextPage() {
        this.configContaBaseLegalService.configContaBaseLegal.configConta.configContaId = this.configConta.configContaId;
        this.contaCorrenteService.configContaBaseLegal = this.configContaBaseLegalService.configContaBaseLegal;
        if(
            this.configContaBaseLegalService.configContaBaseLegal.dataInicioVigencia &&
            this.configContaBaseLegalService.configContaBaseLegal.situacao || this.configConta.configContaId
            ){
            this.contaCorrenteService.validaAba = true;
            this.router.navigate(['/unid-organiza']);
        }
        this.submitted = true;
    }

    prevPage() {
        this.router.navigate(['/config-receita']);
    }

    populaCampos(){
        this.configContaBaseLegalService.configContaBaseLegal = this.contaCorrenteService.configContaBaseLegal
    }

    showBasicDialog() {
        this.baseLegalSearchModalService.displayMaximizable = true;
    }

    addBaseLegal(): void {
        this.submitted= true;
        this.configContaBaseLegalService.configContaBaseLegal.configConta.configContaId = this.configConta.configContaId;
        this.configContaBaseLegalService.configContaBaseLegal.dataInicioVigencia = this.utils.convertDateToSave(this.configContaBaseLegalService.configContaBaseLegal.dataInicioVigencia);
        const data = JSON.parse(JSON.stringify(this.configContaBaseLegalService.configContaBaseLegal));
        if(data.dataInicioVigencia === null || data.situacao === null || data.baseLegal.baseLegalId === null){
            if(data.baseLegal.baseLegalId == null){
                this.alertsUtil.warning(`Não há nenhuma base lega selecionada`);
            }
            return;
        }
        
        // data.dataInicioVigencia = this.utils.convertDateToSave(data.dataInicioVigencia);
        data.baseLegal.tblVigenciaInicio = this.utils.convertDateUsToSave(data.baseLegal.tblVigenciaInicio);
        data.dataFimVigencia = this.utils.convertDateUsToSave(data.dataFimVigencia);
        
        this.blockedDocument = true;
        this.http
            .post<ConfigContaBaseLegal>(
                `${environment.baseUrl}/config-conta-base-legal`,
                JSON.stringify(data),
                this.httpOptions
            )
            .subscribe(response => {
                this.submitted = false;
                this.blockedDocument = false;
                this.resetValues()
                swal.fire('Sucesso', 'Salvo com sucesso', 'success').then(r => {
                    this.pesquisar(this.configContaBaseLegalService.configContaBaseLegal.configConta.configContaId);
                });
            }, error => {
                this.reseteDatas();
                this.blockedDocument = false;
                this.alertsUtil.handleError(error);
                this.scrollToTop();
            });
    }

    reseteDatas() {
        if(this.configContaBaseLegalService.configContaBaseLegal.dataInicioVigencia){
            this.configContaBaseLegalService.configContaBaseLegal.dataInicioVigencia = this.convertDateBr(this.configContaBaseLegalService.configContaBaseLegal.dataInicioVigencia);
        }
        if(this.configContaBaseLegalService.configContaBaseLegal.dataFimVigencia){
            this.configContaBaseLegalService.configContaBaseLegal.dataFimVigencia = this.convertDateBr(this.configContaBaseLegalService.configContaBaseLegal.dataInicioVigencia);
        }
    }

    resetValues() {
        this.configContaBaseLegalService.configContaBaseLegal.baseLegal = new BaseLegal();
        this.configContaBaseLegalService.configContaBaseLegal.configContaBaseLegalId = null;
        this.configContaBaseLegalService.configContaBaseLegal.dataInicioVigencia = null;
        this.configContaBaseLegalService.configContaBaseLegal.dataFimVigencia = null;
    }

    onSelectDataInicioVigencia(event) {
        let dataVigenciaConta = moment(this.configConta.dataInicioVigencia, 'DD/MM/YYYY').format("YYYY-MM-DD");
        let dataInicio = moment(event).format("YYYY-MM-DD");
        if(dataInicio < dataVigenciaConta){
            swal.fire({
                title: 'A data não pode ser menor que a data da vigência da conta',
                icon: 'warning',
                showCancelButton: true,
            }).then((result) => {
                this.configContaBaseLegalService.configContaBaseLegal.dataInicioVigencia = null;
            });
        }
        this.configContaBaseLegalService.configContaBaseLegal.dataInicioVigencia = moment(event).format('DD/MM/YYYY') || null;
    }

    onChangeDataInicioVigencia(event) {
        this.configContaBaseLegalService.configContaBaseLegal.dataInicioVigencia = event.target.value || null;
    }

    onSelectDataFimVigencia(event) {
        this.configContaBaseLegalService.configContaBaseLegal.dataFimVigencia = moment(event).format('DD/MM/YYYY') || null;
    }

    onChangeDataFimVigencia(event) {
        this.configContaBaseLegalService.configContaBaseLegal.dataFimVigencia = event.target.value || null;
    }

    convertDateBr(data) {
        return this.utilService.convertToDateBr(data);
    }

    edit(configContaBaseLegal: ConfigContaBaseLegal) {
        const data = JSON.parse(JSON.stringify(configContaBaseLegal));
        data.dataInicioVigencia = this.utils.convertDateToEdit(data.dataInicioVigencia);
        data.dataFimVigencia = this.utils.convertDateToEdit(data.dataFimVigencia);
        this.configContaBaseLegalService.configContaBaseLegal = JSON.parse(JSON.stringify(data));
    }

    ativarInativar(configContaBaseLegal: ConfigContaBaseLegal) {
            let msgSituacao = configContaBaseLegal.situacao === "ATIVA" ? "inativar" : "ativar"
            swal.fire({
                title: 'Confirmação',
                text: `Deseja ${msgSituacao} base legal da conta corrente?`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não'
            }).then((result) => {
                if (result.isConfirmed) {
                    let msg = configContaBaseLegal.situacao === "ATIVA" ? "Inativado" : "Ativado"
                    this.configContaBaseLegalService.ativaInativaBaseLegal(configContaBaseLegal.configContaBaseLegalId)
                        .subscribe(r => {
                            swal.fire('Sucesso', `${msg} com sucesso`, 'success').then( r => {
                                this.pesquisar(configContaBaseLegal.configConta.configContaId);
                            });
                        });
                }
            });
    }

    cancel() {
        this.configContaBaseLegalService.configContaBaseLegal = new ConfigContaBaseLegal();
        this.configContaBaseLegalService.configContaBaseLegal.baseLegal = new BaseLegal();
        this.configContaBaseLegalService.configContaBaseLegal.configConta = new ConfigConta();
        this.configContaBaseLegalService.configContaBaseLegal.situacao = 'ATIVA';
    }

    scrollToTop() {
        (function smoothscroll() {
            const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
            if (currentScroll > 0) {
                window.requestAnimationFrame(smoothscroll);
                window.scrollTo(0, currentScroll - (currentScroll / 8));
            }
        })();
    }

    delete(item) {
        swal.fire({
            title: "Confirmação",
            text: `Deseja excluir a base legal ${item?.baseLegal?.tblDescBaseLegal}.`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "SIM",
            cancelButtonText: "NÃO"
        }).then((result) => {
            if (result.isConfirmed) {
                this.configContaBaseLegalService
                    .deleteBaseLegal(item.configContaBaseLegalId)
                    .subscribe((resp) => {
                        swal.fire("Sucesso", "Excluído com sucesso", "success").then(
                            () => {
                                this.pesquisar(item.configConta.configContaId);
                            }
                        );
                    });
            }
        });
    }

    changeBaseLegal(evt){
        this.configContaBaseLegalService.configContaBaseLegal.baseLegal.tblVigenciaInicio = this.utils.convertToDateBr(this.configContaBaseLegalService.configContaBaseLegal.baseLegal.tblVigenciaInicio);
        let tblVigenciaInicio = this.configContaBaseLegalService.configContaBaseLegal.baseLegal.tblVigenciaInicio
        tblVigenciaInicio != null ? this.utils.convertToDateBr(this.configContaBaseLegalService.configContaBaseLegal.baseLegal.tblVigenciaInicio) : null;
    }

}
