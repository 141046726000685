import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { ModeloDeEstadoConta } from 'src/app/models/modelo-estado-conta';
import { ModeloDeEstadoContaCorrente } from 'src/app/models/modelo-estado-conta-corrente';
import { Pagination } from 'src/app/shared/pagination/pagination';
import { UtilsService } from "src/app/shared/utils.service";
import { AlertsUtil } from 'src/app/utils/alerts/alerts.util';
import { EstadoContaListService } from './config-estado-conta-list.service';


@Component({
    selector: "app-estado-conta-list",
    templateUrl: "./config-estado-conta-list.component.html",
    styleUrls: ["./config-estado-conta-list.component.scss"],
    providers: [EstadoContaListService],
})
export class ConfigEstadoContaListComponent implements OnInit {
    pagination: Pagination<any>;
    blockedDocument: boolean;
    checkboxDisabled = false;
    situacoes: any[];
    situacao: any;
    perfisVisualizacao: any[] = [];
    idEstadoPerfil: any;
    listaEventoEstadoPerfil: any[] = [];
    dataInicioVigencia: any;
    submitted: boolean;
    objSave: {};

    /**OLD */
    modeloDeEstadoConta: ModeloDeEstadoConta = {
      estadoContaId: 0,
      descricao: '',
      situacao: 'ATIVA',
      dataCadastro: new Date(),
      efeitoSobreConta: null,
      atualizaSaldoConta: 'NAO',
      permiteDare: 'NAO',
      estadoPerfilSaveRequests:[]
    }

    modeloDeEstadoContaCorrente: ModeloDeEstadoContaCorrente = {
        estadoContaId: 0,
        descricao: '',
        dataCadastro: new Date(),
      }

    estadoContaId: any;
    listPerfilSave: any[];

    private get estadoContaListService(): EstadoContaListService {
        return this._estadoContaListService;
    }
    private set estadoContaListService(value: EstadoContaListService) {
        this._estadoContaListService = value;
    }
    constructor(
        private primengConfig: PrimeNGConfig,
        private _estadoContaListService: EstadoContaListService,
        private util: UtilsService,
        private utilService: UtilsService,
        private activatedRouter: ActivatedRoute,
        private alertUtil: AlertsUtil,
        private router: Router
    ) {

    }

    ListaEfeitoSobreContaCorrente: any[];

    ngOnInit(): void {
        this.pagination = new Pagination<any>();
        this.blockedDocument = false;

        this.activatedRouter.params.subscribe((eventoId) => {
            this.estadoContaId = eventoId.id ? eventoId.id : null;
            if(this.estadoContaId) this.getEstadoConta(this.estadoContaId);
            //this.getEstados();
        });

        // this.estadoContaListService
        //     .getEfeitoSobreContaCorrente()
        //     .subscribe((response) => {
        //         this.blockedDocument = true;
        //         this.ListaEfeitoSobreContaCorrente = response;
        //         this.blockedDocument = false;
        //     });
        // this.estadoContaListService.getSituacao().subscribe((response) => {
        //     this.situacoes = response;
        // });
        // this.estadoContaListService.getPerfis().subscribe(
        //     (response) => {
        //         this.perfisVisualizacao = response;
        //     },
        //     (error) => {
        //         this.alertUtil.handleError(error);
        //     }
        // );
    }

    getEstadoConta(estadoContaId){
        this.estadoContaListService.getEstadoContaPorId(estadoContaId).subscribe((resp: any) =>{
            if(resp){
                this.modeloDeEstadoContaCorrente = {
                    estadoContaId: resp.estadoContaId,
                    descricao: resp.descricao,
                    dataCadastro: this.utilService.convertDateToEdit(resp.dataCadastro)
                }
            }
        }, error =>{
            this.alertUtil.handleError(error)
        })
    }

    getEstados() {
        if(this.estadoContaId){
            this.getEstadoPerfil();
            this.getListaEstadoPerfil();
        }
    }

    getEstadoPerfil(){
        const objParams = {
            estadoContaId: this.estadoContaId
        };
        this.estadoContaListService.getEstadoConta(objParams).subscribe((resp: any) =>{
            this.populaCampos(resp.content[0]);
            this.onChangeEfeitoSobreContaCorrente(resp.content[0].efeitoSobreConta);

        }, error =>{
            this.alertUtil.handleError(error)
        })
    }

    populaCampos(getEstados){
        let listaEventos;
        this.modeloDeEstadoConta = getEstados;
        this.modeloDeEstadoConta.dataCadastro =  this.utilService.convertDateToEdit(getEstados.dataCadastro);
        this.modeloDeEstadoConta.estadoPerfilSaveRequests = [];
        this.listaEventoEstadoPerfil.forEach(element => {
            listaEventos = {...element,...{estadoConta: {estadoContaId: this.modeloDeEstadoConta.estadoContaId}}};
        });
    }

    getListaEstadoPerfil() {
        this.listaEventoEstadoPerfil = [];
        if(this.estadoContaId) {
            this.estadoContaListService
            .getListaEstadoPerfil(this.estadoContaId)
            .subscribe((response) => {
                if(response.length > 0){
                    response.forEach(element => {
                        this.listaEventoEstadoPerfil.push({...element,...{estadoConta: {estadoContaId: this.estadoContaId}}});
                    });
                    // this.listaEventoEstadoPerfil = response;
                }
            },erro => {
                this.alertUtil.handleError(erro);
            });
        }
    }

    onChangeEfeitoSobreContaCorrente(item: string) {
        switch (item) {
            case "DEBITO":
                this.checkboxDisabled = true;
                this.modeloDeEstadoConta.atualizaSaldoConta = 'SIM';
                break;
            case "EXTINCAO":
            case "EXCLUSAO":
                this.checkboxDisabled = true
                this.modeloDeEstadoConta.atualizaSaldoConta = 'NAO';
                break;
            case "SUSPENSAO":
                this.checkboxDisabled = false
                break;
            default:
                break;
        }
    }

    onPageChange(e) {
        this.pagination.pageable.pageNumber = e.page;
        this.estadoContaListService.getListaEventosEstadoPerfil();
    }

    convertDateBr(data) {
        return this.utilService.convertToDateBr(data);
    }

    adicionaPerfilVizualizacao() {
        let codigoPerfil = this.idEstadoPerfil;
        let descPerfil = this.perfisVisualizacao.find(aux=> aux.codigoPerfil == this.idEstadoPerfil).descricao;
            if (
                this.listaEventoEstadoPerfil.find(
                    (aux) => aux.codigoPerfil === codigoPerfil
                )
            ) {
                this.alertUtil.warning(
                    `O perfil com código ${codigoPerfil}, já existe na lista!`
                );
                return;

        } else {
            /**exibe lista perfil*/

            this.listaEventoEstadoPerfil = [
                ...this.listaEventoEstadoPerfil,
                {
                    estadoPerfilId: 0,
                    // estadoContaId: this.modeloDeEstadoConta.estadoContaId,
                    estadoConta: {estadoContaId: this.modeloDeEstadoConta.estadoContaId},
                    codigoPerfil: codigoPerfil,
                    situacao: this.modeloDeEstadoConta.situacao,
                    dataInicioAssociacao: this.util.convertDateUsToSave(new Date()),
                    dataFimAssociacao: '',
                    descricao: descPerfil,
                },
            ];


            // /**save lista perfil */
            // this.listPerfilSave = [
            //     ...this.listPerfilSave,
            //     {
            //         estadoPerfilId: 0,
            //         estadoConta: {estadoContaId: this.modeloDeEstadoConta.estadoContaId},
            //         codigoPerfil: codigoPerfil,
            //         situacao: this.modeloDeEstadoConta.situacao,
            //         dataInicioAssociacao: this.util.convertDateUsToSave(new Date()),
            //         dataFimAssociacao: ''
            //     }
            // ];
        }

    }

    delete(i, item) {
        if (item.estadoPerfilId) {
            this.alertUtil.confirm(`Deseja excluir o perfil ${item.codigoPerfil}`).then((result) => {
                if (result.isConfirmed) {
                    this.estadoContaListService.deletePerfil(item.estadoPerfilId).subscribe(
                        resp => {
                            this.alertUtil.saveSuccess(`Perfil ${item.codigoPerfil} Removido com sucesso`).then(
                                () => {
                                    this.listaEventoEstadoPerfil.splice(i, 1);
                                }
                            );
                        }
                    ), error =>{
                        this.alertUtil.handleError(error)
                    }
                }
            });
        } else {
            this.listaEventoEstadoPerfil.splice(i, 1);
        }
    }

    salvar(){
      if(this.validaSalvar()){
        // this.modeloDeEstadoConta.estadoPerfilSaveRequests = this.listaEventoEstadoPerfil;
        const data = JSON.parse(JSON.stringify(this.modeloDeEstadoContaCorrente));
        data.dataCadastro = this.estadoContaId ? this.util.convertDateToSave(data.dataCadastro) : this.util.convertDateUsToSave(data.dataCadastro);
        const obs = this.estadoContaId ? this.estadoContaListService.patchEstadoConta(data) : this.estadoContaListService.postEstadoConta(data)
        obs.subscribe(
            resp => {
                const id = this.estadoContaId ?  this.estadoContaId : resp?.estadoContaId;
                this.alertUtil.saveSuccess(`Modelo de Estado da Conta ${id}, salvo com sucesso`).then(
                    () => {
                        this.router.navigateByUrl('estado-conta/estado-conta-list');
                    }
                )
            }, erro => {
                this.alertUtil.handleError(erro);
            }
        )
      }
    }

    validaSalvar(){
      this.submitted = true;

      if(this.modeloDeEstadoContaCorrente.descricao){
        return true;
      }
      return false;
    }

    ativarInativar(perfil){
        let msgConfirm = perfil.situacao === 'ATIVA' ? 'inativar' : 'ativar'
        let msgAtivaInativa = perfil.situacao === 'ATIVA' ? 'inativado' : 'ativado';
        this.alertUtil.warning(`Deseja ${msgConfirm} o perfil ${perfil.codigoPerfil}?`).then(
            (result) => {
                if(result.isConfirmed){
                    this.estadoContaListService.putAtivaInativa(perfil.estadoPerfilId).subscribe(
                        resp => {
                            this.alertUtil.saveSuccess(`Perfil ${msgAtivaInativa} com sucesso`);
                            this.getListaEstadoPerfil();
                        }, erro => {
                            this.alertUtil.handleError(erro);
                        }
                    )
                }
            }
        );
    }

    voltar(){
        this.router.navigateByUrl('/estado-conta/estado-conta-list')
    }

}
