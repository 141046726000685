<p-card [header]="tituloPagina">
    <form [formGroup]="formGroupTransacao">
        <div class="p-card-content">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-2 p-md-2">
                    <label>Código</label>
                    <input
                        type="text"
                        disabled
                        pInputText=""
                        formControlName="transacaoId"
                    />
                </div>
                <div class="p-field p-col-10 p-md-10">
                    <label>Nome Completo *</label>
                    <input
                        type="text"
                        pInputText=""
                        formControlName="nomeCompleto"
                        maxlength="249"
                    />
                    <p
                        class="erro-validacao"
                        *ngIf="
                            (formGroupTransacao.controls.nomeCompleto.touched ||
                                submitted) &&
                            formGroupTransacao.controls.nomeCompleto.errors
                                ?.required
                        "
                    >
                        Campo obrigatório
                    </p>
                </div>
                <div class="p-field p-col-8">
                    <label>Nome Resumido *</label>
                    <input
                        type="text"
                        pInputText=""
                        formControlName="nomeResumido"
                        maxlength="49"
                    />
                    <p
                        class="erro-validacao"
                        *ngIf="
                            (formGroupTransacao.controls.nomeResumido.touched ||
                                submitted) &&
                            formGroupTransacao.controls.nomeResumido.errors
                                ?.required
                        "
                    >
                        Campo obrigatório
                    </p>
                </div>
                <div class="p-field p-col-4">
                    <label>Tipo de Processamento *</label>
                    <p-dropdown
                        optionLabel="valor"
                        formControlName="tipoTransacao"
                        [autoDisplayFirst]="false"
                        [options]="tipoTransacaoEnum"
                    >
                    </p-dropdown>
                    <p
                        class="erro-validacao"
                        *ngIf="
                            (formGroupTransacao.controls.tipoTransacao
                                .touched ||
                                submitted) &&
                            formGroupTransacao.controls.tipoTransacao.errors
                                ?.required
                        "
                    >
                        Campo obrigatório
                    </p>
                </div>
                <div
                    *ngIf="!desabilitaCheckboxGeraTransacao"
                    class="p-field p-col-12"
                >
                    <p-checkbox
                        binary="false"
                        trueValue="1"
                        falseValue="0"
                        formControlName="geraTransacaoInicial"
                        label="Gera Transação Inicial?"
                        (onChange)="onChangeGeraTransacaoInicial($event)"
                    >
                    </p-checkbox>
                </div>
                <div class="p-field p-col-6">
                    <label>Operação *</label>
                    <p-dropdown
                        formControlName="operacao"
                        [options]="operacaoTransacaoEnum"
                        optionLabel="valor"
                        optionValue="chave"
                        [disabled]="desabilitaDropdownOperacao"
                    >
                    </p-dropdown>
                </div>
                <div class="p-field p-col-3">
                    <label>Situação *</label>
                    <p-dropdown
                        formControlName="situacao"
                        [options]="situacaoTransacaoEnum"
                        [optionLabel]="'valor'"
                        [optionValue]="'chave'"
                        [disabled]="desabilitaDropdownSituacao"
                    >
                    </p-dropdown>
                </div>
                <div class="p-field p-col-3">
                    <label>Data de Criação *</label>
                    <p-calendar
                        formControlName="dataCriacao"
                        dateFormat="dd/mm/yy"
                        name="dataCriacao"
                        disabled="true"
                    >
                    </p-calendar>
                </div>

                <div class="p-field p-col-12">
                    <label>Observação </label>
                    <textarea
                        type="text"
                        pInputText=""
                        formControlName="observacao"
                        name="observacao"
                        style="resize: none"
                    ></textarea>
                </div>
            </div>
        </div>

        <!--TABS-->
        <p-tabView
            styleClass="tabview-custom"
            *ngIf="
                habilitaReferencias ||
                habilitaRegraCalculo ||
                habilitaReducaoMulta
            "
        >
            <p-tabPanel *ngIf="habilitaReferencias">
                <ng-template pTemplate="header">
                    <span>Referências</span>
                    <em
                        *ngIf="!formReferenciasValido && submitted"
                        class="pi pi-info-circle"
                        style="color: #f00; margin-left: 5px"
                    ></em>
                </ng-template>
                <app-referencias
                    #referencias
                    *ngIf="habilitaReferencias"
                    (validaPreenchimentoForm)="
                        verificaFormReferenciasEstaValido($event)
                    "
                    (changeForm)="changeFormReferencias($event)"
                    [transacaoRegraReducaoRequest]="
                        itemTransacaoReferenciaRequest
                    "
                >
                </app-referencias>
            </p-tabPanel>
            <p-tabPanel *ngIf="habilitaRegraCalculo || habilitaReducaoMulta">
                <ng-template pTemplate="header">
                    <span>{{
                        habilitaRegraCalculo
                            ? "Regra de Cálculo"
                            : "Regra de Redução"
                    }}</span>
                    <em
                        *ngIf="
                            (formGroupTransacao.controls.baseIncidencias
                                .touched &&
                                formGroupTransacao.controls.baseIncidencias
                                    .errors?.required) ||
                            (!formGroupRegraCalculo.valid && submitted)
                        "
                        class="pi pi-info-circle"
                        style="color: #f00; margin-left: 5px"
                    ></em>
                </ng-template>

                <div [formGroup]="formGroupRegraCalculo">
                    <p-fieldset
                        legend="Base de Incidência *"
                        *ngIf="habilitaBaseIncidencia"
                        [toggleable]="true"
                        class="card-item"
                    >
                        <div
                            *ngFor="
                                let baseIncidencias of naturezaItemCalculoEnum
                            "
                            style="margin: 2px"
                        >
                            <p-checkbox
                                name="baseIncidencia"
                                [label]="baseIncidencias.descricao"
                                [value]="baseIncidencias.naturezaItemCalculoId"
                                [formControl]="baseIncidenciasSelected"
                            >
                            </p-checkbox>
                        </div>
                        <p
                            class="erro-validacao"
                            *ngIf="
                                (formGroupTransacao.controls.baseIncidencias
                                    .touched ||
                                    submitted) &&
                                formGroupTransacao.controls.baseIncidencias
                                    .errors?.required
                            "
                        >
                            Campo obrigatório
                        </p>
                    </p-fieldset>
                    <p-fieldset
                        legend="Momento da Incidência *"
                        *ngIf="habilitaRegraCalculo"
                        [toggleable]="true"
                        class="card-item"
                    >
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-4">
                                <label>Regra *</label>
                                <p-dropdown
                                    optionLabel="descricao"
                                    [options]="regraVencimentoList"
                                    optionValue="regraVencimentoId"
                                    formControlName="regraVencimento"
                                    placeholder="Regra de Vencimento"
                                    (onChange)="onChangeRegraVencimento($event)"
                                >
                                </p-dropdown>
                                <p
                                    class="erro-validacao"
                                    *ngIf="
                                        (regraCalculoControls.regraVencimento
                                            .touched ||
                                            submitted) &&
                                        regraCalculoControls.regraVencimento
                                            .errors?.required
                                    "
                                >
                                    Campo obrigatório
                                </p>
                            </div>
                            <div class="p-field p-col-4">
                                <label>Data Referência *</label>
                                <p-dropdown
                                    optionLabel="descricao"
                                    [options]="dataReferenciaList"
                                    optionValue="tipoDataReferenciaId"
                                    formControlName="tipoDataReferencia"
                                    placeholder="Data de Referência"
                                >
                                </p-dropdown>
                                <p
                                    class="erro-validacao"
                                    *ngIf="
                                        (regraCalculoControls.tipoDataReferencia
                                            .touched ||
                                            submitted) &&
                                        regraCalculoControls.tipoDataReferencia
                                            .errors?.required
                                    "
                                >
                                    Campo obrigatório
                                </p>
                            </div>
                            <div class="p-field p-col-4">
                                <label>Natureza</label>
                                <div [formGroup]="formGroupTransacao">
                                    <p-dropdown
                                        optionLabel="descricao"
                                        [options]="naturezaItemCalculoEnum"
                                        formControlName="naturezaItemCalculo"
                                        placeholder="Natureza Item Cálculo"
                                    >
                                    </p-dropdown>
                                </div>
                            </div>
                        </div>
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-4" *ngIf="showDia">
                                <label>Dia *</label>
                                <p-inputNumber
                                    formControlName="diaIncidencia"
                                    inputId="integeronly"
                                    [useGrouping]="false"
                                    [max]="31"
                                    maxlength="2"
                                ></p-inputNumber>
                                <p
                                    class="erro-validacao"
                                    *ngIf="
                                        (regraCalculoControls.diaIncidencia
                                            .touched ||
                                            submitted) &&
                                        regraCalculoControls.diaIncidencia
                                            .errors?.required
                                    "
                                >
                                    Campo obrigatório
                                </p>
                            </div>
                            <div class="p-field p-col-4" *ngIf="showMes">
                                <label>Mês *</label>
                                <p-inputNumber
                                    formControlName="mesIncidencia"
                                    inputId="integeronly"
                                    [useGrouping]="false"
                                    [max]="12"
                                    maxlength="2"
                                ></p-inputNumber>
                                <p
                                    class="erro-validacao"
                                    *ngIf="
                                        (regraCalculoControls.mesIncidencia
                                            .touched ||
                                            submitted) &&
                                        regraCalculoControls.mesIncidencia
                                            .errors?.required
                                    "
                                >
                                    Campo obrigatório
                                </p>
                            </div>
                            <div class="p-field p-col-4" *ngIf="showQtdMeses">
                                <label>Quantidade de Meses *</label>
                                <p-inputNumber
                                    formControlName="qtdeMesIncidencia"
                                    inputId="integeronly"
                                    [useGrouping]="false"
                                    maxlength="2"
                                ></p-inputNumber>
                                <p
                                    class="erro-validacao"
                                    *ngIf="
                                        (regraCalculoControls.qtdeMesIncidencia
                                            .touched ||
                                            submitted) &&
                                        regraCalculoControls.qtdeMesIncidencia
                                            .errors?.required
                                    "
                                >
                                    Campo obrigatório
                                </p>
                            </div>
                        </div>
                        <div
                            class="p-fluid p-formgrid p-grid"
                            *ngIf="showQtdDias"
                        >
                            <div class="p-field p-col-4">
                                <label>Quantidade de Dias *</label>
                                <p-inputNumber
                                    formControlName="qtdeDiasIncidencia"
                                    inputId="integeronly"
                                    [useGrouping]="false"
                                    maxlength="3"
                                ></p-inputNumber>
                                <p
                                    class="erro-validacao"
                                    *ngIf="
                                        (regraCalculoControls.qtdeDiasIncidencia
                                            .touched ||
                                            submitted) &&
                                        regraCalculoControls.qtdeDiasIncidencia
                                            .errors?.required
                                    "
                                >
                                    Campo obrigatório
                                </p>
                            </div>
                            <div class="p-field p-col-4">
                                <label>Contagem *</label>
                                <p-dropdown
                                    optionLabel="descricao"
                                    [options]="tipoContagemEnum"
                                    optionValue="tipoContagemId"
                                    formControlName="tipoContagem"
                                    placeholder="Contagem"
                                >
                                </p-dropdown>
                                <p
                                    class="erro-validacao"
                                    *ngIf="
                                        (regraCalculoControls.tipoDataReferencia
                                            .touched ||
                                            submitted) &&
                                        regraCalculoControls.tipoDataReferencia
                                            .errors?.required
                                    "
                                >
                                    Campo obrigatório
                                </p>
                            </div>
                        </div>
                    </p-fieldset>
                    <p-fieldset
                        legend="Forma de Calculo *"
                        *ngIf="habilitaRegraCalculo"
                        [toggleable]="true"
                        class="card-item p-mx-6"
                    >
                        <div
                            class="p-fluid p-formgrid p-grid"
                            style="justify-content: space-between"
                        >
                            <div class="p-field p-col-6">
                                <label> Tipo Aliquota *</label>
                                <p-dropdown
                                    optionLabel="valor"
                                    [options]="tipoAliquotaEnum"
                                    optionValue="chave"
                                    formControlName="tipoAliquota"
                                    placeholder="Tipo Aliquota"
                                    (onChange)="changeTipoAliquota($event)"
                                >
                                </p-dropdown>
                                <p
                                    class="erro-validacao"
                                    *ngIf="
                                        (regraCalculoControls.tipoAliquota
                                            .touched ||
                                            submitted) &&
                                        regraCalculoControls.tipoAliquota.errors
                                            ?.required
                                    "
                                >
                                    Campo obrigatório
                                </p>
                            </div>
                            <div class="p-field p-col-6" *ngIf="usarAliquota">
                                <label>Aliquota(%) *</label>
                                <p-inputNumber
                                    suffix="%"
                                    maxlength="4"
                                    [max]="100"
                                    *ngIf="!mode"
                                    step="0,01"
                                    value="0,01"
                                    min="0,01"
                                    max="100,00"
                                    formControlName="valorAliquota"
                                ></p-inputNumber>
                                <p
                                    class="erro-validacao"
                                    *ngIf="
                                        (regraCalculoControls.valorAliquota
                                            .touched ||
                                            submitted) &&
                                        regraCalculoControls.valorAliquota
                                            .errors?.required
                                    "
                                >
                                    Campo obrigatório
                                </p>
                            </div>
                            <div class="p-field p-col-6">
                                <label>Tipo de Capitalização *</label>
                                <p-dropdown
                                    [options]="tipoCapitalizacaoEnum"
                                    optionLabel="valor"
                                    optionValue="chave"
                                    formControlName="tipoCapitalizacao"
                                    placeholder="Tipo de Capitalização"
                                >
                                </p-dropdown>
                                <p
                                    class="erro-validacao"
                                    *ngIf="
                                        (regraCalculoControls.tipoCapitalizacao
                                            .touched ||
                                            submitted) &&
                                        regraCalculoControls.tipoCapitalizacao
                                            .errors?.required
                                    "
                                >
                                    Campo obrigatório
                                </p>
                            </div>
                            <div class="p-field p-col-6" *ngIf="usarIndice">
                                <label> Índice *</label>
                                <p-dropdown
                                    optionLabel="descricao"
                                    [options]="indiceList"
                                    optionValue="codigo"
                                    placeholder="Índice"
                                    formControlName="indice"
                                >
                                </p-dropdown>
                                <p
                                    class="erro-validacao"
                                    *ngIf="
                                        (regraCalculoControls.indice.touched ||
                                            submitted) &&
                                        regraCalculoControls.indice.errors
                                            ?.required
                                    "
                                >
                                    Campo obrigatório
                                </p>
                            </div>
                        </div>
                        <div
                            class="p-fluid p-formgrid p-grid"
                            style="align-items: end"
                        >
                            <div class="p-field p-col-6">
                                <label>
                                    <p-checkbox
                                        label="Tem aliquota máxima?"
                                        value="1"
                                        [formControl]="temAliquotaMaxima"
                                        [binary]="true"
                                        (onChange)="
                                            changeCheckboxTemAliquotaMaxima(
                                                $event
                                            )
                                        "
                                    >
                                    </p-checkbox>
                                </label>
                                <div>
                                    <label>Aliquota Máxima(%) *</label>
                                    <p-inputNumber
                                        suffix="%"
                                        maxlength="4"
                                        [max]="100"
                                        *ngIf="!mode"
                                        step="0,01"
                                        value="0,01"
                                        min="0,01"
                                        max="100,00"
                                        formControlName="valorAliquotaMaxima"
                                    ></p-inputNumber>
                                    <p
                                        class="erro-validacao"
                                        *ngIf="
                                            (regraCalculoControls
                                                .valorAliquotaMaxima.touched ||
                                                submitted) &&
                                            regraCalculoControls
                                                .valorAliquotaMaxima.errors
                                                ?.required
                                        "
                                    >
                                        Campo obrigatório
                                    </p>
                                </div>
                            </div>
                            <div class="p-field p-col-6">
                                <label> Periodicidade de Incidência *</label>
                                <p-dropdown
                                    optionLabel="valor"
                                    [options]="periodicidadeIncidenciaEnum"
                                    optionValue="chave"
                                    formControlName="periodicidadeIncidencia"
                                    placeholder="Periodicidade de incidência"
                                >
                                </p-dropdown>
                                <p
                                    class="erro-validacao"
                                    *ngIf="
                                        (regraCalculoControls
                                            .periodicidadeIncidencia.touched ||
                                            submitted) &&
                                        regraCalculoControls
                                            .periodicidadeIncidencia.errors
                                            ?.required
                                    "
                                >
                                    Campo obrigatório
                                </p>
                            </div>
                        </div>
                    </p-fieldset>

                    <app-reducao-multa-punitiva
                        #regraReducaoComp
                        *ngIf="habilitaReducaoMulta"
                        (validaPreenchimentoForm)="
                            verificaFormReducaoEstaValido($event)
                        "
                        (changeForm)="changeFormReducao($event)"
                        [transacaoRegraReducaoRequest]="
                            itemTransacaoRegraReducaoRequest
                        "
                        [submitted]="submitted"
                    ></app-reducao-multa-punitiva>
                </div>
            </p-tabPanel>
        </p-tabView>

        <div class="p-mt-3" style="text-align: right">
            <button
                type="button"
                pButton
                label="Voltar"
                class="p-button-outlined p-mr-2"
                (click)="voltar()"
            ></button>
            <button
                [disabled]="formGroupTransacao.invalid"
                type="submit"
                pButton
                label="Salvar"
                (click)="onSubmit()"
            ></button>
        </div>
    </form>
</p-card>

<app-base-legal-search-modal
    (selectBaseLegal)="changeBaseLegal($event)"
></app-base-legal-search-modal>
