<p-messages [(value)]="msgs" [enableService]="false"></p-messages>
<p-card header="Configuração das Regras dos Eventos de Compensação">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-2">
            <label>Código</label>
            <input type="text" pInputText autocomplete="nope" disabled [(ngModel)]="eventoCompensacao.eventoCompensacaoId">
        </div>
        <div class="p-field p-col-12 p-md-10">
            <label>Descrição do Evento de Compensação</label>
            <input
                #eventoCompensacaoDescricao="ngModel"
                type="text"
                pInputText
                autocomplete="nope"
                [(ngModel)]="eventoCompensacao.descricao"
                required
                maxlength="150"
            >
            <small *ngIf="(eventoCompensacaoDescricao.invalid && submitted)" class="p-error">Campo obrigatório</small>
        </div>

        <div class="p-field p-col-12 p-md-2">
            <label>Tipo da Compensação *</label>
            <p-dropdown
                    #tipoCompensacao="ngModel"
                    showClear="true"
                    [autoDisplayFirst]="false"
                    [style]="{'minWidth':'100%'}"
                    [options]="tiposCompensacao"
                    optionLabel="valor"
                    optionValue="chave"
                    [(ngModel)]="eventoCompensacao.tipoCompensacao"
                    (onChange)="onChangeTipoCompensacao($event)"
                    required
            ></p-dropdown>
            <small *ngIf="(tipoCompensacao.invalid && submitted)" class="p-error">Campo obrigatório</small>
        </div>
        <div class="p-field p-col-12 p-md-2">
            <label>Situação</label>
            <p-dropdown
                    showClear="true"
                    optionLabel="valor"
                    optionValue="chave"
                    [autoDisplayFirst]="false"
                    [style]="{'minWidth':'100%'}"
                    [options]="situacoes"
                    [disabled]="eventoCompensacao.implantado === implantadoNao"
                    [(ngModel)]="eventoCompensacao.situacao"
            >
            ></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-2">
            <label>Implantado</label>
            <p-dropdown
                    showClear="true"
                    optionLabel="valor"
                    optionValue="chave"
                    [autoDisplayFirst]="false"
                    [style]="{'minWidth':'100%'}"
                    [options]="simNaoEnum"
                    [disabled]="true"
                    [(ngModel)]="eventoCompensacao.implantado">
            ></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-3">
            <label title="dataInicioVigencia">Início da Vigência *</label>
            <p-calendar
                #eventoCompensacaoDataInicioVigencia="ngModel"
                [showIcon]="true"
                [disabled]="eventoCompensacao.implantado !== implantadoNao || eventoCompensacao.eventoCompensaImputacoes.length > 0"
                [(ngModel)]="eventoCompensacao.dataInicioVigencia"
                dateFormat="dd/mm/yy"
                required
            ></p-calendar>
            <small *ngIf="(eventoCompensacaoDataInicioVigencia.invalid && submitted)" class="p-error">Campo obrigatório</small>
        </div>
        <div class="p-field p-col-12 p-md-3">
            <label title="dataFimVigencia">Fim da Vigência</label>
            <p-calendar
                dateFormat="dd/mm/yy"
                [showIcon]="true"
                [(ngModel)]="eventoCompensacao.dataFimVigencia"
            ></p-calendar>
        </div>
    </div>

    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-6">
            <p-button label="Adicionar regra de imputação" icon="pi pi-check" iconPos="left" (click)="selectRegraInputacao()"></p-button>
        </div>
    </div>

    <div class="p-fluid p-formgrid p-grid" *ngIf="eventoCompensacao.eventoCompensaImputacoes.length">
        <div class="p-field p-col-12">
            <p-table [value]="eventoCompensacao.eventoCompensaImputacoes">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Regra de Imputação</th>
                        <th>Início vigência</th>
                        <th>Fim vigência</th>
                        <th>Ações</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-eci let-i="rowIndex">
                    <tr>
                        <td>{{eci.regraImputacao?.descricao}}</td>
                        <td>{{utilsService.convertToDateBr(eci.dataInicioVigencia)}}</td>
                        <td>{{utilsService.convertToDateBr(eci.dataFimVigencia)}}</td>
                        <td>
                            <button *ngIf="eci.ideventoCompensaImputacao === null" (click)="editEventoCompensacaoImputacao(eci, i)" pButton pRipple type="button" icon="pi pi-pencil" class="p-button-rounded"></button>
                            <button
                                    *ngIf="eventoCompensacao.implantado === implantadoNao || (eventoCompensacao.implantado === implantadoSim && eci.ideventoCompensaImputacao === null)"
                                    (click)="dropEventoCompensacaoImputacao(eci, i)"
                                    pButton
                                    pRipple
                                    type="button"
                                    icon="pi pi-trash"
                                    class="p-button-rounded p-button-danger"
                            ></button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>

    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12">
            <label>Transação da Compensação de Débito</label>
            <div class="p-inputgroup">
                <p-dropdown
                        #transacaoDebito
                        showClear="true"
                        [autoDisplayFirst]="false"
                        [style]="{'minWidth':'100%'}"
                        [options]="transacoesDebito"
                        optionLabel="nomeResumido"
                        appendTo="body"
                        filter="true"
                        emptyMessage="Vazio"
                        [(ngModel)]="eventoCompensacao.transacaoDebito"
                        [disabled]="eventoCompensacao.implantado === implantadoSim"
                ></p-dropdown>
                <button type="button" pButton pRipple label="Adicionar contas de origem" (click)="showBasicDialog('ADICIONAR_CONTAS_ORIGEM')"></button>
            </div>
            <small *ngIf="((eventoCompensacao.transacaoDebito == null) && submitted)" class="p-error">Campo obrigatório</small>
        </div>
    </div>

    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12">
            <p-fieldset legend="Lista das contas de origem">
                <p-table [value]="eventoCompensacao.eventoCompensacaoContas" *ngIf="eventoCompensacao.eventoCompensacaoContas.length">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Código</th>
                            <th>Nome da Conta</th>
                            <th>Ações</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-co let-i="rowIndex">
                        <tr *ngIf="co.origemDestino === 'ORIGEM'">
                            <td>{{co.configConta?.configContaId}}</td>
                            <td>{{co.configConta?.nomeCompleto}}</td>
                            <td>
                                <button
                                        *ngIf="eventoCompensacao.implantado === implantadoNao || (eventoCompensacao.implantado === implantadoSim && co.eventoCompensacaoContaId === null)"
                                        (click)="dropConta(co, i)"
                                        pButton
                                        pRipple
                                        type="button"
                                        icon="pi pi-trash"
                                        class="p-button-rounded p-button-danger"
                                ></button>

                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-fieldset>
        </div>
    </div>

    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12">
            <label>Transação da Compensação de Crédito</label>
            <div class="p-inputgroup">
                <p-dropdown
                        showClear="true"
                        [autoDisplayFirst]="false"
                        [style]="{'minWidth':'100%'}"
                        [options]="transacoesCredito"
                        optionLabel="nomeResumido"
                        filter="true"
                        appendTo="body"
                        emptyMessage="Vazio"
                        [(ngModel)]="eventoCompensacao.transacaoCredito"
                        [disabled]="eventoCompensacao.implantado === implantadoSim"
                ></p-dropdown>
                <button type="button" pButton pRipple label="Adicionar contas ao destino" (click)="showBasicDialog('ADICIONAR_CONTAS_DESTINO')"></button>
            </div>
            <small *ngIf="((eventoCompensacao.transacaoCredito == null) && submitted)" class="p-error">Campo obrigatório</small>
        </div>
    </div>

    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12">
            <p-fieldset legend="Lista das contas de destino">
                <p-table [value]="eventoCompensacao.eventoCompensacaoContas" *ngIf="eventoCompensacao.eventoCompensacaoContas.length">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Código</th>
                            <th>Nome da Conta</th>
                            <th>Ações</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-cd let-i="rowIndex">
                        <tr *ngIf="cd.origemDestino === 'DESTINO'">
                            <td>{{cd.configConta?.configContaId}}</td>
                            <td>{{cd.configConta?.nomeCompleto}}</td>
                            <td>
                                <button
                                        *ngIf="eventoCompensacao.implantado === implantadoNao || (eventoCompensacao.implantado === implantadoSim && cd.eventoCompensacaoContaId === null)"
                                        (click)="dropConta(cd, i)"
                                        pButton
                                        pRipple
                                        type="button"
                                        icon="pi pi-trash"
                                        class="p-button-rounded p-button-danger"
                                ></button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-fieldset>
        </div>
    </div>

    <ng-template pTemplate="footer">
        <div class="grid grid-nogutter justify-content-end">
            <button style="margin-right: 5px;" pButton type="button" label="Voltar" class="p-button-outlined p-mr-2" (click)="voltar()"></button>
            <p-button class="float-btn-next" icon="pi pi-save" (click)="salvar()" label="Salvar"></p-button>
        </div>
    </ng-template>
</p-card>

<app-config-conta-search-modal [multipleSelection]="true"  (outConfigConta)="outConfigConta($event)"></app-config-conta-search-modal>
<app-regra-inputacao-select-modal
        [events]="eventsSubject.asObservable()"
        (onSelected)="getRegraInputacaoSelected($event)"
></app-regra-inputacao-select-modal>

