import {BaseLegal} from './base-legal';
import {ConfigConta} from './config-conta';

export class ConfigContaBaseLegal {
    configContaBaseLegalId: number;
    dataFimVigencia: string;
    dataInicioVigencia: string;
    situacao: string;
    baseLegal: BaseLegal;
    configConta: ConfigConta;

    constructor(init?: Partial<ConfigContaBaseLegal>) {
        if (init) {
            Object.assign(this, init);
        } else {
            this.configContaBaseLegalId = null;
            this.dataFimVigencia = null;
            this.dataInicioVigencia = null;
            this.situacao = null;
            this.baseLegal = null;
            this.configConta = null;
        }
    }
}
