<router-outlet>
    <ngx-spinner
        bdColor="rgba(0, 0, 0, 0.5)"
        size="medium"
        color="#fff"
        type="ball-spin"
        [fullScreen]="true"
        ><p style="color: white">Carregando...</p></ngx-spinner
    >
</router-outlet>
