import { TipoCadastro } from "./tipo-cadastro";

export class Receita {
    configContaReceitaId: number;
    descricao: string;
    codReceita: number;
    codTributo: number;
    dataInicioVigencia: string;
    dataFimVigencia: string;
    nomeReceita: string;
    nomeTributo: string;
    situacao: string;
    tributaria: number;
    subReceita: number;
    tipoCategoria: TipoCadastro;

    constructor(init?: Partial<Receita>) {
        if (init) {
            Object.assign(this, init);
        } else {
            this.configContaReceitaId = 0;
            this.descricao = '';
            this.codReceita = 0;
            this.codTributo = 0;
            this.dataInicioVigencia = "";
            this.dataFimVigencia = "";
            this.nomeReceita = "";
            this.nomeTributo = "";
            this.situacao = "";
            this.tributaria = 0;
            this.subReceita = null;
            this.tipoCategoria = null;
        }
    }
}
